import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Form, Row } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import Title from "antd/lib/typography/Title";
import { FILTER_STATUS, FILTER_STATUS_OPTIONS, VALIDATION_RULES } from "pages/depot-management/depots/constants";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { isLatitude, isLongitude } from "utils/helpers/mapHelpers";
import { USER_TENANT_NAME } from "utils/constants";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
});

const emptyFn = () => {};

const DepotInformation = ({ isNew = true, form, onChange = emptyFn }) => {
  const classes = useStyles();
  const [selectedCounty, setSelectedCounty] = useState(null);
  const {
    list: { county },
  } = useSelector(DictionariesSelector);

  const countyList = useMemo(() => (county && county[USER_TENANT_NAME]?.map(item => item.name)) || [], [county]);

  const subCountyList = useMemo(() => {
    const currentCounty = selectedCounty ?? form.getFieldValue("county");
    const selected = (county && county[USER_TENANT_NAME]?.find(item => item.name === currentCounty)) || {};
    return selected?.sub || [];
  }, [selectedCounty, form.getFieldValue("county")]);

  const onCountyChange = useCallback(
    value => {
      onChange && onChange();
      setSelectedCounty(value);
      form.setFieldValue("subCounty", null);
    },
    [form]
  );

  return (
    <Form form={form} name="depotInfo" layout="inline" onChange={onChange} className={classes.formContainer}>
      <div>
        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Depot Name</Title>
            <Form.Item name="name" rules={VALIDATION_RULES}>
              <NGIInput disabled={!isNew} placeholder="Enter depot name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>County</Title>
            <Form.Item name="county" rules={VALIDATION_RULES}>
              <NGISelect showSearch onChange={onCountyChange} placeholder={"Select value"}>
                {countyList &&
                  countyList.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Sub-county</Title>
            <Form.Item name="subCounty" rules={VALIDATION_RULES}>
              <NGISelect showSearch placeholder={"Select value"} onChage={onChange}>
                {subCountyList &&
                  subCountyList.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>Depot Physical Address</Title>
            <Form.Item name="address" rules={VALIDATION_RULES}>
              <NGIInput placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Depot Latitude</Title>
            <Form.Item
              name="lat"
              rules={[
                ...VALIDATION_RULES,
                {
                  message: "The Latitude should be valid",
                  validator: (_, value) => {
                    if (!isLatitude(value)) {
                      return Promise.reject("The Latitude should be valid");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <NGIInput disablePattern placeholder="Enter value" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>Depot Longitude</Title>
            <Form.Item
              name="lng"
              rules={[
                ...VALIDATION_RULES,
                {
                  message: "The Longitude should be valid",
                  validator: (_, value) => {
                    if (!isLongitude(value)) {
                      return Promise.reject("The Longitude should be valid");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <NGIInput disablePattern placeholder="Enter value" />
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Depot Operationalization Date</Title>
            <Form.Item name="operationalizationDate" rules={VALIDATION_RULES}>
              <NGIDatePicker disabledPasDates allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>Status</Title>
            <Form.Item initialValue={FILTER_STATUS.ACTIVE} name="status" rules={VALIDATION_RULES}>
              <NGISelect showSearch onChange={onChange}>
                {Object.keys(FILTER_STATUS_OPTIONS).map(item => (
                  <Option key={item} value={item}>
                    {FILTER_STATUS_OPTIONS[item]}
                  </Option>
                ))}
              </NGISelect>
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>DSM Phone Number</Title>
            <Form.Item name="dsmPhoneNumber" rules={VALIDATION_RULES}>
              <NGIInput disablePattern placeholder="Enter value" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>Depot Group ID</Title>
            <Form.Item name="azureObjectID">
              <NGIInput maxLength={50} disablePattern placeholder="Enter value" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default DepotInformation;
