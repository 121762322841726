import React, { useCallback, useState } from "react";
import { Divider, Modal, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    width: "400px!important",

    "& .ant-modal-header": {
      padding: "24px 24px 0 !important",
    },

    "& .ant-modal-body": {
      padding: "10px 24px !important",
    },
  },
  description: {
    color: "#666666",
    whiteSpace: "pre-wrap",
    padding: "0 10px",
  },
  buttonsList: {
    display: "flex",
    flexDirection: "column",
  },
  reasonBtn: {
    width: "100%",
    padding: "10px 15px",
    fontSize: 13,
    backgroundColor: "#fff",
    border: "2px solid #0000001A",
    borderRadius: 15,
    marginBottom: 10,

    "&>div": {
      color: "#666666B2",
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedBtn: {
    backgroundColor: "#0085FF",
    color: "#FFF",

    "&>div": {
      color: "#FFFFFFB2",
    },
  },
});

const ConfirmationUpdateModal = ({ title = "Update", updateReasons, onConfirm, onCancel, loading = false }) => {
  const classes = useStyles();
  const [reason, setReason] = useState(updateReasons[0].value);

  const handleReasonChange = useCallback(e => setReason(e.currentTarget.dataset.type), []);
  const handleConfirm = useCallback(() => onConfirm(reason), [reason]);

  return (
    <Modal className={classes.root} open={true} closable={false} title={title} onCancel={onCancel} footer={[]}>
      <Spin spinning={loading}>
        <p className={classes.description}>By updating this Stock Transfer Order you want to proceed with:</p>
        <div className={classes.buttonsList}>
          {updateReasons.map(item => (
            <div
              key={item.value}
              onClick={handleReasonChange}
              data-type={item.value}
              className={classnames(classes.reasonBtn, reason === item.value && classes.selectedBtn)}
            >
              {item.title}
              <div>{item.desc}</div>
            </div>
          ))}
        </div>
        <Divider />
        <Space>
          <CustomButton onClick={onCancel} size="small" type="primary" color="outlined" text="Cancel" />
          <CustomButton onClick={handleConfirm} size="small" type="primary" text="Confirm" />
        </Space>
      </Spin>
    </Modal>
  );
};

export default ConfirmationUpdateModal;
