import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_TENANT_NAME } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { uniq } from "lodash";
import { searchOptions } from "pages/settings/survey-management/surveys/constants";

const { Option } = Select;
const SurveySearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");
  const { filters } = useStoreFilters(FILTER_PAGE.SURVEY);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: null,
        value: null,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    !(values.value && !values.type) && handleSearch(values.type ? { [values.type]: values.value?.trim() } : {});
  }, []);

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            showSearch
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <>
          {selectedFilter === "forDepot" ? (
            <Form.Item name="value">
              <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                <Option value={"ALL"}>ALL</Option>
                {depot[USER_TENANT_NAME] &&
                  uniq(depot[USER_TENANT_NAME])
                    ?.sort()
                    ?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item name="value">
              <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default SurveySearch;
