import React, { useCallback, useEffect, useState } from "react";
import { Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { confirmTransaction, fetchTransactionDetails } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomButton from "components/Common/buttons/CustomButton";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_ROLES } from "utils/constants";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },

  informationContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  mapContainer: {
    width: "100%",
  },
  section: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  item: {
    width: "45%",
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
  divider: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    width: "100%",
    marginBottom: 15,
  },
});

const getNormalizedData = data => [
  [
    { name: "Business Short Code", value: data.businessShortCode },
    { name: "Balance Paid", value: data.balancePaid },
    { name: "Gas Amount", value: data.gasAmount },
    { name: "Stove Amount", value: data.balanceAmount },
  ],
  [
    { name: "Receipt Number", value: data.receiptNumber },
    { name: "Reference Number", value: data.referenceNumber },
    { name: "Transaction Id", value: data.transactionId },
    { name: "Transaction Type", value: data.transactionType },
    { name: "Transaction Mode", value: data.transactionMode },
    { name: "Transaction Status", value: data.transactionStatus },
    { name: "Transaction Amount", value: data.transactionAmount },
    { name: "Transaction Date Time", value: customDateFormatWithTime(data.transactionDateTime) },
    { name: "Processing Type", value: data.processingType },
    { name: "Tax Amount", value: data.taxAmount },
    { name: "Reason", value: data.reason },
  ],

  [
    { name: "Payment Channel", value: data.paymentChannel },
    { name: "Payment Origin", value: data.paymentOrigin },
    { name: "Payment Mode", value: data.paymentMode },
    { name: "Meter Barcode", value: data.meterNumber },
  ],

  [
    { name: "Customer Id", value: data.customerId },
    { name: "Customer Name", value: `${data.customerFirstName} ${data.customerLastName}` },
    { name: "Payee Phone Number", value: data.payeePhoneNumber },
    { name: "Depot", value: data.depot },
  ],
];

const TransactionDetailsModal = ({ isOpen, onClose, id }) => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  useEffect(() => {
    setLoader(true);
    fetchTransactionDetails(id)
      .then(({ data }) => {
        setTransactionDetails(data?.result);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [id]);

  const handleConfirm = useCallback(() => {
    confirmTransaction(id)
      .then(() => {
        successMessage("Transaction was confirmed successfully");
        closeConfirmationModal();
        onClose();
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        closeConfirmationModal();
      });
  }, []);

  return (
    <Modal title={`Transaction ${id} details`} open={isOpen} closable={false} onCancel={onClose} footer={[]}>
      <Spin spinning={isLoading}>
        <div className={classes.root}>
          <div className={classes.informationContainer}>
            {getNormalizedData(transactionDetails).map(item => (
              <>
                <div className={classes.divider} />
                <div className={classes.section}>
                  {item.map(item => (
                    <div key={item.name} className={classes.item}>
                      <div className={classes.title}>{item.name} :</div>
                      <div className={classes.data}>{textFormatter(item.value)}</div>
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
        {transactionDetails.transactionStatus === "VERIFIED" && (
          <Space>
            <CustomButton
              hideRoles={[USER_ROLES.PRODUCT_TECHNICIANS]}
              type="primary"
              size="small"
              onClick={openConfirmationModal}
              text="Confirm"
            />
          </Space>
        )}
      </Spin>
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to confirm transaction?"
          onCancel={closeConfirmationModal}
          onConfirm={handleConfirm}
        />
      )}
    </Modal>
  );
};

export default TransactionDetailsModal;
