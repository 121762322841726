import React, { useCallback } from "react";
import infoIconGrey from "assets/images/svg/infoIconGrey.svg";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { USER_ROLES } from "utils/constants";
import NGISelect from "components/Common/inputs/NGISelect";
import { Select } from "antd";
import { userHasOneOfRoles } from "utils/helpers/functions";
import classnames from "classnames";
import { REQUEST_STATUSES } from "pages/customer-management/customerUpdateLocationRequest/createCustomerLocationUpdateRequest/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 25,
    borderRadius: 5,
  },
  info: {
    backgroundColor: "rgba(0, 133, 255, .1)",
  },
  error: {
    backgroundColor: alpha("#FF0000", 0.1),
  },
  image: {
    marginRight: 10,
  },
  text: {
    margin: 0,
    paddingRight: 10,
  },
});

const { Option } = Select;

const InfoMessage = ({ DSMDepot, updateRequest, customer, TSRList, onSelectTechnician }) => {
  const classes = useStyles();
  const { status, newDepot, depot } = updateRequest;
  const { technician } = customer;
  const selectedTSR = TSRList.find(item => item.email === technician);

  const getMessageContent = useCallback(() => {
    if ((DSMDepot === depot || userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])) && status === "Pending") {
      return newDepot ? (
        newDepot === depot ? (
          <TSRSelect />
        ) : (
          <p className={classes.text}>
            Customer moved to a new depot <b>{newDepot}</b>. Please approve this request to notify the respective DSM or
            ADSM about customer relocation
          </p>
        )
      ) : (
        <p className={classes.text}>
          A customer is moving out of mGas’ operation zones. A <b>Repossession Task</b> will be initiated after
          “Approving” this request.
        </p>
      );
    }

    if (
      (DSMDepot === newDepot || userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])) &&
      status === REQUEST_STATUSES.APPROVED_BY_CURRENT
    ) {
      return <TSRSelect />;
    }
  }, [DSMDepot, newDepot, depot, status]);

  const TSRSelect = useCallback(
    () => (
      <>
        <p className={classes.text}>
          {depot === newDepot
            ? "A customer has moved to another address in your depot. Please assign a TSR to execute the relocation task."
            : "A customer has recently moved to your depot. Please assign a TSR to execute the relocation task."}
        </p>
        <NGISelect
          showSearch
          loading={!TSRList?.length}
          onChange={onSelectTechnician}
          defaultValue={selectedTSR?.email}
          placeholder="Select TSR"
          filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
        >
          {TSRList.map(item => (
            <Option key={item.id} value={item.email}>
              {item.email}
            </Option>
          ))}
        </NGISelect>
      </>
    ),
    [newDepot, depot, TSRList]
  );

  const message = getMessageContent();

  if (!message) {
    return null;
  }

  return (
    <div className={classnames(classes.root, newDepot ? classes.info : classes.error)}>
      <img className={classes.image} src={infoIconGrey} alt="infoIcon" />
      {message}
    </div>
  );
};
export default InfoMessage;
