import { customDateFormatWithoutTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import infoIcon from "assets/images/svg/Info.svg";
import React from "react";
import { STO_VIEW_TYPES } from "pages/stock-management/stockTransferOrders/constants";
import editIcon from "assets/images/svg/Edit.svg";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";

export const stoListColumns = (viewType, setActiveId) => [
  {
    title: "Order ID",
    dataIndex: viewType === STO_VIEW_TYPES.ASSET ? "stoId" : "id",
    render: textFormatter,
  },

  ...(viewType === STO_VIEW_TYPES.ASSET
    ? [
        {
          title: "Asset Type",
          dataIndex: "group",
          render: textFormatter,
        },
        {
          title: "Condition",
          dataIndex: "condition",
          render: textFormatter,
        },
      ]
    : [
        {
          title: "Order Type",
          dataIndex: "type",
          render: textFormatter,
        },
      ]),
  {
    title: "Qty",
    dataIndex: "quantity",
    render: textFormatter,
  },
  {
    title: "To Depot",
    dataIndex: "toDepot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "From Depot",
    dataIndex: "fromDepot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Trip ID",
    dataIndex: "tripId",
    render: tripId =>
      tripId ? (
        <CustomLink underlined target="_blank" to={`/stock-management/trips/${tripId}`}>
          {tripId}
        </CustomLink>
      ) : (
        textFormatter(tripId)
      ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Preferred Date",
    dataIndex: "preferredDate",
    render: cellContent => textFormatter(customDateFormatWithoutTime(cellContent)),
  },
  ...(viewType === STO_VIEW_TYPES.ORDER
    ? [
        {
          title: "Requester",
          dataIndex: "createdBy",
          render: cellContent => (
            <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
              {cellContent}
            </CustomLink>
          ),
        },
        {
          dataIndex: "",
          title: "",
          width: "4%",
          render: row =>
            row.status === "Pending" &&
            userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.LOGISTICS, USER_ROLES.WAREHOUSE]) ? (
              <ButtonGroupContainer>
                <ButtonGroup tooltipTitle="View details" icon={infoIcon} handleClick={() => setActiveId(row.id)} />
                <ButtonGroup tooltipTitle="Edit" icon={editIcon} handleClick={() => setActiveId(row.id, true)} />
              </ButtonGroupContainer>
            ) : (
              <ButtonGroup tooltipTitle="View details" icon={infoIcon} handleClick={() => setActiveId(row.id)} />
            ),
        },
      ]
    : []),
];
