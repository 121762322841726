import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Form, Modal, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import NGIInput from "components/Common/inputs/NGIInput";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { useHistory } from "react-router";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import TitledContainer from "components/Common/components/TitledContainer";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { VALIDATION_RULES } from "pages/marketing/initiativeDetails/constants";
import NGISelect from "components/Common/inputs/NGISelect";
import { USER_TENANT_NAME } from "utils/constants";
import CustomButton from "components/Common/buttons/CustomButton";
import uploadArea from "assets/images/svg/uploadArea.svg";
import Dragger from "antd/lib/upload/Dragger";
import { base64toBuffer, toBase64, uuidv4 } from "utils/helpers/functions";
import closeIcon from "assets/images/svg/closeSmall.svg";
import {
  cancelTerminateInitiative,
  createInitiative,
  retrieveInitiativeUploadUrl,
  uploadPictureToS3Bucket,
} from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import bottomPreviewSection from "assets/images/initiativePreview/bottomPreview.png";
import topPreviewSection from "assets/images/initiativePreview/statusBar.png";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGITimePicker from "components/Common/inputs/NGITimePicker";
import { getDateTime } from "pages/marketing/initiativeDetails/utils";
import config from "appconfig";
import moment from "moment/moment";

const { Option } = Select;

const useStyles = makeStyles({
  root: {},
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 10,
    },
  },
  questionsContainer: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  addNew: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,
    marginTop: 15,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  rowContainer: {
    borderRadius: 5,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgba(250, 250, 250, 1)",
    marginBottom: 5,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  value: {
    fontSize: 13,
    color: "#444444",
    fontWeight: 600,
    marginRight: 5,
    padding: 15,
    whiteSpace: "nowrap",
    width: "15%",

    "&>span": {
      color: "#8C8C8C",
      fontWeight: 300,
    },
  },
  questionContainer: {
    width: "50%",
  },
  questionOptionsContainer: {},
  questionTitle: {
    width: "20%",
  },
  actionContainer: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 80,

    "& img": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  deleteBtn: {
    width: 18,
  },
  overflowText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  title: {
    fontSize: "13px!important",
  },
  resultsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  resultBox: {
    backgroundColor: "rgba(220, 238, 255, 1)",
    padding: 20,
    borderRadius: 20,
    marginBottom: 20,
    width: "49%",
    display: "flex",
    justifyContent: "space-between",
  },
  uploadButton: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    fontWeight: 600,
    color: "white",
    padding: 12,
    height: 40,
    borderRadius: 20,
    textTransform: "uppercase",
    fontSize: "11px!important",

    "&> img": {
      margin: 10,
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  imgPreviewContainer: {
    position: "relative",
    width: "fit-content",

    "&>img": {
      borderRadius: 15,
      maxWidth: "100%",
    },
  },
  imgRemove: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 5,
    right: 5,
    justifyContent: "center",
    padding: 10,
    borderRadius: 50,
    backgroundColor: "#f5f5f5",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  dragger: {
    height: "140px!important",
    minHeight: "140px!important",

    "& >.ant-upload-btn": {
      background: "white",
      borderRadius: 16,
    },
  },
  fileError: {
    position: "absolute",
    bottom: 0,
    color: "red",
    fontSize: 10,
  },
  modalPreview: {
    width: "400px!important",
    "&> .ant-modal-content .ant-modal-body": {
      padding: "0!important",
    },
    "& .ant-modal-close-x": {
      height: 30,
      width: 30,
      lineHeight: "30px",
    },
  },
  initiativePreview: {
    width: 400,

    "&> img": {
      width: "100%",
    },
  },
  textContainer: {
    width: 400,
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 20px",
  },
  previewName: {
    fontSize: 26,
  },
  previewDesc: {
    fontSize: 11,
    textAlign: "center",
  },
  divider: {
    height: 2,
    width: 50,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    margin: "20px 0",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#FFFFFF",
    borderRadius: 30,

    "& > a": {
      fontSize: 15,
    },
  },
});

const EditInitiativeTab = ({ initiative, setIsLoading }) => {
  const classes = useStyles();
  const {
    cognito: { landingDomain },
  } = config;
  const [form] = Form.useForm();
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isTerminateVisible, setIsTerminateVisible] = useState(false);
  const [isCancelVisible, setIsCancelVisible] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    if (initiative) {
      form.setFieldsValue({
        ...initiative,
        startTime: initiative.startAt,
        endTime: initiative.endAt,
      });
    }
  }, [initiative]);

  const sharedLink = useMemo(() => `${landingDomain}/${initiative?.code}`, [initiative]);

  const uploadImg = useCallback(
    data =>
      retrieveInitiativeUploadUrl(data.fileType.replace("image/", ".")).then(async res => {
        const {
          data: { result },
        } = res;
        const base64Data = base64toBuffer(data.base64);

        return uploadPictureToS3Bucket(result.url, base64Data, data.fileType).then(() => result.fileName);
      }),
    []
  );

  const onSave = useCallback(
    async values => {
      try {
        const { name, description, startAt, startTime, endAt, endTime, depots } = values;
        //TODO: remove after fix end time field
        if (new Date(getDateTime(startAt, startTime)).toString() === new Date(getDateTime(endAt, endTime)).toString())
          return errorMessage("Please check the 'End Time' field");
        if (!file) return errorMessage("Please check the 'Initiative Cover Image' field");
        setIsLoading(true);
        const coverImagePath = await uploadImg(file);
        const preparedData = {
          name: name.toUpperCase(),
          description,
          startAt: getDateTime(startAt, startTime),
          endAt: getDateTime(endAt, endTime),
          depots,
          coverImagePath,
        };
        createInitiative(preparedData)
          .then(() => {
            successMessage("Initiative was created successfully");
            history.push("/marketing/initiatives");
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
            defaultNgiErrorMethod(err);
          });
      } catch (e) {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      }
    },
    [file]
  );

  const openConfirmationModal = useCallback(e => {
    e.preventDefault();
    setIsConfirmationVisible(true);
  }, []);

  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationVisible(false);
  }, []);

  const openTerminateModal = useCallback(e => {
    e.preventDefault();
    setIsTerminateVisible(true);
  }, []);

  const closeTerminateModal = useCallback(e => {
    e?.preventDefault();
    setIsTerminateVisible(false);
  }, []);

  const openCancelModal = useCallback(e => {
    e.preventDefault();
    setIsCancelVisible(true);
  }, []);

  const closeCancelModal = useCallback(e => {
    e?.preventDefault();
    setIsCancelVisible(false);
  }, []);

  const openPreviewModal = useCallback(e => {
    e.preventDefault();
    setIsPreviewVisible(true);
  }, []);

  const closePreviewModal = useCallback(e => {
    e?.preventDefault();
    setIsPreviewVisible(false);
  }, []);
  const copyLink = useCallback(
    e => {
      e?.preventDefault();
      navigator.clipboard.writeText(sharedLink);
      successMessage("Link is copied to clipboard.");
    },
    [sharedLink]
  );

  const onSubmit = useCallback(() => {
    setIsConfirmationVisible(false);
    form.submit();
  }, [form]);

  const onCancelTerminate = useCallback(
    type => {
      setIsLoading(true);
      setIsTerminateVisible(false);
      setIsCancelVisible(false);
      cancelTerminateInitiative(initiative.code)
        .then(() => {
          successMessage(`Initiative was ${type} successfully`);
          history.push("/marketing/initiatives");
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [initiative]
  );

  const handleFileChange = useCallback(async event => {
    const fileUploaded = event.file;
    const base64Data = await toBase64(fileUploaded);
    const size = parseFloat((fileUploaded.size / 1000).toFixed(2));
    if (size <= 2000) {
      setFileError(false);
      setFile({
        _id: uuidv4(),
        name: fileUploaded.name,
        size,
        base64: base64Data,
        fileType: fileUploaded.type,
      });
    } else {
      setFileError(true);
    }
  }, []);

  const onRemoveImg = useCallback(() => setFile(null), []);

  const handleChangeStartDate = useCallback(value => {
    form.resetFields(["startTime"]);
    form.resetFields(["endAt"]);
    form.resetFields(["endTime"]);
    setStartDate(value);
    setStartTime(null);
  }, []);

  const handleChangeStartTime = useCallback(value => {
    form.resetFields(["endAt"]);
    form.resetFields(["endTime"]);
    setStartTime(value);
  }, []);

  const handleChangeEndDate = useCallback(value => {
    const { startTime, startAt } = form.getFieldsValue();
    form.resetFields(["endTime"]);
    if (startAt?.format("DD.MM.YYYY") === value?.format("DD.MM.YYYY")) {
      value.set("hour", startTime.hours() + 1);
      value.set("minute", startTime.minutes());
      value.set("second", startTime.seconds());
      setEndDate(value);
      return;
    }
    setEndDate(value);
  }, []);

  return (
    <div className={classes.root}>
      <Form form={form} disabled={!!initiative} name="initiative-edit" onFinish={onSave}>
        {initiative && initiative.code && (
          <TitledContainer title="Unique Shareable Link">
            <div className={classes.linkContainer}>
              <a target="_blank" href={sharedLink} rel="noreferrer">
                {sharedLink}
              </a>
              <CustomButton size="small" text="Copy to clipboard" onClick={copyLink} />
            </div>
          </TitledContainer>
        )}

        <TitledContainer title="Initiative Details">
          <Row gutter={[24, 8]}>
            <Col lg={12}>
              <Title className={classes.title}>Initiative Name (max 10 characters)</Title>
              <Form.Item
                name="name"
                rules={[
                  {
                    message: "Please enter value",
                    validator: (_, value) => {
                      if (!value?.trim()) {
                        return Promise.reject("Please enter value");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGIInput maxLength={10} white placeholder="Enter initiative name" />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Title className={classes.title}>Start Date</Title>
              <Form.Item name="startAt" rules={[...VALIDATION_RULES]}>
                <NGIDatePicker
                  white
                  onChange={handleChangeStartDate}
                  placeholder="Initiative Start"
                  disabledPasDates
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Title className={classes.title}>Start Time</Title>
              <NGIFormItem name="startTime" rules={VALIDATION_RULES}>
                <NGITimePicker
                  white
                  onChange={handleChangeStartTime}
                  minuteStep={60}
                  showSecond={false}
                  isOneDay={moment().format("DD.MM.YYYY") === moment(startDate).format("DD.MM.YYYY")}
                  disabledPasDates
                />
              </NGIFormItem>
            </Col>
            <Col lg={3}>
              <Title className={classes.title}>End Date</Title>
              <Form.Item name="endAt" rules={VALIDATION_RULES}>
                <NGIDatePicker
                  white
                  onChange={handleChangeEndDate}
                  placeholder="Initiative End"
                  disableDates={current => form.getFieldValue("startAt") > current}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Title className={classes.title}>End Time</Title>
              <NGIFormItem name="endTime" rules={VALIDATION_RULES}>
                <NGITimePicker
                  white
                  allowStartFrom
                  isOneDay={moment(startDate).format("DD.MM.YYYY") === moment(endDate).format("DD.MM.YYYY")}
                  startTime={startTime}
                  minuteStep={60}
                  showSecond={false}
                  disabledPasDates
                />
              </NGIFormItem>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col lg={12}>
              <Title className={classes.title}>Initiative Description (max 250 characters)</Title>
              <Form.Item
                name="description"
                rules={[
                  {
                    message: "Please enter value",
                    validator: (_, value) => {
                      if (!value?.trim()) {
                        return Promise.reject("Please enter value");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGITextArea
                  disabled={!!initiative}
                  white
                  maxlength={250}
                  rows={5}
                  placeholder="Please add your message"
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Title className={classes.title}>Initiative Cover Image</Title>

              {!file && !initiative ? (
                <Dragger
                  className={classes.dragger}
                  showUploadList={false}
                  customRequest={handleFileChange}
                  name="file"
                  accept=".png,.jpg,.jpeg"
                >
                  <img className="upload-bg" src={uploadArea} alt="upload" />
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single upload. Strictly prohibit from uploading company data or other band files
                  </p>
                </Dragger>
              ) : (
                <div className={classes.imgPreviewContainer}>
                  <img src={initiative ? initiative?.photoUrl : file.base64} alt="file preview" />
                  {!initiative && (
                    <div className={classes.imgRemove} onClick={onRemoveImg}>
                      <img src={closeIcon} alt="remove img" />
                    </div>
                  )}
                </div>
              )}

              {fileError && <div className={classes.fileError}>File size should be less than or 2MB</div>}
            </Col>
          </Row>
        </TitledContainer>

        {initiative && initiative.code && (
          <TitledContainer title="Initiative Promo Code">
            <Row gutter={[24, 8]}>
              <Col lg={24}>
                <Title className={classes.title}>Initiative Code</Title>
                <Form.Item name="code">
                  <NGIInput disabled maxLength={20} white placeholder="Enter value" />
                </Form.Item>
              </Col>
            </Row>
          </TitledContainer>
        )}

        <TitledContainer title="Initiative Location(s)">
          <Row gutter={[24, 8]}>
            <Col lg={24}>
              <Title className={classes.title}>List of Depots</Title>
              <Form.Item name="depots">
                <NGISelect
                  showSearch
                  style={{ width: "100%" }}
                  mode="multiple"
                  tokenSeparators={[" ", ","]}
                  size="default"
                  placeholder="ALL DEPOTS"
                >
                  {depot[USER_TENANT_NAME]?.length > 0 &&
                    depot[USER_TENANT_NAME]?.sort()?.map(
                      item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ),
                      this
                    )}
                </NGISelect>
              </Form.Item>
            </Col>
          </Row>
        </TitledContainer>

        <div className={classes.buttonsContainer}>
          {!initiative && (
            <>
              <CustomButton color="outlined" disabled={!file} onClick={openPreviewModal} size="small" text="Preview" />
              <CustomButton onClick={openConfirmationModal} size="small" text="Create" />
            </>
          )}
          {initiative && initiative.status === "New" && (
            <CustomButton onClick={openCancelModal} type="error" size="small" text="Cancel Initiative" />
          )}
          {initiative && initiative.status === "Active" && (
            <CustomButton onClick={openTerminateModal} type="error" size="small" text="Terminate Initiative" />
          )}
        </div>
      </Form>

      <Modal className={classes.modalPreview} title="" open={isPreviewVisible} onCancel={closePreviewModal} footer={[]}>
        <div className={classes.initiativePreview}>
          <img src={topPreviewSection} alt="bottom preview" />
          <div
            style={{
              backgroundSize: "cover",
              height: 220,
              backgroundPosition: "center top",
              backgroundImage: `url(${file?.base64})`,
            }}
            className={classes.textContainer}
          >
            <div className={classes.previewName}>{form.getFieldValue("name")}</div>
            <div className={classes.divider}></div>
            <div className={classes.previewDesc}>{form.getFieldValue("description")}</div>
          </div>
          <img src={bottomPreviewSection} alt="bottom preview" />
        </div>
      </Modal>

      {isConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          description={`Are you sure you want to ${!initiative ? "create a new" : "change the"} initiative?`}
        />
      )}
      {isCancelVisible && (
        <ConfirmationModal
          onCancel={closeCancelModal}
          onConfirm={() => onCancelTerminate("canceled")}
          description="Are you sure you want to cancel initiative?"
        />
      )}
      {isTerminateVisible && (
        <ConfirmationModal
          onCancel={closeTerminateModal}
          onConfirm={() => onCancelTerminate("terminated")}
          description="Are you sure you want to terminate initiative?"
        />
      )}
    </div>
  );
};

export default EditInitiativeTab;
