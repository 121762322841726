import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fetchConsumptionStat, retrieveCustomersStats } from "utils/api";
import { Spin } from "antd";
import { textFormatter } from "components/Common/formatter";
import { extractPromisesResult } from "utils/helpers/functions";

const useStyles = makeStyles({
  counters: {
    position: "relative",
    top: 1,
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    backgroundColor: "white",
    borderBottom: "1px solid #0000000D",
    padding: "10px 0",
    height: 60,
  },
  counterItem: {},
  counterTitle: {
    color: "#8C8C8C",
    fontSize: 11,
  },
  counterValue: {
    display: "flex",
    alignItems: "baseline",
    fontSize: 18,
    fontWeight: 700,

    "&>span": {
      fontWeight: 300,
      fontSize: 12,
    },
  },

  withoutDivider: {},
});

export const CustomersStatsHeader = () => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    Promise.allSettled([retrieveCustomersStats(), fetchConsumptionStat()])
      .then(data => {
        const [customersStats, consumptionStat] = extractPromisesResult(data);
        setData({ ...customersStats?.data?.result?.counters, ...consumptionStat?.data?.result });
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className={classes.counters}>
        <div className={classes.counterItem}>
          <div className={classes.counterTitle}>Consumption</div>
          <div className={classes.counterValue}>
            {textFormatter(data?.averageConsumptionLast30Days?.toFixed(3))}
            <span>Kg</span>
          </div>
        </div>
        <div className="filter-divider" />

        <div className={classes.counterItem}>
          <div className={classes.counterTitle}>Active Customers</div>
          <div className={classes.counterValue}>{textFormatter(data?.Active)}</div>
        </div>
        <div className="filter-divider" />

        <div className={classes.counterItem}>
          <div className={classes.counterTitle}>Qualified Leads</div>
          <div className={classes.counterValue}>{textFormatter(data?.Qualified)}</div>
        </div>
        <div className="filter-divider" />

        <div className={classes.counterItem}>
          <div className={classes.counterTitle}>Assigned Prospects</div>
          <div className={classes.counterValue}>{textFormatter(data?.AssignedProspect)}</div>
        </div>
        <div className="filter-divider" />

        <div className={classes.counterItem}>
          <div className={classes.counterTitle}>Opened Prospects</div>
          <div className={classes.counterValue}> {textFormatter(data?.OpenedProspect)}</div>
        </div>
        <div className="filter-divider" />

        <div className={classes.counterItem}>
          <div className={classes.counterTitle}>Repossessed</div>
          <div className={classes.counterValue}>{textFormatter(data?.Repossessed)}</div>
        </div>
        <div className="filter-divider" />

        <div className={classes.counterItem}>
          <div className={classes.counterTitle}>Suspended</div>
          <div className={classes.counterValue}>{textFormatter(data?.Suspended)}</div>
        </div>
      </div>
    </Spin>
  );
};
