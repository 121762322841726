import React, { useCallback, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveCustomerLocationUpdateRequestsList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import CustomerUpdateSearch from "pages/customer-management/customerUpdateRequest/CustomerUpdateSearch";
import CustomButton from "components/Common/buttons/CustomButton";
import CustomLink from "components/Common/buttons/CustomLink";
import { customerUpdateLocationListColumns } from "pages/customer-management/customerUpdateLocationRequest/customerUpdateLocationListColumns";
import { STATUSES } from "pages/customer-management/customerUpdateLocationRequest/constants";

const CustomerUpdateLocationRequests = () => {
  const [isLoading, setLoader] = useState(false);
  const [requestList, setRequestList] = useState(null);
  const { setFilters } = useStoreFilters(FILTER_PAGE.CUSTOMER_UPDATE_REQUESTS);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Customer Update Location Requests were not found");
    }
    setRequestList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    retrieveCustomerLocationUpdateRequestsList({
      ...filterOptions,
    })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setRequestList(null);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomLink to={`/customers/create-update-location-request`}>
                <CustomButton
                  icon={<i className="bx bx-plus-circle" />}
                  roles={[USER_ROLES.CUSTOMER_CARE, USER_ROLES.OPERATION_MANAGERS, USER_ROLES.A_SM]}
                  type="primary"
                  text="NEW REQUEST"
                />
              </CustomLink>
            </div>
            <CustomerUpdateSearch statuses={STATUSES} handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.CUSTOMER_UPDATE_REQUESTS}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={customerUpdateLocationListColumns()}
              data={requestList}
              updateList={handleSearch}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default CustomerUpdateLocationRequests;
