import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";

export const boxColumns = () => [
  {
    title: "Meters Box Id",
    dataIndex: "id",
    render: item => textFormatter(item),
    key: "id",
  },
  {
    title: "Team",
    dataIndex: "maintainedBy",
    render: item => textFormatter(item),
    key: "maintainedBy",
  },
  {
    title: "Date Created",
    dataIndex: "maintainedAt",
    render: item => customDateFormatWithTime(item),
    key: "maintainedAt",
  },
];
