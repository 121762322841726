import React, { useCallback, useEffect, useState } from "react";
import { Form, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE } from "utils/constants";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";

const { RangePicker } = DatePicker;

const BoxFilter = ({ handleFilter }) => {
  const [dateRange, setDateRange] = useState(null);
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.METER_BOX);

  useEffect(() => {
    handleFilter({ reportType: "Packaging", location: AUTH_USER?.depotName });
  }, []);

  const onSearch = useCallback(
    values => {
      const { pageSize } = getFilters();
      const normalizedData = { ...values, reportType: "Packaging", location: AUTH_USER?.depotName };
      if (!!dateRange) {
        normalizedData.timeStart = getStartOfTheDay(dateRange[0]);
        normalizedData.timeEnd = getEndOfTheDay(dateRange[1]);
      }
      handleFilter({ ...normalizedData, pageSize });
    },
    [dateRange]
  );

  const disableDate = useCallback(current => current && current.valueOf() > Date.now(), []);

  const onDateRangeChange = useCallback(value => setDateRange(value), []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <RangePicker inputReadOnly={true} onChange={onDateRangeChange} disabledDate={disableDate} />
        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default BoxFilter;
