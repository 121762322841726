import React, { useEffect, useState } from "react";
import { updateMeterSpareParts } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { CardBody } from "reactstrap";
import { Col, Form, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import NGIInput from "components/Common/inputs/NGIInput";
import NGITextArea from "components/Common/inputs/NGITextArea";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { history } from "store";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { getTrimmedObjectValues, userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  formItem: {
    width: 300,
  },
});

const Edit = ({ data, id }) => {
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    list: { sparePartCategory, sparePartShippingMethod },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.SPARE_PART_CATEGORY));
    dispatch(getDictionary(DICTIONARY_TYPES.SPARE_PART_SHIPPING_METHOD));
  }, []);

  const onSave = async data => {
    setLoading(true);
    const formattedData = {
      ...data,
      minimumLeadTime: Number.parseInt(data?.minimumLeadTime),
    };
    setLoading(true);
    await updateMeterSpareParts({ id, params: getTrimmedObjectValues(formattedData) })
      .then(() => {
        setLoading(false);
        successMessage(`Data was saved successfully`);
        history.push("/meters/spare-parts");
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <CardBody className={classes.cardBody}>
      <Spin spinning={isLoading}>
        <Form
          disabled={userHasOneOfRoles([USER_ROLES.IT_OPERATIONS])}
          form={form}
          onFinish={onSave}
          className="sent-commands-page"
        >
          <Col span={24}>
            <Title level={5}>{"Category:"}</Title>
            <Form.Item
              className={classes.formItem}
              name="category"
              rules={[{ required: true, message: "This field can not be empty" }]}
            >
              <NGISelect showSearch id="category" placeholder={"Please select category"}>
                {!!sparePartCategory &&
                  sparePartCategory.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Name:"}</Title>
            <Form.Item
              className={classes.formItem}
              name="name"
              rules={[{ required: true, message: "This field can not be empty" }]}
            >
              <NGIInput id="name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Minimum order quantity:"}</Title>
            <Form.Item
              className={classes.formItem}
              name="minimumOrderQuantity"
              rules={[{ required: true, message: "This field can not be empty" }]}
            >
              <NGIInput id="minimumOrderQuantity" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Minimum lead time:"}</Title>
            <Form.Item
              className={classes.formItem}
              name="minimumLeadTime"
              rules={[{ required: true, message: "This field can not be empty" }]}
            >
              <NGIInput id="minimumLeadTime" type={"number"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Description:"}</Title>
            <Form.Item className={classes.formItem} name="description">
              <NGITextArea id="description" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Specification:"}</Title>
            <Form.Item className={classes.formItem} name="specification">
              <NGIInput id="specification" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Shipping method:"}</Title>
            <Form.Item className={classes.formItem} name="shippingMethod">
              <NGISelect showSearch id="shippingMethod" placeholder={"Please select shipping method"}>
                {!!sparePartShippingMethod &&
                  sparePartShippingMethod.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Comments:"}</Title>
            <Form.Item className={classes.formItem} name="comments">
              <NGITextArea id="comments" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Col>
                <Space>
                  <CustomButton
                    hideRoles={[USER_ROLES.IT_OPERATIONS]}
                    type="primary"
                    htmlType="submit"
                    size="small"
                    text="Save"
                  />
                </Space>
              </Col>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </CardBody>
  );
};

export default Edit;
