import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import React from "react";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import wrenchIcon from "assets/images/svg/Wrench.svg";

export const FAULTY_REASONS = {
  RFID: "RFID",
  REGULATOR: "Regulator",
  LOCK: "Lock",
  VALVE: "Valve",
  KNOB: "Knob",
  BUTTON: "Button",
  FLOW_SENSOR: "Flow Sensor",
  TOP_COVER: "Top Cover",
  BOTTOM_COVER: "Bottom Cover",
  PCB: "PCB",
  LCD_PCB: "LCD PCB",
  CONFIG: "Config",
  FIRMWARE: "Firmware",
  MODEM: "Modem",
  SIM_PORT: "SIM Port",
};

export const FAULTY_REASONS_OPTIONS = {
  [FAULTY_REASONS.RFID]: "RFID",
  [FAULTY_REASONS.REGULATOR]: "Regulator",
  [FAULTY_REASONS.LOCK]: "Lock",
  [FAULTY_REASONS.VALVE]: "Valve",
  [FAULTY_REASONS.KNOB]: "Knob",
  [FAULTY_REASONS.BUTTON]: "Button",
  [FAULTY_REASONS.FLOW_SENSOR]: "Flow Sensor",
  [FAULTY_REASONS.TOP_COVER]: "Top Cover",
  [FAULTY_REASONS.BOTTOM_COVER]: "Bottom Cover",
  [FAULTY_REASONS.PCB]: "PCB",
  [FAULTY_REASONS.LCD_PCB]: "LCD PCB",
  [FAULTY_REASONS.CONFIG]: "Config",
  [FAULTY_REASONS.FIRMWARE]: "Firmware",
  [FAULTY_REASONS.MODEM]: "Modem",
  [FAULTY_REASONS.SIM_PORT]: "SIM Port",
};

export const SEARCH_TYPES = {
  BARCODE: "barcode",
  SERIAL: "serial",
  PCB: "pcb",
  SENSOR: "sensorSerial",
};

export const SEARCH_TYPES_OPTIONS = {
  [SEARCH_TYPES.BARCODE]: "Meter barcode",
  [SEARCH_TYPES.SERIAL]: "Meter serial number",
  [SEARCH_TYPES.PCB]: "PCB serial number",
  [SEARCH_TYPES.SENSOR]: "Sensor serial number",
};

export const REPAIR_RESOLUTION = {
  REPAIRED: "Repaired",
  DECOMMISSION: "Decommission",
};

export const REPAIR_RESOLUTION_OPTIONS = {
  [REPAIR_RESOLUTION.REPAIRED]: "Repaired",
  [REPAIR_RESOLUTION.DECOMMISSION]: "Decommissioned",
};

export const historyListColumns = openFaultyModal => [
  {
    title: "FINDINGS",
    dataIndex: "faultyReasons",
    render: (cellContent, row) => (
      <Tooltip placement="bottom" title={`${cellContent} details`}>
        <CustomLink to={`/meters/maintenance-report/${row.id}`} target="_blank">
          {cellContent}
        </CustomLink>
      </Tooltip>
    ),
  },
  {
    title: "Meter engineer",
    dataIndex: "maintainedBy",
    render: textFormatter,
  },
  {
    title: "COLLECTED DATE",
    dataIndex: "collectedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "INSPECTION DATE",
    dataIndex: "maintainedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    dataIndex: "",
    title: "",
    width: "60px",
    render: row => {
      if (row?.editable) {
        return (
          <ButtonGroup
            tooltipTitle="Edit Faulty Reasons"
            icon={wrenchIcon}
            handleClick={() => {
              openFaultyModal(row?.id);
            }}
          />
        );
      }
      return null;
    },
  },
];
