import React, { useCallback, useState } from "react";
import { Modal, Space, Spin } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import * as xlsx from "xlsx";
import { retrieveBulkProcessResults, startBulkUploadProcess, uploadMetersToS3Bucket } from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage, warnMessage } from "components/Common/responses/message";
import uploadArea from "../../../../assets/images/svg/uploadArea.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import closeIcon from "assets/images/svg/closeSmall.svg";

const useStyles = makeStyles({
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});
const ShipMeterModal = ({ isVisible, onClose }) => {
  const classes = useStyles();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const readUploadFile = useCallback(e => {
    setDataLoading(true);
    const file = e.file;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        const noError = json.every(item => item?.meterBarcode);
        !noError && errorMessage("Please upload the correct ‘*.csv’ file with meterBarcode field");
        if (noError) setError(false);
        else setError(true);

        setData(file);
        setDataLoading(false);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const onRetrieveBulkProcessResults = useCallback(async (options, id) => {
    setTimeout(async () => {
      await retrieveBulkProcessResults(options, id).then(async res => {
        if (!!res?.data?.result?.completedAt) {
          if (res.data.result?.bulkProcessErrors.length > 0) {
            warnMessage(`Unable to upload file. ${res.data.result.bulkProcessErrors[0].desc}`);
            setDataLoading(false);
          } else {
            successMessage("File was loaded successfully");
            setDataLoading(true);
            onClose();
          }
        } else {
          await onRetrieveBulkProcessResults(options, id);
        }
      });
    }, 3000);
  }, []);

  const onSubmit = useCallback(async () => {
    setDataLoading(true);
    const options = { type: "shipped" };

    startBulkUploadProcess(options)
      .then(async res => {
        const {
          data: {
            result: { id, url },
          },
        } = res;
        if (!url) {
          warnMessage("URL was not generated");
        }
        return { url, id };
      })
      .then(({ url, id }) => ({ result: uploadMetersToS3Bucket(url, data), id }))
      .then(async ({ id }) => {
        await onRetrieveBulkProcessResults(options, id);
      })
      .catch(err => {
        setDataLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [data]);

  const removeFile = useCallback(() => {
    setData(null);
    setError(false);
  }, []);

  return (
    <Modal
      title={"Ship Meters from factory to depots"}
      open={isVisible}
      closable={false}
      onCancel={onClose}
      footer={[]}
      onOk={onSubmit}
    >
      <div data-testid={"meters-upload-modal"}>
        <Spin spinning={dataLoading}>
          {data ? (
            <div className={classes.fileContainer}>
              <div className={classes.fileTitle}>{`${data.name}`}</div>
              <div onClick={removeFile} className={classes.removeBtn}>
                <img src={closeIcon} alt="remove" />
              </div>
            </div>
          ) : (
            <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
              <img className="upload-bg" src={uploadArea} alt="upload" />
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibit from uploading company data or other band files
              </p>
            </Dragger>
          )}
          <br />
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton
              onClick={onSubmit}
              disabled={error || !data}
              htmlType="submit"
              type="primary"
              text="Save"
              size="small"
            />
          </Space>
        </Spin>
      </div>
    </Modal>
  );
};

export default ShipMeterModal;
