import React from "react";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import deleteIcon from "assets/images/svg/Delete.svg";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";

const hideRoles = [USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD];

export const templateListColumns = handleRemoveTemplate => [
  {
    title: "NAME",
    dataIndex: "name",
    sort: true,
    render: (cellContent, item) => {
      if (userHasOneOfRoles(hideRoles)) return textFormatter(cellContent);
      return (
        <Tooltip placement="bottom" title={`${cellContent} details/edit`}>
          <CustomLink to={`/meters/registration/template/${item.name}`}>{textFormatter(cellContent)}</CustomLink>
        </Tooltip>
      );
    },
  },
  {
    title: "description",
    dataIndex: "description",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "created by",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
    sort: true,
  },
  {
    title: "created at",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    dataIndex: "",
    title: "",
    width: "60px",
    render: row => (
      <ButtonGroup
        tooltipTitle="Remove template"
        icon={deleteIcon}
        handleClick={() => {
          handleRemoveTemplate(row.name);
        }}
      />
    ),
  },
];
