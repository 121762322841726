import React from "react";
import { CONDITIONS, FILTER_STATUS } from "./variables";
import { textFormatter } from "components/Common/formatter";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import uploadIcon from "assets/images/svg/UploadToCloud.svg";
import downloadIcon from "assets/images/svg/DownloadFromCloud.svg";
import factoryModeIcon from "assets/images/svg/FactoryMode.svg";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import wrenchIcon from "assets/images/svg/Wrench.svg";
import editIcon from "assets/images/svg/Edit.svg";
import updateIcon from "assets/images/svg/firmware-update.svg";
import { USER_ROLES } from "utils/constants";

const color = "#444444";
export const meterListColumns = ({
  onUploadFile,
  onDownloadFile,
  onFactoryTest,
  onFaultyModal,
  onChangeMeterTypeModal,
  onUpdateFirmwareModal,
}) => [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: (cellContent, row) => (
      <Tooltip placement="bottom" title={`${cellContent} details`} color={color}>
        <div>
          <CustomLink to={`meter/${row.id}`}>{cellContent}</CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    render: cellContent => textFormatter(cellContent),
  },
  {
    dataIndex: "customerId",
    title: "Customer ID",
    render: id => (
      <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
        {id}
      </CustomLink>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Condition",
    dataIndex: "condition",
    render: cellContent => textFormatter(cellContent),
  },
  {
    dataIndex: "",
    title: "",
    width: "60px",
    render: row => (
      <ButtonGroupContainer>
        <ButtonGroup
          tooltipTitle="Upload Log"
          icon={uploadIcon}
          hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP, USER_ROLES.FINANCE]}
          handleClick={() => {
            onUploadFile(row.id);
          }}
        />
        <ButtonGroup
          tooltipTitle="Download Log"
          hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP, USER_ROLES.FINANCE]}
          icon={downloadIcon}
          handleClick={() => {
            onDownloadFile(row.id);
          }}
        />
        {row.condition === CONDITIONS.NEW && (
          <ButtonGroup
            hideRoles={[USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD]}
            tooltipTitle="Factory Test"
            icon={factoryModeIcon}
            handleClick={() => {
              onFactoryTest(row);
            }}
          />
        )}
        {row.status === FILTER_STATUS.INACTIVE && (
          <>
            <ButtonGroup
              hideRoles={[USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD]}
              tooltipTitle="Mark as Faulty"
              icon={wrenchIcon}
              handleClick={() => {
                onFaultyModal(row);
              }}
            />
            <ButtonGroup
              hideRoles={[USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD]}
              tooltipTitle="Edit"
              icon={editIcon}
              handleClick={() => {
                onChangeMeterTypeModal(row);
              }}
            />
          </>
        )}
        {(row.status === FILTER_STATUS.INACTIVE || row.status === FILTER_STATUS.ACTIVE) && (
          <ButtonGroup
            roles={[
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
            ]}
            tooltipTitle="Update Firmware"
            icon={updateIcon}
            handleClick={() => {
              onUpdateFirmwareModal(row);
            }}
          />
        )}
      </ButtonGroupContainer>
    ),
  },
];
