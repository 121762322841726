import React from "react";
import { Redirect, Route } from "react-router-dom";
import UserProfile from "../pages/authentication/user-profile";
import Login from "../pages/authentication/Login";
import Logout from "../pages/authentication/Logout";
import { Dashboard } from "../pages/dashboard/index";
import AssetTypes from "../pages/warehouse/configurations/types/index";
import RetrieveAssets from "pages/warehouse/assets/RetrieveAssets";
import RetrieveTasks from "../pages/operations/tasks/retrieveTasks";
import RetrieveRoutineTasks from "../pages/operations/routines/retrieveRoutineTasks";
import RetrieveAccesories from "../pages/meter-management/configuration/accessory/retrieveAccessories";
import Firmware from "../pages/meter-management/configuration/firmware";
import MeterInformation from "../pages/meter-management/meters/MeterInformation";
import SimCards from "../pages/meter-management/configuration/simCards/simCards";
import CookingSession from "../pages/meter-management/logs/reports/csrp";
import RetrieveMeters from "../pages/meter-management/meter-status/retrieveMeters";
import meterConfig from "../pages/meter-management/configuration/meters/meterInformation";
import OutgoingLogs from "pages/meter-management/logs/outgoingLogs/index";
import Users from "pages/settings/users/users";
import Requests from "pages/POS/request/requests";
import Customers from "pages/customer-management/customers/Customers";
import Packages from "pages/packages/all-packages/Packages";
import CustomerDetails from "pages/customer-management/customerDetails/CustomerDetails";
import Leads from "pages/operations/leads/Leads";
import ReadyForRepair from "pages/meter-management/ready-for-repair/ReadyForRepair";
import LeadDetails from "pages/operations/leadDetails/LeadDetails";
import RoutineDetails from "pages/operations/routineDetails/RoutineDetails";
import TaskDetails from "pages/operations/taskDetails/TaskDetails";
import AwaitingTestingMeters from "pages/meter-management/awaitingTestingMeters/AwaitingTestingMeters";
import MeterSpareParts from "pages/meter-management/meter-spare-parts/MeterSpareParts";
import NewSparePart from "pages/meter-management/meter-spare-parts/newSparePart/NewSparePart";
import EditSpareParts from "pages/meter-management/meter-spare-parts/editSparePart/EditSpareParts";
import MeterDetails from "pages/meter-management/meters/meterDetails/MeterDetails";
import MaintenanceReports from "pages/meter-management/maintenanceReports/MaintenanceReports";
import OutgoingLogsDetails from "pages/meter-management/logs/outgoingLogs/outgoingLogsDetails/OutgoingLogsDetails";
import PackageDetails from "pages/packages/all-packages/packageDetails/PackageDetails";
import Depots from "pages/depot-management/depots/Depots";
import DepotDetails from "pages/depot-management/depotDetails/DepotDetails";
import AssetDetails from "pages/warehouse/assets/assetDetails/AssetDetails";
import UnprocessedPayments from "pages/payments/unprocessed-payments/UnprocessedPayments";
import NewPackage from "pages/packages/all-packages/newPackage/NewPackage";
import FailedPayments from "pages/payments/failed-payments/FailedPayments";
import UploadTemplates from "pages/meter-management/upload-templates/UploadTemplates";
import NewTemplate from "pages/meter-management/upload-templates/newTemplate/NewTemplate";
import AddManualMeter from "pages/meter-management/upload-templates/addManualMeter/AddManualMeter";
import Teams from "pages/depot-management/teams/Teams";
import TeamDetails from "pages/depot-management/teams/teamDetails/TeamDetails";
import StoveRepairPage from "pages/warehouse/stoveRepair/StoveRepairPage";
import CookingSessionDetails from "pages/meter-management/logs/reports/csrp/cookingSessionDetails/CookingSessionDetails";
import TaskRequests from "pages/operations/task-requests/TaskRequests";
import RefundRequestPage from "pages/operations/refundReports/RefundRequestPage";
import TaskArchive from "pages/operations/taskArchive/TaskArchive";
import SendAdmo from "pages/customer-management/sendAdmo/SendAdmo";
import ToMarketReports from "pages/cylinder-weight-reports/reports/ToMarketReports";
import NoAccess from "components/Common/NoAccess";
import CylinderRepairPage from "pages/warehouse/сylinderRepair/CylinderRepairPage";
import FromMarketReports from "pages/cylinder-weight-reports/reports/FromMarketReports";
import FaultyReasons from "pages/meter-management/faulty-reasons/FaultyReasons";
import AwaitingConfigMeters from "pages/meter-management/awaitingConfigurationMeters/AwaitingConfigMeters";
import ValidationTaskRequests from "pages/operations/validation-task-requests/ValidationTaskRequests";
import ValidationTaskRequestDetails from "pages/operations/validationTaskRequestDetails/ValidationTaskRequestDetails";
import RetrieveAssetRequestHistory from "pages/warehouse/assetRequestHistory/RetrieveAssetRequestHistory";
import CreateTask from "pages/operations/createTask/CreateTask";
import MeterBox from "pages/meter-management/meterBox/MeterBox";
import ReportDetails from "pages/meter-management/maintenanceReports/maintanceReportDetails/ReportDetails";
import SMSProvider from "pages/SMS-management/SMS-provider/SMSProvider";
import TAMRequests from "pages/TAM/TAMRequests/TAMRequests";
import CustomerUpdateRequests from "pages/customer-management/customerUpdateRequest/CustomerUpdateRequests";
import RepossessedCustomers from "pages/customer-management/repossessedCustomers/RepossessedCustomers";
import SuspendedCustomers from "pages/customer-management/suspendedCustomers/SuspendedCustomers";
import CreateCustomerDetailsUpdateRequest from "pages/customer-management/customerUpdateRequest/createCustomerDetailsUpdaterequest/CreateCustomerDetailsUpdateRequest";
import SegmentManagementPage from "pages/price-management/PriceManagement/SegmentManagementPage";
import SegmentDetails from "pages/price-management/PriceManagement/priceDetails/SegmentDetails";
import EditSegment from "pages/price-management/PriceManagement/editSegment/EditSegment";
import STOList from "pages/stock-management/stockTransferOrders/STOList";
import TripList from "pages/stock-management/trips/TripList";
import NewTrip from "pages/stock-management/trips/newTrip/NewTrip";
import TripDetails from "pages/stock-management/trips/tripDetails/TripDetails";
import SimCardsReports from "pages/meter-management/configuration/simCardsReports/SimCardsReports";
import CustomerUpdateLocationRequests from "pages/customer-management/customerUpdateLocationRequest/CustomerUpdateLocationRequests";
import CreateCustomerLocationUpdateRequest from "pages/customer-management/customerUpdateLocationRequest/createCustomerLocationUpdateRequest/CreateCustomerLocationUpdateRequest";
import TasksInvestigation from "pages/operations/tasksInvestigation/TasksInvestigation";
import SurveyList from "pages/settings/survey-management/surveys/SurveyList";
import SurveyDetails from "pages/settings/survey-management/surveyDetails/SurveyDetails";
import VehiclesList from "pages/transport-management/vehicles/VehiclesList";
import CampaignDetails from "pages/settings/campaign-management/campaignDetails/CampaignDetails";
import CampaignList from "pages/settings/campaign-management/campaignList/CampaignList";
import ContractVersions from "pages/settings/contract-versions/versionList/ContractVersions";
import ContractVersionDetails from "pages/settings/contract-versions/versionCreate/ContractVersionDetails";
import RepossessionReasons from "pages/operations/repossessionReasons/RepossessionReasons";
import SMSBulk from "pages/SMS-management/SMS-bulk/SMSBulk";
import ProspectDetails from "pages/operations/prospectDetails/ProspectDetails";
import BulkSMSDetails from "pages/SMS-management/SMS-bulk/BulkSMSDetails/BulkSMSDetails";
import Prospects from "pages/operations/prospects/Prospects";
import ProspectInfo from "pages/operations/prospectInfo/ProspectInfo";
import MarketingInitiatives from "pages/marketing/marketingInitiatives/MarketingInitiatives";
import InitiativeDetails from "pages/marketing/initiativeDetails/InitiativeDetails";
import CancelledTasks from "pages/operations/cancelledTasks/CancelledTasks";
import MeterChangeReasons from "pages/operations/meterChangeReasons/MeterChangeReasons";
import FirmwareUpdateProcesses from "pages/meter-management/firmware/firmwareUpdateProcesses/FirmwareUpdateProcesses";
import MetersFirmwareUpdateHistory from "pages/meter-management/firmware/metersFirmwareUpdateHistory/MetersFirmwareUpdateHistory";
import UserList from "pages/user-management/users/Users";
import UserDetails from "pages/user-management/userDetails/UserDetails";
import DepotTeams from "pages/depot-management/depots/DepotTeams";
import MeterChangeDeclineReasons from "pages/operations/meterChangeDeclineReasons/MeterChangeDeclineReasons";
import EditTrip from "pages/stock-management/trips/editTrip/EditTrip";
// import LoadingBaysList from "pages/stock-management/loadingBays/LoadingBaysList";
import NotAssignedLeads from "pages/operations/notAssignedLeads/NotAssignedLeads";

const userRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
  },
  {
    name: "Profile",
    path: "/profile",
    component: UserProfile,
  },
  {
    name: "No Access",
    path: "/no-access",
    component: NoAccess,
  },
  {
    subSections: ["Settings"],
    name: "Asset Types",
    path: "/settings/types",
    component: AssetTypes,
  },
  {
    subSections: ["Warehouse"],
    name: "All Assets",
    path: "/warehouse/assets",
    component: RetrieveAssets,
  },
  {
    subSections: ["SMS Management"],
    name: "SMS Provider",
    path: "/sms/sms-provider/",
    component: SMSProvider,
  },
  {
    subSections: ["SMS Management"],
    name: "SMS Bulk",
    path: "/sms/sms-bulk/",
    component: SMSBulk,
  },
  {
    subSections: ["SMS Management"],
    name: "Details",
    path: "/sms/sms-bulk/:id",
    component: BulkSMSDetails,
  },
  {
    subSections: ["Warehouse"],
    name: "Asset Request History",
    path: "/warehouse/asset-request",
    component: RetrieveAssetRequestHistory,
  },
  {
    subSections: ["Warehouse"],
    name: "Stove Repair",
    path: "/warehouse/repair",
    component: StoveRepairPage,
  },
  {
    subSections: ["Warehouse"],
    name: "Cylinder Repair",
    path: "/warehouse/cylinder-repair",
    component: CylinderRepairPage,
  },
  {
    subSections: ["Warehouse"],
    name: "Details",
    path: "/warehouse/assets/:id",
    component: AssetDetails,
  },
  {
    subSections: ["Operations", "All Tasks"],
    name: "Tasks List",
    path: "/operations/tasks/list",
    component: RetrieveTasks,
  },
  {
    subSections: ["Operations", "All Tasks"],
    name: "Cancelled Tasks",
    path: "/operations/tasks/cancelled",
    component: CancelledTasks,
  },
  {
    subSections: ["Operations", "All Tasks"],
    name: "Task Archive",
    path: "/operations/tasks/archive",
    component: TaskArchive,
  },
  {
    subSections: ["Operations", "All Tasks"],
    name: "Investigations",
    path: "/operations/tasks/investigations",
    component: TasksInvestigation,
  },
  {
    subSections: ["Operations"],
    name: "Task Requests",
    path: "/operations/task-requests",
    component: TaskRequests,
  },
  {
    subSections: ["Operations", "All Tasks", "Tasks List"],
    name: "Details",
    path: "/operations/create-task",
    component: CreateTask,
  },
  {
    subSections: ["Warehouse"],
    name: "Asset Validation",
    path: "/warehouse/asset-validation",
    component: ValidationTaskRequests,
  },
  {
    subSections: ["Warehouse"],
    name: "Details",
    path: "/warehouse/asset-validation/:id",
    component: ValidationTaskRequestDetails,
  },
  {
    subSections: ["Operations", "All Routines"],
    name: "Opened Routines",
    path: "/operations/routines/open",
    component: RetrieveRoutineTasks,
  },
  {
    subSections: ["Operations", "All Routines"],
    name: "Closed Routines",
    path: "/operations/routines/close",
    component: RetrieveRoutineTasks,
  },
  {
    subSections: ["Operations", "All Routines"],
    name: "Suspended Routines",
    path: "/operations/routines/suspended",
    component: RetrieveRoutineTasks,
  },
  {
    subSections: ["Operations", "All Leads"],
    name: "Leads List",
    path: "/operations/leads",
    component: Leads,
  },
  {
    subSections: ["Operations", "All Leads"],
    name: "Not Assigned Leads",
    path: "/operations/not-assigned",
    component: NotAssignedLeads,
  },
  {
    subSections: ["Operations"],
    name: "All Prospects",
    path: "/operations/prospects",
    component: Prospects,
  },
  {
    subSections: ["Operations"],
    name: "Details",
    path: "/operations/prospects/new",
    component: ProspectDetails,
  },
  {
    subSections: ["Operations", "All Prospects"],
    name: "Details",
    path: "/operations/prospect/:id",
    component: ProspectInfo,
  },
  {
    subSections: ["Operations"],
    name: "Refund Requests",
    path: "/operations/refund",
    component: RefundRequestPage,
  },
  {
    name: "Users",
    path: "/settings/users",
    component: Users,
  },
  {
    subSections: ["Settings"],
    name: "Survey Management",
    path: "/settings/surveys",
    component: SurveyList,
  },
  {
    subSections: ["Settings", "Survey Management"],
    name: "Details",
    path: "/settings/survey/:id",
    component: SurveyDetails,
  },
  {
    subSections: ["Settings"],
    name: "Campaign Management",
    path: "/settings/campaigns",
    component: CampaignList,
  },
  {
    subSections: ["Settings"],
    name: "Contract Versions",
    path: "/settings/contract-versions",
    component: ContractVersions,
  },
  {
    subSections: ["Settings", "Contract Versions"],
    name: "Details",
    path: "/settings/contract-version/new",
    component: ContractVersionDetails,
  },
  {
    subSections: ["Settings", "Contract Versions"],
    name: "Details",
    path: "/settings/contract-version/:version",
    component: ContractVersionDetails,
  },
  {
    subSections: ["Settings", "Campaign Management"],
    name: "Details",
    path: "/settings/campaign/:id",
    component: CampaignDetails,
  },
  {
    subSections: ["Price Management"],
    name: "All Segments",
    path: "/price-management/segments",
    component: SegmentManagementPage,
  },
  {
    subSections: ["Price Management", "All Prices"],
    name: "Details",
    path: "/price-management/segment/:id",
    component: EditSegment,
  },
  {
    subSections: ["Price Management", "All Prices"],
    name: "Details",
    path: "/price-management/view/:id",
    component: SegmentDetails,
  },
  {
    subSections: ["Customer Management", "Customer Update Requests"],
    name: "Update Details Requests",
    path: "/customers/update-requests",
    component: CustomerUpdateRequests,
  },
  {
    subSections: ["Customer Management", "Customer Update Requests"],
    name: "Update Location Request",
    path: "/customers/update-location-requests",
    component: CustomerUpdateLocationRequests,
  },
  {
    subSections: ["Customer Management", "Customer Update Requests", "Update Details Requests"],
    name: "Details",
    path: "/customers/create-update-request",
    component: CreateCustomerDetailsUpdateRequest,
  },
  {
    subSections: ["Customer Management", "Customer Update Requests", "Update Location Request"],
    name: "Details",
    path: "/customers/create-update-location-request/",
    component: CreateCustomerLocationUpdateRequest,
  },
  {
    subSections: ["Customer Management", "Customer Update Requests", "Update Location Request"],
    name: "Details",
    path: "/customers/update-location-request/:id",
    component: CreateCustomerLocationUpdateRequest,
  },
  {
    subSections: ["Customer Management", "Customer Update Requests", "Update Details Requests"],
    name: "Details",
    path: "/customers/update-request/:id",
    component: CreateCustomerDetailsUpdateRequest,
  },
  {
    subSections: ["Customer Management", "Payments"],
    name: "Unprocessed Payments",
    path: "/customers/payments/unprocessed",
    component: UnprocessedPayments,
  },
  {
    subSections: ["Customer Management", "Payments"],
    name: "Failed Payments",
    path: "/customers/payments/failed",
    component: FailedPayments,
  },
  { path: "/pos/request_pg/", component: Requests },
  {
    name: "All Meters",
    subSections: ["Meter Management"],
    path: "/meters/meters",
    component: MeterInformation,
  },
  // {
  //   subSections: ["Meters"],
  //   name: "Awaiting Process",
  //   path: "/meters/awaiting",
  //   component: AwaitingTestingMeters,
  // },
  {
    subSections: ["Meter Management", "Ready For Repair"],
    name: "Meters",
    path: "/meters/ready-for-repair/meters",
    component: ReadyForRepair,
  },
  {
    subSections: ["Meter Management", "Ready For Repair"],
    name: "Faulty Reasons",
    path: "/meters/ready-for-repair/faulty-reasons",
    component: FaultyReasons,
  },
  {
    subSections: ["Meter Management", "Awaiting Process"],
    name: "Awaiting Testing",
    path: "/meters/awaiting/testing",
    component: AwaitingTestingMeters,
  },
  {
    subSections: ["Meter Management", "Awaiting Process"],
    name: "Awaiting Configuration",
    path: "/meters/awaiting/configuration",
    component: AwaitingConfigMeters,
  },
  {
    subSections: ["Meter Management"],
    name: "Maintenance Reports",
    path: "/meters/maintenance-reports",
    component: MaintenanceReports,
  },
  {
    name: "Details",
    subSections: ["Meter Management", "Maintenance Reports"],
    path: "/meters/maintenance-report/:id",
    component: ReportDetails,
  },
  {
    subSections: ["Meter Management"],
    name: "Meter Spare Parts",
    path: "/meters/spare-parts",
    component: MeterSpareParts,
  },
  {
    subSections: ["Meter Management"],
    name: "Meter Box and Send",
    path: "/meters/box",
    component: MeterBox,
  },
  {
    name: "Details",
    subSections: ["Meter Management", "Meter Spare Parts"],
    path: "/meters/spare-part/:id",
    component: EditSpareParts,
  },
  {
    subSections: ["Meter Management", "Meter Spare Parts"],
    name: "Details",
    path: "/meters/add-spare-part/",
    component: NewSparePart,
  },
  {
    subSections: ["Meter Management", "Sim Cards"],
    name: "Sim Cards Uploading",
    path: "/meters/sim-cards/uploading",
    component: SimCards,
  },
  {
    subSections: ["Meter Management", "Sim Cards"],
    name: "Sim Cards Reports",
    path: "/meters/sim-card/reports",
    component: SimCardsReports,
  },
  {
    subSections: ["Meter Management", "Configuration"],
    name: "Accessory",
    path: "/meters/configuration/accessory",
    component: RetrieveAccesories,
  },
  {
    subSections: ["Meter Management", "Configuration"],
    name: "Firmware",
    path: "/meters/configuration/firmwares",
    component: Firmware,
  },
  {
    subSections: ["Meter Management", "Configuration"],
    name: "Firmware Update Processes",
    path: "/meters/configuration/update-processes",
    component: FirmwareUpdateProcesses,
  },
  {
    subSections: ["Meter Management", "Configuration"],
    name: "Meters Firmware Update History",
    path: "/meters/configuration/firmware-update-history",
    component: MetersFirmwareUpdateHistory,
  },
  {
    subSections: ["Meter Management", "Logs"],
    name: "Cooking Sessions",
    path: "/meters/logs/session",
    component: CookingSession,
  },
  {
    subSections: ["Meter Management", "Logs", "Cooking Sessions"],
    name: "Details",
    path: "/meters/logs/session-details/:barcode/:number",
    component: CookingSessionDetails,
  },
  {
    subSections: ["Meter Management", "Jobs"],
    name: "Outgoing Logs",
    path: "/meters/jobs/logs",
    component: OutgoingLogs,
  },
  {
    subSections: ["Meter Management", "Jobs"],
    name: "Details",
    path: "/meters/jobs/logs/:id",
    component: OutgoingLogsDetails,
  },
  { path: "/meters/configuration/meters", component: meterConfig },
  {
    subSections: ["Meter Management", "Registration"],
    name: "Templates",
    path: "/meters/registration/templates",
    component: UploadTemplates,
  },
  {
    subSections: ["Meter Management", "Registration"],
    name: "Add meter manually",
    path: "/meters/registration/manual",
    component: AddManualMeter,
  },
  {
    subSections: ["Meter Management", "Registration", "Templates"],
    name: "Details",
    path: "/meters/registration/template/:name",
    component: NewTemplate,
  },
  { path: "/meters/meter-status/RetrieveMeters", component: RetrieveMeters },
  {
    subSections: ["Customer Management", "All Customers"],
    name: "Active Customers",
    path: "/customers/customers/active",
    component: Customers,
  },
  {
    subSections: ["Customer Management", "All Customers"],
    name: "Repossessed Customers",
    path: "/customers/customers/repossessed",
    component: RepossessedCustomers,
  },
  {
    subSections: ["Customer Management", "All Customers"],
    name: "Suspended Customers",
    path: "/customers/customers/suspended",
    component: SuspendedCustomers,
  },
  {
    subSections: ["Customer Management"],
    name: "Send ADMO",
    path: "/customers/send-admo",
    component: SendAdmo,
  },
  {
    subSections: ["Depot Management"],
    name: "All Depots",
    path: "/depots/list",
    component: Depots,
  },
  {
    subSections: ["Depot Management"],
    name: "All Teams",
    path: "/depots/teams",
    component: Teams,
  },
  {
    subSections: ["Depot Management"],
    name: "All Teams",
    path: "/depot/teams",
    component: DepotTeams,
  },
  {
    subSections: ["Depot Management", "All Teams"],
    name: "Details",
    path: "/depots/team/:id",
    component: TeamDetails,
  },
  {
    subSections: ["Depot Management", "All Depots"],
    name: "Details",
    path: "/depots/depot",
    component: DepotDetails,
  },
  {
    subSections: ["Package Management"],
    name: "All Packages",
    path: "/packages/list",
    component: Packages,
  },
  {
    subSections: ["Package Management", "All Packages"],
    name: "Details",
    path: "/packages/package",
    component: NewPackage,
  },
  {
    subSections: ["Package Management", "All Packages"],
    name: "Details",
    path: "/packages/:id",
    component: PackageDetails,
  },
  {
    subSections: ["Customer Management", "All Customers", "Customer"],
    name: "Details",
    path: "/customers/customer/:id",
    component: CustomerDetails,
  },
  {
    name: "Details",
    subSections: ["Meter Management", "All Meters"],
    path: "/meters/meter/:id",
    component: MeterDetails,
  },
  {
    subSections: ["Operations", "All Leads"],
    name: "Details",
    path: "/operations/lead/:id",
    component: LeadDetails,
  },
  {
    subSections: ["Operations", "All Routines", "Routines"],
    name: "Details",
    path: "/operations/routine/:id",
    component: RoutineDetails,
  },
  {
    subSections: ["Operations", "All Tasks", "Tasks List"],
    name: "Details",
    path: "/operations/task/:id",
    component: TaskDetails,
  },
  {
    subSections: ["Operations", "Task Faulty Reasons"],
    name: "Repossession Reasons",
    path: "/operations/repossession-reasons",
    component: RepossessionReasons,
  },
  {
    subSections: ["Operations", "Task Faulty Reasons", "Meter Change Reasons"],
    name: "Create/Approve/Execute",
    path: "/operations/meter-change-reasons",
    component: MeterChangeReasons,
  },
  {
    subSections: ["Operations", "Task Faulty Reasons", "Meter Change Reasons"],
    name: "Decline Requests",
    path: "/operations/meter-change-decline-reasons",
    component: MeterChangeDeclineReasons,
  },
  {
    subSections: ["TAM"],
    name: "TAM Requests",
    path: "/tam/requests",
    component: TAMRequests,
  },
  {
    subSections: ["Cylinder Weight Reports"],
    name: "To Market Reports",
    path: "/cylinder-weight-reports/to-market-reports",
    component: ToMarketReports,
  },
  {
    subSections: ["Cylinder Weight Reports"],
    name: "From Market Reports",
    path: "/cylinder-weight-reports/from-market-reports",
    component: FromMarketReports,
  },
  {
    subSections: ["Stock Management"],
    name: "All Stock Transfer Orders",
    path: "/stock-management/all",
    component: STOList,
  },
  {
    subSections: ["Stock Management"],
    name: "All Trips",
    path: "/stock-management/trips",
    component: TripList,
  },
  {
    subSections: ["Stock Management"],
    name: "Details",
    path: "/stock-management/trips/new",
    component: NewTrip,
  },
  {
    subSections: ["Stock Management", "All Trips"],
    name: "Edit",
    path: "/stock-management/trip/:id",
    component: EditTrip,
  },
  {
    subSections: ["Stock Management"],
    name: "Details",
    path: "/stock-management/trips/:id",
    component: TripDetails,
  },
  // {
  //   subSections: ["Stock Management"],
  //   name: "Loading Bays",
  //   path: "/stock-management/loading-bays",
  //   component: LoadingBaysList,
  // },
  {
    subSections: ["Transport Management"],
    name: "All Vehicles",
    path: "/transport-management/all-vehicles",
    component: VehiclesList,
  },
  {
    subSections: ["Marketing"],
    name: "Marketing Initiatives",
    path: "/marketing/initiatives",
    component: MarketingInitiatives,
  },
  {
    subSections: ["Marketing", "Marketing Initiatives"],
    name: "Details",
    path: "/marketing/initiative/:id",
    component: InitiativeDetails,
  },
  {
    subSections: ["User Management"],
    name: "All Users",
    path: "/users/list",
    component: UserList,
  },
  {
    subSections: ["User Management", "All Users"],
    name: "Details",
    path: "/user/:email",
    component: UserDetails,
  },
  {
    path: "/",
    exact: true,
    component: () => (
      <Route
        render={({ location }) => {
          if (location.hash[0] === "#") {
            return <Redirect to={location.hash.substring(1)} />;
          }
          return <Redirect to={"/dashboard"} />;
        }}
      />
    ),
  },
];

const authRoutes = () => [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
];

export { userRoutes, authRoutes };
