import React, { useCallback, useEffect, useState } from "react";
import { Calendar, Divider, Modal, Space, Spin } from "antd";
import { retrieveCustomerAvailabilityCalendar } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment/moment";
import { formatTime, taskSourceFormatter } from "pages/dashboard/utils";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  modal: {
    maxWidth: "1000px!important",
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  badge: {
    color: "white",
    backgroundColor: "#0085FF",
    fontSize: 10,
    borderRadius: 3,
    padding: "1px 6px",
    position: "absolute",
  },
  infoContainer: {
    display: "flex",
  },
  calendar: {
    width: "50%",
    paddingRight: 20,
    borderRight: "1px solid #0000000D",

    "& .ant-picker-calendar-date-content": {
      height: "25px!important",
    },
  },
  details: {
    marginLeft: 20,
    width: "50%",
  },
  customersList: {
    maxHeight: 420,
    overflow: "auto",
  },
  customerItem: {
    borderBottom: "1px solid #0000000D",
    padding: "15px 10px 15px 0",
  },
  customerNameRow: {
    display: "flex",
    justifyContent: "space-between",

    "&>div": {
      fontWeight: 600,
    },
    "&>span": {
      color: "#0085FF",
      fontWeight: 400,
    },
  },
  taskList: {
    display: "flex",
    justifyContent: "flex-end",
  },
  source: {
    border: "1px solid black",
    fontWeight: 600,
    fontSize: 10,
    textTransform: "uppercase",
    paddingInline: 6,
    borderRadius: 5,
  },
  row: {
    display: "flex",
    marginTop: 5,
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const CustomerAvailabilityCalendarModal = ({ type, activeItem, onClose }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    setIsLoading(true);
    retrieveCustomerAvailabilityCalendar({
      [type.toLowerCase()]: activeItem,
    })
      .then(res => {
        const { schedule } = res.data.result;
        setData(schedule);
        setSelectedDate(schedule[moment().format("YYYY-MM-DD")]);

        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      });
  }, []);

  const onSelect = useCallback(
    value => {
      setSelectedDate(data[value.format("YYYY-MM-DD")]);
    },
    [data]
  );

  const dateCellRender = useCallback(
    value => {
      const dayData = data[value.format("YYYY-MM-DD")];
      return dayData ? <div className={classes.badge}>{dayData?.customers?.length}</div> : null;
    },
    [data]
  );

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            {activeItem}
            <span>Future Availability</span>
          </div>
        </div>
      }
      className={classes.modal}
      open
      closable={false}
      onCancel={onClose}
      footer={[]}
      onOk={onClose}
    >
      <Spin spinning={isLoading}>
        <div className={classes.infoContainer}>
          <Calendar onSelect={onSelect} className={classes.calendar} dateCellRender={dateCellRender} />
          <div className={classes.details}>
            <div className={classes.customersList}>
              {selectedDate?.customers?.map(item => (
                <div className={classes.customerItem} key={item.id}>
                  <div className={classes.customerNameRow}>
                    <NGITooltip title={item.id}>
                      {item.name} (
                      <CustomLink underlined target="_blank" to={`/customers/customer/${item.id}`}>
                        {item.id.length > 7 ? `${item.id.substring(0, 7)}...` : item.id}
                      </CustomLink>
                      )
                    </NGITooltip>
                    <span>{`${formatTime(item.availableFrom)}-${formatTime(item.availableTo)}`}</span>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.tsr}>{item?.technician}</div>
                    <div className={classes.taskList}>
                      {item?.tasks?.map(task => (
                        <div className={classes.source} key={task.id}>
                          {type === "Category" ? taskSourceFormatter(task) : task.category}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Divider />

        <Space>
          <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Close" />
        </Space>
      </Spin>
    </Modal>
  );
};

export default CustomerAvailabilityCalendarModal;
