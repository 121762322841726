import React, { useCallback, useEffect, useState } from "react";
import { Col, Container } from "reactstrap";
import MetaData from "components/VerticalLayout/MetaData";
import { retrieveMeterSpareParts } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { partsListColumns } from "pages/meter-management/meter-spare-parts/partsListColumns";
import { setActiveSparePart } from "store/meter-management/meters/actions";
import CategoriesModal from "pages/meter-management/meter-spare-parts/CategoriesModal";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import addIcon from "assets/images/svg/plus-circle-white.svg";
import { AppstoreOutlined } from "@ant-design/icons";

const MeterSpareParts = () => {
  const [isLoading, setLoader] = useState(false);
  const [partsList, setPartsList] = useState(null);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const dispatch = useDispatch();

  const closeCategoriesModal = useCallback(() => setShowCategoriesModal(false), []);
  const openCategoriesModal = useCallback(() => setShowCategoriesModal(true), []);

  useEffect(() => {
    handleFilter();
  }, []);

  const handleItemClick = useCallback(data => dispatch(setActiveSparePart(data)), []);

  const handleFilter = async (filterOptions = {}) => {
    setLoader(true);
    await retrieveMeterSpareParts(AUTH_USER?.depotName)
      .then(async res => {
        const { result } = res?.data;
        if (!result) {
          warnMessage(`No ${!!filterOptions.status ? filterOptions.status : ""} spare parts were found`);
        }
        setPartsList({ result: { items: result } });
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <div className="page-content">
      <MetaData pageTitle={"Meter Spare Parts"} />
      <Container fluid>
        <div className="filters-wrapper">
          <div className="filter-buttons">
            <ButtonGroupContainer color={"primary"}>
              <ButtonGroup
                hideRoles={[
                  USER_ROLES.INTERNAL_AUDITOR,
                  USER_ROLES.GENERAL,
                  USER_ROLES.PRODUCT_TECHNICIANS,
                  USER_ROLES.IT_OPERATIONS,
                  USER_ROLES.FINANCE,
                  USER_ROLES.METER_ENGINEER,
                  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                ]}
                color={"primary"}
                link="add-spare-part"
                tooltipTitle="New Spare Part"
                icon={addIcon}
              />
              <ButtonGroup
                color={"primary"}
                handleClick={openCategoriesModal}
                hideRoles={[
                  USER_ROLES.INTERNAL_AUDITOR,
                  USER_ROLES.GENERAL,
                  USER_ROLES.PRODUCT_TECHNICIANS,
                  USER_ROLES.IT_OPERATIONS,
                  USER_ROLES.FINANCE,
                  USER_ROLES.METER_ENGINEER,
                  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                ]}
                tooltipTitle="Edit Categories"
              >
                <AppstoreOutlined />
              </ButtonGroup>
            </ButtonGroupContainer>
          </div>
        </div>
        <Col lg={12}>
          <NGITable isLoading={isLoading} columns={partsListColumns(handleItemClick)} data={partsList} />
        </Col>
        {showCategoriesModal && <CategoriesModal onClose={closeCategoriesModal} isOpen={showCategoriesModal} />}
      </Container>
    </div>
  );
};

export default MeterSpareParts;
