import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { otherAssetListColumns } from "../../warehouse/assets/assetListColumns";
import B2cTable from "components/Common/table";
import MetersFilter from "components/Common/filters/meters-management/metersFilter";
import { retrieveAllAssets, retrieveDictionaries } from "utils/api";
import { successMessage, defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { globalSearch, normalAssetCallBack, searchAssetCallBack } from "components/Common/formatter";
import NGISpinner from "components/Common/NGISpinner";
import { AUTH_USER } from "utils/constants";

const RetrieveMeters = () => {
  const [isLoading, setLoader] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [currentFilterOptions, setCurrentFilterOptions] = useState({});
  const [dataEndCursor, setDataEndCursor] = useState(null);
  const [dataStartCursor, setDataStartCursor] = useState(null);
  const [dataHasNextPage, setDataHasNextPage] = useState(false);
  const [dataHasPreviousPage, setDataHasPreviousPage] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [assetTypeList, setAssetTypeList] = useState([]);

  const handleTaskState = async () => {
    retrieveDictionaries("assetState").then(res => {
      const arr = res.data.result.items;
      if (arr.length > 0) {
        setStateList(arr);
      } else {
        warnMessage(`No asset states are available`);
      }
    });
  };

  const handleAssetTypes = async () => {
    retrieveDictionaries("assetTypeGroup").then(res => {
      const arr = res.data.result.items;
      if (arr.length > 0) {
        setAssetTypeList(arr);
      } else {
        warnMessage(`No asset types are available`);
      }
    });
  };

  useEffect(() => {
    handleTaskState();
    handleAssetTypes();
  }, []);

  const handleOnFilter = async filterOptions => {
    await normalAssetCallBack(
      filterOptions,
      AUTH_USER?.depotName,
      retrieveAllAssets,
      warnMessage,
      setCurrentFilterOptions,
      setAssetList,
      setDataEndCursor,
      setDataStartCursor,
      setDataHasNextPage,
      setDataHasPreviousPage,
      setLoader,
      defaultNgiErrorMethod,
      successMessage
    );
  };

  const handleSearchAPI = async filterOptions => {
    await searchAssetCallBack(
      filterOptions,
      AUTH_USER?.depotName,
      retrieveAllAssets,
      warnMessage,
      setAssetList,
      setDataEndCursor,
      setDataStartCursor,
      setDataHasNextPage,
      setDataHasPreviousPage,
      setLoader,
      defaultNgiErrorMethod
    );
  };

  const handleOnSearch = async search => {
    await globalSearch(
      handleOnFilter,
      currentFilterOptions,
      search,
      assetList,
      handleSearchAPI,
      setAssetList,
      setLoader
    );
  };

  const onNextPage = async () => {
    const options = { ...currentFilterOptions, endCursor: dataEndCursor };
    handleOnFilter(options);
  };

  const onPreviousPage = async () => {
    const options = { ...currentFilterOptions, endCursor: dataEndCursor, startCursor: dataStartCursor };
    handleOnFilter(options);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <MetersFilter
              onFilter={handleOnFilter}
              isLoading={isLoading}
              showState={stateList}
              showAssetType={assetTypeList}
            />
            <Col lg={12}>
              {/* Assets Table */}

              <>
                {isLoading ? (
                  <NGISpinner />
                ) : (
                  <>
                    {assetList && assetList.length > 0 ? (
                      <B2cTable
                        columns={otherAssetListColumns}
                        data={assetList}
                        searchName="Search Meter"
                        hasNextpage={dataHasNextPage}
                        hasPreviousPage={dataHasPreviousPage}
                        onNextPage={onNextPage}
                        onSearchHandle={handleOnSearch}
                        onPreviousPage={onPreviousPage}
                      />
                    ) : (
                      <Card>
                        <CardBody className="text-center">
                          <i className="bx bx-warning" />
                          <h5>No Assets available</h5>
                          <p className="text-muted">Please apply filter to retrieve data.</p>
                        </CardBody>
                      </Card>
                    )}
                  </>
                )}
              </>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RetrieveMeters;
