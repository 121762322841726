import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";
import NGITooltip from "components/Common/utils/NGITooltip";

export const campaignListColumns = () => [
  {
    title: "Campaign Id",
    dataIndex: "id",
    render: (cellContent, row) => (
      <NGITooltip title="Campaign details">
        <CustomLink to={`/settings/campaign/${row.id}`}>{cellContent}</CustomLink>
      </NGITooltip>
    ),
  },
  {
    title: "Campaign Name",
    dataIndex: "name",
    render: (cellContent, row) => (
      <NGITooltip title="Campaign details">
        <CustomLink to={`/settings/campaign/${row.id}`}>{cellContent}</CustomLink>
      </NGITooltip>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Start Date",
    dataIndex: "startDateTime",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "End Date",
    dataIndex: "endDateTime",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Campaign Type",
    dataIndex: "type",
    render: textFormatter,
  },
  {
    title: "Total Customers",
    dataIndex: "eligibleCustomersCount",
    render: textFormatter,
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
];
