import React, { useCallback, useEffect, useState } from "react";
import { editPackageDetails } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { CardBody } from "reactstrap";
import { Col, Form, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import { history } from "store";
import { valuesToCheck } from "pages/packages/all-packages/packageDetails/constants";
import { covertObjWithNumbers } from "utils/helpers/functions";
import { fieldsToConvert } from "pages/packages/all-packages/newPackage/constants";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGIPrompt from "components/Common/NGIPrompt";
import { USER_TENANT_NAME } from "utils/constants";

const useStyles = makeStyles({
  cardBody: {
    padding: 0,
  },
  form: {
    width: "50%",
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  saveButton: {
    marginTop: 16,
  },
  rowItem: {
    flex: 1,
  },
});

export const status = ["Active", "Inactive"];

const Edit = ({ data }) => {
  const classes = useStyles();

  const [form] = Form.useForm();
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);
  const { code, type } = data;

  const [loading, setLoading] = useState(false);
  const [defaultDepots, setDefaultDepots] = useState(null);
  const [activeDepots, setActiveDepots] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!!data) {
      form.setFieldsValue({ ...data });
      setDefaultDepots(data?.depots);
      setActiveDepots(data?.depots || []);
    } else form.resetFields();
  }, [form, data]);

  useEffect(() => {
    form.setFieldsValue({ depots: activeDepots });
  }, [activeDepots]);

  const parseIntExistingItems = useCallback(normalizedOptions => {
    valuesToCheck.forEach(item => {
      if (!!normalizedOptions?.[item]) normalizedOptions[item] = Number.parseInt(normalizedOptions[item]);
    });
    return normalizedOptions;
  }, []);

  const handleSave = useCallback(
    async (options = {}) => {
      const { depots, ...newOptions } = options;
      setLoading(true);
      setIsDirty(false);
      const normalizedOptions = parseIntExistingItems({ ...newOptions });
      if (!!activeDepots.length) normalizedOptions.depots = activeDepots;

      const mappedValues = covertObjWithNumbers(normalizedOptions, fieldsToConvert);

      await editPackageDetails({
        code,
        options: { ...mappedValues },
      })
        .then(() => {
          setLoading(false);
          successMessage("Data was updated successfully");
          history.push("/packages/list");
        })
        .catch(error => {
          setLoading(false);
          defaultNgiErrorMethod(error);
        });
    },
    [code, activeDepots]
  );

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  const handleDeselect = useCallback(
    item => {
      if (!defaultDepots?.some(el => el === item)) {
        setActiveDepots(prevState => prevState.filter(el => el !== item));
      } else form.setFieldsValue({ depots: activeDepots });
    },
    [defaultDepots, activeDepots]
  );
  const handleSelect = useCallback(item => setActiveDepots(prevState => [...prevState, item]), []);

  return (
    <CardBody className={classes.cardBody}>
      <NGIPrompt show={isDirty} />
      <Spin spinning={loading}>
        <NGIForm
          name="editPackage"
          isUpdate
          className={classes.form}
          form={form}
          onFinish={handleSave}
          onChange={onFieldsChanged}
        >
          <Col span={24}>
            <Title level={5}>Name</Title>
            <NGIFormItem name="name">
              <NGIInput />
            </NGIFormItem>
          </Col>
          <Col span={24}>
            <Title level={5}>Depots</Title>
            <Col span={24}>
              <NGIFormItem name="depots">
                {!!data?.depots ? (
                  <NGISelect
                    showSearch
                    style={{ width: "100%" }}
                    mode="multiple"
                    tokenSeparators={[" ", ","]}
                    size={"default"}
                    placeholder={"Select depots"}
                    value={activeDepots}
                    onChange={onFieldsChanged}
                    onDeselect={handleDeselect}
                    onSelect={handleSelect}
                  >
                    {!!depot &&
                      depot[USER_TENANT_NAME]?.length > 0 &&
                      depot[USER_TENANT_NAME]?.sort()?.map(
                        item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ),
                        this
                      )}
                  </NGISelect>
                ) : (
                  <NGISelect style={{ width: "100%" }} size={"default"} value={"ALL DEPOTS"} disabled={true} />
                )}
              </NGIFormItem>
            </Col>
          </Col>
          <Col span={24}>
            <Title level={5}>Status</Title>
            <NGIFormItem name="status">
              <NGISelect
                showSearch
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                size={"default"}
                placeholder={"Select status"}
                onChange={onFieldsChanged}
              >
                {status.map(
                  item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ),
                  this
                )}
              </NGISelect>
            </NGIFormItem>
          </Col>
          <Col span={24}>
            <Title level={5}>Gas First Payment Amount</Title>
            <NGIFormItem
              name="gasFirstPaymentAmount"
              rules={[{ required: true, message: "Please enter the gas first payment amount" }]}
            >
              <NGIInput
                type={"number"}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                placeholder={"Enter initial gas payment"}
              />
            </NGIFormItem>
          </Col>
          {data?.type !== "Own Stove" && (
            <>
              <Col span={24}>
                <Title level={5}>Stove First Payment Amount</Title>
                <NGIFormItem name="stoveFirstPaymentAmount">
                  <NGIInput
                    type={"number"}
                    min={0}
                    placeholder={"Enter initial stove payment"}
                    max={Number.MAX_SAFE_INTEGER}
                  />
                </NGIFormItem>
              </Col>
              <Col span={24}>
                <Title level={5}>Stove Repair Cost</Title>
                <NGIFormItem name="stoveRepairCost">
                  <NGIInput
                    type={"number"}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    placeholder={"Enter stove repair cost"}
                  />
                </NGIFormItem>
              </Col>
              <Col span={24}>
                <Title level={5}>Stove Balance</Title>
                <NGIFormItem name="balance">
                  <NGIInput
                    type={"number"}
                    min={type === "Legacy" ? 0 : 1}
                    placeholder={"Enter the full price of stove"}
                    max={Number.MAX_SAFE_INTEGER}
                  />
                </NGIFormItem>
              </Col>
              <Col span={24}>
                <Title level={5}>Warranty Duration</Title>
                <NGIFormItem name="warrantyDuration">
                  <NGIInput
                    type={"number"}
                    min={1}
                    max={Number.MAX_SAFE_INTEGER}
                    placeholder={"Enter duration in month"}
                  />
                </NGIFormItem>
              </Col>
              <Col span={24}>
                <Title level={5}>Stove Change Eligibility Duration</Title>
                <NGIFormItem name="stoveChangeEligibilityDuration">
                  <NGIInput
                    type={"number"}
                    min={1}
                    max={Number.MAX_SAFE_INTEGER}
                    placeholder={"Enter duration in month"}
                  />
                </NGIFormItem>
              </Col>
              <Col span={24}>
                <Title level={5}>Stove Eligible Number</Title>
                <NGIFormItem name="stoveChangeEligibleNumber">
                  <NGIInput
                    type={"number"}
                    min={1}
                    max={Number.MAX_SAFE_INTEGER}
                    placeholder={"Enter the total number of stove changes"}
                  />
                </NGIFormItem>
              </Col>
            </>
          )}

          <CustomButton className={classes.saveButton} htmlType="submit" type="primary" size="small" text="Save" />
        </NGIForm>
      </Spin>
    </CardBody>
  );
};

export default Edit;
