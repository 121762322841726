import React, { useCallback, useState } from "react";
import NGITable from "components/Common/NGITable";
import { customerCreditBookColumns } from "pages/customer-management/customerDetails/constants";
import TransactionDetailsModal from "pages/customer-management/customerDetails/Tabs/TransactionDetailsModal";
import { makeStyles } from "@material-ui/core/styles";
import { customDateFormatWithoutTime, textFormatter } from "components/Common/formatter";

const useStyles = makeStyles({
  balanceHeader: {
    display: "flex",
    marginBottom: 20,

    "& > div + div": {
      marginLeft: 15,
    },
  },
  noDataMessage: {
    textAlign: "center",
    fontWeight: 600,
  },
});

const CreditBookTab = ({ isLoading, creditBook, update, transactions }) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const onShowModal = useCallback(id => {
    setActiveId(id);
    setShowModal(true);
  }, []);

  const onCloseModal = useCallback(() => setShowModal(false), []);

  return (
    <div>
      <>
        <div className={classes.balanceHeader}>
          <div>
            Loan amount: <b>{creditBook?.amount}</b>
          </div>
          <div>
            Paid amount: <b>{creditBook?.paidAmount}</b>
          </div>
          <div>
            Outstanding amount: <b>{creditBook?.outstandingAmount}</b>
          </div>
          <div>
            Deduction amount: <b>{creditBook?.deductionAmount}</b>
          </div>
          {creditBook?.deductionType && (
            <div>
              Deduction type: <b>{creditBook?.deductionType}</b>
            </div>
          )}
          <div>
            Loan date:{" "}
            <b>
              {creditBook?.createdAt
                ? customDateFormatWithoutTime(creditBook?.createdAt)
                : textFormatter(creditBook?.createdAt)}
            </b>
          </div>
        </div>
        {!!transactions.result?.items?.length ? (
          <NGITable
            isLoading={isLoading}
            columns={customerCreditBookColumns({ onShowModal })}
            updateList={update}
            data={transactions}
          />
        ) : (
          <div className={classes.noDataMessage}>No credit balance for the customer</div>
        )}
        {showModal && <TransactionDetailsModal isOpen={showModal} onClose={onCloseModal} id={activeId} />}
      </>
    </div>
  );
};

export default CreditBookTab;
