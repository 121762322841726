import axios from "axios";
import { authToken } from "./authToken";
import { history } from "store";
import appconfig from "appconfig";
import { loginSuccess } from "store/auth/login/actions";
import { AUTH_USER } from "utils/constants";

const responseSuccessHandler = response => response;

let store;
export const injectStore = _store => {
  store = _store;
};

const responseErrorHandler = async (error, isLegacyAPI = false) => {
  if (error?.response?.status === 401 || isLegacyAPI) {
    try {
      await tryToReLogin();
      const { config: oldRequest } = error;
      return await axios.request({
        ...oldRequest,
        headers: { Authorization: `Bearer ${authToken()}`, "Content-Type": "application/json" },
      });
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 400) {
        localStorage.clear();
        history.push("/login");
        return Promise.reject(error);
      }
    }
  }
  return Promise.reject(error);
};

export const tryToReLogin = () =>
  new Promise((resolve, reject) => {
    const { cognito } = appconfig;

    axios
      .post(
        `${cognito.domain}/oauth2/token`,
        new URLSearchParams({
          grant_type: "refresh_token",
          client_id: cognito.clientId,
          refresh_token: AUTH_USER?.refresh_token,
          client_secret: cognito.clientSecret,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then(response => {
        const newData = {
          ...AUTH_USER,
          accessToken: response.data.access_token,
          token: response.data.id_token,
        };
        localStorage.setItem("b2c:authUser", JSON.stringify(newData));
        localStorage.setItem("b2c:authToken", JSON.stringify(response.data.id_token));
        localStorage.setItem("expiration", (Date.now() + response.data.expires_in * 1000).toString());

        store.dispatch(loginSuccess(newData));
        resolve(true);
      })
      .catch(e => {
        reject(e);
      });
  });

const getCustomResponse = (config, isLegacyAPI) =>
  config.interceptors.response.use(
    response => responseSuccessHandler(response),
    async error => await responseErrorHandler(error, isLegacyAPI)
  );

const getConfig = (type, version = "v1") => {
  if (appconfig.env === "pre-prod") appconfig.env = "preprod";

  const config = axios.create({
    baseURL: `https://ngi.${appconfig.env}.iner.gy/${type}/api/${version}/`,
  });
  config.defaults.headers.common["Authorization"] = `Bearer ${authToken()}`;
  config.defaults.headers.post["Content-Type"] = "application/json";

  getCustomResponse(config);
  return config;
};

export const assetMicroservice = () => getConfig("assetsmanagement");
export const smsMicroservice = () => getConfig("smsmanagement");

export const taskMicroservice = version => getConfig("tasksmanagement", version);

export const configurationMicroservice = () => getConfig("configurationmanagement");

export const metersMicroservice = version => getConfig("metersmanagement", version);
export const marketingMicroservice = version => getConfig("marketinginitiatives", version);

export const stoveMicroservice = () => getConfig("stovesmanagement");

export const customersMicroservice = version => getConfig("customersmanagement", version);

export const depotsMicroservice = () => getConfig("depotsmanagement");

export const historyManagement = () => getConfig("historymanagement");

export const packagesMicroservice = () => getConfig("packagesmanagement");

export const paymentsMicroservice = version => getConfig("paymentsgateway", version);

export const transactionAdjustmentMicroservice = () => getConfig("transactionadjustmentmodule");

export const userMicroservice = () => getConfig("usersmanagement");
export const campaignMicroservice = () => getConfig("campaignmanagement");

export const vehicleMicroservice = () => getConfig("vehiclemanagement");

export const smartsaleMicroservice = () => {
  const config = axios.create({
    // baseURL: "https://api.smartsale."+appconfig.env+".iner.gy/api/v1/"
    baseURL: "https://api.smartsale.sandpit.iner.gy/api/v1/",
  });
  config.defaults.headers.post["Content-Type"] = "application/json";
  getCustomResponse(config);
  return config;
};

export const operationsLegacyMicroservice = () => {
  if (appconfig.env === "preprod") appconfig.env = "pre-prod";
  const config = axios.create({
    baseURL: `https://operations.${appconfig.env}.iner.gy/`,
  });
  config.defaults.headers.common["Authorization"] = `Bearer ${authToken()}`;
  config.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=utf-8";
  getCustomResponse(config, true);
  return config;
};

export const pgLegacyMicroservice = () => {
  if (appconfig.env === "preprod") appconfig.env = "pre-prod";
  const config = axios.create({ baseURL: `https://pg.${appconfig.env}.iner.gy/` });
  config.defaults.headers.post["Content-Type"] = "application/json";
  return config;
};
