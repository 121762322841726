import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { AVAILABLE_STATUSES } from "pages/operations/leads/variables";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { isEmpty, uniq } from "lodash";
import { getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { inputTextPattern } from "components/Common/inputs/variables";
import { TSRSelector } from "store/TSR/selectors";
import { userHasAdminRole } from "utils/helpers/functions";
import classnames from "classnames";
import TSRSelect from "components/Common/components/TSRSelect";

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const useStyles = makeStyles({
  root: { width: "250px!important" },
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    height: 60,
    overflow: "auto",

    "& .ant-select-selection-overflow": {
      flexWrap: "nowrap",
    },
  },
  filterSelect: {
    "& .ant-select": {
      minWidth: "auto!important",
    },
  },
});

const LeadsSearch = ({ handleSearch }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [filterType, setFilterType] = useState([]);
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.LEADS);
  const { TSRList } = useSelector(TSRSelector);
  const isFullAdmin = useMemo(() => userHasAdminRole(), []);

  const {
    list: { depot, customerType },
  } = useSelector(DictionariesSelector);

  const filterOptions = [
    { name: "TSR", short: "TSR", value: 1, id: "technician" },
    { name: "Status", short: "STT", value: 2, id: "status" },
    { name: "Category", short: "CTG", value: 3, id: "type" },
    { name: "Customer Information", short: "INF", value: 4, id: "information" },
    { name: "Date", short: "DATE", value: 5, id: "date" },
  ];

  useEffect(() => isFullAdmin && filterOptions.push({ name: "Depot", short: "DPT", value: 6, id: "depot" }), []);

  useEffect(() => {
    if (!isEmpty(filters)) {
      const defaultFilter = filterOptions.filter(item => item.id in filters);
      setFilterType(defaultFilter.map(item => item.value));
      form.setFieldsValue(filters);
    }
  }, []);

  const selectedFilterValues = useMemo(
    () => filterType?.map(item => filterOptions[item - 1]),
    [filterType, filterOptions]
  );

  const onChange = useCallback(value => setFilterType(value), []);

  const handleOnFilter = useCallback(values => {
    const { pageSize } = getFilters();
    const preparedData = Object.keys(values).reduce((a, item) => {
      if (item === "date") {
        return {
          ...a,
          createdAtFrom: getStartOfTheDay(moment(values[item][0])),
          createdAtTo: getStartOfTheDay(moment(values[item][1])),
        };
      }
      return { ...a, [item]: values[item]?.trim() };
    }, {});
    handleSearch({ ...preparedData, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={handleOnFilter}>
        <div className={classnames(classes.filtersContainer, selectedFilterValues.length > 2 && classes.filterSelect)}>
          <Select
            showSearch
            mode="multiple"
            tokenSeparators={[" ", ","]}
            placeholder={"Select type"}
            value={selectedFilterValues}
            onChange={onChange}
            optionLabelProp={selectedFilterValues.length > 3 ? "short" : "label"}
            allowClear
          >
            {filterOptions.map(item => (
              <Option key={item.value} title={item.name} value={item.value} label={item.name} short={item.short}>
                {item.name}
              </Option>
            ))}
          </Select>

          {!!filterType.length && filterType.includes(1) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="technician" rules={[{ required: true, message: "Please select TSR name" }]}>
                <TSRSelect
                  showSearch
                  initialList={TSRList}
                  roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                  depot={AUTH_USER?.depotName}
                />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType?.includes(2) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="status" rules={[{ required: true, message: "Please select status" }]}>
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select status"}>
                  {AVAILABLE_STATUSES.map(item => (
                    <Option key={item} value={item}>
                      {item === "Qualified" ? "Approved" : item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(3) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="type" rules={[{ required: true, message: "Please select task category" }]}>
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                  {customerType?.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(4) && (
            <>
              <div className="filter-divider" />
              <Form.Item
                name="information"
                rules={[{ required: true, message: "Please enter first or last name of customer" }]}
              >
                <Input
                  placeholder={
                    "Search by: First Name, Last Name, Middle Name, Phone Number, Secondary Phone, Close Relative Phone, ID Number, Street"
                  }
                  allowClear
                  pattern={inputTextPattern}
                  title={
                    "Search by: First Name, Last Name, Middle Name, Phone Number, Secondary Phone, Close Relative Phone, ID Number, Street"
                  }
                />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(5) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="date" rules={[{ required: true, message: "Please enter date" }]}>
                <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(6) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="depot" rules={[{ required: true, message: "Please enter depot" }]}>
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                  {depot[USER_TENANT_NAME] &&
                    uniq(depot[USER_TENANT_NAME])
                      ?.sort()
                      ?.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </>
          )}
        </div>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default LeadsSearch;
