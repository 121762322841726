import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { fetchDepotsList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { depotsListColumns } from "pages/depot-management/depots/depotsListColumns";
import NGITable from "components/Common/NGITable";
import DepotsSearch from "pages/depot-management/depots/DepotsSearch";
import DepotsFilter from "pages/depot-management/depots/DepotsFilter";
import DepotModal from "pages/depot-management/depotModal/DepotModal";
import AllDepotsModal from "pages/depot-management/depotModal/AllDepotsModal";
import { randomColor, userHasOneOfRoles } from "utils/helpers/functions";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import addIcon from "assets/images/svg/plus-circle-white.svg";
import { history } from "store";

const hideRoles = [
  USER_ROLES.INTERNAL_AUDITOR,
  USER_ROLES.CUSTOMER_CARE,
  USER_ROLES.D_SM,
  USER_ROLES.A_SM,
  USER_ROLES.OPERATION_MANAGERS,
  USER_ROLES.DEPOT_CLERK,
  USER_ROLES.SALES_SUPPORT,
  USER_ROLES.GENERAL,
  USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
  USER_ROLES.IT_OPERATIONS,
  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
  USER_ROLES.FINANCE,
  USER_ROLES.WAREHOUSE,
];
const Depots = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [allDepotsList, setAllDepotsList] = useState(null);
  const [response, setResponse] = useState(null);
  const [showAddDepotModal, setShowAddDepotModal] = useState(false);
  const [showAllDepotsModal, setShowAllDepotsModal] = useState(false);
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.DEPOT);

  useEffect(() => {
    handleSearch(filters);
    fetchDepotsList({
      pageSize: 1000,
    }).then(res => {
      const {
        result: { items },
      } = res?.data;
      setAllDepotsList(items || []);
    });
    dispatch(getDictionary(DICTIONARY_TYPES.COUNTY));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Depots were not found");
      setLoader(false);
    }
    setResponse(res?.data || {});
    setLoader(false);
  }, []);

  const handleSearch = useCallback((filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);
    fetchDepotsList({
      ...filterOptions,
    })
      .then(async res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setResponse(null);
        defaultNgiErrorMethod(err);
      });
  }, []);
  const openDepotTeams = useCallback(depot => {
    history.push(`/depot/teams?n=${depot.name}`);
  }, []);

  const columns = useMemo(() => depotsListColumns(userHasOneOfRoles(hideRoles), openDepotTeams), []);

  const onAddDepotOpen = useCallback(() => setShowAddDepotModal(true), []);
  const onAddDepotClose = useCallback(() => setShowAddDepotModal(false), []);

  const onAllDepotsOpen = useCallback(() => setShowAllDepotsModal(true), []);
  const onAllDepotsClose = useCallback(() => setShowAllDepotsModal(false), []);

  const polygonList = useMemo(
    () =>
      allDepotsList?.map(item => ({
        id: item.name,
        color: randomColor(),
        poly: item.boundaries.coordinates[0].map(item => [item[1], item[0]]),
      })),
    [allDepotsList]
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <ButtonGroupContainer color={"primary"}>
                <ButtonGroup
                  color="primary"
                  icon={addIcon}
                  handleClick={onAddDepotOpen}
                  tooltipTitle="New Depot"
                  hideRoles={hideRoles}
                />
                <ButtonGroup color="primary" handleClick={onAllDepotsOpen} tooltipTitle="All depots">
                  <i className="bx bx-map-alt" />
                </ButtonGroup>
              </ButtonGroupContainer>
            </div>
            <DepotsFilter handleFilter={handleSearch} />
            <DepotsSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.DEPOT}
              isLoading={isLoading}
              columns={columns}
              updateList={handleSearch}
              data={response}
            />
          </Col>
        </Container>
      </div>
      {showAddDepotModal && (
        <DepotModal
          polygonList={polygonList}
          isOpen={showAddDepotModal}
          onClose={onAddDepotClose}
          onUpdate={handleSearch}
        />
      )}
      {showAllDepotsModal && (
        <AllDepotsModal polygonList={polygonList} isOpen={showAllDepotsModal} onClose={onAllDepotsClose} />
      )}
    </>
  );
};

export default Depots;
