import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    borderRadius: 20,
    backgroundColor: "rgba(250, 250, 250, 1)",
    marginBottom: 15,
  },
  titleSection: {
    padding: 15,
    fontWeight: 400,
    borderRadius: "20px 20px 0 0",
    color: "rgba(0, 0, 0, 0.75)",
    backgroundColor: "rgba(240, 240, 240, 1)",
  },
  container: {
    padding: 15,
  },
});

const TitledContainer = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>{title}</div>
      <div className={classes.container}>{children}</div>
    </div>
  );
};

export default TitledContainer;
