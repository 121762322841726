import React, { useCallback, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { dateFormat, searchOptions } from "pages/cylinder-weight-reports/reports/constants";
import { makeStyles } from "@material-ui/core/styles";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { uniq } from "lodash";
import classnames from "classnames";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { USER_TENANT_NAME } from "utils/constants";

const { RangePicker } = DatePicker;
const { Option } = Select;

const useStyles = makeStyles({
  root: { width: "250px!important" },
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    height: 60,
    overflow: "auto",

    "& .ant-select-selection-overflow": {
      flexWrap: "nowrap",
    },
  },
  filterSelect: {
    "& .ant-select": {
      minWidth: "auto!important",
    },
  },
});

const ReportsSearch = ({ handleSearch, filtersType }) => {
  const { getFilters } = useStoreFilters(filtersType);
  const [form] = Form.useForm();
  const classes = useStyles();
  const [activeFilter, setActiveFilter] = useState([]);
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const handleFilterChange = useCallback(item => {
    setActiveFilter(item);
  }, []);

  const onFinish = useCallback(values => {
    const { pageSize } = getFilters();
    const preparedFilters = Object.keys(values).reduce((a, item) => {
      if (item === "date") {
        return {
          ...a,
          timeStart: getStartOfTheDay(moment(values?.date[0])),
          timeEnd: getEndOfTheDay(moment(values?.date[1])),
        };
      }
      return { ...a, [item]: values[item]?.trim() };
    }, {});
    handleSearch({ ...preparedFilters, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={onFinish}>
        <div className={classnames(classes.filtersContainer, activeFilter.length > 2 && classes.filterSelect)}>
          <Select
            tokenSeparators={[","]}
            onChange={handleFilterChange}
            mode="multiple"
            placeholder={"Select type"}
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={`${item.value}-option`} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
          {!!activeFilter.length && (
            <>
              {activeFilter.includes("serialNumber") && (
                <>
                  <div className="filter-divider" />
                  <Form.Item name="serialNumber" rules={[{ required: true, message: "Please enter serial number" }]}>
                    <Input
                      placeholder={"Enter serial number"}
                      allowClear
                      pattern={inputTextPattern}
                      title={inputPatternTitle}
                    />
                  </Form.Item>
                </>
              )}
              {activeFilter.includes("date") && (
                <>
                  <div className="filter-divider" />
                  <Form.Item
                    name="date"
                    rules={[{ required: true, message: "Please select date range" }]}
                    className={classes.root}
                  >
                    <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
                  </Form.Item>
                </>
              )}
              {activeFilter.includes("operationDepot") && (
                <>
                  <div className="filter-divider" />
                  <Form.Item name="operationDepot" rules={[{ required: true, message: "Please select depot" }]}>
                    <Select showSearch size="default" placeholder="Select depot" allowClear>
                      {depot[USER_TENANT_NAME] &&
                        uniq(depot[USER_TENANT_NAME])
                          ?.sort()
                          ?.map(item => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </>
              )}
            </>
          )}
        </div>
        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default ReportsSearch;
