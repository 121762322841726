import { API_MAPPING } from "pages/meter-management/meters/meterDetails/constants";

export const getPreparedData = (data, type) => {
  switch (API_MAPPING[type]) {
    case API_MAPPING["Activate Meter"]:
      return {
        barcode: data.barcode,
        activateCook: data.activateCook || false,
        activateBLE: data.activateBLE || false,
      };
    case API_MAPPING["Adjust Data"]:
      return {
        barcode: data.barcode,
        gasRemaining: parseFloat(data.gasRemaining),
        creditRemaining: parseFloat(data.creditRemaining),
        endCumulativeMass: parseFloat(data.endCumulativeMass),
        endCumulativeCredit: parseFloat(data.endCumulativeCredit),
        cylinderSerial: data.cylinderSerial,
        meterChangeDate: new Date(data.meterChangeDate).getTime(),
      };
    case API_MAPPING["Change Battery"]:
      return {
        barcode: data.barcode,
        batteryModel: data.batteryModel,
        batteryQuantity: parseInt(data.batteryQuantity),
        batterySerialNumberList: data.batterySerialNumberList,
        batteryCapacity: parseInt(data.batteryCapacity),
      };
    case API_MAPPING["Cylinder Install"]:
      return {
        barcode: data.barcode,
        oldCylinderSerialNumber: data.oldCylinderSerialNumber,
        cylinderSerial: data.cylinderSerial,
        gasLevel: parseInt(data.gasLevel),
        datetime: new Date(data.datetime).getTime(),
      };
    case API_MAPPING["Update Settings"]:
      return {
        barcode: data.barcode,
        startPeriod: parseInt(data.startPeriod),
        unitPrice: parseInt(data.unitPrice),
        gasRemaining: parseInt(data.gasRemaining),
      };
    case API_MAPPING["Close Meter Lock"]:
    case API_MAPPING["Close Meter Valve"]:
    case API_MAPPING["Deactivate Meter"]:
    case API_MAPPING["Get Cumulative Report"]:
    case API_MAPPING["Get Hardware Report"]:
    case API_MAPPING["Get Logs"]:
      return {
        barcode: data.barcode,
        ...(data.count && { indexStart: parseInt(data.index_start), count: parseInt(data.count) }),
      };
    case API_MAPPING["Get Meter Status"]:
    case API_MAPPING["Get Setting"]:
    case API_MAPPING["Open Meter Lock"]:
    case API_MAPPING["Open Meter Valve"]:
    case API_MAPPING["Reset All Parameters"]:
    case API_MAPPING["Self Test"]:
    case API_MAPPING["Send INFQ"]:
      return {
        barcode: data.barcode,
      };
    default:
      return null;
  }
};
