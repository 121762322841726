import React, { useCallback, useMemo, useState } from "react";
import { Option } from "antd/lib/mentions";
import { getUsersList } from "utils/api";
import { debounce } from "lodash";
import NGISelect from "components/Common/inputs/NGISelect";

const emptyFn = () => {};
const TSRSelect = ({
  allowClear = false,
  size,
  showSearch = false,
  roles = [],
  depot,
  handleChange = emptyFn,
  initialList = [],
  placeholder = "Please select or type TSR name to search",
  ...props
}) => {
  const [list, setList] = useState(initialList);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTSR, setSelectedTSR] = useState(null);

  const searchTsr = useCallback(
    value => {
      if (value) {
        const foundTsr = list.find(item => (item?.name ?? "").toLowerCase().includes(value));
        if (!foundTsr) {
          setList([]);
          setIsLoading(true);
          getUsersList({
            name: value,
            role: roles.join(),
            depot,
            pageSize: 100,
          }).then(({ data }) => {
            setList(data.result.items);
            setIsLoading(false);
          });
        } else {
          setList(initialList);
        }
      } else {
        setList(initialList);
      }
    },
    [initialList, roles, depot]
  );

  const onChangeTsr = useCallback(
    value => {
      const foundTsr = list.find(item => item.id === value);
      setSelectedTSR(value);
      handleChange(foundTsr);
    },
    [initialList, handleChange, list]
  );

  const debouncedSearch = useMemo(() => debounce(searchTsr, 1000), []);

  return (
    <NGISelect
      {...props}
      allowClear={allowClear}
      showSearch={showSearch}
      loading={isLoading}
      placeholder={placeholder}
      filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
      onSearch={debouncedSearch}
      onSelect={onChangeTsr}
      value={selectedTSR}
      title={placeholder}
    >
      {list.map(item => (
        <Option key={item.id} value={item.id}>
          {item.name || item.id}
        </Option>
      ))}
    </NGISelect>
  );
};
export default TSRSelect;
