import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PRIORITY_LIST, statusIcons } from "pages/operations/routineDetails/constants";
import classnames from "classnames";
import { customDateFormatWithoutTime, textFormatter } from "components/Common/formatter";

const useStyles = makeStyles({
  root: {
    display: "flex",
    textAlign: "left",
    justifyContent: "space-between",
    backgroundColor: "rgba(245, 245, 245, 1)",
    marginBottom: 10,
    padding: 10,
    alignItems: "center",
  },
  category: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  text: {
    marginLeft: 10,
  },
  info: {
    display: "flex",
  },
  source: {
    border: "1px solid black",
    fontWeight: 600,
    textTransform: "uppercase",
    paddingInline: 6,
    borderRadius: 5,
    marginLeft: 10,
  },
  subtitle: {
    fontWeight: 300,
    color: "grey",
    marginRight: 10,
  },
  badge: {
    marginLeft: 10,
    width: 17,
    height: 17,
    margin: 2,
    padding: 3,
    color: "white",
    display: "flex",
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "11px",
    lineHeight: "11px",
    fontWeight: 500,
  },
  high: {
    backgroundColor: "rgba(255, 46, 0, 1)",
  },
  medium: {
    backgroundColor: "rgba(240, 139, 57, 1)",
  },
  low: {
    backgroundColor: "rgba(23, 195, 71, 1)",
  },
});

const TaskItem = ({ task }) => {
  const classes = useStyles();
  const priority = PRIORITY_LIST.find(
    item =>
      (Math.round(task.priority) <= item.max && Math.round(task.priority) >= item.min) ||
      (!task.priority && item.letter === "L")
  );

  const openTask = useCallback(() => {
    window.open(`/operations/task/${task.id}`, "_blank");
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.category} onClick={openTask}>
        <img src={statusIcons[task.category]} alt="" />
        <div className={classes.text}>
          <b>{task.category}</b>
        </div>
        <span className={classnames(classes.badge, classes[priority?.name.toLowerCase()])}>
          {priority?.letter.toUpperCase()}
        </span>
      </div>
      <div className={classes.info}>
        <span className={classes.subtitle}>TASK STATUS</span>
        <b>{textFormatter(task.status?.toUpperCase())}</b>
      </div>
      <div className={classes.info}>
        <span className={classes.subtitle}>TASK CREATED</span>
        <b>{customDateFormatWithoutTime(task.createdAt)}</b>
      </div>
      <div className={classes.info}>
        <span className={classes.source}>{task.source}</span>
      </div>
    </div>
  );
};

export default TaskItem;
