/* ASSIGN TASKS */

export const ASSIGN_TASKS = "ASSIGN_TASKS";
export const ASSIGN_TASKS_FAIL = "ASSIGN_TASKS_FAIL";
export const ASSIGN_TASKS_SUCCESS = "ASSIGN_TASKS_SUCCESS";
export const GET_TASK_CATEGORY = "GET_TASK_CATEGORY";
export const GET_TASK_CATEGORY_SUCCESS = "GET_TASK_CATEGORY_SUCCESS";
export const GET_TASK_CATEGORY_FAIL = "GET_TASK_CATEGORY_FAIL";
export const GET_TASK_STATUS = "GET_TASK_STATUS";
export const GET_TASK_STATUS_SUCCESS = "GET_TASK_STATUS_SUCCESS";
export const GET_TASK_STATUS_FAIL = "GET_TASK_STATUS_FAIL";
export const CANCEL_TASK = "CANCEL_TASK";
export const CANCEL_TASK_FAIL = "CANCEL_TASK_FAIL";
export const CANCEL_TASK_SUCCESS = "CANCEL_TASK_SUCCESS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
