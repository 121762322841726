import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import {
  customerBadCookingSessionsColumns,
  customerCookingSessionsColumns,
  customerAssetsColumns,
  TABS,
  tasksHistoryColumns,
  getTabsButtons,
} from "pages/customer-management/customerDetails/constants";
import classnames from "classnames";
import PersonalInformationTab from "pages/customer-management/customerDetails/Tabs/PersonalInformationTab";
import TransactionTab from "pages/customer-management/customerDetails/Tabs/TransactionTab";
import CookingSessionsTab from "pages/customer-management/customerDetails/Tabs/CookingSessionsTab";
import NGITable from "components/Common/NGITable";
import BalanceBookTab from "pages/customer-management/customerDetails/Tabs/BalanceBookTab";
import { useLocation } from "react-router";
import CustomButton from "components/Common/buttons/CustomButton";
import { customDateFormatWithTime } from "components/Common/formatter";
import { generateXLS, userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";
import DemographicQuestionsTab from "pages/operations/leadDetails/Tabs/DemographicQuestionsTab";
import CreditBookTab from "pages/customer-management/customerDetails/Tabs/CreditBookTab";
import ContractHistoryTab from "pages/customer-management/customerDetails/Tabs/ContractHistoryTab";
import LinkedAccountsTab from "pages/customer-management/customerDetails/Tabs/LinkedAccountsTab";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  dateTime: {
    marginBottom: 10,
  },
});

const DataTabs = ({
  customer,
  cookingSessions,
  badCookingSessions,
  assets,
  customerBalance,
  tasksHistory,
  contractUrl,
  balanceBookTransactions,
  updateAssets,
  updateHistory,
  handleCookingSessions,
  handleBadCookingSessions,
  updateBalanceBookTransactions,
  isLoading,
  consumptionStat,
  lastCylinderChange,
  photoDetails,
  questionnaire,
  creditBook,
  creditBookTransactions,
  handleCreditBookTransactions,
  meterPinDetails,
}) => {
  const classes = useStyles();
  const searchParams = useLocation().search;
  const activeUrlTab = new URLSearchParams(searchParams).get("activeTab");
  const [activeTab, setActiveTab] = useState(activeUrlTab ?? TABS.PERSONAL_INFORMATION);

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  const normalizedAssets = useMemo(() => {
    const newAssets = { ...assets };
    if (!!assets?.result?.items && customer?.status === "Suspended")
      newAssets.result.items = assets?.result?.items?.filter(item => item.state === "Lost");
    return newAssets;
  }, [assets, customer]);

  const onDownload = useCallback(
    ({ isGood = true, name, reportsList }) =>
      generateXLS(
        reportsList,
        `${name}, ${isGood ? "Good" : "Bad"} cooking sessions, ${customDateFormatWithTime(Date.now())}`,
        "xlsx"
      ),
    []
  );

  const isHavePXMeter = useMemo(() => !!assets?.result?.items?.find(item => item.type.includes("PX")), [assets]);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {getTabsButtons(customer?.status)?.map(item => {
          if (!userHasAdminRole() && userHasOneOfRoles(item?.hideRoles)) return null;
          return (
            <div
              key={item.key}
              className={classnames(classes.tab, activeTab === item.key && classes.active)}
              data-tab={item.key}
              onClick={onTabClick}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      <Card className={classes.container}>
        <CardBody>
          {activeTab === TABS.PERSONAL_INFORMATION && (
            <PersonalInformationTab
              customer={customer}
              contract={contractUrl}
              consumptionStat={consumptionStat}
              lastCylinderChange={lastCylinderChange}
              photoDetails={photoDetails}
              meterPinDetails={meterPinDetails}
              isHavePXMeter={isHavePXMeter}
            />
          )}
          {activeTab === TABS.DEMOGRAPHIC_QUESTIONS && (
            <DemographicQuestionsTab
              mode={""}
              editForm={null}
              registrationSurvey={customer?.registrationSurvey}
              leadQuestionnaire={questionnaire}
            />
          )}
          {activeTab === TABS.TRANSACTION_BOOK && <TransactionTab customerId={customer.id} />}
          {activeTab === TABS.BALANCE_BOOK && (
            <BalanceBookTab
              isLoading={isLoading}
              customerBalance={customerBalance}
              update={updateBalanceBookTransactions}
              transactions={balanceBookTransactions}
            />
          )}
          {activeTab === TABS.CREDIT_BOOK && (
            <CreditBookTab
              isLoading={isLoading}
              creditBook={creditBook}
              update={handleCreditBookTransactions}
              transactions={creditBookTransactions}
            />
          )}
          {activeTab === TABS.COOKING_SESSIONS && (
            <>
              <div className="filters-wrapper">
                <CustomButton
                  icon={<i className="bx bx-download" />}
                  type="primary"
                  size="small"
                  disabled={!cookingSessions?.result?.items?.length}
                  text="Download"
                  onClick={() => {
                    onDownload({
                      isGood: true,
                      reportsList: cookingSessions.result?.items,
                      name: `${customer?.firstName} ${customer?.lastName}`,
                    });
                  }}
                />
              </div>
              <CookingSessionsTab
                isLoading={isLoading}
                update={handleCookingSessions}
                columns={customerCookingSessionsColumns()}
                list={cookingSessions}
              />
            </>
          )}
          {activeTab === TABS.BAD_COOKING_SESSIONS && (
            <>
              <div className="filters-wrapper">
                <CustomButton
                  icon={<i className="bx bx-download" />}
                  type="primary"
                  size="small"
                  disabled={!badCookingSessions?.result?.items?.length}
                  text="Download"
                  onClick={() => {
                    onDownload({
                      isGood: false,
                      reportsList: badCookingSessions.result?.items,
                      name: `${customer?.firstName} ${customer?.lastName}`,
                    });
                  }}
                />
              </div>
              <CookingSessionsTab
                isLoading={isLoading}
                update={handleBadCookingSessions}
                columns={customerBadCookingSessionsColumns()}
                list={badCookingSessions}
              />
            </>
          )}
          {activeTab === TABS.ASSETS && (
            <NGITable
              isLoading={isLoading}
              updateList={updateAssets}
              columns={customerAssetsColumns()}
              data={normalizedAssets}
            />
          )}
          {activeTab === TABS.TASKS_HISTORY && (
            <>
              {customer.lastInterventionAt && (
                <div className={classes.dateTime}>
                  Last Intervention Date: <b>{customDateFormatWithTime(customer.lastInterventionAt)}</b>
                </div>
              )}
              <NGITable
                isLoading={isLoading}
                updateList={updateHistory}
                columns={tasksHistoryColumns(customer.id)}
                data={tasksHistory}
              />
            </>
          )}
          {activeTab === TABS.CONTRACT_HISTORY && (
            <ContractHistoryTab customer={customer} contracts={customer.contracts} />
          )}
          {activeTab === TABS.LINKED_ACCOUNTS && <LinkedAccountsTab customer={customer} />}
        </CardBody>
      </Card>
    </div>
  );
};

export default DataTabs;
