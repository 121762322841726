import { getFormattedKeyName } from "utils/helpers/functions";

export const getConfirmationMessage = (group, duplicates) => {
  let message = "";
  const duplicatesFound = duplicates.find(item => !item.canResolve);

  if (!duplicatesFound?.assignedTo) {
    switch (group) {
      case "Cylinder": {
        if (duplicatesFound.duplicateBy[0] === "serialNumber" && duplicatesFound.duplicateBy.length === 1) {
          message =
            "Cylinder with the same Serial Number is found. Please back to request, enter a valid Duplicate Correction Code in the range of 1-9 or A- Z.";
        }
        if (duplicatesFound.duplicateBy[0] === "qrCode" && duplicatesFound.duplicateBy.length === 1) {
          message = "The QR Code is in use on another asset. Please generate and attach a new QR Code.";
        }
        if (duplicatesFound.duplicateBy.includes("qrCode") && duplicatesFound.duplicateBy.includes("serialNumber")) {
          message =
            "Cylinder with the same Serial Number and QRCode is found. Please do the following actions: " +
            "\n- enter a valid duplicate correction code in the range of 1-9 or A- Z  " +
            "\n- generate and attach a new QR Code.";
        }
        break;
      }
      case "Meter": {
        message = `A meter with the specified ${duplicatesFound.duplicateBy
          .map(getFormattedKeyName)
          .join(" and ")} already exists in the system. Request cannot be approved.`;
        break;
      }
      case "Stove": {
        message = "The QR Code is in use on another asset. Please generate and attach a new QR Code.";
        break;
      }
      default:
        message = "";
        break;
    }
  } else {
    message = `Please contact with <${duplicatesFound?.assignedTo}> to resolve this request.`;
  }

  return message;
};
