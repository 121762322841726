import React, { useCallback, useEffect } from "react";
import { Form, Select, Input } from "antd";
import { JOB_TYPES } from "./variables";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const OutgoingLogsFilter = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.OUTGOING_LOGS);

  useEffect(() => {
    if (!!filters) {
      form.setFieldsValue(filters);
    } else {
      form.setFieldsValue({
        type: null,
        barcode: null,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    const { type, barcode } = values;
    const { pageSize } = getFilters();
    handleSearch({
      type,
      barcode: barcode?.trim(),
      pageSize,
    });
  }, []);

  const getFormattedName = useCallback(name => {
    let formattedName = name.replace(/([A-Z])/g, " $1").trim();
    formattedName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
    return formattedName;
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type" rules={[{ required: true, message: "Please select type" }]}>
          <Select showSearch allowClear style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select type"}>
            {JOB_TYPES.map(item => (
              <Option key={item} value={item}>
                {getFormattedName(item)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <Form.Item name="barcode" rules={[{ message: "Please enter barcode" }]}>
          <Input allowClear placeholder={"Enter barcode"} pattern={inputTextPattern} title={inputPatternTitle} />
        </Form.Item>
        <CustomButton type="primary" htmlType="submit" text="Search" size="small" />
      </Form>
    </div>
  );
};

export default OutgoingLogsFilter;
