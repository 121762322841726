import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { Collapse } from "antd";
import memberIcon from "assets/images/svg/peopleIcon.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import { getValue } from "pages/depot-management/depotDetails/utils";
import ManageMembersModel from "pages/depot-management/depotDetails/ManageMembersModel";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { ALLOWED_TEAMS } from "pages/depot-management/depotDetails/constants";

const { Panel } = Collapse;

const useStyles = makeStyles({
  collapseList: {
    backgroundColor: "#FFF",
    marginBottom: 10,

    "& .ant-collapse-expand-icon": {
      display: "none",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 500,
  },
  headerName: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  memberItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: 6,
    padding: 9,
    marginBottom: 6,
    fontSize: 12,
  },
  subtitle: {
    marginRight: 10,
    color: "grey",
    fontWeight: 400,
  },
  value: {
    fontWeight: 500,
  },
  memberLength: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    padding: "4px 10px",
    borderRadius: 4,

    "& img": {
      marginRight: 10,
    },
  },
  nameContainer: {
    width: "25%",
  },
  titleValue: {
    width: "25%",
  },
  manageMembers: {
    height: "30px!important",
    marginLeft: 10,
    padding: "5px 20px!important",
  },
  noSectors: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Sectors = ({ sectors, TSRList, updateData }) => {
  const classes = useStyles();
  const [activeSector, setActiveSector] = useState(null);
  const {
    list: { teamType },
  } = useSelector(DictionariesSelector);

  const openManageMembers = useCallback(
    e => {
      e.stopPropagation();
      const selectedSector = sectors.find(item => item.name === e.currentTarget.dataset.name);
      setActiveSector(selectedSector);
    },
    [sectors]
  );

  const filteredTeams = useMemo(() => teamType.filter(item => ALLOWED_TEAMS.includes(item.teamType)), [teamType]);

  const closeManageMembers = useCallback(() => setActiveSector(null), []);

  return (
    <>
      {sectors?.length ? (
        <>
          <TitleWithDivider title="Sectors" />
          {sectors?.map(item => (
            <Collapse key={item} className={classes.collapseList}>
              <Panel
                key={item}
                header={
                  <div className={classes.header}>
                    <div className={classes.headerName}>{item.name}</div>
                    <div className={classes.memberLength}>
                      <img src={memberIcon} alt="member" />
                      {Object.keys(item?.users)?.length || 0}
                    </div>
                    <CustomButton
                      className={classes.manageMembers}
                      onClick={openManageMembers}
                      data-name={item.name}
                      size="small"
                      type="primary"
                      color="outlined"
                      text="Manage Members"
                    />
                  </div>
                }
              >
                {filteredTeams.map(team => {
                  const member = item?.users.find(item => item.team === team.teamType) || {};
                  return (
                    <div key={team.teamType} className={classes.memberItem}>
                      <div className={classes.nameContainer}>{team.teamType}</div>
                      <div className={classes.titleValue}>
                        <span className={classes.subtitle}>ASSIGNEE</span>
                        <span className={classes.value}>{getValue(member.name)}</span>
                      </div>
                      <div className={classes.titleValue}>
                        <span className={classes.subtitle}>EMAIL</span>
                        <span className={classes.value}>{getValue(member.email)}</span>
                      </div>
                      <div className={classes.titleValue}>
                        <span className={classes.subtitle}>PHONE NUMBER</span>
                        <span className={classes.value}>{getValue(member.phone)}</span>
                      </div>
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
          ))}
        </>
      ) : (
        <div className={classes.noSectors}>No Sectors</div>
      )}

      {activeSector && (
        <ManageMembersModel
          TSRList={TSRList}
          teams={filteredTeams}
          onClose={closeManageMembers}
          sector={activeSector}
          updateData={updateData}
        />
      )}
    </>
  );
};

export default Sectors;
