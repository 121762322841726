import React from "react";
import { Divider, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";

const useStyles = makeStyles({
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  frameContainer: {
    overflow: "scroll",
    height: 610,
  },
  frame: {
    height: "4000px",
    width: "100%",
  },
});

const PreviewModal = ({ title, open, contractLoading, onCancel, previewUrl, height = 4000 }) => {
  const classes = useStyles();

  return (
    <Modal title={title} className={classes.modalContent} open={open} onCancel={onCancel} closable={false} footer={[]}>
      <Spin spinning={contractLoading}>
        {!!previewUrl && (
          <div className={classes.frameContainer}>
            <iframe
              id="iframeContractPreview"
              src={`${previewUrl}#toolbar=0`}
              className={classes.frame}
              style={{ height }}
            />
          </div>
        )}
      </Spin>
      <Divider />
      <Space>
        <CustomButton onClick={onCancel} type="primary" color="outlined" size="small" text="Close" />
      </Space>
    </Modal>
  );
};

export default PreviewModal;
