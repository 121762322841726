import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import B2cTable from "components/Common/table";
import { requestListColumns } from "./requestListColumns";
import NGISpinner from "components/Common/NGISpinner";

const Requests = () => {
  const [isLoading, setLoader] = useState(false);
  const [items, setItems] = useState(null);

  const onApprove = useCallback(() => {}, []);
  const onReject = useCallback(() => {}, []);

  useEffect(() => {
    setLoader(true);
    setItems([{}]);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Col lg={12}>
            {isLoading ? (
              <NGISpinner />
            ) : (
              <>
                {!!items?.length ? (
                  <>
                    <B2cTable enableSearch={false} columns={requestListColumns({ onApprove, onReject })} data={items} />
                  </>
                ) : (
                  <Card>
                    <CardBody className="text-center">
                      <i className="bx bx-warning" />
                      <h5>No transactions available</h5>
                      <p className="text-muted">No data.</p>
                    </CardBody>
                  </Card>
                )}
              </>
            )}
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Requests;
