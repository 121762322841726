import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import MarketingInitiativesFilter from "pages/marketing/marketingInitiatives/marketingInitiativesFilter";
import { getMarketingInitiatives } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { marketingInitiativesListColumns } from "pages/marketing/marketingInitiatives/marketingInitiativesListColumns";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import CustomLink from "components/Common/buttons/CustomLink";
import config from "appconfig";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  withCopyIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "&> span": {
      marginRight: 10,
    },
  },
});
const MarketingInitiatives = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    cognito: { landingDomain },
  } = config;

  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState(null);
  const [filterOptions, setFilterOptions] = useState({});
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.MARKETING_INITIATIVES);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    handleFilter(filters);
  }, []);

  const handleFilter = useCallback(
    (newFilterOptions = {}) => {
      setLoader(true);
      setFilterOptions(newFilterOptions);
      setFilters(newFilterOptions);
      getMarketingInitiatives({
        ...newFilterOptions,
      })
        .then(res => {
          const {
            result: { items },
          } = res?.data;
          if (items === 0) {
            warnMessage("No initiatives were found");
          }
          setList(res?.data);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
          setList(null);
          defaultNgiErrorMethod(err);
        });
    },
    [filterOptions]
  );

  const columns = useMemo(() => marketingInitiativesListColumns(classes, landingDomain), []);

  return (
    <>
      <div className="page-content" data-testid="metersComponent">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomLink to={`/marketing/initiative/new`}>
                <CustomButton
                  roles={[USER_ROLES.SYSTEM_FULL_ADMINS]}
                  type="primary"
                  icon={<i className="bx bx-plus-circle" />}
                  text="New initiative"
                />
              </CustomLink>
            </div>

            <MarketingInitiativesFilter handleFilter={handleFilter} isLoading={isLoading} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.MARKETING_INITIATIVES}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleFilter}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default MarketingInitiatives;
