import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import {
  createTAMRequest,
  fetchCustomerById,
  fetchTransactionDetails,
  retrieveTAMUploadUrlByType,
  uploadPictureToS3Bucket,
} from "utils/api";
import Title from "antd/es/typography/Title";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { customDateFormatWithTime } from "components/Common/formatter";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { VALIDATION_RULES } from "pages/meter-management/meters/variables";
import NGIInput from "components/Common/inputs/NGIInput";
import NGIForm from "components/Common/form/NGIForm";
import {
  AMOUNT_TITLE,
  AMOUNT_VALIDATION,
  disabledFieldsForType,
  HIDE_SINGLE_TYPES,
  showFieldsMapping,
  TYPES,
} from "pages/TAM/TAMRequests/constants";
import { debounce } from "lodash";
import { getValidPreparedData } from "pages/TAM/TAMRequests/utils";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import moment from "moment/moment";
import { USER_CURRENCY } from "utils/constants";
import removeIcon from "assets/images/svg/Close.svg";
import cameraIcon from "assets/images/svg/Camera.svg";
import { base64toBuffer, getTrimmedObjectValues, toBase64, uuidv4 } from "utils/helpers/functions";
import classnames from "classnames";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "50%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  errorMsg: {
    color: "#ff4d4f",
    fontSize: 10,
    marginLeft: 18,
  },
  form: {
    width: "100%",

    "& .ant-row": {
      width: "100%",
    },
    "& .ant-typography": {
      fontWeight: 400,
      marginTop: 15,
    },
  },
  typeSelect: {
    width: 250,
  },
  dateRange: {
    display: "flex",
    alignItems: "center",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F5F5F5",
    borderRadius: 5,
    padding: 5,
    marginBottom: 10,
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
  },
  fileInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "0 10px",
  },
  fileTitle: {
    fontWeight: 600,
    fontSize: 12,
  },
  fileSize: {
    fontWeight: 300,
    fontSize: 10,
  },
  fileRemove: {
    padding: "0 8px",

    "& :hover": {
      cursor: "pointer",
    },
  },
  uploadContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "20px 0",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    fontWeight: 600,
    color: "white",
    padding: 12,
    height: 40,
    borderRadius: 20,
    textTransform: "uppercase",
    fontSize: "11px!important",

    "&> img": {
      marginRight: 10,
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  disabled: {
    cursor: "not-allowed!important",
    opacity: 0.6,
  },
});

const NewTAMRequestModal = ({ onClose, updateList }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [isSearchCustomer, setIsSearchCustomer] = useState(false);
  const [isSearchReceipt, setIsSearchReceipt] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [extendedData, setExtendedData] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [customerNotFound, setCustomerNotFound] = useState(false);
  const [receiptNotFound, setReceiptNotFound] = useState(false);
  const [errors, setErrors] = useState({ valid: false });
  const [files, setFiles] = useState([]);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const {
    list: { TAMReasons },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.TAM_REASONS));
  }, []);

  useEffect(() => {
    setSelectedType(TAMReasons[0]);
  }, [TAMReasons]);

  const handleChange = useCallback(value => {
    form.resetFields();
    setIsFormTouched(true);
    setSelectedType(value);
    setExtendedData(null);
    setTransactionDetails(null);
    setCustomerNotFound(false);
    setReceiptNotFound(false);
    setFiles([]);
  }, []);

  const changeHandler = useCallback(
    event => {
      const id = event.target.value;
      if (id.includes(" ")) return;
      if (id) {
        setIsSearchCustomer(true);
        fetchCustomerById(id)
          .then(({ data }) => {
            setCustomerNotFound(false);
            setIsSearchCustomer(false);
            setExtendedData(data.result);
          })
          .catch(() => {
            setCustomerNotFound(true);
            setIsSearchCustomer(false);
            setExtendedData(null);
            warnMessage("Customer is not found");
          });
      }
    },
    [form]
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 1000), []);

  const changeReceiptHandler = useCallback(event => {
    const id = event.target.value;
    if (id.includes(" ")) return;
    if (id) {
      setIsSearchReceipt(true);
      fetchTransactionDetails(event.target.value)
        .then(({ data }) => {
          setReceiptNotFound(false);
          setIsSearchReceipt(false);
          setTransactionDetails(data?.result);
        })
        .catch(() => {
          setReceiptNotFound(true);
          setIsSearchReceipt(false);
          setTransactionDetails(null);
          warnMessage("Receipt details are not found");
        });
    }
  }, []);

  const debouncedChangeReceiptHandler = useMemo(() => debounce(changeReceiptHandler, 1000), []);

  const getPictureIds = useCallback(
    async fileList =>
      await Promise.all(
        fileList.map(item =>
          retrieveTAMUploadUrlByType(item.fileType.replace("image/", ".")).then(({ data: { result } }) => {
            const base64Data = base64toBuffer(item.base64);
            return uploadPictureToS3Bucket(result.url, base64Data, item.fileType).then(() => result.fileName);
          })
        )
      ),
    []
  );

  const handleSave = useCallback(
    async values => {
      let photos = [];
      const { valid, data } = getValidPreparedData(values, selectedType, extendedData, transactionDetails, files);
      let dataToSend = getTrimmedObjectValues(data);
      if (valid) {
        setLoader(true);
        if (selectedType === TYPES.METER_MALFUNCTION) {
          photos = await getPictureIds(files);
          dataToSend = { ...dataToSend, photos };
        }

        createTAMRequest(dataToSend)
          .then(() => {
            successMessage("TAM request was created successfully");
            onClose();
            updateList();
            setLoader(false);
          })
          .catch(e => {
            setLoader(false);
            defaultNgiErrorMethod(e);
          });
      }
    },
    [extendedData, transactionDetails, selectedType, files]
  );

  const validateForm = useCallback(() => {
    const values = form.getFieldsValue();
    const { valid, ...errors } = getValidPreparedData(values, selectedType, extendedData, transactionDetails, files);
    setErrors({ valid, ...errors });
  }, [extendedData, transactionDetails, selectedType, form, files]);

  const handleFileChange = useCallback(async event => {
    const fileUploaded = event.target.files[0];
    const base64Data = await toBase64(fileUploaded);
    setFiles(prevState => [
      ...prevState,
      {
        _id: uuidv4(),
        name: fileUploaded.name,
        size: (fileUploaded.size / 1000).toFixed(2),
        base64: base64Data,
        fileType: fileUploaded.type,
      },
    ]);
    validateForm();
  }, []);

  const handleRemove = useCallback(event => {
    const id = event.target.dataset.id;
    setFiles(prevState => prevState.filter(item => item._id !== id));
  }, []);

  useEffect(() => {
    validateForm();
  }, [extendedData, transactionDetails, files]);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>NEW TAM REQUEST</div>
          <NGISelect
            showSearch
            className={classes.typeSelect}
            value={selectedType}
            onChange={handleChange}
            placeholder="Please select TAM case"
          >
            {TAMReasons?.filter(item => !HIDE_SINGLE_TYPES.includes(item))?.map(item => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))}
          </NGISelect>
        </div>
      }
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <NGIForm
            disabled={disabledFieldsForType.includes(selectedType)}
            onFinish={handleSave}
            onChange={validateForm}
            form={form}
            className={classes.form}
            name="newTAM-modal"
            layout="inline"
          >
            <Row>
              <Col span={12}>
                <Title level={5}>Customer ID</Title>
                <NGIFormItem
                  onChange={debouncedChangeHandler}
                  name="customerId"
                  rules={[
                    ...VALIDATION_RULES,
                    {
                      validator: (_, value) =>
                        !value.includes(" ") ? Promise.resolve() : Promise.reject(new Error("No spaces allowed")),
                    },
                  ]}
                >
                  <NGIInput suffix={<Spin size="small" spinning={isSearchCustomer} />} />
                </NGIFormItem>
                {!extendedData && customerNotFound && (
                  <div className={classes.errorMsg}>Customer details are not found. Please change ID.</div>
                )}
                {extendedData && errors.customer && <div className={classes.errorMsg}>{errors.customer}</div>}
              </Col>
              {!!selectedType && showFieldsMapping[selectedType].includes("transactionMode") && (
                <Col span={12}>
                  <Title level={5}>Transaction Mode</Title>
                  <NGIFormItem name="transactionMode" rules={[{ required: true, message: "Please select value" }]}>
                    <NGISelect showSearch onChange={validateForm}>
                      {["Stove Balance Payment Type", "Standard Payment Type"].map(item => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </NGISelect>
                  </NGIFormItem>
                </Col>
              )}
              {!!selectedType && showFieldsMapping[selectedType].includes("transactionId") && (
                <Col span={12}>
                  <Title level={5}>Receipt ID</Title>
                  <NGIFormItem
                    onChange={debouncedChangeReceiptHandler}
                    name="transactionId"
                    rules={[
                      ...VALIDATION_RULES,
                      {
                        max: 30,
                        message: "The max length is 30",
                      },
                      {
                        validator: (_, value) =>
                          !value.includes(" ") ? Promise.resolve() : Promise.reject(new Error("Space isn't allowed")),
                      },
                    ]}
                  >
                    <NGIInput suffix={<Spin size="small" spinning={isSearchReceipt} />} />
                  </NGIFormItem>
                  {!transactionDetails && receiptNotFound && (
                    <div className={classes.errorMsg}>Receipt details are not found. Please change ID.</div>
                  )}
                  {transactionDetails && errors.paymentType && (
                    <div className={classes.errorMsg}>{errors.paymentType}</div>
                  )}
                </Col>
              )}
              {!!selectedType && showFieldsMapping[selectedType].includes("amount") && (
                <Col span={12}>
                  <Title level={5}>{AMOUNT_TITLE[selectedType]}</Title>
                  <NGIFormItem name="amount" rules={[...VALIDATION_RULES, ...AMOUNT_VALIDATION[selectedType]]}>
                    <NGIInput />
                  </NGIFormItem>
                  {transactionDetails && errors.amount && <div className={classes.errorMsg}>{errors.amount}</div>}
                </Col>
              )}
              {!!selectedType && showFieldsMapping[selectedType].includes("date") && (
                <Col span={12}>
                  <Title level={5}>Date Range</Title>
                  <div className={classes.dateRange}>
                    <NGIFormItem name="dateFrom" rules={VALIDATION_RULES}>
                      <NGIDatePicker onChange={validateForm} placeholder="Start Date" disabledFutureDates allowClear />
                    </NGIFormItem>
                    →
                    <NGIFormItem name="dateTo" rules={VALIDATION_RULES}>
                      <NGIDatePicker
                        onChange={validateForm}
                        placeholder="End Date"
                        disableDates={current =>
                          form.getFieldValue("dateFrom") > current || moment().add(0, "days") <= current
                        }
                        allowClear
                      />
                    </NGIFormItem>
                  </div>
                  {transactionDetails && errors.date && <div className={classes.errorMsg}>{errors.date}</div>}
                </Col>
              )}
              {!!selectedType && showFieldsMapping[selectedType].includes("files") && (
                <Col span={24}>
                  <Title level={5}>Attach Photos</Title>
                  {files &&
                    files.map(item => (
                      <div className={classes.fileItem} key={item._id}>
                        <div className={classes.container}>
                          <img className={classes.filePreview} src={item.base64} alt="preview img" />
                          <div className={classes.fileInfo}>
                            <div className={classes.fileTitle}>{item.name}</div>
                            <div className={classes.fileSize}>{`${item.size} kb`}</div>
                          </div>
                        </div>
                        <div className={classes.fileRemove}>
                          <img onClick={handleRemove} data-id={item._id} src={removeIcon} alt="remove" />
                        </div>
                      </div>
                    ))}
                  <div className={classes.uploadContainer}>
                    <label
                      onChange={handleFileChange}
                      htmlFor="formId"
                      className={classnames(classes.uploadButton, files.length === 5 && classes.disabled)}
                    >
                      <input disabled={files.length === 5} accept="image/*" type="file" id="formId" hidden />
                      <img src={cameraIcon} alt="camera" />
                      Add photo
                    </label>
                  </div>
                  {errors.files && !files.length && isFormTouched && (
                    <div className={classes.errorMsg}>Please attach photos</div>
                  )}
                </Col>
              )}
            </Row>

            <Row>
              <Col span={24}>
                <Title level={5}>Comment</Title>
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      max: 100,
                      message: "The max length is 100",
                    },
                  ]}
                >
                  <NGITextArea
                    disabled={disabledFieldsForType.includes(selectedType)}
                    rows={3}
                    placeholder="Please add your comments"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {!!selectedType && showFieldsMapping[selectedType].includes("correctMode") && (
                <Col span={12}>
                  <Title level={5}>Problem</Title>
                  <NGIFormItem name="correctMode" initialValue="Installation Payment Type">
                    <NGISelect disabled>
                      <Option value="Installation Payment Type">Installation Payment Type</Option>
                    </NGISelect>
                  </NGIFormItem>
                </Col>
              )}
            </Row>
          </NGIForm>
          {!!extendedData && (
            <>
              <Divider />
              <div className={classes.container}>
                <div className={classes.listBlock}>
                  <div className={classes.item}>
                    <div className={classes.title}>CUSTOMER ID</div>
                    <div className={classes.value}>{extendedData?.id ?? "-"}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>payment type</div>
                    <div className={classes.value}>
                      {transactionDetails ? transactionDetails?.transactionType : "-"}
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>AMOUNT ({USER_CURRENCY})</div>
                    <div className={classes.value}>
                      {transactionDetails ? transactionDetails?.transactionAmount : "-"}
                    </div>
                  </div>
                </div>
                <div className={classes.listBlock}>
                  <div className={classes.item}>
                    <div className={classes.title}>CUSTOMER NAME</div>
                    <div className={classes.value}>
                      {extendedData?.firstName ? `${extendedData?.firstName} ${extendedData?.lastName}` : "-"}
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>DATE</div>
                    <div className={classes.value}>
                      {transactionDetails ? customDateFormatWithTime(transactionDetails?.transactionDateTime) : "-"}
                    </div>
                  </div>

                  {selectedType === TYPES.STANDARD_PAYMENT_TRANSACTION_PROCESSING_FAILURE && (
                    <div className={classes.item}>
                      <div className={classes.title}>Processing DATE</div>
                      <div className={classes.value}>
                        {transactionDetails ? customDateFormatWithTime(transactionDetails?.processingDateTime) : "-"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Spin>
      <Divider />
      <Space>
        <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
        <CustomButton
          onClick={form.submit}
          disabled={disabledFieldsForType.includes(selectedType) || !errors.valid}
          type="primary"
          size="small"
          text="SUBMIT REQUEST"
        />
      </Space>
    </Modal>
  );
};

export default NewTAMRequestModal;
