import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import {
  fetchCustomerBalance,
  fetchCustomerById,
  fetchCustomerConsumptionStat,
  fetchCustomerCreditBalance,
  fetchCustomerTransactions,
  fetchDepotsList,
  getUsersList,
  readCookingSessionsAPI,
  retrieveAllAssets,
  retrieveAssets,
  retrieveCustomerContractUrl,
  retrieveCustomerPhotoUrlByType,
  retrieveCustomersTasksHistory,
  retrieveMeterPinDetails,
  retrieveSurveysListByIdOrName,
} from "utils/api";
import Header from "pages/customer-management/customerDetails/Header";
import { Spin } from "antd";
import DataTabs from "pages/customer-management/customerDetails/DataTabs";
import PackageModal from "pages/customer-management/customerDetails/PackageModal";
import DepotModal from "pages/customer-management/customerDetails/DepotModal";
import TSRModal from "pages/customer-management/customerDetails/TSRModal";
import { ACTION_TYPE } from "pages/customer-management/customers/constants";
import { getPackages } from "store/packages/actions";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import moment from "moment/moment";
import { extractPromisesResult } from "utils/helpers/functions";
import { getLeadQuestionnaire } from "pages/operations/leadDetails/utils";
import { USER_ROLES } from "utils/constants";
import NotFoundContainer from "components/Common/NotFoundContainer";

const CustomerDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [contractUrl, setContractUrl] = useState(null);
  const [isAdditionalDataLoading, setIsAdditionalDataLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(null);
  const [cookingSessions, setCookingSessions] = useState(null);
  const [assets, setAssets] = useState(null);
  const [tasksHistory, setTasksHistory] = useState(null);
  const [meterPinDetails, setMeterPinDetails] = useState(null);
  const [balanceBookTransactions, setBalanceBookTransactions] = useState(null);
  const [badCookingSessions, setBadCookingSessions] = useState([]);
  const [modalTypeOpen, setModalTypeOpen] = useState("");
  const [consumptionStat, setConsumptionStat] = useState(null);
  const [lastCylinderChange, setLastCylinderChange] = useState(null);
  const [photoDetails, setPhotoDetails] = useState(null);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [creditBook, setCreditBook] = useState(null);
  const [creditBookTransactions, setCreditBookTransactions] = useState(null);
  const [TSRList, setTSRList] = useState([]);
  const [allDepotsList, setAllDepotsList] = useState([]);
  const dispatch = useDispatch();

  const closeModal = useCallback(() => setModalTypeOpen(""), []);
  const openModal = useCallback(type => setModalTypeOpen(type), []);
  const handleUpdate = useCallback(data => setCustomer(prevState => ({ ...prevState, ...data })), []);

  useEffect(() => {
    setIsLoading(true);
    fetchCustomerById(id)
      .then(({ data }) => {
        setIsLoading(false);
        setCustomer(data.result);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
    getUsersList({
      role: [USER_ROLES.TECHNICIANS, USER_ROLES.MTSR].join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
    retrieveCustomerContractUrl(id).then(({ data }) => setContractUrl(data.result));
    fetchCustomerBalance(id).then(({ data }) => {
      setCustomerBalance(data?.result?.items[0]);
    });
    fetchCustomerConsumptionStat(id).then(({ data }) => {
      setConsumptionStat(data?.result);
    });
    retrieveAssets({ customerId: id }).then(({ data }) => {
      const lastCylinderChange = data?.result?.items?.find(item => item.typeGroup === "Cylinder");
      setLastCylinderChange(lastCylinderChange?.updatedAt && moment().diff(lastCylinderChange?.updatedAt, "days"));
    });
    fetchDepotsList({
      pageSize: 1000,
    }).then(res => {
      const {
        result: { items },
      } = res?.data;
      setAllDepotsList(items || []);
    });

    Promise.allSettled([
      retrieveCustomerPhotoUrlByType(id, "id"),
      retrieveCustomerPhotoUrlByType(id, "personalPhoto"),
      retrieveCustomerPhotoUrlByType(id, "housePhoto"),
    ]).then(data => {
      const [customerIdPhoto, customerPersonalPhoto, customerHousePhoto] = extractPromisesResult(data);
      setPhotoDetails({
        idPhotoUrl: customerIdPhoto?.data?.result?.url,
        personalPhotoUrl: customerPersonalPhoto?.data?.result?.url,
        housePhotoUrl: customerHousePhoto?.data?.result?.url,
      });
    });

    handleBadCookingSessions();
    handleCookingSessions();
    handleUpdateAssets();
    handleUpdateHistory();
    handleBalanceBookTransactions();
    handleUpdateCreditBook();
    handleCreditBookTransactions();
    updateMeterPinDetails();
    dispatch(getPackages());
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  useEffect(() => {
    if (customer?.id) {
      const questionnaireToGet = getLeadQuestionnaire(customer);
      retrieveSurveysListByIdOrName(questionnaireToGet).then(res => {
        setQuestionnaire(res.data.result);
      });
    }
  }, [customer?.id]);

  const handleUpdateHistory = useCallback(
    (filters = {}) => {
      setIsAdditionalDataLoading(true);
      retrieveCustomersTasksHistory({ ...filters, customerId: id }).then(({ data }) => {
        setIsAdditionalDataLoading(false);
        setTasksHistory(data);
      });
    },
    [id]
  );

  const updateMeterPinDetails = useCallback(() => {
    setIsAdditionalDataLoading(true);
    retrieveMeterPinDetails({ customerId: id }).then(({ data }) => {
      setIsAdditionalDataLoading(false);
      setMeterPinDetails(data?.result);
    });
  }, [id]);

  const handleBadCookingSessions = useCallback((filters = {}) => {
    setIsAdditionalDataLoading(true);
    readCookingSessionsAPI({ ...filters, customerId: id, bad: true }).then(({ data }) => {
      setIsAdditionalDataLoading(false);
      setBadCookingSessions(data);
    });
  }, []);

  const handleCookingSessions = useCallback((filters = {}) => {
    setIsAdditionalDataLoading(true);
    readCookingSessionsAPI({ ...filters, customerId: id, bad: false }).then(({ data }) => {
      setIsAdditionalDataLoading(false);
      setCookingSessions(data);
    });
  }, []);

  const handleUpdateAssets = useCallback((filters = {}) => {
    setIsAdditionalDataLoading(true);
    retrieveAllAssets({ ...filters, customerId: id }).then(({ data }) => {
      setIsAdditionalDataLoading(false);
      setAssets(data);
    });
  }, []);

  const handleBalanceBookTransactions = useCallback((filters = {}) => {
    setIsAdditionalDataLoading(true);
    fetchCustomerTransactions({ ...filters, customerId: id, balancePaid: "YES", pageSize: 1000 }).then(({ data }) => {
      setIsAdditionalDataLoading(false);
      setBalanceBookTransactions(data);
    });
  }, []);

  const handleUpdateCreditBook = useCallback(() => {
    setIsAdditionalDataLoading(true);
    fetchCustomerCreditBalance(id).then(({ data }) => {
      setIsAdditionalDataLoading(false);
      setCreditBook(data?.result);
    });
  }, []);

  const handleCreditBookTransactions = useCallback((filters = {}) => {
    setIsAdditionalDataLoading(true);
    fetchCustomerTransactions({ ...filters, customerId: id, campaignCreditPaid: "YES", pageSize: 1000 }).then(
      ({ data }) => {
        setIsAdditionalDataLoading(false);
        setCreditBookTransactions(data);
      }
    );
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              <>
                <Header
                  photoDetails={photoDetails}
                  customer={customer}
                  onAction={openModal}
                  handleUpdateHistory={handleUpdateHistory}
                />
                <DataTabs
                  isLoading={isAdditionalDataLoading}
                  contractUrl={contractUrl}
                  customer={customer}
                  cookingSessions={cookingSessions}
                  badCookingSessions={badCookingSessions}
                  assets={assets}
                  customerBalance={customerBalance}
                  tasksHistory={tasksHistory}
                  balanceBookTransactions={balanceBookTransactions}
                  handleBadCookingSessions={handleBadCookingSessions}
                  handleCookingSessions={handleCookingSessions}
                  updateAssets={handleUpdateAssets}
                  updateHistory={handleUpdateHistory}
                  updateBalanceBookTransactions={handleBalanceBookTransactions}
                  consumptionStat={consumptionStat}
                  lastCylinderChange={lastCylinderChange}
                  photoDetails={photoDetails}
                  questionnaire={questionnaire}
                  creditBook={creditBook}
                  creditBookTransactions={creditBookTransactions}
                  handleCreditBookTransactions={handleCreditBookTransactions}
                  meterPinDetails={meterPinDetails}
                />
                {modalTypeOpen === ACTION_TYPE.ASSIGN_PACKAGE && (
                  <PackageModal
                    isOpen={Boolean(modalTypeOpen)}
                    customer={customer}
                    onClose={closeModal}
                    onUpdate={handleUpdate}
                  />
                )}
                {modalTypeOpen === ACTION_TYPE.ASSIGN_DEPOT && (
                  <DepotModal
                    isOpen={Boolean(modalTypeOpen)}
                    customer={customer}
                    onClose={closeModal}
                    onUpdate={handleUpdate}
                    TSRList={TSRList}
                    depotsList={allDepotsList}
                  />
                )}
                {modalTypeOpen === ACTION_TYPE.LINK_TSR && (
                  <TSRModal
                    isOpen={Boolean(modalTypeOpen)}
                    customer={customer}
                    onClose={closeModal}
                    onUpdate={handleUpdate}
                  />
                )}
              </>
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default CustomerDetails;
