import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveAllTasks } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { taskRequestsListColumns } from "pages/operations/task-requests/taskRequestsListColumns";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import TaskRequestsSearch from "pages/operations/task-requests/TaskRequestsSearch";
import ViewTaskRequestModal from "pages/operations/task-requests/ViewTaskRequestModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const TaskRequests = () => {
  const [isLoading, setLoader] = useState(false);
  const [tasksList, setTasksList] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const { setFilters } = useStoreFilters(FILTER_PAGE.TASK_REQUESTS);
  const dispatch = useDispatch();

  const hideRoles = [
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
  ];

  useEffect(() => {
    handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.TASK_CATEGORY));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Task Requests were not found");
    }
    setTasksList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    retrieveAllTasks({
      status: "Pending",
      depot: AUTH_USER?.depotName,
      sortBy: "createdAt",
      ...filterOptions,
    })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setTasksList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const openDetails = useCallback(data => setActiveItem(data), []);
  const closeDetails = useCallback(() => setActiveItem(null), []);

  const columns = useMemo(() => {
    const col = taskRequestsListColumns(openDetails);
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [hideRoles, AUTH_USER]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <TaskRequestsSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.TASK_REQUESTS}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={tasksList}
              updateList={handleSearch}
            />
          </Col>
        </Container>
      </div>
      {activeItem && <ViewTaskRequestModal onClose={closeDetails} update={handleSearch} activeItem={activeItem} />}
    </>
  );
};

export default TaskRequests;
