import React, { useCallback } from "react";
import { Col, Form, Modal, Space } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import { useSelector } from "react-redux";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIForm from "components/Common/form/NGIForm";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NGISelect from "components/Common/inputs/NGISelect";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import { retrieveCylinderReportsDownloadUrl } from "utils/api";
import { apiByType } from "pages/cylinder-weight-reports/reports/constants";
import moment from "moment/moment";
import { errorMessage } from "components/Common/responses/message";
import { downloadURI } from "utils/helpers/functions";
import { uniq } from "lodash";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { USER_TENANT_NAME } from "utils/constants";

const DownloadModal = ({ onClose, type }) => {
  const [form] = Form.useForm();
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const onDownload = useCallback(() => {
    const { date, operationDepot } = form.getFieldsValue();
    const formattedDate = moment(date).format("YYYY-MM-DD");

    retrieveCylinderReportsDownloadUrl({
      type: apiByType[type],
      date: formattedDate,
      operationDepot,
    })
      .then(({ data }) => {
        downloadURI(data.result.url, `Weight Update Report - ${formattedDate}`);
      })
      .catch(() => {
        errorMessage("Reports were not found");
      });
  }, [type]);

  return (
    <Modal open={true} title="Download reports" closable={false} onCancel={onClose} footer={[]}>
      <NGIForm name="downloadReportForm" form={form} onFinish={onDownload}>
        <Col span={24}>
          <Title level={5}>Date</Title>
          <NGIFormItem name="date" rules={[{ required: true, message: "Please select depot" }]}>
            <NGIDatePicker disabledFutureDates allowClear />
          </NGIFormItem>
        </Col>
        <Col span={24}>
          <Title level={5}>Operation Depot</Title>
          <NGIFormItem name="operationDepot" rules={[{ required: true, message: "Please select depot" }]}>
            <NGISelect showSearch placeholder="Select depot" allowClear>
              {depot[USER_TENANT_NAME] &&
                uniq(depot[USER_TENANT_NAME])
                  ?.sort()
                  ?.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
            </NGISelect>
          </NGIFormItem>
        </Col>
        <Space>
          <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
          <CustomButton htmlType="submit" type="primary" size="small" text="Download" />
        </Space>
      </NGIForm>
    </Modal>
  );
};

export default DownloadModal;
