import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

export const simCardsReportsListColumns = () => [
  {
    title: "ICC ID",
    dataIndex: "iccid",
    render: textFormatter,
  },
  {
    title: "Updated Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Updated By",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Report Status",
    dataIndex: "status",
    render: textFormatter,
  },
];
