import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(180deg, #666666 0%, #444444 100%)",
    width: 38,
    height: 38,
    borderRadius: 5,

    "&:hover": {
      cursor: "pointer",
    },

    "& svg": {
      fontSize: 20,
      color: "#FFF",
    },
    "& i": {
      fontSize: 20,
      color: "#FFF",
    },
  },
  disabled: {
    pointerEvents: "none",
    cursor: "not-allowed!important",
    opacity: 0.4,
    background: "linear-gradient(180deg, #666666 0%, #444444 100%) !important",
  },
  link: {
    width: "100% !important",
    height: "100% !important",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
  primary: {
    width: 60,
    height: 60,
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",

    "&>img": {
      borderRadius: "0px!important",
    },
  },
});

const ButtonGroup = ({
  roles,
  hideRoles,
  handleClick,
  icon,
  color,
  tooltipTitle = null,
  link = null,
  disabled = false,
  children,
}) => {
  const classes = useStyles();

  if (!userHasAdminRole()) {
    if (userHasOneOfRoles(hideRoles)) {
      return null;
    }
    if (roles && !userHasOneOfRoles(roles)) {
      return null;
    }
  }
  return (
    <Tooltip placement="bottom" title={tooltipTitle} color="#444444">
      <div
        className={classnames(classes.root, disabled && classes.disabled, color === "primary" && classes.primary)}
        onClick={handleClick}
      >
        {!!link ? (
          <CustomLink className={classes.link} to={link}>
            {icon && <img src={icon} alt="action-icon" />}
          </CustomLink>
        ) : (
          icon && <img src={icon} alt="action-icon" />
        )}
        {children}
      </div>
    </Tooltip>
  );
};
export default ButtonGroup;
