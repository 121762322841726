import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "antd";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    "& .ant-checkbox-inner": {
      borderRadius: 5,
      width: 24,
      height: 24,
    },
    "& .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner":
      {
        borderColor: "#0093ff",
      },
    "& .ant-checkbox-checked::after": {
      borderColor: "#0093ff",
      borderRadius: 5,
    },
    "& .ant-checkbox-inner::after": {
      left: "33%",
    },
    "& .ant-checkbox-checked .ant-checkbox-inner": {
      background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
      border: "none",
    },
  },
});

const NGICheckbox = ({ children, className = "", ...props }) => {
  const classes = useStyles();
  return (
    <Checkbox {...props} className={classnames(classes.root, className)}>
      {children}
    </Checkbox>
  );
};
export default NGICheckbox;
