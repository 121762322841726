import React, { useCallback, useEffect, useState } from "react";
import { Divider, Modal, Space, Spin } from "antd";
import { transferAssets } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import Title from "antd/lib/typography/Title";
import NGIInput from "components/Common/inputs/NGIInput";
import closeIcon from "assets/images/svg/closeIcon.svg";
import classnames from "classnames";
import InfoModal from "components/Common/modals/InfoModal";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    marginBottom: 24,
  },
  mandatory: {
    fontSize: 9,
  },
  tabs: {
    "&.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav": {
      padding: "0px!important",
      margin: 0,
    },
  },
  scanBtnContainer: {
    marginLeft: 20,
  },
  scanButton: {
    height: 50,
  },
  row: {
    display: "flex",
    padding: "20px 0",
    alignItems: "flex-end",
    position: "relative",
  },
  meterList: {},
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: "#F5F5F5",
    borderRadius: 30,
    padding: "10px 15px",
  },
  itemTitle: {
    fontWeight: 500,
    color: "#444444",
  },
  itemButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: "50%",
    width: 32,
    height: 32,
    background: "#fff",
    cursor: "pointer",
  },
  inputContainer: {
    flex: 1,
  },
  errorMessage: {
    position: "absolute",
    left: 14,
    bottom: 5,
    fontSize: 10,
    color: "#ff4d4f",
  },
  hasError: {
    border: "1px solid #ff4d4f",
  },
  notFoundItemError: {
    fontSize: 10,
    color: "#ff4d4f",
    left: 14,
    position: "relative",
  },
  itemContainer: {
    marginBottom: 10,
  },
});

const MeterReceiveModal = ({ isVisible, onClose }) => {
  const classes = useStyles();
  const [meters, setMeters] = useState([]);
  const [metersNotFound, setMetersNotFound] = useState(null);
  const [isLoading, setLoader] = useState(false);
  const [value, setValue] = useState("");
  const [hasError, setHasError] = useState(false);
  const [showFlowModal, setShowFlowModal] = useState(false);

  const closeFlowModal = useCallback(() => {
    setShowFlowModal(false);
    setValue("");
  }, []);
  const openFlowModal = useCallback(() => setShowFlowModal(true), []);

  useEffect(() => {
    if (hasError) {
      openFlowModal();
    }
  }, [hasError]);

  const onSubmit = useCallback(() => {
    const data = {
      depot: "Sunflag",
      assets: meters,
    };
    setLoader(true);
    transferAssets(data, "qrCode")
      .then(() => {
        setLoader(false);
        setMetersNotFound(null);
        successMessage("Meters were transferred successfully");
      })
      .catch(err => {
        if (err.response?.data?.errors[0]?.items) {
          setMetersNotFound(err.response?.data?.errors[0]?.items);
        }
        setLoader(false);
        defaultNgiErrorMethod(err, 5);
      });
  }, [meters]);

  const onAdd = useCallback(
    () =>
      setMeters(prevState => {
        const valueToCheck = value.trim();
        if (valueToCheck && !prevState.includes(valueToCheck)) {
          setValue("");
          return [...prevState, valueToCheck];
        }
        prevState.includes(valueToCheck) && setHasError(true);
        return prevState;
      }),
    [value]
  );

  const onRemove = useCallback(e => {
    const meter = e.currentTarget.dataset.meter;
    setMeters(prevState => prevState.filter(item => item !== meter));
  }, []);

  const onChange = useCallback(
    e => {
      hasError && setHasError(false);
      setValue(e.target.value);
    },
    [hasError]
  );

  const handleScanMeter = useCallback(
    event => {
      if (event.key.toLowerCase() === "enter" && !!value) onAdd();
    },
    [value]
  );

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Receive Meter to Depot
            <span>Please fill in all mandatory fields</span>
          </div>
        </div>
      }
      className={classes.modal}
      open={isVisible}
      closable={false}
      onCancel={onClose}
      footer={[]}
      onOk={onSubmit}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className={classes.row}>
            <div className={classes.inputContainer}>
              <Title level={5}>Scan or Enter Meter Manually</Title>
              <NGIInput value={value} onChange={onChange} placeholder="Enter QR Code" onKeyDown={handleScanMeter} />
              {hasError && <span className={classes.errorMessage}>The meter is already added</span>}
            </div>
            <div className={classes.scanBtnContainer}>
              <CustomButton
                disabled={!value}
                onClick={onAdd}
                className={classes.scanButton}
                type="primary"
                text="Scan | add meter"
                size="small"
              />
            </div>
          </div>
          <div className={classes.meterList}>
            {meters.map(item => (
              <div key={item} className={classes.itemContainer}>
                <div className={classnames(classes.listItem, metersNotFound?.includes(item) && classes.hasError)}>
                  <div className={classes.itemTitle}>Meter / {item}</div>
                  <div className={classes.itemButton} data-meter={item} onClick={onRemove}>
                    <img src={closeIcon} alt="remove" />
                  </div>
                </div>
                {metersNotFound?.includes(item) && <div className={classes.notFoundItemError}>Item was not found</div>}
              </div>
            ))}
          </div>
        </div>
        <Divider />

        <Space>
          <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
          <CustomButton
            disabled={!meters.length}
            onClick={onSubmit}
            htmlType="submit"
            type="primary"
            text="Receive Meters"
            size="small"
          />
        </Space>
        {showFlowModal && <InfoModal onCancel={closeFlowModal} description={`The meter ${value} is already added!`} />}
      </Spin>
    </Modal>
  );
};

export default MeterReceiveModal;
