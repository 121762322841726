import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";

export const getFieldsData = (data, fieldsList) =>
  fieldsList
    .map(item => data[item])
    .filter(item => item)
    .join(", ");

export const preparedFilterData = (data, type) => {
  let filters = {};
  switch (type) {
    case "new":
      filters = {
        newFirmware: data.new,
      };
      break;
    case "old":
      filters = {
        oldFirmware: data.old,
      };
      break;
    case "both":
      filters = {
        newFirmware: data.new,
        oldFirmware: data.old,
      };
      break;
    case "processId":
      filters = {
        processId: data.processId,
      };
      break;
    default:
      return;
  }
  return {
    ...filters,
    ...(data?.date && {
      minCreatedAt: getStartOfTheDay(moment(data?.date[0])),
      maxCreatedAt: getEndOfTheDay(moment(data?.date[1])),
    }),
  };
};
