import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { allowedStatus, RESOLUTION_OPTIONS, RESOLUTIONS } from "./variables";
import { postRefundRequestsAPI, retrieveRefundRequestPhotoUrlAPI } from "utils/api";
import Title from "antd/es/typography/Title";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { customDateFormatWithTime } from "components/Common/formatter";
import NGIInput from "components/Common/inputs/NGIInput";
import NGITextArea from "components/Common/inputs/NGITextArea";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import ContractModal from "pages/customer-management/customerDetails/Tabs/ContractModal";
import { getTrimmedObjectValues, userHasOneOfRoles } from "utils/helpers/functions";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    marginBottom: 24,
    paddingTop: 20,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "50%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  testResultContainer: {
    display: "flex",
  },

  commentContainer: {
    marginTop: 20,
  },
  action: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    paddingBottom: 20,
  },
  actionTitle: {
    fontWeight: 600,
    margin: "20px 0 10px 0",
  },
  actionImage: {
    height: 60,
    borderRadius: 6,
    cursor: "pointer",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 14,
  },
});

const RefundRequestModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const { amount, createdAt, customerNumber, customerPhone, depot, id, status, type, updatedAt } = activeItem;

  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [photoData, setPhotoData] = useState(null);

  const [selectedType, setSelectedType] = useState(RESOLUTION_OPTIONS[0].value);
  const { getFilters } = useStoreFilters(FILTER_PAGE.REFUND_REQUESTS);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  useEffect(() => {
    if (photo?.url) {
      setIsLoading(true);
      const image = new Image();
      image.onload = function () {
        if (this.width > 0) {
          setPhotoData(
            <div className={classes.img}>
              <img onClick={openModal} src={photo?.url} alt="preview" />
            </div>
          );
        }
        setIsLoading(false);
      };
      image.onerror = function () {
        setPhotoData(
          <div className={classes.data}>
            {"The  photo is broken or in the incorrect format. Please re-upload a photo."}
          </div>
        );
        setIsLoading(false);
      };
      image.src = photo.url;
    } else setPhotoData(<div className={classes.data}>{"No uploaded photo"}</div>);
  }, [photo]);

  const enableActions = useMemo(
    () =>
      userHasOneOfRoles([
        USER_ROLES.SYSTEM_FULL_ADMINS,
        USER_ROLES.D_SM,
        USER_ROLES.A_SM,
        USER_ROLES.OPERATION_MANAGERS,
        USER_ROLES.SYSTEM_SUPPORT,
        USER_ROLES.TRAINING,
        USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
      ]),
    []
  );

  const handleChange = useCallback(value => {
    form.resetFields();
    setSelectedType(value);
  }, []);

  const handleSave = useCallback(
    data => {
      setIsLoading(true);

      postRefundRequestsAPI({ type: selectedType, options: { customerNumber, ...getTrimmedObjectValues(data) } })
        .then(() => {
          successMessage(`Request was saved successfully`);
          onUpdate(getFilters());
          onClose();
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [selectedType, customerNumber]
  );

  const handleRetrievePhotoUrl = useCallback(() => {
    setIsLoading(true);
    retrieveRefundRequestPhotoUrlAPI(id)
      .then(res => {
        setPhoto(res?.data?.result);
        setIsLoading(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    handleRetrievePhotoUrl();
  }, []);

  return (
    <Modal
      open={isOpen}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Refund request
            <span>
              CUSTOMER ID{" "}
              <b>
                <CustomLink underlined target="_blank" to={`/customers/customer/${customerNumber}`}>
                  {customerNumber}
                </CustomLink>
              </b>
            </span>
          </div>
          {status === allowedStatus && enableActions && (
            <NGISelect showSearch value={selectedType} onChange={handleChange} placeholder="Please select resolution">
              {RESOLUTION_OPTIONS.map(item => (
                <Option value={item.value} key={item.value}>
                  {item.name}
                </Option>
              ))}
            </NGISelect>
          )}
        </div>
      }
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <div>
            <div className={classes.container}>
              <div className={classes.listBlock}>
                <div className={classes.item}>
                  <div className={classes.title}>CUSTOMER PHONE NUMBER</div>
                  <div className={classes.value}>{customerPhone ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>REFUND AMOUNT</div>
                  <div className={classes.value}>{amount ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>TYPE</div>
                  <div className={classes.value}>{type ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>DEPOT</div>
                  <div className={classes.value}>{depot ?? "-"}</div>
                </div>
              </div>
              <div className={classes.listBlock}>
                <div className={classes.item}>
                  <div className={classes.title}>STATUS</div>
                  <div className={classes.value}>{status ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>CREATION DATE</div>
                  <div className={classes.value}>{customDateFormatWithTime(createdAt) ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>TRANSACTION DATE</div>
                  <div className={classes.value}>{customDateFormatWithTime(updatedAt) ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>TRANSACTION ID</div>
                  <div className={classes.value}>{id ?? "-"}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.action}>
            <div className={classes.actionTitle}>PHOTO ATTACHMENTS</div>
            {photoData}
          </div>
          {status === allowedStatus && (
            <Form form={form} name="for-repair" onFinish={handleSave}>
              <div className={classes.commentContainer}>
                {selectedType === RESOLUTIONS.RESOLVE ? (
                  <Col>
                    <Title level={5}>Transaction ID</Title>
                    <Form.Item name="transactionId" rules={[{ required: true, message: "Please enter value" }]}>
                      <NGIInput placeholder="Enter the transaction ID" />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col>
                    <Title level={5}>Rejection comments</Title>
                    <Form.Item name="comments" rules={[{ required: true, message: "Please enter value" }]}>
                      <NGITextArea placeholder="Please add additional comments here" />
                    </Form.Item>
                  </Col>
                )}
              </div>

              <Space>
                <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
                <CustomButton
                  htmlType="submit"
                  disabled={!selectedType || !enableActions}
                  type="primary"
                  size="small"
                  text="Confirm"
                />
              </Space>
            </Form>
          )}
          {showModal && <ContractModal isOpen={showModal} onClose={closeModal} url={photo?.url} />}
        </div>
      </Spin>
    </Modal>
  );
};

export default RefundRequestModal;
