import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getFormattedKeyName, getTrimmedObjectValues, userHasOneOfRoles } from "utils/helpers/functions";
import { Form, Modal, Spin } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import editIcon from "assets/images/svg/Pencil Drawing.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import { assetValidationItem, assetValidationItemReject, retrieveAssetPhotos } from "utils/api";
import {
  ASSET_STATES,
  DISABLED_EMPTY_FIELDS,
  EDITABLE_FIELDS,
  hideEmptyFields,
  SORTED_KEYS,
  STATES_MAPPING,
  STATUSES,
  TITLES,
  VALIDATION_RULES,
} from "pages/operations/validationTaskRequestDetails/constants";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import classnames from "classnames";
import CustomLink from "components/Common/buttons/CustomLink";
import { DuplicatesDetailsModel } from "pages/operations/validationTaskRequestDetails/DuplicatesDetailsModel";
import { getConfirmationMessage } from "pages/operations/validationTaskRequestDetails/utils";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_ROLES } from "utils/constants";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  buttons: {
    display: "flex",
    width: "50%",
    alignItems: "end",
    justifyContent: "flex-end",

    "& button+button": {
      marginLeft: 10,
    },
  },
  container: {
    display: "flex",
    width: "50%",
  },
  listBlock: {
    width: "100%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
    marginTop: 5,
  },
  editBtn: {
    cursor: "pointer",
  },
  formItem: {
    display: "flex",
    marginTop: 5,

    "& .ant-form-item": {
      width: "85%",
      marginRight: 5,
    },
  },
  form: {
    width: "100%",
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
    marginRight: 16,
    cursor: "pointer",
  },
  imgPreview: {
    width: "100%",
  },
  validated: {
    color: "#00b050",
  },
  failed: {
    color: "#ff4d4f",
  },
  link: {
    textDecoration: "underline!important",
    cursor: "pointer",
  },
});

const GeneralValidationTab = ({ task, type, update, setLoader }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [editableFields, setEditableFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [preview, setPreview] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [viewDuplicates, setViewDuplicates] = useState(false);
  const [approveFailedMsg, setApproveFailedMsg] = useState("");
  const [group, id] = type.split(">");
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isIdentifyConfirmationVisible, setIsIdentifyConfirmationVisible] = useState(false);
  const [isRejectConfirmationVisible, setIsRejectConfirmationVisible] = useState(false);

  const data = useMemo(
    () => task?.assets?.find(item => item.group === group && (item?.id ? item?.id === id : true)),
    [group, id]
  );

  const makeEditable = useCallback(e => {
    const field = e.currentTarget.dataset.fieldName;
    setEditableFields(prevState => {
      if (!prevState.includes(field)) {
        return [...prevState, field];
      }
      return prevState.filter(item => item !== field);
    });
  }, []);

  useEffect(async () => {
    if (data?.photos) {
      setIsLoading(true);
      const urls = await retrieveAssetPhotos(data.id, data.photos.join()).then(({ data: { result } }) =>
        result.map(item => item.url)
      );
      setIsLoading(false);
      setPictures(urls);
    }
  }, [data?.photos]);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
    });
    setEditableFields([]);
  }, [data]);

  const handleApprove = useCallback(
    (identifyAtDepot = false) => {
      closeConfirmationModal();
      closeIdentifyConfirmationModal();
      const dataToSend = {
        forceSave: true,
        assetItemData: {
          ...(identifyAtDepot && { identifyAtDepot }),
          ...getTrimmedObjectValues(data),
          ...getTrimmedObjectValues(form.getFieldsValue()),
        },
      };

      setDisabled(true);
      setLoader(true);
      assetValidationItem(task.id, "approve", data.id, dataToSend)
        .then(() => {
          successMessage(`Asset successfully ${identifyAtDepot ? "identified at depot" : "approved"}`);
          update();
          setLoader(false);
        })
        .catch(err => {
          const duplicatesErrorFound = err.response?.data?.errors?.find(
            item => item.code === "DUPLICATE_ASSET_IS_FOUND"
          );
          if (duplicatesErrorFound) {
            if (err.response?.data?.result?.duplicates) {
              const message = getConfirmationMessage(group, err.response?.data?.result?.duplicates);
              if (message) {
                setApproveFailedMsg(message);
                setLoader(false);
                return;
              }
            }
          }
          setLoader(false);
          update();
          defaultNgiErrorMethod(err);
        });
    },
    [data, task]
  );

  const closeConfirmationModal = () => setIsConfirmationVisible(false);
  const openConfirmationModal = () => setIsConfirmationVisible(true);

  const closeIdentifyConfirmationModal = () => setIsIdentifyConfirmationVisible(false);
  const openIdentifyConfirmationModal = () => setIsIdentifyConfirmationVisible(true);

  const closeRejectConfirmationModal = () => setIsRejectConfirmationVisible(false);
  const openRejectConfirmationModal = () => setIsRejectConfirmationVisible(true);

  const closeApproveModal = useCallback(() => {
    setApproveFailedMsg("");
    update();
  }, []);

  const handleReject = useCallback(() => {
    setDisabled(true);
    setLoader(true);
    closeRejectConfirmationModal();
    assetValidationItemReject(task.id)
      .then(() => {
        successMessage("Asset successfully rejected");
        update();
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        update();
        defaultNgiErrorMethod(err);
      });
  }, [task]);

  const handleIdentifyAtDepot = useCallback(() => handleApprove(true), [handleApprove]);

  const handleInputChange = useCallback(e => {
    const { name, value } = e.currentTarget;
    setDisabled(DISABLED_EMPTY_FIELDS.includes(name) && isEmpty(value));
  }, []);

  const openDuplicatesModal = useCallback(() => setViewDuplicates(true), []);

  const closeDuplicatesModal = useCallback(() => setViewDuplicates(false), []);

  const hideRoles = [USER_ROLES.FINANCE];

  const roles = useMemo(
    () => [
      USER_ROLES.A_DSM,
      USER_ROLES.D_SM,
      USER_ROLES.A_SM,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.WAREHOUSE,
      USER_ROLES.SALES_SUPPORT,
      task.customerId && USER_ROLES.DEPOT_CLERK,
    ],
    [task]
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Form form={form} className={classes.form} name="horizontal_login" layout="inline">
          <div className={classes.listBlock}>
            {SORTED_KEYS[group].map(key => {
              const title = TITLES[group]?.[key];
              if (!data[key] && hideEmptyFields.includes(key)) return null;
              if (key === "photos") {
                return (
                  <div className={classes.item} key={`${key}-${type}`}>
                    <div className={classes.title}>Attached photos</div>
                    <div className={classes.value}>
                      {isLoading ? (
                        <Spin spinning={true} />
                      ) : pictures.length ? (
                        pictures.map(item => (
                          <img
                            key={item}
                            alt="preview"
                            onClick={() => setPreview(item)}
                            className={classes.filePreview}
                            src={item}
                          />
                        ))
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                );
              }
              if (EDITABLE_FIELDS[group].includes(key)) {
                const rules = VALIDATION_RULES[group]?.[key] || [];
                return (
                  <div className={classes.item} key={key}>
                    <div className={classes.title}>{title || getFormattedKeyName(key)}</div>
                    <div className={classes.formItem}>
                      <Form.Item name={key} rules={[{ required: true, message: "Please enter value" }, ...rules]}>
                        <NGIInput
                          name={key}
                          onChange={handleInputChange}
                          placeholder={`Enter ${title || getFormattedKeyName(key)}`}
                          disabled={!editableFields.includes(key)}
                        />
                      </Form.Item>
                      {task.status !== STATUSES.COMPLETED && !userHasOneOfRoles(hideRoles) && (
                        <img
                          data-field-name={key}
                          className={classes.editBtn}
                          onClick={makeEditable}
                          src={editIcon}
                          alt="action-icon"
                        />
                      )}
                    </div>
                  </div>
                );
              }
              if (key === "foundAssetId" || key === "foundDuplicateAssetId") {
                return (
                  <div className={classes.item} key={key}>
                    <div className={classes.title}>{title || getFormattedKeyName(key)}</div>
                    <div className={classes.value}>
                      <CustomLink className={classes.link} to={`/warehouse/assets/${data[key]}`} target={"_blank"}>
                        {data[key]}
                      </CustomLink>
                    </div>
                  </div>
                );
              }
              if (key === "duplicates") {
                return (
                  <div className={classes.item} key={key}>
                    <div className={classes.value}>
                      <div className={classes.link} onClick={openDuplicatesModal}>
                        View found duplicate/s
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className={classes.item} key={key}>
                  <div className={classes.title}>{title || getFormattedKeyName(key)}</div>
                  <div className={classnames(classes.value, key === "state" && classes[STATES_MAPPING[data[key]]])}>
                    {data[key]}
                  </div>
                </div>
              );
            })}
          </div>
        </Form>

        {!!approveFailedMsg && (
          <ConfirmationModal
            onCancel={closeApproveModal}
            onConfirm={closeApproveModal}
            title="Duplicate Found"
            description={approveFailedMsg}
            cancelBtnTitle="Cancel"
            confirmBtnTitle="Ok"
          />
        )}
        {viewDuplicates && <DuplicatesDetailsModel onClose={closeDuplicatesModal} list={data.duplicates} />}

        <Modal title="Photo preview" open={Boolean(preview)} onCancel={() => setPreview("")} footer={null}>
          <img className={classes.imgPreview} src={preview} alt="img preview" />
        </Modal>
      </div>
      <div className={classes.buttons}>
        {[STATUSES.PENDING, STATUSES.IN_PROGRESS].includes(task.status) && data.state !== ASSET_STATES.VALIDATED && (
          <CustomButton
            disabled={!data?.canMarkAsIdentifyAtDepot}
            roles={roles}
            onClick={openIdentifyConfirmationModal}
            color="outlined-white"
            size="small"
            text="Identify At Depot"
          />
        )}
        {task.status !== STATUSES.COMPLETED && (
          <>
            <CustomButton
              roles={roles}
              disabled={task.status === STATUSES.REJECTED}
              onClick={openRejectConfirmationModal}
              color="outlined-white"
              size="small"
              text="Reject"
            />
            <CustomButton
              roles={roles}
              disabled={disabled || task.status === STATUSES.REJECTED}
              onClick={openConfirmationModal}
              type="primary"
              size="small"
              text="Approve"
            />
          </>
        )}
      </div>
      {isConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeConfirmationModal}
          onConfirm={handleApprove}
          description="Are you sure you want to approve this request? Please note this is a permanent action and cannot be undone. Please double check the photo or ask TSR to remake it in case it is bad quality."
        />
      )}
      {isIdentifyConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeIdentifyConfirmationModal}
          onConfirm={handleIdentifyAtDepot}
          description="Are you sure you want to identify assets at the depot?"
        />
      )}
      {isRejectConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeRejectConfirmationModal}
          onConfirm={handleReject}
          description="Are you sure you want to reject the asset validation request?"
        />
      )}
    </div>
  );
};

export default GeneralValidationTab;
