import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Col, Container } from "reactstrap";
import { cylinderRepairListColumns } from "pages/warehouse/сylinderRepair/cylinderRepairListColumns";
import { retrieveAllAssets } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import CylinderRepairModal from "pages/warehouse/сylinderRepair/CylinderRepairModal";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { userHasOneOfRoles } from "utils/helpers/functions";

const CylinderRepairPage = () => {
  const dispatch = useDispatch();
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.CYLINDER_REPAIR);
  const [isLoading, setLoader] = useState(false);
  const [cylindersList, setCylindersList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [itemDetails, setItemDetails] = useState(null);

  const hideRoles = [USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.IT_OPERATIONS];

  const openModal = useCallback(item => {
    setItemDetails(item);
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    handleFilter(filters);
  }, []);

  const handleFilter = useCallback((options = {}) => {
    setFilters(options);
    setLoader(true);

    retrieveAllAssets({
      group: "Cylinder",
      condition: "Damaged",
      ...options,
    })
      .then(res => {
        setCylindersList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(() => {
    const col = cylinderRepairListColumns(openModal);
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [hideRoles, AUTH_USER]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Col>
            <NGITable
              filtersType={FILTER_PAGE.CYLINDER_REPAIR}
              isLoading={isLoading}
              columns={columns}
              data={cylindersList}
              updateList={handleFilter}
            />
          </Col>
          {showModal && (
            <CylinderRepairModal
              onClose={closeModal}
              isOpen={showModal}
              activeItem={itemDetails}
              onUpdate={handleFilter}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default CylinderRepairPage;
