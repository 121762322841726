import React, { useEffect, useMemo, useState } from "react";
import { Switch, useHistory } from "react-router";
import { userRoutes, authRoutes } from "routes/allRoutes";
import AuthMiddleWare from "./routes/middleware/AuthMiddleWare";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import "./assets/css/app.less";
import store from "./store";
import Sidebar from "components/VerticalLayout/Sidebar";
import useAuthorized from "customHooks/useAuthorized";
import packageJson from "../package.json";
import { injectStore } from "utils/api/config";

window.store = store;
injectStore(store);

const App = () => {
  const history = useHistory();
  const [isAuthorized] = useAuthorized();
  const [currentPage, setCurrentPage] = useState(history.location.pathname);

  const hasAccess = useMemo(() => !currentPage.includes("no-access"), [currentPage]);

  useEffect(
    () =>
      history.listen(location => {
        setCurrentPage(location.pathname);
      }),
    [history]
  );

  return (
    <div className="menu-content">
      {isAuthorized && hasAccess && <Sidebar currentPage={currentPage} />}
      <div className="content">
        <Switch>
          {authRoutes().map(route => (
            <AuthMiddleWare
              isAuthorized={isAuthorized}
              hasAccess={hasAccess}
              currentPage={currentPage}
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={route.path}
              isAuthProtected={false}
            />
          ))}

          {userRoutes.map(route => (
            <AuthMiddleWare
              isAuthorized={isAuthorized}
              hasAccess={hasAccess}
              currentPage={currentPage}
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={route.path}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </div>
      <div className="version-container">v.{packageJson.version}</div>
    </div>
  );
};

export default App;
