export const TABS = {
  GENERAL_INFORMATION: "GENERAL_INFORMATION",
};

export const TABS_BUTTONS = [
  {
    title: "General Information",
    key: TABS.GENERAL_INFORMATION,
  },
];

export const SORTED_GENERAL_KEYS = [
  "createdAt",
  "createdBy",
  "customerId",
  "inputData",
  "journey",
  "meterId",
  "tenant",
  "type",
];
