import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CardBody, Container } from "reactstrap";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import Card from "antd/es/card/Card";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router";
import { Collapse, Row, Select, Spin } from "antd";
import { getUsersList, retrieveAllTasks, setUserTeam } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import teamIcon from "assets/images/svg/team.svg";
import { textFormatter } from "components/Common/formatter";
import CustomButton from "components/Common/buttons/CustomButton";
import AddMemberModal from "pages/depot-management/depots/AddMemberModal";
import { USER_ROLES } from "utils/constants";
import { Option } from "antd/es/mentions";
import SwapEmployeesModal from "pages/depot-management/depots/SwapEmployeesModal";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  topContainer: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&> p": {
      whiteSpace: "nowrap",
      margin: 0,
      paddingRight: 5,
      fontSize: 14,
      fontWeight: "500",
    },
    "&> span": {
      whiteSpace: "nowrap",
      margin: 0,
      paddingRight: 5,
      fontSize: 14,
      fontWeight: "600",
    },
  },
  collapseList: {
    backgroundColor: "#FFF",
  },
  userCount: {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    borderRadius: 5,
    padding: 8,
    marginRight: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&> p": {
      fontWeight: "700",
      marginLeft: 5,
      marginBottom: 0,
    },
  },
  userItem: {
    overflow: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    borderRadius: 5,
    padding: 3,
    marginTop: 3,
    justifyContent: "space-between",
    "&> p": {
      fontWeight: "500",
      marginBottom: 0,
    },
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 10,
    color: "grey",
    "&> p": {
      marginLeft: 5,
      marginBottom: 0,
      fontSize: 10,
      color: "black",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  userName: {
    fontSize: 12,
    width: "25%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  manageMembers: {
    height: "30px!important",
    marginLeft: 10,
    padding: "5px 20px!important",
  },
  rightContainer: {
    alignItems: "center",
  },
  select: {
    "&.ant-select > .ant-select-selector": {
      background: "transparent",
      border: "none",
      fontSize: 12,
      "&> span": {
        fontWeight: "700",
      },
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginLeft: 15,
    },
  },
});

const { Panel } = Collapse;

const DepotTeams = () => {
  const searchParams = useLocation().search;
  const depot = new URLSearchParams(searchParams).get("n");
  const [isLoading, setLoader] = useState(false);
  const [addMemberVisible, setAddMemberVisible] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [swapData, setSwapData] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    list: { teamType },
  } = useSelector(DictionariesSelector);

  const teamConfig = useMemo(() => {
    const res = { Unassigned: { users: [], title: "Unassigned [No team assigned]", roles: [] } };
    teamType?.forEach(item => {
      res[item.teamType] = { roles: item.eligibleRoles, title: item.teamType, users: [] };
    });
    return res;
  }, [teamType]);

  const [teams, setTeams] = useState(teamConfig);

  const onListLoaded = useCallback(
    ({ data: { result } }) => {
      const res = { ...teamConfig };
      result?.items.forEach(user => {
        if (user.team) {
          if (!res[user.team]) {
            res[user.team] = { users: [] };
          }
          res[user.team] = { ...res[user.team], users: [...res[user.team].users, user] };
        } else {
          res.Unassigned = { ...res.Unassigned, users: [...res.Unassigned.users, user] };
        }
      });
      setAllUsers(result?.items);
      setTeams(res);
      setLoader(false);
    },
    [teamConfig]
  );

  const openAddMemberModal = useCallback((e, team) => {
    e.stopPropagation();
    setAddMemberVisible(team);
  }, []);

  const closeAddMemberModal = useCallback(() => setAddMemberVisible(false), []);

  const onCancelSwap = useCallback(() => setSwapData(undefined), []);

  const getTeams = useCallback(() => {
    setLoader(true);
    getUsersList({
      depot,
      pageSize: 500,
      role: [USER_ROLES.TSS, USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.LT, USER_ROLES.LOGISTICS].join(),
    })
      .then(res => {
        setTeams(teamConfig);
        onListLoaded(res);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [teamConfig, depot]);

  const handleTaskCheck = useCallback(
    async (team, email) => {
      setLoader(true);
      const user = allUsers.find(item => item.email === email);
      let res = undefined;

      if (user?.team) {
        res = await retrieveAllTasks({ pageSize: 500, assignedTo: email });
      }

      if (res?.data?.result?.items?.length) {
        setSwapData({ tasks: res?.data?.result?.items, firstEmployee: user, newTeam: team, depot });
        setLoader(false);
        return;
      }
      handleChangeTeam(email, team);
    },
    [allUsers, depot]
  );

  const handleChangeTeam = useCallback((email, team) => {
    setLoader(true);
    setUserTeam({ email, team })
      .then(() => {
        successMessage("Team was changed successfully");
        setTimeout(() => {
          getTeams();
          setLoader(false);
          closeAddMemberModal();
        }, 3000);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    getTeams();
    dispatch(getDictionary(DICTIONARY_TYPES.TEAM_TYPE));
  }, []);

  return (
    <div className="page-content">
      <Spin spinning={isLoading}>
        <Container fluid>
          <div className={classes.nameContainer}>
            <BackButtonLink to={"/depots/list"}>Back</BackButtonLink>
            <span className={classes.titleName}>{depot}</span>
          </div>
          <div className={classes.root}>
            <Card className={classes.topContainer}>
              <CardBody className={classes.cardBody}>
                <TitleWithDivider title="Teams" />
                <Collapse className={classes.collapseList} expandIconPosition={"end"}>
                  {Object.keys(teams).map(key => (
                    <Panel
                      header={
                        <div className={classes.header}>
                          <div className={classes.container}>
                            <p>{teams[key].title}</p>
                          </div>
                          <Row className={classes.rightContainer}>
                            <div className={classes.userCount}>
                              <img src={teamIcon} alt="teamIcon" />
                              <p>{teams[key]?.users?.length}</p>
                            </div>
                            {key !== "Unassigned" && (
                              <CustomButton
                                className={classes.manageMembers}
                                onClick={e => openAddMemberModal(e, teams[key].title)}
                                size="small"
                                type="primary"
                                color="outlined"
                                text="Add member"
                              />
                            )}
                          </Row>
                        </div>
                      }
                      key={key}
                      style={{ padding: 0, margin: 0 }}
                    >
                      {teams[key].users?.length ? (
                        teams[key].users.map(user => (
                          <Row key={user.email} className={classes.userItem}>
                            <p className={classes.userName}>{user.name ? user.name : textFormatter(user.name)}</p>
                            <div className={classes.section} style={{ width: "25%" }}>
                              EMAIL
                              <p>{user.email ? user.email : textFormatter(user.email)}</p>
                            </div>
                            <div className={classes.section} style={{ width: "10%" }}>
                              SECTOR
                              <p>{user.sector ? user.sector : textFormatter(user.sector)}</p>
                            </div>
                            <div className={classes.section} style={{ width: "15%" }}>
                              PHONE NUMBER
                              <p>{user.phone ? user.phone : textFormatter(user.phone)}</p>
                            </div>
                            <div className={classes.section} style={{ width: "16%" }}>
                              Team:
                              <Select
                                style={{ width: "75%" }}
                                className={classes.select}
                                value={user.team}
                                tokenSeparators={[","]}
                                placeholder={"Select value"}
                                onChange={value => handleTaskCheck(value, user.email)}
                              >
                                {Object.keys(teamConfig).map(
                                  item =>
                                    item !== "Unassigned" &&
                                    user.roles?.some(role => teamConfig[item].roles?.some(r => r === role)) && (
                                      <Option key={item} value={item}>
                                        {item}
                                      </Option>
                                    )
                                )}
                              </Select>
                            </div>
                          </Row>
                        ))
                      ) : (
                        <Row justify={"center"}>
                          <h6>No data is available</h6>
                        </Row>
                      )}
                    </Panel>
                  ))}
                </Collapse>
              </CardBody>
            </Card>
          </div>
        </Container>
      </Spin>
      {addMemberVisible && (
        <AddMemberModal
          userList={teams.Unassigned.users}
          handleCancel={closeAddMemberModal}
          team={addMemberVisible}
          onChangeTeam={handleTaskCheck}
          isLoading={isLoading}
          teamConfig={teamConfig}
        />
      )}
      {swapData && (
        <SwapEmployeesModal
          swapData={swapData}
          isLoading={isLoading}
          setLoader={setLoader}
          handleCancel={onCancelSwap}
          teams={teams}
          teamConfig={teamConfig}
          onChangeTeam={handleChangeTeam}
        />
      )}
    </div>
  );
};

export default DepotTeams;
