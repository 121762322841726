import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { Spin } from "antd";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import DataTabs from "pages/settings/campaign-management/campaignDetails/DataTabs";
import Header from "pages/settings/campaign-management/campaignDetails/Header";
import { getCampaignDetails } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";

const CampaignDetails = () => {
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== "new") {
      setIsLoading(true);
      getCampaignDetails(id)
        .then(res => {
          setCampaign(res.data.result);
          setIsLoading(false);
        })
        .catch(e => {
          defaultNgiErrorMethod(e);
          setIsLoading(false);
        });
    }

    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.CAMPAIGN_TYPE));
    dispatch(getDictionary(DICTIONARY_TYPES.PAYMENT_SETTINGS));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <Header campaign={campaign} />
            <DataTabs campaign={campaign} setIsLoading={setIsLoading} />
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default CampaignDetails;
