import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Header from "./Header";
import { getFormattedDataByName, getFormattedKeyName } from "utils/helpers/functions";
import { makeStyles } from "@material-ui/core/styles";
import { DATA_TO_DISPLAY } from "pages/meter-management/maintenanceReports/variables";
import { getMaintenanceReportsAPI } from "utils/api";
import { useParams } from "react-router";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { Spin } from "antd";
import NotFoundContainer from "components/Common/NotFoundContainer";

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "white",
    padding: 20,
    marginTop: 20,
    borderRadius: 20,
  },
  informationContainer: {
    display: "flex",
    width: "50%",
  },
  mapContainer: {
    width: "50%",
  },
  column: {
    width: "50%",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
  },
});

const ReportDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [maintenanceReport, setMaintenanceReport] = useState(null);
  const [isLoading, setLoader] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    setLoader(true);
    getMaintenanceReportsAPI({
      reportId: id,
    })
      .then(res => {
        setLoader(false);
        const {
          result: { items },
        } = res?.data;
        if (items.length === 0) {
          warnMessage("Report not found");
          setIsNotFound(true);
        }
        setMaintenanceReport(items[0] || null);
      })
      .catch(err => {
        setLoader(false);
        setMaintenanceReport(null);
        defaultNgiErrorMethod(err);
      });
  }, [id]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              <>
                <Header data={maintenanceReport} />
                <div className={classes.root}>
                  <div className={classes.informationContainer}>
                    <div className={classes.column}>
                      {DATA_TO_DISPLAY.map(key => (
                        <div key={key} className={classes.title}>
                          {getFormattedKeyName(key)} :
                        </div>
                      ))}
                    </div>
                    {maintenanceReport && (
                      <div className={classes.column}>
                        {DATA_TO_DISPLAY.map(key => (
                          <div key={`${key}-item`} className={classes.data}>
                            {getFormattedDataByName(key, maintenanceReport[key] || "-")}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={classes.mapContainer}></div>
                </div>
              </>
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default ReportDetails;
