import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";
import NGITooltip from "components/Common/utils/NGITooltip";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const hideRoles = [USER_ROLES.IT_OPERATIONS];

export const surveyListColumns = () => [
  {
    title: "Name",
    dataIndex: "name",
    render: (cellContent, row) =>
      userHasOneOfRoles(hideRoles) ? (
        textFormatter(cellContent)
      ) : (
        <NGITooltip title="Survey details">
          <CustomLink to={`/settings/survey/${row.id}`}>{cellContent}</CustomLink>
        </NGITooltip>
      ),
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "Questions Qty",
    render: row => textFormatter(row?.items?.length),
  },
  {
    title: "Updated At",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
];
