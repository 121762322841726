import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import { assignDepotToCustomer } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import NGISelect from "components/Common/inputs/NGISelect";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { ENGINE_VERSION, USER_ROLES } from "utils/constants";
import TSRSelect from "components/Common/components/TSRSelect";

const DepotModal = ({ onClose, isOpen, customer, onUpdate, TSRList, depotsList }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDepot, setSelectedDepot] = useState(null);
  const [selectedTsr, setSelectedTsr] = useState(null);

  const handleCancel = useCallback(() => onClose(), [onClose]);

  const handleSave = useCallback(
    values => {
      const { depot } = values;
      setIsLoading(true);
      const dataToUpdate =
        selectedDepot !== customer?.depot && !values?.sector
          ? {
              technician: selectedTsr.email,
              technicianName: selectedTsr.name,
              depot,
            }
          : {
              depot,
              ...(values?.sector && { sector: values?.sector }),
            };
      assignDepotToCustomer({ id: customer.id, ...dataToUpdate })
        .then(() => {
          onUpdate({ ...customer, ...dataToUpdate });
          onClose();
          warnMessage("Customer depot is successfully changed.");
          setIsLoading(false);
        })
        .catch(e => {
          defaultNgiErrorMethod(e);
          setIsLoading(false);
        });
    },
    [onUpdate, customer, selectedTsr, selectedDepot]
  );

  const handleChange = useCallback(
    depot => {
      setSelectedDepot(depot);
      form.setFieldsValue({
        tsr: null,
        sector: null,
      });
    },
    [form]
  );
  const filteredTSRs = useMemo(() => TSRList.filter(item => item.depot === selectedDepot), [selectedDepot]);
  const selectedDepotData = useMemo(() => depotsList.find(item => item.name === selectedDepot), [selectedDepot]);

  useEffect(() => {
    setSelectedDepot(customer?.depot);
    form.setFieldsValue({
      depot: customer?.depot,
      ...(customer?.sector && { sector: customer?.sector }),
    });
  }, [customer]);

  return (
    <Modal open={isOpen} title="Assign Customer to Depot" closable={false} onCancel={handleCancel} footer={[]}>
      <>
        <Spin spinning={isLoading}>
          <NGIForm name="assignCustomerToDepot" form={form} onFinish={handleSave}>
            <Col span={24}>
              <Title level={5}>Depot</Title>
              <NGIFormItem name="depot" rules={[{ required: true, message: "Please select depot" }]}>
                <NGISelect showSearch placeholder={"Please select depot"} onChange={handleChange} value={selectedDepot}>
                  {depotsList?.sort().map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
            {selectedDepot !== customer?.depot && selectedDepotData?.taskEngine !== ENGINE_VERSION.V3 && (
              <Col span={24}>
                <Title level={5}>TSR</Title>
                <NGIFormItem name="tsr" rules={[{ required: true, message: "Please select TSR" }]}>
                  <TSRSelect
                    showSearch
                    initialList={filteredTSRs}
                    handleChange={setSelectedTsr}
                    roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                    depot={selectedDepot}
                  />
                </NGIFormItem>
              </Col>
            )}
            {selectedDepotData?.taskEngine === ENGINE_VERSION.V3 && (
              <Col span={24}>
                <Title level={5}>Sector</Title>
                <NGIFormItem name="sector" rules={[{ required: true, message: "Please select Sector" }]}>
                  <NGISelect showSearch placeholder={"Please select Sector"}>
                    {selectedDepotData?.sectors?.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            )}

            <Space>
              <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
              <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
            </Space>
          </NGIForm>
        </Spin>
      </>
    </Modal>
  );
};

export default DepotModal;
