import React from "react";
import { customDateFormatWithTime, textFormatter, uppercaseTextFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import infoIcon from "assets/images/svg/Info.svg";
import { USER_ROLES } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";

export const taskRequestsListColumns = handleDetails => [
  {
    title: "Task Type",
    dataIndex: "category",
    render: uppercaseTextFormatter,
  },
  {
    title: "Customer ID",
    dataIndex: "customerId",
    render: id => (
      <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
        {id}
      </CustomLink>
    ),
  },
  {
    title: "Assigned To",
    dataIndex: "assignedToName",
    render: (cellContent, task) => (
      <CustomLink underlined target="_blank" to={`/user/${task.assignedTo}`}>
        {textFormatter(task.assignedTo)}
      </CustomLink>
    ),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row =>
      row.status === "Pending" && (
        <ButtonGroup
          hideRoles={[USER_ROLES.CUSTOMER_CARE]}
          tooltipTitle="Approve/Decline"
          icon={infoIcon}
          handleClick={() => {
            handleDetails(row);
          }}
        />
      ),
  },
];
