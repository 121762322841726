import React from "react";
import { Alert, Card, CardBody, Col, Container, Media, Row } from "reactstrap";
import { useSelector } from "react-redux";
import Breadcrumb from "components/Common/Breadcrumb";
import userImage from "../../assets/images/user.png";
import MetaData from "components/VerticalLayout/MetaData";
import { formatPhoneNumber, formatString } from "utils/helpers/functions";
import { LoginSelector } from "store/auth/login/selectors";
import { AUTH_USER } from "utils/constants";

const UserProfile = () => {
  const { error } = useSelector(LoginSelector);
  //added to avoid eslint check
  //need to remove component if it will not be used
  const name = "";
  return (
    <>
      <div className="page-content">
        <MetaData pageTitle={"Profile"} />
        <Container fluid>
          <Breadcrumb title="User" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      <img src={userImage} alt={AUTH_USER.id} className="avatar-md rounded-circle img-thumbnail" />
                    </div>
                    <Media body className="align-self-center" style={{ marginLeft: 16 }}>
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">
                          {formatString(`${AUTH_USER.firstName.toLowerCase()} ${AUTH_USER.lastName.toLowerCase()}`)}
                        </p>
                        <p className="mb-1">
                          <a href={`mailto:${AUTH_USER.email}`}>{AUTH_USER.email}</a>
                        </p>
                        <p className="mb-0">
                          Phone Number :
                          <a href={`tel:${formatPhoneNumber(AUTH_USER.phone)}`}>{formatPhoneNumber(AUTH_USER.phone)}</a>
                        </p>
                        <p className="mb-0">Depot : {AUTH_USER?.depotName}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserProfile;
