import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveReadyForRepairMeters } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import ReadyForRepairSearch from "pages/meter-management/ready-for-repair/ReadyForRepairSearch";
import NGITable from "components/Common/NGITable";
import { readyForRepairListColumns } from "pages/meter-management/ready-for-repair/readyForRepairListColumns";
import ReadyForRepairFilter from "pages/meter-management/ready-for-repair/ReadyForRepairFilter";
import RepairMeterModal from "pages/meter-management/ready-for-repair/RepairMeter/RepairMeterModal";
import { FILTER_PAGE } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";

const ReadyForRepair = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const { setFilters } = useStoreFilters(FILTER_PAGE.READY_FOR_REPAIR);
  const [list, setList] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const openModal = useCallback(item => {
    setActiveItem(item);
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => setIsModalVisible(false), []);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_FAULTY_REASONS_V2));
    handleFilter();
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items.length === 0) {
      warnMessage(`Meters were not found`);
      setLoader(false);
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleFilter = (options = {}) => {
    setLoader(true);
    setFilters(options);
    retrieveReadyForRepairMeters(options)
      .then(async res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => readyForRepairListColumns(openModal), [openModal]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <ReadyForRepairFilter handleFilter={handleFilter} />
            <ReadyForRepairSearch handleSearch={handleFilter} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.READY_FOR_REPAIR}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleFilter}
            />
          </Col>
        </Container>
      </div>
      {isModalVisible && (
        <RepairMeterModal
          isOpen={isModalVisible}
          onClose={closeModal}
          activeItem={activeItem}
          onUpdate={handleFilter}
        />
      )}
    </>
  );
};

export default ReadyForRepair;
