import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";

export const customerUpdateListColumns = () => [
  {
    title: "Customer ID",
    dataIndex: "",
    render: row => {
      if (
        !userHasOneOfRoles([
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.A_SM,
        ]) ||
        row.status !== "Pending"
      )
        return textFormatter(row.customerId);

      return (
        <NGITooltip title="Approve/Decline Customer Details Update request">
          <div>
            <CustomLink to={`/customers/update-request/${row.id}`}>{row.customerId}</CustomLink>
          </div>
        </NGITooltip>
      );
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Created by",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Created by role",
    dataIndex: "createdByRole",
    render: textFormatter,
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
];
