import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import moment from "moment/moment";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { dateFormat, searchOptions } from "pages/customer-management/customers/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_TENANT_NAME } from "utils/constants";
import { isEmpty, uniq } from "lodash";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import NGISelect from "components/Common/inputs/NGISelect";
import { TSRSelector } from "store/TSR/selectors";

const { RangePicker } = DatePicker;
const { Option } = Select;

const useStyles = makeStyles({
  root: { width: "250px!important" },
});

const CustomerSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.CUSTOMERS);
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);
  const classes = useStyles();
  const { TSRList } = useSelector(TSRSelector);

  useEffect(() => {
    const options = searchOptions.map(item => item.value);
    if (!isEmpty(filters)) {
      const filtersArray = [];
      Object.keys(filters).forEach(item => {
        if (options.includes(item)) {
          if (item === "installDateFrom") {
            form.setFieldsValue({
              type: "date",
              date: [moment(filters?.installDateFrom), moment(filters?.installDateTo)],
              value: null,
            });
            filtersArray.push("date");
          } else {
            form.setFieldsValue({
              [item]: filters[item],
            });
            filtersArray.push(item);
          }
        }
      });
      setSelectedFilter(filtersArray.filter(item => item !== "installDateTo"));
    } else {
      form.setFieldsValue({
        type: "date",
        date: [moment(getStartOfTheDay(moment())), moment(getEndOfTheDay(moment()))],
        value: null,
      });
      setSelectedFilter(["date"]);
    }
  }, [form]);

  const onChangeFilter = useCallback(values => {
    if (values.includes("id") && values[0] !== "id") {
      setSelectedFilter(["id"]);
    } else {
      setSelectedFilter(values.length > 1 ? values.filter(item => item !== "id") : values);
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    const preparedFilters = Object.keys(values).reduce((a, item) => {
      if (item === "date") {
        return {
          ...a,
          installDateFrom: getStartOfTheDay(moment(values?.date[0])),
          installDateTo: getEndOfTheDay(moment(values?.date[1])),
        };
      }
      return { ...a, [item]: values[item]?.trim() };
    }, {});
    handleSearch({ ...preparedFilters, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Select
          onChange={onChangeFilter}
          style={{ width: "100%" }}
          tokenSeparators={[","]}
          mode="multiple"
          value={selectedFilter}
          placeholder="Select type"
          allowClear
        >
          {searchOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.title}
            </Option>
          ))}
        </Select>
        <>
          {!!selectedFilter.length && selectedFilter.includes("date") && (
            <>
              <div className="filter-divider" />
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Please select date range" }]}
                className={classes.root}
              >
                <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
              </Form.Item>
            </>
          )}
          {!!selectedFilter.length && selectedFilter.includes("id") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="id">
                <Input placeholder={"Enter id"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
              </Form.Item>
            </>
          )}
          {!!selectedFilter.length && selectedFilter.includes("firstName") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="firstName">
                <Input
                  placeholder={"Enter first name"}
                  allowClear
                  pattern={inputTextPattern}
                  title={inputPatternTitle}
                />
              </Form.Item>
            </>
          )}
          {!!selectedFilter.length && selectedFilter.includes("lastName") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="lastName">
                <Input
                  placeholder={"Enter last name"}
                  allowClear
                  pattern={inputTextPattern}
                  title={inputPatternTitle}
                />
              </Form.Item>
            </>
          )}
          {!!selectedFilter.length && selectedFilter.includes("technicianName") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="technician">
                <NGISelect
                  showSearch
                  loading={!TSRList?.length}
                  placeholder="Please select TSR name"
                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                >
                  {TSRList.map(item => (
                    <Option key={item.id} value={item.email}>
                      {item.email}
                    </Option>
                  ))}
                </NGISelect>
              </Form.Item>
            </>
          )}

          {!!selectedFilter.length && selectedFilter.includes("depot") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="depot">
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select depot"}>
                  {depot[USER_TENANT_NAME] &&
                    uniq(depot[USER_TENANT_NAME])
                      ?.sort()
                      ?.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default CustomerSearch;
