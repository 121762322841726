import React, { useCallback, useEffect, useState } from "react";
import {
  allowedStates,
  allowedStatus,
  LOST_MODAL_DESCRIPTION,
  routineAssetsColumns,
  SUCCESS_LOST_MESSAGE,
} from "pages/operations/routineDetails/constants";
import NGITable from "components/Common/NGITable";
import CustomButton from "components/Common/buttons/CustomButton";
import NGITooltip from "components/Common/utils/NGITooltip";
import { Spin } from "antd";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { setLostAssets } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const AssetsTab = ({ routine: { assets = [], status }, updateDetails, isLoading: defaultLoading }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowList, setRowList] = useState([]);
  const [isLoading, setLoader] = useState(false);

  const hideRoles = [
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.A_DSM,
    USER_ROLES.D_SM,
    USER_ROLES.A_SM,
    USER_ROLES.OPERATION_MANAGERS,
    USER_ROLES.SALES_SUPPORT,
    USER_ROLES.GENERAL,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.FINANCE,
  ];

  useEffect(() => {
    setLoader(defaultLoading);
  }, [defaultLoading]);

  const rowSelection = {
    selectedRowKeys,
    onChange: rows => {
      setSelectedRowKeys(rows);
    },
    renderCell: (checked, record, index, node) => {
      if (!allowedStates.includes(record.state)) {
        return <NGITooltip title="Incorrect status for marking as Lost">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      if (!allowedStates.includes(record.state)) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };

  const resetSelectedRows = useCallback(() => setSelectedRowKeys([]), []);

  const openModal = useCallback(() => {
    setRowList(selectedRowKeys);
    setShowModal(true);
  }, [selectedRowKeys]);

  const closeModal = useCallback(() => {
    setRowList([]);
    setShowModal(false);
  }, [selectedRowKeys]);

  const handleSubmit = useCallback(async () => {
    setLoader(true);
    await setLostAssets({ assets: rowList })
      .then(() => {
        setTimeout(() => {
          updateDetails();
          setLoader(false);
          successMessage(SUCCESS_LOST_MESSAGE);
        }, 3000);
        resetSelectedRows();
        closeModal();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [rowList]);

  return (
    <>
      <div className="filters-wrapper with-buttons">
        {status === allowedStatus && (
          <CustomButton
            hideRoles={hideRoles}
            type="primary"
            size="small"
            text="Mark as Lost"
            onClick={openModal}
            disabled={!selectedRowKeys.length}
          />
        )}
      </div>
      <Spin spinning={isLoading}>
        <NGITable
          columns={routineAssetsColumns()}
          data={{
            result: { items: assets },
          }}
          rowSelection={userHasOneOfRoles(hideRoles) || status !== allowedStatus ? null : rowSelection}
          rowKey={record => record.id}
        />
      </Spin>
      {showModal && (
        <ConfirmationModal
          loading={isLoading}
          onCancel={closeModal}
          onConfirm={handleSubmit}
          description={LOST_MODAL_DESCRIPTION}
        />
      )}
    </>
  );
};

export default AssetsTab;
