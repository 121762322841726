import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Col, Container } from "reactstrap";
import AccessoryFilters from "components/Common/filters/meters-management/accessoryFilter";
import { errorMessage, NgiErrorMethod, successMessage } from "components/Common/responses/message";
import { useSelector, useDispatch } from "react-redux";
import { deleteAccessory, readAccessory } from "store/actions";
import { AccessoriesSelector } from "store/meter-management/configuration/accessories/selectors";
import AccessoryModal from "./AccessoryModal";
import CustomButton from "components/Common/buttons/CustomButton";
import NGITable from "components/Common/NGITable";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import editIcon from "assets/images/svg/Edit.svg";
import deleteIcon from "assets/images/svg/Delete.svg";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";
import CustomLink from "components/Common/buttons/CustomLink";

const hideRoles = [
  USER_ROLES.INTERNAL_AUDITOR,
  USER_ROLES.PRODUCT_TECHNICIANS,
  USER_ROLES.GENERAL,
  USER_ROLES.IT_OPERATIONS,
  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
  USER_ROLES.FINANCE,
];

const RetrieveAccesories = () => {
  const { loading, accessories, accessorySuccessPost, accessorySuccessUpdate, accessorySuccessDelete, accessoryError } =
    useSelector(AccessoriesSelector);

  const columns = useMemo(() => {
    const col = [
      {
        dataIndex: "type",
        title: "Type",
        width: "20%",
        render: (cellContent, routine) => textFormatter(routine.type),
        sorter: (a, b) => a.type.length - b.type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        dataIndex: "description",
        title: "Description",
        render: (cellContent, routine) => textFormatter(routine.description),
      },
      {
        title: "Accessory Type",
        dataIndex: "accessoryType",
        width: "20%",
        render: (cellContent, routine) => textFormatter(routine.accessoryType),
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        render: (cellContent, routine) => (
          <CustomLink underlined target="_blank" to={`/user/${routine.createdBy}`}>
            {textFormatter(routine.createdBy)}
          </CustomLink>
        ),
        sorter: (a, b) => a.createdBy.length - b.createdBy.length,
        sortDirections: ["descend", "ascend"],
      },

      {
        dataIndex: "updatedAt",
        title: "Date",
        width: "30%",
        render: (cellContent, routine) => textFormatter(customDateFormatWithTime(routine.updatedAt)),
      },
      {
        title: "",
        key: "action",
        render: (text, record) => (
          <ButtonGroupContainer>
            <ButtonGroup
              hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
              tooltipTitle="Edit"
              icon={editIcon}
              handleClick={() => onUpdate(record)}
            />
            <ButtonGroup
              hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
              tooltipTitle="Delete"
              icon={deleteIcon}
              handleClick={() => onDelete(record)}
            />
          </ButtonGroupContainer>
        ),
      },
    ];
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [hideRoles]);

  const dispatch = useDispatch();

  const dataSource = useMemo(
    () =>
      accessories
        .map((item, index) => ({ ...item, id: index.toString(), key: index.toString() }))
        .sort((a, b) => b.updatedAt - a.updatedAt),
    [accessories]
  );

  const [currentFilterOptions, setCurrentFilterOptions] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);
  const accessoryList = useRef(["Sensor", "Microcontroller"]);

  useEffect(() => {
    !accessoryError && accessorySuccessPost && successMessage("Accessory Created Successfully");
  }, [accessoryError, accessorySuccessPost]);

  useEffect(() => {
    !accessoryError && accessorySuccessUpdate && successMessage("Accessory Updated Successfully");
  }, [accessoryError, accessorySuccessUpdate]);

  useEffect(() => {
    !accessoryError && accessorySuccessDelete && successMessage("Accessory Deleted Successfully");
  }, [accessoryError, accessorySuccessDelete]);

  useEffect(() => {
    accessoryError && errorMessage(NgiErrorMethod(accessoryError));
  }, [accessoryError]);

  useEffect(() => {
    handleOnFilter({ accessoryType: "Sensor" });
  }, []);

  const handleOnFilter = async filterOptions => {
    filterOptions.accessoryType = !filterOptions ? currentFilterOptions : filterOptions.accessoryType;
    setCurrentFilterOptions(filterOptions);
    dispatch(readAccessory(filterOptions.accessoryType));
  };

  const onCreate = useCallback(() => {
    setSelectedItem(null);
    openModal();
  }, []);
  const onUpdate = useCallback(item => {
    setSelectedItem({ ...item });
    openModal();
  }, []);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [accessoryToDelete, setAccessoryToDelete] = useState(null);

  const closeDeleteModal = () => setIsDeleteModalVisible(false);
  const openDeleteModal = () => setIsDeleteModalVisible(true);

  const onDelete = useCallback(version => {
    setAccessoryToDelete(version);
    openDeleteModal();
  }, []);

  const onConfirmDelete = useCallback(() => {
    closeDeleteModal();
    dispatch(deleteAccessory(accessoryToDelete));
  }, [accessoryToDelete]);

  const data = useMemo(() => ({ result: { items: dataSource } }), [dataSource]);

  return (
    <>
      <div className="page-content" data-testid="retrieveAccessoryComp">
        <Container fluid>
          <div className="filters-wrapper">
            <CustomButton
              hideRoles={hideRoles}
              type="primary"
              onClick={onCreate}
              text="NEW ACCESSORY"
              icon={<i className="bx bx-plus-circle" />}
            />
            <AccessoryFilters onFilter={handleOnFilter} loading={loading} accessoryList={accessoryList.current} />
          </div>

          <Col lg={12}>
            <NGITable isLoading={loading} columns={columns} data={data} />
          </Col>
        </Container>
      </div>
      {isModalVisible && (
        <AccessoryModal
          onClose={closeModal}
          isVisible={isModalVisible}
          initialValues={selectedItem}
          accessoryTypes={accessoryList.current}
        />
      )}
      {isDeleteModalVisible && (
        <ConfirmationModal
          onCancel={closeDeleteModal}
          onConfirm={onConfirmDelete}
          description={`Are you sure you want to delete Accessory Type ${accessoryToDelete.accessoryType}: ${accessoryToDelete.type}?`}
        />
      )}
    </>
  );
};

export default RetrieveAccesories;
