import React from "react";
import { Col, Form, Modal, Row, Space } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { VALIDATION_RULES } from "pages/SMS-management/SMS-bulk/NewBulkSMS/constants";
import { USER_TENANT_NAME } from "utils/constants";
import { uniq } from "lodash";
import { Option } from "antd/es/mentions";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NGISelect from "components/Common/inputs/NGISelect";

const useStyles = makeStyles({
  root: {
    width: "600px!important",

    "& .ant-modal-header": {
      padding: "24px 24px 0 !important",
    },

    "& .ant-modal-body": {
      padding: "10px 24px !important",
    },
  },
  description: {
    whiteSpace: "pre-wrap",
    padding: "0 10px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  formItem: {
    marginBottom: 15,
  },
});

const SpotTripModal = ({ onConfirm, hasAssets, onCancel }) => {
  const classes = useStyles();
  const [form] = Form.useForm();

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  return (
    <Modal className={classes.root} open={true} closable={false} title={"Confirmation"} onCancel={onCancel} footer={[]}>
      <>
        <p className={classes.description}>
          If this trip contains unserved STO, they will be deattached from it and revert to Pending or Particially
          Planned.
        </p>
        <Form form={form} layout="inline" onFinish={onConfirm} className={classes.formContainer}>
          <Row>
            {hasAssets && (
              <Col span={24} className={classes.formItem}>
                <Form.Item name="depot" rules={[{ required: true, message: "Please select value" }]}>
                  <NGISelect showSearch placeholder={"Select value"}>
                    {depot[USER_TENANT_NAME] &&
                      uniq(depot[USER_TENANT_NAME])
                        ?.sort()
                        ?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                  </NGISelect>
                </Form.Item>
              </Col>
            )}

            <Col span={24} className={classes.formItem}>
              <Form.Item name="reason" rules={VALIDATION_RULES}>
                <NGITextArea rows={5} placeholder="Please add your reason" />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <CustomButton onClick={onCancel} size="small" type="primary" color="outlined" text={"Cancel"} />
            <CustomButton size="small" type="primary" text={"Stop Trip"} />
          </Space>
        </Form>
      </>
    </Modal>
  );
};

export default SpotTripModal;
