import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;
const CookingSessionFilters = props => {
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.COOKING_SESSION);
  const [form] = Form.useForm();
  const { onFilter } = props;

  useEffect(() => {
    filters?.meterBarcode
      ? form.setFieldsValue({
          value: filters.meterBarcode,
        })
      : form.setFieldsValue({
          value: null,
        });
    filters?.bad
      ? form.setFieldsValue({
          isBad: filters.bad,
        })
      : form.setFieldsValue({
          isBad: false,
        });
  }, [filters?.meterBarcode, filters?.bad]);

  const onFinish = values => {
    const { value, isBad } = values;
    const { pageSize } = getFilters();
    const filter = isBad
      ? Object.assign({}, { meterBarcode: value?.trim(), bad: isBad })
      : Object.assign({}, { meterBarcode: value?.trim() });
    onFilter({ ...filter, pageSize });
  };

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
          <Input placeholder="Enter meter barcode " allowClear pattern={inputTextPattern} title={inputPatternTitle} />
        </Form.Item>
        <div className="filter-divider" />
        <Form.Item name="isBad" rules={[{ required: false, message: "Please enter value" }]}>
          <Select showSearch tokenSeparators={[","]} placeholder={"Select cooking session type"} allowClear>
            <Option key={"1"} value={true}>
              Bad
            </Option>
            <Option key={"2"} value={false}>
              Good
            </Option>
          </Select>
        </Form.Item>
        <CustomButton type="primary" htmlType="submit" text="Search" size="small" />
      </Form>
    </div>
  );
};

CookingSessionFilters.propTypes = {
  typesList: PropTypes.array,
  onFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CookingSessionFilters;
