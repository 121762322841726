export const filterOptions = [
  {
    value: "status",
    title: "Status",
  },
  {
    value: "customerId",
    title: "Customer ID",
  },
  {
    value: "createdBy",
    title: "TSR",
  },
];

export const STATUS = { DECLINED: "Declined", PENDING: "Pending", COMPLETED: "Completed" };
export const STATUSES = [STATUS.COMPLETED, STATUS.DECLINED, STATUS.PENDING];
