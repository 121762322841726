import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    color: "#000000",

    "&:hover": {
      cursor: "pointer",
      color: "#0085FF",
    },
  },
  underlined: {
    textDecoration: "underline!important",
  },
});

const CustomLink = ({
  to,
  color = "#000",
  target = "_self",
  underlined = false,
  children,
  roles,
  onClick,
  className = "",
  ...props
}) => {
  const classes = useStyles();

  if (!userHasAdminRole()) {
    if (roles && !userHasOneOfRoles(roles)) {
      return null;
    }
  }

  return to ? (
    <Link
      {...props}
      style={{ color }}
      className={classnames(classes.root, className, underlined && classes.underlined)}
      target={target}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <span
      {...props}
      style={{ color }}
      className={classnames(classes.root, className, underlined && classes.underlined)}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default CustomLink;
