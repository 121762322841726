import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { searchOptions, statusList } from "pages/settings/campaign-management/campaignList/constants";
import NGIDateRangePicker from "components/Common/inputs/NGIDateRangePicker";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import TSRSelect from "components/Common/components/TSRSelect";

const useStyles = makeStyles({
  value: {
    width: "auto !important",
  },
  date: { backgroundColor: "white !important" },
});

const { Option } = Select;
const CampaignSearch = ({ handleSearch, TSRList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.CAMPAIGN);

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: null,
        value: null,
      });
    }
  }, []);

  const onSearch = useCallback(
    values => {
      const { pageSize } = getFilters();
      if (selectedFilter === "startDateFrom") {
        handleSearch({
          from: getStartOfTheDay(moment(values.value[0])),
          to: getEndOfTheDay(moment(values.value[1])),
          pageSize,
        });
      } else if (!(values.value && !values.type)) {
        handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
      }
    },
    [selectedFilter]
  );

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            showSearch
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedFilter === "status" && (
          <Form.Item name="value">
            <Select showSearch tokenSeparators={[","]} size={"default"} placeholder={"Select status"} allowClear>
              {statusList.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {selectedFilter === "startDateFrom" && (
          <Form.Item name="value" className={classes.value}>
            <NGIDateRangePicker className={classes.date} />
          </Form.Item>
        )}

        {selectedFilter === "createdBy" && (
          <Form.Item name="value" className={classes.value}>
            <TSRSelect
              showSearch
              initialList={TSRList}
              roles={[USER_ROLES.SYSTEM_FULL_ADMINS]}
              depot={AUTH_USER?.depotName}
              placeholder="Please select user"
            />
          </Form.Item>
        )}

        {(selectedFilter === "name" || selectedFilter === "campaignId") && (
          <Form.Item name="value">
            <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
          </Form.Item>
        )}

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default CampaignSearch;
