import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { depotHistoryColumns, TABS, TABS_BUTTONS } from "pages/depot-management/depotDetails/constants";
import DepotInformation from "pages/depot-management/depotModal/Tabs/DepotInformation";
import GeoDetails from "pages/depot-management/depotModal/Tabs/GeoDetails";
import CustomButton from "components/Common/buttons/CustomButton";
import NGITable from "components/Common/NGITable";
import NGIPrompt from "components/Common/NGIPrompt";
import Sectors from "pages/depot-management/depotDetails/Tabs/Sectors";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  hide: {
    display: "none",
  },
  show: {
    display: "block",
  },
  actionContainer: {
    marginTop: 16,
  },
  editContainer: {
    display: "flex",
    "&> div": {
      width: "50%",
    },
  },
});

const DataTabs = ({
  depot,
  depotsHistory,
  depotForm,
  isLoaded,
  onClearMap,
  onPolygonComplete,
  onMarkerComplete,
  isTouched,
  handleSave,
  isHistoryLoading,
  polygonList,
  TSRList,
  updateData,
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TABS.EDIT_GEOFENCE);
  const [isDirty, setIsDirty] = useState(false);

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  const { marker, polygon } = useMemo(
    () => ({
      marker: depot ? { lat: +depot?.location?.lat, lng: +depot?.location?.lon } : {},
      polygon: depot ? depot?.boundaries?.coordinates[0] : [],
    }),
    [depot]
  );
  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  const onPolygonCompleteWrapper = useCallback(
    (e, f) => {
      onPolygonComplete(e, f);
      setIsDirty(true);
    },
    [onPolygonComplete]
  );

  const onMarkerCompleteWrapper = useCallback(
    e => {
      onMarkerComplete(e);
      setIsDirty(true);
    },
    [onMarkerComplete]
  );

  const onClearMapWrapper = useCallback(
    e => {
      onClearMap(e);
      setIsDirty(true);
    },
    [onClearMap]
  );

  const onSaveWrapper = useCallback(
    e => {
      setIsDirty(false);
      handleSave(e);
    },
    [handleSave]
  );

  const center = useMemo(() => ({ ...(polygon && { lat: polygon[0][0], lng: polygon[0][1] }) }), []);

  return (
    <div className={classes.root}>
      <NGIPrompt show={isDirty} />
      <div className={classes.tabs}>
        {TABS_BUTTONS.map(item => (
          <div
            key={item.key}
            className={classnames(classes.tab, activeTab === item.key && classes.active)}
            data-tab={item.key}
            onClick={onTabClick}
          >
            {item.title}
          </div>
        ))}
      </div>
      <Card className={classes.container}>
        <CardBody>
          {activeTab === TABS.EDIT_GEOFENCE && (
            <>
              <div className={classes.editContainer}>
                <div>
                  <DepotInformation isNew={false} form={depotForm} onChange={onFieldsChanged} />
                </div>
                <div>
                  <GeoDetails
                    polygonList={polygonList}
                    center={marker.lat ? marker : center}
                    initialData={{ marker, polygon }}
                    isLoaded={isLoaded}
                    onClearMap={onClearMapWrapper}
                    onPolygonComplete={onPolygonCompleteWrapper}
                    onMarkerComplete={onMarkerCompleteWrapper}
                    isTouched={isTouched}
                    allowSearch
                  />
                </div>
              </div>
              <div className={classes.actionContainer}>
                <CustomButton type="primary" size="small" text="Save" onClick={onSaveWrapper} />
              </div>
            </>
          )}
          {activeTab === TABS.SECTORS && <Sectors updateData={updateData} sectors={depot?.sectors} TSRList={TSRList} />}
          {activeTab === TABS.DEPOT_HISTORY && (
            <NGITable isLoading={isHistoryLoading} columns={depotHistoryColumns()} data={depotsHistory} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DataTabs;
