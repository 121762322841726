import { USER_ROLES } from "utils/constants";

export const TABS = {
  GENERAL_INFORMATION: "GENERAL_INFORMATION",
  CYLINDER_INSTALL: "CYLINDER_INSTALL",
  GET_METER_STATUS: "GET_METER_STATUS",
  HISTORY: "HISTORY",
};

export const TABS_BUTTONS = [
  {
    title: "General Information",
    key: TABS.GENERAL_INFORMATION,
  },
  // {
  //   title: "Cylinder Install",
  //   key: TABS.CYLINDER_INSTALL,
  //   hideRoles: [USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.GENERAL],
  // },
  {
    title: "Get Meter Status",
    key: TABS.GET_METER_STATUS,
    hideRoles: [
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
    ],
  },
  {
    title: "History",
    key: TABS.HISTORY,
    hideRoles: [USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP],
  },
];

export const HIDE_FIELDS = ["status", "logFiles"];
export const getFieldsMapping = (type, isPx) => {
  switch (type) {
    case "Adjust Data":
      return [
        {
          name: "gasRemaining",
          title: "Gas Remaining",
          type: "number",
          required: false,
        },
        {
          name: "creditRemaining",
          title: "Credit Remaining",
          type: "number",
          required: false,
        },
        {
          name: "endCumulativeMass",
          title: "End Cumulative Mass",
          type: "number",
          required: false,
        },
        {
          name: "endCumulativeCredit",
          title: "End Cumulative Credit",
          type: "number",
          required: false,
        },
        {
          name: "cylinderSerial",
          title: "Cylinder Serial",
          type: "string",
          required: false,
        },
        {
          name: "meterChangeDate",
          title: "Meter Change Date",
          type: "datetime",
          required: false,
        },
      ];
    case "Change Battery":
      return [
        {
          name: "batteryModel",
          title: "Battery Model",
          type: "string",
          required: false,
        },
        {
          name: "batteryQuantity",
          title: "Battery Quantity",
          type: "number",
          required: false,
        },
        {
          name: "batterySerialNumberList",
          title: "Battery Serial Number List",
          type: "string",
          required: false,
        },
        {
          name: "batteryCapacity",
          title: "Battery Capacity",
          type: "number",
          required: false,
        },
      ];
    case "Cylinder Install":
      return [
        {
          name: "oldCylinderSerialNumber",
          title: "Old Cylinder Serial Number",
          type: "number",
          required: false,
        },
        {
          name: "cylinderSerial",
          title: "Cylinder Serial",
          type: "number",
          required: true,
        },
        {
          name: "gasLevel",
          title: "Gas Level",
          type: "number",
          required: false,
          rules: [
            {
              message: "Value should be between 3 and 13",
              validator: function (_, value) {
                const numberValue = parseFloat(value);
                if (!numberValue) {
                  return Promise.resolve();
                }
                if (numberValue >= 3 && numberValue <= 13) {
                  return Promise.resolve();
                }
                return Promise.reject("Value should be between 3 and 13");
              },
            },
          ],
        },
        {
          name: "datetime",
          title: "Cylinder Change Date",
          type: "datetime",
          required: false,
        },
      ];
    case "Update Settings":
      return [
        {
          name: "startPeriod",
          title: "Start Period",
          type: "number",
          required: false,
        },
        {
          name: "unitPrice",
          title: "Unit Price",
          type: "number",
          required: false,
        },
        {
          name: "gasRemaining",
          title: "Gas Remaining",
          type: "number",
          required: false,
        },
      ];
    case "Activate Meter":
      return [
        {
          name: "activateCook",
          title: "Activate Cook",
          type: "boolean",
          required: false,
          rules: [
            formInstance => ({
              validator(rule, value) {
                const activateBLE = formInstance.getFieldValue("activateBLE");
                if (!activateBLE && !value) {
                  return Promise.reject("No option is chosen");
                }
                return Promise.resolve();
              },
            }),
          ],
        },
        {
          name: "activateBLE",
          title: "Activate BLE",
          type: "boolean",
          required: false,
          rules: [
            formInstance => ({
              validator(rule, value) {
                const activateCook = formInstance.getFieldValue("activateCook");
                if (!activateCook && !value) {
                  return Promise.reject("No option is chosen");
                }
                return Promise.resolve();
              },
            }),
          ],
        },
      ];
    case "Get Logs":
      return isPx
        ? [
            {
              name: "index_start",
              title: "Index Start",
              type: "number",
              required: true,
            },
            {
              name: "count",
              title: "Count",
              type: "number",
              required: true,
            },
          ]
        : [];
    default:
      return [];
  }
};

export const API_MAPPING = {
  "Activate Meter": "activate",
  "Adjust Data": "adjustData",
  "Change Battery": "changeBattery",
  "Close Meter Lock": "lock",
  "Close Meter Valve": "closeMeterValve",
  "Cylinder Install": "cylinderInstall",
  "Deactivate Meter": "deactivate",
  "Get Cumulative Report": "triggerGetCumulativeReportStatus",
  "Get Hardware Report": "triggerGetHardwareReport",
  "Get Logs": "triggerGetLogsReport",
  "Get Meter Status": "triggerGetMeterStatusReport",
  "Get Setting": "triggerGetSettingsReport",
  "Open Meter Lock": "unlock",
  "Open Meter Valve": "openMeterValve",
  "Reset All Parameters": "resetAllParameters",
  "Self Test": "triggerMeterSelfTest",
  "Send INFQ": "sendInfq",
  "Update Settings": "updateSettings",
};

export const commandRefuseConfig = {
  "Activate Meter": ["P4-TCP"],
  "Adjust Data": [],
  "Change Battery": ["P4-TCP"],
  "Close Meter Lock": [],
  "Close Meter Valve": [],
  "Cylinder Install": [],
  "Deactivate Meter": ["P4-TCP"],
  "Get Cumulative Report": ["P4-TCP"],
  "Get Hardware Report": ["P4-TCP"],
  "Get Logs": ["P4-TCP"],
  "Get Meter Status": ["P4-TCP"],
  "Get Setting": ["P4-TCP"],
  "Open Meter Lock": [],
  "Open Meter Valve": [],
  "Reset All Parameters": ["P4-TCP"],
  "Self Test": ["P4-TCP"],
  "Send INFQ": ["P4-MQTT", "PX-MQTT"],
  "Update Settings": [],
};
