import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextArea from "antd/lib/input/TextArea";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    background: "#FFF!important",
    border: "2px solid #444444!important",
    padding: 14,
    borderRadius: 10,
    resize: "none",
  },
  white: {
    background: "#FFF!important",
  },
});

const NGITextArea = ({ className, white = false, ...props }) => {
  const classes = useStyles();

  return <TextArea {...props} className={classnames(classes.root, className, white && classes.white)} />;
};
export default NGITextArea;
