import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import userPreviewIcon from "../../../assets/images/svg/userPreview.svg";
import calendarIcon from "../../../assets/images/svg/calendarMinus.svg";
import depotIcon from "../../../assets/images/svg/depot.svg";
import familyIcon from "../../../assets/images/svg/family.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import { textFormatter } from "components/Common/formatter";
import { useLocation } from "react-router";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
    backgroundColor: "#8C8C8C",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  Pending: {
    backgroundColor: "#8C8C8C",
  },
  Incompleted: {
    backgroundColor: "#DB6607",
  },
  Declined: {
    backgroundColor: "#FF0000",
  },
  Qualified: {
    backgroundColor: "#17C347",
  },
  iconContainer: {
    padding: 8,
    marginLeft: 23,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 65,
    minWidth: 65,
    height: 65,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ lead }) => {
  const classes = useStyles();
  const { firstName, lastName, status, familySize, dob, depot, personalPhotoUrl, leadScore, sector } = lead;
  const searchParams = useLocation().search;
  const backUrl = new URLSearchParams(searchParams).get("backUrl");
  const backButtonUrl = useMemo(() => (backUrl ? backUrl : "/operations/leads"), [backUrl]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={backButtonUrl}>Back</BackButtonLink>

        <div className={classes.iconContainer}>
          <img src={personalPhotoUrl || userPreviewIcon} alt="customer" />
        </div>
        <div className={classes.nameContainer}>
          <span>{firstName && `${firstName} ${lastName}`}</span>
          {status && (
            <div className={classnames(classes.status, classes[status])}>
              {leadScore?.toFixed(4)}
              {status === "Qualified" ? " Approved" : ` ${status}`}
            </div>
          )}
        </div>
      </div>

      <div className={classes.rightContainer}>
        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <img src={familyIcon} alt="family size" />
            <div>
              <div className={classes.title}>FAMILY SIZE</div>
              <div className={classes.data}>{familySize} PEOPLE</div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <img src={calendarIcon} alt="date of birth" />
            <div>
              <div className={classes.title}>DATE OF BIRTH</div>
              <div className={classes.data}>{dob}</div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <img src={depotIcon} alt="depot" />
            <div>
              <div className={classes.title}>DEPOT</div>
              <div className={classnames(classes.data, classes.overflowText)} title={depot}>
                {depot}
              </div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <div>
              <div className={classes.title}>SECTOR</div>
              <div className={classnames(classes.data, classes.overflowText)} title={sector}>
                {textFormatter(sector)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
