import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HIDE_FIELDS } from "pages/operations/routineDetails/constants";
import { getFormattedDataByName, getFormattedKeyName } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "100%",
  },
  mapContainer: {
    width: "50%",
  },
  column: {
    width: "50%",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
  },
});

const GeneralInformationTab = ({ routine }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.informationContainer}>
        <div className={classes.column}>
          {Object.keys(routine).map(key => {
            if (HIDE_FIELDS.includes(key)) return null;
            return (
              <div key={key} className={classes.title}>
                {getFormattedKeyName(key === "updatedBy" && routine.status === "Close" ? "closedBy" : key)}
              </div>
            );
          })}
        </div>
        <div className={classes.column}>
          {Object.keys(routine).map(key => {
            if (HIDE_FIELDS.includes(key)) return null;
            return (
              <div key={key} className={classes.data}>
                {!!routine[key] ? getFormattedDataByName(key, routine[key]) : "-"}
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.mapContainer}></div>
    </div>
  );
};

export default GeneralInformationTab;
