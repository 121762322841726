import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import { createAssetType } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { getTrimmedObjectValues } from "utils/helpers/functions";

const ASSET_GROUP = {
  "Other Accessory": "Accessory",
  Battery: "Battery",
  Cylinder: "Cylinder",
  "Fire Blanket": "Fire Blanket",
  Meter: "Meter",
  Card: "RFID Card",
  Stove: "Stove",
};

const AddAssetTypeModal = ({ onCancel, isOpen, updateList }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");

  const onGroupChange = useCallback(value => setSelectedGroup(value), []);

  const onFinish = useCallback(values => {
    const { group, sapId, name, cylinderCapacity } = values;

    setLoader(true);
    createAssetType(
      getTrimmedObjectValues({
        group,
        sapId,
        name,
        ...(group === ASSET_GROUP.Cylinder && {
          cylinderAttributes: { cylinderCapacity: parseFloat(cylinderCapacity) },
        }),
      })
    )
      .then(() => {
        setLoader(false);
        updateList();
        successMessage("Asset type was created successfully");

        onCancel();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  return (
    <Modal open={isOpen} title="Add Asset Type" onCancel={onCancel} closable={false} footer={[]}>
      <Spin spinning={isLoading}>
        <NGIForm name="addAssetTypeForm" form={form} onFinish={onFinish}>
          <Col span={24}>
            <Title level={5}>Name</Title>
            <NGIFormItem name="name" rules={[{ required: true, message: "Please enter asset name" }]}>
              <NGIInput placeholder="Asset type name" />
            </NGIFormItem>
          </Col>

          <Col span={24}>
            <Title level={5}>Asset group</Title>
            <NGIFormItem name="group" rules={[{ required: true, message: "Please select asset group." }]}>
              <NGISelect showSearch onChange={onGroupChange} placeholder="Select asset group">
                {Object.keys(ASSET_GROUP).map(item => (
                  <Option key={item} value={item}>
                    {ASSET_GROUP[item]}
                  </Option>
                ))}
              </NGISelect>
            </NGIFormItem>
          </Col>

          <Col span={24}>
            <Title level={5}>Unique ID</Title>
            <NGIFormItem name="sapId" rules={[{ required: true, message: "Please select SAP ID." }]}>
              <NGIInput placeholder="Unique ID from SAP" />
            </NGIFormItem>
          </Col>

          {selectedGroup === ASSET_GROUP.Cylinder && (
            <Col span={24}>
              <Title level={5}>Cylinder Capacity</Title>
              <NGIFormItem
                name="cylinderCapacity"
                rules={[{ required: true, message: "Cylinder Capacity is required." }]}
              >
                <NGIInput placeholder="Cylinder Capacity" />
              </NGIFormItem>
            </Col>
          )}

          <Space>
            <CustomButton onClick={onCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default AddAssetTypeModal;
