export const REPORT_TYPES = ["Testing", "FactoryAcceptanceTest", "Configuration", "Repair", "Return", "Packaging"];

export const TYPE_NAMES = {
  REPORT_TYPE: "reportType",
  DEPOT: "fromDepot",
  TECHNICIAN: "maintainedBy",
  BARCODE: "barcode",
  SERIAL: "serial",
};

export const FILTER_TYPES = [
  { name: "Depot", value: TYPE_NAMES.DEPOT },
  { name: "TSR", value: TYPE_NAMES.TECHNICIAN },
  { name: "Meter barcode", value: TYPE_NAMES.BARCODE },
  { name: "Meter serial number", value: TYPE_NAMES.SERIAL },
];

export const DATA_TO_DISPLAY = [
  "id",
  "location",
  "maintainedBy",
  "maintainedAt",
  "meterId",
  "journeyStartDate",
  "serial",
  "collectedAt",
  "isGoodForUse",
  "faultyReasons",
  "factoryAcceptanceTestData",
  "returnData",
  "configurationData",
  "testingData",
  "repairData",
  "packagingData",
];
