import React, { useCallback, useState } from "react";
import { Modal, Form, Space, Col, Spin } from "antd";
import { linkTSRToCustomers } from "utils/api";
import { successMessage } from "components/Common/responses/message";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { useSelector } from "react-redux";
import { TSRSelector } from "store/TSR/selectors";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import TSRSelect from "components/Common/components/TSRSelect";

const AssignProspectModal = ({ onClose, customerIds, onUpdate }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const { TSRList } = useSelector(TSRSelector);

  const handleCancel = useCallback(() => onClose(), [onClose]);

  const handleSave = useCallback(() => {
    const { tsr } = form.getFieldsValue();
    if (tsr && customerIds.length) {
      setLoader(true);
      const selectedTSR = TSRList.find(item => item.id === tsr);
      linkTSRToCustomers({ customerIds, technician: selectedTSR.email, technicianName: selectedTSR.name })
        .then(() => {
          setTimeout(() => {
            successMessage("Prospects were linked with the new TSR successfully");
            setLoader(false);
            onUpdate();
            onClose();
          }, 2000);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [onUpdate, form, customerIds, TSRList]);

  return (
    <Modal open={true} title="Assign to TSR" closable={false} onCancel={handleCancel} footer={[]}>
      <Spin spinning={isLoading}>
        <NGIForm name="assignModalForm" form={form} onFinish={handleSave}>
          <Col span={24}>
            <Title level={5}>New TSR</Title>
            <NGIFormItem name="tsr" rules={[{ required: true, message: "Please select TSR" }]}>
              <TSRSelect
                showSearch
                initialList={TSRList}
                roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                depot={AUTH_USER?.depotName}
              />
            </NGIFormItem>
          </Col>
          <Space>
            <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default AssignProspectModal;
