import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import downloadIcon from "assets/images/svg/DownloadFromCloud.svg";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const hideRoles = [USER_ROLES.IT_OPERATIONS];

export const smsBulkColumns = (onDownload, classes) => [
  {
    title: "SMS Title",
    dataIndex: "title",
    render: (cellContent, row) =>
      userHasOneOfRoles(hideRoles) ? (
        textFormatter(cellContent)
      ) : (
        <div className={classes.overflowText}>
          <NGITooltip title={`${cellContent} details`}>
            <CustomLink to={`/sms/sms-bulk/${row.id}`}>{cellContent}</CustomLink>
          </NGITooltip>
        </div>
      ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Sent Date",
    dataIndex: "sendAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Download Report",
    dataIndex: "uploadedFile",
    render: (cellContent, row) => (
      <ButtonGroupContainer>
        <ButtonGroup
          tooltipTitle="Download"
          disabled={row.status !== "Completed"}
          icon={downloadIcon}
          handleClick={() => {
            onDownload(cellContent, row.title);
          }}
        />
      </ButtonGroupContainer>
    ),
  },
];
