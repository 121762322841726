import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import { Collapse } from "antd";
import { Card, CardBody } from "reactstrap";
const { Panel } = Collapse;

const useStyles = makeStyles({
  root: {},
  column: {
    width: "50%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "40%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "60%",
    textAlign: "end",
  },
  id: {
    width: "60%",
  },
  withId: {
    width: "20%!important",
  },
  containerWrapper: {
    display: "flex",

    "&>:first-child": {
      paddingRight: 20,
    },
  },
  locationIcon: {
    width: 8,
    marginRight: 4,
    marginTop: -2,
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  img: {
    marginRight: 5,
    marginBottom: 5,
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  rowImgs: {},
  images: {
    display: "flex",
    flexWrap: "wrap",
  },
  link: {
    color: "rgba(0, 133, 255, 1) !important",

    "&:hover": {
      textDecoration: "underline!important",
    },
  },
  collapseList: {
    backgroundColor: "#FFF",
  },
  versionContainer: {
    backgroundColor: "rgba(250, 250, 250, 1)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  section: {
    color: "rgba(102, 102, 102, 1)",

    "&>span": {
      color: "rgba(0, 0, 0, 1)",
    },
  },
});

const SurveyHistoryTab = ({ history }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {history.length ? (
        <Collapse className={classes.versionContainer}>
          {history.map(item => (
            <Panel
              key={item.id}
              header={
                <div className={classes.header}>
                  <div className={classes.section}>
                    Survey version from <span>{customDateFormatWithoutTime(item.createdAt)}</span>
                  </div>
                  <div className={classes.section}>
                    Total Questions <span>{item?.items?.length}</span>
                  </div>
                </div>
              }
            >
              <Collapse className={classes.collapseList}>
                {item?.items?.map(item => (
                  <Panel header={item.question} key={item.id}>
                    {item.type === "Select Single" || item.type === "Select Multiple" ? (
                      <ol>
                        {item?.options?.map(option => (
                          <li key={option.id}>{option.text}</li>
                        ))}
                      </ol>
                    ) : (
                      item.type
                    )}
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Card>
          <CardBody className="text-center">
            <i className="bx bx-warning" />
            <h5>No data to display</h5>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default SurveyHistoryTab;
