import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { USER_TENANT_NAME } from "utils/constants";

const useStyles = makeStyles({
  stopItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f5f5f5",
    borderRadius: 50,
    padding: 5,
    marginBottom: 10,
  },
  remove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  stopSelect: {
    width: "100%",
  },
  removeBtnContainer: {
    width: "7%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const NewStopRow = ({ item, index, setStops, depot, onRemoveStop }) => {
  const classes = useStyles();

  const handleStopChange = useCallback(
    value =>
      setStops(prevState => {
        prevState[index].depot = value;
        return [...prevState];
      }),
    [index]
  );

  const depotList = useMemo(() => depot && depot[USER_TENANT_NAME], [depot]);

  return (
    <div className={classes.stopItem}>
      <NGISelect
        showSearch
        className={classes.stopSelect}
        size="small"
        bordered={false}
        disabled={!depotList || !index}
        value={item.depot}
        onChange={handleStopChange}
        placeholder="Select depot"
      >
        {depotList &&
          depotList?.sort().map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
      </NGISelect>
      <div className={classes.removeBtnContainer}>
        {index !== 0 && (
          <div className={classes.remove} data-id={item.id} onClick={onRemoveStop}>
            <img src={closeIcon} alt="remove" />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewStopRow;
