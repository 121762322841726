export const CREATE_ACCESSORY = "CREATE_ACCESSORY";
export const CREATE_ACCESSORY_SUCCESS = "CREATE_ACCESSORY_SUCCESS";
export const CREATE_ACCESSORY_FAIL = "CREATE_ACCESSORY_FAIL";

export const READ_ACCESSORY = "READ_ACCESSORY";
export const READ_ACCESSORY_SUCCESS = "READ_ACCESSORY_SUCCESS";
export const READ_ACCESSORY_FAIL = "READ_ACCESSORY_FAIL";

export const GET_SENSORS = "GET_SENSORS";
export const GET_SENSORS_SUCCESS = "GET_SENSORS_SUCCESS";
export const GET_SENSORS_FAIL = "GET_SENSORS_FAIL";

export const GET_MICROCONTROLLERS = "GET_MICROCONTROLLERS";
export const GET_MICROCONTROLLERS_SUCCESS = "GET_MICROCONTROLLERS_SUCCESS";
export const GET_MICROCONTROLLERS_FAIL = "GET_MICROCONTROLLERS_FAIL";

export const UPDATE_ACCESSORY = "UPDATE_ACCESSORY";
export const UPDATE_ACCESSORY_SUCCESS = "UPDATE_ACCESSORY_SUCCESS";
export const UPDATE_ACCESSORY_FAIL = "UPDATE_ACCESSORY_FAIL";

export const DELETE_ACCESSORY = "DELETE_ACCESSORY";
export const DELETE_ACCESSORY_SUCCESS = "DELETE_ACCESSORY_SUCCESS";
export const DELETE_ACCESSORY_FAIL = "DELETE_ACCESSORY_FAIL";
