import React from "react";
import { Modal } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGITable from "components/Common/NGITable";
import { duplicatesListColumns } from "pages/operations/validationTaskRequestDetails/constants";

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
});

export const DuplicatesDetailsModel = ({ list, onClose }) => {
  const classes = useStyles();

  return (
    <Modal title={<div className={classes.title}>Found duplicate/s</div>} open={true} onCancel={onClose} footer={null}>
      <div className={classes.listContainer}>
        <NGITable
          columns={duplicatesListColumns}
          data={{
            result: { items: list },
          }}
        />
      </div>
    </Modal>
  );
};
