export const filterOptions = [{ name: "Name", value: "name" }];

export const SEGMENT_COLORS = {
  Gold: "#ffc400",
  Bronze: "#ff991f",
  Silver: "#97a0af",
  Platinum: "#97a0af",
  Diamond: "#b3f5ff",
  Staff: "#0747a6",
};
