import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveVehicles } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import VehicleSearch from "pages/transport-management/vehicles/VehicleSearch";
import { vehicleListColumns } from "pages/transport-management/vehicles/VehicleListColumns";
import UploadVehiclesModal from "pages/transport-management/vehicles/uploadModal";

const VehiclesList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [isUploadModalVehiclesVisible, setUploadModalVehiclesVisible] = useState(false);
  const [list, setList] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.VEHICLE);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Vehicles were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    retrieveVehicles(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const openUploadModal = useCallback(() => {
    setUploadModalVehiclesVisible(true);
  }, []);
  const closeUploadVehiclesModal = useCallback(() => {
    setUploadModalVehiclesVisible(false);
  }, []);

  const columns = useMemo(() => vehicleListColumns(), [filters]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                roles={[USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.SECURITY]}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="Upload CSV"
                onClick={openUploadModal}
              />
            </div>

            <VehicleSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.VEHICLE}
              rowSelection={null}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>
        </Container>
        {isUploadModalVehiclesVisible && (
          <UploadVehiclesModal isVisible={isUploadModalVehiclesVisible} onClose={closeUploadVehiclesModal} />
        )}
      </div>
    </>
  );
};

export default VehiclesList;
