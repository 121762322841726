import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { RESOLUTION_OPTIONS, RESOLUTIONS } from "pages/meter-management/awaitingTestingMeters/variables";
import {
  retrieveMeterDetails,
  retrieveUploadUrlByType,
  saveMeterTestingReport,
  uploadPictureToS3Bucket,
} from "utils/api";
import MeterResolutionConfirmation from "pages/meter-management/awaitingTestingMeters/MeterResolutionConfirmation";
import Title from "antd/es/typography/Title";
import NGITextArea from "components/Common/inputs/NGITextArea";
import removeIcon from "assets/images/svg/Close.svg";
import cameraIcon from "assets/images/svg/Camera.svg";
import { base64toBuffer, toBase64, uuidv4 } from "utils/helpers/functions";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import FaultyReasons from "pages/meter-management/ready-for-repair/RepairMeter/Tabs/FaultyReasons";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getPhotoRequiredReasons } from "pages/meter-management/faulty-reasons/utils";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "50%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  testResultContainer: {
    display: "flex",
  },
  faultyReasonContainer: {
    width: "30%",
    padding: "30px 0",
  },
  commentContainer: {
    width: "70%",
    borderLeft: "1px solid #f0f0f0",
    padding: 30,
  },
  checkboxRow: {
    marginBottom: 10,
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F5F5F5",
    borderRadius: 5,
    padding: 5,
    marginBottom: 10,
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
  },
  fileInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "0 10px",
  },
  fileTitle: {
    fontWeight: 600,
    fontSize: 12,
  },
  fileSize: {
    fontWeight: 300,
    fontSize: 10,
  },
  fileRemove: {
    padding: "0 8px",

    "& :hover": {
      cursor: "pointer",
    },
  },
  uploadContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "20px 0",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    fontWeight: 600,
    color: "white",
    padding: 12,
    height: 40,
    borderRadius: 20,
    textTransform: "uppercase",
    fontSize: "11px!important",

    "&> img": {
      marginRight: 10,
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    color: "#ff4d4f",
    fontSize: 10,
  },
});

const MeterTestResultModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [form] = Form.useForm();
  const { serial, id } = activeItem;
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [extendedData, setExtendedData] = useState(null);
  const [faultyReasons, setFaultyReasons] = useState([]);
  const [isPhotoRequired, setIsPhotoRequired] = useState(false);
  const {
    list: { meterFaultyReasonV2 },
  } = useSelector(DictionariesSelector);
  const reasonsToCheck = useMemo(
    () => (meterFaultyReasonV2 ? getPhotoRequiredReasons(meterFaultyReasonV2) : []),
    [meterFaultyReasonV2]
  );

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_FAULTY_REASONS_V2));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    retrieveMeterDetails(activeItem.id)
      .then(res => {
        const {
          data: { result },
        } = res;
        setExtendedData(result);
        setIsLoading(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setIsLoading(false);
      });
  }, [activeItem]);

  const handleChange = useCallback(value => {
    form.resetFields();
    setFiles([]);
    setIsFormTouched(false);
    setSelectedType(value);
  }, []);

  const getPictureIds = useCallback(
    async (fileList, id) =>
      await Promise.all(
        fileList.map(item =>
          retrieveUploadUrlByType("picture", item.fileType.replace("image/", "."), id).then(({ data: { result } }) => {
            const base64Data = base64toBuffer(item.base64);
            return uploadPictureToS3Bucket(result.url, base64Data, item.fileType).then(() => result.fileName);
          })
        )
      ),
    []
  );

  const handleSave = useCallback(async () => {
    let pictures = [];
    setIsFormTouched(true);
    const { comment } = form.getFieldsValue();
    if (selectedType === RESOLUTIONS.REPAIR) {
      if (isPhotoRequired && files.length) {
        setIsLoading(true);
        pictures = await getPictureIds(files, id);
      } else if (isPhotoRequired || !faultyReasons.length) {
        return form.submit();
      } else {
        form.submit();
      }
    }
    setIsLoading(true);
    saveMeterTestingReport({
      id,
      params: {
        resolution: selectedType,
        faultyReasons,
        comment,
        pictures,
      },
    })
      .then(() => {
        onClose();
        onUpdate();
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [form, selectedType, id, files, faultyReasons, isPhotoRequired]);

  const handleFileChange = useCallback(async event => {
    const fileUploaded = event.target.files[0];
    const base64Data = await toBase64(fileUploaded);
    setFiles(prevState => [
      ...prevState,
      {
        _id: uuidv4(),
        name: fileUploaded.name,
        size: (fileUploaded.size / 1000).toFixed(2),
        base64: base64Data,
        fileType: fileUploaded.type,
      },
    ]);
  }, []);

  const handleRemove = useCallback(event => {
    const id = event.target.dataset.id;
    setFiles(prevState => prevState.filter(item => item._id !== id));
  }, []);

  useEffect(() => {
    const isRequired = faultyReasons.some(item => reasonsToCheck.includes(item));
    setIsPhotoRequired(isRequired);
  }, [faultyReasons]);

  return (
    <Modal
      open={isOpen}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Meter Test Results
            <span>{`Meter (${serial})`}</span>
          </div>
          <NGISelect value={selectedType} onChange={handleChange} placeholder="Please select resolution" allowClear>
            {RESOLUTION_OPTIONS.map(item => (
              <Option value={item.value} key={item.value}>
                {item.name}
              </Option>
            ))}
          </NGISelect>
        </div>
      }
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          {!selectedType && (
            <div>
              <div className={classes.description}>Meter Details</div>
              <div className={classes.container}>
                <div className={classes.listBlock}>
                  <div className={classes.item}>
                    <div className={classes.title}>meter serial number</div>
                    <div className={classes.value}>{extendedData?.serial ?? "-"}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>meter barcode</div>
                    <div className={classes.value}>{extendedData?.barcode ?? "-"}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>pcb serial number</div>
                    <div className={classes.value}>{extendedData?.pcb ?? "-"}</div>
                  </div>
                </div>
                <div className={classes.listBlock}>
                  <div className={classes.item}>
                    <div className={classes.title}>current firmware</div>
                    <div className={classes.value}>{extendedData?.firmware ?? "-"}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>sim card</div>
                    <div className={classes.value}>{extendedData?.removableIccid ?? "-"}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>collected date</div>
                    <div className={classes.value}>{extendedData?.maintenanceStartDate ?? "-"}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedType === RESOLUTIONS.CERTIFIED && <MeterResolutionConfirmation resolution={selectedType} />}
          {selectedType === RESOLUTIONS.REPAIR && (
            <Form form={form} name="for-repair">
              <div className={classes.testResultContainer}>
                <FaultyReasons
                  className={classes.faultyReasonContainer}
                  updateFaultyReason={setFaultyReasons}
                  setPhotoRequired={setIsPhotoRequired}
                />
                <div className={classes.commentContainer}>
                  <Col>
                    <Title level={5}>Additional Comments</Title>
                    <Form.Item name="comment">
                      <NGITextArea rows={5} placeholder="Please add your comments" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Title level={5}>Attach Photos</Title>
                    {files &&
                      files.map(item => (
                        <div className={classes.fileItem} key={item._id}>
                          <div className={classes.container}>
                            <img className={classes.filePreview} src={item.base64} alt="preview img" />
                            <div className={classes.fileInfo}>
                              <div className={classes.fileTitle}>{item.name}</div>
                              <div className={classes.fileSize}>{`${item.size} kb`}</div>
                            </div>
                          </div>
                          <div className={classes.fileRemove}>
                            <img onClick={handleRemove} data-id={item._id} src={removeIcon} alt="remove" />
                          </div>
                        </div>
                      ))}
                    <div className={classes.uploadContainer}>
                      <label onChange={handleFileChange} htmlFor="formId" className={classes.uploadButton}>
                        <input accept="image/*" type="file" id="formId" hidden />
                        <img src={cameraIcon} alt="camera" />
                        Add photo
                      </label>
                    </div>
                    {isPhotoRequired && !files.length && isFormTouched && (
                      <div className={classes.errorMsg}>Please attach photos</div>
                    )}
                  </Col>
                </div>
              </div>
            </Form>
          )}
        </div>
      </Spin>
      <Space>
        <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
        <CustomButton onClick={handleSave} disabled={!selectedType} type="primary" size="small" text="Confirm" />
      </Space>
    </Modal>
  );
};

export default MeterTestResultModal;
