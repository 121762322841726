export const dateFormat = "DD/MM/YYYY";

export const STO_VIEW_TYPES = {
  ORDER: "Order",
  ASSET: "Asset",
};

export const DEPOT_TYPES = {
  DEPOT_TO: "toDepot",
  DEPOT_FROM: "fromDepot",
};

export const STO_TYPES = {
  OUTBOUND: "Outbound",
  INBOUND: "Inbound",
};

export const DEPOT_STO_TYPE_MAP = {
  [DEPOT_TYPES.DEPOT_FROM]: STO_TYPES.INBOUND,
  [DEPOT_TYPES.DEPOT_TO]: STO_TYPES.OUTBOUND,
};

export const DEPOT_TO_STO_TYPE_MAP = {
  [DEPOT_TYPES.DEPOT_FROM]: STO_TYPES.OUTBOUND,
  [DEPOT_TYPES.DEPOT_TO]: STO_TYPES.INBOUND,
};

export const DEPOT_TYPE_SELECT = [
  {
    title: "From Depot",
    value: DEPOT_TYPES.DEPOT_FROM,
  },
  {
    title: "To Depot",
    value: DEPOT_TYPES.DEPOT_TO,
  },
];

export const searchOptions = [
  {
    value: "assetGroup",
    title: "Asset Type",
  },
  {
    value: "stoId",
    title: "Order ID",
  },
  {
    value: "tripId",
    title: "Trip ID",
  },
  {
    value: "stoStatus",
    title: "STO Status",
  },
  {
    value: "date",
    title: "Preferred Date",
  },
];

export const getStatusList = viewType => [
  "Pending",
  "Partially Planned",
  "Planned",
  "InTransit",
  ...(viewType === STO_VIEW_TYPES.ORDER ? [] : ["Completed"]),
];
