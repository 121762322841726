import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const TYPES = ["barcode", "customerId", "custodianType", "routineId", "cardNumber", "qrCode"];

const AsserSearch = props => {
  const [form] = Form.useForm();
  const { onFilter, showLocation } = props;
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.ASSET);

  useEffect(() => {
    if (!!filters && !filters?.state) {
      const filterKey = Object.keys(filters)[0];
      if (TYPES.includes(filterKey)) {
        form.setFieldsValue({
          category: filterKey,
          value: filters[filterKey],
          value2: filters[filterKey],
        });
      }
    } else
      form.setFieldsValue({
        category: null,
        value: null,
        value2: null,
      });
  }, [filters]);

  const onFinish = values => {
    const { category, value, value2 } = values;
    const { pageSize } = getFilters();
    const filter = {};
    if (category === "location") filter[category] = value2;
    else if (category === "custodianType") {
      const custodian = value.split("/");
      filter.custodian = custodian[1]?.trim();
      filter.custodianType = custodian[0]?.trim();
    } else filter[category] = value.trim();
    onFilter({ ...filter, pageSize });
  };

  const handleAssetCategory = filter => {
    switch (filter) {
      case "barcode":
        form.setFieldsValue({
          note: "barcode",
        });
        return;
      case "customerId":
        form.setFieldsValue({
          note: "customerId",
        });
        return;
      case "custodianType":
        form.setFieldsValue({
          note: "custodian",
        });
        return;
      case "routineId":
        form.setFieldsValue({
          note: "routineId",
        });
        return;
      default:
        return;
    }
  };

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item name="category" rules={[{ required: true, message: "Please select type" }]}>
          <Select
            style={{ width: "100%" }}
            onChange={handleAssetCategory}
            tokenSeparators={[","]}
            size={"default"}
            placeholder={"Select type"}
            allowClear
          >
            <Option value={"barcode"}>Barcode</Option>
            <Option value={"customerId"}>Customer ID</Option>
            <Option value={"custodianType"}>Сustodian</Option>
            <Option value={"routineId"}>Routine ID</Option>
            <Option value={"cardNumber"}>Card Number</Option>
            <Option value={"qrCode"}>QR Code</Option>
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.category !== currentValues.category}>
          {({ getFieldValue }) =>
            getFieldValue("category") === "location" ? (
              <Form.Item name="value2" rules={[{ required: true, message: "Please select value" }]}>
                <Select
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  size={"default"}
                  placeholder={"Select value"}
                  allowClear
                >
                  {showLocation.length > 0 &&
                    showLocation.map(
                      item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ),
                      this
                    )}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
                <Input placeholder="Enter value" allowClear pattern={inputTextPattern} title={inputPatternTitle} />
              </Form.Item>
            )
          }
        </Form.Item>
        <CustomButton type="primary" htmlType="submit" text="Search" size="small" />
      </Form>
    </div>
  );
};

AsserSearch.propTypes = {
  onFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showLocation: PropTypes.array,
};

export default AsserSearch;
