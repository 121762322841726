import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { fetchVersionById } from "utils/api";
import Header from "pages/settings/contract-versions/versionCreate/Header";
import DataTabs from "pages/settings/contract-versions/versionCreate/DataTabs";

const ContractVersionDetails = () => {
  const { version } = useParams();
  const [contractVersion, setContractVersion] = useState();
  const [isAdditionalDataLoading, setIsAdditionalDataLoading] = useState(false);

  useEffect(() => {
    if (version) {
      setIsAdditionalDataLoading(true);
      fetchVersionById(version).then(res => {
        setContractVersion(res.data.result);
        setIsAdditionalDataLoading(false);
      });
    }
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <>
            <Header contractVersion={contractVersion} />
            <DataTabs isLoading={isAdditionalDataLoading} contractVersion={contractVersion} version={version} />
          </>
        </Container>
      </div>
    </>
  );
};

export default ContractVersionDetails;
