import React, { useCallback, useEffect, useState } from "react";
import NGITable from "components/Common/NGITable";
import { retrieveMeterSettings } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { settingsColumns } from "pages/meter-management/meters/meterDetails/Tabs/getMeterStatus/constants";
import { isEmpty } from "lodash";

const Settings = ({ id }) => {
  const [settingValues, setSettingValues] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const onGetSettings = useCallback(() => {
    setLoading(true);
    retrieveMeterSettings(id)
      .then(res => {
        if (!isEmpty(res.data.result)) {
          setSettingValues({ result: { items: [res.data.result] } });
        } else {
          setSettingValues([]);
        }
        setLoading(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    onGetSettings();
  }, []);

  return <NGITable isLoading={isLoading} columns={settingsColumns} data={settingValues} />;
};

export default Settings;
