import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { deleteTeam, fetchTeamsList } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { teamsListColumns } from "pages/depot-management/teams/teamsListColumns";
import TeamsSearch from "pages/depot-management/teams/TeamsSearch";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import CustomButton from "components/Common/buttons/CustomButton";
import NewTeamModal from "pages/depot-management/teams/NewTeamModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const Teams = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [response, setResponse] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [activeTeam, setActiveTeam] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.TEAMS);

  const hideRoles = [USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.GENERAL, USER_ROLES.FINANCE];

  useEffect(() => {
    handleSearch(filters);
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;

    if (items?.length === 0) {
      warnMessage("Teams were not found");
      setLoader(false);
    }

    setResponse(res?.data || {});
    setLoader(false);
  }, []);

  const handleSearch = useCallback((filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);
    fetchTeamsList({
      ...filterOptions,
    })
      .then(async res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setResponse(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const handleRemove = useCallback(() => {
    deleteTeam(activeTeam.name, activeTeam.depot)
      .then(() => {
        successMessage("Team was deleted successfully");
        closeConfirmationModal();
        handleSearch();
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, [activeTeam]);

  const openConfirmationModal = useCallback(team => {
    setIsConfirmationOpen(true);
    setActiveTeam(team);
  }, []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const columns = useMemo(() => {
    const col = teamsListColumns(openConfirmationModal);
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [openConfirmationModal]);

  const openAddModal = useCallback(() => setShowAddModal(true), []);
  const closeAddModal = useCallback(() => setShowAddModal(false), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                hideRoles={hideRoles}
                type="primary"
                icon={<i className="bx bx-plus-circle" />}
                text="NEW TEAM"
                onClick={openAddModal}
              />
            </div>
            <TeamsSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.TEAMS}
              isLoading={isLoading}
              columns={columns}
              updateList={handleSearch}
              data={response}
            />
          </Col>
        </Container>
        {isConfirmationOpen && (
          <ConfirmationModal
            description={"Are you sure to delete this team?"}
            onCancel={closeConfirmationModal}
            onConfirm={handleRemove}
          />
        )}
        {showAddModal && <NewTeamModal onUpdate={handleSearch} onClose={closeAddModal} />}
      </div>
    </>
  );
};

export default Teams;
