import { all, fork } from "redux-saga/effects";
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import statsSaga from "./dashboard/stats/saga";
import assetsSaga from "./warehouse/assets/saga";
import assetTypesSaga from "./warehouse/configurations/asset-types/saga";
import tasksSaga from "./operations/tasks/saga";
import accessorySaga from "./meter-management/configuration/accessories/saga";
import globalSettingsSaga from "./meter-management/configuration/global-settings/saga";
import DepotSaga from "./auth/depotLogin/saga";
import firmwareSaga from "./meter-management/configuration/firmware/saga";
import meterReportsSaga from "./meter-management/logs/reports/saga";
import meterRegistrationSaga from "./meter-management/preparation/registration/saga";
import meterCommands from "./meter-management/meters/saga";
import PackagesSaga from "store/packages/saga";
import DictionariesSaga from "store/dictionaries/saga";
import TSRSaga from "store/TSR/saga";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(statsSaga),
    ProfileSaga(),
    fork(assetsSaga),
    fork(assetTypesSaga),
    fork(tasksSaga),
    fork(accessorySaga),
    fork(DepotSaga),
    fork(firmwareSaga),
    fork(globalSettingsSaga),
    fork(meterReportsSaga),
    fork(meterRegistrationSaga),
    fork(meterCommands),
    fork(PackagesSaga),
    fork(DictionariesSaga),
    fork(TSRSaga),
  ]);
}
