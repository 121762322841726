import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { linkTSRToCustomer } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIInput from "components/Common/inputs/NGIInput";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { warnMessage } from "components/Common/responses/message";
import { useSelector } from "react-redux";
import { TSRSelector } from "store/TSR/selectors";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import TSRSelect from "components/Common/components/TSRSelect";

const TSRModal = ({ onClose, isOpen, customer, onUpdate }) => {
  const [form] = Form.useForm();
  const { TSRList } = useSelector(TSRSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTSR, setSelectedTSR] = useState(null);

  const handleCancel = useCallback(() => onClose(), [onClose]);

  const handleSave = useCallback(() => {
    setIsLoading(true);
    // const tsr = list.find(item => item.id === selectedTSR);
    linkTSRToCustomer({ id: customer.id, technician: selectedTSR.email, technicianName: selectedTSR.name }).then(() => {
      onUpdate({ ...customer, technicianName: selectedTSR.name, technician: selectedTSR.email });
      onClose();
      warnMessage("Customer is successfully assigned to the new TSR.");
      setIsLoading(false);
    });
  }, [onUpdate, selectedTSR, customer]);

  const handleChange = useCallback(technician => setSelectedTSR(technician), []);

  return (
    <Modal open={isOpen} title="Link customer to TSR" closable={false} onCancel={handleCancel} footer={[]}>
      <>
        <Spin spinning={isLoading}>
          <Form name="customerToTsr" form={form} onFinish={handleSave}>
            <Col span={24}>
              <Title level={5}>Current TSR</Title>
              <NGIFormItem>
                <NGIInput disabled value={customer?.technicianName || "-"} />
              </NGIFormItem>
            </Col>
            <Col span={24}>
              <Title level={5}>New TSR</Title>
              <NGIFormItem name="tsr" rules={[{ required: true, message: "Please select TSR" }]}>
                <TSRSelect
                  showSearch
                  initialList={TSRList}
                  handleChange={handleChange}
                  roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                  depot={AUTH_USER?.depotName}
                />
              </NGIFormItem>
            </Col>
            <Space>
              <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
              <CustomButton disabled={!selectedTSR} htmlType="submit" type="primary" size="small" text="Save" />
            </Space>
          </Form>
        </Spin>
      </>
    </Modal>
  );
};

export default TSRModal;
