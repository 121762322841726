import React, { useCallback, useState } from "react";
import { updateSparePartCounterAPI } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { CardBody } from "reactstrap";
import { Col, Form, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import NGIInput from "components/Common/inputs/NGIInput";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { history } from "store";
import { AUTH_USER } from "utils/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  formItem: {
    width: 300,
  },
});

const Edit = ({ id }) => {
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [form] = Form.useForm();

  const onSave = useCallback(
    count => {
      setLoading(true);
      updateSparePartCounterAPI({ id, params: { ...count, depot: AUTH_USER?.depotName } })
        .then(() => {
          setLoading(false);
          successMessage(`Data was saved successfully`);
          history.push("/meters/spare-parts");
        })
        .catch(err => {
          setLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [id]
  );

  return (
    <CardBody className={classes.cardBody}>
      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onSave} className="sent-commands-page">
          <Col span={24}>
            <Title level={5}>{"Received parts:"}</Title>
            <Form.Item
              className={classes.formItem}
              name="count"
              rules={[{ required: true, message: "This field can not be empty" }]}
            >
              <NGIInput type={"number"} min={0} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Col>
                <Space>
                  <CustomButton type="primary" htmlType="submit" size="small" text="Update Quantity" />
                </Space>
              </Col>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </CardBody>
  );
};

export default Edit;
