import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Option } from "antd/lib/mentions";
import { retrieveTasksStatistics } from "utils/api";
import { Divider, Select } from "antd";
import NGICollapsibleTable from "components/Common/NGICollapsibleTable";
import { sourceStatsColumns, taskSourceTypes } from "pages/dashboard/variables";
import { getMappedSourceData } from "pages/dashboard/utils";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { AUTH_USER } from "utils/constants";
import { useSelector } from "react-redux";
import { TSRSelector } from "store/TSR/selectors";
import classnames from "classnames";

const useStyles = makeStyles({
  filter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  filterTitle: {
    padding: 10,
    color: "rgba(140, 140, 140, 1)",
  },
  filterSelect: {
    minWidth: 100,
    fontSize: 15,
    "&>.ant-select-selector": {
      border: "0!important",
    },
  },
  divider: {
    margin: 0,
    padding: 10,
  },
  counter: {
    fontSize: 16,
    flexGrow: 1,
    textAlign: "end",
    fontWeight: 500,

    "&>span:first-child": {
      marginRight: 5,
    },
    "&>span:nth-child(2)": {
      marginLeft: 5,
      color: "rgba(23, 195, 71, 1)",
    },
  },
  tsrSelect: {
    maxWidth: 220,
  },
});

export const SourceSection = () => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [stats, setStats] = useState({ list: [], all: 0, complited: 0, mapping: {} });
  const [taskSourceType, setTaskSourceType] = useState(taskSourceTypes[0]);
  const [selectedTSR, setSelectedTSR] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState([]);
  const { TSRList } = useSelector(TSRSelector);

  useEffect(() => {
    filterData(taskSourceType);
  }, []);

  const onTypeChange = useCallback(value => {
    setTaskSourceType(value);
    setSelectedTSR(null);
    filterData(value);
  }, []);

  const onTSRChange = useCallback(
    value => {
      setSelectedTSR(value);
      setSelectedPriority([]);
      filterData(taskSourceType, value);
    },
    [taskSourceType]
  );

  const onPriorityChange = useCallback(
    value => {
      if (value.length) {
        if (!isNaN(value[value.length - 1])) {
          setSelectedPriority(value);
          filterData(taskSourceType, selectedTSR, value?.join(","));
        }
      } else {
        setSelectedPriority([]);
        filterData(taskSourceType, selectedTSR, []);
      }
    },
    [taskSourceType, selectedTSR]
  );

  const filterData = useCallback((type, filterByEmail = null, priority = []) => {
    setLoader(true);
    retrieveTasksStatistics(
      {
        depot: AUTH_USER?.depotName,
        filterBySource: type,
        filterByEmail,
        selectedPriority: priority.length ? priority : null,
        filterByStatus: "PriorityTasks",
      },
      "v2"
    )
      .then(res => {
        const data = getMappedSourceData(res?.data?.result?.sourceStats);
        setStats(data);
        setLoader(false);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, []);

  return (
    <NGICollapsibleTable
      isLoading={isLoading}
      size="small"
      headerContent={
        <>
          <div className={classes.filter}>
            <div className={classes.filterTitle}>Sources:</div>
            <Select
              showSearch
              value={taskSourceType}
              className={classes.filterSelect}
              placeholder={"Select type"}
              onChange={onTypeChange}
            >
              {taskSourceTypes.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            {taskSourceType !== "AssignedTo" && (
              <>
                <div className={classes.filterTitle}>TSR:</div>
                <Select
                  showSearch
                  value={selectedTSR}
                  className={classnames(classes.filterSelect, classes.tsrSelect)}
                  placeholder={"Select TSR"}
                  onChange={onTSRChange}
                  allowClear
                >
                  {TSRList.map(item => (
                    <Option key={item.id} value={item.email}>
                      {item.email}
                    </Option>
                  ))}
                </Select>
              </>
            )}
            <div className={classes.filterTitle}>Priority:</div>
            <Select
              showSearch
              value={selectedPriority}
              className={classnames(classes.filterSelect, classes.tsrSelect)}
              placeholder={"Select priority"}
              onChange={onPriorityChange}
              allowClear
              mode="tags"
            />
          </div>

          <Divider className={classes.divider} />
        </>
      }
      data={stats.list}
      columns={sourceStatsColumns(taskSourceType, stats.mapping)}
    />
  );
};
