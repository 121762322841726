import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import CustomButton from "components/Common/buttons/CustomButton";
import { FrownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import classnames from "classnames";

const useStyles = makeStyles({
  notFoundContainer: {
    position: "relative",
    color: "rgba(68, 68, 68, 1)",
  },
  linkText: {
    marginTop: "15%",
  },
  modalMargin: {
    marginTop: "10%",
  },
  icon: {
    fontSize: 150,
    display: "block",
  },
  modalIcon: {
    marginBottom: 30,
  },
  code: {
    fontWeight: 200,
    fontSize: 60,
    display: "block",
  },
  text: {
    fontWeight: 100,
    marginBottom: 20,
    fontSize: 30,
    display: "block",
    whiteSpace: "pre-line",
  },
  linkContainer: {
    width: "100%",
    height: "100%",
    zIndex: 9,
    background: "#ffffffeb",
    textAlign: "center",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
  },
  pageMode: {
    minHeight: "100vh",
  },
});

const NotFoundContainer = ({
  isNotFound = false,
  backUrl = "",
  text = "Oops! The requested data is not found.",
  code = "404",
  isModal = false,
  children,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = useCallback(() => (backUrl ? history.push(backUrl) : history.goBack()), [backUrl]);

  if (isNotFound)
    return (
      <div className={classes.notFoundContainer}>
        {children}
        <div className={classnames(classes.linkContainer, !isModal && classes.pageMode)}>
          <div className={isModal ? classes.modalMargin : classes.linkText}>
            {!isModal && <FrownOutlined className={classes.icon} />}
            {isModal && <InfoCircleOutlined className={classnames(classes.icon, classes.modalIcon)} />}
            <span className={classes.code}>{code}</span>
            <span className={classes.text}>{text}</span>
            {!isModal && <CustomButton size={"small"} onClick={handleBack} type="primary" text="Go Back" />}
          </div>
        </div>
      </div>
    );
  return children;
};

export default NotFoundContainer;
