import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIForm from "components/Common/form/NGIForm";
import NGIInput from "components/Common/inputs/NGIInput";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NGIFormItem from "components/Common/form/NGIFormItem";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { createDictionary } from "utils/api";

const useStyles = makeStyles({
  root: {
    width: "650px!important",
  },
  newTypeRow: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  addButton: {
    marginTop: 10,
  },
  typeList: {},
  typeItem: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 50,
    fontWeight: 600,
    marginBottom: 15,
    padding: "10px 20px",
  },
  desc: {
    fontWeight: 300,
  },
  close: {
    cursor: "pointer",
  },
});
const UpdateFileTypeModal = ({ onClose, visible }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const {
    list: { meterFirmwareFileType },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    setLoading(true);
    dispatch(getDictionary(DICTIONARY_TYPES.METER_FIRMWARE_FILE_TYPE));
  }, []);

  useEffect(() => {
    if (meterFirmwareFileType?.length) {
      setList(meterFirmwareFileType);
      setLoading(false);
    }
  }, [meterFirmwareFileType]);

  const onSave = useCallback(async () => {
    setLoading(true);
    closeConfirmationModal();

    const formattedData = {
      name: DICTIONARY_TYPES.METER_FIRMWARE_FILE_TYPE,
      items: list,
    };

    await createDictionary(formattedData)
      .then(() => {
        setLoading(false);
        successMessage(`Data was saved successfully`);
        onClose();
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [list]);

  const openDeleteConfirmation = useCallback(e => {
    const index = Number(e.target.dataset.index);
    setDeleteIndex(index);
  }, []);
  const closeDeleteConfirmation = useCallback(() => setDeleteIndex(null), []);

  const onAdd = useCallback(
    values => {
      const { id, desc } = values;
      const foundValue = list.find(item => item.id === Number(id));
      if (!foundValue) {
        setList(prevState => [...prevState, { desc, id: Number(id) }]);
        form.resetFields();
      } else {
        form.setFields([
          {
            name: "id",
            errors: ["Entered ID already exist."],
          },
        ]);
      }
    },
    [list, form]
  );

  const onRemove = useCallback(
    () =>
      setList(prevState => {
        prevState.splice(deleteIndex, 1);
        setDeleteIndex(null);
        return [...prevState];
      }),
    [deleteIndex]
  );

  const openConfirmationModal = useCallback(() => setConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setConfirmationOpen(false), []);

  return (
    <Modal
      className={classes.root}
      title="Update File Type"
      open={visible}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <>
        <Spin spinning={loading}>
          <NGIForm name="updateFileType" form={form} onFinish={onAdd}>
            <Row className={classes.newTypeRow}>
              <Col span={9}>
                <Title level={5}>New File Type ID</Title>
                <NGIFormItem name="id" rules={[{ required: true, message: "Please enter ID" }]}>
                  <NGIInput disablePattern placeholder="Enter ID" />
                </NGIFormItem>
              </Col>
              <Col span={9}>
                <Title level={5}>New File Type Description</Title>
                <NGIFormItem name="desc" rules={[{ required: true, message: "Please enter description" }]}>
                  <NGIInput disablePattern placeholder="Enter description" />
                </NGIFormItem>
              </Col>
              <Col span={4}>
                <CustomButton htmlType="submit" className={classes.addButton} size="small" type="primary" text="Add" />
              </Col>
            </Row>
          </NGIForm>
          <div className={classes.typeList}>
            {list.map((item, index) => (
              <div key={item.id} className={classes.typeItem}>
                <span>
                  {item.id} <span className={classes.desc}>{item.desc}</span>
                </span>
                <img
                  src={closeIcon}
                  className={classes.close}
                  alt="close"
                  data-index={index}
                  onClick={openDeleteConfirmation}
                />
              </div>
            ))}
          </div>
          <Col>
            <Space>
              <CustomButton size="small" onClick={onClose} type="primary" color="outlined" text="Cancel" />
              <CustomButton size="small" onClick={openConfirmationModal} type="primary" text="Save" />
            </Space>
          </Col>
        </Spin>
        {!!confirmationOpen && (
          <ConfirmationModal
            onCancel={closeConfirmationModal}
            onConfirm={onSave}
            description="The 'File Type' dictionary will be updated. Are you sure?"
          />
        )}
        {Number.isInteger(deleteIndex) && (
          <ConfirmationModal
            onCancel={closeDeleteConfirmation}
            onConfirm={onRemove}
            description="Are you sure you want to delete the value?"
          />
        )}
      </>
    </Modal>
  );
};

export default UpdateFileTypeModal;
