import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { linkTSRToCustomers } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import uploadArea from "assets/images/svg/uploadArea.svg";
import Dragger from "antd/lib/upload/Dragger";
import * as xlsx from "xlsx";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { useSelector } from "react-redux";
import { TSRSelector } from "store/TSR/selectors";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { makeStyles } from "@material-ui/core/styles";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import TSRSelect from "components/Common/components/TSRSelect";

const useStyles = makeStyles({
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});

const BulkLinkTSRModal = ({ onClose, onUpdate }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [customerIds, setCustomerIds] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const { TSRList } = useSelector(TSRSelector);
  const [file, setFile] = useState(null);

  const handleCancel = useCallback(() => onClose(), [onClose]);

  const readUploadFile = useCallback(e => {
    const file = e.file;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        const formattedResult = json.map(item => {
          if (item.customerId) return item.customerId ? item.customerId : null;
          if (item.id) return item.id ? item.id : null;
          return null;
        });
        const noError = formattedResult.every(item => item || null);
        !noError && errorMessage("Please upload the correct ‘*.csv’ or ‘*.xlsx’ file with id or customerId fields");

        if (noError) {
          setFile(file);
          setCustomerIds(formattedResult);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const handleSave = useCallback(() => {
    const { tsr } = form.getFieldsValue();
    if (tsr && customerIds.length) {
      setLoader(true);
      const selectedTSR = TSRList.find(item => item.id === tsr);
      linkTSRToCustomers({ customerIds, technician: selectedTSR.email, technicianName: selectedTSR.name })
        .then(() => {
          setTimeout(() => {
            successMessage("Customers were linked with the new TSR successfully");
            setLoader(false);
            onUpdate();
            onClose();
          }, 2000);
        })
        .catch(err => {
          if (err?.response?.data?.errors[0]?.code === "NOT_FOUND") {
            defaultNgiErrorMethod(err, 0);
          } else {
            errorMessage(
              "Customers were not found and/or not linked to the new TSR! Please verify the list of IDs in the file."
            );
          }
          setLoader(false);
        });
    }
  }, [form, customerIds, TSRList]);

  const removeFile = useCallback(() => {
    setFile(null);
    setCustomerIds([]);
  }, []);

  return (
    <Modal open={true} title="Bulk link customers to TSR" closable={false} onCancel={handleCancel} footer={[]}>
      <Spin spinning={isLoading}>
        <NGIForm name="bulkLinkCustomersToTSR" form={form} onFinish={handleSave}>
          <Col span={24}>
            {file ? (
              <div className={classes.fileContainer}>
                <div className={classes.fileTitle}>{`${file.name} (${customerIds.length})`}</div>
                <div onClick={removeFile} className={classes.removeBtn}>
                  <img src={closeIcon} alt="remove" />
                </div>
              </div>
            ) : (
              <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                <img className="upload-bg" src={uploadArea} alt="upload" />
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single upload. Strictly prohibit from uploading company data or other band files
                </p>
              </Dragger>
            )}
          </Col>
          <br />
          <Col span={24}>
            <Title level={5}>New TSR</Title>
            <NGIFormItem name="tsr" rules={[{ required: true, message: "Please select TSR" }]}>
              <TSRSelect
                showSearch
                initialList={TSRList}
                roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                depot={AUTH_USER?.depotName}
              />
            </NGIFormItem>
          </Col>
          <Space>
            <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default BulkLinkTSRModal;
