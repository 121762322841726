import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import ProspectDetailsSection from "pages/operations/prospectInfo/ProspectDetailsSection";
import AssignProspectModal from "pages/operations/prospects/AssignProspectModal";
import { useHistory } from "react-router";
import CustomButton from "components/Common/buttons/CustomButton";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { archiveProspect } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    "& button + button": {
      marginLeft: 10,
    },
  },
  show: {
    display: "block",
  },
});

const DataTabs = ({ prospect, allDepots, setIsLoading, isLoading }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const openAssignModal = useCallback(() => setIsAssignModalVisible(true), []);
  const closeAssignModal = useCallback(() => setIsAssignModalVisible(false), []);
  const openConfirmation = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmation = useCallback(() => setIsConfirmationOpen(false), []);
  const onUpdate = useCallback(() => history.push("/operations/prospects"), []);
  const onArchiveProspect = useCallback(() => {
    setIsLoading(true);
    archiveProspect(prospect.id)
      .then(() => {
        successMessage("Prospect were archived successfully");
        setIsLoading(false);
        onUpdate();
        closeConfirmation();
      })
      .catch(e => {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      });
  }, [prospect]);

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardBody>
          <ProspectDetailsSection prospect={prospect} allDepots={allDepots} />
          <div className={classes.buttonsContainer}>
            {(prospect?.status === "OpenedProspect" || prospect?.status === "AssignedProspect") && (
              <CustomButton color="outlined" text="Archive Prospect" onClick={openConfirmation} />
            )}
            {prospect?.status === "OpenedProspect" && (
              <CustomButton type="success" text="Assign to TSR" onClick={openAssignModal} />
            )}
          </div>
          {isAssignModalVisible && (
            <AssignProspectModal onClose={closeAssignModal} customerIds={[prospect.id]} onUpdate={onUpdate} />
          )}
          {isConfirmationOpen && (
            <ConfirmationModal
              description="Are you sure you want to archive this prospect?"
              onCancel={closeConfirmation}
              onConfirm={onArchiveProspect}
              loading={isLoading}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DataTabs;
