import React from "react";
import { Row } from "antd";
import { customDateFormatWithTime } from "components/Common/formatter";
import { makeStyles } from "@material-ui/core/styles";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    "&> p": {
      color: "grey",
      "&> span": {
        fontWeight: "500",
        color: "black",
      },
    },
  },
});

const LoadedAssetItem = ({ item }) => {
  const classes = useStyles();

  return (
    <Row justify={"space-between"} className={classes.root}>
      <p>
        QrCode:{" "}
        <span>
          <CustomLink to={`/warehouse/assets/${item.id}`} target={"_blank"} underlined>
            {item.qrCode}
          </CustomLink>
        </span>
      </p>
      <p>
        Serial Number: <span>{item.serialNumber}</span>
      </p>
      <p>
        Scanned At: <span>{customDateFormatWithTime(item.scannedAt)}</span>
      </p>
    </Row>
  );
};

export default LoadedAssetItem;
