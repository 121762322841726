import { textFormatter, customDateFormatWithTime } from "components/Common/formatter";

export const assetRequestHistoryListColumns = [
  {
    title: "Request Id",
    dataIndex: "id",
    render: (cellContent, asset) => textFormatter(asset.id),
  },
  {
    title: "Type",
    dataIndex: "type",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.type),
  },
  {
    title: "Status",
    dataIndex: "status",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.status),
  },
  {
    title: "Requested For",
    dataIndex: "requestedFor",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.requestedFor),
  },
  {
    dataIndex: "createdAt",
    title: "Created At",
    sort: true,
    render: (cellContent, asset) => textFormatter(customDateFormatWithTime(asset.createdAt)),
  },
  {
    dataIndex: "createdBy",
    title: "Approved By",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.createdBy),
  },
  {
    dataIndex: "updatedBy",
    title: "Updated By",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.updatedBy),
  },
];
