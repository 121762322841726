import { USER_ROLES } from "utils/constants";

export const TABS = {
  SURVEY_DETAILS: "SURVEY_DETAILS",
  EDIT_SURVEY: "EDIT_SURVEY",
  SURVEY_HISTORY: "SURVEY_HISTORY",
};

export const getTabButtons = isCreate => [
  ...(!isCreate
    ? [
        {
          title: "Survey Details",
          key: TABS.SURVEY_DETAILS,
        },
      ]
    : []),
  {
    title: isCreate ? "Survey Details" : "Edit Survey",
    key: TABS.EDIT_SURVEY,
    hideRoles: [USER_ROLES.IT_OPERATIONS],
  },
  ...(!isCreate
    ? [
        {
          title: "History",
          key: TABS.SURVEY_HISTORY,
        },
      ]
    : []),
];

export const getBreacrumbs = isCreate => [
  {
    name: "Settings",
  },
  {
    name: "Survey Management",
  },
  {
    name: isCreate ? "Create New Survey" : "Survey Details",
  },
];
