import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import userIcon from "assets/images/svg/user.svg";
import { camelCaseTextFormatter } from "components/Common/formatter";
import { AUTH_USER } from "utils/constants";

const ProfileMenu = () => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);

  return (
    <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
      <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
        <img src={userIcon} alt="Header Avatar" />
        <span className="user-name">
          Hello <b>{AUTH_USER?.firstName}</b>
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <span className="hello-user">
          Hello <b>{AUTH_USER?.firstName}</b>
        </span>
        <div className="infoText">
          <div className="title">ASSIGNED DEPOT</div>
          <div className="value">{AUTH_USER?.depotName}</div>
          <div className="title">WORKING AS</div>
          <div className="value">{camelCaseTextFormatter(AUTH_USER?.roles)}</div>
        </div>
        <DropdownItem>
          <Link to="/logout" className="dropdown-item logout">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t("Logout")}</span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;
