import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import { useSelector } from "react-redux";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import { makeStyles } from "@material-ui/core/styles";
import { VALIDATION_RULES } from "pages/depot-management/teams/constants";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { createTeam } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import { uniq } from "lodash";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { USER_TENANT_NAME } from "utils/constants";
import { getTrimmedObjectValues } from "utils/helpers/functions";

const useStyles = makeStyles({
  modalContent: {
    display: "flex",
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

const NewTeamModal = ({ onClose, onUpdate }) => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);

  const [form] = Form.useForm();
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);
  const handleCancel = useCallback(() => onClose(), [onClose]);

  const handleSave = useCallback(() => {
    const { name, teamDepot } = getTrimmedObjectValues(form.getFieldsValue());
    if (name && teamDepot) {
      setLoader(true);
      createTeam({ name, teamDepot })
        .then(() => {
          successMessage("Team was created successfully");
          setLoader(false);
          onClose();
          onUpdate();
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoader(false);
        });
    }
  }, [onUpdate, form]);

  return (
    <Modal open={true} title="New Team" closable={false} onCancel={handleCancel} footer={[]}>
      <Spin spinning={isLoading}>
        <NGIForm name="newTeamForm" form={form} layout="inline" onFinish={handleSave} className={classes.formContainer}>
          <div className={classes.modalContent}>
            <Col span={12}>
              <Title level={5}>Team Name</Title>
              <NGIFormItem name="name" rules={VALIDATION_RULES}>
                <NGIInput />
              </NGIFormItem>
            </Col>
            <Col span={12}>
              <Title level={5}>Maintenance Depot</Title>
              <NGIFormItem name="teamDepot" rules={VALIDATION_RULES}>
                <NGISelect showSearch placeholder={"Please select depot"}>
                  {depot[USER_TENANT_NAME] &&
                    uniq(depot[USER_TENANT_NAME])
                      ?.sort()
                      ?.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
          </div>
          <Space>
            <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Create" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default NewTeamModal;
