import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import MetaData from "components/VerticalLayout/MetaData";
import { deleteUploadTemplate, retrieveDictionaries } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import CustomButton from "components/Common/buttons/CustomButton";
import CustomLink from "components/Common/buttons/CustomLink";
import { templateListColumns } from "pages/meter-management/upload-templates/templateListColumns";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const UploadTemplates = () => {
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);

  const hideRolesForDelBtn = [
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
    USER_ROLES.PRODUCT_TECHNICIANS,
    USER_ROLES.METER_ENGINEER,
  ];

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = useCallback(() => {
    setLoader(true);
    retrieveDictionaries("meterUploadTemplate")
      .then(res => {
        const { result } = res?.data;
        if (!result.items) {
          warnMessage(`No templates were found`);
        }
        setList(res?.data);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const handleRemoveTemplate = useCallback(() => {
    deleteUploadTemplate(activeTemplate)
      .then(() => {
        successMessage("Template was deleted successfully");
        closeConfirmationModal();
        loadTemplates();
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, [activeTemplate]);

  const openConfirmationModal = useCallback(templateName => {
    setIsConfirmationOpen(true);
    setActiveTemplate(templateName);
  }, []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const columns = useMemo(() => {
    const hasRole = userHasOneOfRoles(hideRolesForDelBtn);
    const col = templateListColumns(openConfirmationModal);
    if (hasRole) col.pop();
    return col;
  }, [openConfirmationModal]);

  return (
    <div className="page-content">
      <MetaData pageTitle={"Upload Templates"} />
      <Container fluid>
        <div className="filters-wrapper">
          <div className="filter-buttons">
            <CustomLink className="margin-right" to={"/meters/registration/template/new"}>
              <CustomButton
                hideRoles={[
                  USER_ROLES.INTERNAL_AUDITOR,
                  USER_ROLES.METER_ENGINEER,
                  USER_ROLES.PRODUCT_TECHNICIANS,
                  USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                ]}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="NEW TEMPLATE"
              />
            </CustomLink>
          </div>
        </div>
        <Col lg={12}>
          <NGITable isLoading={isLoading} columns={columns} data={list} />
        </Col>
      </Container>
      {isConfirmationOpen && (
        <ConfirmationModal
          description={"Are you sure to delete this template?"}
          onCancel={closeConfirmationModal}
          onConfirm={handleRemoveTemplate}
        />
      )}
    </div>
  );
};

export default UploadTemplates;
