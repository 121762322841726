import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { DatePicker } from "antd";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import moment from "moment";
import { FILTER_TYPES, STATUSES, TYPE_NAMES } from "pages/marketing/marketingInitiatives/variables";
import { getSelectedFilterProp } from "pages/marketing/marketingInitiatives/utils";

const { RangePicker } = DatePicker;

const { Option } = Select;

const MarketingInitiativesFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.MARKETING_INITIATIVES);
  const [filterType, setFilterType] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    form.setFieldsValue(filters);
    const selectedFilter = getSelectedFilterProp(filters);
    setFilterType(selectedFilter);
    if (filters["startAtFrom"]) {
      setDateRange([moment(filters["startAtFrom"]), moment(filters["startAtTo"])]);
    }
  }, []);

  const onTypeChange = useCallback(value => {
    if (!!value) setFilterType(value);
    else setFilterType(null);
  }, []);

  const onDateRangeChange = useCallback(value => setDateRange(value), []);

  const onSearch = useCallback(
    values => {
      const { pageSize } = getFilters();
      const normalizedData = { ...values };
      if (!!normalizedData[filterType?.value])
        normalizedData[filterType?.value] = normalizedData[filterType?.value]?.trim();
      if (!!dateRange) {
        normalizedData.startAtFrom = getStartOfTheDay(dateRange[0]);
        normalizedData.startAtTo = getEndOfTheDay(dateRange[1]);
      }
      handleFilter({ ...normalizedData, pageSize });
    },
    [dateRange, filterType]
  );

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <RangePicker inputReadOnly={true} value={dateRange} onChange={onDateRangeChange} />

        <div className="filter-divider" />
        <Select
          showSearch
          style={{ width: "100%" }}
          tokenSeparators={[","]}
          placeholder={"Select type"}
          allowClear
          value={filterType}
          onChange={onTypeChange}
        >
          {FILTER_TYPES?.map(item => (
            <Option key={item.value} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
        {!!filterType && (
          <Form.Item
            name={filterType}
            rules={[{ required: true, message: `Please select ${filterType.toLowerCase()}` }]}
          >
            {filterType === TYPE_NAMES.STATUS ? (
              <Select
                showSearch
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                placeholder={"Select status"}
                allowClear
              >
                {STATUSES.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            ) : (
              <Input placeholder="Enter value" allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            )}
          </Form.Item>
        )}
        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default MarketingInitiativesFilter;
