import React, { useCallback, useState } from "react";
import { Modal, Space } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { textFormatter } from "components/Common/formatter";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import classnames from "classnames";
import GeoDetails from "pages/depot-management/depotModal/Tabs/GeoDetails";
import { extractAddress, extractMarkerCoords, isMarkerInsidePolygon } from "utils/helpers/mapHelpers";

const useStyles = makeStyles({
  modal: {
    "& .ant-modal-content .ant-modal-header": {
      padding: "25px 30px 0 !important",
    },
    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 30px !important",
    },
    "& .ant-modal-title": {
      borderBottom: "1px solid #00000014",
      paddingBottom: 15,
    },
  },
  tableContainer: {
    marginBottom: 20,
  },
  tableHeader: {
    display: "flex",
    fontWeight: 600,

    "& div": {
      width: "33%",
      padding: "0 0 10px 10px",
      borderBottom: "1px solid #00000014",
    },

    "& div:nth-child(2)": {
      borderLeft: "1px solid #00000014",
      borderRight: "1px solid #00000014",
    },
  },
  tableData: {
    display: "flex",
    fontWeight: 400,

    "& >div": {
      width: "33%",
      padding: 10,
      borderBottom: "1px solid #00000014",
    },

    "& div:nth-child(2)": {
      borderLeft: "1px solid #00000014",
      borderRight: "1px solid #00000014",
    },
  },
  fullWidth: {
    width: "50%!important",
  },
});

const ChangeAddressModel = ({ onClose, onSave, customer, classes, polygonList, isLoaded }) => {
  const modalClasses = useStyles();
  const [marker, setMarker] = useState(null);
  const {
    depot,
    location: { district, houseNumber, lat, lon, region, street },
  } = customer;
  const [newAddress, setNewAddress] = useState({ district, houseNumber, lat, lon, region, street, depot });

  // eslint-disable-next-line no-undef
  const geocoder = new google.maps.Geocoder();

  const handleSave = useCallback(() => {
    onClose();
    onSave(newAddress);
  }, [newAddress]);

  const onMarkerComplete = useCallback(
    data =>
      setMarker(prevMarker => {
        prevMarker?.setMap(null);
        let insideDepot;
        polygonList.some(item => {
          const isInside = isMarkerInsidePolygon(data, item.poly);
          if (isInside) {
            insideDepot = item;
          }
          return isInside;
        });

        const markerCoords = extractMarkerCoords(data);
        geocoder
          .geocode({
            location: {
              lat: markerCoords[0],
              lng: markerCoords[1],
            },
          })
          .then(response => {
            if (response?.results?.length) {
              const { newStreet, newHouseNumber } = extractAddress(response.results);
              setNewAddress({
                street: newStreet,
                houseNumber: newHouseNumber,
                district: insideDepot?.district || "",
                region: insideDepot?.region || "",
                lat: markerCoords[0],
                lon: markerCoords[1],
                depot: insideDepot?.id || "",
              });
            }
          });
        return data;
      }),
    [polygonList]
  );

  return (
    <Modal
      open={true}
      title="Change Customer Address"
      className={modalClasses.modal}
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <>
        <div>
          <GeoDetails
            allowSearch
            allowPolygon={false}
            center={
              marker || {
                lat,
                lng: lon,
              }
            }
            initialData={{
              marker: { lat, lng: lon },
            }}
            allowOpenDetails={false}
            isTouched={marker}
            polygonList={polygonList}
            onMarkerComplete={onMarkerComplete}
            isLoaded={isLoaded}
          />
        </div>
        <TitleWithDivider title="Customer Location" />
        <div className={classes.columnsContainer}>
          <div className={classnames(modalClasses.fullWidth, classes.column)}>
            <div className={classes.rowData}>
              <div className={classes.title}>Location</div>
              <div className={classes.data}>
                {newAddress.region ? `${newAddress.region}, ${newAddress.district ?? "-"}` : textFormatter("")}
              </div>
            </div>
            <div className={classes.rowData}>
              <div className={classes.title}>Address</div>
              <div className={classes.data}>
                {newAddress.street ? `${newAddress.street} ${newAddress.houseNumber ?? "-"}` : textFormatter("")}
              </div>
            </div>
          </div>
          <div className={classnames(modalClasses.fullWidth, classes.column)}>
            <div className={classes.rowData}>
              <div className={classes.title}>Latitude / Longitude</div>
              <div className={classes.data}>
                {newAddress.lat ? `${newAddress.lat} / ${newAddress.lon}` : textFormatter("")}
              </div>
            </div>
            <div className={classes.rowData}>
              <div className={classes.title}>Depot Geofence</div>
              <div className={classes.data}>{textFormatter(newAddress.depot)}</div>
            </div>
          </div>
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Close" />
          <CustomButton onClick={handleSave} disabled={!newAddress?.lat} type="primary" size="small" text="Save" />
        </Space>
      </>
    </Modal>
  );
};

export default ChangeAddressModel;
