import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 15,
    padding: "16px 0",
    fontWeight: 600,
    position: "relative",

    "&>span": {
      backgroundColor: "#FFF",
      zIndex: 1,
      position: "relative",
      paddingRight: 10,
    },

    "&:after": {
      content: "''",
      display: "block",
      width: "100%",
      height: 1,
      background: "rgba(0, 0, 0, 0.05)",
      top: "50%",
      position: "absolute",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFF",
    zIndex: 1,
    position: "relative",
    paddingLeft: 10,
  },
});

const TitleWithDivider = ({ title, button, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} {...props}>
      <span>{title}</span>
      {button && <div className={classes.buttonContainer}>{button}</div>}
    </div>
  );
};

export default TitleWithDivider;
