import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_TENANT_NAME } from "utils/constants";
import { isEmpty, uniq } from "lodash";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { validFilters } from "pages/depot-management/teams/constants";

const { Option } = Select;

const searchOptions = [
  {
    value: "teamMember",
    title: "Member",
  },
  {
    value: "teamDepot",
    title: "Depot",
  },
];

const TeamsSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const { getFilters, filters } = useStoreFilters(FILTER_PAGE.TEAMS);
  const [selectedFilter, setSelectedFilter] = useState("");
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const onChangeFilter = useCallback(
    value => {
      setSelectedFilter(value);
      form.setFieldValue("value", null);
    },
    [form]
  );

  useEffect(() => {
    if (!isEmpty(filters)) {
      const filterKey = Object.keys(filters)[0];
      const type = validFilters.includes(filterKey) ? filterKey : null;
      form.setFieldsValue({
        type,
        value: filters[filterKey],
      });

      setSelectedFilter(type);
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    !(values.value && !values.type) &&
      handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select showSearch onChange={onChangeFilter} placeholder="Select type" allowClear>
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        {selectedFilter !== "teamDepot" && (
          <Form.Item name="value">
            <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
          </Form.Item>
        )}
        {selectedFilter === "teamDepot" && (
          <Form.Item name="value">
            <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
              {depot[USER_TENANT_NAME] &&
                uniq(depot[USER_TENANT_NAME])
                  ?.sort()
                  ?.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        )}
        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default TeamsSearch;
