import { NUMBER_PATTERN } from "pages/meter-management/configuration/meters/variables";
import { NATIONAL_ID_LENGTH_MAX, NATIONAL_ID_LENGTH_MIN } from "utils/constants";

export const TABS = {
  PERSONAL_INFORMATION: "PERSONAL_INFORMATION",
  DEMOGRAPHIC_QUESTIONS: "DEMOGRAPHIC_QUESTIONS",
};

export const TABS_BUTTONS = [
  {
    title: "Personal Information",
    key: TABS.PERSONAL_INFORMATION,
  },
  {
    title: "Demographic Questions",
    key: TABS.DEMOGRAPHIC_QUESTIONS,
  },
];

export const APPROVE_DECLINE_STATUSES = {
  DECLINE: "decline",
  APPROVE: "approve",
};

export const editableFieldsMap = [
  {
    title: "Personal Information",
    fields: [
      {
        firstName: "First Name",
        required: true,
      },
      {
        lastName: "Last Name",
        required: true,
      },
      {
        middleName: "Middle Name",
      },
      {
        gender: "Gender",
        required: true,
      },
      {
        dob: "Date of Birth",
        required: true,
      },
      {
        occupation: "Occupation",
        required: true,
      },
      {
        familySize: "Family Size",
        required: true,
        rules: [
          { pattern: NUMBER_PATTERN, message: "Should be a number" },
          {
            pattern: /^[1-9]$|^0[1-9]$|^1[0-9]$|^20$/,
            message: "Value should be between 1 and 20",
          },
        ],
      },
      {
        type: "Lead Category",
        required: true,
      },
      {
        packageCode: "Package",
        required: true,
      },
    ],
  },
  {
    title: "Address and Contacts",
    fields: [
      {
        street: "Street Name",
        required: true,
      },
      {
        houseNumber: "House Number",
        required: true,
      },
      {
        housePhotoFileName: "House Photo",
        required: true,
      },
      {
        secondaryPhone: "Secondary Phone #1",
        required: true,
        rules: [
          {
            message: "Secondary Phone #1 should be in the correct format",
            validator: function (_, value) {
              const hasZero = value[3] === "0";
              if (hasZero && value.match(/^(254|255)\d{10}$/)) {
                return Promise.resolve();
              } else if (!hasZero && value.match(/^(254|255)\d{9}$/)) {
                return Promise.resolve();
              }
              return Promise.reject("Secondary Phone #1 should be in the correct format");
            },
          },
        ],
      },
      {
        closeRelativePhone: "Secondary Phone #2",
        rules: [
          {
            message: "Secondary Phone #2 should be in the correct format",
            validator: function (_, value) {
              if (!value) {
                return Promise.resolve();
              }
              const hasZero = value[3] === "0";
              if (hasZero && value.match(/^(254|255)\d{10}$/)) {
                return Promise.resolve();
              } else if (!hasZero && value.match(/^(254|255)\d{9}$/)) {
                return Promise.resolve();
              }
              return Promise.reject("Secondary Phone #2 should be in the correct format");
            },
          },
        ],
      },
    ],
  },
  {
    title: "Customer Identity",
    fields: [
      {
        idType: "ID Type",
        required: true,
      },
      {
        idNumber: "ID Number",
        required: true,
        rules: [
          formInstance => ({
            validator(rule, value) {
              const idType = formInstance.getFieldValue("idType");
              const regExp = new RegExp(`^\\d{${NATIONAL_ID_LENGTH_MIN},${NATIONAL_ID_LENGTH_MAX}}$`);
              if (idType === "National ID" && !value.match(regExp)) {
                return Promise.reject(
                  `ID Number should have from ${NATIONAL_ID_LENGTH_MIN} to ${NATIONAL_ID_LENGTH_MAX} characters`
                );
              } else if (value.length < 1) {
                return Promise.reject(`ID Number should have min 1 character`);
              }
              return Promise.resolve();
            },
          }),
        ],
      },
      {
        idPhotoFileName: "ID Photo",
        required: true,
      },
      {
        personalPhotoFileName: "Personal Photo",
        required: false,
      },
    ],
  },
];
