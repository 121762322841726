import React, { useState } from "react";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

export const MySearch = props => {
  const [search, setSearch] = useState("");

  const handleClick = val => {
    setSearch(val);

    props.searchHandler(val);
  };

  return (
    <div>
      <input
        type="search"
        className="form-control"
        placeholder={props.name}
        value={search}
        onChange={e => handleClick(e.target.value)}
        pattern={inputTextPattern}
        title={inputPatternTitle}
      />
    </div>
  );
};
