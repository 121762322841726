import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import NGISpinner from "components/Common/NGISpinner";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .ant-table-selection-column": {},
    "& .ant-table-thead > tr > th": {
      backgroundColor: "transparent",
      border: "none",
    },
    "& .ant-table-thead > tr > th.ant-table-cell": {
      color: "rgba(140, 140, 140, 1)",
    },
    "& .ant-table-cell": {
      fontSize: 13,
      border: "none",
      padding: "5px 0",

      "&:before": {
        backgroundColor: "transparent!important",
      },
    },
    "& .ant-table.ant-table-bordered": {},
    "& .ant-table.ant-table-bordered > .ant-table-container": {},
    "& .ant-table-content > table": {},
    "& .ant-table": {},
    "& .ant-table-tbody .ant-table-row:nth-child(even)": {},

    "& .ant-pagination-prev > .ant-pagination-item-link": {},

    "& .ant-pagination-next > .ant-pagination-item-link": {},

    "& .ant-pagination-item": {},
    "& .ant-pagination-disabled": {},
    "& .ant-pagination-item-active": {
      "&:hover a": {},
    },

    "& .ant-table-pagination.ant-pagination": {},

    "& .ant-select > .ant-select-selector": {},
    "& .ant-select:not(.ant-select-disabled):hover .ant-select-selector": {},
  },
  container: {
    width: "50%",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    padding: 10,
    boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.08)",
  },
  withHeader: {
    padding: "15px 20px",
    borderRadius: 15,
    backgroundColor: "white",
    marginBottom: 20,
  },
  showMoreContainer: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  showMore: {
    cursor: "pointer",
    padding: "5px 15px",
    border: "1px solid",
    marginTop: 5,

    "&:hover": {
      color: "#0085FF !important",
    },
  },
  smallMargin: {
    width: "49%",
  },
  fullscreen: {
    width: "100%",
  },
  cardBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

const hidePaginationConfig = {
  position: ["none"],
};

const NGICollapsibleTable = ({
  columns,
  headerContent,
  data,
  lineCount = 5,
  initialExpanded = false,
  isLoading = false,
  size = "large",
  ...props
}) => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [isExpanded, setIsExpanded] = useState(initialExpanded);
  const [isFullScreen, setIsFullScreen] = useState(size !== "small");

  useEffect(() => {
    setItems(data);
  }, [data]);

  const onCollapseExpand = useCallback(
    () =>
      setIsExpanded(prevState => {
        if (size === "small") {
          setIsFullScreen(!prevState);
        }
        return !prevState;
      }),
    []
  );

  return (
    <>
      {items?.length ? (
        <div
          className={classnames(
            classes.container,
            headerContent && classes.withHeader,
            size === "small" && classes.smallMargin,
            isFullScreen && classes.fullscreen
          )}
        >
          {headerContent}
          <Table
            {...props}
            loading={isLoading}
            dataSource={items}
            columns={columns}
            className={classnames(classes.root)}
            pagination={{ ...hidePaginationConfig, pageSize: isExpanded ? data.length : lineCount }}
          />
          {data.length >= lineCount && (
            <div className={classes.showMoreContainer}>
              <div className={classes.showMore} onClick={onCollapseExpand}>{`Show ${
                isExpanded ? "less" : "more"
              }`}</div>
            </div>
          )}
        </div>
      ) : (
        <>
          {!data.length && !isLoading ? (
            <Card
              className={classnames(
                classes.container,
                headerContent && classes.withHeader,
                size === "small" && classes.smallMargin,
                isFullScreen && classes.fullscreen
              )}
            >
              {headerContent}
              <CardBody className={classes.cardBody}>
                <i className="bx bx-warning" />
                <h5>No data is available</h5>
                <p className="text-muted">Please make a different filter selection</p>
              </CardBody>
            </Card>
          ) : (
            <Card
              className={classnames(
                classes.container,
                size === "small" && classes.smallMargin,
                isFullScreen && classes.fullscreen
              )}
            >
              <NGISpinner hideContainer fullscreen />
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default NGICollapsibleTable;
