import React, { useState, useEffect } from "react";
import { Col, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import CustomButton from "components/Common/buttons/CustomButton";
import NGITable from "components/Common/NGITable";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { retrieveMeterReports } from "utils/api";
import BoxFilter from "pages/meter-management/meterBox/BoxFilter";
import { boxColumns } from "pages/meter-management/meterBox/boxColumns";
import MeterBoxModal from "pages/meter-management/meterBox/MeterBoxModal";

const MeterBox = () => {
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setFilters } = useStoreFilters(FILTER_PAGE.METER_BOX);

  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const handleOnFilter = (filterOptions = {}) => {
    setLoading(true);
    setFilters(filterOptions);
    retrieveMeterReports(filterOptions)
      .then(res => {
        setReports(res?.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="filters-wrapper">
          <div className="filter-buttons">
            <CustomButton
              type="primary"
              hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
              text="Box and Send"
              icon={<i className="bx bx-package" />}
              onClick={openModal}
            />
          </div>
          <BoxFilter handleFilter={handleOnFilter} setFilters={setFilters} />
        </div>
        <Col lg={12}>
          <NGITable
            filtersType={FILTER_PAGE.METER_BOX}
            updateList={handleOnFilter}
            isLoading={loading}
            columns={boxColumns()}
            data={reports}
          />
        </Col>
      </Container>
      {isModalVisible && <MeterBoxModal onClose={closeModal} isVisible={isModalVisible} updateData={handleOnFilter} />}
    </div>
  );
};

export default MeterBox;
