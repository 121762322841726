import { TYPES } from "pages/TAM/TAMRequests/constants";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";

const notValidObj = { valid: false };
export const getValidPreparedData = (values, type, customer, transaction, files) => {
  const { customerId, transactionMode, transactionId, amount, dateFrom, dateTo, correctMode, comment } = values;
  switch (type) {
    case TYPES.INSTALLATION_PAYMENT_PROCESSING_FAILURE:
      if (
        customerId &&
        customer &&
        (customer?.status === "Active" || customer?.status === "Qualified") &&
        transactionId &&
        transaction &&
        correctMode &&
        transaction?.transactionMode === "Installation Payment Type"
      ) {
        if (transaction?.referenceNumber[0] && transaction?.referenceNumber[0]?.toUpperCase() !== "R") {
          return {
            ...notValidObj,
            paymentType: "Incorrect RECEIPT ID is used. Payment transactions with R prefix should be used.",
          };
        }
        if (customer.id === transaction.customerId) {
          return {
            ...notValidObj,
            paymentType: "Customer ID and customer ID in the receipt should not be the same.",
          };
        }
        return {
          valid: true,
          data: {
            customerId,
            description: comment,
            reason: type,
            transactionMode: correctMode,
            transactionId,
            amount: parseFloat(transaction.transactionAmount),
          },
        };
      }

      return {
        ...notValidObj,
        paymentType:
          transaction?.transactionMode !== "Installation Payment Type"
            ? "Transaction type from current 'Receipt ID' is different from Payment type."
            : "",
        customer:
          customer?.status !== "Active" && customer?.status !== "Qualified"
            ? "Customer should be in 'Active' or 'Qualified' status."
            : "",
      };

    case TYPES.STANDARD_PAYMENT_TRANSACTION_PROCESSING_FAILURE:
      if (
        customerId &&
        customer &&
        (customer?.status === "Active" || customer?.status === "Qualified") &&
        transactionId &&
        transaction &&
        dateFrom &&
        dateTo
      ) {
        if (
          !(getStartOfTheDay(moment(dateFrom)) < transaction?.transactionDateTime) ||
          !(getEndOfTheDay(moment(dateTo)) > transaction?.transactionDateTime)
        ) {
          return {
            ...notValidObj,
            date: "The date range should be within which the payment failed.",
          };
        }
        if (customer.id !== transaction.customerId) {
          return {
            ...notValidObj,
            paymentType: "Customer ID and customer ID in the receipt should be the same.",
          };
        }
        if (transaction.transactionStatus !== "UNCLEARED" || transaction.transactionStatus !== "VERIFIED") {
          return {
            ...notValidObj,
            paymentType: "This payment has already been cleared, select the correct transaction.",
          };
        }
        return {
          valid: true,
          data: {
            customerId,
            description: comment,
            reason: type,
            dateFrom: getStartOfTheDay(moment(dateFrom)),
            dateTo: getEndOfTheDay(moment(dateTo)),
            transactionId,
            amount: parseFloat(transaction.transactionAmount),
          },
        };
      }
      return {
        ...notValidObj,
        customer:
          customer?.status !== "Active" && customer?.status !== "Qualified"
            ? "Customer should be in 'Active' or 'Qualified' status."
            : "",
      };

    case TYPES.REVERSAL_CREDIT_ERROR:
      if (
        customerId &&
        customer &&
        (customer?.status === "Active" || customer?.status === "Qualified") &&
        transactionId &&
        transaction
      ) {
        if (parseFloat(amount) > 0) {
          return {
            ...notValidObj,
            amount: "Only negative values are possible.",
          };
        }
        if (-parseFloat(amount) > parseFloat(transaction.gasAmount)) {
          return {
            ...notValidObj,
            amount: `Entered amount should be less or equal to gas amount ${transaction.gasAmount}`,
          };
        }
        if (customer.id !== transaction.customerId) {
          return {
            ...notValidObj,
            paymentType: "Customer ID and customer ID in the receipt should be the same.",
          };
        }
        return {
          valid: true,
          data: {
            customerId,
            transactionId,
            description: comment,
            reason: type,
            amount: parseFloat(amount),
          },
        };
      }
      return {
        ...notValidObj,
        customer:
          customer?.status !== "Active" && customer?.status !== "Qualified"
            ? "Customer should be in 'Active' or 'Qualified' status."
            : "",
      };

    case TYPES.METER_MALFUNCTION:
      if (
        customerId &&
        customer &&
        (customer?.status === "Active" || customer?.status === "Qualified") &&
        (parseFloat(amount) || amount === "0")
      ) {
        if (files?.length === 0) {
          return {
            ...notValidObj,
            file: "At least 1 is mandatory",
          };
        }
        return {
          valid: true,
          data: {
            customerId,
            description: comment,
            reason: type,
            amount: parseFloat(amount),
          },
        };
      }
      return {
        ...notValidObj,
        customer:
          customer?.status !== "Active" && customer?.status !== "Qualified"
            ? "Customer should be in 'Active' or 'Qualified' status."
            : "",
      };

    case TYPES.PROMOTION_AND_BONUS:
      if (
        customerId &&
        customer &&
        (customer?.status === "Active" || customer?.status === "Qualified") &&
        parseFloat(amount)
      ) {
        return {
          valid: true,
          data: {
            customerId,
            description: comment,
            reason: type,
            amount: parseFloat(amount),
          },
        };
      }
      return {
        ...notValidObj,
        customer:
          customer?.status !== "Active" && customer?.status !== "Qualified"
            ? "Customer should be in 'Active' or 'Qualified' status."
            : "",
      };

    case TYPES.CUSTOMER_LEDGER_ADJUSTMENT:
      if (
        customerId &&
        customer &&
        (customer?.status === "Active" || customer?.status === "Qualified") &&
        transactionId &&
        transaction &&
        transactionMode
      ) {
        if (transactionMode === "Stove Balance Payment Type" && isNaN(transaction.referenceNumber[0])) {
          return {
            ...notValidObj,
            paymentType: "Incorrect RECEIPT ID is used. Payment transactions without prefix should be used.",
          };
        }
        if (
          transactionMode === "Standard Payment Type" &&
          transaction.referenceNumber[0] &&
          transaction.referenceNumber[0]?.toUpperCase() !== "M"
        ) {
          return {
            ...notValidObj,
            paymentType: "Incorrect RECEIPT ID is used. Payment transactions with M prefix should be used.",
          };
        }
        if (customer.id !== transaction.customerId) {
          return {
            ...notValidObj,
            paymentType: "Customer ID and customer ID in the receipt should be the same.",
          };
        }
        return {
          valid: true,
          data: {
            customerId,
            description: comment,
            reason: type,
            transactionId,
            transactionMode,
            amount: parseFloat(transaction.transactionAmount),
          },
        };
      }
      return {
        ...notValidObj,
        customer:
          customer?.status !== "Active" && customer?.status !== "Qualified"
            ? "Customer should be in 'Active' or 'Qualified' status."
            : "",
      };

    case TYPES.FULL_REVERSAL:
    case TYPES.CREDIT_TRANSFER:
      if (
        customerId &&
        customer &&
        (customer?.status === "Active" || customer?.status === "Qualified") &&
        transactionId &&
        transaction
      ) {
        if (customer.id === transaction.customerId) {
          return {
            ...notValidObj,
            paymentType: "Customer ID and customer ID in the receipt should not be the same.",
          };
        }
        return {
          valid: true,
          data: {
            customerId,
            description: comment,
            reason: type,
            transactionId,
            amount: parseFloat(transaction.transactionAmount),
          },
        };
      }
      return {
        ...notValidObj,
        customer:
          customer?.status !== "Active" && customer?.status !== "Qualified"
            ? "Customer should be in 'Active' or 'Qualified' status."
            : "",
      };

    case TYPES.REFUND_TO_CUSTOMERS:
      return notValidObj;
    default:
      return notValidObj;
  }
};

export const getNormalizedCSVData = data => data.split("\n").map(item => item.split(","));

export const getAmountFiled = (item, data) => {
  switch (item.reason) {
    case TYPES.INSTALLATION_PAYMENT_PROCESSING_FAILURE:
    case TYPES.STANDARD_PAYMENT_TRANSACTION_PROCESSING_FAILURE:
    case TYPES.CUSTOMER_LEDGER_ADJUSTMENT:
    case TYPES.CREDIT_TRANSFER:
      return data?.transactionAmount;
    case TYPES.REVERSAL_CREDIT_ERROR:
    case TYPES.METER_MALFUNCTION:
    case TYPES.PROMOTION_AND_BONUS:
      return item?.amount;
    default:
      return data?.transactionAmount;
  }
};
