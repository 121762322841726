import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Header from "pages/operations/prospectInfo/Header";
import DataTabs from "pages/operations/prospectInfo/DataTabs";
import { fetchCustomerById, fetchDepotsList } from "utils/api";
import { useParams } from "react-router";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { randomColor } from "utils/helpers/functions";
import NotFoundContainer from "components/Common/NotFoundContainer";
import { Spin } from "antd";

const ProspectInfo = () => {
  const { id } = useParams();
  const [prospect, setProspect] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [allDepotsList, setAllDepotsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchDepotsList({
      pageSize: 1000,
    })
      .then(data => {
        const list = data?.data?.result?.items?.map(item => ({
          id: item.name,
          district: item.location.subCounty,
          region: item.location.county,
          color: randomColor(),
          poly: item.boundaries.coordinates[0].map(item => [item[1], item[0]]),
        }));
        setIsLoading(false);
        setAllDepotsList(list || []);
      })
      .catch(e => {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      });

    fetchCustomerById(id)
      .then(data => {
        setIsLoading(false);
        setProspect(data?.data?.result);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setIsLoading(false);
        if (e.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              <Header prospect={prospect} />
              <DataTabs
                prospect={prospect}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                allDepots={allDepotsList}
              />
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default ProspectInfo;
