import {call, put, takeEvery} from "redux-saga/effects";
import {ASSIGN_TASKS, GET_TASK_CATEGORY, GET_TASK_STATUS, CANCEL_TASK} from "./actionTypes";
import {
  cancelTaskSuccess,
  cancelTaskFailed,
  assignTasksFailed,
  assignTasksSuccess,
  getTaskCategoryFailed,
  getTaskCategorySuccess,
  getTaskStatusFailed,
  getTaskStatusSuccess,
} from "./actions";
import {assignBulkTaskApi, retrieveDictionaries, cancelTaskApi} from "utils/api";

function* putTaskCategory() {
  try {
    const response = yield call(retrieveDictionaries, "taskCategory");
    yield put(getTaskCategorySuccess(response.data.result.items));
  } catch (error) {
    yield put(getTaskCategoryFailed(error));
  }
}
function* putTaskStatus() {
  try {
    const response = yield call(retrieveDictionaries, "taskStatus");
    yield put(getTaskStatusSuccess(response.data.result.items));
  } catch (error) {
    yield put(getTaskStatusFailed(error));
  }
}

function* putBulkTasks() {
  try {
    const response = yield call(assignBulkTaskApi);
    yield put(assignTasksSuccess(response.data.result.items));
  } catch (error) {
    yield put(assignTasksFailed(error));
  }
}

function* putCancelTask(data) {
  try {
    const response = yield call(cancelTaskApi, data.payload);
    if (response.status === 204) yield put(cancelTaskSuccess({result: "Task Cancelled Successful"}));
  } catch (error) {
    yield put(cancelTaskFailed(error));
  }
}

function* tasksSaga() {
  yield takeEvery(ASSIGN_TASKS, putBulkTasks);
  yield takeEvery(GET_TASK_CATEGORY, putTaskCategory);
  yield takeEvery(GET_TASK_STATUS, putTaskStatus);
  yield takeEvery(ASSIGN_TASKS, putBulkTasks);
  yield takeEvery(CANCEL_TASK, putCancelTask);
}

export default tasksSaga;
