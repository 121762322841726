import {
  textFormatter,
  priorityFormatter,
  uppercaseTextFormatter,
  customDateFormatWithTime,
} from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

const color = "#444444";
export const taskColumns = [
  {
    title: "Type",
    dataIndex: "",
    key: "category",
    sort: true,
    render: row => (
      <Tooltip placement="bottom" title={`${row.category} task details`} color={color}>
        <div>
          <CustomLink to={`/operations/task/${row.id}?backUrl=/operations/tasks/archive`}>
            {uppercaseTextFormatter(row.category)}
          </CustomLink>
        </div>
      </Tooltip>
    ),
    width: "20%",
  },
  {
    dataIndex: "id",
    key: "id",
    title: "CUSTOMER ID",
    render: (_, task) =>
      task?.customer?.id ? (
        <CustomLink underlined target="_blank" to={`/customers/customer/${task?.customer?.id}`}>
          {task?.customer?.id}
        </CustomLink>
      ) : (
        textFormatter(task?.customer?.id)
      ),
  },
  {
    dataIndex: "customerName",
    key: "customerName",
    title: "Customer Name",
    render: (_, task) => priorityFormatter(task?.customer?.name),
  },
  {
    dataIndex: "priority",
    key: "priority",
    title: "Priority",
    render: textFormatter,
  },
  {
    dataIndex: "assignedTo",
    key: "assignedTo",
    title: "ASSIGNED TO",
    render: (cellContent, task) => (
      <CustomLink underlined target="_blank" to={`/user/${task.assignedTo}`}>
        {textFormatter(task.assignedTo)}
      </CustomLink>
    ),
  },
  {
    dataIndex: "updatedAt",
    key: "updatedAt",
    title: "Last Updated Date",
    render: (cellContent, task) => textFormatter(customDateFormatWithTime(task.updatedAt)),
  },
];
