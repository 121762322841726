import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import tasksIcon from "../../../../assets/images/svg/tasks.svg";
import { USER_ROLES } from "utils/constants";
import SendCommandsModal from "pages/meter-management/meters/meterDetails/SendCommandsModal";
import UpdateFirmwareModal from "pages/meter-management/meters/UpdateFirmwareModal";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import mailIcon from "assets/images/svg/mail.svg";
import updateIcon from "assets/images/svg/firmware-update.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  button: {
    marginLeft: 10,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginRight: 20,
    marginLeft: 20,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  inactive: {
    backgroundColor: "#c31d17",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    marginRight: 20,
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ data, onUpdate }) => {
  const classes = useStyles();
  const [isSendCommandOpen, setIsSendCommandOpen] = useState(false);
  const [isUpdateFirmwareOpen, setIsUpdateFirmwareOpen] = useState(false);

  const openSendCommandModal = useCallback(() => setIsSendCommandOpen(true), []);
  const closeSendCommandModal = useCallback(() => setIsSendCommandOpen(false), []);

  const openUpdateFirmwareModal = useCallback(() => setIsUpdateFirmwareOpen(true), []);
  const closeUpdateFirmwareModal = useCallback(() => setIsUpdateFirmwareOpen(false), []);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/meters/meters"}>Back</BackButtonLink>
        <div className={classes.nameContainer}>
          <span>{data?.barcode}</span>
          <div className={classnames(classes.status, data?.status === "Inactive" && classes.inactive)}>
            {data?.status}
          </div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <img src={tasksIcon} alt="task count" />
            <div>
              <div className={classes.title}>METER CONDITION</div>
              <div className={classes.data}>{data?.condition}</div>
            </div>
          </div>
        </div>
        <div className="filter-buttons">
          <ButtonGroupContainer color={"primary"}>
            <ButtonGroup
              hideRoles={[
                USER_ROLES.INTERNAL_AUDITOR,
                USER_ROLES.GENERAL,
                USER_ROLES.FINANCE,
                USER_ROLES.PRODUCT_TECHNICIANS,
                USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                USER_ROLES.IT_OPERATIONS,
                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              ]}
              color={"primary"}
              handleClick={openSendCommandModal}
              tooltipTitle="Send Commands"
              icon={mailIcon}
            />
            <ButtonGroup
              roles={[
                USER_ROLES.METER_ENGINEER_LEADERSHIP,
                USER_ROLES.METER_ENGINEER,
                USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              ]}
              color={"primary"}
              handleClick={openUpdateFirmwareModal}
              tooltipTitle="Update Firmware"
              icon={updateIcon}
            />
          </ButtonGroupContainer>
        </div>
      </div>
      {isUpdateFirmwareOpen && (
        <UpdateFirmwareModal
          onUpdate={onUpdate}
          isOpen={isUpdateFirmwareOpen}
          activeItem={data}
          onClose={closeUpdateFirmwareModal}
        />
      )}
      {isSendCommandOpen && (
        <SendCommandsModal
          onClose={closeSendCommandModal}
          isPX={data.type === "PX-MQTT"}
          meterType={data.type}
          meterId={data.id}
        />
      )}
    </div>
  );
};

export default Header;
