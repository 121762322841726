import React, { useCallback, useEffect, useState } from "react";
import { Col, Container } from "reactstrap";
import MetaData from "components/VerticalLayout/MetaData";
import FirmwareUpdateProcessesFilter from "pages/meter-management/firmware/firmwareUpdateProcesses/FirmwareUpdateProcessesFilter";
import { getFirmwareUpdateProcesses } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { FILTER_PAGE } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { firmwareUpdateProcessesColumns } from "pages/meter-management/firmware/firmwareUpdateProcesses/firmwareUpdateProcessesColumns";
import { useDispatch } from "react-redux";
import { getFirmwares } from "store/meter-management/configuration/firmware/actions";

const FirmwareUpdateProcesses = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.FIRMWARE_UPDATE_PROCESSES);
  const [list, setList] = useState({});
  useEffect(() => {
    dispatch(getFirmwares());
  }, []);

  const handleFilter = useCallback((newFilterOptions = filters) => {
    setLoader(true);
    setFilters(newFilterOptions);
    getFirmwareUpdateProcesses({
      ...newFilterOptions,
    })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items.length === 0) {
          warnMessage("No items were found");
          setLoader(false);
        }
        setList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setList({});
        defaultNgiErrorMethod(err);
      });
  }, []);

  return (
    <>
      <div className="page-content" data-testid="metersComponent">
        <MetaData pageTitle={"Firmware Update Processes"} />
        <Container fluid>
          <div className="filters-wrapper">
            <FirmwareUpdateProcessesFilter handleFilter={handleFilter} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.FIRMWARE_UPDATE_PROCESSES}
              isLoading={isLoading}
              columns={firmwareUpdateProcessesColumns}
              data={list}
              updateList={handleFilter}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default FirmwareUpdateProcesses;
