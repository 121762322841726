import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Space, Spin, Typography } from "antd";

import { useDispatch } from "react-redux";
import { cancelTask } from "store/actions";
import CustomButton from "components/Common/buttons/CustomButton";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { retrieveAllTasks } from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";

const { Title } = Typography;
const CancelTaskModal = ({ onClose, lead, setLead }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { firstName, lastName, id } = lead;
  const [loading, setLoading] = useState(false);

  const allowedStatuses = ["Incompletable", "Pending", "Assigned"];

  const onSave = useCallback(values => {
    setLoading(true);
    retrieveAllTasks({
      sortBy: "createdAt",
      category: "New Installation",
      customerId: id,
    })
      .then(({ data }) => {
        const task = data.result?.items?.length && data.result?.items[0];
        if (!allowedStatuses.includes(task.status)) {
          errorMessage("The lead is not Declined. The task is not Cancelled. Please check the task status.");
          setLoading(false);
          onClose();
          return;
        }
        const payload = { id: task?.id, reason: "Not Relevant", ...values };
        dispatch(cancelTask(payload));
        setLoading(false);
        setLead(prevState => ({ ...prevState, status: "Declined" }));
        onClose();
        successMessage("Tasks were cancelled successfully");
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  return (
    <Modal title={"Confirmation"} open={true} onCancel={onClose} closable={false} footer={[]}>
      <>
        <Spin spinning={loading}>
          <p>{`If you cancel installation task for ${firstName} ${lastName}, this lead will be declined as well. Do you want to proceed?`}</p>

          <Form form={form} onFinish={onSave} data-testid={"CancelTaskFormId"}>
            <Col>
              <Title data-testid={"commentTitleId"} level={5}>
                Comments
              </Title>
              <Form.Item name="comments" rules={[{ required: true, message: "Please enter comments" }]}>
                <NGITextArea rows={4} data-testid={"commentValueId"} placeholder="Enter comments" />
              </Form.Item>
            </Col>
            <Col>
              <Space>
                <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="No" />
                <CustomButton htmlType="submit" type="primary" size="small" text="Yes" />
              </Space>
            </Col>
          </Form>
        </Spin>
      </>
    </Modal>
  );
};

export default CancelTaskModal;
