import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import contractVersionPreview from "assets/images/svg/contractVersionPreview.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import { useLocation } from "react-router";
import { booleanStatusFormatter, customDateFormatWithoutTime } from "components/Common/formatter";
import calendarIcon from "assets/images/svg/calendarMinus.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
      maxWidth: 200,
    },
  },
  iconContainer: {
    padding: 8,
    marginLeft: 23,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 65,
    minWidth: 65,
    height: 65,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginRight: 20,
  },
});

const Header = ({ contractVersion }) => {
  const classes = useStyles();

  const searchParams = useLocation().search;
  const backUrl = new URLSearchParams(searchParams).get("backUrl");
  const backButtonUrl = useMemo(() => (backUrl ? backUrl : "/settings/contract-versions"), [backUrl]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={backButtonUrl}>Back</BackButtonLink>

        <div className={classes.iconContainer}>
          <img src={contractVersionPreview} alt="customer" />
        </div>
        <div className={classes.nameContainer}>
          <span>{contractVersion?.version || "Create Version"}</span>
          {contractVersion && booleanStatusFormatter(contractVersion?.isActive)}
        </div>
      </div>
      {!!contractVersion && (
        <div className={classes.rightContainer}>
          <div className={classes.mainInfo}>
            <div className={classes.section}>
              <img src={calendarIcon} alt="Creation Date" />
              <div>
                <div className={classes.title}>CREATION DATE</div>
                <div className={classes.data}>{customDateFormatWithoutTime(contractVersion?.createdAt)}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
