import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { faultOrigins, RESOLUTION_OPTIONS, RESOLUTION_TYPES } from "./variables";
import { repairStoveAPI } from "utils/api";
import Title from "antd/es/typography/Title";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import actionIcon from "./../../../assets/images/svg/ActionRequired.svg";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    marginBottom: 24,
    paddingTop: 20,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "50%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  testResultContainer: {
    display: "flex",
  },

  commentContainer: {
    marginTop: 40,
  },
  action: {
    textAlign: "center",
    height: 240,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
  },
  actionTitle: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    width: 300,
    marginBottom: 20,
  },
  actionText: {
    fontSize: 13,
    fontWeight: 300,
    textAlign: "center",
    width: 300,
  },
  actionImage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: "10%",
  },
  smallAction: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    paddingTop: 20,
  },
  smallActionTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 6,
  },
  smallActionText: {
    fontSize: 13,
    fontWeight: 300,
  },
  smallActionImage: {
    opacity: "10%",
    width: 60,
    height: 60,
    marginRight: 15,
  },
});

const StoveRepairModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const { stoveBarcode, customerName, stoveType, collectedBy, collectedAt, customerId, stoveId } = activeItem;
  const classes = useStyles();
  const { getFilters } = useStoreFilters(FILTER_PAGE.STOVE_REPAIR);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [faultOrigin, setFaultOrigin] = useState(null);

  const handleChange = useCallback(value => {
    form.resetFields();
    setSelectedType(value);
    setFaultOrigin(null);
  }, []);

  const handleChangeFaultOrigin = useCallback(value => {
    setFaultOrigin(value);
  }, []);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    const params = { stoveId, action: selectedType, faultOrigin };
    repairStoveAPI({ userName: customerName, params })
      .then(() => {
        successMessage(`Resolution was saved successfully`);
        onUpdate(getFilters());
        onClose();
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [form, customerName, selectedType, faultOrigin]);

  return (
    <Modal
      open={isOpen}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Stove
            <span>{stoveBarcode}</span>
          </div>
          <NGISelect showSearch value={selectedType} onChange={handleChange} placeholder="Please select resolution">
            {RESOLUTION_OPTIONS.map(item => (
              <Option value={item.value} key={item.value}>
                {item.name}
              </Option>
            ))}
          </NGISelect>
        </div>
      }
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <div>
            <div className={classes.container}>
              <div className={classes.listBlock}>
                <div className={classes.item}>
                  <div className={classes.title}>Customer Name</div>
                  <div className={classes.value}>{customerName ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>Stove Type</div>
                  <div className={classes.value}>{stoveType ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>Collected By</div>
                  <div className={classes.value}>
                    {collectedBy ? (
                      <CustomLink underlined target="_blank" to={`/user/${collectedBy}`}>
                        {collectedBy}
                      </CustomLink>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.listBlock}>
                <div className={classes.item}>
                  <div className={classes.title}>Customer Id</div>
                  <div className={classes.value}>
                    {customerId ? (
                      <CustomLink underlined target="_blank" to={`/customers/customer/${customerId}`}>
                        {customerId}
                      </CustomLink>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>Stove Id</div>
                  <div className={classes.value}>
                    {stoveId ? (
                      <CustomLink underlined target="_blank" to={`/warehouse/assets/${stoveId}`}>
                        {stoveId}
                      </CustomLink>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>Collected At</div>
                  <div className={classes.value}>{customDateFormatWithoutTime(collectedAt) ?? "-"}</div>
                </div>
              </div>
            </div>
          </div>
          {!selectedType ? (
            <div className={classes.action}>
              <img src={actionIcon} alt="actionIcon" className={classes.actionImage} />
              <div className={classes.actionTitle}>ACTION IS REQUIRED!</div>
              <div className={classes.actionText}>
                Please select one of the dropdown options to change the status of the stove.
              </div>
            </div>
          ) : (
            <Form form={form} name="for-repair">
              <div className={classes.smallAction}>
                <img src={actionIcon} alt="actionIcon" className={classes.smallActionImage} />
                <div>
                  <div className={classes.smallActionTitle}>{RESOLUTION_TYPES[selectedType].title}</div>
                  <div className={classes.smallActionText}>{RESOLUTION_TYPES[selectedType].description}</div>
                </div>
              </div>
              <div className={classes.commentContainer}>
                <Col>
                  <Title level={5}>Fault Origin</Title>
                  <Form.Item name="faultOrigin">
                    <NGISelect
                      showSearch
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      size="default"
                      placeholder="Select Fault Origin"
                      onChange={handleChangeFaultOrigin}
                    >
                      {faultOrigins.length > 0 &&
                        faultOrigins.map(item => (
                          <Option key={item} value={item.value}>
                            {item.name}
                          </Option>
                        ))}
                    </NGISelect>
                  </Form.Item>
                </Col>
              </div>
            </Form>
          )}
        </div>
      </Spin>
      <Space>
        <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
        <CustomButton
          onClick={handleSave}
          disabled={!selectedType || !faultOrigin}
          type="primary"
          size="small"
          text="Confirm"
        />
      </Space>
    </Modal>
  );
};

export default StoveRepairModal;
