import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import removeIcon from "assets/images/svg/Delete.svg";
import { Autocomplete, DrawingManager, GoogleMap, InfoBox, Marker, Polygon } from "@react-google-maps/api";
import { Spin } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { getMapZoom } from "utils/helpers/mapHelpers";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const useStyles = makeStyles({
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  removeButton: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    minWidth: "auto",
  },
  mapActionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    "& > span": {
      fontSize: 12,
      color: "#444444bf",
      fontStyle: "italic",
    },
  },
});

const containerStyle = {
  width: "100%",
  height: "400px",
};

const infoBoxStyle = {
  backgroundColor: "black",
  opacity: 0.6,
  padding: 12,
  borderRadius: 4,
};

const linkStyle = {
  fontSize: 16,
  color: "#fff",
};

const inputStyles = {
  boxSizing: "border-box",
  border: "1px solid transparent",
  width: 200,
  height: 32,
  padding: "0 12px",
  borderRadius: 3,
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
  fontSize: 14,
  outline: "none",
  textOverflow: "ellipses",
  position: "absolute",
  marginLeft: 270,
  top: 5,
};

/* eslint-disable no-undef */
const GeoDetails = ({
  center,
  isLoaded,
  onClearMap,
  onPolygonComplete,
  onMarkerComplete,
  initialData,
  isTouched,
  polygonList = [],
  drawingMode = true,
  allowPolygon = true,
  allowMarker = true,
  allowSearch = false,
  allowOpenDetails = true,
}) => {
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [mapCenter, setMapCenter] = useState(center);
  const [mapZoom, setMapZoom] = useState(9);
  const hideRoles = [
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
    USER_ROLES.GENERAL,
    USER_ROLES.D_SM,
    USER_ROLES.A_SM,
    USER_ROLES.OPERATION_MANAGERS,
    USER_ROLES.DEPOT_CLERK,
    USER_ROLES.SALES_SUPPORT,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.FINANCE,
    USER_ROLES.WAREHOUSE,
  ];

  const onLoad = useCallback(data => {
    setAutocomplete(data);
    const mapDiv = document.getElementsByClassName("mapContainer")[0].getElementsByTagName("div")[0];
    mapDiv.appendChild(document.getElementsByClassName("customMapSearch")[0]);
  }, []);

  const onPlaceClick = useCallback(() => {
    const data = autocomplete.getPlace();
    const zoom = getMapZoom(data.types);
    setMapZoom(zoom);
    setMapCenter({ lat: data.geometry.location.lat(), lng: data.geometry.location.lng() });

    const latlngObj = new google.maps.LatLng(data.geometry.location.lat(), data.geometry.location.lng());
    onMarkerComplete &&
      onMarkerComplete(
        new google.maps.Marker({
          position: latlngObj,
        })
      );
  }, [autocomplete, onMarkerComplete]);

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      onPolygonComplete(polygonRef.current, true);
    }
  }, [onPolygonComplete]);

  const onLoadPolygon = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  useEffect(() => {
    if (mapCenter?.lat !== center?.lat || mapCenter?.lng !== center?.lng) setMapCenter(center);
  }, [center]);

  document.onfullscreenchange = event => {
    const target = event.target;
    const pacContainerElements = document.getElementsByClassName("pac-container");
    if (pacContainerElements.length > 0) {
      const pacContainer = document.getElementsByClassName("pac-container")[0];
      if (pacContainer.parentElement === target) {
        document.getElementsByTagName("body")[0].appendChild(pacContainer);
        pacContainer.className += pacContainer.className.replace("fullscreen-pac-container", "");
      } else {
        target.appendChild(pacContainer);
        pacContainer.className += " fullscreen-pac-container";
      }
    }
  };

  return (
    <div className={classes.mapContainer}>
      {onClearMap && (
        <div className={classes.mapActionContainer}>
          <span>Note: Start and End should converge at the same point</span>
          <CustomButton
            className={classes.removeButton}
            onClick={onClearMap}
            type="primary"
            color="outlined"
            size="small"
            text={<img src={removeIcon} alt="" />}
          />
        </div>
      )}

      {isLoaded && mapCenter ? (
        <GoogleMap
          mapContainerClassName="mapContainer"
          options={{ keyboardShortcuts: false }}
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={mapZoom}
        >
          {allowSearch && (
            <Autocomplete className="customMapSearch" onLoad={onLoad} onPlaceChanged={onPlaceClick}>
              <input
                type="text"
                placeholder="Search..."
                style={inputStyles}
                pattern={inputTextPattern}
                title={inputPatternTitle}
              />
            </Autocomplete>
          )}

          <DrawingManager
            options={{
              drawingControl: drawingMode,
              drawingControlOptions: {
                drawingModes: [
                  allowMarker && google.maps.drawing.OverlayType.MARKER,
                  allowPolygon && google.maps.drawing.OverlayType.POLYGON,
                ],
              },
              polygonOptions: {
                editable: true,
              },
            }}
            onPolygonComplete={onPolygonComplete}
            onMarkerComplete={onMarkerComplete}
          />

          {initialData && !isTouched && initialData.polygon && (
            <Polygon
              editable
              onMouseUp={onEdit}
              onDragEnd={onEdit}
              onLoad={onLoadPolygon}
              paths={[initialData.polygon?.map(item => new google.maps.LatLng(item[0], item[1]))]}
            />
          )}
          {initialData && initialData.marker && <Marker position={initialData.marker} />}

          {polygonList &&
            polygonList.map(item => (
              <Polygon
                key={item.id}
                options={{
                  fillColor: item.color,
                }}
                onClick={e => {
                  setActiveItem({ lat: e.latLng.lat(), lng: e.latLng.lng(), id: item.id });
                }}
                paths={[item.poly.map(item => new google.maps.LatLng(item[0], item[1]))]}
              />
            ))}
          {activeItem && (
            <InfoBox
              options={{ closeBoxURL: "", enableEventPropagation: true }}
              position={{ lat: activeItem.lat, lng: activeItem.lng }}
            >
              <div style={infoBoxStyle}>
                <div style={linkStyle}>
                  {userHasOneOfRoles(hideRoles) || !allowOpenDetails ? (
                    <b>{activeItem.id}</b>
                  ) : (
                    <CustomLink color="#fff" to={`/depots/depot?name=${activeItem.id}`} target={"_blank"}>
                      View <b>{activeItem.id}</b>
                    </CustomLink>
                  )}
                </div>
              </div>
            </InfoBox>
          )}
        </GoogleMap>
      ) : (
        <Spin spinning={true} />
      )}
    </div>
  );
};

export default GeoDetails;
