import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container } from "reactstrap";
import { taskColumns } from "./taskColumns";
import { getTaskCategory } from "store/operations/tasks/actions";
import { retrieveTasksArchive } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { TaskSelector } from "store/operations/tasks/selectors";
import NGITable from "components/Common/NGITable";
import { FILTER_PAGE } from "utils/constants";
import ArchiveFilter from "pages/operations/taskArchive/taskArchiveFilter";
import useStoreFilters from "customHooks/useStoreFilters";

const TaskArchive = () => {
  const dispatch = useDispatch();
  const { taskCategory } = useSelector(TaskSelector);
  const [isLoading, setLoader] = useState(false);
  const [taskList, setTaskList] = useState(null);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.TASK_ARCHIVE);

  useEffect(() => {
    dispatch(getTaskCategory());
    handleOnFilter(filters);
  }, []);

  const handleOnFilter = (options = {}) => {
    setLoader(true);
    setFilters(options);

    retrieveTasksArchive({ ...options })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items.length > 0) {
          setTaskList(res?.data);
          setLoader(false);
        } else {
          warnMessage("Task was filtered");
          setTaskList([]);
          setLoader(false);
        }
      })
      .catch(err => {
        setLoader(false);
        setTaskList([]);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <ArchiveFilter onFilter={handleOnFilter} isLoading={isLoading} showCategory={taskCategory} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.TASK_ARCHIVE}
              isLoading={isLoading}
              columns={taskColumns}
              updateList={handleOnFilter}
              data={taskList}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default TaskArchive;
