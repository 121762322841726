import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { updateMeterTypeAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { FirmwaresSettingsSelector } from "store/meter-management/configuration/firmware/selectors";
import { getFirmwares } from "store/meter-management/configuration/firmware/actions";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const useStyles = makeStyles({
  root: { width: "300px!important" },
  modal: {
    width: "400px!important",
  },

  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  title: {
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
    marginTop: 20,
  },
});

const ChangeMeterTypeModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { filters } = useStoreFilters(FILTER_PAGE.METER);
  const [loading, setLoader] = useState(false);
  const classes = useStyles();
  const [firmware, setFirmware] = useState(null);
  const [type, setType] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [data, setData] = useState(false);
  const {
    list: { meterType },
  } = useSelector(DictionariesSelector);
  const { firmwares } = useSelector(FirmwaresSettingsSelector);

  useEffect(() => {
    form.setFieldsValue({
      firmware: activeItem?.firmware,
      meterType: activeItem?.type,
    });
  }, [activeItem]);
  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_TYPE));
    dispatch(getFirmwares());
  }, []);

  const openConfirmationModal = useCallback(data => {
    setData(data);
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const handleFirmwareChange = useCallback(value => setFirmware(value), []);
  const handleTypeChange = useCallback(value => setType(value), []);

  const handleSubmit = useCallback(() => {
    setLoader(true);
    updateMeterTypeAPI(activeItem.id, data)
      .then(() => {
        closeConfirmationModal();
        onClose();
        warnMessage(`Meter type was changed successfully.`);
        onUpdate(filters);
        setLoader(false);
      })
      .catch(err => {
        closeConfirmationModal();
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [data]);

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Edit meter
            <span>{`Meter (${activeItem?.serial})`}</span>
          </div>
        </div>
      }
      className={classes.modal}
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={loading}>
        <>
          <NGIForm name="change-meter-type" form={form} isUpdate={true} onFinish={openConfirmationModal}>
            <Title level={5}>Change meter type</Title>
            <Form.Item name="meterType" rules={[{ required: true, message: "Please select meter type" }]}>
              <NGISelect
                showSearch
                className={classes.root}
                onChange={handleTypeChange}
                placeholder="Please select meter type"
              >
                {!!meterType &&
                  meterType.map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>

            <Title className={classes.title} level={5}>
              Change firmware version
            </Title>
            <Form.Item name="firmware" rules={[{ required: true, message: "Please select firmware version" }]}>
              <NGISelect
                showSearch
                className={classes.root}
                onChange={handleFirmwareChange}
                placeholder="Please select firmware version"
              >
                {!!firmwares &&
                  firmwares.map(item => (
                    <Option value={item.version} key={item.version}>
                      {item.version}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>

            <Space>
              <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
              <CustomButton disabled={!firmware && !type} type="primary" text="Save" size="small" />
            </Space>
          </NGIForm>
          {isConfirmationOpen && (
            <ConfirmationModal
              loading={loading}
              description={"Are you sure you want to change the meter type and firmware?"}
              onCancel={closeConfirmationModal}
              onConfirm={handleSubmit}
            />
          )}
        </>
      </Spin>
    </Modal>
  );
};

export default ChangeMeterTypeModal;
