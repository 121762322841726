import { textFormatter, customDateFormatWithTime } from "components/Common/formatter";

export const meterSettingsColumns = [
  {
    title: "updatePeriod",
    dataIndex: "updatePeriod",
    key: "updatePeriod",
    render: (cellContent, report) => textFormatter(report.warningLowCreditBalance),
    width: "20%",
  },
  {
    title: "startPeriod",
    dataIndex: "startPeriod",
    key: "startPeriod",
    render: (cellContent, report) => textFormatter(report.startPeriod),
    width: "20%",
  },
  {
    title: "warningLowBatteryVoltage",
    dataIndex: "warningLowBatteryVoltage",
    key: "warningLowBatteryVoltage",
    render: (cellContent, report) => textFormatter(report.warningLowBatteryVoltage),
  },
  {
    title: "warningLowCreditBalance",
    dataIndex: "warningLowCreditBalance",
    key: "warningLowCreditBalance",
    render: (cellContent, report) => textFormatter(report.warningLowCreditBalance),
  },
  {
    title: "unitPrice",
    dataIndex: "unitPrice",
    key: "unitPrice",
    render: (cellContent, report) => textFormatter(report.unitPrice),
    width: "20%",
  },
  {
    title: "warningLowGasMass",
    dataIndex: "gawarningLowGasMasssRemaining",
    key: "warningLowGasMass",
    render: (cellContent, report) => textFormatter(report.gasRemaining),
    width: "20%",
  },
  {
    title: "gasRemaining",
    dataIndex: "gasRemaining",
    key: "gasRemaining",
    render: (cellContent, report) => textFormatter(report.gasRemaining),
    width: "20%",
  },
];

export const reportColumns = [
  {
    title: "Voltage",
    dataIndex: "batteryModel",
    key: "batteryModel",
    render: (cellContent, report) => textFormatter(report.batteryModel),
  },
  {
    title: "Meter Serial",
    dataIndex: "meterSerialNumber",
    key: "meterSerialNumber",
    render: (cellContent, report) => textFormatter(report.meterSerialNumber),
  },
  {
    title: "MCU FV",
    dataIndex: "mcuFirmwareVersion",
    key: "mcuFirmwareVersion",
    render: (cellContent, report) => textFormatter(report.mcuFirmwareVersion),
  },
  {
    title: "PCB V",
    dataIndex: "pcbVersion",
    key: "pcbVersion",
    render: (cellContent, report) => textFormatter(report.pcbVersion),
  },
  {
    title: "MCU CDate",
    dataIndex: "mcuCompileDate",
    key: "mcuCompileDate",
    render: (cellContent, report) => textFormatter(report.mcuCompileDate),
  },
  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
  },
];

export const cumulativeReportColumns = [
  {
    title: "Gas Level",
    dataIndex: "gasRemaining",
    key: "gasRemaining",
    render: (cellContent, report) => textFormatter(report.gasRemaining),
    width: 100,
  },
  {
    title: "Credit",
    dataIndex: "currentCredit",
    key: "currentCredit",
    render: (cellContent, report) => textFormatter(report.currentCredit),
    width: 100,
  },
  {
    title: "Cumulative Credit",
    dataIndex: "endCumulativeCredit",
    key: "endCumulativeCredit",
    render: (cellContent, report) => textFormatter(report.endCumulativeCredit),
    width: 100,
  },
  {
    title: "Cumulative Gas",
    dataIndex: "endCumulativeMass",
    key: "endCumulativeMass",
    render: (cellContent, report) => textFormatter(report.endCumulativeMass.toFixed(2)),
    width: 100,
  },
  {
    title: "CS Sent",
    dataIndex: "cookingSessionSent",
    key: "cookingSessionSent",
    render: (cellContent, report) => textFormatter(report.cookingSessionSent),
    width: 100,
  },
  {
    title: "Signal",
    dataIndex: "gsmSignalIntensity",
    key: "gsmSignalIntensity",
    render: (cellContent, report) => textFormatter(report.gsmSignalIntensity),
    width: 100,
  },
  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
    width: 150,
  },
];

export const meterStatusColumns = [
  {
    title: "Volts",
    dataIndex: "batteryVoltage",
    key: "batteryVoltage",
    render: (cellContent, report) => textFormatter(report.batteryVoltage),
    width: 100,
  },
  {
    title: "Battery Level",
    dataIndex: "batteryLevel",
    key: "batteryLevel",
    render: cellContent => (cellContent ? `${cellContent}%` : textFormatter(cellContent)),
    width: 100,
  },
  {
    title: "CS Sent",
    dataIndex: "cookingSessionSent",
    key: "cookingSessionSent",
    render: (cellContent, report) => textFormatter(report.cookingSessionSent),
    width: 100,
  },
  {
    title: "Signal",
    dataIndex: "gsmSignalIntensity",
    key: "gsmSignalIntensity",
    render: (cellContent, report) => textFormatter(report.gsmSignalIntensity),
    width: 100,
  },
  {
    title: "Light",
    dataIndex: "lidLightSensorStatus",
    key: "lidLightSensorStatus",
    render: (cellContent, report) => textFormatter(report.lidLightSensorStatus),
    width: 100,
  },
  {
    title: "Lock",
    dataIndex: "meterLockStatus",
    key: "meterLockStatus",
    render: (cellContent, report) => textFormatter(report.meterLockStatus),
    width: 100,
  },
  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
    width: 100,
  },
];

export const alertColumns = [
  {
    title: "type",
    dataIndex: "type",
    key: "type",
    render: (cellContent, report) => textFormatter(report.type),
  },
  // {
  //   title: "Gas Level",
  //   dataIndex: "gasLevel",
  //   key: "gasLevel",
  //   render: (cellContent, report) => textFormatter(cellContent),
  // },
  // {
  //   title: "Voltage",
  //   dataIndex: "batteryVoltage",
  //   key: "batteryVoltage",
  //   render: (cellContent, report) => textFormatter(cellContent),
  // },
  // {
  //   title: "Customer",
  //   dataIndex: "customerId",
  //   key: "customerId",
  //   render: (cellContent, report) => textFormatter(cellContent),
  //    width: "2%",
  // },
  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
  },
];
