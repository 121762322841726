import React, { useCallback, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Divider, Form, Row, Spin } from "antd";
import { VALIDATION_RULES } from "./constants";
import Header from "./Header";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import NGIInput from "components/Common/inputs/NGIInput";
import { fetchCustomerById } from "utils/api";
import SendADMOSection from "pages/customer-management/sendAdmo/SendADMOSection";
import { warnMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sectionItem: {
    marginTop: 30,
  },
  informationContainer: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
  },
  column: {
    width: "100%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
  },
  buttonCenter: {
    marginTop: 5,
  },
});

const SendAdmo = () => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [controlsDisabled, setControlsDisabled] = useState(false);

  const onSubmit = useCallback(
    values => {
      setLoader(true);
      fetchCustomerById(values?.customerId?.trim())
        .then(res => {
          setLoader(false);
          setCustomer(res?.data?.result);
        })
        .catch(error => {
          let msg = "Customer is not found";
          const errorMessage = error.response?.data?.errors[0];
          if (errorMessage.code === "ACCESS_DENIED") {
            msg = errorMessage.desc;
            form.setFieldValue("customerId", null);
            setControlsDisabled(true);
          }
          warnMessage(msg);
          setLoader(false);
          setCustomer(null);
        });
    },
    [form]
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    <Form form={form} layout="inline" onFinish={onSubmit} className={classes.formContainer}>
                      <div className={classes.modalContent}>
                        <Row>
                          <Col span={12}>
                            <Form.Item name="customerId" rules={VALIDATION_RULES}>
                              <NGIInput disabled={controlsDisabled} allowClear placeholder={"Enter Customer ID"} />
                            </Form.Item>
                          </Col>
                          <Col span={12} className={classes.buttonCenter}>
                            <CustomButton
                              disabled={controlsDisabled}
                              htmlType="submit"
                              type="primary"
                              text="Search"
                              size="small"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                    {Boolean(customer) && (
                      <>
                        <Divider />

                        <Row className={classes.sectionItem}>
                          <Col span={12}>
                            <div className={classes.informationContainer}>
                              <div className={classes.column}>
                                <div className={classes.title}>Name</div>
                                <div className={classes.title}>Customer ID</div>
                              </div>
                              <div className={classes.column}>
                                <div className={classes.data}>{`${customer.firstName} ${customer.lastName}`}</div>
                                <div className={classes.data}>{customer.id}</div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <SendADMOSection customerId={customer.id} />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default SendAdmo;
