import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useLocation, useParams } from "react-router";
import { retrieveAssetPhotos, retrieveAssetsDetails } from "utils/api";
import { Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { boolFormatter, customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import Header from "./Header";
import ContractModal from "pages/customer-management/customerDetails/Tabs/ContractModal";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";
import CustomLink from "components/Common/buttons/CustomLink";
import NotFoundContainer from "components/Common/NotFoundContainer";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  rootBox: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "70%",
  },
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "30%",
  },
  column: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "25%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "51%",
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
      marginRight: 10,
    },
  },
});

const AssetDetails = () => {
  const { id } = useParams();
  const searchParams = useLocation().search;
  const idType = new URLSearchParams(searchParams).get("t");
  const classes = useStyles();
  const [isDataLoading, setDataLoading] = useState(false);
  const [initialValues, setinitialValues] = useState(null);
  const [photoURLs, setPhotoURLs] = useState([]);
  const [currentURL, setCurrentURL] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const openModal = useCallback(url => {
    setCurrentURL(url);
    setShowModal(true);
  }, []);
  const closeModal = useCallback(() => setShowModal(false), []);

  useEffect(() => {
    if (!!initialValues?.cylinderAttributes?.repairPhotos?.length && !!id) {
      setDataLoading(true);
      retrieveAssetPhotos(id)
        .then(async res => {
          if (res.data.result) {
            setDataLoading(false);
            setPhotoURLs(res.data.result.map(item => item?.url));
          } else {
            setPhotoURLs([]);
          }
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
        });
    }
  }, [initialValues, id]);

  useEffect(() => {
    onViewAsset();
  }, []);

  const onViewAsset = async () => {
    setDataLoading(true);
    await retrieveAssetsDetails(id, idType)
      .then(async res => {
        if (res.data.result) {
          setDataLoading(false);
          setinitialValues(res.data.result);
        } else {
          setinitialValues(null);
        }
      })
      .catch(error => {
        setDataLoading(false);
        if (error.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
  };

  const qrCode = useMemo(
    () =>
      initialValues?.qrCode &&
      initialValues?.qrCode !== "Unk" &&
      initialValues?.qrCode !== "UNKNOWN" && <QRCode size={180} value={initialValues?.qrCode} />,
    [initialValues?.qrCode]
  );

  const barcode = useMemo(
    () =>
      initialValues?.barcode &&
      initialValues?.barcode !== "UNKNOWN" && <Barcode width={1} displayValue={false} value={initialValues?.barcode} />,
    [initialValues?.barcode]
  );

  return (
    <div className="page-content">
      <Container fluid>
        <NotFoundContainer isNotFound={isNotFound}>
          <Spin spinning={isDataLoading}>
            <Header id={id} initialValues={initialValues} />
            <div className={classes.root}>
              <Card className={classes.container}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.rootBox}>
                    <div className={classes.informationContainer}>
                      <div className={classes.column}>
                        <div className={classes.title}> Asset Type: </div>
                        <div className={classes.data}>{initialValues?.type || "-"}</div>
                        <div className={classes.title}>Barcode: </div>
                        <div className={classes.data}>{initialValues?.barcode || "-"}</div>
                        <div className={classes.title}>QrCode: </div>
                        <div className={classes.data}>{initialValues?.qrCode || "-"}</div>
                        <div className={classes.title}>Custodian: </div>
                        <div className={classes.data}>
                          {textFormatter(
                            !!initialValues?.custodian
                              ? `${initialValues.custodianType}/${initialValues.custodian}`
                              : null
                          )}
                        </div>
                        <div className={classes.title}>Serial: </div>
                        <div className={classes.data}>{initialValues?.serialNumber || "-"}</div>
                        {initialValues?.typeGroup === "Cylinder" && (
                          <>
                            <div className={classes.title}>Cylinder Weight: </div>
                            <div className={classes.data}>
                              {initialValues?.cylinderAttributes?.cylinderWeight || "-"}
                            </div>
                            <div className={classes.title}>Gas Weight: </div>
                            <div className={classes.data}>{initialValues?.cylinderAttributes?.gasWeight || "-"}</div>
                            <div className={classes.title}>Gas Weight From Market: </div>
                            <div className={classes.data}>{initialValues?.cylinderAttributes?.gasWeightFromMarket}</div>
                            <div className={classes.title}>Gas Weight To Market: </div>
                            <div className={classes.data}>{initialValues?.cylinderAttributes?.gasWeightToMarket}</div>

                            <div className={classes.title}>Total Weight: </div>
                            <div className={classes.data}>{initialValues?.cylinderAttributes?.totalWeight || "-"}</div>
                            <div className={classes.title}>Manufacture Weight: </div>
                            <div className={classes.data}>{initialValues?.manufactureWeight || "-"} </div>
                          </>
                        )}
                        {initialValues?.typeGroup === "Battery" && (
                          <>
                            <div className={classes.title}>Battery Voltage: </div>
                            <div className={classes.data}>{initialValues?.batteryAttributes?.voltage || "-"}</div>
                          </>
                        )}
                        {initialValues?.typeGroup === "Card" && (
                          <>
                            <div className={classes.title}>Card #: </div>
                            <div className={classes.data}>{initialValues?.cardAttributes?.number || "-"}</div>
                          </>
                        )}
                        {initialValues?.state === "In Use" && (
                          <>
                            <div className={classes.title}>Customer Id: </div>
                            <div className={classes.data}>{initialValues?.customerId || "-"}</div>
                          </>
                        )}
                        {/*//sd*/}
                        <div className={classes.title}>Assigned To: </div>
                        <div className={classes.data}>
                          {initialValues?.assignedTo ? (
                            <CustomLink underlined target="_blank" to={`/user/${initialValues.assignedTo}`}>
                              {initialValues.assignedTo}
                            </CustomLink>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className={classes.title}>Routine ID: </div>
                        <div className={classes.data}>{initialValues?.routineId || "-"}</div>
                        <div className={classes.title}>Created By: </div>
                        <div className={classes.data}>
                          {initialValues?.createdBy ? (
                            <CustomLink underlined target="_blank" to={`/user/${initialValues?.createdBy}`}>
                              {initialValues?.createdBy}
                            </CustomLink>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className={classes.title}>Created At: </div>
                        <div className={classes.data}>{customDateFormatWithTime(initialValues?.createdAt || "-")}</div>
                        <div className={classes.title}>Updated By: </div>
                        <div className={classes.data}>
                          {initialValues?.updatedBy ? (
                            <CustomLink underlined target="_blank" to={`/user/${initialValues.updatedBy}`}>
                              {initialValues.updatedBy}
                            </CustomLink>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className={classes.title}>Updated At: </div>
                        <div className={classes.data}>{customDateFormatWithTime(initialValues?.updatedAt || "-")}</div>
                        <div className={classes.title}>Lost Copy: </div>
                        <div className={classes.data}>{boolFormatter(initialValues?.isAssetCopy || "-")}</div>
                        <div className={classes.title}>Trip Number: </div>
                        <div className={classes.data}>
                          {initialValues?.assetTransferTripId ? (
                            <CustomLink
                              underlined
                              target={"_blank"}
                              to={`/stock-management/trips/${initialValues?.assetTransferTripId}`}
                            >
                              {initialValues?.assetTransferTripId}
                            </CustomLink>
                          ) : (
                            textFormatter(initialValues?.assetTransferTripId)
                          )}
                        </div>
                        <div className={classes.title}>Last Trip Number: </div>
                        <div className={classes.data}>
                          {initialValues?.lastAssetTransferTripID ? (
                            <CustomLink
                              underlined
                              target={"_blank"}
                              to={`/stock-management/trips/${initialValues?.lastAssetTransferTripID}`}
                            >
                              {initialValues?.lastAssetTransferTripID}
                            </CustomLink>
                          ) : (
                            textFormatter(initialValues?.lastAssetTransferTripID)
                          )}
                        </div>

                        {!!photoURLs.length && <div className={classes.title}>Asset Photos: </div>}
                        <div className={classes.data}>
                          <div className={classes.img}>
                            {photoURLs.map(url => (
                              <img key={url} onClick={() => openModal(url)} src={url} alt={url} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.mapContainer}>
                      {qrCode && <div title={initialValues?.qrCode}>{qrCode}</div>}
                      {barcode && <div title={initialValues?.barcode}>{barcode}</div>}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Spin>
        </NotFoundContainer>
      </Container>
      {showModal && <ContractModal isOpen={showModal} onClose={closeModal} url={currentURL} />}
    </div>
  );
};

export default AssetDetails;
