import React, { useState, useEffect } from "react";
import { Modal, Tabs } from "antd";
import { cumulativeReportColumns, meterStatusColumns, alertColumns } from "./meterReportColumns";
import { retrieveMeterStatus, readAlertsAPI, readCumulativeReportsAPI } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import NGISpinner from "components/Common/NGISpinner";

export const ReportModel = props => {
  const { TabPane } = Tabs;
  const [isCumulativeLoading, setCumulativeLoading] = useState(false);
  const [initialValues, setinitialValues] = useState(null);
  const [cumulativeValues, setCumulativeValues] = useState(null);
  const [statusValues, setStatusValues] = useState(null);

  const { isVisible, onCancel, getAssetId } = props;
  useEffect(() => {
    onViewAlerts();
    onViewCumulative();
    onViewStatus();
  }, []);

  const onViewCumulative = async () => {
    setCumulativeLoading(true);
    await readCumulativeReportsAPI({ meterId: getAssetId })
      .then(async res => {
        if (res.data.result.items) {
          setCumulativeLoading(false);
          setCumulativeValues(res.data.result.items);
        } else {
          setCumulativeValues(null);
          setCumulativeValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };

  const onViewStatus = async () => {
    await retrieveMeterStatus(getAssetId)
      .then(async res => {
        if (res.data.result.items) {
          setStatusValues(res.data.result.items);
        } else {
          setStatusValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };

  const onViewAlerts = async () => {
    await readAlertsAPI({ meterId: getAssetId })
      .then(async res => {
        if (res.data.result.items) {
          setinitialValues(res.data.result.items);
        } else {
          setinitialValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };
  return (
    <div>
      <Modal title="Meter Reports" open={isVisible} onOk={onCancel} onCancel={onCancel} width={900} footer={[]}>
        <>
          <div className="card-container">
            {isCumulativeLoading ? (
              <NGISpinner />
            ) : (
              <>
                <Tabs type="card" tabBarExtraContent={""}>
                  <TabPane tab="Alerts" key="1">
                    {initialValues?.length > 0 && <NGITable columns={alertColumns} data={initialValues} />}
                  </TabPane>

                  <TabPane tab="Cumulative Report" key="2">
                    {cumulativeValues?.length > 0 && (
                      <NGITable columns={cumulativeReportColumns} data={cumulativeValues} />
                    )}
                  </TabPane>

                  <TabPane tab="Meter Status" key="3">
                    {statusValues?.length > 0 && <NGITable columns={meterStatusColumns} data={statusValues} />}
                  </TabPane>
                </Tabs>
              </>
            )}
          </div>
        </>
      </Modal>
    </div>
  );
};
