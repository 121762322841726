import React, { useCallback, useState } from "react";
import NGITable from "components/Common/NGITable";
import { customerBalanceBookColumns } from "pages/customer-management/customerDetails/constants";
import { makeStyles } from "@material-ui/core/styles";
import TransactionDetailsModal from "pages/customer-management/customerDetails/Tabs/TransactionDetailsModal";

const useStyles = makeStyles({
  balanceHeader: {
    display: "flex",
    marginBottom: 20,

    "& > div + div": {
      marginLeft: 15,
    },
  },
  noDataMessage: {
    textAlign: "center",
    fontWeight: 600,
  },
});

const BalanceBookTab = ({ customerBalance, isLoading, update, transactions }) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const onShowModal = useCallback(id => {
    setActiveId(id);
    setShowModal(true);
  }, []);

  const onCloseModal = useCallback(() => setShowModal(false), []);

  return (
    <div>
      {customerBalance ? (
        <>
          <div className={classes.balanceHeader}>
            <div>
              Stove price: <b>{customerBalance.balanceAmount}</b>
            </div>
            <div>
              Paid amount: <b>{customerBalance.paidAmount}</b>
            </div>
            <div>
              Outstanding amount: <b>{customerBalance.outstandingAmount}</b>
            </div>
            <div>
              Deduction value: <b>{customerBalance.deductionValue}</b>
            </div>
            {customerBalance.deductionType && (
              <div>
                Deduction type: <b>{customerBalance.deductionType}</b>
              </div>
            )}
            <div>
              Deduction amount: <b>{customerBalance.deductionAmount}</b>
            </div>
          </div>
          <NGITable
            isLoading={isLoading}
            updateList={update}
            columns={customerBalanceBookColumns({ onShowModal })}
            data={transactions}
          />
        </>
      ) : (
        <div className={classes.noDataMessage}>No active balance for the customer</div>
      )}
      {showModal && <TransactionDetailsModal isOpen={showModal} onClose={onCloseModal} id={activeId} />}
    </div>
  );
};

export default BalanceBookTab;
