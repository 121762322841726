import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import MetaData from "components/VerticalLayout/MetaData";
import MetersFilter from "./metersFilter";
import { retrieveMeters } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import MeterConfigurationModal from "./MeterConfigurationModal";
import MeterTestResultModal from "pages/meter-management/awaitingTestingMeters/MeterTestResultModal";
import { FILTER_PAGE } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { awaitingConfigMeterListColumns } from "pages/meter-management/awaitingConfigurationMeters/awaitingConfigMeterListColumns";

const AwaitingConfigMeters = () => {
  const [isLoading, setLoader] = useState(false);
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.AWAITING_TESTING_CONFIG);
  const [meterList, setMeterList] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isMeterTestResultModalVisible, setIsMeterTestResultModalVisible] = useState(null);
  const closeMeterConfigurationModal = useCallback(() => setIsMeterConfigurationModalVisible(false), []);

  const openMeterConfigurationModal = useCallback(item => {
    setActiveItem(item);
    setIsMeterConfigurationModalVisible(true);
  }, []);

  const closeMeterTestResultModal = useCallback(() => setIsMeterTestResultModalVisible(false), []);

  const openMeterTestResultModal = useCallback(item => {
    setActiveItem(item);
    setIsMeterTestResultModalVisible(true);
  }, []);

  const [isMeterConfigurationModalVisible, setIsMeterConfigurationModalVisible] = useState(null);

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const handleFilter = useCallback((newFilterOptions = {}) => {
    setLoader(true);
    setFilters(newFilterOptions);
    retrieveMeters({
      condition: "Awaiting Configuration",
      ...newFilterOptions,
    })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items.length === 0) {
          warnMessage("No meters were found");
          setLoader(false);
        }
        setMeterList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setMeterList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(
    () => awaitingConfigMeterListColumns({ openMeterConfigurationModal, openMeterTestResultModal }),
    []
  );

  return (
    <>
      <div className="page-content" data-testid="metersComponent">
        <MetaData pageTitle={"Awaiting Configuration"} />
        <Container fluid>
          <div className="filters-wrapper">
            <MetersFilter handleFilter={handleFilter} isLoading={isLoading} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.AWAITING_TESTING_CONFIG}
              isLoading={isLoading}
              columns={columns}
              data={meterList}
              updateList={handleFilter}
            />
          </Col>
        </Container>
      </div>
      {isMeterConfigurationModalVisible && (
        <MeterConfigurationModal
          updateData={handleFilter}
          isOpen={isMeterConfigurationModalVisible}
          onClose={closeMeterConfigurationModal}
          activeItem={activeItem}
        />
      )}
      {isMeterTestResultModalVisible && (
        <MeterTestResultModal
          isOpen={isMeterTestResultModalVisible}
          onClose={closeMeterTestResultModal}
          activeItem={activeItem}
          onUpdate={handleFilter}
        />
      )}
    </>
  );
};

export default AwaitingConfigMeters;
