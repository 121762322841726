import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveSurveysList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { surveyListColumns } from "pages/settings/survey-management/surveys/SurveyListColumns";
import SurveySearch from "pages/settings/survey-management/surveys/SurveySearch";
import CustomLink from "components/Common/buttons/CustomLink";

const SurveyList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.SURVEY);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const onListLoaded = useCallback(res => {
    const { result } = res?.data;
    if (result?.length === 0) {
      warnMessage("Surveys were not found");
    }
    setList({ result: { items: Array.isArray(result) ? result : [result] } } || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    retrieveSurveysList(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => surveyListColumns(), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomLink
                roles={[USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
                className="margin-right"
                to={"/settings/survey/new"}
              >
                <CustomButton icon={<i className="bx bx-plus-circle" />} type="primary" text="New Survey" />
              </CustomLink>
            </div>

            <SurveySearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <>
              <NGITable
                filtersType={FILTER_PAGE.SURVEY}
                isLoading={isLoading}
                columns={columns}
                data={list}
                updateList={handleSearch}
              />
            </>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default SurveyList;
