import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import { boolFormatter, customDateFormatWithTime } from "components/Common/formatter";
import pricePreview from "assets/images/svg/pricePreview.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  iconContainer: {
    padding: 8,
    marginLeft: 23,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 65,
    minWidth: 65,
    height: 65,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    minWidth: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ data = {} }) => {
  const classes = useStyles();
  const { id, name, isDefault, updatedBy, updatedAt } = data;

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/price-management/segments"}>Back</BackButtonLink>
        {!!id ? (
          <>
            <div className={classes.iconContainer}>
              <img src={pricePreview} alt="customer" />
            </div>
            <div className={classes.nameContainer}>
              <span>{name}</span>
            </div>
          </>
        ) : (
          <div className={classes.nameContainer}>
            <span>Create New Segment</span>
            <div className={classes.status}>Please fill in all mandatory fields (*)</div>
          </div>
        )}
      </div>
      {!!id && (
        <div className={classes.rightContainer}>
          <div className={classes.mainInfo}>
            <div className={classes.section}>
              <div>
                <div className={classes.title}>UPDATED BY</div>
                <div className={classes.data}>{updatedBy}</div>
              </div>
            </div>
            <div className={classes.divider} />
            <div className={classes.section}>
              <div>
                <div className={classes.title}>UPDATED AT</div>
                <div className={classes.data}>{customDateFormatWithTime(updatedAt)}</div>
              </div>
            </div>
            <div className={classes.divider} />
            <div className={classes.section}>
              <div>
                <div className={classes.title}>DEFAULT</div>
                <div className={classes.data}>{boolFormatter(isDefault)}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
