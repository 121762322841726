import { USER_ROLES } from "utils/constants";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

export const TABS = {
  GENERAL: "GENERAL",
  STOPS: "STOPS",
  ASSETS: "ASSETS",
  HISTORY: "HISTORY",
  EXTRA_ASSETS: "EXTRA_ASSETS",
  LOST_ASSETS: "LOST_ASSETS",
  REPORT: "REPORT",
};

export const ASSET_TYPES = {
  LOST: "LOST",
  EXTRA: "EXTRA",
  ALL: "ALL",
};

export const TABS_BUTTONS = [
  {
    title: "General Information",
    key: TABS.GENERAL,
  },
  {
    title: "Trip Stops",
    key: TABS.STOPS,
  },
  {
    title: "Assets in Truck",
    key: TABS.ASSETS,
  },
  {
    title: "Extra Assets",
    key: TABS.EXTRA_ASSETS,
  },
  {
    title: "Lost Assets",
    key: TABS.LOST_ASSETS,
  },
  {
    title: "History",
    key: TABS.HISTORY,
  },
  {
    title: "Report",
    key: TABS.REPORT,
  },
];

export const BREADCRUMBS = [
  {
    name: "STOCK MANAGEMENT",
  },
  {
    name: "ALL TRIPS",
  },
  {
    name: "View Trip",
    roles: [USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.DEPOT_CLERK, USER_ROLES.WAREHOUSE, USER_ROLES.LOGISTICS],
  },
];

export const tripHistoryColumns = () => [
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Updated by",
    dataIndex: "updatedBy",
    render: textFormatter,
  },
  {
    title: "Changed Fields",
    dataIndex: "fields",
    render: textFormatter,
  },
];

export const allAssetsColumns = () => [
  {
    title: "Group",
    dataIndex: "typeGroup",
    key: "typeGroup",
    render: (cellContent, routine) => (
      <Tooltip placement="bottom" title={`${routine.typeGroup} details`}>
        <div>
          <CustomLink underlined target={"_blank"} to={`/warehouse/assets/${routine.id}`}>
            {textFormatter(routine.typeGroup)}
          </CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Barcode",
    dataIndex: "barcode",
    key: "barcode",
    render: (cellContent, routine) => textFormatter(routine.barcode),
  },
  {
    title: "Serial",
    dataField: "serialNumber",
    key: "serialNumber",
    render: (cellContent, routine) => textFormatter(routine.serialNumber),
  },
  {
    title: "QR Code",
    dataIndex: "qrCode",
    key: "qrCode",
    render: (cellContent, routine) => textFormatter(routine.qrCode),
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    render: (cellContent, routine) => textFormatter(routine.state),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (cellContent, routine) => textFormatter(routine.type),
  },
];

export const extraAssetsColumns = () => [
  {
    title: "Group",
    dataIndex: "typeGroup",
    key: "typeGroup",
    render: (cellContent, routine) => (
      <Tooltip placement="bottom" title={`${routine.typeGroup} details`}>
        <div>
          <CustomLink underlined target={"_blank"} to={`/warehouse/assets/${routine.id}`}>
            {textFormatter(routine.typeGroup)}
          </CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Serial",
    dataField: "serialNumber",
    key: "serialNumber",
    render: (cellContent, routine) => textFormatter(routine.serialNumber),
  },
  {
    title: "QR Code",
    dataIndex: "qrCode",
    key: "qrCode",
    render: (cellContent, routine) => textFormatter(routine.qrCode),
  },
  {
    title: "Condition",
    dataIndex: "condition",
    key: "condition",
    render: (cellContent, routine) => textFormatter(routine.condition),
  },
];
