import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Divider, Form, Row, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import NGIInput from "components/Common/inputs/NGIInput";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY, TENANT_PHONE_MASK } from "utils/constants";
import ChangeAddressModel from "pages/operations/prospectDetails/ChangeAddressModel";
import CustomButton from "components/Common/buttons/CustomButton";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { fetchCustomerById, fetchCustomerList } from "utils/api";
import { warnMessage } from "components/Common/responses/message";
import { debounce } from "lodash";
import { VALIDATION_RULES } from "pages/meter-management/meters/variables";
import NGIFormItem from "components/Common/form/NGIFormItem";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";

const useStyles = makeStyles({
  column: {
    width: "100%",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  editContainer: {
    display: "flex",
    width: "100%",
  },
  form: {
    width: "100%",
  },
  filedColumn: {
    padding: "0 10px",
  },
  columnTitle: {
    fontSize: "14px!important",
    fontWeight: "400!important",
  },
  openMapButton: {
    height: 28,
    padding: "inherit",
  },
  customerInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  infoRow: {
    "&>span": {
      color: "#666666",
      fontSize: 13,
      fontWeight: 300,
    },
  },
});

const ProspectInformation = ({ editForm, allDepotsList, setAddress, address, foundCustomer, setFoundCustomer }) => {
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    libraries: ["drawing", "places"],
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });
  const {
    list: { leadSources },
  } = useSelector(DictionariesSelector);
  const [isSearchCustomer, setIsSearchCustomer] = useState(false);
  const [selectedRef, setSelectedRef] = useState(null);
  const [isAddressChangeModalOpen, setIsAddressChangeModalOpen] = useState(false);
  const closeChangeAddressModal = useCallback(() => setIsAddressChangeModalOpen(false), []);
  const openChangeAddressModal = useCallback(e => {
    e.preventDefault();
    setIsAddressChangeModalOpen(true);
  }, []);

  const handleAddressSave = useCallback(
    values => {
      setAddress(values);
      editForm.setFields([
        {
          name: "address",
          value: values.street,
          errors: [],
        },
        {
          name: "houseNumber",
          value: values.houseNumber,
        },
      ]);
    },
    [editForm]
  );

  const changeReceiptHandler = useCallback(event => {
    const value = event.target.value;
    setFoundCustomer(null);
    if (value.includes(" ")) return;
    if (value.length === 7) {
      setIsSearchCustomer(true);
      fetchCustomerById(value)
        .then(res => {
          const customer = res?.data?.result;
          setIsSearchCustomer(false);
          if (customer.status === "Active") {
            setFoundCustomer(customer);
          } else {
            warnMessage("The customer should be in Active status");
          }
        })
        .catch(() => {
          setIsSearchCustomer(false);
          warnMessage("Customer details are not found");
        });
      return;
    }
    if (value.length > 7) {
      setIsSearchCustomer(true);
      fetchCustomerList({ phone: value, status: "Active" })
        .then(res => {
          const {
            result: { items },
          } = res?.data;
          if (!items[0]) {
            warnMessage("Customer details are not found");
          } else {
            setFoundCustomer(items[0]);
          }
          setIsSearchCustomer(false);
        })
        .catch(() => {
          setIsSearchCustomer(false);
          warnMessage("Customer details are not found");
        });
    }
  }, []);

  const refSourceChanged = useCallback(value => {
    setFoundCustomer(null);
    setSelectedRef(value);
  }, []);

  const debouncedChangeReceiptHandler = useMemo(() => debounce(changeReceiptHandler, 1000), []);

  return (
    <div>
      <div className={classes.editContainer}>
        <Form form={editForm} className={classes.form}>
          <div className={classes.section}>
            <TitleWithDivider title="Prospect Information" />
            <Row>
              <Col span={6} className={classes.filedColumn}>
                <Title className={classes.columnTitle}>First Name</Title>
                <Form.Item name="firstName" rules={[{ required: true, message: "Please enter value" }]}>
                  <NGIInput placeholder="Enter the first name" />
                </Form.Item>
              </Col>
              <Col span={6} className={classes.filedColumn}>
                <Title className={classes.columnTitle}>Last Name</Title>
                <Form.Item name="lastName" rules={[{ required: true, message: "Please enter value" }]}>
                  <NGIInput placeholder="Enter the last name" />
                </Form.Item>
              </Col>
              <Col span={6} className={classes.filedColumn}>
                <Title className={classes.columnTitle}>Phone Number</Title>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: "Please enter value" },
                    {
                      message: "Phone Number should be in the correct format",
                      validator: function (_, value) {
                        const hasZero = value[3] === "0";
                        if (hasZero && value.match(/^(254|255)\d{10}$/)) {
                          return Promise.resolve();
                        } else if (!hasZero && value.match(/^(254|255)\d{9}$/)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Phone Number should be in the correct format");
                      },
                    },
                  ]}
                >
                  <NGIInput placeholder={`Enter ${TENANT_PHONE_MASK}`} />
                </Form.Item>
              </Col>
              <Col span={6} className={classes.filedColumn}>
                <Title className={classes.columnTitle}>Address</Title>
                <Form.Item name="address" rules={[{ required: true, message: "Please enter value" }]}>
                  <NGIInput
                    placeholder="Pick or enter the address"
                    suffix={
                      <CustomButton
                        className={classes.openMapButton}
                        onClick={openChangeAddressModal}
                        type="success"
                        size="small"
                        text="OPEN MAP"
                      />
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6} className={classes.filedColumn}>
                <Title className={classes.columnTitle}>House Number</Title>
                <Form.Item name="houseNumber" rules={[{ required: true, message: "Please enter value" }]}>
                  <NGIInput placeholder="Enter the house number" />
                </Form.Item>
              </Col>
            </Row>
            <TitleWithDivider title="Referral Information" />
            <Row>
              <Col span={6} className={classes.filedColumn}>
                <Title className={classes.columnTitle}>Referral Source</Title>
                <Form.Item rules={[{ required: true, message: "Please select value" }]} name="refSource">
                  <NGISelect showSearch onChange={refSourceChanged} placeholder="Select value">
                    {leadSources?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </NGISelect>
                </Form.Item>
              </Col>
              {selectedRef === "Customer" && (
                <Col span={6} className={classes.filedColumn}>
                  <Title className={classes.columnTitle}>Referral Value</Title>
                  <NGIFormItem
                    onChange={debouncedChangeReceiptHandler}
                    name="refValue"
                    rules={[
                      ...VALIDATION_RULES,
                      {
                        validator: (_, value) =>
                          !value?.includes(" ") ? Promise.resolve() : Promise.reject(new Error("Space isn't allowed")),
                      },
                    ]}
                  >
                    <NGIInput
                      placeholder="Referral Customer ID/ Phone Number"
                      suffix={<Spin size="small" spinning={isSearchCustomer} />}
                    />
                  </NGIFormItem>
                </Col>
              )}
              {selectedRef !== "Customer" && (
                <Col span={6} className={classes.filedColumn}>
                  <Title className={classes.columnTitle}>Referral Value</Title>
                  <NGIFormItem name="refValue">
                    <NGIInput maxLength={50} placeholder="Referral value" />
                  </NGIFormItem>
                </Col>
              )}

              {foundCustomer && (
                <>
                  <Col span={6} className={classnames(classes.filedColumn, classes.customerInfoContainer)}>
                    <div className={classes.infoRow}>
                      <span>Name:</span> {`${foundCustomer.firstName} ${foundCustomer.lastName}`}
                    </div>
                    <div className={classes.infoRow}>
                      <span>Status:</span> {foundCustomer.status}
                    </div>
                  </Col>
                  <Col span={6} className={classnames(classes.filedColumn, classes.customerInfoContainer)}>
                    <div className={classes.infoRow}>
                      <span>Customer ID:</span> {foundCustomer.id}
                    </div>
                    <div className={classes.infoRow}>
                      <span>Phone Number:</span> {foundCustomer.phone}
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <Divider />
          </div>
        </Form>
      </div>
      {isAddressChangeModalOpen && (
        <ChangeAddressModel
          isLoaded={isLoaded}
          address={address}
          classes={classes}
          onClose={closeChangeAddressModal}
          onSave={handleAddressSave}
          polygonList={allDepotsList}
        />
      )}
    </div>
  );
};

export default ProspectInformation;
