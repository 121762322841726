import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { getFieldsData } from "pages/meter-management/firmware/firmwareUpdateProcesses/utils";
import CustomLink from "components/Common/buttons/CustomLink";

export const firmwareUpdateProcessesColumns = [
  {
    title: "New Firmware",
    dataIndex: "input",
    render: cellContent => textFormatter(cellContent?.newFirmware),
  },
  {
    title: "Additional data",
    dataIndex: "input",
    render: (cellContent, row) =>
      cellContent.inputType === "CSV"
        ? `CSV, ${row.counts?.input || "No meters"}`
        : getFieldsData(cellContent, ["meterBarcode", "mcuType", "oldFirmware", "depot"]),
  },
  {
    title: "Triggered At",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
  },
  {
    title: "Completed At",
    dataIndex: "completedAt",
    render: customDateFormatWithTime,
  },
  {
    title: "Cancelled At",
    dataIndex: "cancelledAt",
    render: customDateFormatWithTime,
  },
  {
    title: "Triggered By",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Cancelled By",
    dataIndex: "cancelledBy",
    render: textFormatter,
  },
  {
    title: "Triggered Qty",
    dataIndex: "counts",
    render: cellContent => textFormatter(cellContent?.triggered),
  },
  {
    title: "Succeeded Qty",
    dataIndex: "counts",
    render: cellContent => textFormatter(cellContent?.succeeded),
  },
  {
    title: "Failed Qty",
    dataIndex: "counts",
    render: cellContent => textFormatter(cellContent?.failed),
  },
  {
    title: "Invalid Qty",
    dataIndex: "counts",
    render: cellContent => textFormatter(cellContent?.invalid),
  },
  {
    title: "Failed To Trigger Qty",
    dataIndex: "counts",
    render: cellContent => textFormatter(cellContent?.failedToTrigger),
  },
];
