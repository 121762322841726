import React from "react";
import moment from "moment";
import { textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import NGITooltip from "components/Common/utils/NGITooltip";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const hideRoles = [
  USER_ROLES.PRODUCT_TECHNICIANS,
  USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
  USER_ROLES.IT_OPERATIONS,
  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
];

export const listColumns = () =>
  [
    {
      title: "Barcode",
      dataIndex: "barcode",
      sort: true,
      render: (cellContent, row) =>
        userHasOneOfRoles(hideRoles) ? (
          <div>{textFormatter(cellContent)}</div>
        ) : (
          <NGITooltip title={`${cellContent} details`}>
            <CustomLink to={`logs/${row.id}`}>{textFormatter(cellContent)}</CustomLink>
          </NGITooltip>
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      sort: true,
      render: cellContent => textFormatter(cellContent),
    },
    {
      title: "Status",
      dataIndex: "status",
      sort: true,
      render: cellContent => textFormatter(cellContent),
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      sort: true,
      render: cellContent => (
        <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
          {cellContent}
        </CustomLink>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      sort: true,
      render: cellContent => moment(cellContent).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "LAST UPDATED",
      dataIndex: "updatedAt",
      sort: true,
      render: cellContent => moment(cellContent).format("YYYY-MM-DD HH:mm:ss"),
    },
  ].filter(item => item.dataIndex !== "createdBy");
