import { getTrimmedObjectValues } from "utils/helpers/functions";

export const getFormattedResult = result =>
  `Failed items: ${result?.failedItemsCount}, skipped items: ${result?.skippedItemsCount}, succeeded items: ${result?.succeededItemsCount}`;

export const getFormattedMeterFields = json =>
  json?.map(item =>
    getTrimmedObjectValues({
      barcode: !!item.meterBarcode ? item.meterBarcode.toString() : item?.barcode?.toString(),
      serial: item?.serial?.toString(),
      type: !!item.meterType ? item.meterType.toString() : item?.type?.toString(),
      pcb: item?.pcb?.toString(),
      sensorSerial: item?.sensorSerial?.toString(),
      sensorType: item?.sensorType?.toString(),
      iccid: item?.iccid?.toString(),
      embeddedSimCardIccid: item?.embeddedSimCardIccid?.toString(),
      removableSimCardIccid: item?.removableSimCardIccid?.toString(),
      simCardSlotsNumber: Number.parseInt(item?.simCardSlotsNumber),
      simCardTypes: item?.simCardTypes?.toString(),
      firmware: item?.firmware?.toString(),
      controller: !!item.microcontroller ? item.microcontroller.toString() : item?.controller?.toString(),
      batteries: item?.batteries?.toString(),
      batteryModel: item?.batteryModel?.toString(),
      maxBatteryVoltage: Number.parseInt(item?.maxBatteryVoltage),
      minBatteryVoltage: Number.parseInt(item?.minBatteryVoltage),
      calibratedAt: Number.parseInt(item?.calibratedAt),
      manufactureName: item?.manufactureName?.toString(),
      manufactureLocation: item?.manufactureLocation?.toString(),
      modemMac: item?.modemMac?.toString(),
      bleMac: item?.bleMac?.toString(),
      assemblyDate: Number.parseInt(item?.assemblyDate),
      controller2: item?.controller2?.toString(),
      mcu2Firmware: item?.mcu2Firmware?.toString(),
      modemFirmware: item?.mcu2Firmware?.toString(),
      sensorFwVersion: item?.sensorFwVersion?.toString(),
      sensorHwVersion: item?.sensorHwVersion?.toString(),
      batteryCapacity: Number.parseInt(item?.batteryCapacity),
      isFactoryTested: item?.isFactoryTested?.toString().toLowerCase() === "yes",
      isFactoryTestPassed: item?.isFactoryTestPassed?.toString().toLowerCase() === "yes",
    })
  );

export const isMeterFileNoError = data =>
  data.length &&
  data.every(item => handleValidate(item, item.type === "PX-MQTT" ? pxMeterMandatoryFields : p4MeterMandatoryFields));

export const pxMeterMandatoryFields = ["barcode", "pcb", "type", "controller", "assemblyDate"];
export const p4MeterMandatoryFields = [
  "barcode",
  "serial",
  "pcb",
  "type",
  "sensorSerial",
  "simCardSlotsNumber",
  "simCardTypes",
  "firmware",
  "controller",
  "batteryModel",
  "maxBatteryVoltage",
  "minBatteryVoltage",
  "calibratedAt",
];

const handleValidate = (item, requiredFields) => !requiredFields.some(field => !item[field]);

export const getErrorList = form => {
  const errors = [];
  const list = form.getFieldsError();
  list.forEach(item => {
    if (item.errors.length) {
      errors.push(item.name);
    }
  });
  return errors;
};
