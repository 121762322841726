import React, { useCallback, useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { CardBody } from "reactstrap";
import { downloadMeterLog, retrieveMeterDetails } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import NGISelect from "components/Common/inputs/NGISelect";
import { makeStyles } from "@material-ui/core/styles";
import { generateXLS } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    width: "400px!important",
  },
  select: {
    width: 200,
    marginRight: 10,
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
});

const DownloadLogModal = ({ isVisible, onCancel, meterId }) => {
  const classes = useStyles();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selectedLog, setSelectedLog] = useState(null);

  useEffect(() => {
    setDataLoading(true);
    retrieveMeterDetails(meterId)
      .then(async res => {
        const {
          data: { result },
        } = res;
        if (result?.logFiles) setData(result.logFiles);
        else setData(null);
        setDataLoading(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, [meterId]);

  const handleChange = useCallback(e => {
    setSelectedLog(e);
  }, []);

  const exportFile = useCallback(fileData => generateXLS(fileData, selectedLog), [selectedLog]);

  const onSubmit = useCallback(async () => {
    setDataLoading(true);

    await downloadMeterLog(selectedLog)
      .then(async res => {
        setDataLoading(false);
        exportFile(res?.data);
      })
      .catch(err => {
        setDataLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [selectedLog]);

  return (
    <Modal
      className={classes.root}
      title={"Download Meter Log"}
      open={isVisible}
      onCancel={onCancel}
      footer={[]}
      onOk={onSubmit}
    >
      <div>
        <Spin spinning={dataLoading}>
          {data ? (
            <CardBody className={classes.container}>
              <NGISelect
                showSearch
                className={classes.select}
                size="small"
                placeholder="Select meter log file"
                onChange={handleChange}
              >
                {!!data &&
                  data.map(
                    item => (
                      <Option key={item.fileName} value={item.fileName}>
                        {item.fileName}
                      </Option>
                    ),
                    this
                  )}
              </NGISelect>
              <CustomButton
                type="primary"
                htmlType="submit"
                onClick={onSubmit}
                disabled={!selectedLog}
                size="small"
                text="Download"
              />
            </CardBody>
          ) : (
            <CardBody className="text-center">
              <i className="bx bx-warning" />
              <h5>No Logs available</h5>
            </CardBody>
          )}
        </Spin>
      </div>
    </Modal>
  );
};

export default DownloadLogModal;
