import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import { retrieveSMSProvidersAPI, updateSMSProviders } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import CustomButton from "components/Common/buttons/CustomButton";
import Header from "./Header";
import { Row, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGIPrompt from "components/Common/NGIPrompt";
import { USER_TENANT } from "utils/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sectionItem: {
    marginTop: 30,
  },
  informationContainer: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
  },
  column: {
    width: "100%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
  },
  buttonCenter: {
    marginTop: 5,
    marginLeft: 10,
  },
  select: { width: 300 },
});

const SMSProvider = () => {
  const classes = useStyles();

  const [isLoading, setLoader] = useState(false);
  const [availableProviders, setAvailableProviders] = useState(null);
  const [activeProvider, setActiveProvider] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  useEffect(() => {
    setSelectedProvider(activeProvider);
  }, [activeProvider]);

  useEffect(() => {
    getProviders();
  }, []);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const handleProviderChange = useCallback(value => setSelectedProvider(value), []);

  const disableSubmit = useMemo(
    () => !selectedProvider || activeProvider === selectedProvider,
    [activeProvider, selectedProvider]
  );

  const getProviders = useCallback(async () => {
    setLoader(true);
    await retrieveSMSProvidersAPI()
      .then(res => {
        const {
          data: {
            result: { activeProviders, availableProviders },
          },
        } = res;
        setAvailableProviders(availableProviders);
        setActiveProvider(activeProviders[USER_TENANT]);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setAvailableProviders(null);
        setActiveProvider(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const updateProvider = useCallback(async () => {
    setLoader(true);
    await updateSMSProviders({ [USER_TENANT]: selectedProvider })
      .then(() => {
        setLoader(false);
        warnMessage(`Provider was updated successfully`);
        getProviders();
        closeConfirmationModal();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [selectedProvider]);

  return (
    <>
      <NGIPrompt show={!disableSubmit} />
      <div className="page-content">
        <Spin spinning={isLoading}>
          <Container fluid>
            <Header activeProvider={activeProvider} />
            <div className={classes.root}>
              <Card className={classes.container}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.modalContent}>
                    <Row>
                      <NGISelect
                        showSearch
                        className={classes.select}
                        placeholder={"Select new provider"}
                        tokenSeparators={[" ", ","]}
                        onChange={handleProviderChange}
                        value={selectedProvider}
                      >
                        {!!availableProviders &&
                          availableProviders?.map(
                            item => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ),
                            this
                          )}
                      </NGISelect>
                      <Col className={classes.buttonCenter}>
                        <CustomButton
                          disabled={disableSubmit}
                          onClick={openConfirmationModal}
                          type="primary"
                          text="Save"
                          size="small"
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Container>
          {isConfirmationOpen && (
            <ConfirmationModal
              loading={isLoading}
              description={`Are you sure you want to make ${selectedProvider} provider active?`}
              onCancel={closeConfirmationModal}
              onConfirm={updateProvider}
            />
          )}
        </Spin>
      </div>
    </>
  );
};

export default SMSProvider;
