import React, { useCallback, useEffect, useState } from "react";
import { retrieveHardwareReports } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { reportColumns } from "pages/meter-management/meters/meterReportColumns";

const HardwareReports = ({ id }) => {
  const [reportValues, setReportValues] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const onGetHardware = useCallback(
    (options = {}) => {
      setLoading(true);
      retrieveHardwareReports(id, options)
        .then(res => {
          if (res.data.result.items) {
            setReportValues(res.data);
          } else {
            setReportValues([]);
          }
          setLoading(false);
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    onGetHardware();
  }, []);

  return <NGITable isLoading={isLoading} columns={reportColumns} data={reportValues} />;
};

export default HardwareReports;
