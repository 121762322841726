import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Progress, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import { useSelector } from "react-redux";
import CustomButton from "components/Common/buttons/CustomButton";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { createBulkCardApi, downloadFileData, getCardsGenerationStatus } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { makeStyles } from "@material-ui/core/styles";
import { generateXLS, getTrimmedObjectValues } from "utils/helpers/functions";
import { USER_TENANT_NAME } from "utils/constants";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0 10px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  statusContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0 30px 0",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    marginBottom: 24,
  },
  successContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    justifyContent: "space-between",
  },
});

const AddRfidCardsModal = ({ onCancel }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [resultId, setResultId] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [processedCount, setProcessedCount] = useState(0);
  const [downloadLink, setDownloadLink] = useState("");

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const openCancelConfirmationModal = useCallback(() => setIsCancelConfirmationOpen(true), []);
  const closeCancelConfirmationModal = useCallback(() => setIsCancelConfirmationOpen(false), []);

  const onConfirmClosingDialog = useCallback(() => {
    setResultId(null);
    closeCancelConfirmationModal();
    onCancel();
  }, []);

  const onDownload = useCallback(e => {
    downloadFileData(e.target.dataset.url)
      .then(res => {
        generateXLS(res.data?.cards, "Cards Data", "xlsx", false);
      })
      .catch(error => {
        defaultNgiErrorMethod(error);
      });
  }, []);

  const handleCloseModal = useCallback(() => (!!resultId ? openCancelConfirmationModal() : onCancel()), [resultId]);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const handleDataChanged = useCallback(res => {
    !!res?.data?.result?.processedCount && setProcessedCount(res.data.result.processedCount);
  }, []);

  const onRetrieveAsyncProcessStatusResults = useCallback(id => {
    getCardsGenerationStatus(id)
      .then(res => {
        handleDataChanged(res);
        if (res?.data?.result?.processedCount === res?.data?.result?.totalCount) {
          if (res?.data?.result?.errors?.length > 0) {
            warnMessage(`${res.data.result.errors}`);
          } else {
            if (res?.data?.result?.link) {
              setDownloadLink(res?.data?.result?.link);
              successMessage("RFID cards were created successfully", 3);
            }
          }
          setResultId(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    let timeoutID;
    if (resultId) {
      timeoutID = setInterval(() => {
        onRetrieveAsyncProcessStatusResults(resultId);
      }, 30000);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [resultId]);

  const handleSave = useCallback(() => {
    const values = getTrimmedObjectValues(form.getFieldsValue());
    setLoader(true);
    closeConfirmationModal();
    createBulkCardApi({ ...values, count: parseInt(values.count), type: "RFID Card" })
      .then(res => {
        setLoader(false);
        const {
          data: { result },
        } = res;
        setResultId(result.id);
      })
      .catch(error => {
        setLoader(false);
        defaultNgiErrorMethod(error);
      });
  }, []);

  const getCount = useCallback(() => form.getFieldValue("count"), [form]);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithDescription}>
          Generate RFID Cards
          <span>Bulk Card generation</span>
        </div>
      }
      onCancel={handleCloseModal}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm name="addRFIDCard" form={form} onFinish={openConfirmationModal} className={classes.formContainer}>
          <div className={classes.modalContent}>
            <Row className={classes.row}>
              <Col span={11}>
                <Title level={5}>Card Count</Title>
                <NGIFormItem
                  name="count"
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (parseInt(value) > 50000) {
                          return Promise.reject(new Error("Value must be less than or equal to 50000"));
                        } else if (parseInt(value) < 1) {
                          return Promise.reject(new Error("Number of cards to be generated is required."));
                        }
                      },
                    },
                  ]}
                >
                  <NGIInput
                    disabled={!!resultId || downloadLink}
                    type={"number"}
                    min={1}
                    max={50000}
                    placeholder="Enter No. of cards (Max 50000)"
                  />
                </NGIFormItem>
              </Col>
              <Col span={11}>
                <Title level={5}>Depots</Title>
                <Col span={24}>
                  <NGIFormItem name="depot">
                    <NGISelect
                      showSearch
                      disabled={!!resultId || downloadLink}
                      style={{ width: "100%" }}
                      tokenSeparators={[" ", ","]}
                      size="default"
                      placeholder="Select depots"
                    >
                      {depot[USER_TENANT_NAME]?.length > 0 &&
                        depot[USER_TENANT_NAME]?.sort()?.map(
                          item => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ),
                          this
                        )}
                    </NGISelect>
                  </NGIFormItem>
                </Col>
              </Col>
            </Row>
          </div>
        </NGIForm>
        {(!!resultId || downloadLink) && (
          <div className={classes.statusContent}>
            {!downloadLink ? (
              <>
                <Progress
                  type="circle"
                  percent={(processedCount / getCount()) * 100}
                  format={() => `${processedCount}`}
                />
                <h3 style={{ marginTop: 20 }}>Generating Cards</h3>
                <div>Please wait, it might take a few minutes...</div>
              </>
            ) : (
              <div className={classes.successContent}>
                <h5>
                  Card Generated <b>{getCount()} pcs</b>
                </h5>
                <CustomButton
                  data-url={downloadLink}
                  onClick={onDownload}
                  color="primary"
                  size="small"
                  text="Download Cards Data"
                />
              </div>
            )}
          </div>
        )}
        <Space>
          {!downloadLink && (
            <CustomButton onClick={handleCloseModal} type="primary" color="outlined" size="small" text="Cancel" />
          )}
          <CustomButton
            disabled={!!resultId}
            onClick={downloadLink ? handleCloseModal : form.submit}
            htmlType="submit"
            type="primary"
            color={downloadLink ? "outlined" : ""}
            size="small"
            text={downloadLink ? "CLOSE" : "GENERATE"}
          />
        </Space>
      </Spin>
      {isConfirmationOpen && (
        <ConfirmationModal
          description={<span>Do you really want to generate {getCount()} cards?</span>}
          onCancel={closeConfirmationModal}
          onConfirm={handleSave}
        />
      )}
      {isCancelConfirmationOpen && (
        <ConfirmationModal
          description={
            <span>
              The RFID cards generating process has already started.{" "}
              <b>The closing of the popup doesn’t stop the generating</b>. Are you sure?
            </span>
          }
          onCancel={closeCancelConfirmationModal}
          onConfirm={onConfirmClosingDialog}
        />
      )}
    </Modal>
  );
};

export default AddRfidCardsModal;
