import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { fetchCustomerById, fetchCustomerList, fetchDepotsList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { customerListColumns } from "./customerListColumns";
import CustomerSearch from "./CustomerSearch";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { makeStyles } from "@material-ui/core/styles";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import MultipleLinkTSRModal from "pages/customer-management/customers/MultipleLinkTSRModal";
import BulkLinkTSRModal from "pages/customer-management/customers/BulkLinkTSRModal";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { generateXLS, userHasOneOfRoles } from "utils/helpers/functions";
import { getTSRList } from "store/TSR/actions";
import { emptyResponse } from "pages/customer-management/customers/constants";
import NGIDropdownButton from "components/Common/buttons/NGIDropdownButton";
import BulkLinkDepotModal from "pages/customer-management/customers/BulkLinkDepotModal";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import { UsergroupAddOutlined } from "@ant-design/icons";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import downloadIcon from "assets/images/svg/DownloadFromCloud.svg";
import workerIcon from "assets/images/svg/worker.svg";

const useStyles = makeStyles({
  totalItem: {
    textAlign: "right",
    top: -43,
    position: "relative",
    zIndex: 1,
  },
  scoreContainer: {
    padding: "3px 8px",
    borderRadius: 6,
    textAlign: "center",
  },
  Declined: {
    backgroundColor: "#FF0000",
    color: "white",
  },
  Active: {
    backgroundColor: "#17C347",
    color: "white",
  },
});

const Customers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const { filters, setFilters, getFilters } = useStoreFilters(FILTER_PAGE.CUSTOMERS);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showMultipleTSRModal, setShowMultipleTSRModal] = useState(false);
  const [showBulkTSRModal, setShowBulkTSRModal] = useState(false);
  const [showBulkDepotModal, setShowBulkDepotModal] = useState(false);
  const [allDepotsList, setAllDepotsList] = useState([]);

  const hideRoles = [
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.GENERAL,
    USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
    USER_ROLES.BUSINESS_DEV,
    USER_ROLES.FINANCE,
    USER_ROLES.TSS,
    USER_ROLES.METER_ENGINEER,
    USER_ROLES.METER_ENGINEER_LEADERSHIP,
    USER_ROLES.PRODUCT_TECHNICIANS,
  ];

  const handleUpdate = useCallback(() => {
    const filters = getFilters();
    handleSearch(filters);
    setSelectedRowKeys([]);
  }, []);

  useEffect(() => {
    !isEmpty(filters)
      ? handleSearch(filters)
      : handleSearch({ installDateFrom: getStartOfTheDay(moment()), installDateTo: getEndOfTheDay(moment()) });
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    fetchDepotsList({
      pageSize: 1000,
    }).then(res => {
      const {
        result: { items },
      } = res?.data;
      setAllDepotsList(items || []);
    });
    dispatch(getTSRList());
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: {
        items,
        meta: { totalCount },
      },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Customers were not found");
    }
    setTotalCustomers(totalCount || 0);
    setCustomerList(res?.data || emptyResponse);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    if (filterOptions.id) {
      fetchCustomerById(filterOptions.id)
        .then(res => {
          if (res?.data?.result?.status === "Active") {
            setCustomerList({
              result: { items: [res?.data?.result] },
            });
          } else {
            warnMessage("Customers were not found");
            setCustomerList(emptyResponse);
          }
          setTotalCustomers(0);
          setLoader(false);
        })
        .catch(err => {
          setTotalCustomers(0);
          setLoader(false);
          setCustomerList(emptyResponse);
          defaultNgiErrorMethod(err);
        });
      return;
    }

    fetchCustomerList({
      ...filterOptions,
      status: "Active",
    })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setCustomerList(emptyResponse);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => customerListColumns(classes), []);

  const onDownload = useCallback(() => {
    const filters = getFilters();
    fetchCustomerList({
      ...filters,
      pageSize: totalCustomers,
      status: "Active",
    })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        generateXLS(items, "Customers", "csv");
      })
      .catch(e => defaultNgiErrorMethod(e));
  }, [totalCustomers]);

  const closeModal = useCallback(() => {
    setShowMultipleTSRModal(false);
    setShowBulkTSRModal(false);
    setShowBulkDepotModal(false);
  }, []);

  const openMultipleModal = useCallback(() => setShowMultipleTSRModal(true), []);
  const openBulkModal = useCallback(() => setShowBulkTSRModal(true), []);
  const openBulkDepotModal = useCallback(() => setShowBulkDepotModal(true), []);

  const rowSelection = {
    selectedRowKeys,
    onChange: rows => setSelectedRowKeys(rows),
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <ButtonGroupContainer color={"primary"}>
                <ButtonGroup
                  hideRoles={[
                    USER_ROLES.CUSTOMER_CARE,
                    USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                    USER_ROLES.INTERNAL_AUDITOR,
                    USER_ROLES.D_SM,
                    USER_ROLES.A_SM,
                    USER_ROLES.OPERATION_MANAGERS,
                    USER_ROLES.SALES_SUPPORT,
                    USER_ROLES.GENERAL,
                    USER_ROLES.IT_OPERATIONS,
                    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                    USER_ROLES.BUSINESS_DEV,
                    USER_ROLES.FINANCE,
                    USER_ROLES.METER_ENGINEER,
                    USER_ROLES.METER_ENGINEER_LEADERSHIP,
                  ]}
                  color={"primary"}
                  handleClick={onDownload}
                  tooltipTitle="Download"
                  disabled={!totalCustomers}
                  icon={downloadIcon}
                />
                <NGIDropdownButton
                  hideRoles={[
                    USER_ROLES.CUSTOMER_CARE,
                    USER_ROLES.INTERNAL_AUDITOR,
                    USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                    USER_ROLES.GENERAL,
                    USER_ROLES.IT_OPERATIONS,
                    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                    USER_ROLES.BUSINESS_DEV,
                    USER_ROLES.FINANCE,
                    USER_ROLES.TSS,
                    USER_ROLES.METER_ENGINEER,
                    USER_ROLES.METER_ENGINEER_LEADERSHIP,
                    USER_ROLES.PRODUCT_TECHNICIANS,
                  ]}
                  toggleEl={
                    <ButtonGroup color="primary" tooltipTitle="Bulk Link">
                      <UsergroupAddOutlined />
                    </ButtonGroup>
                  }
                  menuItems={[
                    {
                      label: "To TSR",
                      callback: openBulkModal,
                    },
                    {
                      label: "To Depot",
                      callback: openBulkDepotModal,
                      roles: [USER_ROLES.SYSTEM_FULL_ADMINS],
                    },
                  ]}
                />
                {!!selectedRowKeys.length && (
                  <ButtonGroup
                    hideRoles={[
                      USER_ROLES.CUSTOMER_CARE,
                      USER_ROLES.INTERNAL_AUDITOR,
                      USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                      USER_ROLES.IT_OPERATIONS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.GENERAL,
                      USER_ROLES.BUSINESS_DEV,
                      USER_ROLES.METER_ENGINEER,
                      USER_ROLES.METER_ENGINEER_LEADERSHIP,
                      USER_ROLES.PRODUCT_TECHNICIANS,
                    ]}
                    color="primary"
                    tooltipTitle="Link to TSR"
                    handleClick={openMultipleModal}
                    icon={workerIcon}
                  />
                )}
              </ButtonGroupContainer>
            </div>

            <CustomerSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <>
              <NGITable
                filtersType={FILTER_PAGE.CUSTOMERS}
                rowSelection={userHasOneOfRoles(hideRoles) ? null : rowSelection}
                rowKey={record => record.id}
                isLoading={isLoading}
                columns={columns}
                data={customerList}
                updateList={handleSearch}
              />
              {!!totalCustomers && totalCustomers > 0 && (
                <div className={classes.totalItem}>Total number of customers = {totalCustomers}</div>
              )}
            </>
          </Col>
        </Container>
        {showMultipleTSRModal && (
          <MultipleLinkTSRModal onClose={closeModal} customerIds={selectedRowKeys} onUpdate={handleUpdate} />
        )}
        {showBulkTSRModal && <BulkLinkTSRModal onClose={closeModal} onUpdate={handleUpdate} />}
        {showBulkDepotModal && (
          <BulkLinkDepotModal onClose={closeModal} onUpdate={handleUpdate} depotsList={allDepotsList} />
        )}
      </div>
    </>
  );
};

export default Customers;
