import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Input, Modal, Select, Spin, Space } from "antd";
import Title from "antd/lib/typography/Title";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { getDepotInfoLegacyAPI, getRolesInfoLegacyAPI, updateUserLegacyAPI } from "utils/api";
import { Option } from "antd/lib/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { getFormattedKeyName } from "utils/helpers/functions";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const UserModal = ({ isVisible, onClose, item, type, updateData, handleSearch, filter, pageNumber }) => {
  const [form] = Form.useForm();

  const normalizedData = useMemo(() => {
    const { status, depotReferenceId, ipAddress, userName, roleName, fullName, team, depotName, ...data } = item;
    return { ...data, active: status, depotId: depotReferenceId };
  }, [item]);

  const [selectedItem, setSelectedItem] = useState(normalizedData);
  const [isLoading, setLoader] = useState(false);
  const [depots, setDepots] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setLoader(true);
    !!selectedItem && setLoader(false);
  }, [selectedItem]);

  useEffect(() => {
    if (type === "edit") {
      setLoader(true);
      getDepotInfoLegacyAPI()
        .then(async result => {
          const { data } = result;
          setDepots(data);
          setLoader(false);
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
        });
    }
  }, []);

  useEffect(() => {
    if (type === "edit") {
      setLoader(true);
      getRolesInfoLegacyAPI()
        .then(async res => {
          const {
            data: { results },
          } = res;
          setRoles(results);
          setLoader(false);
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
        });
    }
  }, []);

  const onCancel = useCallback(() => onClose(), [form]);
  form.setFieldsValue(selectedItem);

  const onSave = async () => {
    setLoader(true);
    await updateUserLegacyAPI(item.id, selectedItem)
      .then(() => {
        successMessage(`User information was saved successfully`);
        if (!!filter) handleSearch(filter, pageNumber);
        else updateData(pageNumber);
        onClose();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  };

  const onInputChange = useCallback((e, key) => {
    if (key === "active" || key === "depotId" || key === "roleId" || key === "gender")
      setSelectedItem(prevState => ({ ...prevState, [key]: e }));
    else setSelectedItem(prevState => ({ ...prevState, [key]: e.target.value }));
  }, []);

  const style = {
    width: "100%",
    height: "auto",
    position: "relative",
    left: "0",
    top: "0",
    margin: "0",
  };

  return (
    <Modal
      title={` ${type === "edit" ? "Edit User Information" : "View  User Information"}`}
      open={isVisible}
      onCancel={onCancel}
      closable={false}
      footer={[]}
    >
      <>
        <Spin spinning={isLoading}>
          <Form form={form} onFinish={onSave}>
            {type === "view" &&
              Object.keys(selectedItem).map(key => {
                if (key === "status") return null;
                return (
                  <Col key={key} span={24}>
                    <Title level={5}>{getFormattedKeyName(key)}</Title>
                    <Form.Item>
                      <Input disabled value={!!selectedItem[key] ? selectedItem[key] : "-"} />
                    </Form.Item>
                  </Col>
                );
              })}
            {type === "edit" && (
              <>
                <Col span={24}>
                  <Title level={5}>{"First Name"}</Title>
                  <Form.Item name="firstName">
                    <Input
                      pattern={inputTextPattern}
                      title={inputPatternTitle}
                      style={style}
                      id="firstName"
                      value={selectedItem.firstName || ""}
                      onChange={e => onInputChange(e, "firstName")}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Title level={5}>{"Last Name"}</Title>
                  <Form.Item name="lastName">
                    <Input
                      pattern={inputTextPattern}
                      title={inputPatternTitle}
                      style={style}
                      id="lastName"
                      value={selectedItem.lastName || ""}
                      onChange={e => onInputChange(e, "lastName")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5}>{"Gender"}</Title>
                  <Form.Item name="gender">
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      placeholder={"Please select Gender"}
                      onChange={e => onInputChange(e, "gender")}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5}>{"Phone"}</Title>
                  <Form.Item name="phone">
                    <Input
                      pattern={inputTextPattern}
                      title={inputPatternTitle}
                      style={style}
                      id="phone"
                      value={selectedItem.phone || ""}
                      onChange={e => onInputChange(e, "phone")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5}>{"Email"}</Title>
                  <Form.Item name="email">
                    <Input
                      pattern={inputTextPattern}
                      title={inputPatternTitle}
                      style={style}
                      id="email"
                      value={selectedItem.email || ""}
                      onChange={e => onInputChange(e, "email")}
                    />
                  </Form.Item>
                </Col>
                {!!depots?.length && (
                  <Col span={24}>
                    <Title level={5}>{"Depot"}</Title>
                    <Form.Item name="depotId">
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        tokenSeparators={[","]}
                        placeholder={"Please select Depot"}
                        onChange={e => onInputChange(e, "depotId")}
                      >
                        {depots.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {!!roles?.length && (
                  <Col span={24}>
                    <Title level={5}>{"Role"}</Title>
                    <Form.Item name="roleId">
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        tokenSeparators={[","]}
                        placeholder={"Please select Role"}
                        onChange={e => onInputChange(e, "roleId")}
                      >
                        {roles.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.roleName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                <Col span={24}>
                  <Title level={5}>{"Active"}</Title>
                  <Form.Item name="active">
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      placeholder={"Please select status"}
                      onChange={e => onInputChange(e, "active")}
                    >
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            {type === "view" ? (
              <Col>
                <CustomButton onClick={onCancel} type="primary" size="small" color="outlined" text="Close" />
              </Col>
            ) : (
              <Space>
                <CustomButton onClick={onCancel} type="primary" color="outlined" size="small" text="Cancel" />
                <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
              </Space>
            )}
          </Form>
        </Spin>
      </>
    </Modal>
  );
};

export default UserModal;
