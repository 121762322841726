import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Form, Row, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { discardBulkSMS, getBulkSMSDetails, retrieveSMSProvidersAPI, updateBulkSMS, uploadBulkSMSCsv } from "utils/api";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGIInput from "components/Common/inputs/NGIInput";
import { VALIDATION_RULES } from "pages/SMS-management/SMS-bulk/NewBulkSMS/constants";
import NGITimePicker from "components/Common/inputs/NGITimePicker";
import { Card, CardBody, Container } from "reactstrap";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { getSendAt } from "pages/SMS-management/SMS-bulk/utils";
import { useHistory, useParams } from "react-router";
import Header from "pages/SMS-management/SMS-bulk/BulkSMSDetails/Header";
import moment from "moment";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  container: {
    borderRadius: 30,
    padding: 30,
    marginTop: 30,
  },
  cardBody: {
    padding: 0,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col + .ant-col": {
      paddingLeft: 10,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  addNewAsset: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
  select: {
    width: "100%",
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const BulkSMSDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [providers, setProviders] = useState([]);
  const [date, setDate] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [isSchedule, setIsSchedule] = useState(false);
  const [file, setFile] = useState(null);

  const uploadPhoneNumbers = useCallback(
    async upload => {
      if (upload.length) {
        const {
          data: {
            result: { total, fileName },
          },
        } = await uploadBulkSMSCsv(upload);
        return { total, fileName };
      }
      return {
        total: data.total,
        fileName: data.uploadedFile,
      };
    },
    [data]
  );

  const onSave = useCallback(
    async values => {
      try {
        const { provider, sendAt, time, title, message } = values;

        if (!isEmpty(title?.trim())) {
          setIsLoading(true);
          const { total, fileName } = await uploadPhoneNumbers(uploadedData);
          if (!total) {
            errorMessage("Please upload a file with at least 1 phone.");
            setIsLoading(false);
            return;
          }

          const preparedData = {
            title: title?.trim(),
            message,
            sendAt: getSendAt(sendAt, time),
            file: fileName,
            total,
            provider,
          };
          updateBulkSMS(preparedData, data.id, isSchedule)
            .then(() => {
              successMessage("Bulk SMS Template was updates successfully");
              setIsLoading(false);
              history.push("/sms/sms-bulk");
            })
            .catch(err => {
              setIsLoading(false);
              setProviders(null);
              defaultNgiErrorMethod(err);
            });
        }
      } catch (e) {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      }
    },
    [uploadedData, data, isSchedule]
  );

  const onSubmit = useCallback(() => {
    form.submit();
    closeConfirmationModal();
  }, [form]);

  const readUploadFile = useCallback(e => {
    const file = e.file;
    setFile(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        const csv = xlsx.utils.sheet_to_csv(worksheet, { raw: false });

        const formattedResult = json.map(row => ({ ...row }));
        const noError = formattedResult.every(item => item || null);
        !noError && errorMessage("Please upload the correct ‘*.csv’ file.");
        if (noError) {
          setUploadedData(csv);
        } else {
          setUploadedData("");
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const removeFile = useCallback(() => {
    setFile(null);
    setUploadedData([]);
  }, []);

  const handleChangeDate = useCallback(value => {
    form.resetFields(["time"]);
    setDate(value);
  }, []);

  const getProviders = useCallback(async () => {
    setIsLoading(true);
    await retrieveSMSProvidersAPI()
      .then(res => {
        const {
          data: {
            result: { availableProviders },
          },
        } = res;
        setProviders(availableProviders);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setProviders(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const onDiscard = useCallback(id => {
    setIsLoading(true);
    discardBulkSMS(id)
      .then(() => {
        successMessage("Bulk SMS Template was discarded successfully");
        setIsLoading(false);
        history.push("/sms/sms-bulk");
      })
      .catch(err => {
        setIsLoading(false);
        setProviders(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getBulkSMSDetails(id)
      .then(res => {
        const { title, message, sendAt, provider, uploadedFile } = res.data.result;
        const extractedDate = moment(sendAt);
        form.setFieldsValue({
          title,
          message,
          sendAt: extractedDate,
          time: extractedDate,
          provider,
        });
        setDate(extractedDate);
        setData(res.data.result);
        setFile({ name: uploadedFile });
        setIsLoading(false);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setIsLoading(false);
      });

    getProviders();
  }, []);

  const onSchedule = useCallback(() => {
    setIsSchedule(true);
    form.submit();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Spin spinning={isLoading}>
          <Header data={data} scheduleTemplate={onSchedule} discardTemplate={onDiscard} />
          <Card className={classes.container}>
            <CardBody className={classes.cardBody}>
              <NGIForm disabled={data?.status !== "Draft"} name="newBulkSMS" form={form} onFinish={onSave}>
                <Row className={classes.formItem}>
                  <Col span={24}>
                    <Title level={5}>SMS Title</Title>
                    <NGIFormItem name="title" rules={VALIDATION_RULES}>
                      <NGIInput maxLength={50} />
                    </NGIFormItem>
                  </Col>
                </Row>

                <Row className={classes.formItem}>
                  <Col span={24}>
                    <Title level={5}>SMS Message</Title>
                    <NGIFormItem name="message" rules={VALIDATION_RULES}>
                      <NGITextArea maxLength={200} rows={4} placeholder="Add Additional Comments" />
                    </NGIFormItem>
                  </Col>
                </Row>

                <Row className={classes.formItem}>
                  <Col span={6}>
                    <Title level={5}>Sent Date</Title>
                    <NGIFormItem name="sendAt" rules={VALIDATION_RULES}>
                      <NGIDatePicker onChange={handleChangeDate} disabledPasDates allowClear />
                    </NGIFormItem>
                  </Col>
                  <Col span={6}>
                    <Title level={5}>Sent Time</Title>
                    <NGIFormItem name="time" rules={VALIDATION_RULES}>
                      <NGITimePicker date={date} minuteStep={60} showSecond={false} disabledPasDates />
                    </NGIFormItem>
                  </Col>
                  <Col span={12}>
                    <Title level={5}>SMS Provider</Title>
                    <NGIFormItem name="provider">
                      <NGISelect showSearch className={classes.select} placeholder="Select value">
                        {providers?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </NGISelect>
                    </NGIFormItem>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Title level={5}>List of Users *.csv</Title>
                    {file ? (
                      <div className={classes.fileContainer}>
                        <div className={classes.fileTitle}>{`${file.name} (${
                          uploadedData.length ? uploadedData.length : data.total
                        })`}</div>
                        {data?.status === "Draft" && (
                          <div onClick={removeFile} className={classes.removeBtn}>
                            <img src={closeIcon} alt="remove" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                          <img className="upload-bg" src={uploadArea} alt="upload" />
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint">
                            Support for a single upload. Strictly prohibit from uploading company data or other band
                            files
                          </p>
                        </Dragger>
                      </>
                    )}
                  </Col>
                </Row>
              </NGIForm>
              <Divider />
              <br />
              <div className={classes.actions}>
                {data?.status === "Draft" && (
                  <CustomButton type="primary" onClick={openConfirmationModal} text="Save" size="small" />
                )}
              </div>
            </CardBody>
          </Card>
          {isConfirmationOpen && (
            <ConfirmationModal
              description="Are you sure you want to create a new bulk sms template?"
              onCancel={closeConfirmationModal}
              onConfirm={onSubmit}
              loading={isLoading}
            />
          )}
        </Spin>
      </Container>
    </div>
  );
};

export default BulkSMSDetails;
