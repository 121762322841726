import React, { useCallback, useState } from "react";
import { Modal, Space, Spin } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import * as xlsx from "xlsx";
import { uploadMeterLog } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import uploadArea from "assets/images/svg/uploadArea.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});
const UploadLogModal = ({ isVisible, onCancel, meterId }) => {
  const classes = useStyles();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);

  const readUploadFile = e => {
    setDataLoading(true);
    const file = e.file;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: true });

        setFile(file);
        setData(JSON.stringify(json));
        setDataLoading(false);
        successMessage("File Uploaded Successfully");
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(e.file);
    });
  };

  const onSubmit = useCallback(async () => {
    setDataLoading(true);

    await uploadMeterLog(meterId, { fileName: "log", content: data })
      .then(async () => {
        setDataLoading(false);
        onCancel();
        successMessage(`Meter log was uploaded successfully`);
      })
      .catch(err => {
        setDataLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [data]);

  const removeFile = useCallback(() => {
    setData(null);
    setFile(null);
  }, []);

  return (
    <Modal title={"Upload Log"} open={isVisible} onCancel={onCancel} closable={false} footer={[]} onOk={onSubmit}>
      <div>
        <Spin spinning={dataLoading}>
          {file ? (
            <div className={classes.fileContainer}>
              <div className={classes.fileTitle}>{`${file.name}`}</div>
              <div onClick={removeFile} className={classes.removeBtn}>
                <img src={closeIcon} alt="remove" />
              </div>
            </div>
          ) : (
            <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
              <img className="upload-bg" src={uploadArea} alt="upload" />
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibit from uploading company data or other band files
              </p>
            </Dragger>
          )}
          <br />
          <Space>
            <CustomButton onClick={onCancel} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton onClick={onSubmit} htmlType="submit" size="small" type="primary" text="Save" />
          </Space>
        </Spin>
      </div>
    </Modal>
  );
};

export default UploadLogModal;
