import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { TABS, TABS_BUTTONS } from "pages/meter-management/meters/meterDetails/constants";
import GeneralInformationTab from "pages/meter-management/meters/meterDetails/Tabs/GeneralInformationTab";
import SendCommandTab from "pages/meter-management/meters/meterDetails/Tabs/SendCommandTab";
import GetMeterStatusTabs from "pages/meter-management/meters/meterDetails/Tabs/getMeterStatus/GetMeterStatusTabs";
import HistoryTab from "pages/meter-management/meters/meterDetails/Tabs/historyTab/HistoryTab";
import { USER_ROLES } from "utils/constants";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
});

const DataTabs = ({ data }) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(TABS.GENERAL_INFORMATION);

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {TABS_BUTTONS.map(item => {
          if (item?.hideRoles && !userHasAdminRole() && userHasOneOfRoles(item?.hideRoles)) return null;
          return (
            <div
              key={item.key}
              className={classnames(classes.tab, activeTab === item.key && classes.active)}
              data-tab={item.key}
              onClick={onTabClick}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      <Card className={classes.container}>
        <CardBody>
          {activeTab === TABS.GENERAL_INFORMATION && <GeneralInformationTab data={data} />}
          {activeTab === TABS.CYLINDER_INSTALL && (
            <SendCommandTab hideRoles={[USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.GENERAL]} data={data} />
          )}
          {activeTab === TABS.GET_METER_STATUS && <GetMeterStatusTabs id={data.id} />}
          {activeTab === TABS.HISTORY && <HistoryTab data={data} />}
        </CardBody>
      </Card>
    </div>
  );
};

export default DataTabs;
