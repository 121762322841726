import React from "react";
import { Spin } from "antd";
import { Card } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    borderRadius: 30,
    padding: 30,
  },
  hideContainer: {
    background: "transparent",
    boxShadow: "none",
  },
  fullscreen: {
    width: "100%",
  },
});

const NGISpinner = ({ hideContainer = false, fullscreen = false }) => {
  const classes = useStyles();

  return (
    <Card
      className={classnames(
        classes.loaderContainer,
        hideContainer && classes.hideContainer,
        fullscreen && classes.fullscreen
      )}
    >
      <Spin spinning={true} />
    </Card>
  );
};

export default NGISpinner;
