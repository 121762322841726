import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Divider, Modal, Space, Spin, Form, Collapse } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { makeStyles } from "@material-ui/core/styles";
import {
  customDateFormatWithoutTime,
  customDateFormatWithTime,
  customTimeFormat,
  textFormatter,
} from "components/Common/formatter";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGITimeRangePicker from "components/Common/inputs/NGITimeRangePicker";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGIForm from "components/Common/form/NGIForm";
import moment from "moment";
import { fetchCustomerById, setCustomerAvailability } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { CLASSIFICATION } from "pages/operations/routineDetails/constants";
import TaskItem from "pages/operations/routineDetails/TaskItem";
import { formatPhoneNumber } from "utils/helpers/functions";
import classnames from "classnames";
import CustomLink from "components/Common/buttons/CustomLink";

const { Panel } = Collapse;

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    textAlign: "left",
    justifyContent: "space-between",
  },
  container: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 30,
    marginTop: "-30px",
    paddingBottom: 20,
  },
  nameContainer: {
    display: "flex",
    fontWeight: 600,
  },
  modal: {
    maxWidth: "900px!important",
  },
  phones: {
    display: "flex",
    flexWrap: "wrap",
  },
  marginL24: {
    marginLeft: 24,
  },
  marginB10: {
    marginBottom: 10,
  },
  id: {
    marginLeft: 10,
    color: "gray",
  },
  textRed: {
    color: "red",
  },
  cookInfo: {
    marginRight: 5,
    marginTop: 5,
    display: "flex",
    "&>span": {
      fontWeight: 300,
      marginRight: 5,
    },
  },
  formItem: {
    marginInline: 10,
  },
  subtitle: {
    fontWeight: 300,
    fontSize: 12,
    color: "grey",
    marginRight: 10,
    textTransform: "uppercase",
  },
  noInfo: {
    textAlign: "center",
  },
});

const DetailsModal = ({ customer, onClose, isOpen, updateRoutine }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [date, setDate] = useState(moment(customer?.availableFrom || undefined));
  const [phones, setPhones] = useState([]);
  const isNotReachable = customer.classification?.includes(CLASSIFICATION.NOT_REACHABLE);

  const openConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const handleChangeDate = useCallback(value => {
    form.resetFields(["timeRange"]);
    setDate(value);
    form.setFieldValue("date", value);
  }, []);

  const markNotReachable = useCallback(() => {
    setLoader(true);
    setCustomerAvailability(customer.id, { unreachable: true })
      .then(() => {
        successMessage(`Customer is marked as ‘Not Reachable’ for today`);
        setLoader(false);
        onClose();
        updateRoutine();
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [customer]);

  const handleSave = useCallback(
    values => {
      const [timeStart, timeEnd] = values.timeRange;
      const from = moment(date);
      const to = moment(date);
      from.set({ hour: moment(timeStart).hour(), minute: moment(timeStart).minute(), second: 0, millisecond: 0 });
      to.set({ hour: moment(timeEnd).hour(), minute: moment(timeEnd).minute(), second: 0, millisecond: 0 });

      const data = {
        unreachable: false,
        availableFrom: moment(from).valueOf(),
        availableTo: moment(to).valueOf(),
      };

      setLoader(true);
      setCustomerAvailability(customer.id, data)
        .then(() => {
          successMessage(`Customer Availability is set successfully`);
          setLoader(false);
          onClose();
          updateRoutine();
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoader(false);
        });
    },
    [date, customer]
  );

  const retrieveCustomer = useCallback(() => {
    setLoader(true);
    fetchCustomerById(customer.id)
      .then(({ data }) => {
        const phones = [
          { name: "Primary Phone", value: data.result?.phone },
          { name: "Secondary Phone", value: data.result?.secondaryPhone },
          { name: "Close Relative Phone", value: data.result?.closeRelativePhone },
        ];
        const transactionNumbers = data.result?.payeePhoneNumbers?.map(number => ({
          name: "Transaction",
          value: number,
        }));
        !!transactionNumbers?.length && phones.push(...transactionNumbers);

        setPhones(phones.filter(item => item.value));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    retrieveCustomer();
    if (customer) {
      form.setFieldsValue({
        ...(customer.availableFrom && { timeRange: [moment(customer.availableFrom), moment(customer.availableTo)] }),
        date: moment(customer.availableFrom || undefined),
      });
    }
  }, [customer]);

  return (
    <Modal
      className={classes.modal}
      title={
        <div>
          <div className={classes.titleWithDescription}>
            <div className={classes.nameContainer}>
              {`${customer.name}`}
              <span className={classes.id}>
                (ID{" "}
                <CustomLink underlined target="_blank" to={`/customers/customer/${customer.id}`}>
                  {customer.id}
                </CustomLink>
                )
              </span>
            </div>
            <span>
              AVAILABILITY{" "}
              {isNotReachable ? (
                <span className={classes.textRed}>NOT REACHABLE</span>
              ) : (
                <span>
                  {!!customer.availableFrom
                    ? `${customDateFormatWithTime(customer.availableFrom, false)} - ${customTimeFormat(
                        customer.availableTo
                      )}`
                    : "TIME SLOT NOT SET"}
                </span>
              )}
            </span>
          </div>
          <div className={classes.cookInfo}>
            <div className={classes.cookInfo}>
              <span>LAST COOKED:</span>{" "}
              <b>{customer.lastCookedAt ? customDateFormatWithoutTime(customer.lastCookedAt) : textFormatter()}</b>
            </div>
            <div className={classes.cookInfo}>
              <span>DAYS SINCE LAST COOKING:</span> <b>{textFormatter(customer.daysSinceLastCooking)}</b>
            </div>
            <div className={classes.cookInfo}>
              <span>DAYS LEFT TO NO COOK:</span> <b>{textFormatter(customer.daysToNoCook)}</b>
            </div>
          </div>
          <Divider />
        </div>
      }
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.container}>
          <TitleWithDivider title="Schedule Availability" />
          <NGIForm onFinish={handleSave} form={form} name={"customer-availability"}>
            <Row className="space-between">
              <Col span={12}>
                <NGIFormItem name="date" rules={[{ required: true, message: "Please select date" }]}>
                  <NGIDatePicker placeholder="Start Date" disabledPasDates allowClear onChange={handleChangeDate} />
                </NGIFormItem>
              </Col>

              <Col span={11}>
                <NGIFormItem
                  name="timeRange"
                  rules={[
                    { required: true, message: "Please select time" },
                    {
                      message: "The time should be between 7:00 and 19:00",
                      validator: (_, value) => {
                        const startTime = moment(value[0], "HH:mm");
                        const endTime = moment(value[1], "HH:mm");
                        const minTime = moment("6:59", "HH:mm");
                        const maxTime = moment("19:01", "HH:mm");
                        if (endTime.isBefore(maxTime) && startTime.isAfter(minTime)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("The maximum value for the End Time is 19:00");
                      },
                    },
                  ]}
                >
                  <NGITimeRangePicker date={date} minuteStep={30} showSecond={false} disabledPasDates />
                </NGIFormItem>
              </Col>
            </Row>
            <Row className="space-between">
              <CustomButton
                disabled={isNotReachable}
                onClick={openConfirmationModal}
                type="error"
                size="small"
                color="danger"
                text="Not Reachable"
              />
              <CustomButton htmlType="submit" type="primary" size="small" text="Schedule" />
            </Row>
          </NGIForm>

          <TitleWithDivider title="Customer Phone Numbers" />
          {phones.length ? (
            <Collapse ghost collapsible="icon">
              <Panel
                key={"phones"}
                showArrow={phones.length > 4}
                header={
                  <div className={classes.phones}>
                    {phones.map(
                      (phone, i) =>
                        i < 4 &&
                        phone.value && (
                          <Col span={6} key={phone.value}>
                            <div className={classes.nameContainer}>{formatPhoneNumber(String(phone.value))}</div>
                            <div className={classes.subtitle}>{phone.name}</div>
                          </Col>
                        )
                    )}
                  </div>
                }
              >
                <div className={classnames(classes.phones, classes.marginL24)}>
                  {phones.map(
                    (phone, i) =>
                      i >= 4 &&
                      phone.value && (
                        <Col span={6} key={phone.value} className={classes.marginB10}>
                          <div className={classes.nameContainer}>{formatPhoneNumber(String(phone.value))}</div>
                          <div className={classes.subtitle}>{phone.name}</div>
                        </Col>
                      )
                  )}
                </div>
              </Panel>
            </Collapse>
          ) : (
            <div>
              {customer.phone ? (
                <Col span={6} className={classes.marginB10}>
                  <div className={classes.nameContainer}>{formatPhoneNumber(customer.phone)}</div>
                  <div className={classes.subtitle}>PRIMARY PHONE</div>
                </Col>
              ) : (
                <div className={classes.noInfo}>
                  <b>No information</b>
                </div>
              )}
            </div>
          )}

          <TitleWithDivider title="List of Tasks" />
          {customer?.tasks?.length ? (
            <div>
              {customer.tasks.map(task => (
                <TaskItem key={task.id} task={task} />
              ))}
            </div>
          ) : (
            <div className={classes.noInfo}>
              <b>No tasks</b>
            </div>
          )}
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Close Popup" />
        </Space>
      </Spin>
      {isConfirmationOpen && (
        <ConfirmationModal
          description={<span>Do you really want to mark customer as ‘Not Reachable’ for today?</span>}
          onCancel={closeConfirmationModal}
          onConfirm={markNotReachable}
        />
      )}
    </Modal>
  );
};

export default DetailsModal;
