const validateObj = {
  data: null,
  valid: false,
};
export const getIsValidForm = (data, type) => {
  if (!data.amountType) return validateObj;
  if (data.amountType === "Fixed Amount" && !data.amount) return validateObj;
  switch (type) {
    case "Consumption":
      if (Boolean(data?.consumptionFrom && data?.consumptionTo)) {
        return {
          valid: true,
          data: {
            values: [
              { value: parseInt(data?.consumptionFrom), type: "Min" },
              { value: parseInt(data?.consumptionTo), type: "Max" },
            ],
            ...((data?.meterBalanceMin || data?.meterBalanceMax) && {
              meterBalance: {
                ...(data?.meterBalanceMin && { min: parseInt(data?.meterBalanceMin) }),
                ...(data?.meterBalanceMax && { max: parseInt(data?.meterBalanceMax) }),
              },
            }),
            ...((data?.cookedDaysMin || data?.cookedDaysMax) && {
              cookedDays: {
                ...(data?.cookedDaysMin && { min: parseInt(data?.cookedDaysMin) }),
                ...(data?.cookedDaysMax && { max: parseInt(data?.cookedDaysMax) }),
              },
            }),
            amountType: data.amountType,
            ...(data.amountType === "Fixed Amount" ? { amount: parseInt(data.amount) } : {}),
            ...(data.amountType === "Fixed Amount"
              ? { amount: parseInt(data.amount) }
              : { multiplier: parseInt(data?.multiplier) }),
          },
        };
      }
      return validateObj;
    case "CSV":
    case "CustomerID":
      if (Boolean(data?.users)) {
        return {
          valid: true,
          data: {
            values: Array.isArray(data?.users) ? data?.users : [...data?.users.split(",")],
            ...((data?.meterBalanceMin || data?.meterBalanceMax) && {
              meterBalance: {
                ...(data?.meterBalanceMin && { min: parseInt(data?.meterBalanceMin) }),
                ...(data?.meterBalanceMax && { max: parseInt(data?.meterBalanceMax) }),
              },
            }),
            ...((data?.cookedDaysMin || data?.cookedDaysMax) && {
              cookedDays: {
                ...(data?.cookedDaysMin && { min: parseInt(data?.cookedDaysMin) }),
                ...(data?.cookedDaysMax && { max: parseInt(data?.cookedDaysMax) }),
              },
            }),
            amountType: data.amountType,
            ...(data.amountType === "Fixed Amount"
              ? { amount: parseInt(data.amount) }
              : { multiplier: parseInt(data?.multiplier) }),
          },
        };
      }
      return validateObj;
    case "Depots":
      if (Boolean(data?.depots?.length)) {
        return {
          valid: true,
          data: {
            values: data?.depots,
            ...((data?.meterBalanceMin || data?.meterBalanceMax) && {
              meterBalance: {
                ...(data?.meterBalanceMin && { min: parseInt(data?.meterBalanceMin) }),
                ...(data?.meterBalanceMax && { max: parseInt(data?.meterBalanceMax) }),
              },
            }),
            ...((data?.cookedDaysMin || data?.cookedDaysMax) && {
              cookedDays: {
                ...(data?.cookedDaysMin && { min: parseInt(data?.cookedDaysMin) }),
                ...(data?.cookedDaysMax && { max: parseInt(data?.cookedDaysMax) }),
              },
            }),
            amountType: data.amountType,
            ...(data.amountType === "Fixed Amount"
              ? { amount: parseInt(data.amount) }
              : { multiplier: parseInt(data?.multiplier) }),
          },
        };
      }
      return validateObj;
    default:
      return false;
  }
};
