import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import userPreviewIcon from "../../../assets/images/svg/userPreview.svg";
import calendarIcon from "../../../assets/images/svg/calendarMinus.svg";
import depotIcon from "../../../assets/images/svg/depot.svg";
import depotWhiteIcon from "../../../assets/images/svg/depot-white.svg";
import workerIcon from "../../../assets/images/svg/worker.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import { ACTION_TYPE } from "pages/customer-management/customers/constants";
import { Tooltip } from "antd";
import classnames from "classnames";
import { customDateFormatWithoutTime, textFormatter } from "components/Common/formatter";
import { USER_ROLES } from "utils/constants";
import { useLocation } from "react-router";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { STATUSES } from "pages/customer-management/customerDetails/constants";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
      maxWidth: 200,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  iconContainer: {
    padding: 8,
    marginLeft: 23,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 65,
    minWidth: 65,
    height: 65,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});
const color = "#448aff";

const Header = ({ customer, photoDetails, onAction }) => {
  const classes = useStyles();
  const { firstName, lastName, status, priceSegment, dob, depot, installationDate, sector } = customer;
  const handleBtnClick = useCallback(e => onAction(e.currentTarget.dataset.type), []);

  const searchParams = useLocation().search;
  const backUrl = new URLSearchParams(searchParams).get("backUrl");
  const backButtonUrl = useMemo(() => (backUrl ? backUrl : "/customers/customers/active"), [backUrl]);

  const hideRoles = [USER_ROLES.CUSTOMER_CARE, USER_ROLES.INTERNAL_AUDITOR];

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={backButtonUrl}>Back</BackButtonLink>

        <div className={classes.iconContainer}>
          <img src={photoDetails?.personalPhotoUrl || userPreviewIcon} alt="customer" />
        </div>
        <div className={classes.nameContainer}>
          <span>{firstName && `${firstName} ${lastName}`}</span>
          {status && <div className={classes.status}>{status}</div>}
        </div>
      </div>

      <div className={classes.rightContainer}>
        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <div>
              <div className={classes.title}>CUSTOMER SEGMENT</div>
              <div className={classes.data}>{priceSegment || "-"} </div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <img src={calendarIcon} alt="date of birth" />
            <div>
              <div className={classes.title}>DATE OF BIRTH</div>
              <div className={classes.data}>{dob}</div>
            </div>
          </div>
          <div className={classes.divider} />
          {!!installationDate && (
            <>
              <div className={classes.section}>
                <img src={calendarIcon} alt="depot" />
                <div>
                  <div className={classes.title}>INSTALLATION DATE</div>
                  <div className={classnames(classes.data, classes.overflowText)} title={installationDate}>
                    {customDateFormatWithoutTime(installationDate)}
                  </div>
                </div>
              </div>
              <div className={classes.divider} />
            </>
          )}
          <div className={classes.section}>
            <img src={depotIcon} alt="depot" />
            <div>
              <div className={classes.title}>DEPOT</div>
              <div className={classnames(classes.data, classes.overflowText)} title={depot}>
                {depot}
              </div>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.section}>
            <div>
              <div className={classes.title}>SECTOR</div>
              <div className={classnames(classes.data, classes.overflowText)} title={sector}>
                {textFormatter(sector)}
              </div>
            </div>
          </div>
        </div>
        {userHasOneOfRoles(hideRoles) ? null : (
          <div className={classes.actionsContainer}>
            {/*hide change package temporary*/}

            {/*<div className={classes.iconButton} data-type={ACTION_TYPE.ASSIGN_PACKAGE} onClick={handleBtnClick}>*/}
            {/*  <Tooltip placement="bottom" title="Assign package" color={color}>*/}
            {/*    <img src={cardboardBoxIcon} alt="change package" />*/}
            {/*  </Tooltip>*/}
            {/*</div>*/}
            {/*<div className={classes.divider} />*/}

            {status && status !== STATUSES.REPOSSESSED && status !== STATUSES.SUSPENDED && (
              <>
                {!userHasOneOfRoles([
                  USER_ROLES.SALES_SUPPORT,
                  USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                  USER_ROLES.CUSTOMER_CARE,
                  USER_ROLES.IT_OPERATIONS,
                  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                  USER_ROLES.FINANCE,
                  USER_ROLES.GENERAL,
                  USER_ROLES.METER_ENGINEER,
                  USER_ROLES.METER_ENGINEER_LEADERSHIP,
                  USER_ROLES.PRODUCT_TECHNICIANS,
                ]) && (
                  <>
                    <div className={classes.iconButton} data-type={ACTION_TYPE.ASSIGN_DEPOT} onClick={handleBtnClick}>
                      <Tooltip placement="bottom" title="Assign depot" color={color}>
                        <img src={depotWhiteIcon} alt="change depot" />
                      </Tooltip>
                    </div>
                  </>
                )}

                {!userHasOneOfRoles([
                  USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                  USER_ROLES.CUSTOMER_CARE,
                  USER_ROLES.IT_OPERATIONS,
                  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                  USER_ROLES.FINANCE,
                  USER_ROLES.GENERAL,
                  USER_ROLES.METER_ENGINEER,
                  USER_ROLES.METER_ENGINEER_LEADERSHIP,
                  USER_ROLES.PRODUCT_TECHNICIANS,
                ]) && (
                  <>
                    <div className={classes.divider} />
                    <div className={classes.iconButton} data-type={ACTION_TYPE.LINK_TSR} onClick={handleBtnClick}>
                      <Tooltip placement="bottom" title="Link to TSR" color={color}>
                        <img src={workerIcon} alt="change tsr" />
                      </Tooltip>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
