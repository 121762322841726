import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import Header from "./Header";
import DataTabs from "./DataTabs";
import { fetchTeamsList } from "utils/api";
import { Spin } from "antd";
import { isEmpty } from "lodash";

const TeamDetails = () => {
  const [isLoading, setLoader] = useState(false);
  const { id } = useParams();
  const [team, setTeam] = useState({});

  useEffect(() => {
    const [name, teamDepot] = id.split("+");
    setLoader(true);
    fetchTeamsList({ name, teamDepot }).then(({ data }) => {
      setLoader(false);
      const teamData = data.result.items.find(item => item.name === name && item.depot === teamDepot);
      setTeam(teamData);
    });
  }, []);

  return (
    <div className="page-content">
      <Spin spinning={isLoading}>
        <Container fluid>
          {!isEmpty(team) && (
            <>
              <Header team={team} />
              <DataTabs team={team} />
            </>
          )}
        </Container>
      </Spin>
    </div>
  );
};

export default TeamDetails;
