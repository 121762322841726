import React, { useCallback } from "react";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { filterOptions } from "pages/meter-management/awaitingConfigurationMeters/variables";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const MetersFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.AWAITING_TESTING_CONFIG);
  const onSearch = useCallback(values => {
    const { type, value } = values;
    const { pageSize } = getFilters();
    handleFilter({ [type]: value?.trim(), pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select showSearch placeholder={"Select type"} allowClear>
            {filterOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />

        <Form.Item name="value">
          <Input placeholder="Enter value" allowClear pattern={inputTextPattern} title={inputPatternTitle} />
        </Form.Item>

        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default MetersFilter;
