import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import moment from "moment";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { dateFormat, getStatusList, searchOptions } from "pages/stock-management/stockTransferOrders/constants";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { makeStyles } from "@material-ui/core/styles";
import { customDateFormatWithoutTime } from "components/Common/formatter";

const { Option } = Select;
const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  root: { width: "250px!important" },
});
const STOSearch = ({ handleSearch }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.STO);
  const { viewType } = getFilters();

  const {
    list: { assetTypeGroup },
  } = useSelector(DictionariesSelector);

  const statusList = useMemo(() => getStatusList(viewType), [viewType]);

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (filters?.createdAtFrom) {
        form.setFieldsValue({
          type: "date",
          date: [moment(filters?.fromPreferredDate), moment(filters?.toPreferredDate)],
          value: null,
        });
        setSelectedFilter("date");
      }
      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: null,
        value: null,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();

    if (values?.type === "date") {
      const filter = { pageSize };
      filter.fromPreferredDate = customDateFormatWithoutTime(values?.date[0]);
      filter.toPreferredDate = customDateFormatWithoutTime(values?.date[1]);
      handleSearch(filter);
    } else {
      !(values.value && !values.type) &&
        handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
    }
  }, []);

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <>
          {(selectedFilter === "stoId" || selectedFilter === "tripId") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value">
                <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
              </Form.Item>
            </>
          )}

          {selectedFilter === "stoStatus" && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value">
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                  {statusList.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          {selectedFilter === "assetGroup" && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value">
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                  {assetTypeGroup.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          {selectedFilter === "date" && (
            <>
              <div className="filter-divider" />
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Please select date range" }]}
                className={classes.root}
              >
                <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
              </Form.Item>
            </>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default STOSearch;
