import { textFormatter } from "components/Common/formatter";

export const getRepossessionReasons = reasons => {
  try {
    if (!Array.isArray(reasons)) {
      const getValues = data => {
        const key = Object.keys(data)[0];
        result.push(key);
        if (typeof data[key] === "object") {
          getValues(data[key]);
        } else {
          result.push(data[key]);
        }
      };
      const result = [];
      getValues(reasons);
      return result.join(" > ");
    }
    return textFormatter(reasons?.map((item, i) => (i === reasons?.length - 1 ? item : `${item}, `)));
  } catch (e) {
    return "";
  }
};
