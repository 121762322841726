import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { uniq } from "lodash";
import { searchOptions } from "./constants";

const { Option } = Select;

const VehicleSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.VEHICLE);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const depotList = useMemo(
    () =>
      userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])
        ? depot && depot[USER_TENANT_NAME] && uniq(depot[USER_TENANT_NAME])?.sort()
        : [AUTH_USER?.depotName],
    [depot]
  );

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];

      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: null,
        value: null,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    !(values.value && !values.type) &&
      handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
  }, []);

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select Search Type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <>
          {(selectedFilter === "qrCode" || selectedFilter === "licencePlate") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value">
                <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
              </Form.Item>
            </>
          )}

          {selectedFilter === "depot" && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value">
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                  {depotList.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default VehicleSearch;
