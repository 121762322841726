import { textFormatter } from "components/Common/formatter";
import { Row, Tag } from "antd";
import React from "react";
import CustomLink from "components/Common/buttons/CustomLink";

export const firmwareTableColumns = [
  {
    title: "Version",
    dataIndex: "version",
    key: "version",
    render: (cellContent, row) => (
      <Row wrap={false}>
        {textFormatter(cellContent)}
        {!!row.isDefaultVersion && (
          <Tag style={{ marginLeft: 5 }} color={"green"}>
            DEFAULT
          </Tag>
        )}
      </Row>
    ),
  },
  {
    title: "Sensor Type",
    dataIndex: "sensorType",
    key: "sensorType",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "File Type",
    dataIndex: "fileType",
    key: "fileType",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Generation",
    dataIndex: "generation",
    key: "generation",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Microcontroller Type",
    dataIndex: "microcontrollerType",
    key: "microcontrollerType",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    key: "updatedBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Release At",
    dataIndex: "releaseAt",
    key: "releaseAt",
    render: cellContent => textFormatter(cellContent),
  },
];

export const FIRMWARE_GEN_LIST = ["P4", "P5", "P6", "P7", "P8", "PX"];
export const dateFormat = "YYYY-MM-DD";
