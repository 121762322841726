import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import userPreviewIcon from "assets/images/svg/userPreview.svg";
import calendarIcon from "assets/images/svg/calendarMinus.svg";
import unavailabilityIcon from "assets/images/svg/calendarIcon.svg";
import depotIcon from "assets/images/svg/depot.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import { getFormattedDataByName } from "utils/helpers/functions";
import SetUserUnavailabilityModal from "pages/user-management/users/SetUserUnavailabilityModal";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import { textFormatter } from "components/Common/formatter";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
      maxWidth: 200,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  iconContainer: {
    padding: 8,
    marginLeft: 23,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 65,
    minWidth: 65,
    height: 65,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ user, photoDetails, updateUserData }) => {
  const classes = useStyles();
  const { name, status, updatedAt, depot, team, sector } = user;
  const [unavailabilityModalVisible, setUnavailabilityModalVisible] = useState(false);

  const openUnavailabilityModal = useCallback(() => setUnavailabilityModalVisible(true), []);
  const closeUnavailabilityModal = useCallback(() => setUnavailabilityModalVisible(false), []);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/users/list"}>Back</BackButtonLink>

        <div className={classes.iconContainer}>
          <img src={photoDetails?.personalPhotoUrl || userPreviewIcon} alt="customer" />
        </div>
        <div className={classes.nameContainer}>
          <span>{name}</span>
          {status && <div className={classes.status}>{status}</div>}
        </div>
      </div>

      <div className={classes.rightContainer}>
        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <img src={calendarIcon} alt="updated at" />
            <div>
              <div className={classes.title}>UPDATED AT</div>
              <div className={classes.data}>{getFormattedDataByName("updatedAt", updatedAt)}</div>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.section}>
            <img src={depotIcon} alt="depot" />
            <div>
              <div className={classes.title}>DEPOT</div>
              <div className={classnames(classes.data, classes.overflowText)} title={depot}>
                {depot}
              </div>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.section}>
            <div>
              <div className={classes.title}>SECTOR</div>
              <div className={classnames(classes.data, classes.overflowText)} title={sector}>
                {textFormatter(sector)}
              </div>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.section}>
            <div>
              <div className={classes.title}>TEAM</div>
              <div className={classnames(classes.data, classes.overflowText)} title={team}>
                {textFormatter(team)}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actionsContainer}>
          <ButtonGroupContainer color={"primary"}>
            <ButtonGroup
              roles={[
                USER_ROLES.SYSTEM_FULL_ADMINS,
                USER_ROLES.OPERATION_MANAGERS,
                USER_ROLES.A_SM,
                USER_ROLES.SALES_SUPPORT,
                USER_ROLES.TSS,
              ]}
              disabled={depot !== AUTH_USER?.depotName}
              color={"primary"}
              handleClick={openUnavailabilityModal}
              tooltipTitle="Set User unavailability"
              icon={unavailabilityIcon}
            />
          </ButtonGroupContainer>
        </div>
      </div>
      {unavailabilityModalVisible && (
        <SetUserUnavailabilityModal user={user} handleCancel={closeUnavailabilityModal} onSuccess={updateUserData} />
      )}
    </div>
  );
};

export default Header;
