import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Divider, Form, Row, Select, Spin } from "antd";
import { ACCESSORY_TYPES, MAC_ADDRESS_VALIDATION_RULES, VALIDATION_RULES } from "./constants";
import Header from "./Header";
import Title from "antd/lib/typography/Title";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { bulkUploadMeters, fetchFirmwares, getAccessoryByType } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import { useHistory } from "react-router";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import moment from "moment/moment";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getFormattedResult } from "pages/meter-management/configuration/meters/utils";
import NGICheckbox from "components/Common/inputs/NGICheckbox";
import { extractPromisesResult, getTrimmedObjectValues, handleFocusChange } from "utils/helpers/functions";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { NO_SPACE_PATTERN } from "pages/meter-management/configuration/meters/variables";
import NGIPrompt from "components/Common/NGIPrompt";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
  sectionItem: {
    marginTop: 30,
  },
  titleWithAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const AddManualMeter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoader] = useState(false);
  const [meterDisabled, setMeterDisabled] = useState(true);
  const [simDisabled, setSimDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [isPXType, setIsPXType] = useState(false);
  const [dictionaries, setDictionaries] = useState({
    sensors: [],
    microcontrollers: [],
    firmwares: [],
  });

  useEffect(() => {
    Promise.allSettled([
      getAccessoryByType(ACCESSORY_TYPES.SENSOR),
      getAccessoryByType(ACCESSORY_TYPES.MICROCONTROLLER),
      fetchFirmwares(),
    ]).then(data => {
      const [sensors, microcontrollers, firmwares] = extractPromisesResult(data);
      setDictionaries({
        sensors: sensors?.data?.result,
        microcontrollers: microcontrollers?.data?.result,
        firmwares: firmwares?.data?.result,
      });
    });
    dispatch(getDictionary(DICTIONARY_TYPES.UPLOAD_TEMPLATE));
    dispatch(getDictionary(DICTIONARY_TYPES.BATTERY_MODEL));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_TYPE));
    dispatch(getDictionary(DICTIONARY_TYPES.SIM_CARD_TYPE));
  }, []);

  const [form] = Form.useForm();
  const {
    list: { meterUploadTemplate, batteryModel, meterType, simCardType },
  } = useSelector(DictionariesSelector);

  const onSubmit = useCallback(
    values => {
      setLoader(true);
      setIsDirty(false);
      const normalizedData = {
        items: [
          {
            ...getTrimmedObjectValues(values),
            calibratedAt: values?.calibratedAt?.valueOf(),
            assemblyDate: values?.assemblyDate?.valueOf(),
            simCardTypes: values?.simCardTypes?.toString(),
            ...(values?.ip?.length && { ip: values?.ip }),
            ...((values.battery1 || values.battery2) && { batteries: [values.battery1, values.battery2].join() }),
          },
        ],
      };

      Object.keys(normalizedData.items[0]).forEach(key => {
        if (!normalizedData.items[0][key]) {
          delete normalizedData.items[0][key];
        }
      });

      bulkUploadMeters("assemblies", normalizedData)
        .then(async ({ data }) => {
          setLoader(false);
          successMessage(`Meters were uploaded successfully, ${getFormattedResult(data?.result)}`);
          history.push("/meters/registration/manual");
        })
        .catch(err => {
          setLoader(false);
          defaultNgiErrorMethod(err);
        });
    },
    [history]
  );

  const handleChange = useCallback(
    template => {
      form.resetFields();
      onFieldsChanged();
      const selectedItem = meterUploadTemplate?.find(item => item.name === template);
      form.setFieldsValue({
        ...selectedItem.content,
        template,
        calibratedAt: selectedItem.content.calibratedAt ? moment(selectedItem.content.calibratedAt) : undefined,
        assemblyDate: moment(selectedItem.content.assemblyDate),
        simCardTypes:
          typeof selectedItem.content.simCardTypes === "string"
            ? selectedItem.content.simCardTypes.split(",")
            : selectedItem.content.simCardTypes,
      });
      setIsPXType(selectedItem?.content?.type === "PX-MQTT");
    },
    [meterUploadTemplate]
  );

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  const handleMeterDisabledClick = useCallback(() => {
    setMeterDisabled(prevState => !prevState);
  }, []);

  const handleSimDisabledClick = useCallback(() => {
    setSimDisabled(prevState => !prevState);
  }, []);

  const handleOnchange = useCallback(
    e => {
      form.setFieldValue("iccid", e.currentTarget.value);
    },
    [form]
  );

  const handleBatteryChange = useCallback(
    e => {
      const { value, name } = e.currentTarget;
      const removeText = "]C1";
      if (value.substring(0, 3) === removeText) {
        form.setFieldValue(name, value.substring(3, value.length));
      }
    },
    [form]
  );

  return (
    <>
      <NGIPrompt show={isDirty} />
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    <NGIForm
                      form={form}
                      name="horizontal_login"
                      layout="inline"
                      onFinish={onSubmit}
                      onChange={onFieldsChanged}
                      className={classes.formContainer}
                    >
                      <div className={classes.modalContent}>
                        <Row className={classes.sectionItem}>
                          <Col span={24}>
                            <div className={classes.titleWithAction}>
                              <Title level={4}>Choose template</Title>
                              <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
                            </div>
                            <Divider />
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <NGIFormItem name="template">
                              <NGISelect showSearch placeholder={"Please select template"} onChange={handleChange}>
                                {meterUploadTemplate?.map(item => (
                                  <Option key={item.name} value={item.name}>
                                    {item.name}
                                  </Option>
                                ))}
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                        </Row>

                        <Row className={classes.sectionItem}>
                          <Col span={24}>
                            <Title level={4}>Edit Meter Details</Title>
                            <Divider />
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Meter Barcode *</Title>
                            <NGIFormItem
                              name="meterBarcode"
                              rules={[
                                ...VALIDATION_RULES,
                                {
                                  pattern: /^[A-Z0-9]+$/,
                                  message: "Please match the requested format. Only A-Z or 0-9 characters are allowed.",
                                },
                                {
                                  max: 30,
                                  message: "The max length is 30",
                                },
                              ]}
                            >
                              <NGIInput disablePattern onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>Serial {!isPXType && "*"}</Title>
                            <NGIFormItem
                              name="serial"
                              rules={[
                                ...(isPXType ? [] : VALIDATION_RULES),
                                {
                                  pattern: /^[A-Z0-9-]+$/,
                                  message:
                                    "Please match the requested format. Only -, A-Z or 0-9 characters are allowed.",
                                },
                                {
                                  max: 30,
                                  message: "The max length is 30",
                                },
                              ]}
                            >
                              <NGIInput disablePattern onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Pcb *</Title>
                            <NGIFormItem
                              name="pcb"
                              rules={[
                                ...VALIDATION_RULES,
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  max: 30,
                                  message: "Please change the value. The max length is 30.",
                                },
                              ]}
                            >
                              <NGIInput disablePattern onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>Sensor Serial {!isPXType && "*"}</Title>
                            <NGIFormItem
                              name="sensorSerial"
                              rules={[
                                ...(isPXType ? [] : VALIDATION_RULES),
                                {
                                  pattern: /^[A-Z0-9]+$/,
                                  message: "Please match the requested format. Only A-Z or 0-9 characters are allowed.",
                                },
                                {
                                  max: 30,
                                  message: "The max length is 30",
                                },
                              ]}
                            >
                              <NGIInput disablePattern onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Battery 1</Title>
                            <NGIFormItem
                              name="battery1"
                              rules={[
                                {
                                  pattern: /^[A-Z0-9]+$/,
                                  message: "Please match the requested format. Only A-Z or 0-9 characters are allowed.",
                                },
                                {
                                  max: 30,
                                  message: "The max length is 30",
                                },
                              ]}
                            >
                              <NGIInput name="battery1" onChange={handleBatteryChange} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>Battery 2</Title>
                            <NGIFormItem
                              name="battery2"
                              rules={[
                                {
                                  pattern: /^[A-Z0-9]+$/,
                                  message: "Please match the requested format. Only A-Z or 0-9 characters are allowed.",
                                },
                                {
                                  max: 30,
                                  message: "The max length is 30",
                                },
                              ]}
                            >
                              <NGIInput name="battery2" onChange={handleBatteryChange} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Removable SimCard Iccid</Title>
                            <NGIFormItem
                              name="removableSimCardIccid"
                              rules={[
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  max: 20,
                                  message: "Please change the value. The max length is 20.",
                                },
                              ]}
                            >
                              <NGIInput onChange={handleOnchange} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>

                        <Row className={classes.sectionItem}>
                          <Col span={24}>
                            <NGICheckbox
                              onChange={handleSimDisabledClick}
                              className={classes.checkbox}
                              checked={!simDisabled}
                            >
                              <Title level={4}>Edit Sim Details (data from template)</Title>
                            </NGICheckbox>
                            <Divider />
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>ICCID</Title>
                            <NGIFormItem
                              name="iccid"
                              rules={[
                                {
                                  pattern: !simDisabled ? /^(89)\d+$/ : "",
                                  message: "Please match the requested format. ICCID always starts with 89.",
                                },
                                { min: 18, max: 20, message: "The max length is from 18 to 20." },
                              ]}
                            >
                              <NGIInput disabled={simDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>MSISDN</Title>
                            <NGIFormItem
                              name="msisdn"
                              rules={[
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  max: 13,
                                  message: "Please change the value. The max length is 13.",
                                },
                              ]}
                            >
                              <NGIInput disabled={simDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>IMSI</Title>
                            <NGIFormItem
                              name="imsi"
                              rules={[
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  max: 15,
                                  message: "Please change the value. The max length is 15.",
                                },
                              ]}
                            >
                              <NGIInput disabled={simDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>IP</Title>
                            <NGIFormItem
                              name="ip"
                              rules={[
                                {
                                  pattern: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
                                  message: "Please match the requested format. For example, 192.168.0.15",
                                },
                              ]}
                            >
                              <NGIInput disabled={simDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>

                        <Row className={classes.sectionItem}>
                          <Col span={24}>
                            <NGICheckbox
                              onChange={handleMeterDisabledClick}
                              className={classes.checkbox}
                              checked={!meterDisabled}
                            >
                              <Title level={4}>Edit Meter Details (data from template)</Title>
                            </NGICheckbox>

                            <Divider />
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Type *</Title>
                            <NGIFormItem name="type" rules={[{ required: true, message: "Please enter value" }]}>
                              <NGISelect showSearch disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                {meterType?.map(item => (
                                  <Select.Option key={item} value={item}>
                                    {item}
                                  </Select.Option>
                                ))}
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>Sensor Type</Title>
                            <NGIFormItem name="sensorType">
                              <NGISelect showSearch disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                {dictionaries.sensors.map(item => (
                                  <Select.Option key={item.type} value={item.type}>
                                    {item.type}
                                  </Select.Option>
                                ))}
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Embedded SimCard Iccid </Title>
                            <NGIFormItem
                              name="embeddedSimCardIccid"
                              rules={[
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  max: 20,
                                  message: "Please change the value. The max length is 20.",
                                },
                              ]}
                            >
                              <NGIInput disabled={meterDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>SimCard Slots Number {!isPXType && "*"}</Title>
                            <NGIFormItem name="simCardSlotsNumber" rules={isPXType ? [] : VALIDATION_RULES}>
                              <NGISelect disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={24}>
                            <Title level={5}>SimCard Types {!isPXType && "*"}</Title>
                            <NGIFormItem name="simCardTypes" rules={isPXType ? [] : VALIDATION_RULES}>
                              <NGISelect
                                disabled={meterDisabled}
                                onKeyDown={handleFocusChange}
                                style={{ width: "100%" }}
                                tokenSeparators={[","]}
                                mode="multiple"
                              >
                                {simCardType?.map(item => (
                                  <Select.Option key={item} value={item}>
                                    {item}
                                  </Select.Option>
                                ))}
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Firmware {!isPXType && "*"}</Title>
                            <NGIFormItem name="firmware" rules={isPXType ? [] : VALIDATION_RULES}>
                              <NGISelect disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                {dictionaries.firmwares.map(item => (
                                  <Select.Option key={item.version} value={item.version}>
                                    {item.version}
                                  </Select.Option>
                                ))}
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>Controller *</Title>
                            <NGIFormItem name="controller" rules={[{ required: true, message: "Please enter value" }]}>
                              <NGISelect disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                {dictionaries.microcontrollers.map(item => (
                                  <Select.Option key={item.type} value={item.type}>
                                    {item.type}
                                  </Select.Option>
                                ))}
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={24}>
                            <Title level={5}>Battery Model {!isPXType && "*"}</Title>
                            <NGIFormItem name="batteryModel" rules={isPXType ? [] : VALIDATION_RULES}>
                              <NGISelect disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                {batteryModel?.map(item => (
                                  <Select.Option key={item} value={item}>
                                    {item}
                                  </Select.Option>
                                ))}
                              </NGISelect>
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Max Battery Voltage {!isPXType && "*"}</Title>
                            <NGIFormItem
                              name="maxBatteryVoltage"
                              rules={[
                                ...(isPXType ? [] : VALIDATION_RULES),
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  validator: (_, value) => {
                                    if (parseFloat(value) < 3 || parseFloat(value) > 4.3) {
                                      return Promise.reject(
                                        "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30."
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                  message:
                                    "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30.",
                                },
                              ]}
                            >
                              <NGIInput disablePattern disabled={meterDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>Min Battery Voltage {!isPXType && "*"}</Title>
                            <NGIFormItem
                              name="minBatteryVoltage"
                              rules={[
                                ...(isPXType ? [] : VALIDATION_RULES),
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  validator: (_, value) => {
                                    if (parseFloat(value) < 3 || parseFloat(value) > 4.3) {
                                      return Promise.reject(
                                        "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30."
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                  message:
                                    "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30.",
                                },
                              ]}
                            >
                              <NGIInput disablePattern disabled={meterDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={12}>
                            <Title level={5}>Calibrated At {!isPXType && "*"}</Title>
                            <NGIFormItem name="calibratedAt" rules={isPXType ? [] : VALIDATION_RULES}>
                              <NGIDatePicker disabled={meterDisabled} allowClear onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>Manufacture Name</Title>
                            <NGIFormItem
                              name="manufactureName"
                              rules={[
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  max: 50,
                                  message: "Please change the value. The max length is 50.",
                                },
                              ]}
                            >
                              <NGIInput disablePattern disabled={meterDisabled} onKeyDown={handleFocusChange} />
                            </NGIFormItem>
                          </Col>
                        </Row>
                        <Row className={classes.formItem}>
                          <Col span={24}>
                            <Title level={5}>Manufacture Location</Title>
                            <NGIFormItem
                              name="manufactureLocation"
                              rules={[
                                {
                                  pattern: NO_SPACE_PATTERN,
                                  message: "Space isn't allowed on begin and finish.",
                                },
                                {
                                  max: 50,
                                  message: "Please change the value. The max length is 50.",
                                },
                              ]}
                            >
                              <NGIInput disablePattern disabled={meterDisabled} />
                            </NGIFormItem>
                          </Col>
                        </Row>
                        {isPXType && (
                          <>
                            <Row className={classes.formItem}>
                              <Col span={12}>
                                <Title level={5}>Modem MAC address</Title>
                                <NGIFormItem name="modemMac" rules={MAC_ADDRESS_VALIDATION_RULES}>
                                  <NGIInput />
                                </NGIFormItem>
                              </Col>
                              <Col span={12}>
                                <Title level={5}>Bluetooth Low Energy MAC address</Title>
                                <NGIFormItem name="bleMac" rules={MAC_ADDRESS_VALIDATION_RULES}>
                                  <NGIInput />
                                </NGIFormItem>
                              </Col>
                            </Row>

                            <Row className={classes.formItem}>
                              <Col span={12}>
                                <Title level={5}>Meter Assembly Date *</Title>
                                <NGIFormItem name="assemblyDate" rules={VALIDATION_RULES}>
                                  <NGIDatePicker disabled={meterDisabled} allowClear onKeyDown={handleFocusChange} />
                                </NGIFormItem>
                              </Col>
                              <Col span={12}>
                                <Title level={5}>Certificate ID</Title>
                                <NGIFormItem name="certId">
                                  <NGIInput />
                                </NGIFormItem>
                              </Col>
                            </Row>

                            <Row className={classes.formItem}>
                              <Col span={12}>
                                <Title level={5}>Secondary Controller</Title>
                                <NGIFormItem name="controller2">
                                  <NGISelect disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                    {dictionaries.microcontrollers.map(item => (
                                      <Select.Option key={item.type} value={item.type}>
                                        {item.type}
                                      </Select.Option>
                                    ))}
                                  </NGISelect>
                                </NGIFormItem>
                              </Col>
                              <Col span={12}>
                                <Title level={5}>Secondary Firmware</Title>
                                <NGIFormItem name="mcu2Firmware">
                                  <NGISelect disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                    {dictionaries.firmwares.map(item => (
                                      <Select.Option key={item.version} value={item.version}>
                                        {item.version}
                                      </Select.Option>
                                    ))}
                                  </NGISelect>
                                </NGIFormItem>
                              </Col>
                            </Row>

                            <Row className={classes.formItem}>
                              <Col span={12}>
                                <Title level={5}>Modem Firmware</Title>
                                <NGIFormItem name="modemFirmware">
                                  <NGISelect disabled={meterDisabled} onKeyDown={handleFocusChange}>
                                    {dictionaries.firmwares.map(item => (
                                      <Select.Option key={item.version} value={item.version}>
                                        {item.version}
                                      </Select.Option>
                                    ))}
                                  </NGISelect>
                                </NGIFormItem>
                              </Col>
                              <Col span={12}>
                                <Title level={5}>Sensor Firmware</Title>
                                <NGIFormItem name="sensorFwVersion">
                                  <NGIInput />
                                </NGIFormItem>
                              </Col>
                            </Row>

                            <Row className={classes.formItem}>
                              <Col span={12}>
                                <Title level={5}>Sensor Hardware</Title>
                                <NGIFormItem name="sensorHwVersion">
                                  <NGIInput />
                                </NGIFormItem>
                              </Col>
                              <Col span={12}>
                                <Title level={5}>Battery Capacity</Title>
                                <NGIFormItem name="batteryCapacity">
                                  <NGIInput suffix={"mah"} type="number" step="any" />
                                </NGIFormItem>
                              </Col>
                            </Row>

                            <Row className={classes.formItem}>
                              <Col span={12}>
                                <Title level={5}>Factory Tested</Title>
                                <NGIFormItem name="isFactoryTested">
                                  <NGISelect onKeyDown={handleFocusChange}>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                  </NGISelect>
                                </NGIFormItem>
                              </Col>
                              <Col span={12}>
                                <Title level={5}>Factory Test Passed</Title>
                                <NGIFormItem name="isFactoryTestPassed">
                                  <NGISelect onKeyDown={handleFocusChange}>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                  </NGISelect>
                                </NGIFormItem>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </NGIForm>
                  </CardBody>
                </Card>
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default AddManualMeter;
