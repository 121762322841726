export const filterOptions = [
  {
    name: "Old Firmware",
    value: "old",
  },
  {
    name: "New Firmware",
    value: "new",
  },
  {
    name: "Old & New Firmware",
    value: "both",
  },
  {
    name: "Meter Barcode",
    value: "barcode",
  },
  {
    name: "Process ID",
    value: "id",
  },
];
