import React from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";

const MetaData = props => {
  const { pageTitle } = props;

  return (
    <MetaTags>
      <title> {pageTitle} | NGI PORTAL</title>
    </MetaTags>
  );
};

MetaData.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default MetaData;
