import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { segmentWithColor } from "pages/price-management/PriceManagement/utils";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";

const color = "#444444";

const hideRoles = [USER_ROLES.TSS];
export const repossessedListColumns = () => [
  {
    title: "Customer ID",
    dataIndex: "id",
    render: textFormatter,
  },
  {
    title: "Full Name",
    dataIndex: "",
    render: row => {
      const fullName = `${row.firstName} ${row.lastName}`;
      return userHasOneOfRoles(hideRoles) ? (
        textFormatter(fullName)
      ) : (
        <Tooltip placement="bottom" title={`${row.firstName} ${row.lastName} details`} color={color}>
          <div>
            <CustomLink to={`/customers/customer/${row.id}?backUrl=/customers/customers/repossessed`}>
              {textFormatter(fullName)}
            </CustomLink>
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: "Customer Segment",
    dataIndex: "priceSegment",
    render: segmentWithColor,
  },
  {
    title: "Document ID",
    dataIndex: "document",
    render: cellContent => textFormatter(cellContent?.number),
  },
  {
    title: "TSR",
    dataIndex: "technician",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: textFormatter,
  },
  {
    title: "Category",
    dataIndex: "type",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Installation Date",
    dataIndex: "installationDate",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Repossession Date",
    dataIndex: "updatedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
];
