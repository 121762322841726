import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { segmentWithColor } from "pages/price-management/PriceManagement/utils";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";
import classnames from "classnames";

const color = "#444444";

export const customerListColumns = styles => [
  {
    title: "Customer ID",
    dataIndex: "id",
    render: textFormatter,
  },
  {
    title: "Full Name",
    dataIndex: "",
    render: row => {
      const fullName = `${row.firstName} ${row.lastName}`;
      if (userHasOneOfRoles([USER_ROLES.BUSINESS_DEV, USER_ROLES.TSS])) return textFormatter(fullName);

      return (
        <Tooltip placement="bottom" title={`${row.firstName} ${row.lastName} details`} color={color}>
          <div>
            {row.id.includes("%") ? (
              row.id
            ) : (
              <CustomLink to={`/customers/customer/${row.id}`}>{textFormatter(fullName)}</CustomLink>
            )}
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: "Customer Segment",
    dataIndex: "priceSegment",
    render: segmentWithColor,
  },
  {
    title: "TSR",
    dataIndex: "technician",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: textFormatter,
  },
  {
    title: "Score",
    dataIndex: "leadScore",
    render: (cellContent, row) => (
      <div className={classnames(styles.scoreContainer, styles[row.status])}>
        {cellContent ? cellContent?.toFixed(4) : "-"}
      </div>
    ),
    sort: true,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Package",
    dataIndex: "packageName",
    render: textFormatter,
  },
  {
    title: "Installation Date",
    dataIndex: "installationDate",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
];
