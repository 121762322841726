import { customDateFormatWithTime, customTimeFormat, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import { Tooltip } from "antd";
import React from "react";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import infoIcon from "assets/images/svg/Info.svg";
import newInstallation from "../../../assets/images/svg/NewInstallation.svg";
import cylinderChange from "../../../assets/images/svg/CylinderChange.svg";
import batteryChange from "../../../assets/images/svg/BatteryChange.svg";
import meterChange from "../../../assets/images/svg/MeterChange.svg";
import stoveChange from "../../../assets/images/svg/StiveChange.svg";
import packageUpgrade from "../../../assets/images/svg/PackageUpgrade.svg";
import reposession from "../../../assets/images/svg/Reposession.svg";
import inspection from "../../../assets/images/svg/Inspection.svg";
import investigation from "../../../assets/images/svg/Investigation.svg";
import pipeChange from "../../../assets/images/svg/PipeChange.svg";
import followUp from "../../../assets/images/svg/FollowUp.svg";
import intervention from "../../../assets/images/svg/Intervention.svg";
import safety from "../../../assets/images/svg/Safety.svg";
import relocation from "../../../assets/images/svg/Relocation.svg";
import delivery from "../../../assets/images/svg/Delivery.svg";
import contractChange from "../../../assets/images/svg/ContractChangeLarge.svg";
import assetSwap from "../../../assets/images/svg/AssetSwap.svg";
import assetCollection from "../../../assets/images/svg/AssetCollection.svg";

export const PRIORITY_LIST = [
  { min: 25, max: 36, name: "Low", letter: "L" },
  { min: 13, max: 24, name: "Medium", letter: "M" },
  { min: 1, max: 12, name: "High", letter: "H" },
];

export const TABS = {
  GENERAL_INFORMATION: "GENERAL_INFORMATION",
  PRIORITY_TASKS: "PRIORITY_TASKS",
  REPOSSESSIONS_TASKS: "REPOSSESSIONS_TASKS",
  INSTALLATIONS_TASKS: "INSTALLATIONS_TASKS",
  FUTURE_TASKS: "FUTURE_TASKS",
  BACKLOG_TASKS: "BACKLOG_TASKS",
  NOT_REACHABLE_TASKS: "NOT_REACHABLE_TASKS",
  ASSETS: "ASSETS",
};

export const TABS_BUTTONS = [
  {
    title: "General Information",
    key: TABS.GENERAL_INFORMATION,
  },
  {
    title: "Priority Tasks",
    key: TABS.PRIORITY_TASKS,
  },
  {
    title: "Repossessions",
    key: TABS.REPOSSESSIONS_TASKS,
  },
  {
    title: "Installations",
    key: TABS.INSTALLATIONS_TASKS,
  },
  {
    title: "Assets",
    key: TABS.ASSETS,
  },
  {
    title: "Future Tasks",
    key: TABS.FUTURE_TASKS,
  },
  {
    title: "Backlog Tasks",
    key: TABS.BACKLOG_TASKS,
  },
  {
    title: "Not Reachable Customers",
    key: TABS.NOT_REACHABLE_TASKS,
  },
];

export const HIDE_FIELDS = [
  "status",
  "tasksCount",
  "assets",
  "tasks",
  "assigneeName",
  "updatedAt",
  "depot",
  "customers",
];

export const CLASSIFICATION = {
  PRIORITY: "Priority",
  REPOSSESSIONS: "Repossessions",
  BACKLOG: "Backlog",
  FUTURE_TASKS: "Future Tasks",
  INSTALLATIONS: "Installations",
  NOT_REACHABLE: "Not Reachable",
};

export const routineTasksColumns = (onOpen, showAction) => [
  {
    title: "Customer Name",
    dataIndex: "customer.name",
    key: "customer.name",
    render: (cellContent, customer) => textFormatter(customer.name),
    width: "20%",
  },
  {
    title: "Customer ID",
    dataIndex: "id",
    key: "id",
    render: (cellContent, customer) =>
      customer.id ? (
        <CustomLink underlined target="_blank" to={`/customers/customer/${customer.id}`}>
          {customer.id}
        </CustomLink>
      ) : (
        textFormatter(customer.id)
      ),
  },
  {
    title: "Tasks Count",
    dataIndex: "tasks",
    key: "tasks",
    render: (cellContent, customer) => textFormatter(customer.tasks.length),
  },
  {
    title: "Time Slot",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, customer) =>
      !!customer.availableFrom
        ? `${customDateFormatWithTime(customer.availableFrom, false)} - ${customTimeFormat(customer.availableTo)}`
        : "Not Set",
    width: "20%",
  },
  ...(showAction
    ? [
        {
          title: "",
          key: "action",
          render: (cellContent, customer) => (
            <ButtonGroup tooltipTitle="View Details" icon={infoIcon} handleClick={() => onOpen(customer)} />
          ),
        },
      ]
    : []),
];

export const routineAssetsColumns = () => [
  {
    title: "Group",
    dataIndex: "typeGroup",
    key: "typeGroup",
    render: (cellContent, routine) => (
      <Tooltip placement="bottom" title={`${routine.typeGroup} details`}>
        <div>
          <CustomLink target={"_blank"} to={`/warehouse/assets/${routine.id}`}>
            {textFormatter(routine.typeGroup)}
          </CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Barcode",
    dataIndex: "barcode",
    key: "barcode",
    render: (cellContent, routine) => textFormatter(routine.barcode),
  },
  {
    title: "Serial",
    dataField: "serialNumber",
    key: "serialNumber",
    render: (cellContent, routine) =>
      routine.serialNumber !== "UNKNOWN" ? (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${routine.serialNumber}?t=serialNumber`}>
          {routine.serialNumber}
        </CustomLink>
      ) : (
        routine.serialNumber
      ),
  },
  {
    title: "QR Code",
    dataIndex: "qrCode",
    key: "qrCode",
    render: (cellContent, routine) => textFormatter(routine.qrCode),
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    render: (cellContent, routine) => textFormatter(routine.state),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (cellContent, routine) => textFormatter(routine.type),
  },
];

export const allowedStates = ["Assigned", "Collected"];
export const allowedStatus = "Suspended";
export const SUCCESS_LOST_MESSAGE = "Selected assets were marked as lost";
export const LOST_MODAL_DESCRIPTION =
  "Are you sure you want to mark all items from the selected routine as lost? This will change the status of the assets to lost. This will also release the routine from suspension.";

export const statusIcons = {
  "New Installation": newInstallation,
  "Cylinder Change": cylinderChange,
  "Battery Change": batteryChange,
  "Meter Change": meterChange,
  "Stove Change": stoveChange,
  "Package Upgrade": packageUpgrade,
  Repossession: reposession,
  Inspection: inspection,
  Investigation: investigation,
  "Pipe Change": pipeChange,
  "Follow Up": followUp,
  Intervention: intervention,
  Safety: safety,
  Relocation: relocation,
  Delivery: delivery,
  "Contract Change": contractChange,
  "Asset Swap": assetSwap,
  "Asset Collection": assetCollection,
};
