import React, { useCallback, useMemo } from "react";
import { Col, Form, Modal, Row, Space } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import Title from "antd/lib/typography/Title";
import { VALIDATION_RULES } from "pages/meter-management/meters/variables";

const useStyles = makeStyles({
  root: {
    maxWidth: "350px!important",

    "& .ant-modal-body": {
      padding: "24px 30px!important",
    },
  },
  checkboxes: {
    display: "flex",
    flexDirection: "column",

    "&>label": {
      marginLeft: "0!important",
    },
  },
  photoContainer: {
    marginBottom: 0,
  },
});

const NewMeterChangeReasonModal = ({ onClose, reason = "", isSub, onSave, allReasons }) => {
  const [form] = Form.useForm();
  const classes = useStyles();

  const [level1, level2] = useMemo(() => reason.split(">"), []);
  const allReasonsNames = useMemo(() => allReasons?.map(item => item?.item?.toLowerCase()) || [], [allReasons]);
  const initialReason = isSub ? level2 : level1;
  const onSubmit = useCallback(
    values => {
      onSave(isSub ? `${level1}>${values.reason}` : values.reason);
    },
    [isSub]
  );

  return (
    <Modal open={true} className={classes.root} closable={false} onCancel={onClose} footer={[]}>
      <div>
        <Form
          form={form}
          onFinish={onSubmit}
          initialValues={{
            reason: isSub ? level2 : level1,
          }}
        >
          <Row>
            <Col span={24}>
              <Title level={5}>Reason Name</Title>
              <Form.Item
                name="reason"
                rules={[
                  ...VALIDATION_RULES,
                  {
                    message: "The reason has already existed",
                    validator: (_, value) => {
                      if (
                        allReasonsNames.includes(value.toLowerCase()) &&
                        initialReason.toLowerCase() !== value.toLowerCase()
                      ) {
                        return Promise.reject("The reason has already existed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGIInput disablePattern placeholder="Enter name" />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
          </Space>
        </Form>
      </div>
    </Modal>
  );
};

export default NewMeterChangeReasonModal;
