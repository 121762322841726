import React, { useCallback, useMemo, useState } from "react";
import { Form, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import { makeStyles } from "@material-ui/core/styles";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const { Option } = Select;

const FILTER_TYPES = {
  PAYMENT: {
    name: "Payment Type",
    value: "transactionMode",
  },
  TRANSACTION_ID: {
    name: "Transaction ID",
    value: "transactionId",
  },
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 10,
  },
  button: {
    height: 50,
  },
  input: {
    width: "200px !important",
  },
});

const TransactionsFilter = ({ onFilter, type }) => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const [activeType, setActiveType] = useState(FILTER_TYPES.PAYMENT.value);
  const { getFilters } = useStoreFilters(FILTER_PAGE.CUSTOMERS_TRANSACTION_BOOK);

  const handleTypeChange = useCallback(e => {
    form.setFieldsValue({ value: null });
    setActiveType(e);
  }, []);

  const handleFilter = useCallback(
    filter => {
      if (!(filter.value && !filter.type)) {
        const { pageSize } = getFilters();
        if (type === "balanceBook") onFilter({ [filter.type]: filter.value, pageSize, payment: "YES" });
        else onFilter({ [filter.type]: filter.value, pageSize });
      }
    },
    [type]
  );

  const TRANSACTION_TYPES = useMemo(
    () => [
      "Stove Balance Payment Type",
      "Standard Payment Type",
      "Installation Payment Type",
      "Stove Repair Payment Type",
      "Card Replacement Payment Type",
      "Lost Asset Payment Type",
    ],
    [type]
  );

  return (
    <>
      <div className={classes.root}>
        <Form form={form} name="horizontal_login" layout="inline" onFinish={handleFilter}>
          <Form.Item name="type">
            <NGISelect
              className={classes.input}
              onChange={handleTypeChange}
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              size="default"
              placeholder="Select type"
              allowClear
            >
              <Option value={FILTER_TYPES.PAYMENT.value}>{FILTER_TYPES.PAYMENT.name}</Option>
              <Option value={FILTER_TYPES.TRANSACTION_ID.value}>{FILTER_TYPES.TRANSACTION_ID.name}</Option>
            </NGISelect>
          </Form.Item>

          {activeType === FILTER_TYPES.PAYMENT.value ? (
            <Form.Item name="value">
              <NGISelect
                className={classes.input}
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                size="default"
                placeholder="Select payment type"
                allowClear
              >
                {TRANSACTION_TYPES.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </NGISelect>
            </Form.Item>
          ) : (
            <Form.Item name="value">
              <NGIInput placeholder="Enter transaction ID" className={classes.input} />
            </Form.Item>
          )}

          <CustomButton type="primary" htmlType="submit" text="Filter" size="small" className={classes.button} />
        </Form>
      </div>
    </>
  );
};

export default TransactionsFilter;
