import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import { useLocation } from "react-router";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import CustomButton from "components/Common/buttons/CustomButton";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,
  },
  titleName: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 4,
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 150,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,

    "& button+button": {
      marginLeft: 10,
    },
  },
  Creating: {
    color: "#e9e9e9",
    backgroundColor: "#757575",
  },
  Stopped: {
    color: "#e6f5ff",
    backgroundColor: "#0088ea",
  },
  Active: {
    backgroundColor: "#19c346",
    color: "#d7ffe3",
  },
  New: {
    color: "#db6605",
    backgroundColor: "#ffe3cc",
  },
  Inactive: {
    color: "#fff",
    backgroundColor: "#c31d17",
  },
});

const Header = ({ data, scheduleTemplate, discardTemplate }) => {
  const classes = useStyles();
  const searchParams = useLocation().search;
  const backUrl = new URLSearchParams(searchParams).get("backUrl");
  const backButtonUrl = useMemo(() => (backUrl ? backUrl : "/sms/sms-bulk"), [backUrl]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const openConfirmationDiscardModal = useCallback(() => setIsDiscardConfirmationOpen(true), []);
  const closeConfirmationDiscardModal = useCallback(() => setIsDiscardConfirmationOpen(false), []);

  const openConfirmationCancelModal = useCallback(() => setIsCancelConfirmationOpen(true), []);
  const closeConfirmationCancelModal = useCallback(() => setIsCancelConfirmationOpen(false), []);

  const onSchedule = useCallback(() => {
    scheduleTemplate();
    closeConfirmationModal();
  }, []);

  const onDiscard = useCallback(() => {
    discardTemplate(data?.id);
    closeConfirmationDiscardModal();
  }, [data]);

  const onCancel = useCallback(() => {
    discardTemplate(data?.id);
    closeConfirmationCancelModal();
  }, [data]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={backButtonUrl}>Back</BackButtonLink>

        <div className={classes.nameContainer}>
          <div>
            <span className={classes.titleName}>ID</span>
            {data?.id}
          </div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        {data?.status === "Draft" && (
          <>
            <CustomButton onClick={openConfirmationModal} type="success" size="small" text="SCHEDULE" />
            <CustomButton onClick={openConfirmationDiscardModal} type="primary" size="small" text="Discard" />
          </>
        )}

        {data?.status === "Scheduled" && (
          <CustomButton onClick={openConfirmationCancelModal} type="success" size="small" text="Cancel" />
        )}
      </div>
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to schedule bulk sms template?"
          onCancel={closeConfirmationModal}
          onConfirm={onSchedule}
        />
      )}
      {isDiscardConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to discard bulk sms template?"
          onCancel={closeConfirmationDiscardModal}
          onConfirm={onDiscard}
        />
      )}
      {isCancelConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to cancel bulk sms template?"
          onCancel={closeConfirmationCancelModal}
          onConfirm={onCancel}
        />
      )}
    </div>
  );
};

export default Header;
