import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getFormattedDataByName, getFormattedKeyName } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "100%",
  },
  mapContainer: {
    width: "50%",
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
});

const GeneralDataTab = ({ data, sortedKeys = [] }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.informationContainer}>
        <div className={classes.column}>
          {sortedKeys.map(key => {
            if (!data[key]) return null;
            return (
              <div key={key} className={classes.rowData}>
                <div className={classes.title}>{getFormattedKeyName(key)}</div>
                <div className={classes.data}>{!!data[key] ? getFormattedDataByName(key, data[key]) : "-"}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.mapContainer}></div>
    </div>
  );
};

export default GeneralDataTab;
