import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Radio, Divider, Modal, Space, Spin, Select, Input } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { planAssetTrip, retrievePendingTrips } from "utils/api";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import { useSelector } from "react-redux";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { FILTER_PAGE, USER_TENANT_NAME } from "utils/constants";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { debounce, uniq } from "lodash";
import { DEPOT_TO_STO_TYPE_MAP, DEPOT_TYPES } from "pages/stock-management/stockTransferOrders/constants";
import AssetRow from "pages/stock-management/stockTransferOrders/AssignSTO/AssetRow";
import CustomLink from "components/Common/buttons/CustomLink";
import openTrip from "./../../../../assets/images/svg/open.svg";
import useStoreFilters from "customHooks/useStoreFilters";
import { searchOptions } from "pages/stock-management/stockTransferOrders/AssignSTO/constants";

const useStyles = makeStyles({
  modal: {
    maxWidth: "1000px!important",

    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 30px !important",
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  depotSelect: {
    width: "50%",
  },
  assetsContainer: {
    width: "100%",
  },
  assetsHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 10,

    "& span": {
      width: "20%",
    },
  },
  radioContainer: {
    width: "100%",
  },
  radioItem: {
    width: "100%",
    marginRight: 2,
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: 10,
    borderRadius: 10,
    marginBottom: 6,

    "& span:nth-child(1)": {
      width: 16,
      height: 16,
    },

    "&>span:nth-child(2)": {
      display: "flex",
      width: "100%",
      fontSize: 12,
      fontWeight: 600,
      paddingRight: 0,

      "& span:nth-child(1)": {
        width: "25%",
      },
      "& span": {
        width: "37%",

        "& span": {
          width: "initial!important",
          color: "#8C8C8C",
          fontWeight: 300,
        },
      },
    },
  },
  tableHeader: {
    width: "100%",
    marginRight: 2,
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: 10,
    borderRadius: 10,
    marginBottom: 6,
    fontWeight: 600,

    "& span:nth-child(1)": {
      width: "25%",
      marginLeft: 24,
    },

    "& span:nth-child(4)": {
      width: "37%",
      marginRight: 12,
    },

    "& span": {
      width: "37%",
    },
  },
  tripSearch: {
    width: "50%",
    marginBottom: 10,
  },
  searchWithType: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #444444",
    padding: 7,
    minHeight: 50,
    borderRadius: 30,

    "& .ant-select-selector": {
      border: "none!important",
    },
    "& .ant-input": {
      border: "none!important",
    },
    "& .ant-input:focus": {
      boxShadow: "none",
    },
  },
});

const AssignSTOModal = ({ onClose, update, selectedAssetIds, assetList = [], selectedAssetType }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { filters } = useStoreFilters(FILTER_PAGE.STO);
  const [assets, setAssets] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [tripList, setTripList] = useState([]);
  const [selectedTripId, setSelectedTripId] = useState(null);
  const [type, setType] = useState("tripNumber");

  const {
    list: { depot, truckLicensePlates },
  } = useSelector(DictionariesSelector);

  const loadTrips = useCallback(({ tripNumber, truckLicensePlate, location }) => {
    retrievePendingTrips({
      tripNumber,
      truckLicensePlate,
      location,
    })
      .then(res => {
        const { result } = res?.data;
        if (!result.length) {
          warnMessage("No trips found");
        }
        setTripList(result);
      })
      .catch(err => {
        setIsLoading(false);
        setTripList([]);
        defaultNgiErrorMethod(err);
      });
  }, []);

  useEffect(() => {
    const mappedAssets = assetList
      .filter(item => selectedAssetIds.includes(item.id))
      .map(item => ({ ...item, newQuantity: item.quantity }));
    setAssets(mappedAssets);
  }, []);

  const onSave = useCallback(() => {
    setIsLoading(true);
    closeConfirmationModal();
    const preparedData = {
      location: {
        depot: selectedLocation,
        address: " ",
      },
      assets: assets.map(item => ({
        stoId: item.stoId,
        stoItemId: item.id,
        stoType: DEPOT_TO_STO_TYPE_MAP[selectedAssetType],
        group: item.group,
        condition: item.condition,
        quantity: item.newQuantity,
      })),
    };
    planAssetTrip(selectedTripId, preparedData)
      .then(() => {
        setTimeout(() => {
          update(filters);
          onClose();
          setIsLoading(false);
          successMessage("Trip was planned successfully");
        }, 3000);
      })
      .catch(e => {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      });
  }, [assets, selectedLocation, selectedTripId, selectedAssetType, filters]);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const openConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(true), []);
  const closeConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(false), []);

  const onRemoveAsset = useCallback(e => {
    const { id } = e.currentTarget.dataset;
    setAssets(prevState => prevState.filter(item => item.id !== id));
  }, []);

  const onTripChange = useCallback(e => setSelectedTripId(e.target.value), [assets]);

  const changeHandler = useCallback(
    e => {
      const isPlate = typeof e === "string";
      const id = isPlate ? e : e.target.value;
      setSelectedTripId(null);
      loadTrips({
        ...(isPlate
          ? {
              truckLicensePlate: id,
            }
          : {
              tripNumber: id.trim(),
            }),
        location: selectedLocation,
      });
    },
    [loadTrips, selectedLocation]
  );

  const filteredDepots = useMemo(() => {
    const depotsToHide = uniq(
      assets.map(item => (selectedAssetType === DEPOT_TYPES.DEPOT_TO ? item.toDepot : item.fromDepot))
    );
    return (
      depot &&
      depot[USER_TENANT_NAME] &&
      uniq(depot[USER_TENANT_NAME])
        ?.sort()
        .filter(item => !depotsToHide.includes(item))
    );
  }, [depot, assets]);

  const onLocationChange = useCallback(location => {
    setSelectedLocation(location);
    setSelectedTripId(null);
    setType(null);
    loadTrips({ location });
  }, []);

  const onChangeType = useCallback(
    value => {
      setType(value);
      loadTrips({ location: selectedLocation });
    },
    [selectedLocation]
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 1000), [changeHandler]);

  return (
    <Modal
      title={
        <div className={classes.infoContainer}>
          Plan a TRIP
          <span>Please fill in all mandatory fields</span>
        </div>
      }
      className={classes.modal}
      open
      onCancel={openConfirmCloseModal}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div>
          <TitleWithDivider title="List of Assets" />
          <div className={classes.assetsContainer}>
            <div className={classes.assetsHeader}>
              <span>Asset Type</span>
              <span>{selectedAssetType === DEPOT_TYPES.DEPOT_TO ? "To Depot" : "From Depot"}</span>
              <span>Requested QTY</span>
              <span>Approved QTY</span>
            </div>
            {assets.map((item, index) => (
              <AssetRow
                key={item.id}
                index={index}
                item={item}
                type={selectedAssetType}
                setAssets={setAssets}
                onRemoveAsset={onRemoveAsset}
                allowRemove={Boolean(assets.length > 1)}
              />
            ))}
          </div>
          <TitleWithDivider title={selectedAssetType === DEPOT_TYPES.DEPOT_FROM ? "To Depot" : "From Depot"} />
          <NGISelect
            onChange={onLocationChange}
            className={classes.depotSelect}
            value={selectedLocation}
            placeholder={"Select value"}
            allowClear
            showSearch
          >
            {filteredDepots?.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </NGISelect>

          <TitleWithDivider title="Select a Trip" />
          <div className={classes.tripSearch}>
            <div className={classes.searchWithType}>
              <Select
                style={{ width: "35%" }}
                value={type}
                onChange={onChangeType}
                placeholder="Select type"
                allowClear
              >
                {searchOptions.map(item => (
                  <Option key={item.value} value={item.value}>
                    {item.title}
                  </Option>
                ))}
              </Select>
              {type === "tripNumber" && (
                <Input style={{ width: "65%" }} placeholder="Search by ID" onChange={debouncedChangeHandler} />
              )}
              {type === "truckLicensePlate" && (
                <Select
                  style={{ width: "65%" }}
                  onChange={debouncedChangeHandler}
                  showSearch
                  placeholder={"Select value"}
                >
                  {truckLicensePlates &&
                    truckLicensePlates[USER_TENANT_NAME] &&
                    truckLicensePlates[USER_TENANT_NAME]?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              )}
            </div>
          </div>
          <div className={classes.tripsContainer}>
            <Spin spinning={!tripList}>
              {!!tripList?.length && (
                <div className={classes.tableHeader}>
                  <span>TRIP ID</span>
                  <span>START LOCATION</span>
                  <span>END LOCATION</span>
                  <span>START TIME</span>
                </div>
              )}

              <Radio.Group className={classes.radioContainer} onChange={onTripChange} value={selectedTripId}>
                {tripList &&
                  tripList.map(item => (
                    <Radio className={classes.radioItem} key={item.tripNumber} value={item.tripNumber}>
                      <span>{item.tripNumber}</span>
                      <span>{item.startLocation}</span>
                      <span>{item.endLocation}</span>
                      <span>{`${item.startDate} AT ${item.startTime}`}</span>
                      <CustomLink target="_blank" to={`/stock-management/trips/${item.tripNumber}`}>
                        <img src={openTrip} alt="action-icon" />
                      </CustomLink>
                    </Radio>
                  ))}
              </Radio.Group>
            </Spin>
          </div>
        </div>
      </Spin>
      <Divider />
      <br />
      <Space>
        <CustomButton onClick={openConfirmCloseModal} type="primary" size="small" color="outlined" text="Close" />
        <CustomButton
          type="primary"
          disabled={!assets.length || !selectedTripId || !selectedLocation}
          onClick={openConfirmationModal}
          text="ASSIGN TO TRIP"
          size="small"
        />
      </Space>
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to assign assets to the trip?"
          onCancel={closeConfirmationModal}
          onConfirm={onSave}
          loading={isLoading}
        />
      )}
      {isConfirmCloseOpen && (
        <ConfirmationModal
          description="Planing a trip is in progress. Are you sure you want to close it?"
          onCancel={closeConfirmCloseModal}
          onConfirm={onClose}
        />
      )}
    </Modal>
  );
};

export default AssignSTOModal;
