import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import MetaData from "components/VerticalLayout/MetaData";
import MetersFilter from "pages/meter-management/meters/MetersFilter";
import { retrieveMeters } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { meterListColumns } from "./meterListColumns";
import MetersSearch from "pages/meter-management/meters/MetersSearch";
import ResponseModel from "./ResponseModel";
import { ReportModel } from "./ReportModel";
import { CommandsModel } from "./CommandsModel";
import UploadLogModal from "./uploadLogModal";
import DownloadLogModal from "./downloadLogsModal";
import UploadMeterModal from "pages/meter-management/configuration/meters/uploadModal";
import NGITable from "components/Common/NGITable";
import FactoryTestModal from "pages/meter-management/configuration/meters/FactoryTest/FactoryTestModal";
import ShipMeterModal from "pages/meter-management/configuration/meters/shipMetersModal";
import { useDispatch } from "react-redux";
import ConfigurationModal from "pages/meter-management/meters/ConfigurationModal";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import NGIDropdownButton from "components/Common/buttons/NGIDropdownButton";
import { useHistory } from "react-router";
import useStoreFilters from "customHooks/useStoreFilters";
import MeterReceiveModal from "pages/meter-management/configuration/meters/MeterReceiveModal";
import MarkAsFaultyModal from "pages/meter-management/meters/MarkAsFaultyModal";
import ChangeMeterTypeModal from "pages/meter-management/meters/ChangeMeterTypeModal";
import { userHasOneOfRoles } from "utils/helpers/functions";
import UpdateFirmwareModal from "pages/meter-management/meters/UpdateFirmwareModal";
import BulkUpdateFirmwareModal from "pages/meter-management/meters/BulkUpdateFirmwareModal";
import SendCommandsModal from "pages/meter-management/meters/meterDetails/SendCommandsModal";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import uploadIcon from "assets/images/svg/Upload.svg";
import wrenchIcon from "assets/images/svg/Wrench.svg";
import firmwareIcon from "assets/images/svg/firmware-update.svg";
import mailIcon from "assets/images/svg/mail.svg";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";

const MeterInformation = () => {
  const history = useHistory();
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.METER);
  const [isLoading, setLoader] = useState(false);
  const [meterList, setMeterList] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [activeMeter, setActiveMeter] = useState(null);
  const [open, setOpen] = useState(false);
  const [command, setCommand] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isFaultyModalOpen, setIsFaultyModalOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [isChangeMeterTypeModalVisible, setIsChangeMeterTypeModalVisible] = useState(false);
  const [isUpdateFirmwareVisible, setIsUpdateFirmwareVisible] = useState(false);
  const [isUploadModalMeterVisible, setIsUploadModalMeterVisible] = useState(false);
  const [isBulkUpdateFirmwareVisible, setIsBulkUpdateFirmwareVisible] = useState(false);
  const [isSendCommandsVisible, setIsSendCommandsVisible] = useState(false);
  const [isCsvUpload, setIsCsvUpload] = useState(false);
  const [isShipModalVisible, setIsShipModalVisible] = useState(false);
  const [isMeterReceiveOpen, setIsMeterReceiveOpen] = useState(false);
  const [isFactoryTestModalOpen, setIsFactoryTestModalOpen] = useState(false);

  const hideRoles = [
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.GENERAL,
    USER_ROLES.FINANCE,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
  ];

  const closeChangeMeterTypeModal = useCallback(() => setIsChangeMeterTypeModalVisible(false), []);
  const openChangeMeterTypeModal = useCallback(item => {
    setActiveMeter(item);
    setIsChangeMeterTypeModalVisible(true);
  }, []);

  const closeUpdateFirmwareModal = useCallback(() => setIsUpdateFirmwareVisible(false), []);
  const openUpdateFirmwareModal = useCallback(item => {
    setActiveMeter(item);
    setIsUpdateFirmwareVisible(true);
  }, []);

  const openBulkUpdateFirmwareModal = useCallback(() => setIsBulkUpdateFirmwareVisible(true), []);
  const openCsvBulkUpdateFirmwareModal = useCallback(() => {
    setIsBulkUpdateFirmwareVisible(true);
    setIsCsvUpload(true);
  }, []);
  const closeBulkUpdateFirmwareModal = useCallback(() => {
    setIsBulkUpdateFirmwareVisible(false);
    setIsCsvUpload(false);
  }, []);

  const openSendCommandsModal = useCallback(() => setIsSendCommandsVisible(true), []);
  const closeSendCommandsModal = useCallback(() => setIsSendCommandsVisible(false), []);

  const closeUploadMeterModal = useCallback(() => setIsUploadModalMeterVisible(false), []);
  const openUploadMeterModal = useCallback(() => setIsUploadModalMeterVisible(true), []);
  const closeShipModal = useCallback(() => setIsShipModalVisible(false), []);
  const openShipModal = useCallback(() => setIsShipModalVisible(true), []);
  const closeCommandModel = useCallback(item => setCommand(item), []);
  const openCommandModel = useCallback(() => setCommand(true), []);
  const closeUploadModal = useCallback(() => setUploadModalVisible(false), []);
  const closeMeterReceiveModal = useCallback(() => setIsMeterReceiveOpen(false), []);
  const openMeterReceiveModal = useCallback(() => setIsMeterReceiveOpen(true), []);

  const openConfigurationModal = useCallback(() => setIsConfigModalOpen(true), []);
  const closeConfigurationModal = useCallback(() => setIsConfigModalOpen(false), []);

  const openFaultyModal = useCallback(item => {
    setActiveMeter(item);
    setIsFaultyModalOpen(true);
  }, []);
  const closeFaultyModal = useCallback(() => setIsFaultyModalOpen(false), []);

  const dispatch = useDispatch();

  const openUploadModal = useCallback(item => {
    setAssetId(item);
    setUploadModalVisible(true);
  }, []);

  const closeDownloadModal = useCallback(() => setDownloadModalVisible(false), []);

  const openDownloadModal = useCallback(item => {
    setAssetId(item);
    setDownloadModalVisible(true);
  }, []);

  const closeFactoryTestModal = useCallback(() => {
    setIsFactoryTestModalOpen(false);
    setActiveMeter(null);
  }, []);

  const openFactoryTestModal = useCallback(item => {
    setActiveMeter(item);
    setIsFactoryTestModalOpen(true);
  }, []);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.UPLOAD_TEMPLATE));
    dispatch(getDictionary(DICTIONARY_TYPES.MANUALLY_TRIGGERED_COMMAND));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_FAULTY_REASONS_V2));
  }, []);

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const handleFilter = (filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    retrieveMeters(filterOptions)
      .then(res => {
        const {
          result: { items },
        } = res?.data;

        if (items === 0) {
          warnMessage(`No ${!!filterOptions.status ? filterOptions.status : ""} meters were found`);
        }
        setMeterList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setMeterList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const onRetrieveStatus = useCallback(item => {
    setAssetId(item);
    setOpen(true);
  }, []);

  const onSendRequest = useCallback(item => {
    setAssetId(item);
    openCommandModel();
  }, []);

  const columns = useMemo(() => {
    const col = meterListColumns({
      onRequests: onSendRequest,
      onResponses: onRetrieveStatus,
      onUploadFile: openUploadModal,
      onDownloadFile: openDownloadModal,
      onFactoryTest: openFactoryTestModal,
      onFaultyModal: openFaultyModal,
      onChangeMeterTypeModal: openChangeMeterTypeModal,
      onUpdateFirmwareModal: openUpdateFirmwareModal,
    });
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [onSendRequest, onRetrieveStatus, openUploadModal, openDownloadModal, openFactoryTestModal, hideRoles, AUTH_USER]);

  return (
    <>
      <div className="page-content" data-testid="metersComponent">
        <MetaData pageTitle={"All Meters"} />
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <ButtonGroupContainer color={"primary"}>
                <NGIDropdownButton
                  hideRoles={[
                    USER_ROLES.INTERNAL_AUDITOR,
                    USER_ROLES.GENERAL,
                    USER_ROLES.IT_OPERATIONS,
                    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                    USER_ROLES.FINANCE,
                  ]}
                  toggleEl={<ButtonGroup color={"primary"} tooltipTitle="Upload" icon={uploadIcon} />}
                  menuItems={[
                    {
                      label: "Upload Meters",
                      callback: openUploadMeterModal,
                    },
                    {
                      label: "Add shipping meters",
                      callback: openShipModal,
                      hideRoles: [USER_ROLES.METER_ENGINEER],
                    },
                    {
                      label: "Add meter manually",
                      callback: () => {
                        history.push("/meters/registration/manual");
                      },
                      hideRoles: [USER_ROLES.METER_ENGINEER],
                    },
                    {
                      label: "Receive Meters",
                      callback: openMeterReceiveModal,
                      roles: [
                        USER_ROLES.SYSTEM_FULL_ADMINS,
                        USER_ROLES.PRODUCT_TECHNICIANS,
                        USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                        USER_ROLES.METER_ENGINEER_LEADERSHIP,
                      ],
                    },
                  ]}
                />
                <ButtonGroup
                  color={"primary"}
                  handleClick={openConfigurationModal}
                  tooltipTitle="Configuration"
                  icon={wrenchIcon}
                />
                <NGIDropdownButton
                  toggleEl={<ButtonGroup color={"primary"} tooltipTitle="Send OTA Update" icon={firmwareIcon} />}
                  roles={[
                    USER_ROLES.METER_ENGINEER_LEADERSHIP,
                    USER_ROLES.METER_ENGINEER,
                    USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                  ]}
                  menuItems={[
                    {
                      label: "From Old to New Firmware",
                      callback: openBulkUpdateFirmwareModal,
                    },
                    {
                      label: "CSV file",
                      callback: openCsvBulkUpdateFirmwareModal,
                    },
                  ]}
                />
                <ButtonGroup
                  color={"primary"}
                  handleClick={openSendCommandsModal}
                  tooltipTitle="Send Commands"
                  icon={mailIcon}
                />
              </ButtonGroupContainer>
            </div>

            <MetersFilter handleFilter={handleFilter} isLoading={isLoading} />
            <MetersSearch handleFilter={handleFilter} isLoading={isLoading} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.METER}
              isLoading={isLoading}
              columns={columns}
              data={meterList}
              updateList={handleFilter}
            />
          </Col>
        </Container>
      </div>
      {open && <ResponseModel isVisible={open} onCancel={() => setOpen(false)} getAssetId={assetId} />}
      {openReport && <ReportModel isVisible={openReport} onCancel={() => setOpenReport(false)} getAssetId={assetId} />}
      {command && <CommandsModel isVisible={openCommandModel} onCancel={closeCommandModel} meterId={assetId} />}
      {uploadModalVisible && (
        <UploadLogModal isVisible={uploadModalVisible} onCancel={closeUploadModal} meterId={assetId} />
      )}
      {downloadModalVisible && (
        <DownloadLogModal isVisible={downloadModalVisible} onCancel={closeDownloadModal} meterId={assetId} />
      )}
      {isUploadModalMeterVisible && (
        <UploadMeterModal isVisible={isUploadModalMeterVisible} onClose={closeUploadMeterModal} />
      )}
      {isBulkUpdateFirmwareVisible && (
        <BulkUpdateFirmwareModal
          isOpen={isBulkUpdateFirmwareVisible}
          onClose={closeBulkUpdateFirmwareModal}
          isCsv={isCsvUpload}
        />
      )}
      {isShipModalVisible && <ShipMeterModal isVisible={isShipModalVisible} onClose={closeShipModal} />}
      {isMeterReceiveOpen && <MeterReceiveModal isVisible={isMeterReceiveOpen} onClose={closeMeterReceiveModal} />}
      {isFactoryTestModalOpen && (
        <FactoryTestModal isOpen={isFactoryTestModalOpen} onClose={closeFactoryTestModal} meter={activeMeter} />
      )}
      {isConfigModalOpen && <ConfigurationModal isOpen={isConfigModalOpen} onClose={closeConfigurationModal} />}
      {isFaultyModalOpen && (
        <MarkAsFaultyModal
          onUpdate={handleFilter}
          isOpen={isFaultyModalOpen}
          isLoading={isLoading}
          activeItem={activeMeter}
          onClose={closeFaultyModal}
        />
      )}
      {isChangeMeterTypeModalVisible && (
        <ChangeMeterTypeModal
          onUpdate={handleFilter}
          isOpen={isChangeMeterTypeModalVisible}
          isLoading={isLoading}
          activeItem={activeMeter}
          onClose={closeChangeMeterTypeModal}
        />
      )}
      {isUpdateFirmwareVisible && (
        <UpdateFirmwareModal
          onUpdate={handleFilter}
          isOpen={isUpdateFirmwareVisible}
          isLoading={isLoading}
          activeItem={activeMeter}
          onClose={closeUpdateFirmwareModal}
        />
      )}
      {isSendCommandsVisible && <SendCommandsModal onClose={closeSendCommandsModal} isBulk={true} />}
    </>
  );
};

export default MeterInformation;
