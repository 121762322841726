import React, { useCallback, useState } from "react";
import { Col, Modal, Row, Space } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "20px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
  title: {
    color: "#666666",
    textTransform: "uppercase",
  },
});

const DiscardModal = ({ onClose, onDiscard, item }) => {
  const classes = useStyles();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleDiscard = useCallback(() => {
    onDiscard(item.transactionId);
    closeConfirmationModal();
  }, [item]);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithDescription}>
          UNPROCESSED PAYMENT
          <span>
            {`${item.customerFullName} - `}
            <CustomLink underlined target="_blank" to={`/customers/customer/${item.customerId}`}>
              {item.customerId}
            </CustomLink>
          </span>
        </div>
      }
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <div className={classes.modalContent}>
        <Row>
          <Col span={12}>
            <div className={classes.title}>CUSTOMER</div>
            <b>{item.customerFullName}</b>
          </Col>
          <Col span={12}>
            <div className={classes.title}>CUSTOMER ID</div>
            <b>
              <CustomLink underlined target="_blank" to={`/customers/customer/${item.customerId}`}>
                {item.customerId}
              </CustomLink>
            </b>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <div className={classes.title}>AMOUNT</div>
            <b>{textFormatter(item.transactionAmount)}</b>
          </Col>
          <Col span={12}>
            <div className={classes.title}>DURATION</div>
            <b>{textFormatter(item.transactionDuration)}</b>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <div className={classes.title}>METER BARCODE</div>
            <b>{textFormatter(item.meterNumber)}</b>
          </Col>
          <Col span={12}>
            <div className={classes.title}>PROCESSING TYPE</div>
            <b>{textFormatter(item.processingType)}</b>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <div className={classes.title}>DEPOT</div>
            <b>{textFormatter(item.depot)}</b>
          </Col>
          <Col span={12}>
            <div className={classes.title}>DATE</div>
            <b>{customDateFormatWithTime(item.transactionDateTime)}</b>
          </Col>
        </Row>
        <Row className={classes.formItem}>
          <Col span={12}>
            <div className={classes.title}>PAYEE PHONE NUMBER</div>
            <b>{textFormatter(item.payeePhoneNumber)}</b>
          </Col>
        </Row>
      </div>
      <Space>
        <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
        <CustomButton onClick={openConfirmationModal} type="primary" text="DISCARD PAYMENT" size="small" />
      </Space>
      {isConfirmationOpen && (
        <ConfirmationModal
          description={"Are you sure to discard this payment?"}
          onCancel={closeConfirmationModal}
          onConfirm={handleDiscard}
        />
      )}
    </Modal>
  );
};

export default DiscardModal;
