import React, { useMemo } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import CustomButton from "components/Common/buttons/CustomButton";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    "&> p": {
      color: "#8C8C8C",
      fontWeight: 300,
      fontSize: 12,
      "&> span": {
        fontWeight: 500,
        color: "black",
      },
    },
  },
});

const AddMemberModal = ({ handleCancel, userList, team, isLoading, onChangeTeam, teamConfig }) => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const filteredUsers = useMemo(
    () => userList.filter(item => item.roles?.some(role => teamConfig[team].roles?.some(r => r === role))),
    [userList]
  );

  return (
    <Modal
      open={true}
      title={
        <div className={classes.title}>
          Add Member{" "}
          <p>
            Select new team member for the <span>{team}</span> team
          </p>
        </div>
      }
      closable={false}
      onCancel={handleCancel}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm name="addMemberForm" form={form} onFinish={({ email }) => onChangeTeam(team, email)}>
          <Col span={24}>
            <Title level={5}>Employee Name</Title>
            <NGIFormItem name="email" rules={[{ required: true, message: "Please select member" }]}>
              <NGISelect placeholder="Please select member">
                {!!filteredUsers &&
                  filteredUsers.map(item => (
                    <Option value={item.email} key={item.email}>
                      {item.name}
                    </Option>
                  ))}
              </NGISelect>
            </NGIFormItem>
          </Col>
          <Space>
            <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Add Member" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default AddMemberModal;
