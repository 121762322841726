import { USER_ROLES } from "utils/constants";

export const TABS = {
  DETAILS: "DETAILS",
  EDIT: "EDIT",
};

export const TABS_BUTTONS = [
  {
    title: "General information",
    key: TABS.DETAILS,
  },
  {
    title: "Edit Package",
    key: TABS.EDIT,
    hideRoles: [
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.D_SM,
      USER_ROLES.A_SM,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.GENERAL,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.FINANCE,
    ],
  },
];

export const SORTED_GENERAL_KEYS = [
  "code",
  "gasFirstPaymentAmount",
  "stoveFirstPaymentAmount",
  "upfrontAmount",
  "stoveRepairCost",
  "balance",
  "deductionPeriod",
  "deductionDay",
  "deductionType",
  "deductionValue",
  "warrantyDuration",
  "stoveChangeEligibilityDuration",
  "stoveChangeEligibleNumber",
  "depots",
  "products",
  "createdAt",
  "createdBy",
  "updatedAt",
  "updatedBy",
];

export const valuesToCheck = [
  "balance",
  "unitPrice",
  "stoveChangeEligibilityDuration",
  "gasFirstPaymentAmount",
  "stoveChangeEligibleNumber",
  "stoveChangeEligibleNumber",
  "warrantyDuration",
  "stoveFirstPaymentAmount",
  "stoveRepairCost",
];
