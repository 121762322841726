import React, { useCallback } from "react";
import { Form, Select, Input } from "antd";
import { FILTER_TYPE_ICCID, FILTER_TYPE_MSISDN } from "./variables";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const SimCardsFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.SIM_CARDS);
  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    const { type, value } = values;
    handleFilter({ [type]: value?.trim(), pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type" rules={[{ required: true, message: "Please select type" }]}>
          <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select type"} allowClear>
            <Option value={FILTER_TYPE_MSISDN}>{FILTER_TYPE_MSISDN}</Option>
            <Option value={FILTER_TYPE_ICCID}>{FILTER_TYPE_ICCID}</Option>
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
          <Input placeholder="Enter value" allowClear pattern={inputTextPattern} title={inputPatternTitle} />
        </Form.Item>

        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default SimCardsFilter;
