import React from "react";
import { Container } from "reactstrap";
import Header from "pages/operations/prospectDetails/Header";
import DataTabs from "pages/operations/prospectDetails/DataTabs";

const ProspectDetails = () => (
  <>
    <div className="page-content">
      <Container fluid>
        <Header />
        <DataTabs />
      </Container>
    </div>
  </>
);

export default ProspectDetails;
