import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TimePicker } from "antd";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
    background: "#F5F5F5!important",
    border: "none!important",
    padding: 14,
    borderRadius: 30,

    "&>input": {
      background: "#F5F5F5!important",
    },
    "&input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const DEFAULT_DATEFORMAT = "HH:mm";

const NGITimeRangePicker = ({ dateFormat = DEFAULT_DATEFORMAT, allowClear = false, date, ...props }) => {
  const classes = useStyles();

  const disabledHours = useCallback(() => {
    const hours = [];
    const currentHour = moment().hour();
    const currentMinute = moment().minute();
    const startHour =
      moment(date).format("DD.MM.YYYY") === moment().format("DD.MM.YYYY")
        ? currentMinute < 30
          ? currentHour
          : currentHour + 1
        : 7;
    for (let i = 0; i <= 24; i++) {
      if (i < startHour || i > 19) {
        hours.push(i);
      }
    }

    return hours;
  }, [date]);

  const disabledMinutes = useCallback(selectedHour => {
    const minutes = [];
    const currentMinute = moment().minute();
    for (let i = 0; i <= 60; i++) {
      if (
        (selectedHour >= 19 && i >= 30) ||
        (selectedHour === moment().hour() && currentMinute < 30 && i < 30) ||
        selectedHour === -1
      ) {
        minutes.push(i);
      }
    }
    return minutes;
  }, []);

  return (
    <TimePicker.RangePicker
      {...props}
      inputReadOnly={true}
      allowClear={allowClear}
      className={classes.root}
      hideDisabledOptions
      disabledTime={() => ({ disabledHours, disabledMinutes })}
      format={dateFormat}
    />
  );
};
export default NGITimeRangePicker;
