import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import NGIForm from "components/Common/form/NGIForm";
import { CardBody } from "reactstrap";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { fetchTeamsList, saveMetersPackagingReport } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import { BOX_SIZES, BOXES } from "pages/meter-management/meterBox/variables";
import { makeStyles } from "@material-ui/core/styles";
import closeIcon from "../../../assets/images/svg/closeSmall.svg";
import noInfo from "../../../assets/images/svg/noInfo.svg";
import { printLabel } from "pages/meter-management/meterBox/utils";
import classnames from "classnames";
import InfoModal from "components/Common/modals/InfoModal";
import { AUTH_USER } from "utils/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  cardBody: {
    padding: 0,
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",
    justifyContent: "space-around",
  },

  input: {
    marginBottom: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  titleWithAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  scan: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: "50px",
    cursor: "pointer",
  },
  close: {
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  select: { width: "100%" },
  index: { marginRight: 5 },
  descriptions: {
    fontSize: 13,
    fontWeight: 300,
    transform: "translateY(-16px)",
    color: "rgba(102, 102, 102, 1)",
  },
  noMeters: {
    marginLeft: 16,
  },
  title: {
    color: "rgba(68, 68, 68, 1)",
    marginBottom: 20,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  description: {
    fontSize: 13,
    fontWeight: 300,
    color: "rgba(102, 102, 102, 1)",
  },
});

const MeterBoxModal = ({ isVisible, onClose, updateData }) => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [size, setSize] = useState(BOX_SIZES.BIG);
  const [meters, setMeters] = useState([""]);
  const [failedMeters, setFailedMeters] = useState([]);
  const [form] = Form.useForm();
  const [teams, setTeams] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [label, setLabel] = useState(null);

  const hasEmptyValues = useMemo(() => meters.includes(""), [meters]);

  const handleChangeSize = useCallback(
    value => {
      setSize(value);
      if (meters?.length > value) {
        setMeters(prevState => {
          prevState.splice(value, meters.length - value);
          return [...prevState];
        });
      }
    },
    [meters]
  );

  const handleAddMeter = useCallback(() => {
    if (meters?.length < size) setMeters(prevState => [...prevState, ""]);
  }, [meters, size]);

  const handleRemoveMeter = useCallback(
    index =>
      setMeters(prevState => {
        prevState.splice(index, 1);
        return [...prevState];
      }),
    []
  );

  const handleMeterChange = useCallback((event, index) => {
    setMeters(prevState => {
      prevState[index] = event?.target?.value;
      return [...prevState];
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    fetchTeamsList({})
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items?.length === 0) {
          warnMessage("Teams were not found");
          setLoader(false);
        }
        setTeams(items || []);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setTeams([null]);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const handleSave = useCallback(() => {
    const { teamName } = form.getFieldsValue();
    setLoader(true);
    saveMetersPackagingReport({ teamName, serialNumbers: meters })
      .then(res => {
        setLoader(false);
        setLabel(res?.data?.result?.boxNumber);
      })
      .catch(err => {
        if (!!err.response.data.errors.length) setFailedMeters(err.response.data.errors[0].items);
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [form, meters]);

  const handlePrintLabel = useCallback(label => {
    printLabel("iframeContentsToPrint", `<html><body><h1>${label}</h1></body></html>`);
    onClose();
    closeModal();
    updateData({ reportType: "Packaging", location: AUTH_USER?.depotName });
  }, []);

  useEffect(() => {
    if (!!label) setShowModal(true);
  }, [label]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <Modal title={"Box and Send"} open={isVisible} onCancel={onClose} closable={false} footer={[]}>
      <div>
        <Spin spinning={isLoading}>
          <div className={classes.descriptions}>Please fill in all mandatory fields</div>
          <CardBody className={classes.cardBody}>
            <NGIForm name="meterBox" isUpdate form={form} onFinish={handleSave} className={classes.formContainer}>
              <div className={classes.modalContent}>
                <Row className={classes.sectionItem}>
                  <Col span={24}>
                    <Title level={5}>General Information</Title>
                    <Divider />
                  </Col>
                </Row>
                <Row className={classes.formItem}>
                  <Col span={11}>
                    <Title level={5}>Box Size</Title>
                    <NGISelect
                      showSearch
                      placeholder="Please select box size"
                      value={size}
                      className={classes.select}
                      onChange={handleChangeSize}
                    >
                      {BOXES?.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </NGISelect>
                  </Col>
                  <Col span={11}>
                    <Title level={5}>Boxed by Team</Title>
                    <Form.Item name="teamName" rules={[{ required: true, message: "Please select Team" }]}>
                      <NGISelect showSearch placeholder="Please select Team">
                        {teams?.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </NGISelect>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className={classes.sectionItem}>
                  <Col span={24}>
                    <Title level={5}>
                      Meters List - {meters?.length}/{size}
                    </Title>
                    <Divider />
                  </Col>
                </Row>
                <Row className={classes.formItem}>
                  {!!meters?.length ? (
                    meters.map((item, index) => (
                      <Col key={item} span={11} className={classes.input}>
                        <span className={classes.index}>{index + 1}.</span>
                        <NGIInput
                          placeholder="Enter serial number"
                          value={item}
                          onChange={event => handleMeterChange(event, index)}
                          className={classes.red}
                          style={{ color: failedMeters.includes(item) && "red" }}
                        />
                        <div className={classes.close} onClick={() => handleRemoveMeter(index)}>
                          <img src={closeIcon} alt="remove" />
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col span={24} className={classnames(classes.input, classes.flex)}>
                      <img src={noInfo} alt="no info" />
                      <div className={classes.noMeters}>
                        <Title level={5} className={classes.title}>
                          No meters added.
                        </Title>
                        <div className={classes.description}>
                          Please add meters to the box in order to proceed with sending them.
                        </div>
                      </div>
                    </Col>
                  )}
                  {meters.length < size && (
                    <Col span={11}>
                      <div className={classes.scan} onClick={handleAddMeter}>
                        + SCAN AND ADD METER
                      </div>
                    </Col>
                  )}
                </Row>
                <Divider />
              </div>
            </NGIForm>
            <Col span={24}>
              <Space>
                <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
                <CustomButton
                  disabled={!meters.length || hasEmptyValues}
                  onClick={form.submit}
                  type="primary"
                  size="small"
                  text="Print label and pack box"
                />
              </Space>
            </Col>
          </CardBody>
        </Spin>
        {showModal && (
          <InfoModal
            onCancel={() => handlePrintLabel(label)}
            description={"Please print and stick label on the box."}
          />
        )}
        <iframe id="iframeContentsToPrint" style={{ height: "0px", width: "0px", position: "absolute" }}></iframe>
      </div>
    </Modal>
  );
};

export default MeterBoxModal;
