/* ID_TYPES */

export const GET_DEPOT = "GET_DEPOT"
export const GET_DEPOT_FAIL = "GET_DEPOT_FAIL"
export const GET_DEPOT_SUCCESS = "GET_DEPOT_SUCCESS"

export const CREATE_DEPOT = "CREATE_DEPOT "
export const CREATE_DEPOT_FAIL = "CREATE_DEPOT_FAIL"
export const CREATE_DEPOT_SUCCESS = "CREATE_DEPOT_SUCCESS"

