import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardBody } from "reactstrap";
import { booleanStatusFormatter } from "components/Common/formatter";
import { createBulkTaskApi, retrieveCustomerContractUrl } from "utils/api";
import { Collapse, Spin } from "antd";
import requestPhotoIcon from "assets/images/svg/requestPhotoIcon.svg";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import ContractModal from "pages/customer-management/customerDetails/Tabs/ContractModal";
import { AUTH_USER } from "utils/constants";
import classnames from "classnames";

const { Panel } = Collapse;

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  itemTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  itemImages: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  contractVersion: {
    marginInline: 10,
  },
  img: {
    marginInline: 5,
    "& img": {
      height: 100,
      width: 80,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  dataError: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    color: "red",
    width: "30%",
  },
  requestPhotoButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 3,
    marginRight: 5,
    border: "1px dashed rgba(0, 0, 0, 0.1)",
    height: 100,
    width: 80,
    borderRadius: 6,
    cursor: "pointer",
    backgroundColor: "rgba(0, 133, 255, 0.2)",
  },
  disabled: {
    cursor: "default",
    opacity: 0.5,
  },
});

const ContractHistoryTab = ({ customer, contracts }) => {
  const classes = useStyles();
  const [url, setUrl] = useState(false);
  const [groupedContracts, setGroupedContracts] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [showRequestPhotoModal, setShowRequestPhotoModal] = useState(false);

  const groupContractByVersion = useCallback(
    (contracts = []) =>
      contracts.reduce(function (r, a) {
        r[a.version] = r[a.version] || [];
        r[a.version].push({ ...a });
        return r;
      }, {}),
    []
  );

  const openModal = useCallback(event => setUrl(event.target.dataset.url), []);
  const closeModal = useCallback(() => setUrl(undefined), []);

  const getAllUrls = useCallback(fileList => {
    setLoader(true);
    Promise.all(
      fileList.map(item =>
        retrieveCustomerContractUrl(customer.id, item.path).then(({ data: { result } }) => ({ ...result, ...item }))
      )
    ).then(res => {
      const grouped = groupContractByVersion(res);
      setGroupedContracts(grouped);
      setLoader(false);
    });
  }, []);

  const openRequestModal = useCallback(() => setShowRequestPhotoModal(true), []);
  const closeRequestModal = useCallback(() => setShowRequestPhotoModal(false), []);
  const handleRequestPhoto = useCallback(() => {
    setLoader(true);
    const assignedBy = AUTH_USER.email || "";
    const options = {
      assignedBy: assignedBy,
      assignedTo: customer.technician,
      assignedToName: customer.technicianName || assignedBy,
      assignedToDepot: customer.depot,
      comments: "Manually created task through NGI Dashboard",
      category: "Contract Change",
      source: "ngi-dashboard",
      customer: {
        id: customer.id,
        name: `${customer.firstName} ${customer.lastName}`,
        phone: customer.phone || customer.secondaryPhone,
        status: customer.status,
        location: {
          lat: customer.location?.lat || 0,
          lon: customer.location?.lon || 0,
        },
        address: customer.location?.region,
      },
    };
    setShowRequestPhotoModal(false);
    createBulkTaskApi({ tasks: [options], source: "ngi-dashboard" })
      .then(() => {
        setLoader(false);
        successMessage(`Task was assigned successfully`);
      })
      .catch(error => {
        setLoader(false);
        defaultNgiErrorMethod(error);
      });
  }, [customer]);

  useEffect(() => {
    if (contracts) {
      getAllUrls(contracts);
    }
  }, []);

  return (
    <div className={classes.root}>
      <Spin spinning={isLoading}>
        <Collapse>
          {!!Object.keys(groupedContracts).length ? (
            Object.keys(groupedContracts)
              .sort()
              .reverse()
              .map(key => (
                <Panel
                  key={key}
                  header={
                    <div className={classes.itemTitle}>
                      <b>Contract</b>
                      <div className={classes.contractVersion}>({key})</div>
                      <div>{booleanStatusFormatter(groupedContracts[key].findIndex(item => item.isActive) !== -1)}</div>
                    </div>
                  }
                >
                  <div className={classes.itemImages}>
                    {customer.status === "Active" && groupedContracts[key].findIndex(item => item.isActive) !== -1 && (
                      <div
                        onClick={groupedContracts[key].length >= 3 ? null : openRequestModal}
                        className={classnames(
                          classes.requestPhotoButton,
                          groupedContracts[key].length >= 3 && classes.disabled
                        )}
                      >
                        <img src={requestPhotoIcon} alt="Request Photo" />
                      </div>
                    )}
                    {groupedContracts[key]
                      ?.sort((a, b) => a.createdAt - b.createdAt)
                      ?.map(item => (
                        <div key={item.path} className={classes.img}>
                          <img data-url={item?.url} onClick={openModal} src={item?.url} alt="Photo preview" />
                        </div>
                      ))}
                  </div>
                </Panel>
              ))
          ) : (
            <CardBody className="text-center">
              <i className="bx bx-warning" />
              <h5>No data to display</h5>
            </CardBody>
          )}
        </Collapse>
        {showRequestPhotoModal && (
          <ConfirmationModal
            onCancel={closeRequestModal}
            onConfirm={handleRequestPhoto}
            title={"Contract Update Request"}
            description={`You are about to assign a “Contract Update” task to TSR ${
              customer.technicianName || "-"
            }.\n\nAre you sure you want to proceed?`}
          />
        )}
        {!!url && <ContractModal isOpen={!!url} onClose={closeModal} url={url} />}
      </Spin>
    </div>
  );
};

export default ContractHistoryTab;
