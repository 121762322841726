import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input } from "antd";
import { SEARCH_TYPES, SEARCH_TYPES_OPTIONS } from "./variables";
import CustomButton from "components/Common/buttons/CustomButton";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const MetersSearch = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(null);
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.METER);
  const {
    list: { meterCondition },
  } = useSelector(DictionariesSelector);

  const onSearch = useCallback(values => {
    const { type, value } = values;
    const { pageSize } = getFilters();

    handleFilter({ [type]: value?.trim(), pageSize });
  }, []);

  useEffect(() => {
    if (!isEmpty(filters)) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (property in SEARCH_TYPES_OPTIONS) {
        form.setFieldsValue({
          type: property,
          value,
        });
        setSelectedType(property);
      } else {
        form.setFieldsValue({
          type: null,
          value: null,
        });
        setSelectedType(null);
      }
    }
  }, []);

  const onTypeChanged = useCallback(
    value => {
      form.setFieldsValue({
        value: null,
      });
      setSelectedType(value);
    },
    [form]
  );

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.CONDITIONS));
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type" rules={[{ required: true, message: "Please select type" }]}>
          <Select showSearch placeholder="Select type" allowClear onChange={onTypeChanged}>
            {Object.keys(SEARCH_TYPES_OPTIONS).map(key => (
              <Option key={key} value={key}>
                {SEARCH_TYPES_OPTIONS[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        {selectedType !== SEARCH_TYPES.CONDITION && (
          <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
            <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
          </Form.Item>
        )}

        {selectedType === SEARCH_TYPES.CONDITION && (
          <Form.Item name="value" rules={[{ required: true, message: "Please select value" }]}>
            <Select showSearch placeholder="Select value" allowClear>
              {meterCondition?.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default MetersSearch;
