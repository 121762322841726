import { TRIP_STATUSES } from "pages/stock-management/trips/constants";
import { STO_TYPES } from "pages/stock-management/stockTransferOrders/constants";

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const DISABLE_EDIT_FOR_STATUS = {
  startLocation: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  preferredDate: [TRIP_STATUSES.IN_TRANSIT],
  driverName: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  driverLicenseID: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  truckLicensePlate: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  trailerLicensePlate: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  addNewSTO: [TRIP_STATUSES.EXPIRED],
  removeEditSTO: [TRIP_STATUSES.EXPIRED],
};

export const getIsDisabledField = (status, field) => DISABLE_EDIT_FOR_STATUS[field].includes(status);

export const UPDATE_REASONS = {
  [TRIP_STATUSES.IN_TRANSIT]: {
    [STO_TYPES.OUTBOUND]: [
      {
        value: "updateCorresponding",
        title: "Update corresponding order",
        desc: "Increase the reflective Inbound order",
      },
      {
        value: "createNew",
        title: "Create new Inbound STO",
        desc: "Create new Inbound STO with the same qty.",
      },
    ],
    [STO_TYPES.INBOUND]: [
      {
        value: "updateCorrespondingInbound",
        title: "Update corresponding order",
        desc: "Increase the reflective Inbound order",
      },
      {
        value: "createNew",
        title: "Create new Inbound STO",
        desc: "Create new Inbound STO with the same qty.",
      },
    ],
  },
};

export const UNASSIGN_CREATE_TYPE = {
  RECREATE: "RECREATE",
  CREATE_NEW: "CREATE_NEW",
  OUTBOUND_DEDUCTION: "OUTBOUND_DEDUCTION",
  INBOUND_DEDUCTION: "INBOUND_DEDUCTION",
};
