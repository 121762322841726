import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NGIInput from "components/Common/inputs/NGIInput";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { warnMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  assetItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: 50,
    padding: "5px 10px",
    marginBottom: 10,
  },
  remove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  dataField: {
    width: "20%",
    fontWeight: 600,
    fontSize: 12,
    "&> span": {
      fontWeight: 300,
    },
  },
  assetQTY: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "&>input": {
      width: "70%",
      height: 30,
      padding: "0 10px",
      backgroundColor: "#FFF!important",
    },
  },
});

const AssetRow = ({ item, index, setAssets, type, onRemoveAsset, allowRemove }) => {
  const classes = useStyles();

  const handleQtyChange = useCallback(
    event =>
      setAssets(prevState => {
        const value = event.target.value;
        if (value && (Number(value) < 1 || Number(value) > item.quantity)) {
          warnMessage(`Cannot be 0 or less, max ${item.quantity}`);
        } else if (!isNaN(value)) {
          prevState[index].newQuantity = Number(value);
        }
        return [...prevState];
      }),
    [item, index]
  );

  return (
    <div className={classes.assetItem}>
      <div className={classes.dataField}>{item.group}</div>
      <div className={classes.dataField}>{item[type]}</div>
      <div className={classes.dataField}>
        {item.quantity}
        <span> PCS</span>
      </div>
      <div className={classes.assetQTY}>
        <NGIInput size="small" value={item.newQuantity} placeholder="Enter QTY" onChange={handleQtyChange} />
        {allowRemove && (
          <div className={classes.remove} data-id={item.id} onClick={onRemoveAsset}>
            <img src={closeIcon} alt="remove" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetRow;
