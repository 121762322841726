import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Text } from "recharts";

const useStyles = makeStyles({
  assetItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: 50,
    padding: 14,
    marginBottom: 10,
  },
  text: {
    color: "grey",
  },
});

const EmptyAssetItem = () => {
  const classes = useStyles();

  return (
    <div className={classes.assetItem}>
      <Text strong className={classes.text}>
        No STO assigned yet
      </Text>
    </div>
  );
};

export default EmptyAssetItem;
