import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "antd";
import { textFormatter } from "components/Common/formatter";
import { getEmptyQuestionnaire, getMappedQuestionnaire } from "pages/operations/leadDetails/utils";
import { Card, CardBody } from "reactstrap";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "100%",
    paddingRight: 25,
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    width: "60%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    width: "40%",
    whiteSpace: "break-spaces",
  },
  red: {
    color: "#ff4d4f",
  },
  columnTitle: {
    fontSize: "14px!important",
    fontWeight: "400!important",
  },
  form: {
    width: "100%",
  },
});

const QuestionsTab = ({ taskSurvey = {}, leadQuestionnaire }) => {
  const classes = useStyles();
  const { items: answers } = taskSurvey;
  const { items: questionnaire } = leadQuestionnaire || {};

  const mappedQuestionnaire = useMemo(() => getMappedQuestionnaire(questionnaire), [questionnaire]);
  const emptyQuestionnaire = useMemo(() => getEmptyQuestionnaire(questionnaire), [questionnaire]);

  const getAnswer = useCallback(
    item => {
      const type = mappedQuestionnaire[item?.questionId].type;
      switch (type) {
        case "Select Single":
        case "Select":
          return mappedQuestionnaire[item?.questionId]?.answers[item?.answerId];
        case "Select Multiple":
          return item?.answerIds.map(answer => mappedQuestionnaire[item?.questionId]?.answers[answer]).join(", ");
        case "Instruction":
          return mappedQuestionnaire[item?.questionId]?.answers.join("\n");
        default:
          return item?.answer;
      }
    },
    [mappedQuestionnaire]
  );

  if (!mappedQuestionnaire)
    return (
      <Card>
        <CardBody className="text-center">
          <i className="bx bx-warning" />
          <h5>No data to display</h5>
        </CardBody>
      </Card>
    );

  return (
    <div className={classes.root}>
      <div className={classes.informationContainer}>
        <div className={classes.column}>
          {(answers || emptyQuestionnaire)?.map(item => (
            <>
              <div className={classes.rowData} key={item?.questionId}>
                <div className={classes.title}>{mappedQuestionnaire[item?.questionId]?.question}</div>
                <div className={classes.data}>{textFormatter(getAnswer(item))}</div>
              </div>
              <Divider />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionsTab;
