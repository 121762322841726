import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container } from "reactstrap";
import { taskColumns } from "./taskColumns";
import { getTaskCategory } from "store/operations/tasks/actions";
import { retrieveAllTasks } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { TaskSelector } from "store/operations/tasks/selectors";
import { phoneBeautifier } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useLocation } from "react-router";
import TasksFilter from "pages/operations/cancelledTasks/TasksFilter";

const CancelledTasks = () => {
  const dispatch = useDispatch();
  const { taskCategory, taskStatus } = useSelector(TaskSelector);
  const [isLoading, setLoader] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.TASK_CANCELLED);
  const searchParams = useLocation().search;
  const customerId = new URLSearchParams(searchParams).get("customerId");

  useEffect(() => {
    dispatch(getTaskCategory());
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  useEffect(() => {
    handleOnFilter(customerId ? { customerId } : filters);
  }, []);

  const handleOnFilter = (options = {}) => {
    setLoader(true);
    let filterOptions = options;

    if (filterOptions.hasOwnProperty("customerPhone")) filterOptions = phoneBeautifier(filterOptions["customerPhone"]);

    const myConstOptions = AUTH_USER?.roles?.includes(USER_ROLES.SYSTEM_FULL_ADMINS)
      ? { ...filterOptions, status: "Cancelled" }
      : { ...filterOptions, status: "Cancelled", depot: AUTH_USER?.depotName };
    setFilters(myConstOptions);
    retrieveAllTasks(myConstOptions)
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items.length === 0) {
          warnMessage("Task was filtered");
        }
        setTaskList(res?.data);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setTaskList([]);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <TasksFilter
              onFilter={handleOnFilter}
              isLoading={isLoading}
              showStatus={taskStatus}
              showCategory={taskCategory}
            />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.TASK_CANCELLED}
              isLoading={isLoading}
              columns={taskColumns}
              data={taskList}
              updateList={handleOnFilter}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default CancelledTasks;
