export const getFormErrors = (form, fields) => {
  let errors = 0;
  fields.forEach(item => {
    if (form.getFieldError(item).length > 0) {
      errors = errors + 1;
    }
  });
  return errors;
};

export const getLeadQuestionnaire = lead => {
  if (lead.registrationSurvey) {
    return {
      id: lead?.registrationSurvey?.id,
    };
  }
  return {
    name: "Register",
  };
};

export const getEmptyQuestionnaire = questionnaire =>
  questionnaire?.map(item => ({
    questionId: item.id,
    answerId: "",
  }));

export const getMappedQuestionnaire = questionnaire =>
  questionnaire?.reduce((acc, item) => {
    const answers = item.options
      ? item.options.reduce((a, i) => {
          a[i.id] = i.text;
          return a;
        }, {})
      : item?.items?.map(item => item.text);
    acc[item.id] = {
      question: item.question || item.title,
      type: item.type,
      answers,
    };
    return acc;
  }, {});
