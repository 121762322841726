import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import leftArrow from "../../../assets/images/svg/leftArrow.svg";

const useStyles = makeStyles({
  root: {
    color: "#666666",
    padding: "10px 15px",
    fontSize: 12,
    background: "#FFFFFF",
    boxShadow: "0px 8px 16px rgb(0 0 0 / 8%)",
    transition: "0.3s",
    fontWeight: 300,
    borderRadius: 50,
    textTransform: "uppercase",
    height: 40,
    minWidth: "max-content",
    lineHeight: "22px",

    "&:hover": {
      opacity: 0.8,
    },

    "& > img": {
      marginRight: 5,
    },
  },
});

const BackButtonLink = ({ to, children }) => {
  const classes = useStyles();
  return (
    <Link className={classes.root} to={to}>
      <img src={leftArrow} alt="back button" />
      {children}
    </Link>
  );
};

export default BackButtonLink;
