import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Option } from "antd/lib/mentions";
import { retrieveTasksSchedule } from "utils/api";
import { Divider, Select } from "antd";
import NGICollapsibleTable from "components/Common/NGICollapsibleTable";
import { customerAvailabilityColumns, taskScheduleTypes } from "pages/dashboard/variables";
import { getMappedData } from "pages/dashboard/utils";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import CustomerAvailabilityCalendarModal from "pages/dashboard/sections/CustomerAvailabilityCalendarModal";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { TSRSelector } from "store/TSR/selectors";

const useStyles = makeStyles({
  filter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  filterTitle: {
    padding: 10,
    color: "rgba(140, 140, 140, 1)",
  },
  filterSelect: {
    fontSize: 15,
    "&>.ant-select-selector": {
      border: "0!important",
    },
  },
  divider: {
    margin: 0,
    padding: 10,
  },
  counter: {
    fontSize: 16,
    flexGrow: 1,
    textAlign: "end",
    fontWeight: 500,

    "&>span:first-child": {
      marginRight: 5,
    },
    "&>span:nth-child(2)": {
      marginLeft: 5,
      color: "rgba(23, 195, 71, 1)",
    },
  },
  tsrSelect: {
    maxWidth: 220,
  },
});

export const CustomerAvailabilitySection = () => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [taskScheduleState, setTaskScheduleState] = useState({ list: [], available: 0, all: 0, mapping: {} });
  const [taskScheduleType, setTaskScheduleType] = useState(taskScheduleTypes[0]);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedTSR, setSelectedTSR] = useState(null);
  const { TSRList } = useSelector(TSRSelector);

  useEffect(() => {
    filterCustomerAvailability(taskScheduleType);
  }, []);

  const onTypeChange = useCallback(value => {
    setTaskScheduleType(value);
    setSelectedTSR(null);
    filterCustomerAvailability(value);
  }, []);

  const onTSRChange = useCallback(
    value => {
      setSelectedTSR(value);
      filterCustomerAvailability(taskScheduleType, value);
    },
    [taskScheduleType]
  );

  const filterCustomerAvailability = useCallback((type, assignedTo = null) => {
    setLoader(true);
    retrieveTasksSchedule({ breakdown: type, assignedTo })
      .then(res => {
        const { preparedData, available, all, mapping } = getMappedData(res?.data?.result);
        setTaskScheduleState({ list: preparedData, available, all, mapping });
        setLoader(false);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, []);

  const onItemClick = useCallback(item => setActiveItem(item), []);
  const onModalClose = useCallback(() => setActiveItem(null), []);

  return (
    <>
      <NGICollapsibleTable
        isLoading={isLoading}
        headerContent={
          <>
            <div className={classes.filter}>
              <div className={classes.filterTitle}>Customer Availability:</div>
              <Select
                showSearch
                value={taskScheduleType}
                className={classes.filterSelect}
                placeholder={"Select type"}
                onChange={onTypeChange}
              >
                {taskScheduleTypes.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <>
                <div className={classes.filterTitle}>TSR:</div>
                <Select
                  showSearch
                  value={selectedTSR}
                  className={classnames(classes.filterSelect, classes.tsrSelect)}
                  placeholder={"Select TSR"}
                  onChange={onTSRChange}
                  allowClear
                >
                  {TSRList.map(item => (
                    <Option key={item.id} value={item.email}>
                      {item.email}
                    </Option>
                  ))}
                </Select>
              </>
              <div className={classes.counter}>
                <span>{taskScheduleState.all}</span>/<span>{taskScheduleState.available}</span>
              </div>
            </div>

            <Divider className={classes.divider} />
          </>
        }
        data={taskScheduleState.list}
        columns={customerAvailabilityColumns(taskScheduleType, onItemClick, taskScheduleState.mapping)}
      />
      {activeItem && (
        <CustomerAvailabilityCalendarModal onClose={onModalClose} type={taskScheduleType} activeItem={activeItem} />
      )}
    </>
  );
};
