export const ACTION_TYPE = {
  ASSIGN_PACKAGE: "ASSIGN_PACKAGE",
  ASSIGN_DEPOT: "ASSIGN_DEPOT",
  LINK_TSR: "LINK_TSR",
};

export const searchOptions = [
  {
    value: "date",
    title: "Installation Date",
  },
  {
    value: "id",
    title: "Customer ID",
  },
  {
    value: "firstName",
    title: "First Name",
  },
  {
    value: "lastName",
    title: "Last Name",
  },
  {
    value: "depot",
    title: "Depot",
  },
];
export const dateFormat = "DD/MM/YYYY";

export const TASK_STATUS = {
  APPROVE: "APPROVE",
  CANCEL: "CANCEL",
};
export const TASK_STATUSES_OPTIONS = {
  [TASK_STATUS.APPROVE]: "TASK APPROVED",
  [TASK_STATUS.CANCEL]: "TASK DECLINED",
};
