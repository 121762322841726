import { Container } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATION_RULES } from "./constants";
import Header from "./Header";
import { Card, CardBody } from "reactstrap";
import NGIForm from "components/Common/form/NGIForm";
import { Col, Divider, Form, Row, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import { makeStyles } from "@material-ui/core/styles";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import { uniq } from "lodash";
import classnames from "classnames";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NewStopRow from "pages/stock-management/trips/newTrip/NewStopRow";
import NGIInput from "components/Common/inputs/NGIInput";
import CustomButton from "components/Common/buttons/CustomButton";
import moment from "moment";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import { v4 as uuidv4 } from "uuid";
import { createTrip } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { useHistory } from "react-router";
import { validatedStops } from "pages/stock-management/trips/utils";
import { USER_TENANT_NAME } from "utils/constants";
import { getTrimmedObjectValues } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  formItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  addNewStop: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
});

const NewTrip = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [stops, setStops] = useState([]);
  const [preferredTime, setPreferredTime] = useState("");

  const {
    list: { depot, truckLicensePlates },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.TRUCK_LICENSE_PLATES));
  }, []);

  const onSave = useCallback(
    values => {
      const data = {
        ...getTrimmedObjectValues(values),
        stops: validatedStops(stops),
        preferredTime,
        preferredDate: customDateFormatWithoutTime(values.preferredDate),
      };

      setIsLoading(true);
      createTrip(data)
        .then(() => {
          successMessage("Trip was created successfully");
          setIsLoading(false);
          handleCancel();
        })
        .catch(e => {
          setIsLoading(false);
          defaultNgiErrorMethod(e);
        });
    },
    [preferredTime, stops]
  );

  const handleCancel = useCallback(() => {
    history.push("/stock-management/trips");
  }, []);

  const onAddStop = useCallback(() => setStops(prevState => [...prevState, { id: uuidv4(), address: " " }]), []);
  const onRemoveStop = useCallback(e => {
    const { id } = e.currentTarget.dataset;
    setStops(prevState => prevState.filter(item => item.id !== id));
  }, []);

  const handleChangeData = useCallback(data => {
    setPreferredTime(moment(data).format("HH:mm"));
  }, []);

  const onStartLocationChange = useCallback(value => {
    setStops(prevState => {
      prevState[0] = { id: uuidv4(), depot: value, address: " " };
      return [...prevState];
    });
  }, []);

  return (
    <div className="page-content">
      <Container fluid maxWidth={"xl"}>
        <Header />
        <div className={classes.root}>
          <Card className={classes.container}>
            <CardBody className={classes.cardBody}>
              <Spin spinning={isLoading}>
                <NGIForm name="newTrip" form={form} onFinish={onSave}>
                  <TitleWithDivider title="General Information" />
                  <Row className={classes.formItem}>
                    <Col span={7}>
                      <Title level={5}>Start Location</Title>
                      <NGIFormItem name="startLocation" rules={VALIDATION_RULES}>
                        <NGISelect showSearch placeholder={"Select value"} onChange={onStartLocationChange}>
                          {depot &&
                            depot[USER_TENANT_NAME] &&
                            uniq(depot[USER_TENANT_NAME])
                              ?.sort()
                              ?.map(item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                        </NGISelect>
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Trip Date</Title>
                      <NGIFormItem name="preferredDate" rules={VALIDATION_RULES}>
                        <NGIDatePicker
                          showTime={{ format: "HH:mm" }}
                          disabledPasDates
                          allowClear
                          onChange={handleChangeData}
                          dateFormat={"YYYY-MM-DD HH:mm"}
                        />
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Driver Name</Title>
                      <NGIFormItem name="driverName" rules={VALIDATION_RULES}>
                        <NGIInput placeholder={"Please enter value"} />
                      </NGIFormItem>
                    </Col>
                  </Row>

                  <Row className={classes.formItem}>
                    <Col span={7}>
                      <Title level={5}>Driver License ID</Title>
                      <NGIFormItem
                        name="driverLicenseID"
                        rules={[
                          ...VALIDATION_RULES,
                          {
                            max: 50,
                            message: "The max length is 50",
                          },
                        ]}
                      >
                        <NGIInput placeholder={"Please add value"} />
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Truck License Plate</Title>
                      <NGIFormItem name="truckLicensePlate" rules={VALIDATION_RULES}>
                        <NGISelect showSearch placeholder={"Select value"}>
                          {truckLicensePlates &&
                            truckLicensePlates[USER_TENANT_NAME] &&
                            truckLicensePlates[USER_TENANT_NAME]?.map(item => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                        </NGISelect>
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Trailer License Plate</Title>
                      <NGIFormItem
                        name="trailerLicensePlate"
                        rules={[
                          ...VALIDATION_RULES,
                          {
                            max: 50,
                            message: "The max length is 50",
                          },
                        ]}
                      >
                        <NGIInput placeholder={"Please add value"} />
                      </NGIFormItem>
                    </Col>
                  </Row>

                  <TitleWithDivider title="List Of Stops" />
                  <div>
                    {stops.map((item, i) => (
                      <NewStopRow
                        depot={depot}
                        key={item.id}
                        index={i}
                        setStops={setStops}
                        onRemoveStop={onRemoveStop}
                        item={item}
                      />
                    ))}

                    <div
                      className={classnames(
                        classes.addNewStop,
                        !form.getFieldValue("startLocation") && classes.disabled
                      )}
                      onClick={onAddStop}
                    >
                      + Add New Stop
                    </div>
                  </div>
                  <Divider />
                  <div className={classes.buttons}>
                    <Space>
                      <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
                      <CustomButton htmlType="submit" type="primary" size="small" text="Create Trip" />
                    </Space>
                  </div>
                </NGIForm>
              </Spin>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default NewTrip;
