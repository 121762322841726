import { textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

export const taskScheduleTypes = ["Category", "Source"];
export const taskStatusTypes = ["PriorityTasks", "AllTasks", "AssignedTo", "Source"];
export const taskSourceTypes = ["PriorityTasks", "AllTasks", "AssignedTo"];

export const customerAvailabilityColumns = (type, onClick, mapping) => [
  {
    title: type,
    dataIndex: "name",
    render: cellContent => (
      <CustomLink color={"#0085FF"} underlined onClick={() => onClick(cellContent)}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: `Available (${mapping.today})`,
    dataIndex: "today",
    render: cellContent => textFormatter(cellContent, "#17C347"),
  },
  {
    title: `Available Tomorrow (${mapping.tomorrow})`,
    dataIndex: "tomorrow",
    render: textFormatter,
  },
  {
    title: `Available 2-7 Days (${mapping.nextWeek})`,
    dataIndex: "nextWeek",
    render: textFormatter,
  },
  {
    title: `After 7 days (${mapping.afterNextWeek})`,
    dataIndex: "afterNextWeek",
    render: textFormatter,
  },
  {
    title: `Not Called Yet (${mapping.notCalledYet})`,
    dataIndex: "notCalledYet",
    render: textFormatter,
  },
  {
    title: `Not Reachable (${mapping.unreachable})`,
    dataIndex: "unreachable",
    render: textFormatter,
  },
];

export const statusStatsColumns = (type, mapping) => [
  {
    title: type,
    dataIndex: "name",
    render: textFormatter,
  },
  {
    title: `Assigned (${mapping.assigned})`,
    dataIndex: "assigned",
    render: textFormatter,
  },
  {
    title: `InProgress (${mapping.inProgress})`,
    dataIndex: "inProgress",
    render: textFormatter,
  },
  {
    title: `Cancelled (${mapping.cancelled})`,
    dataIndex: "cancelled",
    render: textFormatter,
  },
  {
    title: `Completed (${mapping.completed})`,
    dataIndex: "completed",
    render: cellContent => textFormatter(cellContent, "#17C347"),
  },
];

export const sourceStatsColumns = (type, mapping) => [
  {
    title: type,
    dataIndex: "name",
    render: textFormatter,
  },
  {
    title: `CC (${mapping.cc})`,
    dataIndex: "cc",
    render: textFormatter,
  },
  {
    title: `TL (${mapping.tl})`,
    dataIndex: "tl",
    render: textFormatter,
  },
  {
    title: `TR (${mapping.tr})`,
    dataIndex: "tr",
    render: textFormatter,
  },
  {
    title: `BE (${mapping.be})`,
    dataIndex: "be",
    render: textFormatter,
  },
  {
    title: `ND (${mapping.nd})`,
    dataIndex: "nd",
    render: textFormatter,
  },
];
