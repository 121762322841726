import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import NGIPrompt from "components/Common/NGIPrompt";
import { Card, CardBody, Container } from "reactstrap";
import { Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import editIcon from "assets/images/svg/Pencil Drawing.svg";
import { makeStyles } from "@material-ui/core/styles";
import NewMeterChangeDeclineReasonModal from "pages/operations/meterChangeDeclineReasons/NewMeterChangeDeclineReasonModal";
import { createDictionary } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  builderContainer: {
    width: "50%",

    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  reasonItem: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 5,
    padding: 20,
    marginTop: 30,
    marginBottom: 15,
  },
  imgBtn: {
    cursor: "pointer",
    marginLeft: 10,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  noReasons: {
    marginTop: 20,
  },
  buttons: {
    width: 40,
  },
});

const MeterChangeDeclineReasons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [currentReason, setCurrentReason] = useState("");
  const [reasons, setReasons] = useState([]);

  const {
    list: { meterChangeDeclineTaskReasons },
  } = useSelector(DictionariesSelector);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setCurrentReason("");
  }, []);

  const handleAddNew = useCallback(() => {
    setIsOpen(true);
  }, [meterChangeDeclineTaskReasons]);

  const editReason = useCallback(
    event => {
      setCurrentReason(event.currentTarget.dataset.reason);
      setIsOpen(true);
    },
    [meterChangeDeclineTaskReasons]
  );

  const handleSave = useCallback(
    newReason => {
      setDisabled(false);

      setReasons(prevState => {
        const state = [...prevState];
        const l1Index = state?.findIndex(item => item === currentReason);
        if (l1Index !== -1) {
          state[l1Index] = newReason;
        } else {
          state.push(newReason);
        }
        return state;
      });
      handleClose();
    },
    [currentReason]
  );

  const handleSaveReasons = useCallback(async () => {
    const formattedData = {
      name: DICTIONARY_TYPES.METER_CHANGE_DECLINE_REASONS,
      isMultiLevel: false,
      items: reasons,
    };

    setLoader(true);
    await createDictionary(formattedData)
      .then(() => {
        setLoader(false);
        successMessage("Reasons saved successfully");
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [reasons]);

  useEffect(() => {
    if (meterChangeDeclineTaskReasons?.length) {
      setReasons(meterChangeDeclineTaskReasons);
    }
  }, [meterChangeDeclineTaskReasons]);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_CHANGE_DECLINE_REASONS));
  }, []);
  return (
    <>
      <NGIPrompt show={!disabled} />
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <div className={classes.root}>
                <div className={classes.cardBody}>
                  <div className={classes.buttonsContainer}>
                    <CustomButton
                      icon={<i className="bx bx-plus-circle" />}
                      type="primary"
                      text="NEW REASON"
                      onClick={handleAddNew}
                    />
                    <CustomButton type="primary" text="Save" disabled={disabled} onClick={handleSaveReasons} />
                  </div>
                  <div className={classes.builderContainer}>
                    {reasons.map(item => (
                      <div key={item}>
                        <div className={classes.reasonItem}>
                          <div>{item}</div>
                          <div className={classes.buttons}>
                            <img
                              data-reason={item}
                              className={classes.imgBtn}
                              onClick={editReason}
                              src={editIcon}
                              alt="action-icon"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {!reasons.length && (
                    <Card className={classes.noReasons}>
                      <CardBody className="text-center">
                        <i className="bx bx-warning" />
                        <h5>No reasons</h5>
                        <p className="text-muted">Please add new reason</p>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </div>
            </>
          </Spin>
        </Container>
        {isOpen && (
          <NewMeterChangeDeclineReasonModal
            onClose={handleClose}
            reason={currentReason}
            onSave={handleSave}
            allReasons={meterChangeDeclineTaskReasons}
          />
        )}
      </div>
    </>
  );
};

export default MeterChangeDeclineReasons;
