export const FILTER_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const FILTER_STATUS_OPTIONS = {
  [FILTER_STATUS.ACTIVE]: "Active",
  [FILTER_STATUS.INACTIVE]: "Inactive",
};

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const DEFAULT_CENTER = {
  lat: -1.279888744557952,
  lng: 36.83057693777175,
};
