import { textFormatter, weightFormatter, customDateFormatWithTime, boolFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

export const assetListCylinderColumns = [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: (cellContent, asset) => (
      <Tooltip placement="bottom" title={`${asset.barcode} details`}>
        <div>
          <CustomLink to={`assets/${asset.id}`}>{textFormatter(asset.barcode)}</CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.type),
  },
  {
    title: "Сustodian",
    dataIndex: "custodian",
    sort: true,
    render: (cellContent, asset) =>
      textFormatter(!!asset.custodian ? `${asset.custodianType}/${asset.custodian}` : null),
  },
  {
    dataIndex: "cylinderAttributes.gasWeightFromMarket",
    title: "Gas Weight From Market",
    sort: false,
    render: (cellContent, asset) => weightFormatter(asset?.cylinderAttributes?.gasWeightFromMarket),
  },
  {
    dataIndex: "cylinderAttributes.gasWeightToMarket",
    title: "Gas Weight To Market",
    sort: false,
    render: (cellContent, asset) => weightFormatter(asset?.cylinderAttributes?.gasWeightToMarket),
  },
  {
    title: "State",
    dataIndex: "state",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.state),
  },
  {
    title: "Condition",
    dataIndex: "condition",
    sort: true,
    render: textFormatter,
  },
  {
    title: "Is Validated",
    dataIndex: "isValidated",
    render: boolFormatter,
    width: "130px",
  },
  {
    title: "Depot",
    dataIndex: "depot",
    key: "depot",
    render: value => textFormatter(value),
  },
  {
    dataIndex: "createdAt",
    title: "Date",
    sort: true,
    render: (cellContent, asset) => textFormatter(customDateFormatWithTime(asset.createdAt)),
  },
];

export const otherAssetListColumns = [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: (cellContent, asset) => (
      <Tooltip placement="bottom" title={`${asset.barcode} details`}>
        <div>
          <CustomLink to={`assets/${asset.id}`}>{textFormatter(asset.barcode)}</CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.type),
  },
  {
    title: "Сustodian",
    dataIndex: "custodian",
    sort: true,
    render: (cellContent, asset) =>
      textFormatter(!!asset.custodian ? `${asset.custodianType}/${asset.custodian}` : null),
  },
  {
    title: "State",
    dataIndex: "state",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.state),
  },
  {
    title: "Condition",
    dataIndex: "condition",
    sort: true,
    render: textFormatter,
  },
  {
    title: "Is Validated",
    dataIndex: "isValidated",
    render: boolFormatter,
    width: "130px",
  },
  {
    title: "Depot",
    dataIndex: "depot",
    key: "depot",
    render: value => textFormatter(value),
  },
  {
    dataIndex: "createdAt",
    title: "Date",
    sort: true,
    render: (cellContent, asset) => textFormatter(customDateFormatWithTime(asset.createdAt)),
  },
];

export const assetListCardColumns = [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: (cellContent, asset) => (
      <Tooltip placement="bottom" title={`${asset.barcode} details`}>
        <div>
          <CustomLink to={`assets/${asset.id}`}>{textFormatter(asset.barcode)}</CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.type),
  },
  {
    title: "Сustodian",
    dataIndex: "custodian",
    sort: true,
    render: (cellContent, asset) =>
      textFormatter(!!asset.custodian ? `${asset.custodianType}/${asset.custodian}` : null),
  },
  {
    title: "QrCode",
    dataIndex: "qrCode",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.qrCode),
  },

  {
    dataIndex: "cardAttributes.number",
    title: "Card #",
    sort: false,
    render: (cellContent, asset) => textFormatter(asset?.cardAttributes?.number),
  },
  {
    title: "State",
    dataIndex: "state",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.state),
  },
  {
    title: "Condition",
    dataIndex: "condition",
    sort: true,
    render: textFormatter,
  },
  {
    title: "Is Validated",
    dataIndex: "isValidated",
    render: boolFormatter,
    width: "130px",
  },
  {
    title: "Location",
    dataIndex: "location",
    sort: true,
    render: (cellContent, asset) => textFormatter(asset.location),
  },
  {
    title: "Depot",
    dataIndex: "depot",
    key: "depot",
    render: value => textFormatter(value),
  },
  {
    dataIndex: "createdAt",
    title: "Date",
    sort: true,
    render: (cellContent, asset) => textFormatter(customDateFormatWithTime(asset.createdAt)),
  },
];
