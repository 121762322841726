import React, { useCallback, useEffect, useState } from "react";
import { Form, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { DatePicker } from "antd";
import { REPORT_TYPES, TYPE_NAMES } from "pages/meter-management/maintenanceReports/variables";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportsFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    handleFilter({});
  }, []);

  const onDateRangeChange = useCallback(value => setDateRange(value), []);

  const onSearch = useCallback(
    values => {
      if (!!dateRange) {
        values.timeStart = getStartOfTheDay(dateRange[0]);
        values.timeEnd = getEndOfTheDay(dateRange[1]);
      }
      handleFilter(values);
    },
    [dateRange]
  );

  const disableDate = useCallback(current => current && current.valueOf() > Date.now(), []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <RangePicker inputReadOnly={true} onChange={onDateRangeChange} disabledDate={disableDate} />
        <div className="filter-divider" />
        <Form.Item name={TYPE_NAMES.REPORT_TYPE}>
          <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select type"} allowClear>
            {REPORT_TYPES?.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default ReportsFilter;
