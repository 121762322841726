export const searchOptions = [
  {
    value: "licencePlate",
    title: "Licence Plate",
  },
  {
    value: "depot",
    title: "Depot",
  },
  {
    value: "qrCode",
    title: "QR Code",
  },
];
