import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Divider, Form, Radio, Space } from "antd";
import { textFormatter } from "components/Common/formatter";
import Title from "antd/lib/typography/Title";
import { getEmptyQuestionnaire, getMappedQuestionnaire } from "pages/operations/leadDetails/utils";
import NGISpinner from "components/Common/NGISpinner";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "100%",
    paddingRight: 25,
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    width: "60%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    width: "40%",
  },
  red: {
    color: "#ff4d4f",
  },
  columnTitle: {
    fontSize: "14px!important",
    fontWeight: "400!important",
  },
  form: {
    width: "100%",
  },
});

const DemographicQuestionsTab = ({ registrationSurvey = {}, mode, editForm, leadQuestionnaire }) => {
  const classes = useStyles();
  const { items: leadAnswers } = registrationSurvey;
  const { items: questionnaire } = leadQuestionnaire || {};

  const mappedQuestionnaire = useMemo(() => getMappedQuestionnaire(questionnaire), [questionnaire]);
  const emptyQuestionnaire = useMemo(() => getEmptyQuestionnaire(questionnaire), [questionnaire]);

  const getAnswer = useCallback(
    item => {
      const type = mappedQuestionnaire[item?.questionId]?.type;
      switch (type) {
        case "Select Single":
        case "Select":
          return mappedQuestionnaire[item?.questionId]?.answers[item?.answerId];
        case "Select Multiple":
          return item?.answerIds.map(answer => mappedQuestionnaire[item?.questionId]?.answers[answer]).join(", ");
        case "Instruction":
          return mappedQuestionnaire[item?.questionId]?.answers.join("\n");
        default:
          return item?.answer;
      }
    },
    [mappedQuestionnaire]
  );

  if (!mappedQuestionnaire) return <NGISpinner hideContainer />;

  return (
    <div className={classes.root}>
      <div className={classes.informationContainer}>
        {mode !== "edit" ? (
          <div className={classes.column}>
            {(leadAnswers || emptyQuestionnaire)?.map(item => (
              <>
                <div className={classes.rowData} key={item?.questionId}>
                  <div className={classes.title}>{mappedQuestionnaire[item?.questionId]?.question}</div>
                  <div className={classes.data}>{textFormatter(getAnswer(item))}</div>
                </div>
                <Divider />
              </>
            ))}
          </div>
        ) : (
          <Form className={classes.form} form={editForm}>
            {questionnaire.length &&
              questionnaire.map(item => {
                if (!item?.options?.length) return null;
                return (
                  <Col span={12} key={item.id}>
                    <Title className={classes.columnTitle}>{item.question}</Title>
                    <Form.Item name={item.id} rules={[{ required: true, message: "Please enter value" }]}>
                      <Radio.Group>
                        <Space direction="vertical">
                          {item?.options?.map(radio => (
                            <Radio key={radio.id} value={radio.id}>
                              {radio.text}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );
              })}
          </Form>
        )}
      </div>
    </div>
  );
};

export default DemographicQuestionsTab;
