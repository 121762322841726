import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import EditSurveyTab from "pages/settings/survey-management/surveyDetails/Tabs/EditSurveyTab";
import { getTabButtons, TABS } from "pages/settings/survey-management/surveyDetails/constants";
import SurveyDetailsTab from "pages/settings/survey-management/surveyDetails/Tabs/SurveyDetailsTab";
import SurveyHistoryTab from "pages/settings/survey-management/surveyDetails/Tabs/SurveyHistoryTab";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
});

const DataTabs = ({ isCreate, survey, surveyHistory, setIsLoading }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(isCreate ? TABS.EDIT_SURVEY : TABS.SURVEY_DETAILS);

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {getTabButtons(isCreate).map(item => {
          // if (item?.allowCategories && !item.allowCategories.includes(survey.id)) return null;
          if (item?.hideRoles && !userHasAdminRole() && userHasOneOfRoles(item?.hideRoles)) return null;
          return (
            <div
              key={item.key}
              className={classnames(classes.tab, activeTab === item.key && classes.active)}
              data-tab={item.key}
              onClick={onTabClick}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      <Card className={classes.container}>
        <CardBody className={classes.cardBody}>
          {activeTab === TABS.SURVEY_DETAILS && <SurveyDetailsTab survey={survey} />}
          {activeTab === TABS.EDIT_SURVEY && (
            <EditSurveyTab isCreate={isCreate} survey={survey} setIsLoading={setIsLoading} />
          )}
          {activeTab === TABS.SURVEY_HISTORY && <SurveyHistoryTab history={surveyHistory} />}
        </CardBody>
      </Card>
    </div>
  );
};

export default DataTabs;
