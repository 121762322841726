import React, { useCallback, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveAssetsRequestsAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { assetRequestHistoryListColumns } from "pages/warehouse/assetRequestHistory/assetRequestHistoryListColumns";
import AssetRequestFilter from "pages/warehouse/assetRequestHistory/assetRequestFilter";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const RetrieveAssetRequestHistory = () => {
  const { setFilters } = useStoreFilters(FILTER_PAGE.ASSET_REQUEST_HISTORY);
  const [isLoading, setLoader] = useState(false);
  const [assetList, setAssetList] = useState([]);

  const handleOnFilter = useCallback(filterOptions => {
    setLoader(true);
    setFilters(filterOptions);
    retrieveAssetsRequestsAPI({ ...filterOptions })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items?.length === 0) {
          warnMessage(`Items not found`);
          setLoader(false);
        }

        setAssetList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setAssetList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <AssetRequestFilter onFilter={handleOnFilter} filtersType={FILTER_PAGE.ASSET_REQUEST_HISTORY} />
          </div>
          <Col>
            <NGITable
              columns={assetRequestHistoryListColumns}
              data={assetList}
              isLoading={isLoading}
              updateList={handleOnFilter}
              filtersType={FILTER_PAGE.ASSET_REQUEST_HISTORY}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default RetrieveAssetRequestHistory;
