import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import NGITable from "components/Common/NGITable";
import { fromToMarketReportsListColumns, REPORT_TYPES } from "pages/cylinder-weight-reports/reports/constants";
import { useDispatch } from "react-redux";
import { retrieveCylinderWeightReports } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import ReportsSearch from "pages/cylinder-weight-reports/reports/ReportsSearch";
import CustomButton from "components/Common/buttons/CustomButton";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import DownloadModal from "pages/cylinder-weight-reports/reports/DownloadModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";

const ToMarketReports = () => {
  const dispatch = useDispatch();
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.TO_MARKET_REPORTS);
  const [reports, setReports] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = useMemo(() => fromToMarketReportsListColumns(), []);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    handleSearch(filters);
  }, []);

  const handleSearch = useCallback((filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    retrieveCylinderWeightReports("To Market", {
      ...filterOptions,
    })
      .then(res => {
        setLoader(false);
        setReports(res?.data || null);
      })
      .catch(err => {
        setLoader(false);
        setReports(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const onDownloadClick = useCallback(() => setIsModalOpen(true), []);

  const onClose = useCallback(() => setIsModalOpen(false), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
                icon={<i className="bx bx-download" />}
                type="primary"
                text="Download"
                onClick={onDownloadClick}
              />
            </div>
            <ReportsSearch handleSearch={handleSearch} filtersType={FILTER_PAGE.TO_MARKET_REPORTS} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.TO_MARKET_REPORTS}
              isLoading={isLoading}
              columns={columns}
              data={reports}
              updateList={handleSearch}
            />
          </Col>
          {isModalOpen && <DownloadModal onClose={onClose} type={REPORT_TYPES.TO_MARKET_REPORTS} />}
        </Container>
      </div>
    </>
  );
};

export default ToMarketReports;
