export const FILTER_METER_ID = "meterId";
export const FILTER_TYPE = "type";

export const JOB_TYPES = [
  "activateMeter",
  "adjustCredits",
  "adjustData",
  "changeBattery",
  "closeMeterLock",
  "closeMeterValve",
  "contsettings",
  "cylinderInstall",
  "deactivateMeter",
  "firmwareUpdate",
  "getCookingSessionReport",
  "getCumulativeReport",
  "getCustomerInstall",
  "getHardwareReport",
  "getLogs",
  "getMeterStatus",
  "getSetting",
  "openMeterLock",
  "openMeterValve",
  "selfTest",
  "sendInfq",
  "updateSettings",
];
