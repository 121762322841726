import React, { useCallback, useMemo, useState } from "react";
import { Radio, Divider, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIInput from "components/Common/inputs/NGIInput";
import { formatPhoneNumber, getTrimmedObjectValues } from "utils/helpers/functions";
import { setMeterPin } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";

export const FLOW_TYPES = {
  ACTIVATE: "activate",
  DEACTIVATE: "deactivate",
  REMIND: "resend",
};

const useStyles = makeStyles({
  modal: {
    maxWidth: "400px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  radioContainer: {
    width: "100%",
  },
  radioItem: {
    width: "100%",
    marginRight: 2,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: 10,
    borderRadius: 10,
    paddingTop: 6,
    marginBottom: 6,
  },
  subtitle: {
    color: "#8C8C8C",
    fontWeight: 300,
    textTransform: "uppercase",
  },
});

const MeterPinModal = ({ onClose, flow, customerId, phonesList = [] }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(phonesList[0]?.value);
  const [pin, setPin] = useState("");

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    const preparedData = getTrimmedObjectValues({
      ...(flow === FLOW_TYPES.ACTIVATE && { pinCode: pin }),
      phone: selectedPhone,
      customerId,
    });

    setMeterPin(preparedData, flow)
      .then(() => {
        onClose();
        setIsLoading(false);
        flow === FLOW_TYPES.ACTIVATE &&
          successMessage("Pin was set successfully. Status will change after connecting meter to the network");
        flow === FLOW_TYPES.DEACTIVATE &&
          successMessage("Pin was deactivated successfully. Status will change after connecting meter to the network");
        flow === FLOW_TYPES.REMIND && successMessage("Reminder was sent successfully");
      })
      .catch(e => {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      });
  }, [pin, selectedPhone, customerId, flow]);

  const handleChangePin = useCallback(e => {
    if (e.target.value?.length <= 2) {
      setPin(e.target.value);
    }
  }, []);

  const handleChangePhone = useCallback(e => setSelectedPhone(e.target.value), []);

  const title = useMemo(() => {
    switch (flow) {
      case FLOW_TYPES.ACTIVATE:
        return (
          <div className={classes.infoContainer}>
            Set Meter Pin
            <span>Please select phone to send the code to</span>
          </div>
        );
      case FLOW_TYPES.DEACTIVATE:
        return (
          <div className={classes.infoContainer}>
            Deactivate Safety Code
            <span>Deactivating safety code</span>
          </div>
        );
      case FLOW_TYPES.REMIND:
        return (
          <div className={classes.infoContainer}>
            Remind Meter PIN
            <span>Send a reminder of meter PIN Code</span>
          </div>
        );
      default:
        return <></>;
    }
  }, [flow]);

  return (
    <Modal title={title} className={classes.modal} open onCancel={onClose} closable={false} footer={[]}>
      <Spin spinning={isLoading}>
        <div>
          <div>
            {flow === FLOW_TYPES.ACTIVATE && (
              <NGIInput
                placeholder="Two digit PIN code"
                value={pin}
                type={"number"}
                max={99}
                onChange={handleChangePin}
              />
            )}
          </div>
          <Divider />
          <div>
            <Radio.Group className={classes.radioContainer} onChange={handleChangePhone} value={selectedPhone}>
              {phonesList &&
                phonesList.map(item => (
                  <Radio className={classes.radioItem} key={item.value} value={item.value}>
                    <span>{formatPhoneNumber(item.value)}</span>
                    <div>
                      <span className={classes.subtitle}>{item.label}</span>
                    </div>
                  </Radio>
                ))}
            </Radio.Group>
          </div>
        </div>
      </Spin>
      <br />
      <Space>
        <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Close" />
        {flow === FLOW_TYPES.ACTIVATE && (
          <CustomButton
            type="primary"
            disabled={!pin || !selectedPhone}
            onClick={onSubmit}
            text="ACTIVATE PIN"
            size="small"
          />
        )}
        {flow === FLOW_TYPES.DEACTIVATE && (
          <CustomButton type="primary" disabled={!selectedPhone} onClick={onSubmit} text="DEACTIVATE" size="small" />
        )}
        {flow === FLOW_TYPES.REMIND && (
          <CustomButton type="primary" disabled={!selectedPhone} onClick={onSubmit} text="SEND REMINDER" size="small" />
        )}
      </Space>
    </Modal>
  );
};

export default MeterPinModal;
