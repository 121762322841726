import React, { useCallback } from "react";
import { Form, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";

const { Option } = Select;

const ReadyForRepairFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const {
    list: { meterFaultyReasonV2 },
  } = useSelector(DictionariesSelector);
  const { getFilters } = useStoreFilters(FILTER_PAGE.READY_FOR_REPAIR);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    handleFilter({ ...values, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item>Faulty</Form.Item>
        <div className="filter-divider" />
        <Form.Item name="faultyReason">
          <Select showSearch placeholder={"Select reason"} allowClear>
            {meterFaultyReasonV2?.map(item => (
              <Option key={item.item} value={item.item}>
                {item.item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default ReadyForRepairFilter;
