import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "antd";
import classnames from "classnames";
const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  root: {
    background: "#F5F5F5!important",
    border: "none!important",
    padding: 14,
    borderRadius: 30,
    width: "100%",

    "&>input": {
      background: "#F5F5F5!important",
    },
    "&input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const NGIDateRangePicker = ({ dateFormat = "DD/MM/YYYY", size = "default", className, ...props }) => {
  const classes = useStyles();

  return (
    <RangePicker
      {...props}
      inputReadOnly={true}
      dateFormat={dateFormat}
      size={size}
      className={classnames(classes.root, className)}
    />
  );
};
export default NGIDateRangePicker;
