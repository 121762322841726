import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Select, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { createFirmware, updateFirmware } from "store/meter-management/configuration/firmware/actions";
import moment from "moment";
import { AccessoriesSelector } from "store/meter-management/configuration/accessories/selectors";
import { getMicrocontrollers, getSensors } from "store/meter-management/configuration/accessories/actions";
import { errorMessage, NgiErrorMethod, successMessage } from "components/Common/responses/message";
import Dragger from "antd/lib/upload/Dragger";
import { dateFormat, FIRMWARE_GEN_LIST } from "./variables";
import uploadArea from "assets/images/svg/uploadArea.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIForm from "components/Common/form/NGIForm";
import NGISelect from "components/Common/inputs/NGISelect";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGIInput from "components/Common/inputs/NGIInput";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NGIFormItem from "components/Common/form/NGIFormItem";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { makeStyles } from "@material-ui/core/styles";
import NGICheckbox from "components/Common/inputs/NGICheckbox";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGITooltip from "components/Common/utils/NGITooltip";
import { toBase64 } from "utils/helpers/functions";

const useStyles = makeStyles({
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  checkbox: {
    display: "flex",
    alignItems: "center",

    "&>span:nth-child(2)": {
      paddingTop: 8,
    },
  },
});
const NewFirmwareModal = ({ isVisible, onClose, initialValues }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { sensors, microcontrollers, error, microcontrollerSuccess, sensorsSuccess } = useSelector(AccessoriesSelector);
  const noError = useMemo(() => !Object.keys(error || {}).length || error == null, [error]);
  const [uploadedFile, setUploadedFile] = useState(initialValues?.fileName || null);
  const [fileName, setFileName] = useState(initialValues?.fileName || "");
  const [valuesToConfirm, setValuesToConfirm] = useState(false);

  const {
    list: { meterType, firmwareStatus, meterFirmwareFileType },
  } = useSelector(DictionariesSelector);

  const findFileTypeDesc = useCallback(
    val => !!meterFirmwareFileType?.length && meterFirmwareFileType?.find(item => item.id === val)?.desc,
    [meterFirmwareFileType]
  );
  const [fileTypeDescription, setFileTypeDescription] = useState(findFileTypeDesc(initialValues?.fileType));

  useEffect(() => {
    !noError && errorMessage(NgiErrorMethod(error));
  }, [noError, error]);

  useEffect(() => {
    if (!!initialValues) form.setFieldsValue({ ...initialValues, releaseAt: moment() });
    else form.resetFields();
  }, [form, initialValues]);

  useEffect(() => {
    setLoading(true);
    dispatch(getSensors("Sensor"));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_TYPE));
    dispatch(getDictionary(DICTIONARY_TYPES.FIRMWARE_STATUS));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_FIRMWARE_FILE_TYPE));
    dispatch(getMicrocontrollers("Microcontroller"));
  }, []);

  useEffect(() => {
    if ((sensorsSuccess && microcontrollerSuccess) || error) setLoading(false);
  }, [sensorsSuccess, microcontrollerSuccess, error]);

  const onSave = useCallback(
    async values => {
      const { version, ...normalizedValues } = values;

      if (!!initialValues) {
        dispatch(
          updateFirmware({
            version,
            data: {
              ...normalizedValues,
              releaseAt: moment(version.releaseAt).format("YYYY-MM-DD HH:mm:ss"),
            },
          })
        );
        successMessage("Firmware was updated successfully");
      } else {
        dispatch(
          createFirmware({
            ...normalizedValues,
            version,
            releaseAt: moment(version.releaseAt).format("YYYY-MM-DD HH:mm:ss"),
          })
        );
        successMessage("Firmware was created successfully");
      }
      onClose();
    },
    [initialValues]
  );

  const readUploadFile = useCallback(async e => {
    setLoading(true);
    const file = e.file;
    const base64Data = await toBase64(file);

    const result = base64Data.replace("data:", "").replace(/^.+,/, "");
    setUploadedFile(result);
    setFileName(file.name);
    form.setFieldValue("file", result);
    setLoading(false);
  }, []);

  const removeFile = useCallback(() => {
    setUploadedFile(null);
    form.setFieldValue("file", null);
  }, []);

  const handleSave = useCallback(
    values => (values.isDefaultVersion ? openConfirmationModal(values) : onSave(values)),
    []
  );

  const openConfirmationModal = useCallback(values => setValuesToConfirm(values), []);
  const closeConfirmationModal = useCallback(() => setValuesToConfirm(false), []);

  const handleFileTypeChange = useCallback(
    val => {
      form.setFieldValue("fileType", val);
      setFileTypeDescription(findFileTypeDesc(val));
    },
    [meterFirmwareFileType]
  );

  return (
    <Modal
      data-testid={"modal-element"}
      title={!!initialValues ? `Edit Firmware Version: ${initialValues.version}` : "Add new Firmware"}
      open={isVisible}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <>
        <Spin spinning={loading}>
          <NGIForm name="newFirmwareForm" isUpdate={!!initialValues} form={form} onFinish={handleSave}>
            <Col>
              <Title data-testid="versionText" level={5}>
                Version
              </Title>
              <NGIFormItem name="version" rules={[{ required: true, message: "Please enter the version name" }]}>
                <NGIInput data-testid="versionValue" disabled={!!initialValues} placeholder="Enter the version name" />
              </NGIFormItem>
              <Title level={5}>Default Version</Title>
              <Form.Item valuePropName="checked" name="isDefaultVersion">
                <NGICheckbox className={classes.checkbox}>Used as a default version</NGICheckbox>
              </Form.Item>
            </Col>
            {!!FIRMWARE_GEN_LIST.length && (
              <Col span={24}>
                <Title level={5}>Generation</Title>
                <NGIFormItem name="generation" rules={[{ required: true, message: "Please select generation" }]}>
                  <NGISelect
                    showSearch
                    className="generation"
                    optionFilterProp="children"
                    data-testid="generation"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    placeholder={"Select generation"}
                  >
                    {FIRMWARE_GEN_LIST.map(item => (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            )}
            <Col span={24}>
              <Title level={5}>Release at</Title>
              <NGIFormItem name="releaseAt" rules={[{ required: true, message: "Please select Release date" }]}>
                <NGIDatePicker className="date" style={{ width: "100%" }} format={dateFormat} />
              </NGIFormItem>
            </Col>
            {!!firmwareStatus?.length && (
              <Col span={24}>
                <Title level={5}>Status</Title>
                <NGIFormItem name="status" rules={[{ required: true, message: "Please select status" }]}>
                  <NGISelect showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select status"}>
                    {firmwareStatus.map(item => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            )}

            {!!meterType?.length && (
              <Col span={24}>
                <Title level={5}>Meter type</Title>
                <NGIFormItem name="meterType">
                  <NGISelect showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select type"}>
                    {meterType.map(item => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            )}
            {!!sensors?.length && (
              <Col span={24}>
                <Title data-testid="sensorTypeTitle" level={5}>
                  Sensor type
                </Title>
                <NGIFormItem name="sensorType">
                  <NGISelect
                    showSearch
                    className="sensor"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    placeholder={"Select type"}
                  >
                    {sensors.map(item => (
                      <Select.Option key={item.type} value={item.type}>
                        {item.type}
                      </Select.Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            )}
            {!!microcontrollers?.length && (
              <Col span={24}>
                <Title data-testid="microcontrollerTypeTitle" level={5}>
                  Microcontroller type
                </Title>
                <NGIFormItem name="microcontrollerType">
                  <NGISelect
                    showSearch
                    className="controller"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    placeholder={"Select type"}
                  >
                    {microcontrollers.map(item => (
                      <Select.Option key={item.type} value={item.type}>
                        {item.type}
                      </Select.Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            )}
            {!!meterFirmwareFileType?.length && (
              <Col span={24}>
                <Title data-testid="fileTypeTitle" level={5}>
                  File type
                </Title>
                <NGITooltip title={fileTypeDescription}>
                  <NGIFormItem name="fileType" rules={[{ required: true, message: "Please select file type" }]}>
                    <NGISelect
                      showSearch
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      placeholder={"Select type"}
                      onChange={handleFileTypeChange}
                    >
                      {meterFirmwareFileType?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.id}
                        </Select.Option>
                      ))}
                    </NGISelect>
                  </NGIFormItem>
                </NGITooltip>
              </Col>
            )}
            <Col>
              <Title level={5}>Upload file</Title>
              <NGIFormItem
                name="file"
                rules={!initialValues && [{ required: true, message: "Please upload firmware" }]}
              >
                {uploadedFile ? (
                  <div className={classes.fileContainer}>
                    <div className={classes.fileTitle}>{`${fileName}`}</div>
                    <div onClick={removeFile} className={classes.removeBtn}>
                      <img src={closeIcon} alt="remove" />
                    </div>
                  </div>
                ) : (
                  <Dragger showUploadList={false} accept={".bin"} customRequest={readUploadFile} name="file">
                    <img className="upload-bg" src={uploadArea} alt="upload" />
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single upload. Strictly prohibit from uploading company data or other band files
                    </p>
                  </Dragger>
                )}
              </NGIFormItem>
            </Col>

            <Col>
              <Space>
                <CustomButton size="small" onClick={onClose} type="primary" color="outlined" text="Cancel" />
                <CustomButton
                  size="small"
                  htmlType="submit"
                  type="primary"
                  text={!!initialValues ? "Update" : "Save"}
                />
              </Space>
            </Col>
          </NGIForm>
        </Spin>
        {!!valuesToConfirm && (
          <ConfirmationModal
            onCancel={closeConfirmationModal}
            onConfirm={() => onSave(valuesToConfirm)}
            description={`Are you sure you want to make this firmware as default version? This will reset default flag for previous firmware. `}
          />
        )}
      </>
    </Modal>
  );
};

export default NewFirmwareModal;
