import { USER_ROLES } from "utils/constants";

export const TABS = {
  DETAILS: "DETAILS",
  EDIT: "EDIT",
  RECEIVE: "RECEIVE",
};

export const TABS_BUTTONS = [
  {
    title: "General information",
    key: TABS.DETAILS,
  },
  {
    title: "Edit Spare Part",
    key: TABS.EDIT,
    hideRoles: [
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.GENERAL,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.FINANCE,
      USER_ROLES.METER_ENGINEER,
    ],
  },
  {
    title: "Receive Spare Part",
    key: TABS.RECEIVE,
    hideRoles: [
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.GENERAL,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.FINANCE,
      USER_ROLES.METER_ENGINEER,
    ],
  },
];
