import { useCallback, useState } from "react";
import { omit } from "lodash";

const FILTER_STORAGE = "filtersStorage";

const useStoreFilters = pageName => {
  const [filterStore, setFilterStore] = useState(JSON.parse(sessionStorage.getItem(FILTER_STORAGE) || "{}"));
  const [filterForPage] = useState(pageName);

  const setFilters = useCallback(
    filters => {
      sessionStorage.setItem(
        FILTER_STORAGE,
        JSON.stringify({ ...filterStore, [filterForPage]: omit(filters, ["pageAfter", "pageBefore"]) })
      );
      setFilterStore({ [filterForPage]: filters });
    },
    [filterForPage]
  );

  const clearFilters = useCallback(() => {
    sessionStorage.removeItem(FILTER_STORAGE);
    setFilterStore({});
  }, [filterForPage]);

  const getFilters = useCallback(
    () => JSON.parse(sessionStorage.getItem(FILTER_STORAGE))?.[filterForPage] || {},
    [filterForPage]
  );

  return {
    setFilters,
    getFilters,
    clearFilters,
    filters: filterStore[filterForPage] || {},
  };
};

export default useStoreFilters;
