import React, { useEffect, useState } from "react";
import { Modal, Space } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { useJsApiLoader } from "@react-google-maps/api";
import GeoDetails from "pages/depot-management/depotModal/Tabs/GeoDetails";
import { DEFAULT_CENTER } from "pages/depot-management/depots/constants";
import { GOOGLE_MAPS_API_KEY } from "utils/constants";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  container: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 30,
    paddingBottom: 20,
  },
  tabs: {
    "&.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav": {
      padding: "0px!important",
      margin: 0,
    },
  },
});

const AllDepotsModal = ({ onClose, polygonList, isOpen }) => {
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    libraries: ["drawing", "places"],
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });
  const [center, setCenter] = useState(null);

  useEffect(
    () =>
      navigator.geolocation.getCurrentPosition(
        ({ coords }) =>
          setCenter({
            lat: coords.latitude,
            lng: coords.longitude,
          }),
        () => setCenter(DEFAULT_CENTER)
      ),
    []
  );

  return (
    <Modal
      title={<div className={classes.titleWithDescription}>View all depots on the map</div>}
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <>
        <div className={classes.container}>
          <GeoDetails allowSearch drawingMode={false} center={center} polygonList={polygonList} isLoaded={isLoaded} />
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
        </Space>
      </>
    </Modal>
  );
};

export default AllDepotsModal;
