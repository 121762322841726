import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import MetaData from "components/VerticalLayout/MetaData";
import MetersFilter from "./metersFilter";
import { deleteMeterAPI, resetAllParametersAPI, retrieveMeters, triggerGetLogsReport } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { awaitingTestingMeterListColumns } from "pages/meter-management/awaitingTestingMeters/awaitingTestingMeterListColumns";
import NGITable from "components/Common/NGITable";
import MeterTestResultModal from "pages/meter-management/awaitingTestingMeters/MeterTestResultModal";
import { FILTER_PAGE } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";

const AwaitingTestingMeters = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.AWAITING_TESTING);
  const [meterList, setMeterList] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isMeterTestResultModalVisible, setIsMeterTestResultModalVisible] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const [isConfirmationDeleteOpen, setIsConfirmationDeleteOpen] = useState(false);

  const openConfirmationDeleteModal = useCallback(id => {
    setActiveId(id);
    setIsConfirmationDeleteOpen(true);
  }, []);

  const closeConfirmationDeleteModal = useCallback(() => setIsConfirmationDeleteOpen(false), []);

  const openConfirmationModal = useCallback(id => {
    setActiveId(id);
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const handleConfirm = useCallback(() => {
    handleResetAllParameters(activeId);
    closeConfirmationModal();
  }, [activeId]);

  const closeMeterTestResultModal = useCallback(() => setIsMeterTestResultModalVisible(false), []);

  const openMeterTestResultModal = useCallback(item => {
    setActiveItem(item);
    setIsMeterTestResultModalVisible(true);
  }, []);

  useEffect(() => {
    handleFilter();

    dispatch(getDictionary(DICTIONARY_TYPES.METER_FAULTY_REASONS_V2));
  }, []);

  const handleFilter = useCallback((newFilterOptions = {}) => {
    setLoader(true);
    setFilters(newFilterOptions);
    retrieveMeters({
      condition: "Awaiting Testing",
      ...newFilterOptions,
    })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items.length === 0) {
          warnMessage("No meters were found");
          setLoader(false);
        }
        setMeterList(res?.data);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setMeterList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const handleRemove = useCallback(() => {
    setLoader(true);
    deleteMeterAPI(activeId)
      .then(() => {
        setLoader(false);
        successMessage("Meter was removed successfully");
        closeConfirmationDeleteModal();
        handleFilter(filters);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [activeId, filters]);

  const handleResetAllParameters = useCallback(id => {
    setLoader(true);
    resetAllParametersAPI(id)
      .then(() => {
        setLoader(false);
        successMessage("Reset all parameters command was sent to the meter");
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const handleTriggerGetLogsReport = useCallback(id => {
    setLoader(true);
    triggerGetLogsReport(id)
      .then(() => {
        setLoader(false);
        successMessage("Get logs report command was sent to the meter");
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(
    () =>
      awaitingTestingMeterListColumns({
        openMeterTestResultModal,
        openConfirmationModal,
        handleTriggerGetLogsReport,
        openConfirmationDeleteModal,
      }),
    []
  );

  return (
    <>
      <div className="page-content" data-testid="metersComponent">
        <MetaData pageTitle={"Awaiting Testing"} />
        <Container fluid>
          <div className="filters-wrapper">
            <MetersFilter handleFilter={handleFilter} isLoading={isLoading} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.AWAITING_TESTING}
              isLoading={isLoading}
              columns={columns}
              data={meterList}
              updateList={handleFilter}
            />
          </Col>
        </Container>
      </div>
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to send the command of resetting all parameters to the meter?"
          onCancel={closeConfirmationModal}
          onConfirm={handleConfirm}
        />
      )}
      {isMeterTestResultModalVisible && (
        <MeterTestResultModal
          isOpen={isMeterTestResultModalVisible}
          onClose={closeMeterTestResultModal}
          activeItem={activeItem}
          onUpdate={handleFilter}
        />
      )}
      {isConfirmationDeleteOpen && (
        <ConfirmationModal
          loading={isLoading}
          description={"Are you sure you want to remove meter?"}
          onCancel={closeConfirmationDeleteModal}
          onConfirm={handleRemove}
        />
      )}
    </>
  );
};

export default AwaitingTestingMeters;
