import customers from "../../assets/images/svg/customers.svg";
import warehouse from "../../assets/images/svg/warehouse.svg";
import operations from "../../assets/images/svg/operations.svg";
import meters from "../../assets/images/svg/meters.svg";
import settings from "../../assets/images/svg/settings.svg";
import payments from "../../assets/images/svg/payments.svg";
import allAccets from "../../assets/images/svg/allAccets.svg";
import allMeters from "../../assets/images/svg/allMeters.svg";
import allReadyForRepair from "../../assets/images/svg/wrench-dark.svg";
import configuration from "../../assets/images/svg/configuration.svg";
import tasks from "../../assets/images/svg/tasks.svg";
import routines from "../../assets/images/svg/routines.svg";
import leads from "../../assets/images/svg/leads.svg";
import prospects from "../../assets/images/svg/prospects.svg";
import refund from "../../assets/images/svg/refund.svg";
import marketing from "../../assets/images/svg/marketing.svg";
import initiatives from "../../assets/images/svg/initiatives.svg";
import repossession from "../../assets/images/svg/repossession.svg";
import dashboard from "../../assets/images/svg/dashboard.svg";
import jobs from "../../assets/images/svg/jobs.svg";
import logs from "../../assets/images/svg/logs.svg";
import assetTypes from "../../assets/images/svg/assetTypes.svg";
import packages from "../../assets/images/svg/packages.svg";
import smsProvider from "../../assets/images/svg/smsProvider.svg";
import smsBulk from "../../assets/images/svg/sms-bulk.svg";
import sms from "../../assets/images/svg/sms.svg";
import depot from "../../assets/images/svg/depot-menu.svg";
import simCards from "../../assets/images/svg/sim-cards.svg";
import allCustomers from "../../assets/images/svg/all-customers.svg";
import awaitingTesting from "../../assets/images/svg/awaitingTesting.svg";
import spareParts from "../../assets/images/svg/spareParts.svg";
import maintenanceReports from "../../assets/images/svg/maintanceReports.svg";
import toMarket from "../../assets/images/svg/toMarket.svg";
import fromMarket from "../../assets/images/svg/fromMarket.svg";
import registrationIcon from "../../assets/images/svg/registrationMeters.svg";
import taskRequest from "../../assets/images/svg/taskRequest.svg";
import taskArchive from "../../assets/images/svg/taskArchive.svg";
import price from "../../assets/images/svg/price.svg";
import money from "../../assets/images/svg/money.svg";
import allSTO from "../../assets/images/svg/all-sto.svg";
import allTrips from "../../assets/images/svg/all-trips.svg";
import stoManagement from "../../assets/images/svg/stock-management.svg";
import customerUpdateRequest from "../../assets/images/svg/customerUpdateRequest.svg";
import surveyManagementIcon from "../../assets/images/svg/SurveyManagement.svg";
import campaignManagementIcon from "../../assets/images/svg/CampaignManagement.svg";
import contractChange from "../../assets/images/svg/ContractChange.svg";
import transportManagement from "../../assets/images/svg/transportManagement.svg";
import loadingBays from "../../assets/images/svg/loadingBays.svg";
import { USER_ROLES } from "utils/constants";

const ALL_CUSTOMERS_ROLES = [
  USER_ROLES.PRODUCT_TECHNICIANS,
  USER_ROLES.SYSTEM_SUPPORT,
  USER_ROLES.CUSTOMER_CARE,
  USER_ROLES.TRAINING,
  USER_ROLES.INTERNAL_AUDITOR,
];
export const menuItems = [
  {
    name: "Dashboard",
    level: 1,
    link: "/dashboard",
    icon: dashboard,
    items: [],
  },
  {
    name: "Meter Management",
    roles: [
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.SYSTEM_SUPPORT,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
      USER_ROLES.GENERAL,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.FINANCE,
      USER_ROLES.METER_ENGINEER,
      USER_ROLES.METER_ENGINEER_LEADERSHIP,
    ],
    level: 1,
    icon: meters,
    items: [
      {
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        name: "All Meters",
        level: 2,
        link: "/meters/meters",
        items: [],
        icon: allMeters,
      },
      {
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        name: "Awaiting Process",
        items: [
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Awaiting Testing",
            link: "/meters/awaiting/testing",
            items: [],
            level: 3,
          },
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Awaiting Configuration",
            link: "/meters/awaiting/configuration",
            items: [],
            level: 3,
          },
        ],
        icon: awaitingTesting,
        level: 2,
      },
      {
        name: "Ready For Repair",
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        level: 3,
        items: [
          {
            name: "Meters",
            link: "/meters/ready-for-repair/meters",
            items: [],
            icon: "",
            level: 3,
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
          },
          {
            name: "Faulty Reasons",
            link: "/meters/ready-for-repair/faulty-reasons",
            items: [],
            icon: "",
            level: 3,
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
          },
        ],
        icon: allReadyForRepair,
      },
      {
        roles: [
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        name: "Maintenance Reports",
        level: 3,
        link: "/meters/maintenance-reports",
        items: [],
        icon: maintenanceReports,
      },
      {
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        name: "Meter Spare Parts",
        link: "/meters/spare-parts",
        items: [],
        icon: spareParts,
        level: 2,
      },
      {
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        name: "Sim Cards",
        items: [
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.FINANCE,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Sim Cards Uploading",
            link: "/meters/sim-cards/uploading",
            items: [],
            level: 2,
          },
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.GENERAL,
              USER_ROLES.FINANCE,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Sim Cards Reports",
            link: "/meters/sim-card/reports",
            items: [],
            level: 2,
          },
        ],
        icon: simCards,
        level: 2,
      },

      {
        name: "Registration",
        roles: [
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        hideRoles: [USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD],
        level: 2,
        items: [
          {
            name: "Templates",
            link: "/meters/registration/templates",
            items: [],
            icon: "",
            level: 3,
          },
        ],
        icon: registrationIcon,
      },
      {
        name: "Meter Box and Send",
        link: "/meters/box",
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        level: 2,
        items: [],
        icon: allAccets,
      },
      {
        name: "Configuration",
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        level: 2,
        items: [
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.FINANCE,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Firmware List",
            link: "/meters/configuration/firmwares",
            items: [],
            icon: "",
            level: 3,
          },
          {
            roles: [USER_ROLES.METER_ENGINEER_LEADERSHIP],
            name: "Firmware Update Processes",
            link: "/meters/configuration/update-processes",
            items: [],
            icon: "",
            level: 3,
          },
          {
            roles: [USER_ROLES.METER_ENGINEER_LEADERSHIP],
            name: "Meters Firmware Update History",
            link: "/meters/configuration/firmware-update-history",
            items: [],
            icon: "",
            level: 3,
          },
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.FINANCE,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Accessory",
            link: "/meters/configuration/accessory",
            items: [],
            icon: "",
            level: 3,
          },
        ],
        icon: configuration,
      },
      {
        name: "Logs",
        level: 2,
        icon: logs,
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        items: [
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.FINANCE,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Cooking Sessions",
            link: "/meters/logs/session",
            items: [],
            icon: "",
            level: 3,
          },
        ],
      },
      {
        name: "Jobs",
        level: 2,
        icon: jobs,
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        items: [
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.FINANCE,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Outgoing Logs",
            link: "/meters/jobs/logs",
            items: [],
            icon: "",
            level: 3,
          },
        ],
      },
    ],
  },
  {
    name: "Package Management",
    roles: [
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.SYSTEM_SUPPORT,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.D_SM,
      USER_ROLES.A_SM,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.GENERAL,
      USER_ROLES.FINANCE,
      USER_ROLES.TSS,
    ],
    level: 1,
    icon: packages,
    items: [
      {
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.D_SM,
          USER_ROLES.A_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.TSS,
        ],
        name: "All Packages",
        level: 2,
        link: "/packages/list",
        items: [],
        icon: packages,
      },
    ],
  },
  {
    name: "SMS Management",
    roles: [
      USER_ROLES.SYSTEM_FULL_ADMINS,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
    ],
    level: 1,
    icon: sms,
    items: [
      {
        roles: [
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
        ],
        name: "SMS Provider",
        level: 2,
        link: "/sms/sms-provider",
        items: [],
        icon: smsProvider,
      },
      {
        roles: [USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP],
        name: "SMS Bulk",
        level: 2,
        link: "/sms/sms-bulk",
        items: [],
        icon: smsBulk,
      },
    ],
  },
  {
    name: "Stock Management",
    roles: [
      USER_ROLES.DEPOT_CLERK,
      USER_ROLES.WAREHOUSE,
      USER_ROLES.LOGISTICS,
      USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.FINANCE,
    ],
    level: 1,
    icon: stoManagement,
    items: [
      {
        name: "All Stock Transfer Orders",
        link: "/stock-management/all",
        roles: [USER_ROLES.DEPOT_CLERK, USER_ROLES.WAREHOUSE, USER_ROLES.LOGISTICS],
        items: [],
        icon: allSTO,
        level: 3,
      },
      {
        name: "All Trips",
        link: "/stock-management/trips",
        roles: [
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.WAREHOUSE,
          USER_ROLES.LOGISTICS,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.FINANCE,
        ],
        items: [],
        icon: allTrips,
        level: 3,
      },
      // {
      //   name: "Loading Bays",
      //   link: "/stock-management/loading-bays",
      //   roles: [USER_ROLES.WAREHOUSE, USER_ROLES.DEPOT_CLERK],
      //   items: [],
      //   icon: loadingBays,
      //   level: 3,
      // },
    ],
  },
  {
    name: "Transport Management",
    roles: [
      USER_ROLES.SYSTEM_FULL_ADMINS,
      USER_ROLES.SECURITY,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
    ],
    icon: transportManagement,
    level: 1,
    items: [
      {
        name: "All Vehicles",
        link: "/transport-management/all-vehicles",
        roles: [
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.SECURITY,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        ],
        items: [],
        icon: allSTO,
        level: 3,
      },
    ],
  },
  {
    name: USER_ROLES.WAREHOUSE,
    roles: [
      USER_ROLES.WAREHOUSE,
      USER_ROLES.A_DSM,
      USER_ROLES.D_SM,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.TRAINING,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.DEPOT_CLERK,
      USER_ROLES.SALES_SUPPORT,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.GENERAL,
      USER_ROLES.FINANCE,
      USER_ROLES.A_SM,
    ],
    icon: warehouse,
    level: 1,
    items: [
      {
        roles: [
          USER_ROLES.A_DSM,
          USER_ROLES.A_SM,
          USER_ROLES.D_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.WAREHOUSE,
          USER_ROLES.TRAINING,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
        ],
        name: "All Assets",
        link: "/warehouse/assets",
        items: [],
        icon: allAccets,
        level: 2,
      },
      {
        name: "Asset Request History",
        link: "/warehouse/asset-request",
        items: [],
        icon: taskArchive,
        level: 2,
      },
      {
        roles: [
          USER_ROLES.WAREHOUSE,
          USER_ROLES.A_DSM,
          USER_ROLES.D_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.A_SM,
        ],
        name: "Asset Validation",
        link: "/warehouse/asset-validation",
        items: [],
        icon: taskRequest,
        level: 2,
      },
      {
        roles: [
          USER_ROLES.WAREHOUSE,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        ],
        name: "Stove Repair",
        link: "/warehouse/repair",
        items: [],
        icon: allReadyForRepair,
        level: 2,
      },
      {
        name: "Cylinder Repair",
        roles: [
          USER_ROLES.WAREHOUSE,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        ],
        link: "/warehouse/cylinder-repair",
        items: [],
        icon: allReadyForRepair,
        level: 2,
      },
    ],
  },
  {
    roles: [
      USER_ROLES.A_DSM,
      USER_ROLES.D_SM,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.WAREHOUSE,
      USER_ROLES.TRAINING,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.DEPOT_CLERK,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.GENERAL,
      USER_ROLES.FINANCE,
      USER_ROLES.TSS,
      USER_ROLES.A_SM,
    ],
    name: "Cylinder Weight Reports",
    icon: maintenanceReports,
    level: 1,
    items: [
      {
        roles: [
          USER_ROLES.A_DSM,
          USER_ROLES.D_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.TRAINING,
          USER_ROLES.WAREHOUSE,
          USER_ROLES.A_SM,
        ],
        name: "From Market Reports",
        link: "/cylinder-weight-reports/from-market-reports",
        items: [],
        icon: fromMarket,
        level: 2,
      },
      {
        roles: [
          USER_ROLES.A_DSM,
          USER_ROLES.D_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.TRAINING,
          USER_ROLES.TSS,
          USER_ROLES.WAREHOUSE,
          USER_ROLES.A_SM,
        ],
        name: "To Market Reports",
        link: "/cylinder-weight-reports/to-market-reports",
        items: [],
        icon: toMarket,
        level: 2,
      },
    ],
  },
  {
    name: "Operations",
    roles: [
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.CUSTOMER_CARE,
      USER_ROLES.TRAINING,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.DEPOT_CLERK,
      USER_ROLES.SALES_SUPPORT,
      USER_ROLES.GENERAL,
      USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.BUSINESS_DEV,
      USER_ROLES.FINANCE,
      USER_ROLES.TSS,
      USER_ROLES.A_SM,
    ],
    level: 1,
    icon: operations,
    items: [
      {
        roles: [
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.TRAINING,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.GENERAL,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.FINANCE,
          USER_ROLES.TSS,
          USER_ROLES.A_SM,
        ],
        name: "All Tasks",
        items: [
          {
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.DEPOT_CLERK,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
            ],
            name: "Tasks List",
            link: "/operations/tasks/list",
            items: [],
            level: 2,
          },
          {
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.GENERAL,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
              USER_ROLES.DEPOT_CLERK,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
            ],
            name: "Task Archive",
            link: "/operations/tasks/archive",
            items: [],
            level: 2,
          },
          {
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.DEPOT_CLERK,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.A_SM,
            ],
            name: "Investigations",
            link: "/operations/tasks/investigations",
            items: [],
            level: 2,
          },
          {
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.DEPOT_CLERK,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
            ],
            name: "Cancelled Tasks",
            link: "/operations/tasks/cancelled",
            items: [],
            level: 2,
          },
        ],
        icon: tasks,
      },
      {
        roles: [
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.TRAINING,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.A_SM,
        ],
        name: "Task Requests",
        link: "/operations/task-requests",
        items: [],
        icon: taskArchive,
        level: 2,
      },
      {
        roles: [
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.TRAINING,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.GENERAL,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.FINANCE,
          USER_ROLES.TSS,
          USER_ROLES.A_SM,
        ],
        name: "All Routines",
        icon: routines,
        items: [
          {
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.DEPOT_CLERK,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.GENERAL,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
            ],
            name: "Opened Routines",
            link: "/operations/routines/open",
            items: [],
            level: 2,
          },
          {
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.DEPOT_CLERK,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.GENERAL,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
            ],
            name: "Closed Routines",
            link: "/operations/routines/close",
            items: [],
            level: 2,
          },
          {
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.DEPOT_CLERK,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.GENERAL,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
            ],
            name: "Suspended Routines",
            link: "/operations/routines/suspended",
            items: [],
            level: 2,
          },
        ],
      },
      {
        name: "All Leads",
        roles: [
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.TRAINING,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.BUSINESS_DEV,
          USER_ROLES.FINANCE,
          USER_ROLES.TSS,
          USER_ROLES.A_SM,
        ],
        items: [
          {
            name: "Leads List",
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.BUSINESS_DEV,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
            ],
            link: "/operations/leads",
            items: [],
            level: 2,
          },
          {
            name: "Not Assigned Leads",
            roles: [
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.BUSINESS_DEV,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.A_SM,
            ],
            link: "/operations/not-assigned",
            items: [],
            level: 2,
          },
        ],
        icon: leads,
        level: 2,
      },
      {
        name: "All Prospects",
        roles: [
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.TRAINING,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.GENERAL,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.BUSINESS_DEV,
          USER_ROLES.FINANCE,
          USER_ROLES.TSS,
          USER_ROLES.A_SM,
        ],
        link: "/operations/prospects",
        items: [],
        icon: prospects,
        level: 2,
      },
      {
        name: "Refund Requests",
        link: "/operations/refund",
        roles: [
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.A_DSM,
          USER_ROLES.D_SM,
          USER_ROLES.A_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.TRAINING,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.FINANCE,
        ],
        items: [],
        icon: refund,
        level: 2,
      },
      {
        name: "Task Faulty Reasons",
        roles: [],
        icon: repossession,
        items: [
          {
            name: "Repossession Reasons",
            link: "/operations/repossession-reasons",
            roles: [],
            level: 2,
            items: [],
          },
          {
            name: "Meter Change Reasons",
            roles: [],
            level: 2,
            items: [
              {
                name: "Create/Approve/Execute",
                roles: [],
                level: 2,
                link: "/operations/meter-change-reasons",
              },
              {
                name: "Decline Requests",
                roles: [],
                level: 2,
                link: "/operations/meter-change-decline-reasons",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "TAM",
    roles: [
      USER_ROLES.D_SM,
      USER_ROLES.A_SM,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.CUSTOMER_CARE,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
      USER_ROLES.TRAINING,
    ],
    level: 1,
    icon: refund,
    items: [
      {
        roles: [
          USER_ROLES.D_SM,
          USER_ROLES.A_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.TRAINING,
        ],
        name: "TAM Requests",
        link: "/tam/requests",
        items: [],
        icon: refund,
        level: 2,
      },
    ],
  },
  {
    name: "Customer Management",
    roles: [
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.SYSTEM_SUPPORT,
      USER_ROLES.CUSTOMER_CARE,
      USER_ROLES.TRAINING,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.D_SM,
      USER_ROLES.A_SM,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.SALES_SUPPORT,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.GENERAL,
      USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
      USER_ROLES.BUSINESS_DEV,
      USER_ROLES.FINANCE,
      USER_ROLES.TSS,
      USER_ROLES.LOGISTICS,
      USER_ROLES.METER_ENGINEER,
      USER_ROLES.METER_ENGINEER_LEADERSHIP,
    ],
    level: 1,
    icon: customers,
    items: [
      {
        name: "Payments",
        level: 2,
        icon: payments,
        roles: [
          ...ALL_CUSTOMERS_ROLES,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.FINANCE,
          USER_ROLES.LOGISTICS,
        ],
        items: [
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.FINANCE,
            ],
            name: "Unprocessed Payments",
            level: 2,
            link: "/customers/payments/unprocessed",
            items: [],
            icon: "",
          },
          {
            roles: [
              USER_ROLES.PRODUCT_TECHNICIANS,
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.TRAINING,
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.FINANCE,
              USER_ROLES.LOGISTICS,
            ],
            name: "Failed Payments",
            level: 2,
            link: "/customers/payments/failed",
            items: [],
            icon: "",
          },
        ],
      },
      {
        name: "All Customers",
        roles: [
          ...ALL_CUSTOMERS_ROLES,
          USER_ROLES.D_SM,
          USER_ROLES.A_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.BUSINESS_DEV,
          USER_ROLES.FINANCE,
          USER_ROLES.TSS,
          USER_ROLES.METER_ENGINEER,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        icon: allCustomers,
        items: [
          {
            roles: [
              ...ALL_CUSTOMERS_ROLES,
              USER_ROLES.D_SM,
              USER_ROLES.A_SM,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.BUSINESS_DEV,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Active Customers",
            level: 2,
            link: "/customers/customers/active",
          },
          {
            roles: [
              ...ALL_CUSTOMERS_ROLES,
              USER_ROLES.D_SM,
              USER_ROLES.A_SM,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.BUSINESS_DEV,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Repossessed Customers",
            level: 2,
            link: "/customers/customers/repossessed",
          },
          {
            roles: [
              ...ALL_CUSTOMERS_ROLES,
              USER_ROLES.D_SM,
              USER_ROLES.A_SM,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.GENERAL,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
              USER_ROLES.BUSINESS_DEV,
              USER_ROLES.FINANCE,
              USER_ROLES.TSS,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.METER_ENGINEER_LEADERSHIP,
            ],
            name: "Suspended Customers",
            level: 2,
            link: "/customers/customers/suspended",
          },
        ],
      },
      {
        roles: [
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.D_SM,
          USER_ROLES.A_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
        ],
        name: "Customer Update Requests",
        level: 2,
        icon: customerUpdateRequest,
        items: [
          {
            roles: [
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.D_SM,
              USER_ROLES.A_SM,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
            ],
            name: "Update Details Requests",
            level: 3,
            link: "/customers/update-requests",
          },
          {
            roles: [
              USER_ROLES.SYSTEM_SUPPORT,
              USER_ROLES.CUSTOMER_CARE,
              USER_ROLES.D_SM,
              USER_ROLES.A_SM,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
            ],
            name: "Update Location Requests",
            level: 3,
            link: "/customers/update-location-requests",
          },
        ],
      },
      /*{
        name: "Send ADMO",
        level: 2,
        link: "/customers/send-admo",
        items: [],
        roles: [USER_ROLES.SYSTEM_SUPPORT, USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP, USER_ROLES.PRODUCT_TECHNICIANS],
        icon: sendAdmoIcon,
      },*/
    ],
  },
  {
    name: "User Management",
    roles: [
      USER_ROLES.SYSTEM_FULL_ADMINS,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.A_SM,
      USER_ROLES.SALES_SUPPORT,
      USER_ROLES.TSS,
    ],
    level: 1,
    icon: customers,
    items: [
      {
        name: "All Users",
        roles: [
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.A_SM,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.TSS,
        ],
        icon: allCustomers,
        level: 2,
        items: [],
        link: "/users/list",
      },
    ],
  },
  {
    name: "MARKETING",
    roles: [],
    level: 1,
    icon: marketing,
    items: [
      {
        name: "Marketing Initiatives",
        level: 2,
        link: "/marketing/initiatives",
        items: [],
        roles: [],
        icon: initiatives,
      },
    ],
  },
  {
    name: "Depot Management",
    roles: [
      USER_ROLES.WAREHOUSE,
      USER_ROLES.DEPOT_CLERK,
      USER_ROLES.CUSTOMER_CARE,
      USER_ROLES.A_DSM,
      USER_ROLES.D_SM,
      USER_ROLES.A_SM,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.SALES_SUPPORT,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.GENERAL,
      USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
      USER_ROLES.BUSINESS_DEV,
      USER_ROLES.FINANCE,
    ],
    level: 1,
    icon: depot,
    items: [
      {
        roles: [
          USER_ROLES.WAREHOUSE,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.A_DSM,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.D_SM,
          USER_ROLES.A_SM,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.BUSINESS_DEV,
          USER_ROLES.FINANCE,
        ],
        name: "All Depots",
        level: 2,
        link: "/depots/list",
        items: [],
        icon: depot,
      },
      /*{
        roles: [
          USER_ROLES.WAREHOUSE,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.A_DSM,
          USER_ROLES.D_SM,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
        ],
        name: "All Teams",
        level: 2,
        link: "/depots/teams",
        items: [],
        icon: teamsIcon,
      },*/
    ],
  },
  {
    name: "Settings",
    level: 1,
    icon: settings,
    roles: [
      USER_ROLES.SYSTEM_FULL_ADMINS,
      USER_ROLES.TECHNICIANS,
      USER_ROLES.MTSR,
      USER_ROLES.SALES_SUPPORT,
      USER_ROLES.DEPOT_CLERK,
      USER_ROLES.OPERATION_MANAGERS,
      USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
      USER_ROLES.CUSTOMER_CARE,
      USER_ROLES.GENERAL,
      USER_ROLES.BUSINESS_DEV,
      USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.IT_OPERATIONS,
      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
      USER_ROLES.WAREHOUSE,
      USER_ROLES.A_SM,
      USER_ROLES.TRAINING,
      USER_ROLES.FINANCE,
      USER_ROLES.SYSTEM_SUPPORT,
      USER_ROLES.INTERNAL_AUDITOR,
      USER_ROLES.TSS,
      USER_ROLES.LOGISTICS,
    ],
    items: [
      // {
      //   name: "Users",
      //   level: 2,
      //   link: "/settings/users",
      //   items: [],
      //   icon: users,
      // },
      {
        name: "Asset Types",
        link: "/settings/types",
        roles: [
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.SYSTEM_SUPPORT,
          USER_ROLES.INTERNAL_AUDITOR,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.GENERAL,
          USER_ROLES.FINANCE,
          USER_ROLES.TSS,
        ],
        items: [],
        icon: assetTypes,
        level: 3,
      },
      {
        name: "Survey Management",
        link: "/settings/surveys",
        roles: [
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.LOGISTICS,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        ],
        items: [],
        icon: surveyManagementIcon,
        level: 3,
      },
      {
        name: "Campaign Management",
        link: "/settings/campaigns",
        roles: [USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.CUSTOMER_CARE_LEADERSHIP],
        items: [],
        icon: campaignManagementIcon,
        level: 3,
      },
      {
        name: "Contract Versions",
        link: "/settings/contract-versions",
        icon: contractChange,
        roles: [
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.TECHNICIANS,
          USER_ROLES.MTSR,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.DEPOT_CLERK,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.GENERAL,
          USER_ROLES.BUSINESS_DEV,
          USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
          USER_ROLES.PRODUCT_TECHNICIANS,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.WAREHOUSE,
          USER_ROLES.A_SM,
          USER_ROLES.TRAINING,
          USER_ROLES.FINANCE,
          USER_ROLES.METER_ENGINEER_LEADERSHIP,
        ],
        items: [],
        level: 3,
      },
    ],
  },
  {
    name: "Price Management",
    roles: [USER_ROLES.A_SM, USER_ROLES.FINANCE, USER_ROLES.IT_OPERATIONS_LEADERSHIP],
    level: 1,
    icon: price,
    items: [
      {
        name: "All Segments",
        link: "/price-management/segments",
        roles: [USER_ROLES.A_SM, USER_ROLES.FINANCE, USER_ROLES.IT_OPERATIONS_LEADERSHIP],
        items: [],
        icon: money,
        level: 3,
      },
    ],
  },
];
