import React, { useCallback, useState } from "react";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import CustomLink from "components/Common/buttons/CustomLink";
import PreviewModal from "pages/settings/contract-versions/versionCreate/PreviewModal";
import { downloadFileBlob } from "utils/api";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import downloadIcon from "assets/images/svg/DownloadFromCloud.svg";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import { Row } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { defaultNgiErrorMethod } from "components/Common/responses/message";

const useStyles = makeStyles({
  link: {
    marginRight: 10,
  },
  row: {
    alignItems: "center",
  },
});

const ReportTab = ({ stops, closingReport }) => {
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState();

  const onPreview = useCallback(e => {
    e.preventDefault();
    const url = e.target.dataset.url;
    setPreviewUrl(url);
  }, []);

  const onDownload = useCallback((url, fileName) => {
    downloadFileBlob(url)
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
      });
  }, []);

  return (
    <>
      {stops?.map(stop => (
        <div key={stop.location.depot}>
          <TitleWithDivider title={stop.location.depot} />
          {stop.report ? (
            <Row className={classes.row}>
              <CustomLink
                underlined
                to={" "}
                data-url={stop.report?.fullPath}
                onClick={onPreview}
                className={classes.link}
              >
                {stop.report?.filename}
              </CustomLink>
              <ButtonGroupContainer>
                <ButtonGroup
                  tooltipTitle="Download"
                  icon={downloadIcon}
                  handleClick={() => onDownload(stop.report?.fullPath, stop.report?.filename)}
                />
              </ButtonGroupContainer>
            </Row>
          ) : (
            <h6>No report</h6>
          )}
        </div>
      ))}

      <div>
        <TitleWithDivider title={"TRIP REPORT"} />
        {closingReport ? (
          <Row className={classes.row}>
            <CustomLink
              underlined
              to={" "}
              data-url={closingReport?.fullPath}
              onClick={onPreview}
              className={classes.link}
            >
              {closingReport?.filename}
            </CustomLink>
            <ButtonGroupContainer>
              <ButtonGroup
                tooltipTitle="Download"
                icon={downloadIcon}
                handleClick={() => onDownload(closingReport?.fullPath, closingReport.filename)}
              />
            </ButtonGroupContainer>
          </Row>
        ) : (
          <h6>No report</h6>
        )}
      </div>

      <PreviewModal
        contractLoading={false}
        title={"Report Preview"}
        previewUrl={previewUrl}
        open={!!previewUrl}
        onCancel={() => setPreviewUrl(undefined)}
        height={1000}
      />
    </>
  );
};

export default ReportTab;
