import React, { useCallback, useState } from "react";
import { Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { updateMeterMaintenanceReportAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/es/typography/Title";
import FaultyReasons from "pages/meter-management/ready-for-repair/RepairMeter/Tabs/FaultyReasons";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
});

const MarkAsFaultyReportModal = ({ onClose, activeItem, updateReportsList, updateMeterList = () => {} }) => {
  const [form] = Form.useForm();
  const [loading, setLoader] = useState(false);
  const classes = useStyles();
  const [faultyReasons, setFaultyReasons] = useState([]);
  const { filters } = useStoreFilters(FILTER_PAGE.READY_FOR_REPAIR);

  const handleSubmit = useCallback(() => {
    setLoader(true);
    updateMeterMaintenanceReportAPI(activeItem, faultyReasons)
      .then(() => {
        onClose();
        warnMessage(`Report's faulty reason was updated successfully.`);
        setLoader(false);
        updateReportsList();
        updateMeterList(filters);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [faultyReasons]);

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Edit Faulty Reasons
            <span>{`Report id:  ${activeItem}`}</span>
          </div>
        </div>
      }
      className={classes.modal}
      open={true}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={loading}>
        <>
          <Title level={5}>Faulty Reasons</Title>
          <NGIForm name="faulty-reasons" form={form}>
            <FaultyReasons updateFaultyReason={setFaultyReasons} />
          </NGIForm>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton
              disabled={!faultyReasons?.length}
              onClick={handleSubmit}
              type="primary"
              text="Save"
              size="small"
            />
          </Space>
        </>
      </Spin>
    </Modal>
  );
};

export default MarkAsFaultyReportModal;
