import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Form, Spin } from "antd";
import NGICheckbox from "components/Common/inputs/NGICheckbox";
import Title from "antd/es/typography/Title";
import { retrieveMeterSpareParts } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import { AUTH_USER } from "utils/constants";

const useStyles = makeStyles({
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  checkboxRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 60,
    width: 350,
    marginRight: 10,
    position: "relative",
  },
  category: {
    width: 150,
  },
  input: { width: 70, marginTop: 10 },
  error: { position: "absolute", bottom: 0, color: "red", fontSize: 10 },
});

const SpareParts = ({ setSelectedSpareParts }) => {
  const classes = useStyles();
  const [spareParts, setSpareParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    retrieveMeterSpareParts(AUTH_USER?.depotName)
      .then(res => {
        const {
          data: { result },
        } = res;
        setSpareParts(result);
        setIsLoading(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setIsLoading(false);
      });
  }, []);

  const onSelectCategory = useCallback(
    e => {
      const {
        value,
        checked,
        dataset: { partName },
      } = e.target;
      setSelectedItems(prevState => {
        const newState = checked
          ? [...prevState, { partName, partId: value, count: 1 }]
          : prevState.filter(item => item.partId !== value);
        setSelectedSpareParts(newState);
        return newState;
      });
    },
    [selectedItems]
  );

  const onAmountChange = useCallback(
    e =>
      setSelectedItems(prevState => {
        const newState = [...prevState];
        const intValue = parseInt(e.target.value);
        const maxAllowedValue = parseInt(e.target.dataset.count);
        const objIndex = prevState.findIndex(obj => obj?.partId === e.target.dataset.id);
        if (intValue < 1) {
          newState[objIndex].count = 1;
        } else if (intValue > maxAllowedValue) {
          newState[objIndex].count = maxAllowedValue;
        } else newState[objIndex].count = intValue;
        setSelectedSpareParts(newState);
        return newState;
      }),
    []
  );

  const renderQuantity = useCallback(
    ({ id, count }) =>
      selectedItems.map(item => {
        if (id === item?.partId)
          return (
            <NGIInput
              key={item?.partId}
              onChange={onAmountChange}
              data-id={id}
              data-count={count}
              type={"number"}
              min={1}
              value={item.count}
              max={count}
              className={classes.input}
            />
          );
        return null;
      }),
    [selectedItems]
  );

  return (
    <>
      <Spin spinning={isLoading}>
        <Title level={5}>Spare Parts</Title>
        <Form.Item className={classes.checkboxContainer} name="spareParts">
          <Checkbox.Group className={classes.checkboxContainer}>
            {spareParts?.map(item => (
              <div key={`key-${item.id}`} className={classes.checkboxRow}>
                <NGICheckbox
                  data-part-name={item.name}
                  onClick={onSelectCategory}
                  value={item.id}
                  disabled={!parseInt(item.count)}
                >
                  <div className={classes.category}>{item.name}</div>
                </NGICheckbox>
                {renderQuantity(item)}
                {!parseInt(item.count) && <div className={classes.error}>No available spare parts</div>}
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Spin>
    </>
  );
};

export default SpareParts;
