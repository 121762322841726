import React, { useCallback, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { retrieveRoutineDetails } from "utils/api";
import { Spin } from "antd";
import { CLASSIFICATION } from "pages/operations/routineDetails/constants";
import Header from "pages/operations/routineDetails/Header";
import DataTabs from "pages/operations/routineDetails/DataTabs";
import NotFoundContainer from "components/Common/NotFoundContainer";

const RoutineDetails = () => {
  const { id } = useParams();
  const [routine, setRoutine] = useState({});
  const [customers, setCustomers] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const onRetrieveRoutineDetails = useCallback(() => {
    setLoading(true);
    retrieveRoutineDetails(id)
      .then(({ data }) => {
        setRoutine({ ...data.result });
        const result = {
          [CLASSIFICATION.PRIORITY]: [],
          [CLASSIFICATION.REPOSSESSIONS]: [],
          [CLASSIFICATION.BACKLOG]: [],
          [CLASSIFICATION.FUTURE_TASKS]: [],
          [CLASSIFICATION.INSTALLATIONS]: [],
          [CLASSIFICATION.NOT_REACHABLE]: [],
        };

        data?.result?.customers.forEach(item => {
          const customerTasks = data?.result?.tasks.filter(task => task.customerId === item.id);
          item.classification.forEach(classification => {
            result[classification].push({ ...item, tasks: customerTasks });
          });
        });
        setCustomers(result);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
  }, []);

  useEffect(() => {
    onRetrieveRoutineDetails();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              <>
                <Header routine={routine} />
                <DataTabs
                  routine={routine}
                  customers={customers}
                  updateDetails={onRetrieveRoutineDetails}
                  isLoading={isLoading}
                />
              </>
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default RoutineDetails;
