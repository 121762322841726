import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { editableFieldsMap, VALIDATION_RULES } from "./constants";
import Header from "./Header";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import NGIInput from "components/Common/inputs/NGIInput";
import {
  approveCustomerUpdateRequestsById,
  createCustomerUpdateRequest,
  customerDuplicateCheckRequest,
  declineCustomerUpdateRequestsById,
  fetchCustomerById,
  retrieveCustomerPhotoUrlByType,
  retrieveCustomerUpdateRequestsById,
} from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";

import { useDispatch, useSelector } from "react-redux";
import Title from "antd/lib/typography/Title";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { extractPromisesResult } from "utils/helpers/functions";
import moment from "moment";
import CustomLink from "components/Common/buttons/CustomLink";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { customDateFormatWithoutTime, textFormatter } from "components/Common/formatter";
import OTPVerificationModal from "pages/customer-management/customerUpdateRequest/createCustomerDetailsUpdaterequest/OTPVerificationModal";
import { useHistory, useParams } from "react-router";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import ChangeOverviewModel from "pages/customer-management/customerUpdateRequest/createCustomerDetailsUpdaterequest/ChangeOverviewModel";
import { USER_ROLES } from "utils/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  buttonCenter: {
    marginTop: 5,
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  link: {
    textDecoration: "underline!important",
    color: "rgba(0, 133, 255, 1) !important",
    margin: "20px 0",
    fontWeight: "bold",
    display: "block",
    fontSize: 15,
    maxWidth: "max-content",
  },
  editContainer: {
    display: "flex",
  },
  filedColumn: {
    padding: "0 10px",
  },
  columnTitle: {
    fontSize: "14px!important",
  },
  button: {
    height: 50,
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 10,
    },
  },
  approveDeclineContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  column: {
    width: "33%",
    marginRight: 35,
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
    textAlign: "end",
  },
  columnsContainer: {
    display: "flex",
  },
  sendOtpBtn: {
    height: 30,
    padding: "0 12px",
  },
  customerFind: {
    fontSize: 15,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
});

const CreateCustomerDetailsUpdateRequest = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id: customerRequestId } = useParams();
  const [editForm] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [updatedCustomer, setUpdatedCustomer] = useState(null);
  const [requestUpdates, setRequestUpdates] = useState(null);
  const [controlsDisabled, setControlsDisabled] = useState(false);
  const [id, setId] = useState(null);
  const [photoDetails, setPhotoDetails] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [OTPVerificationData, setOTPVerificationData] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmationDeclineOpen, setIsConfirmationDeclineOpen] = useState(false);
  const [isChangesPreviewModalOpen, setIsChangesPreviewModalOpen] = useState(false);

  const openConfirmationModal = useCallback(e => {
    e.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(false);
  }, []);

  const openConfirmationDeclineModal = useCallback(e => {
    e.preventDefault();
    setIsConfirmationDeclineOpen(true);
  }, []);

  const closeConfirmationDeclineModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationDeclineOpen(false);
  }, []);

  const {
    list: { customerType, occupation: occupationList },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.CUSTOMER_TYPE));
    dispatch(getDictionary(DICTIONARY_TYPES.OCCUPATION));
    dispatch(getDictionary(DICTIONARY_TYPES.COUNTY));
  }, []);

  useEffect(() => {
    if (!!id) {
      Promise.allSettled([
        retrieveCustomerPhotoUrlByType(id, "personalPhoto"),
        retrieveCustomerPhotoUrlByType(id, "housePhoto"),
      ]).then(data => {
        const [customerPersonalPhoto, customerHousePhoto] = extractPromisesResult(data);
        setPhotoDetails({
          personalPhotoUrl: customerPersonalPhoto?.data?.result?.url,
          housePhotoUrl: customerHousePhoto?.data?.result?.url,
        });
      });
    }
  }, [id]);

  useEffect(() => {
    if (!!customerRequestId) {
      setLoader(true);
      retrieveCustomerUpdateRequestsById(customerRequestId).then(({ data }) => {
        setRequestUpdates(data.result);
        setId(data?.result?.customerId);

        fetchCustomerById(data?.result?.customerId).then(res => {
          setLoader(false);
          setCustomer(res?.data?.result);
          setUpdatedCustomer(res?.data?.result);
        });
      });
    }
  }, [customerRequestId]);

  useEffect(() => {
    if (!!customer) {
      const {
        firstName,
        lastName,
        middleName,
        dob,
        occupation,
        familySize,
        location: { street, region, houseNumber, district },
        phone,
        secondaryPhone,
        closeRelativePhone,
      } = customer;
      editForm.setFieldsValue({
        firstName,
        lastName,
        middleName,
        dob: moment(dob),
        occupation,
        familySize,
        street,
        region,
        houseNumber,
        district,
        phone,
        secondaryPhone,
        closeRelativePhone: closeRelativePhone || "",
      });

      if (!occupationList.includes(occupation)) {
        warnMessage("The occupation is incorrect. Please choose the new occupation.", 20);
      }
    }
  }, [customer]);

  const onFieldsChanged = useCallback(
    async () =>
      editForm
        .validateFields()
        .then(() => {
          setFormValid(true);
        })
        .catch(res => {
          setFormValid(!res.errorFields.length);
        })
        .finally(() => {
          setIsDirty(true);
          setIsValidated(false);
        }),
    [editForm]
  );

  const sendOtpToCustomer = useCallback(
    e => {
      setLoader(true);
      e?.preventDefault();
      const { phone } = editForm.getFieldsValue();
      customerDuplicateCheckRequest(id, { phone })
        .then(({ data }) => {
          if (data?.result?.duplicates?.length) {
            warnMessage("The customer's phone is duplicated. Please try again with a new phone number.", 10);
          } else {
            setIsDirty(false);
            setOTPVerificationData({
              customerId: id,
              phone,
            });
          }
          setLoader(false);
        })
        .catch(e => {
          setLoader(false);
          defaultNgiErrorMethod(e);
        });
    },
    [editForm, id]
  );

  const onCloseVerificationModal = useCallback(() => setOTPVerificationData(null), []);

  const getField = useCallback(
    (type, disabled = false) => {
      switch (type) {
        case "dob":
          return (
            <NGIDatePicker
              disabled={disabled}
              onChange={onFieldsChanged}
              disableDates={current => moment().add(-100, "years") >= current || moment().add(-18, "years") <= current}
            />
          );
        case "occupation":
          return (
            <NGISelect disabled={disabled} onChange={onFieldsChanged} placeholder={"Select value"}>
              {occupationList.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </NGISelect>
          );
        case "type":
          return (
            <NGISelect disabled={disabled} onChange={onFieldsChanged} placeholder={"Select value"}>
              {customerType?.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </NGISelect>
          );
        case "personalPhotoFileName":
          return photoDetails?.personalPhotoUrl ? (
            <div className={classes.img}>
              <img
                data-url={photoDetails?.personalPhotoUrl}
                onClick={onPreviewImg}
                src={photoDetails?.personalPhotoUrl}
                alt="preview"
              />
            </div>
          ) : (
            "No photo"
          );
        case "phone":
          return (
            <NGIInput
              disabled={disabled}
              suffix={
                !customerRequestId && (
                  <CustomButton
                    className={classes.sendOtpBtn}
                    disabled={!isDirty || !formValid}
                    onClick={sendOtpToCustomer}
                    size="small"
                    type="success"
                    text="SEND OTP"
                  />
                )
              }
            />
          );

        default:
          return <NGIInput disabled={disabled} />;
      }
    },
    [customerType, occupationList, photoDetails, sendOtpToCustomer, isDirty, onFieldsChanged, formValid]
  );

  const onPreviewImg = useCallback(e => {
    const url = e.currentTarget.dataset.url;
    setPreviewUrl(url);
  }, []);

  const onClose = useCallback(() => setPreviewUrl(""), []);

  const onSubmit = useCallback(
    values => {
      setLoader(true);
      setId(values?.customerId?.trim());
      fetchCustomerById(values?.customerId?.trim())
        .then(res => {
          setLoader(false);
          if (res?.data?.result?.status === "Active") {
            setCustomer(res?.data?.result);
            setUpdatedCustomer(res?.data?.result);
          } else {
            warnMessage("Customer should be ACTIVE");
            setCustomer(null);
            setUpdatedCustomer(null);
          }
        })
        .catch(error => {
          let msg = "Customer is not found";
          const errorMessage = error.response?.data?.errors[0];
          if (errorMessage.code === "ACCESS_DENIED") {
            msg = errorMessage.desc;
            form.setFieldValue("customerId", null);
            setControlsDisabled(true);
          }
          warnMessage(msg);
          setLoader(false);
          setCustomer(null);
          setUpdatedCustomer(null);
        });
    },
    [form]
  );

  const onSave = useCallback(
    values => {
      setLoader(true);
      const { depot } = updatedCustomer;
      const {
        firstName,
        lastName,
        middleName,
        dob,
        occupation,
        familySize,
        phone,
        secondaryPhone,
        closeRelativePhone,
      } = values;

      const params = {
        firstName,
        lastName,
        middleName,
        dob: customDateFormatWithoutTime(dob),
        occupation,
        familySize: parseInt(familySize),
        phone: phone?.toString(),
        secondaryPhone: secondaryPhone?.toString(),
        closeRelativePhone: closeRelativePhone?.toString(),
        depot,
        //added temporary
        personalPhotoFileName: updatedCustomer?.personalPhotoFileName || " ",
      };
      createCustomerUpdateRequest(id.trim(), params)
        .then(() => {
          successMessage("Update request was created successfully");
          setLoader(false);
          history.push("/customers/update-requests");
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoader(false);
        });
    },
    [editForm, id, updatedCustomer]
  );

  const handleApprove = useCallback(() => {
    setLoader(true);
    approveCustomerUpdateRequestsById(customerRequestId)
      .then(() => {
        setLoader(false);
        setIsConfirmationOpen(false);
        successMessage(`The information for ${customer.firstName} ${customer.lastName} was updated successfully.`, 5);
        history.push("/customers/update-requests");
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [customerRequestId, customer]);

  const handleDecline = useCallback(
    reason => {
      setLoader(true);
      declineCustomerUpdateRequestsById(customerRequestId, reason?.trim())
        .then(() => {
          setLoader(false);
          setIsConfirmationDeclineOpen(false);
          successMessage(`The information for ${customer.firstName} ${customer.lastName} was declined successfully`, 5);
          history.push("/customers/update-requests");
        })
        .catch(e => {
          setLoader(false);
          defaultNgiErrorMethod(e);
        });
    },
    [customerRequestId, customer]
  );

  const handleViewChanges = useCallback(e => {
    e.preventDefault();
    setIsChangesPreviewModalOpen(true);
  }, []);

  const closeViewChangesModal = useCallback(() => setIsChangesPreviewModalOpen(false), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header isEdit={!!customerRequestId} />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    {!customerRequestId && (
                      <Form form={form} layout="inline" onFinish={onSubmit} className={classes.formContainer}>
                        <div className={classes.modalContent}>
                          <Row>
                            <Col span={3} className={classes.customerFind}>
                              Find Customer
                            </Col>
                            <Col span={12}>
                              <Form.Item name="customerId" rules={VALIDATION_RULES}>
                                <NGIInput disabled={controlsDisabled} allowClear placeholder={"Enter Customer ID"} />
                              </Form.Item>
                            </Col>
                            <Col span={9} className={classes.buttonCenter}>
                              <CustomButton
                                disabled={controlsDisabled}
                                htmlType="submit"
                                type="primary"
                                text="Search"
                                size="small"
                              />
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    )}
                    {Boolean(customer) && (
                      <>
                        <CustomLink
                          className={classes.link}
                          target="_blank"
                          to={`/customers/customer/${id || customer.id}`}
                        >
                          {(!!id || customer.id) && `${customer.firstName} ${customer.lastName}`}
                        </CustomLink>

                        <div className={classes.columnsContainer}>
                          <div className={classes.column}>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Customer ID</div>
                              <div className={classes.data}>{textFormatter(id || customer?.id)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Phone</div>
                              <div className={classes.data}>{textFormatter(customer?.phone)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Location (region, district)</div>
                              <div
                                className={classes.data}
                              >{`${customer?.location?.region}, ${customer?.location?.district}`}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Address</div>
                              <div
                                className={classes.data}
                              >{`${customer?.location?.street} ${customer?.location?.houseNumber}`}</div>
                            </div>
                          </div>
                          <div className={classes.column}>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Current Customer Depot</div>
                              <div className={classes.data}>{textFormatter(customer?.depot)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Customer Package</div>
                              <div className={classes.data}>{textFormatter(customer?.packageName)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Assigned TSR</div>
                              <div className={classes.data}>
                                {customer?.technicianName ? (
                                  <CustomLink underlined target="_blank" to={`/user/${customer?.technician}`}>
                                    {textFormatter(customer?.technicianName)}
                                  </CustomLink>
                                ) : (
                                  textFormatter(customer?.technicianName)
                                )}
                              </div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>TSR Email</div>
                              <div className={classes.data}>
                                {customer?.technician ? (
                                  <CustomLink underlined target="_blank" to={`/user/${customer?.technician}`}>
                                    {textFormatter(customer?.technician)}
                                  </CustomLink>
                                ) : (
                                  textFormatter(customer?.technician)
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={classes.editContainer}>
                          <Form
                            form={editForm}
                            onFinish={onSave}
                            onChange={onFieldsChanged}
                            className={classes.formContainer}
                            disabled={!!customerRequestId}
                          >
                            {editableFieldsMap.map(item => (
                              <div className={classes.section} key={item.title}>
                                <TitleWithDivider title={item.title} />
                                <Row>
                                  {item.fields.map(field => {
                                    const [fieldName] = Object.keys(field);
                                    const fieldByType = getField(fieldName, field.disabled);
                                    return (
                                      <Col span={6} className={classes.filedColumn} key={fieldName}>
                                        <Title className={classes.columnTitle}>{field[fieldName]}</Title>
                                        <Form.Item
                                          name={fieldName}
                                          rules={[
                                            { required: field?.required, message: "Please enter value" },
                                            ...(field?.rules || []),
                                          ]}
                                        >
                                          {fieldByType}
                                        </Form.Item>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </div>
                            ))}
                            <Divider />
                            <div className={classes.approveDeclineContainer}>
                              {customerRequestId && (
                                <>
                                  <CustomButton
                                    color="outlined"
                                    size="small"
                                    text="Changes overview"
                                    onClick={handleViewChanges}
                                  />

                                  <Space>
                                    <CustomButton
                                      hideRoles={[
                                        USER_ROLES.CUSTOMER_CARE,
                                        USER_ROLES.IT_OPERATIONS,
                                        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                                        USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                                      ]}
                                      type="error"
                                      size="small"
                                      text="Decline request"
                                      onClick={openConfirmationDeclineModal}
                                    />
                                    <CustomButton
                                      hideRoles={[
                                        USER_ROLES.CUSTOMER_CARE,
                                        USER_ROLES.IT_OPERATIONS,
                                        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                                        USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                                      ]}
                                      type="success"
                                      size="small"
                                      text="Approve request"
                                      onClick={openConfirmationModal}
                                    />
                                  </Space>
                                </>
                              )}
                            </div>

                            <div className={classes.buttonsContainer}>
                              {!customerRequestId && (
                                <CustomButton
                                  htmlType="submit"
                                  type="primary"
                                  size="small"
                                  text="send request"
                                  disabled={!isValidated}
                                />
                              )}
                            </div>
                          </Form>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
              {OTPVerificationData && (
                <OTPVerificationModal
                  onClose={onCloseVerificationModal}
                  data={OTPVerificationData}
                  onVerifySuccess={setIsValidated}
                  onSendOTP={sendOtpToCustomer}
                  customerData={customer}
                />
              )}
              <Modal title="Photo preview" open={!!previewUrl} onCancel={onClose} footer={[]}>
                <img className={classes.imgPreview} src={previewUrl} alt="preview" />
              </Modal>
              {isConfirmationOpen && (
                <ConfirmationModal
                  description="Are you sure you want to approve request?"
                  onCancel={closeConfirmationModal}
                  onConfirm={handleApprove}
                />
              )}
              {isConfirmationDeclineOpen && (
                <ConfirmationModal
                  title="Decline Reason"
                  description="Add Decline Reason"
                  onCancel={closeConfirmationDeclineModal}
                  cancelBtnTitle="Cancel"
                  confirmBtnTitle="Save Changes"
                  onConfirm={handleDecline}
                  withComment
                  isCommentRequired
                  commentLength={100}
                />
              )}

              {isChangesPreviewModalOpen && (
                <ChangeOverviewModel
                  customer={customer}
                  oldPhotoDetails={photoDetails}
                  customerChanges={requestUpdates.updates}
                  onClose={closeViewChangesModal}
                />
              )}
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default CreateCustomerDetailsUpdateRequest;
