import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Divider, Form, Row, Spin } from "antd";
import { VALIDATION_RULES } from "./constants";
import Header from "./Header";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import NGIInput from "components/Common/inputs/NGIInput";
import { fetchCustomerBalance, fetchCustomerById, fetchCustomerConsumptionStat, retrieveAssets } from "utils/api";
import { warnMessage } from "components/Common/responses/message";
import info from "./../../../assets/images/svg/InfoRed.svg";
import CreateTaskSection from "pages/operations/createTask/CreateTaskSection";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import classnames from "classnames";
import { dateAgo } from "utils/helpers/functions";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  containerData: {
    display: "flex",
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sectionItem: {
    marginTop: 30,
  },
  informationContainer: {
    display: "flex",
    width: "50%",
    paddingRight: 25,
  },
  mapContainer: {
    width: "50%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
    paddingLeft: 25,
  },
  inactive: {
    fontSize: 13,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "red",
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
  },
  inactiveImg: {
    margin: "10px 10px 10px 0",
  },
  buttonCenter: {
    marginTop: 5,
  },
  customerFind: {
    fontSize: 15,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 14,
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
  green: {
    color: "#00b050",
  },
  red: {
    color: "#ff4d4f",
  },
});

const CreateTask = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [controlsDisabled, setControlsDisabled] = useState(false);
  const [lastCylinderChange, setLastCylinderChange] = useState(null);
  const [customerBalance, setCustomerBalance] = useState(null);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_CHANGE_REASONS_V2));
  }, []);
  const onSubmit = useCallback(
    values => {
      setLoader(true);
      const id = values.customerId?.trim();
      fetchCustomerById(id)
        .then(res => {
          fetchCustomerConsumptionStat(id).then(({ data }) => {
            setLoader(false);
            setCustomer({ ...res?.data?.result, consumptionStat: data?.result });
          });
          fetchCustomerBalance(id).then(({ data }) => {
            setCustomerBalance(data?.result?.items[0]);
          });
          retrieveAssets({ customerId: id }).then(({ data }) => {
            const lastCylinderChange = data?.result?.items?.find(item => item.typeGroup === "Cylinder");
            setLastCylinderChange(
              lastCylinderChange?.updatedAt && moment().diff(lastCylinderChange?.updatedAt, "days")
            );
            setAssets(data?.result?.items);
          });
        })
        .catch(error => {
          let msg = "Customer is not found";
          const errorMessage = error.response?.data?.errors[0];
          if (errorMessage.code === "ACCESS_DENIED") {
            msg = errorMessage.desc;
            form.setFieldValue("customerId", null);
            setControlsDisabled(true);
          }
          warnMessage(msg);
          setLoader(false);
          setCustomer(null);
        });
    },
    [form]
  );

  //added to avoid eslint check
  //need to remove component if it will not be used
  const name = "";
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header isEdit={name !== "new"} />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    <Form form={form} layout="inline" onFinish={onSubmit} className={classes.formContainer}>
                      <div className={classes.modalContent}>
                        <Row>
                          <Col span={3} className={classes.customerFind}>
                            Find Customer
                          </Col>
                          <Col span={12}>
                            <Form.Item name="customerId" rules={VALIDATION_RULES}>
                              <NGIInput disabled={controlsDisabled} allowClear placeholder={"Enter Customer ID"} />
                            </Form.Item>
                          </Col>
                          <Col span={9} className={classes.buttonCenter}>
                            <CustomButton
                              disabled={controlsDisabled}
                              htmlType="submit"
                              type="primary"
                              text="Search"
                              size="small"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                    {Boolean(customer) && (
                      <>
                        <Divider />
                        <CreateTaskSection data={customer} />
                        <Divider />
                        <Row>
                          <Col span={24}>
                            {customer.status !== "Active" && (
                              <div className={classes.inactive}>
                                <img className={classes.inactiveImg} src={info} alt="info" /> Customer is not active
                              </div>
                            )}
                          </Col>
                        </Row>
                        <div className={classes.containerData}>
                          <div className={classes.informationContainer}>
                            <div className={classes.column}>
                              <div className={classes.sectionTitle}>Account Details</div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Customer ID</div>
                                <div className={classes.data}>{customer.id}</div>
                              </div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Name</div>
                                <div className={classes.data}>{`${customer.firstName} ${customer.lastName}`}</div>
                              </div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Address</div>
                                <div
                                  className={classes.data}
                                >{`${customer?.location?.region}, ${customer?.location?.district}, ${customer?.location?.street} ${customer?.location?.houseNumber}`}</div>
                              </div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Package</div>
                                <div className={classes.data}>{`${customer.packageName ?? ""}`}</div>
                              </div>

                              <Divider />
                              <div className={classes.sectionTitle}>Meter Data</div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Last Connected</div>
                                <div className={classes.data}>
                                  {textFormatter(
                                    customer?.consumptionStat?.meterLastConnectedTime
                                      ? customDateFormatWithTime(customer?.consumptionStat?.meterLastConnectedTime)
                                      : ""
                                  )}
                                </div>
                              </div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Battery Level</div>
                                <div
                                  className={classnames(
                                    classes.data,
                                    customer?.consumptionStat?.batteryLevel >= 20 ? classes.green : classes.red
                                  )}
                                >
                                  {textFormatter(
                                    !isNaN(customer?.consumptionStat?.batteryVoltage) &&
                                      !isNaN(customer?.consumptionStat?.batteryLevel)
                                      ? `${customer?.consumptionStat?.batteryVoltage}V (${customer?.consumptionStat?.batteryLevel}%)`
                                      : ""
                                  )}
                                </div>
                              </div>

                              <Divider />
                              <div className={classes.sectionTitle}>Cylinder Data</div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Gas Level</div>
                                <div className={classes.data}>
                                  {textFormatter(
                                    customer?.consumptionStat?.gasLevel
                                      ? `${customer?.consumptionStat?.gasLevel} Kg`
                                      : ""
                                  )}
                                </div>
                              </div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Last Cylinder Change</div>
                                <div className={classes.data}>
                                  {lastCylinderChange ? (
                                    <>{textFormatter(`${lastCylinderChange} days ago`)}</>
                                  ) : (
                                    textFormatter(lastCylinderChange)
                                  )}
                                </div>
                              </div>

                              <div className={classes.rowData}>
                                <div className={classes.title}>Age As Customer</div>
                                <div className={classes.data}>
                                  {textFormatter(customer?.installationDate ? dateAgo(customer?.installationDate) : "")}
                                </div>
                              </div>
                              <div className={classes.rowData}>
                                <div className={classes.title}>Spent Since Last Cylinder Change</div>
                                <div className={classes.data}>
                                  {textFormatter(customer?.consumptionStat?.amountSpentSinceLastCylinderChange)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classes.mapContainer}>
                            <div className={classes.sectionTitle}>Last 30 Days</div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Total Payments</div>
                              <div className={classes.data}>
                                {textFormatter(customer?.consumptionStat?.totalPaymentLast30Days)}
                              </div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Total Consumption</div>
                              <div className={classes.data}>
                                {textFormatter(customer?.consumptionStat?.totalConsumptionLast30Days)}
                              </div>
                            </div>

                            <div className={classes.rowData}>
                              <div className={classes.title}>Outstanding Amount</div>
                              <div className={classes.data}>{textFormatter(customerBalance?.outstandingAmount)}</div>
                            </div>
                            <Divider />

                            <div className={classes.sectionTitle}>Last 6 Months</div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Average Payment</div>
                              <div className={classes.data}>
                                {textFormatter(customer?.consumptionStat?.averagePaymentLast6Month)}
                              </div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Average Consumption</div>
                              <div className={classes.data}>
                                {textFormatter(customer?.consumptionStat?.averageConsumptionLast6Month)}
                              </div>
                            </div>
                            <Divider />

                            {assets && (
                              <>
                                <div className={classes.sectionTitle}>Assets</div>
                                {assets.map(item => (
                                  <div key={item.barcode} className={classes.rowData}>
                                    <div className={classes.title}>{item?.typeGroup}</div>
                                    <div className={classes.data}>{item?.barcode}</div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default CreateTask;
