export const fieldsToConvert = [
  "unitPrice",
  "gasFirstPaymentAmount",
  "stoveFirstPaymentAmount",
  "stoveRepairCost",
  "balance",
  "deductionDay",
  "deductionValue",
  "warrantyDuration",
  "stoveChangeEligibilityDuration",
  "stoveChangeEligibleNumber",
];

export const TYPE = {
  OWN_STOVE: "Own Stove",
  OUTRIGHT_PURCHASE: "Outright Purchase",
  INSTALLMENT_PURCHASE: "Instalment Purchase",
  OUTRIGHT_SALES: "Outright Sales",
  INSTALLMENT_SALES: "Instalment Sales",
};

export const types = [
  TYPE.OWN_STOVE,
  TYPE.OUTRIGHT_PURCHASE,
  TYPE.INSTALLMENT_PURCHASE,
  TYPE.OUTRIGHT_SALES,
  TYPE.INSTALLMENT_SALES,
];

export const weekDays = [
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
  { name: "Sunday", value: 7 },
];

export const deductionType = ["Percentage", "Amount"];

export const activeProductsValidationString = "Cylinder Meter Card";
