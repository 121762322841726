import React, { useCallback, useState } from "react";
import { Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { markMeterFaultyAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/es/typography/Title";
import FaultyReasons from "pages/meter-management/ready-for-repair/RepairMeter/Tabs/FaultyReasons";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  mandatory: {
    fontSize: 9,
  },
  tabs: {
    "&.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav": {
      padding: "0px!important",
      margin: 0,
    },
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "100%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
    marginTop: 5,
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
    marginRight: 16,
  },
  simCardContainer: {
    margin: "10px 0",
  },
  checkboxContainer: {
    width: "100%",
  },
  checkboxRow: {
    marginBottom: 10,
  },
  subItem: {
    marginLeft: 20,
    marginTop: 10,
  },
});

const MarkAsFaultyModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoader] = useState(false);
  const classes = useStyles();
  const [faultyReasons, setFaultyReasons] = useState([]);

  const handleSubmit = useCallback(() => {
    setLoader(true);
    markMeterFaultyAPI(activeItem.id, faultyReasons)
      .then(async () => {
        onClose();
        warnMessage(`Meter was marked as failed successfully.`);
        onUpdate();
        setLoader(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [faultyReasons]);

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Mark meter as Faulty
            <span>{`Meter (${activeItem?.serial})`}</span>
          </div>
        </div>
      }
      className={classes.modal}
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={loading}>
        <>
          <Title level={5}>Faulty Reasons</Title>
          <NGIForm name="faulty-reasons" form={form}>
            <FaultyReasons updateFaultyReason={setFaultyReasons} />
          </NGIForm>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton
              disabled={!faultyReasons?.length}
              onClick={handleSubmit}
              type="primary"
              text="Save"
              size="small"
            />
          </Space>
        </>
      </Spin>
    </Modal>
  );
};

export default MarkAsFaultyModal;
