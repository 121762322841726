import React, { useCallback, useEffect, useState } from "react";
import NGIForm from "components/Common/form/NGIForm";
import { Col, Form, Row, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGIInput from "components/Common/inputs/NGIInput";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import {
  createContractVersion,
  retrieveCustomerContractUploadUrl,
  retrieveCustomerVersionDownloadUrl,
  uploadPictureToS3Bucket,
} from "utils/api";
import moment from "moment";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { useHistory } from "react-router";
import classnames from "classnames";
import { base64toBinary, toBase64, uuidv4 } from "utils/helpers/functions";
import fileImport from "assets/images/svg/fileImport.svg";
import PreviewModal from "pages/settings/contract-versions/versionCreate/PreviewModal";
import deleteIcon from "assets/images/svg/closeIconWhite.svg";
import openIcon from "assets/images/svg/openWhite.svg";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";

const useStyles = makeStyles({
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
  buttonContainer: {
    justifyContent: "flex-end",
  },
  uploadButton: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    fontWeight: 600,
    color: "white",
    padding: 12,
    height: 50,
    borderRadius: 25,
    textTransform: "uppercase",
    fontSize: "11px!important",

    "&> img": {
      margin: 10,
    },

    "&:hover": {
      cursor: "pointer",
    },

    "&:active": {
      background: "#0085FF",
    },
  },
  previewBtn: { marginLeft: 20 },
  fileNameContainerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  selectedFileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const GeneralTab = ({ contractVersion, version }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [file, setFile] = useState();
  const [contractLoading, setContractLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const history = useHistory();

  const onSubmit = useCallback(
    values => {
      const normalizedData = {
        ...values,
        activeFrom: customDateFormatWithoutTime(values.activeFrom),
      };
      setLoader(true);
      const base64Data = base64toBinary(file.base64);
      retrieveCustomerContractUploadUrl("pdf").then(({ data: { result } }) =>
        uploadPictureToS3Bucket(result.url, base64Data, file.fileType).then(() =>
          createContractVersion({ ...normalizedData, fileName: result.fileName })
            .then(() => {
              successMessage("Contract version was created successfully");
              setLoader(false);
              history.push("/settings/contract-versions");
            })
            .catch(err => {
              defaultNgiErrorMethod(err);
              setLoader(false);
            })
        )
      );
    },
    [file]
  );

  const openPreview = useCallback(
    async e => {
      e.preventDefault();
      setContractLoading(true);
      setOpen(true);
      const res = await retrieveCustomerVersionDownloadUrl(version).catch(err => {
        defaultNgiErrorMethod(err);
      });
      setPreviewUrl(res.data.result.url);
      setContractLoading(false);
    },
    [version]
  );

  const openPreviewLocal = useCallback(
    e => {
      e.preventDefault();
      setOpen(true);
      setPreviewUrl(file.base64);
    },
    [file]
  );

  const readUploadFile = useCallback(async e => {
    const file = e.target.files[0];
    if (file) {
      const base64Data = await toBase64(file);
      setFile({
        _id: uuidv4(),
        name: file.name,
        size: (file.size / 1000).toFixed(2),
        base64: base64Data,
        fileType: file.type,
      });
      form.setFieldValue("fileName", file.name);
    }
  }, []);

  useEffect(() => {
    !!contractVersion && form.setFieldValue("activeFrom", moment(contractVersion.activeFrom));
    !!contractVersion && form.setFieldValue("fileName", contractVersion.fileName);
  }, [contractVersion]);

  const onBack = useCallback(() => history.push("/settings/contract-versions"), []);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <Spin spinning={(version && !contractVersion) || isLoading}>
      <NGIForm
        form={form}
        name="contract-version"
        layout="inline"
        onFinish={onSubmit}
        className={classes.formContainer}
      >
        <div className={classes.modalContent}>
          <Row>
            <Col span={12}>
              <Title level={5}>{version ? "Activation Date" : "Active From"}</Title>
              <NGIFormItem name="activeFrom" rules={[{ required: true, message: "Please enter value" }]}>
                <NGIDatePicker
                  disabled={version}
                  allowClear
                  disabledPasDates
                  showToday={false}
                  disableDates={current => moment() > current}
                />
              </NGIFormItem>
            </Col>
            <Col span={12}>
              <Title level={5}>Contract Template</Title>
              <NGIFormItem name="fileName" rules={[{ required: true, message: "Please enter value" }]}>
                {!version ? (
                  <>
                    {!file && (
                      <label
                        onChange={readUploadFile}
                        htmlFor="contractFile"
                        className={classnames(classes.uploadButton)}
                      >
                        Upload Contract
                        <input id="contractFile" accept=".pdf" type="file" hidden />
                      </label>
                    )}
                    {file && (
                      <div className={classes.selectedFileContainer}>
                        <NGIInput
                          placeholder={`${file.name}`}
                          disabled
                          disablePattern
                          suffix={<img src={fileImport} alt="" />}
                        />
                        <div className={classes.previewBtn}>
                          <ButtonGroupContainer className={classes.previewBtn}>
                            <ButtonGroup tooltipTitle="Preview" icon={openIcon} handleClick={openPreviewLocal} />
                            <ButtonGroup
                              tooltipTitle="Remove file"
                              icon={deleteIcon}
                              handleClick={() => {
                                setFile(undefined);
                              }}
                            />
                          </ButtonGroupContainer>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={classes.fileNameContainerStyle}>
                    <NGIInput
                      placeholder={form.getFieldValue("fileName")}
                      disabled={version}
                      disablePattern
                      suffix={<img src={fileImport} alt="" />}
                    />
                    <div className={classes.previewBtn}>
                      <ButtonGroupContainer>
                        <ButtonGroup tooltipTitle="Preview" icon={openIcon} handleClick={openPreview} />
                      </ButtonGroupContainer>
                    </div>
                  </div>
                )}
              </NGIFormItem>
            </Col>
          </Row>
        </div>
        {!version && (
          <Space className={classes.buttonContainer}>
            <CustomButton onClick={onBack} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
          </Space>
        )}
      </NGIForm>
      <PreviewModal
        title={"Contract Preview"}
        open={isOpen}
        previewUrl={previewUrl}
        onCancel={onClose}
        contractLoading={contractLoading}
      />
    </Spin>
  );
};

export default GeneralTab;
