import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { filterOptions } from "./variables";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const { Option } = Select;

const useStyles = makeStyles({
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    height: 60,
    overflow: "auto",

    "& .ant-select-selection-overflow": {
      flexWrap: "nowrap",
    },
  },
  value: {
    width: "auto !important",
  },
});

const PriceManagementFilter = ({ onFilter }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { filters } = useStoreFilters(FILTER_PAGE.PRICE);
  const [activeFilter, setFilter] = useState(null);

  const handleFilterChange = useCallback(
    value => {
      setFilter(value);
      form.setFieldsValue({
        type: value,
        value: null,
      });
    },
    [form]
  );

  useEffect(() => {
    const { pageSize, ...dataToSet } = filters;
    if (!!Object.keys(dataToSet)[0]) {
      setFilter(Object.keys(dataToSet)[0]);
      form.setFieldsValue({
        value: dataToSet[Object.keys(dataToSet)[0]],
        type: Object.keys(dataToSet)[0],
      });
    }
  }, [filters]);

  const onFinish = useCallback(
    values => {
      const normalizedFilters = !!filters?.pageSize ? { pageSize: filters.pageSize } : {};
      if (!(values.value && !values.type)) {
        onFilter({ ...normalizedFilters, [values.type]: values.value?.trim() });
      }
    },
    [activeFilter, filters]
  );

  return (
    <>
      <div className="custom-filter">
        <Form form={form} layout="inline" onFinish={onFinish}>
          <div className={classes.filtersContainer}>
            <Form.Item name="type">
              <Select
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                size="default"
                placeholder="Select type"
                allowClear
                onChange={handleFilterChange}
              >
                {filterOptions.length > 0 &&
                  filterOptions.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {!!activeFilter && <div className="filter-divider" />}
            {activeFilter === "name" && (
              <Form.Item name="value" className={classes.value}>
                <Input placeholder={"Enter value"} />
              </Form.Item>
            )}
          </div>

          <CustomButton type="primary" htmlType="submit" text="Filter" size="small" />
        </Form>
      </div>
    </>
  );
};

export default PriceManagementFilter;
