import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveTrips } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { tripListColumns } from "pages/stock-management/trips/TripListColumns";
import TripFilter from "pages/stock-management/trips/TripFilter";
import TripSearch from "pages/stock-management/trips/TripSearch";
import CustomLink from "components/Common/buttons/CustomLink";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  withCopyIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
const TripList = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const { filters, setFilters, getFilters } = useStoreFilters(FILTER_PAGE.TRIP);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch({ startLocation: AUTH_USER?.depotName });
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.TRUCK_LICENSE_PLATES));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Trips were not found");
    }
    setList(res?.data || null);

    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const { startLocation, endLocation } = getFilters();
    const filters = {
      startLocation: filterOptions?.startLocation || startLocation,
      endLocation: filterOptions?.endLocation || endLocation,
      ...filterOptions,
    };
    setFilters(filters);
    retrieveTrips(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList([]);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => tripListColumns(classes, history), [filters]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomLink
                roles={[USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.LOGISTICS, USER_ROLES.WAREHOUSE]}
                className="margin-right"
                to={"trips/new"}
              >
                <CustomButton icon={<i className="bx bx-plus-circle" />} type="primary" text="New Trip" />
              </CustomLink>
            </div>

            <TripFilter handleSearch={handleSearch} />
            <TripSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <>
              <NGITable
                filtersType={FILTER_PAGE.TRIP}
                rowKey={record => record.id}
                isLoading={isLoading}
                columns={columns}
                data={list}
                updateList={handleSearch}
              />
            </>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default TripList;
