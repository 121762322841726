import { textFormatter } from "components/Common/formatter";
import moment from "moment";
import { Tooltip } from "antd";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomLink from "components/Common/buttons/CustomLink";
import { USER_ROLES } from "utils/constants";

const useStyles = makeStyles({
  root: {
    color: "#000000",
    cursor: "pointer",
    "&:hover": {
      color: "#0085FF",
    },
  },
});

export const TABS = {
  PERSONAL_INFORMATION: "PERSONAL_INFORMATION",
  DEMOGRAPHIC_QUESTIONS: "DEMOGRAPHIC_QUESTIONS",
  TRANSACTION_BOOK: "TRANSACTION_BOOK",
  BALANCE_BOOK: "BALANCE_BOOK",
  CREDIT_BOOK: "CREDIT_BOOK",
  COOKING_SESSIONS: "COOKING_SESSIONS",
  BAD_COOKING_SESSIONS: "BAD_COOKING_SESSIONS",
  ASSETS: "ASSETS",
  TASKS_HISTORY: "TASKS_HISTORY",
  CONTRACT_HISTORY: "CONTRACT_HISTORY",
  LINKED_ACCOUNTS: "LINKED_ACCOUNTS",
};

export const getTabsButtons = status => [
  {
    title: "Personal Information",
    key: TABS.PERSONAL_INFORMATION,
  },
  {
    title: "Demographic Questions",
    key: TABS.DEMOGRAPHIC_QUESTIONS,
  },
  {
    title: "Transaction Book",
    key: TABS.TRANSACTION_BOOK,
    hideRoles: [USER_ROLES.INTERNAL_AUDITOR],
  },
  {
    title: "Stove Book",
    key: TABS.BALANCE_BOOK,
    hideRoles: [USER_ROLES.INTERNAL_AUDITOR],
  },
  {
    title: "Credit Book",
    key: TABS.CREDIT_BOOK,
  },
  {
    title: "Cooking Sessions",
    key: TABS.COOKING_SESSIONS,
    hideRoles: [USER_ROLES.INTERNAL_AUDITOR],
  },
  {
    title: "Bad Cooking Sessions",
    key: TABS.BAD_COOKING_SESSIONS,
    hideRoles: [USER_ROLES.INTERNAL_AUDITOR],
  },
  ...(!!status && status !== "Repossessed"
    ? [
        {
          title: `${status === "Suspended" ? "Missing Assets" : "Assets"}`,
          key: TABS.ASSETS,
          hideRoles: [USER_ROLES.INTERNAL_AUDITOR],
        },
      ]
    : []),
  {
    title: "Task History",
    key: TABS.TASKS_HISTORY,
    hideRoles: [USER_ROLES.INTERNAL_AUDITOR],
  },
  {
    title: "Contracts",
    key: TABS.CONTRACT_HISTORY,
    hideRoles: [
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
      USER_ROLES.METER_ENGINEER_LEADERSHIP,
      USER_ROLES.METER_ENGINEER,
    ],
  },
  {
    title: "Linked Accounts",
    key: TABS.LINKED_ACCOUNTS,
  },
];

export const customerTransactionsColumns = ({ onShowModal }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  return [
    {
      title: "Payee Phone Number",
      dataIndex: "payeePhoneNumber",
      key: "payeePhoneNumber",
      render: (cellContent, item) => (
        <Tooltip placement="bottom" title={`Transaction details`}>
          <div className={classes.root} onClick={() => onShowModal(item.transactionId)}>
            {textFormatter(cellContent)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Transaction Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      render: value => textFormatter(value),
    },
    {
      title: "Gas Amount",
      dataIndex: "gasAmount",
      key: "gasAmount",
      render: value => textFormatter(value),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      render: value => textFormatter(value),
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionMode",
      key: "transactionMode",
      render: value => textFormatter(value),
    },
    {
      title: "Transaction Status",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: value => textFormatter(value),
    },

    {
      title: "Transaction Date",
      dataIndex: "transactionDateTime",
      key: "transactionDateTime",
      render: value => (value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : textFormatter(value)),
    },
  ];
};

export const customerBalanceBookColumns = ({ onShowModal }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  return [
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (cellContent, item) => (
        <Tooltip placement="bottom" title={`Transaction details`}>
          <div className={classes.root} onClick={() => onShowModal(item.transactionId)}>
            {textFormatter(cellContent)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Transaction Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      render: value => textFormatter(value),
    },
    {
      title: "Stove amount",
      dataIndex: "balanceAmount",
      key: "balanceAmount",
      render: value => textFormatter(value),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      render: value => textFormatter(value),
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionMode",
      key: "transactionMode",
      render: value => textFormatter(value),
    },
    {
      title: "Transaction Status",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: value => textFormatter(value),
    },

    {
      title: "Transaction Date",
      dataIndex: "transactionDateTime",
      key: "transactionDateTime",
      render: value => (value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : textFormatter(value)),
    },
  ];
};

export const customerCreditBookColumns = ({ onShowModal }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  return [
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (cellContent, item) => (
        <Tooltip placement="bottom" title={`Transaction details`}>
          <div className={classes.root} onClick={() => onShowModal(item.transactionId)}>
            {textFormatter(cellContent)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Transaction Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      render: value => textFormatter(value),
    },
    {
      title: "Credit Amount",
      dataIndex: "campaignCreditAmount",
      key: "campaignCreditAmount",
      render: value => textFormatter(value),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      render: value => textFormatter(value),
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionMode",
      key: "transactionMode",
      render: value => textFormatter(value),
    },
    {
      title: "Transaction Status",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: value => textFormatter(value),
    },
    {
      title: "Campaign ID",
      dataIndex: "campaignId",
      key: "campaignId",
      render: value => textFormatter(value),
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDateTime",
      key: "transactionDateTime",
      render: value => (value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : textFormatter(value)),
    },
  ];
};

export const customerCookingSessionsColumns = () => [
  {
    title: "Session Start Time",
    render: row =>
      row?.cookingSession?.startTime
        ? moment(row?.cookingSession?.startTime).format("YYYY-MM-DD HH:mm:ss")
        : textFormatter(row?.cookingSession?.startTime),
  },
  {
    title: "Session End Time",
    render: row =>
      row?.cookingSession?.endTime
        ? moment(row?.cookingSession?.endTime).format("YYYY-MM-DD HH:mm:ss")
        : textFormatter(row?.cookingSession?.endTime),
  },
  {
    title: "Time(m)",
    render: row => ((row?.cookingSession?.endTime - row?.cookingSession?.startTime) / 60000).toFixed(),
  },
  {
    title: "Volume Used (g)",
    render: row =>
      textFormatter((row?.cookingSession?.endCumulativeMass - row?.cookingSession?.startCumulativeMass).toFixed(2)),
  },
  {
    title: "Gas Remaining",
    render: row => textFormatter(row?.cookingSession?.gasRemaining?.toFixed(2)),
  },
  {
    title: "Session Start Credit",
    render: row => textFormatter(row?.cookingSession?.startCredit),
  },
  {
    title: "Session End Credit",
    render: row => textFormatter(row?.cookingSession?.endCredit),
  },
  {
    title: "Credit Used",
    render: row => row?.cookingSession?.startCredit - row?.cookingSession?.endCredit,
  },
  {
    title: "Session End Type",
    render: row => textFormatter(row?.cookingSession?.endReason),
  },
];

export const customerAssetsColumns = () => [
  {
    title: "Product Type",
    render: row => textFormatter(row?.type),
  },
  {
    title: "Barcode",
    render: row =>
      row?.barcode !== "UNKNOWN" ? (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${row?.serialNumber}?t=serialNumber`}>
          {textFormatter(row?.barcode)}
        </CustomLink>
      ) : (
        textFormatter(row?.barcode)
      ),
  },
  {
    title: "State",
    render: row => textFormatter(row?.state),
  },
  {
    title: "Date",
    render: row => (row?.inUseAt ? moment(row?.inUseAt).format("YYYY-MM-DD HH:mm:ss") : textFormatter(row?.inUseAt)),
  },
];

export const tasksHistoryColumns = customerId => [
  {
    title: "Task Type",
    render: row => (
      <CustomLink
        to={`/operations/task/${row.id}?backUrl=/customers/customer/${customerId}?activeTab=${TABS.TASKS_HISTORY}`}
      >
        {row.category}
      </CustomLink>
    ),
  },
  {
    title: "Status",
    render: row => textFormatter(row?.status),
  },
  {
    title: "Assigned To",
    render: row => (
      <CustomLink underlined target="_blank" to={`/user/${row.assignedTo}`}>
        {row.assignedTo}
      </CustomLink>
    ),
  },
  {
    title: "Comments",
    render: row => textFormatter(row?.comments?.replace(`"\\n----------\\n"`, "\n")),
  },
  {
    title: "Source",
    dataIndex: "source",
    render: textFormatter,
  },
  {
    title: "Date Created",
    render: row =>
      row?.createdAt ? moment(row?.createdAt).format("YYYY-MM-DD HH:mm:ss") : textFormatter(row?.createdAt),
  },
  {
    title: "Date Updated",
    render: row =>
      row?.updatedAt ? moment(row?.updatedAt).format("YYYY-MM-DD HH:mm:ss") : textFormatter(row?.updatedAt),
  },
];

export const linkedAccountsColumns = () => [
  {
    title: "Customer ID",
    render: row => (
      <CustomLink to={`/customers/customer/${row.id}`} target={"_blank"}>
        {row.id}
      </CustomLink>
    ),
  },
  {
    title: "Full Name",
    render: row => `${row.firstName} ${row.lastName}`,
  },
  {
    title: "Phone Number",
    render: row => textFormatter(row?.phone),
  },
  {
    title: "Category",
    dataIndex: "type",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "Registered By",
    dataIndex: "createdBy",
    render: textFormatter,
  },
];

export const customerBadCookingSessionsColumns = () => [
  {
    title: "Meter",
    render: row => textFormatter(row.meterBarcode),
  },
  {
    title: "Unit Price",
    render: row => textFormatter(row?.cookingSession?.unitPrice),
  },
  {
    title: "Credit",
    render: row => (row?.cookingSession?.startCredit - row?.cookingSession?.endCredit).toFixed(1),
  },
  {
    title: "Volume Used (g)",
    render: row =>
      textFormatter((row?.cookingSession?.endCumulativeMass - row?.cookingSession?.startCumulativeMass).toFixed(2)),
  },
  {
    title: "Session Start Time",
    render: row =>
      row?.cookingSession?.startTime
        ? moment(row?.cookingSession?.startTime).format("YYYY-MM-DD HH:mm:ss")
        : textFormatter(row?.cookingSession?.startTime),
  },
  {
    title: "Session End Time",
    render: row =>
      row?.cookingSession?.endTime
        ? moment(row?.cookingSession?.endTime).format("YYYY-MM-DD HH:mm:ss")
        : textFormatter(row?.cookingSession?.endTime),
  },
];

export const STATUSES = {
  QUALIFIED: "Qualified",
  REPOSSESSED: "Repossessed",
  ACTIVE: "Active",
  DECLINED: "Declined",
  SUSPENDED: "Suspended",
  PENDING: "Pending",
  INCOMPLETED: "Incompleted",
  REJECTED: "Rejected",
};
