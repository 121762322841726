export const GET_ALERTS = "GET_ALERTS";
export const GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS";
export const GET_ALERTS_FAIL = "GET_ALERTS_FAIL";

export const GET_CUM_REPORTS = "GET_CUM_REPORTS";
export const GET_CUM_REPORTS_SUCCESS = "GET_CUM_REPORTS_SUCCESS";
export const GET_CUM_REPORTS_FAIL = "GET_CUM_REPORTS_FAIL";

export const RESET_CSRP = "RESET_CSRP";
export const GET_CSRP = "GET_CSRP";
export const GET_CSRP_SUCCESS = "GET_CSRP_SUCCESS";
export const GET_CSRP_FAIL = "GET_CSRP_FAIL";

export const GET_METER_STATUS = "GET_METER_STATUS";
export const GET_METER_STATUS_SUCCESS = "GET_METER_STATUS_SUCCESS";
export const GET_METER_STATUS_FAIL = "GET_METER_STATUS_FAIL";

export const GET_META = "GET_META";

export const GET_METER_TEST_REPORT = "GET_METER_TEST_REPORT";
export const GET_METER_TEST_REPORT_SUCCESS = "GET_METER_TEST_REPORT_SUCCESS";
export const GET_METER_TEST_REPORT_FAIL = "GET_METER_TEST_REPORT_FAIL";
export const GET_METER_TEST_REPORT_META = "GET_METER_TEST_REPORT_META";
