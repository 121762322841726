import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Form, Row, Spin } from "antd";
import classnames from "classnames";
import Title from "antd/es/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import NGIInput from "components/Common/inputs/NGIInput";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import CustomButton from "components/Common/buttons/CustomButton";
import {
  createCampaign,
  getCalculatedStatus,
  startCustomersCalculation,
  stopCampaign,
  updateCampaign,
} from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage, warnMessage } from "components/Common/responses/message";
import { useHistory } from "react-router";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import TitledContainer from "components/Common/components/TitledContainer";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import { AUTH_USER, USER_CURRENCY, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { weekDays } from "pages/packages/all-packages/newPackage/constants";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { VALIDATION_RULES } from "pages/settings/campaign-management/campaignDetails/constants";
import { getIsValidForm } from "pages/settings/campaign-management/campaignDetails/utils";
import { debounce } from "lodash";
import * as xlsx from "xlsx";
import moment from "moment";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { NUMBER_PATTERN } from "pages/meter-management/configuration/meters/variables";

const useStyles = makeStyles({
  root: {},
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 10,
    },
  },
  questionsContainer: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  addNew: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,
    marginTop: 15,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  rowContainer: {
    borderRadius: 5,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgba(250, 250, 250, 1)",
    marginBottom: 5,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  value: {
    fontSize: 13,
    color: "#444444",
    fontWeight: 600,
    marginRight: 5,
    padding: 15,
    whiteSpace: "nowrap",
    width: "15%",

    "&>span": {
      color: "#8C8C8C",
      fontWeight: 300,
    },
  },
  questionContainer: {
    width: "50%",
  },
  questionOptionsContainer: {},
  questionTitle: {
    width: "20%",
  },
  actionContainer: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 80,

    "& img": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  deleteBtn: {
    width: 18,
  },
  overflowText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  title: {
    fontSize: "13px!important",
  },
  resultsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  resultBox: {
    backgroundColor: "rgba(220, 238, 255, 1)",
    padding: 20,
    borderRadius: 20,
    marginBottom: 20,
    width: "49%",
    display: "flex",
    justifyContent: "space-between",
  },
  uploadButton: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    fontWeight: 600,
    color: "white",
    padding: 12,
    height: 40,
    borderRadius: 20,
    textTransform: "uppercase",
    fontSize: "11px!important",

    "&> img": {
      margin: 10,
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
});

const EditCampaignTab = ({ campaign, setIsLoading }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const history = useHistory();
  const [caseReason, setCaseReason] = useState("");
  const [caseType, setCaseType] = useState("");
  const [deductionType, setDeductionType] = useState("");
  const [deductionPeriod, setDeductionPeriod] = useState("");
  const [recoveryType, setRecoveryType] = useState("");
  const [defineAmount, setDefineAmount] = useState("");
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isStopConfirmationVisible, setIsStopConfirmationVisible] = useState(false);
  const [calculationId, setCalculationId] = useState(null);
  const [calculationData, setCalculationData] = useState(null);
  const {
    list: { depot, campaignType, paymentSettings },
  } = useSelector(DictionariesSelector);
  const userSettings = useMemo(() => {
    const tenantTemplate = paymentSettings?.find(item => item.tenant === AUTH_USER.tenant);
    const defaultTemplate = paymentSettings?.find(item => item.tenant === "default");
    return tenantTemplate ? tenantTemplate : defaultTemplate;
  }, []);

  useEffect(() => {
    if (campaign) {
      form.setFieldsValue({
        ...campaign,
        ...campaign?.queryData,
        ...campaign?.campaignRecovery,
        creationReason: campaign?.creationReason === "Payment Provider Down" ? campaign?.creationReason : "Other",
        reason: campaign?.creationReason !== "Payment Provider Down" ? campaign?.creationReason : "",
        users: campaign?.queryData?.values,
        startDateTime: moment(campaign?.startDateTime),
        endDateTime: moment(campaign?.endDateTime),
      });
      setCaseType(campaign.type);
      setCaseReason(campaign?.creationReason === "Payment Provider Down" ? campaign?.creationReason : "Other");
      setRecoveryType(campaign?.campaignRecovery?.recoveryType);
      setDeductionPeriod(campaign?.campaignRecovery?.deductionPeriod);
      setDeductionType(campaign?.campaignRecovery?.deductionType);
    } else {
      form.setFieldValue("smsTemplate", userSettings?.smsTemplate?.TemplateCampaignStart);
    }
  }, [campaign, userSettings]);

  const onSave = useCallback(
    values => {
      const {
        name,
        creationReason,
        reason,
        startDateTime,
        endDateTime,
        smsTemplate,
        recoveryType,
        deductionPeriod,
        deductionDay,
        deductionType,
        deductionValue,
      } = {
        ...values,
      };
      const { data } = getIsValidForm(values, caseType);
      closeConfirmationModal();
      setIsLoading(true);
      if (!campaign) {
        createCampaign(caseType, {
          name: name?.trim(),
          creationReason: creationReason === "Other" ? reason : creationReason,
          startDateTime: new Date(startDateTime).getTime(),
          endDateTime: new Date(endDateTime).getTime(),
          smsTemplate: smsTemplate?.trim(),
          queryData: {
            ...data,
          },
          campaignRecovery: {
            recoveryType,
            deductionPeriod,
            deductionDay: parseInt(deductionDay),
            deductionType,
            deductionValue: parseInt(deductionValue),
          },
        })
          .then(() => {
            successMessage(`Campaign was created successfully`);
            setIsLoading(false);
            history.push("/settings/campaigns");
          })
          .catch(err => {
            setIsLoading(false);
            defaultNgiErrorMethod(err);
          });
      } else {
        updateCampaign(campaign.id, {
          name: name?.trim(),
          creationReason: creationReason === "Other" ? reason : creationReason,
          startDateTime: new Date(startDateTime).getTime(),
          endDateTime: new Date(endDateTime).getTime(),
          smsTemplate: smsTemplate?.trim(),
          campaignRecovery: {
            recoveryType,
            deductionPeriod,
            deductionDay,
            deductionType,
            deductionValue,
          },
        })
          .then(() => {
            successMessage(`Campaign was updated successfully`);
            setIsLoading(false);
            history.push("/settings/campaigns");
          })
          .catch(err => {
            setIsLoading(false);
            defaultNgiErrorMethod(err);
          });
      }
    },
    [caseType]
  );

  const readUploadFile = useCallback(
    e => {
      const file = e.target.files[0];
      if (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = e => {
            const data = e.target.result;
            const workbook = xlsx.read(data);
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
            const formattedResult = json.map(item => {
              if (item.customerId) return item.customerId ? item.customerId : null;
              return null;
            });
            const noError = formattedResult.every(item => item || null);
            !noError && errorMessage("Please upload the correct ‘*.csv’ file with customerId fields");

            form.setFieldValue("users", formattedResult);
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
          calculateData();
        });
      }
    },
    [form]
  );

  const onReasonChange = useCallback(
    value => {
      setCaseReason(value);
      if (value !== "Other") {
        form.setFieldValue("reason", "");
      }
    },
    [form]
  );

  const onCaseTypeChange = useCallback(
    value => {
      setCaseType(value);
      form.setFieldsValue({
        consumptionFrom: "",
        consumptionTo: "",
        users: "",
        depots: [],
      });
    },
    [form]
  );

  const calculateData = useCallback(() => {
    const values = form.getFieldsValue();
    const { data, valid } = getIsValidForm(values, caseType);
    if (valid) {
      setIsLoading(true);
      startCustomersCalculation(caseType, data)
        .then(({ data }) => {
          setIsLoading(false);
          setCalculationId(data.result.id);
          setCalculationData(null);
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    }
  }, [form, caseType]);
  const debouncedChangeHandler = useMemo(() => debounce(calculateData, 3000), [calculateData]);

  const onDeductionTypeChange = useCallback(value => setDeductionType(value), []);
  const onDeductionPeriodChange = useCallback(
    value => {
      setDeductionPeriod(value);
      form.setFieldValue("deductionDay", null);
    },
    [form]
  );
  const onRecoveryTypeChange = useCallback(
    value => {
      setRecoveryType(value);
      if (value === "Immediately") {
        form.setFieldsValue({
          deductionPeriod: null,
          deductionDay: null,
          deductionType: null,
          deductionValue: null,
        });
      }
    },
    [form]
  );
  const onDefineAmountChange = useCallback(
    value => {
      setDefineAmount(value);
      form.setFieldsValue({
        amount: null,
        multiplier: null,
      });
      debouncedChangeHandler();
    },
    [debouncedChangeHandler]
  );

  const getCalculatedData = useCallback(id => {
    getCalculatedStatus(id)
      .then(({ data }) => {
        setCalculationData(data.result);
        if (!data.result.eligibleCustomersCount) {
          warnMessage(
            "No possibility to calculate the total user’s amount for chosen case type. Please choose another case type.",
            3
          );
        }
        setCalculationId(null);
      })
      .catch(err => {
        if (err?.response?.data?.errors[0]?.code !== "NOT_FOUND") {
          setCalculationId(null);
          defaultNgiErrorMethod(err);
        }
      });
  }, []);

  useEffect(() => {
    let timeoutID;
    if (calculationId) {
      timeoutID = setInterval(() => {
        getCalculatedData(calculationId);
      }, 15000);
    } else {
      clearTimeout(timeoutID);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [calculationId]);

  const onStopCampaign = useCallback(
    reason => {
      setIsStopConfirmationVisible(true);
      setIsLoading(true);
      stopCampaign(campaign.id, reason)
        .then(() => {
          successMessage("Campaign has been stopped successfully");
          setIsLoading(false);
          history.push("/settings/campaigns");
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [campaign]
  );

  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationVisible(false);
  }, []);

  const onSubmit = useCallback(() => {
    setIsConfirmationVisible(false);
    form.submit();
  }, [form]);

  const openConfirmationModal = useCallback(e => {
    e.preventDefault();
    setIsConfirmationVisible(true);
  }, []);

  const openStopConfirmationModal = useCallback(e => {
    e.preventDefault();
    setIsStopConfirmationVisible(true);
  }, []);

  const closeStopConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsStopConfirmationVisible(false);
  }, []);

  const isDisabled = useMemo(
    () =>
      userHasOneOfRoles([USER_ROLES.CUSTOMER_CARE_LEADERSHIP]) ||
      campaign?.status === "Stopped" ||
      campaign?.status === "Inactive",
    [campaign]
  );

  return (
    <div className={classes.root}>
      {!campaign && (
        <div className={classes.resultsContainer}>
          <div className={classes.resultBox}>
            <span>Total Users</span>
            <b>{calculationId ? <Spin spinning={true} /> : calculationData?.eligibleCustomersCount || "-"}</b>
          </div>
          <div className={classes.resultBox}>
            <span>Total Amount</span>
            <span>
              <b>{calculationId ? <Spin spinning={true} /> : calculationData?.totalCreditAmount || "-"}</b>{" "}
              {USER_CURRENCY}
            </span>
          </div>
        </div>
      )}

      <Form disabled={isDisabled} form={form} name="campaign-edit" onFinish={onSave}>
        <TitledContainer title="Campaign Details">
          <Row gutter={[24, 8]}>
            <Col lg={6}>
              <Title className={classes.title}>Campaign Name</Title>
              <Form.Item
                name="name"
                rules={[
                  {
                    message: "Please enter value",
                    validator: (_, value) => {
                      if (!value?.trim()) {
                        return Promise.reject("Please enter value");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGIInput white placeholder="Enter campaign name" />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Title className={classes.title}>Case Reason</Title>
              <Form.Item name="creationReason" rules={VALIDATION_RULES}>
                <NGISelect showSearch onChange={onReasonChange} placeholder="Case Reason">
                  {["Payment Provider Down", "Other"].map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Title className={classes.title}>Campaign Start</Title>
              <Form.Item
                name="startDateTime"
                rules={[
                  ...VALIDATION_RULES,
                  {
                    message: "Should be more than 15 minutes",
                    validator: (_, value) => {
                      if ((new Date(value).getTime() - new Date().getTime()) / 1000 / 60 < 15) {
                        return Promise.reject("Should be more than 15 minutes");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGIDatePicker
                  dateFormat={"YYYY-MM-DD HH:mm"}
                  showTime={{ format: "HH:mm" }}
                  placeholder="Campaign Start"
                  disabledPasDates
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Title className={classes.title}>Campaign End</Title>
              <Form.Item name="endDateTime" rules={VALIDATION_RULES}>
                <NGIDatePicker
                  dateFormat={"YYYY-MM-DD HH:mm"}
                  showTime={{ format: "HH:mm" }}
                  placeholder="Campaign End"
                  disableDates={current => form.getFieldValue("startDateTime") > current}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          {caseReason === "Other" && (
            <Row gutter={[24, 8]}>
              <Col lg={6}>
                <Title className={classes.title}>Reason</Title>
                <Form.Item name="reason" rules={VALIDATION_RULES}>
                  <NGIInput white placeholder="Enter reason" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </TitledContainer>

        {!campaign && (
          <TitledContainer title="Campaign Type & Amount">
            <Row gutter={[24, 8]}>
              <Col lg={6}>
                <Title className={classes.title}>Case Type</Title>
                <Form.Item name="type" rules={VALIDATION_RULES}>
                  <NGISelect showSearch disabled={calculationId} onChange={onCaseTypeChange} placeholder="Case Type">
                    {campaignType?.map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                  </NGISelect>
                </Form.Item>
              </Col>

              {caseType === "Consumption" && (
                <>
                  <Col lg={9}>
                    <Title className={classes.title}>From</Title>
                    <Form.Item name="consumptionFrom" rules={VALIDATION_RULES}>
                      <NGIInput
                        disabled={calculationId}
                        onChange={debouncedChangeHandler}
                        white
                        placeholder="Enter value"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={9}>
                    <Title className={classes.title}>To</Title>
                    <Form.Item name="consumptionTo" rules={VALIDATION_RULES}>
                      <NGIInput
                        disabled={calculationId}
                        onChange={debouncedChangeHandler}
                        white
                        placeholder="Enter value"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {caseType === "CustomerID" && (
                <Col lg={18}>
                  <Title className={classes.title}>Individual users</Title>
                  <Form.Item name="users" rules={VALIDATION_RULES}>
                    <NGIInput
                      disabled={calculationId}
                      onChange={debouncedChangeHandler}
                      white
                      placeholder="Enter value"
                    />
                  </Form.Item>
                </Col>
              )}
              {caseType === "CSV" && (
                <>
                  <Col>
                    <Title className={classes.title}>Upload users</Title>
                    <Form.Item name="users" rules={VALIDATION_RULES}>
                      <label
                        onChange={readUploadFile}
                        htmlFor="customersCsv"
                        className={classnames(classes.uploadButton)}
                      >
                        <input id="customersCsv" accept=".csv" type="file" hidden />
                        Upload csv
                      </label>
                    </Form.Item>
                  </Col>
                </>
              )}
              {caseType === "Depots" && (
                <Col lg={18}>
                  <Title className={classes.title}>Depots</Title>
                  <Form.Item name="depots" rules={VALIDATION_RULES}>
                    <NGISelect
                      showSearch
                      style={{ width: "100%" }}
                      mode="multiple"
                      tokenSeparators={[" ", ","]}
                      size="default"
                      onChange={debouncedChangeHandler}
                      placeholder="Select depots"
                      disabled={calculationId}
                    >
                      {depot[USER_TENANT_NAME]?.length > 0 &&
                        depot[USER_TENANT_NAME]?.sort()?.map(
                          item => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ),
                          this
                        )}
                    </NGISelect>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[24, 8]}>
              <Col lg={6}>
                <Title className={classes.title}>Define Amount</Title>
                <Form.Item name="amountType">
                  <NGISelect
                    showSearch
                    disabled={calculationId}
                    onChange={onDefineAmountChange}
                    placeholder="Define Amount"
                  >
                    {["Automatic Personal Amount", "Fixed Amount"].map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                  </NGISelect>
                </Form.Item>
              </Col>
              {defineAmount === "Fixed Amount" && (
                <Col lg={6}>
                  <Title className={classes.title}>Amount</Title>
                  <Form.Item name="amount" rules={VALIDATION_RULES}>
                    <NGIInput
                      disabled={calculationId}
                      onChange={debouncedChangeHandler}
                      white
                      placeholder="Enter Amount"
                    />
                  </Form.Item>
                </Col>
              )}
              {defineAmount === "Automatic Personal Amount" && (
                <Col lg={6}>
                  <Title className={classes.title}>Multiplier</Title>
                  <Form.Item
                    name="multiplier"
                    rules={[
                      { pattern: NUMBER_PATTERN, message: "Should be a number" },
                      {
                        message: `Value should be between ${userSettings?.campaignMultiplier?.minValue} and ${userSettings?.campaignMultiplier.maxValue}`,
                        validator: function (_, value) {
                          const numberValue = parseFloat(value);
                          if (
                            numberValue >= userSettings?.campaignMultiplier?.minValue &&
                            numberValue <= userSettings?.campaignMultiplier.maxValue
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            `Value should be between ${userSettings?.campaignMultiplier?.minValue} and ${userSettings?.campaignMultiplier.maxValue}`
                          );
                        },
                      },
                    ]}
                  >
                    <NGIInput
                      disabled={calculationId}
                      onChange={debouncedChangeHandler}
                      white
                      placeholder="Enter Multiplier"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[24, 8]}>
              <Col lg={6}>
                <Title className={classes.title}>Meter Balance From</Title>
                <Form.Item name="meterBalanceMin">
                  <NGIInput
                    disabled={calculationId}
                    onChange={debouncedChangeHandler}
                    white
                    placeholder="Enter value"
                  />
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Title className={classes.title}>Meter Balance To</Title>
                <Form.Item name="meterBalanceMax">
                  <NGIInput
                    disabled={calculationId}
                    onChange={debouncedChangeHandler}
                    white
                    placeholder="Enter value"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 8]}>
              <Col lg={6}>
                <Title className={classes.title}>Cooked Days From</Title>
                <Form.Item name="cookedDaysMin">
                  <NGIInput
                    disabled={calculationId}
                    onChange={debouncedChangeHandler}
                    white
                    placeholder="Enter value"
                  />
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Title className={classes.title}>Cooked Days To</Title>
                <Form.Item name="cookedDaysMax">
                  <NGIInput
                    disabled={calculationId}
                    onChange={debouncedChangeHandler}
                    white
                    placeholder="Enter value"
                  />
                </Form.Item>
              </Col>
            </Row>
          </TitledContainer>
        )}

        <TitledContainer title="Campaign Recovery">
          <Row gutter={[24, 8]}>
            <Col lg={4}>
              <Title className={classes.title}>Recovery Type</Title>
              <Form.Item name="recoveryType">
                <NGISelect showSearch onChange={onRecoveryTypeChange} placeholder="Recovery Type">
                  {["Immediately", "Gradually"].map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </Form.Item>
            </Col>
            {recoveryType === "Gradually" && (
              <>
                <Col lg={5}>
                  <Title className={classes.title}>Deduction Period</Title>
                  <Form.Item name="deductionPeriod">
                    <NGISelect showSearch onChange={onDeductionPeriodChange} placeholder="Deduction Period">
                      {["Upon Payment", "Monthly", "Weekly"].map(item => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </NGISelect>
                  </Form.Item>
                </Col>
                {(deductionPeriod === "Weekly" || deductionPeriod === "Monthly") && (
                  <Col lg={5}>
                    <Title className={classes.title}>Deduction Day</Title>
                    <Form.Item
                      name="deductionDay"
                      rules={[
                        ...VALIDATION_RULES,
                        ...(deductionPeriod !== "Weekly"
                          ? [
                              {
                                message: "Value should be between 1 and 28",
                                validator: function (_, value) {
                                  const numberValue = parseInt(value);
                                  if (numberValue >= 1 && numberValue <= 28) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject("Value should be between 1 and 28");
                                },
                              },
                            ]
                          : []),
                      ]}
                    >
                      {deductionPeriod === "Weekly" ? (
                        <NGISelect showSearch placeholder="Deduction Day">
                          {weekDays.map(item => (
                            <Option value={item.value} key={item.name}>
                              {item.name}
                            </Option>
                          ))}
                        </NGISelect>
                      ) : (
                        <NGIInput
                          type={"number"}
                          min={0}
                          max={28}
                          placeholder={"Enter the ordinal numeral of the month's day (1-28)"}
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}

                <Col lg={5}>
                  <Title className={classes.title}>Deduction Type</Title>
                  <Form.Item name="deductionType">
                    <NGISelect showSearch onChange={onDeductionTypeChange} placeholder="Deduction Type">
                      {["Percentage", "Amount"].map(item => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </NGISelect>
                  </Form.Item>
                </Col>
                <Col lg={5}>
                  <Title className={classes.title}>{deductionType || "Amount"}</Title>
                  <Form.Item
                    name="deductionValue"
                    rules={
                      deductionPeriod === "Weekly" || deductionPeriod === "Monthly"
                        ? [
                            ...VALIDATION_RULES,
                            ...(deductionType === "Percentage"
                              ? [
                                  {
                                    message: "Value should be between 1 and 100",
                                    validator: function (_, value) {
                                      const numberValue = parseInt(value);
                                      if (numberValue >= 1 && numberValue <= 100) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject("Value should be between 1 and 100");
                                    },
                                  },
                                ]
                              : []),
                          ]
                        : [
                            {
                              required: false,
                            },
                            ...(deductionType === "Percentage"
                              ? [
                                  {
                                    message: "Value should be between 1 and 100",
                                    validator: function (_, value) {
                                      const numberValue = parseInt(value);
                                      if (numberValue >= 1 && numberValue <= 100) {
                                        return Promise.resolve();
                                      }
                                      if (!numberValue) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject("Value should be between 1 and 100");
                                    },
                                  },
                                ]
                              : []),
                          ]
                    }
                  >
                    <NGIInput white placeholder="Enter deduction value" />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </TitledContainer>

        <TitledContainer title="Campaign SMS Message">
          <Row gutter={[24, 8]}>
            <Col lg={24}>
              <Title className={classes.title}>Campaign Message (max. 165 Characters)</Title>
              <Form.Item
                name="smsTemplate"
                rules={[
                  ...VALIDATION_RULES,
                  {
                    message: "The '{creditAmount}' and '1 or dial *479#' placeholders should be present in template",
                    validator: (_, value) => {
                      if (!value.includes("{creditAmount}") || !value.includes("1 or dial *479#")) {
                        return Promise.reject(
                          "The '{creditAmount}' and '1 or dial *479#' placeholders should be present in template"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGITextArea
                  disabled={isDisabled}
                  white
                  maxlength={165}
                  rows={5}
                  placeholder="Please add your message"
                />
              </Form.Item>
            </Col>
          </Row>
        </TitledContainer>

        <div className={classes.buttonsContainer}>
          {!campaign && (
            <CustomButton
              disabled={calculationId || !calculationData?.eligibleCustomersCount}
              onClick={openConfirmationModal}
              size="small"
              text="Create"
            />
          )}

          {campaign?.status === "New" && (
            <CustomButton
              roles={[USER_ROLES.SYSTEM_FULL_ADMINS]}
              onClick={openConfirmationModal}
              size="small"
              text="Update"
            />
          )}

          {campaign && (campaign.status === "New" || campaign.status === "Active") && (
            <CustomButton
              roles={[USER_ROLES.SYSTEM_FULL_ADMINS]}
              onClick={openStopConfirmationModal}
              size="small"
              type="error"
              text="STOP CAMPAIGN"
            />
          )}
        </div>
      </Form>

      {isConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          description={`Are you sure you want to ${!campaign ? "create a new" : "change the"} campaign?`}
        />
      )}
      {isStopConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeStopConfirmationModal}
          onConfirm={onStopCampaign}
          withComment
          isCommentRequired
          commentLength={100}
          description="Are you sure you want to stop campaign?"
        />
      )}
    </div>
  );
};

export default EditCampaignTab;
