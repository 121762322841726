import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import calendarIcon from "../../../assets/images/svg/calendarMinus.svg";
import depotIcon from "../../../assets/images/svg/depot.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import { getFormattedDataByName } from "utils/helpers/functions";
import { useHistory, useLocation } from "react-router";
import { USER_ROLES } from "utils/constants";
import CustomButton from "components/Common/buttons/CustomButton";
import { Form, Modal, Select, Space } from "antd";
import NGIForm from "components/Common/form/NGIForm";
import { Col, Row } from "reactstrap";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGISelect from "components/Common/inputs/NGISelect";
import { assignBulkTaskApi } from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import CancelTaskModal from "pages/operations/tasks/CancelTaskModal";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import cancelIcon from "assets/images/svg/cancel-white.svg";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import workerIcon from "assets/images/svg/worker.svg";

const { Option } = Select;

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 10,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
  button: {
    marginRight: 10,
  },
});
const allowedStatuses = ["Assigned", "Incompletable"];

const Header = ({ task, setLoader, agentList, updateTask }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const history = useHistory();
  const { category, status, depot, createdAt, source } = task;
  const searchParams = useLocation().search;
  const backUrl = new URLSearchParams(searchParams).get("backUrl");
  const backButtonUrl = useMemo(() => (backUrl ? backUrl : "/operations/tasks/list"), [backUrl]);
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleCancel = useCallback(() => setReassignModalOpen(false), []);
  const showModal = useCallback(() => setReassignModalOpen(true), []);
  const handleOk = useCallback(
    async values => {
      const { email, name, depot } = JSON.parse(values.tsr);
      const options = {
        assignTo: email,
        assignToName: name,
        assignToDepot: depot,
        taskIds: [task.id],
      };
      setLoader(true);
      setReassignModalOpen(false);
      await assignBulkTaskApi(options)
        .then(() => {
          setTimeout(() => {
            successMessage(`Task was reassigned successfully`);
            updateTask();
            setLoader(false);
            onSuccess();
          }, 2000);
        })
        .catch(error => {
          setReassignModalOpen(false);
          setLoader(false);
          if (error?.response?.data?.errors[0]?.code === "TEAM_IS_NOT_ELIGIBLE") {
            errorMessage("Team is not eligible for this task type and TSR.");
          } else {
            defaultNgiErrorMethod(error);
          }
        });
    },
    [task]
  );

  const closeDeleteModal = useCallback(() => setIsDeleteModalOpen(false), []);
  const openDeleteModal = useCallback(() => setIsDeleteModalOpen(true), []);

  const onSuccessCancelTask = useCallback(() => {
    closeDeleteModal();
    updateTask();
  }, []);

  const onSuccess = useCallback(() => {
    closeDeleteModal();
    handleCancel();
    !!backUrl && history.push(backUrl);
  }, [backUrl]);

  const isInvestigation = useMemo(() => task.category === "Investigation", [task]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={backButtonUrl}>Back</BackButtonLink>

        <div className={classes.nameContainer}>
          <span>{category}</span>
          {status && <div className={classes.status}>{status}</div>}
        </div>
      </div>

      <div className={classes.rightContainer}>
        {allowedStatuses.includes(task.status) && (
          <ButtonGroupContainer color={"primary"}>
            <ButtonGroup
              hideRoles={[
                USER_ROLES.CUSTOMER_CARE,
                USER_ROLES.INTERNAL_AUDITOR,
                USER_ROLES.A_DSM,
                USER_ROLES.D_SM,
                USER_ROLES.OPERATION_MANAGERS,
                USER_ROLES.GENERAL,
                USER_ROLES.IT_OPERATIONS,
                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                USER_ROLES.FINANCE,
                USER_ROLES.DEPOT_CLERK,
                USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                USER_ROLES.A_SM,
                USER_ROLES.TSS,
              ]}
              color={"primary"}
              tooltipTitle="Reassign"
              handleClick={showModal}
              icon={workerIcon}
            />
            <ButtonGroup
              hideRoles={[
                USER_ROLES.A_DSM,
                USER_ROLES.D_SM,
                USER_ROLES.IT_OPERATIONS,
                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                USER_ROLES.GENERAL,
                USER_ROLES.DEPOT_CLERK,
                USER_ROLES.CUSTOMER_CARE,
                USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                USER_ROLES.A_SM,
                USER_ROLES.FINANCE,
                USER_ROLES.TSS,
              ]}
              color={"primary"}
              tooltipTitle="Cancel"
              icon={cancelIcon}
              handleClick={openDeleteModal}
            />
          </ButtonGroupContainer>
        )}

        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <div>
              <div className={classes.title}>SOURCE</div>
              <div className={classnames(classes.data, classes.overflowText)}>{source}</div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <img src={calendarIcon} alt="Created At" />
            <div>
              <div className={classes.title}>CREATED AT</div>
              <div className={classnames(classes.data, classes.overflowText)}>
                {getFormattedDataByName("createdAt", createdAt)}
              </div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <img src={depotIcon} alt="depot" />
            <div>
              <div className={classes.title}>DEPOT</div>
              <div className={classnames(classes.data, classes.overflowText)} title={depot}>
                {depot}
              </div>
            </div>
          </div>
        </div>
      </div>

      {reassignModalOpen && (
        <Modal
          className={classes.root}
          title={`Reassign To ${isInvestigation ? "TSS" : "User"}`}
          open={reassignModalOpen}
          onCancel={handleCancel}
          closable={false}
          footer={null}
        >
          <NGIForm name="reassignToTSR" form={form} onFinish={handleOk}>
            <Row>
              <NGIFormItem
                name="tsr"
                rules={[
                  {
                    required: true,
                    message: `Please select ${isInvestigation ? "TSS" : "a user"}`,
                  },
                ]}
              >
                <NGISelect
                  showSearch
                  style={{ width: 300 }}
                  placeholder={`Select ${isInvestigation ? "TSS" : "a user"}`}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {agentList.length > 0 &&
                    agentList
                      .filter(item =>
                        isInvestigation
                          ? item.roles.includes(USER_ROLES.TSS)
                          : item.roles.includes(USER_ROLES.TSS) ||
                            item.roles.includes(USER_ROLES.TECHNICIANS) ||
                            item.roles.includes(USER_ROLES.MTSR)
                      )
                      .map(
                        item => (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.name || item.id}
                          </Option>
                        ),
                        this
                      )}
                </NGISelect>
              </NGIFormItem>
              <Col span={12}>
                <Space>
                  <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
                  <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
                </Space>
              </Col>
            </Row>
          </NGIForm>
        </Modal>
      )}

      {isDeleteModalOpen && (
        <CancelTaskModal
          onClose={closeDeleteModal}
          updateTable={() => {}}
          initialValues={task}
          bulkValues={[]}
          resetSelectedRowKeys={onSuccess}
          setLoader={setLoader}
          onSuccess={onSuccessCancelTask}
        />
      )}
    </div>
  );
};

export default Header;
