import React, { useCallback, useEffect, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { simCardsReportsListColumns } from "pages/meter-management/configuration/simCardsReports/SimCardsReportsListColumns";
import SimCardsReportsFilter from "pages/meter-management/configuration/simCardsReports/SimCardsReportsFilter";
import NGITable from "components/Common/NGITable";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { retrieveSimCardReports } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import UploadModal from "pages/meter-management/configuration/simCardsReports/uploadModal";
const SimCardsReports = () => {
  const [isLoading, setLoader] = useState(false);
  const [simCardList, setSimCardList] = useState(null);
  const { setFilters } = useStoreFilters(FILTER_PAGE.SIM_CARD_REPORTS);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

  useEffect(() => {
    getSimCards();
  }, []);

  const closeUploadModal = useCallback(() => setIsUploadModalVisible(false), []);
  const openUploadModal = useCallback(() => setIsUploadModalVisible(true), []);

  const getSimCards = useCallback((filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);
    retrieveSimCardReports(filterOptions)
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (!items.length) {
          warnMessage(`No sim card reports were found`);
          setLoader(false);
        }
        setSimCardList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setSimCardList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="filters-wrapper">
          <CustomButton
            hideRoles={[
              USER_ROLES.INTERNAL_AUDITOR,
              USER_ROLES.GENERAL,
              USER_ROLES.IT_OPERATIONS,
              USER_ROLES.FINANCE,
              USER_ROLES.METER_ENGINEER,
              USER_ROLES.PRODUCT_TECHNICIANS,
            ]}
            icon={<i className="bx bx-upload" />}
            type="primary"
            onClick={openUploadModal}
            text="Upload"
          />
          <SimCardsReportsFilter handleFilter={getSimCards} isLoading={isLoading} />
        </div>
        <Col>
          <NGITable
            filtersType={FILTER_PAGE.SIM_CARD_REPORTS}
            isLoading={isLoading}
            columns={simCardsReportsListColumns()}
            data={simCardList}
            updateList={getSimCards}
          />
        </Col>
      </Container>
      {isUploadModalVisible && (
        <UploadModal isVisible={isUploadModalVisible} onClose={closeUploadModal} onUpdate={getSimCards} />
      )}
    </div>
  );
};

export default SimCardsReports;
