import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Divider, Form, Modal, Row, Spin } from "antd";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import { retrieveCustomerPhotoUploadUrlByType } from "utils/api";
import { editableFieldsMap } from "pages/operations/leadDetails/constants";
import Title from "antd/lib/typography/Title";
import NGIInput from "components/Common/inputs/NGIInput";
import { toBase64, uuidv4 } from "utils/helpers/functions";
import { Option } from "antd/lib/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import classnames from "classnames";
import cameraIcon from "assets/images/svg/Camera.svg";
import removeIcon from "assets/images/svg/Close.svg";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import moment from "moment/moment";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "50%",
    paddingRight: 25,
  },
  mapContainer: {
    width: "50%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
    paddingLeft: 25,
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 14,
  },
  green: {
    color: "#00b050",
  },
  red: {
    color: "#ff4d4f",
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  link: {
    textDecoration: "underline!important",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 10,
    },
  },
  editContainer: {
    display: "flex",
  },
  filedColumn: {
    padding: "0 10px",
  },
  columnTitle: {
    fontSize: "14px!important",
    fontWeight: "400!important",
  },
  uploadButton: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    fontWeight: 600,
    color: "white",
    padding: 12,
    height: 36,
    borderRadius: 20,
    textTransform: "uppercase",
    fontSize: "11px!important",

    "&> img": {
      margin: 10,
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  disabled: {
    pointerEvents: "none",
    cursor: "not-allowed!important",
    opacity: 0.6,
  },
  uploadContainer: {
    display: "flex",
    justifyContent: "space-between",
    border: "2px solid #444444",
    borderRadius: 30,
    padding: 5,
    height: 50,
  },
  filePreview: {
    width: 36,
    borderRadius: 5,
    margin: "0 10px",
  },
  fileRemove: {
    padding: "0 8px",

    "& :hover": {
      cursor: "pointer",
    },
  },
  previewContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fileInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  fileTitle: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  spinner: {
    marginTop: 7,
    marginLeft: 7,
  },
});

const PersonalInformationTab = ({ lead, mode, editForm, isLoading, packages }) => {
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState("");
  const [files, setFiles] = useState({});
  const [photoState, setPhotoState] = useState({});

  const {
    list: { customerType, occupation: occupationList, documentType },
  } = useSelector(DictionariesSelector);

  const {
    gender,
    occupation,
    type,
    packageName,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    leadScoringAt,
    declinedReason,
    leadId,
    document,
    location,
    phone,
    secondaryPhone,
    closeRelativePhone,
    tenant,
    status,
    id,
    personalPhotoUrl,
    idPhotoUrl,
    housePhotoUrl,
    refSource,
    refValue,
  } = lead;

  const onPreviewImg = useCallback(
    e => {
      const url = e.currentTarget.dataset.url;
      setPreviewUrl(url);
    },
    [id]
  );

  useEffect(() => {
    setFiles("");
  }, [mode]);

  const onClose = useCallback(() => setPreviewUrl(""), [id]);

  const handleFileChange = useCallback(
    async event => {
      const type = event.target.dataset.type;
      const fieldType = event.target.dataset.fieldType;
      const fileUploaded = event.target.files[0];
      setPhotoState(prevState => ({ ...prevState, [type]: true }));
      retrieveCustomerPhotoUploadUrlByType(type, fileUploaded.type.replace("image/", ".")).then(async res => {
        const {
          data: { result },
        } = res;
        const base64Data = await toBase64(fileUploaded);
        editForm.setFieldValue(fieldType, {
          base64: base64Data,
          fileType: fileUploaded.type,
          name: fileUploaded.name,
          ...result,
        });
        setPhotoState(prevState => ({ ...prevState, [type]: false }));
        setFiles(prevState => ({
          ...prevState,
          [type]: {
            _id: uuidv4(),
            name: fileUploaded.name,
            size: (fileUploaded.size / 1000).toFixed(2),
            base64: base64Data,
            fileType: fileUploaded.type,
          },
        }));
      });
    },
    [editForm]
  );

  const removePhoto = useCallback(
    e => {
      const type = e.target.dataset.type;
      const fieldType = e.target.dataset.fieldType;
      setFiles(prevState => ({
        ...prevState,
        [type]: null,
      }));
      editForm.setFieldValue(fieldType, null);
    },
    [editForm]
  );

  const getField = useCallback(
    type => {
      switch (type) {
        case "dob":
          return (
            <NGIDatePicker
              disableDates={current => moment().add(-100, "years") >= current || moment().add(-18, "years") <= current}
            />
          );
        case "occupation":
          return (
            <NGISelect showSearch placeholder={"Select value"}>
              {occupationList.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </NGISelect>
          );
        case "type":
          return (
            <NGISelect showSearch placeholder={"Select value"}>
              {customerType?.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </NGISelect>
          );
        case "idType":
          return (
            <NGISelect showSearch placeholder={"Select value"}>
              {documentType.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </NGISelect>
          );
        case "gender":
          return (
            <NGISelect showSearch placeholder={"Please select Gender"}>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
            </NGISelect>
          );

        case "packageCode":
          return (
            <NGISelect showSearch placeholder={"Please select package"}>
              {packages?.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </NGISelect>
          );
        case "housePhotoFileName":
          return (
            <div className={classes.uploadContainer}>
              <div className={classes.spinner}>{photoState["housePhotos"] && <Spin spinning={true} />}</div>
              {files["housePhotos"] ? (
                <div className={classes.previewContainer}>
                  <div className={classes.fileInfo}>
                    <img className={classes.filePreview} src={files["housePhotos"].base64} alt="preview img" />
                    <div className={classes.fileTitle}>{files["housePhotos"].name}</div>
                  </div>
                  <div className={classes.fileRemove}>
                    <img
                      onClick={removePhoto}
                      data-field-type="housePhotoFileName"
                      data-type={"housePhotos"}
                      src={removeIcon}
                      alt="remove"
                    />
                  </div>
                </div>
              ) : (
                <label
                  onChange={handleFileChange}
                  htmlFor="housePhotos"
                  className={classnames(classes.uploadButton, files["housePhotos"] && classes.disabled)}
                >
                  <input
                    data-type="housePhotos"
                    data-field-type="housePhotoFileName"
                    accept="image/*"
                    type="file"
                    id="housePhotos"
                    hidden
                  />
                  <img src={cameraIcon} alt="camera" />
                  Add photo
                </label>
              )}
            </div>
          );
        case "idPhotoFileName":
          return (
            <div className={classes.uploadContainer}>
              <div className={classes.spinner}>{photoState["upload/id"] && <Spin spinning={true} />}</div>
              {files["upload/id"] ? (
                <div className={classes.previewContainer}>
                  <div className={classes.fileInfo}>
                    <img className={classes.filePreview} src={files["upload/id"].base64} alt="preview img" />
                    <div className={classes.fileTitle}>{files["upload/id"].name}</div>
                  </div>
                  <div className={classes.fileRemove}>
                    <img
                      onClick={removePhoto}
                      data-field-type="idPhotoFileName"
                      data-type={"upload/id"}
                      src={removeIcon}
                      alt="remove"
                    />
                  </div>
                </div>
              ) : (
                <label
                  onChange={handleFileChange}
                  htmlFor="upload/id"
                  className={classnames(classes.uploadButton, files["upload/id"] && classes.disabled)}
                >
                  <input
                    data-type="upload/id"
                    data-field-type="idPhotoFileName"
                    accept="image/*"
                    type="file"
                    id="upload/id"
                    hidden
                  />
                  <img src={cameraIcon} alt="camera" />
                  Add photo
                </label>
              )}
            </div>
          );
        case "personalPhotoFileName":
          return (
            <div className={classes.uploadContainer}>
              <div className={classes.spinner}>{photoState["personalPhotos"] && <Spin spinning={true} />}</div>

              {files["personalPhotos"] ? (
                <div className={classes.previewContainer}>
                  <div className={classes.fileInfo}>
                    <img className={classes.filePreview} src={files["personalPhotos"].base64} alt="preview img" />
                    <div className={classes.fileTitle}>{files["personalPhotos"].name}</div>
                  </div>
                  <div className={classes.fileRemove}>
                    <img
                      onClick={removePhoto}
                      data-field-type="personalPhotoFileName"
                      data-type={"personalPhotos"}
                      src={removeIcon}
                      alt="remove"
                    />
                  </div>
                </div>
              ) : (
                <label
                  onChange={handleFileChange}
                  htmlFor="personalPhotos"
                  className={classnames(classes.uploadButton, files["personalPhotos"] && classes.disabled)}
                >
                  <input
                    data-type="personalPhotos"
                    data-field-type="personalPhotoFileName"
                    accept="image/*"
                    type="file"
                    id="personalPhotos"
                    hidden
                  />
                  <img src={cameraIcon} alt="camera" />
                  Add photo
                </label>
              )}
            </div>
          );
        default:
          return <NGIInput />;
      }
    },
    [customerType, occupationList, files, packages, photoState]
  );

  return (
    <div>
      <Spin spinning={isLoading}>
        {mode !== "edit" ? (
          <>
            <div className={classes.root}>
              <div className={classes.informationContainer}>
                <div className={classes.column}>
                  <div className={classes.sectionTitle}>Personal Information</div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Lead Id</div>
                    <div className={classes.data}>{textFormatter(leadId)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Gender</div>
                    <div className={classes.data}>{textFormatter(gender)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Occupation</div>
                    <div className={classes.data}>{textFormatter(occupation)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Lead Category</div>
                    <div className={classes.data}>{textFormatter(type)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Package</div>
                    <div className={classes.data}>{textFormatter(packageName)}</div>
                  </div>

                  <Divider />
                  <div className={classes.sectionTitle}>Address&Contact</div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Street Name</div>
                    <div className={classes.data}>{textFormatter(location?.street)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>House Number</div>
                    <div className={classes.data}>{textFormatter(location?.houseNumber)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>House Photo</div>
                    <div className={classes.data}>
                      {housePhotoUrl ? (
                        <CustomLink className={classes.link} data-url={housePhotoUrl} onClick={onPreviewImg}>
                          View Photo
                        </CustomLink>
                      ) : (
                        "No photo"
                      )}
                    </div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Primary Phone</div>
                    <div className={classes.data}>{textFormatter(phone)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Secondary Phone</div>
                    <div className={classes.data}>{textFormatter(secondaryPhone)}</div>
                  </div>
                  <div className={classes.rowData}>
                    <div className={classes.title}>Close Relative Phone</div>
                    <div className={classes.data}>{textFormatter(closeRelativePhone)}</div>
                  </div>
                </div>
              </div>
              <div className={classes.mapContainer}>
                <div className={classes.sectionTitle}>Customer Identity</div>
                <div className={classes.rowData}>
                  <div className={classes.title}>ID Type</div>
                  <div className={classes.data}>{textFormatter(document?.type)}</div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>ID Number</div>
                  <div className={classes.data}>{textFormatter(document?.number)}</div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>ID Photo</div>
                  <div className={classes.data}>
                    {idPhotoUrl ? (
                      <CustomLink className={classes.link} data-url={idPhotoUrl} onClick={onPreviewImg}>
                        View Photo
                      </CustomLink>
                    ) : (
                      "No photo"
                    )}
                  </div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>Tenant</div>
                  <div className={classes.data}>{textFormatter(tenant)}</div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>Personal Photo</div>
                  <div className={classes.data}>
                    {personalPhotoUrl ? (
                      <CustomLink className={classes.link} data-url={personalPhotoUrl} onClick={onPreviewImg}>
                        View Photo
                      </CustomLink>
                    ) : (
                      "No photo"
                    )}
                  </div>
                </div>
                <Divider />
                <div className={classes.sectionTitle}>Lead Information</div>
                <div className={classes.rowData}>
                  <div className={classes.title}>Created By</div>
                  <div className={classes.data}>
                    <CustomLink underlined target="_blank" to={`/user/${createdBy}`}>
                      {textFormatter(createdBy)}
                    </CustomLink>
                  </div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>Created At</div>
                  <div className={classes.data}>{textFormatter(customDateFormatWithTime(createdAt))}</div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>Updated By</div>
                  <div className={classes.data}>
                    <CustomLink underlined target="_blank" to={`/user/${updatedBy}`}>
                      {textFormatter(updatedBy)}
                    </CustomLink>
                  </div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>Updated At</div>
                  <div className={classes.data}>{textFormatter(customDateFormatWithTime(updatedAt))}</div>
                </div>
                <div className={classes.rowData}>
                  <div className={classes.title}>Lead Source</div>
                  <div className={classes.data}>
                    {textFormatter(refSource)}
                    {!!refValue && refValue.length === 7 ? (
                      <CustomLink className={classes.link} target="_blank" to={`/customers/customer/${refValue}`}>
                        {refValue}
                      </CustomLink>
                    ) : (
                      refValue
                    )}
                  </div>
                </div>

                {status === "Declined" && (
                  <>
                    <div className={classes.rowData}>
                      <div className={classes.title}>Declined Date</div>
                      <div className={classes.data}>{textFormatter(customDateFormatWithTime(updatedAt))}</div>
                    </div>
                    <div className={classes.rowData}>
                      <div className={classes.title}>Declined Reason</div>
                      <div className={classes.data}>{textFormatter(declinedReason)}</div>
                    </div>
                  </>
                )}

                {status === "Qualified" && (
                  <>
                    <div className={classes.rowData}>
                      <div className={classes.title}>Approved Date</div>
                      <div className={classes.data}>{textFormatter(customDateFormatWithTime(updatedAt))}</div>
                    </div>
                    <div className={classes.rowData}>
                      <div className={classes.title}>Installation task created</div>
                      <div className={classes.data}>{textFormatter(customDateFormatWithTime(updatedAt))}</div>
                    </div>
                    <div className={classes.rowData}>
                      <div className={classes.title}>Installation task</div>
                      <div className={classes.data}>
                        <CustomLink
                          target="_blank"
                          className={classes.link}
                          to={`/operations/tasks/list?customerId=${id}`}
                        >
                          View Task
                        </CustomLink>
                      </div>
                    </div>
                  </>
                )}
                <div className={classes.rowData}>
                  <div className={classes.title}>Scheduled Approval</div>
                  <div className={classes.data}>
                    {textFormatter(leadScoringAt && customDateFormatWithTime(leadScoringAt))}
                  </div>
                </div>
              </div>
              {previewUrl && (
                <Modal title="Photo preview" open={!!previewUrl} onCancel={onClose} footer={[]}>
                  <img className={classes.imgPreview} src={previewUrl} alt="preview" />
                </Modal>
              )}
            </div>
            <Divider />
          </>
        ) : (
          <div className={classes.editContainer}>
            <Form form={editForm}>
              {editableFieldsMap.map(item => (
                <div className={classes.section} key={item.title}>
                  <div className={classes.sectionTitle}>{item.title}</div>
                  <Row>
                    {item.fields.map(field => {
                      const [fieldName] = Object.keys(field);
                      const fieldByType = getField(fieldName);
                      return (
                        <Col span={6} className={classes.filedColumn} key={fieldName}>
                          <Title className={classes.columnTitle}>{field[fieldName]}</Title>
                          <Form.Item
                            name={fieldName}
                            rules={[
                              { required: field?.required, message: "Please enter value" },
                              ...(field?.rules || []),
                            ]}
                          >
                            {fieldByType}
                          </Form.Item>
                        </Col>
                      );
                    })}
                  </Row>
                  <Divider />
                </div>
              ))}
            </Form>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default PersonalInformationTab;
