import { textFormatter, priorityFormatter, uppercaseTextFormatter, boolFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

const color = "#444444";
export const taskColumns = [
  {
    title: "Type",
    dataIndex: "",
    key: "category",
    sort: true,
    render: row => (
      <Tooltip placement="bottom" title={`${row.category} task details`} color={color}>
        <div>
          <CustomLink to={`/operations/task/${row.id}?backUrl=/operations/tasks/investigations`}>
            {uppercaseTextFormatter(row.category)}
          </CustomLink>
        </div>
      </Tooltip>
    ),
    width: "20%",
  },
  {
    dataIndex: "customerId",
    key: "customerId",
    title: "Customer ID",
    render: (_, task) =>
      task?.customer?.id ? (
        <CustomLink underlined target="_blank" to={`/customers/customer/${task?.customer?.id}`}>
          {task?.customer?.id}
        </CustomLink>
      ) : (
        textFormatter(task?.customer?.id)
      ),
    width: "20%",
  },
  {
    dataIndex: "customerName",
    key: "customerName",
    title: "Customer Name",
    render: (_, task) => priorityFormatter(task?.customer?.name),
    width: "16%",
  },
  {
    dataIndex: "priority",
    key: "priority",
    title: "Priority",
    render: textFormatter,
    width: "16%",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sort: true,
    render: (cellContent, task) => textFormatter(task.status),
    width: "20%",
  },
  {
    dataIndex: "assignedTo",
    key: "assignedTo",
    title: "ASSIGNED TO",
    render: (cellContent, task) => (
      <CustomLink underlined target="_blank" to={`/user/${task.assignedTo}`}>
        {textFormatter(task.assignedTo)}
      </CustomLink>
    ),
    width: "40%",
  },
  {
    title: "Depot",
    dataIndex: "depot",
    key: "depot",
    sort: true,
    render: (cellContent, task) => textFormatter(task.depot),
    width: "20%",
  },
  {
    dataIndex: "isCallCenterIssue",
    key: "isCallCenterIssue",
    title: "Customer Care",
    render: (cellContent, task) => boolFormatter(task?.extraData?.isCallCenterIssue),
    width: "40%",
  },
];
