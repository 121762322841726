import React, { useCallback, useMemo, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import { assignDepotToCustomers } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import NGISelect from "components/Common/inputs/NGISelect";
import uploadArea from "assets/images/svg/uploadArea.svg";
import Dragger from "antd/lib/upload/Dragger";
import * as xlsx from "xlsx";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { ENGINE_VERSION } from "utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import closeIcon from "assets/images/svg/closeSmall.svg";

const useStyles = makeStyles({
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});
const BulkLinkDepotModal = ({ onClose, onUpdate, depotsList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [uploadedData, setUploadedData] = useState("");
  const [selectedDepot, setSelectedDepot] = useState(null);
  const [file, setFile] = useState(null);
  const handleCancel = useCallback(() => onClose(), [onClose]);
  const selectedDepotData = useMemo(() => depotsList.find(item => item.name === selectedDepot), [selectedDepot]);

  const readUploadFile = useCallback(e => {
    const file = e.file;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        const csv = xlsx.utils.sheet_to_csv(worksheet, { raw: false });

        const formattedResult = json.map(row => ({ ...row }));
        const noError = formattedResult.every(item => item || null);
        !noError && errorMessage("Please upload the correct ‘*.csv’ file with customerId field");
        if (noError) {
          setUploadedData(csv);
          setFile(file);
        } else {
          setUploadedData("");
          setFile(null);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const handleChange = useCallback(
    depot => {
      setSelectedDepot(depot);
      form.setFieldsValue({
        sector: null,
      });
    },
    [form]
  );

  const handleSave = useCallback(() => {
    const { depot, sector } = form.getFieldsValue();
    if (depot && uploadedData) {
      setLoader(true);
      assignDepotToCustomers({ uploadedData, depot, ...(sector && { sector }) })
        .then(() => {
          setTimeout(() => {
            successMessage("Customers were linked with the new Depot successfully");
            setLoader(false);
            onUpdate();
            onClose();
          }, 2000);
        })
        .catch(err => {
          if (err?.response?.data?.errors[0]?.code === "NOT_FOUND") {
            defaultNgiErrorMethod(err, 0);
          } else {
            errorMessage(
              "Customers were not found and/or not linked to the new Depot! Please verify the list of IDs in the file."
            );
          }
          setLoader(false);
        });
    }
  }, [form, uploadedData, depotsList]);

  const removeFile = useCallback(() => {
    setFile(null);
    setUploadedData([]);
  }, []);

  return (
    <Modal open={true} title="Bulk link customers to Depot" closable={false} onCancel={handleCancel} footer={[]}>
      <Spin spinning={isLoading}>
        <NGIForm name="bulkLinkCustomersToDepot" form={form} onFinish={handleSave}>
          <Col span={24}>
            {file ? (
              <div className={classes.fileContainer}>
                <div className={classes.fileTitle}>{`${file.name} (${uploadedData.length})`}</div>
                <div onClick={removeFile} className={classes.removeBtn}>
                  <img src={closeIcon} alt="remove" />
                </div>
              </div>
            ) : (
              <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                <img className="upload-bg" src={uploadArea} alt="upload" />
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single upload. Strictly prohibit from uploading company data or other band files
                </p>
              </Dragger>
            )}
          </Col>
          <br />
          <Col span={24}>
            <Title level={5}>New Depot</Title>
            <NGIFormItem name="depot" rules={[{ required: true, message: "Please select Depot" }]}>
              <NGISelect
                showSearch
                onChange={handleChange}
                loading={!depotsList?.length}
                placeholder="Please select Depot"
                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
              >
                {depotsList?.sort().map(item => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </NGISelect>
            </NGIFormItem>
          </Col>
          {selectedDepotData?.taskEngine === ENGINE_VERSION.V3 && (
            <Col span={24}>
              <Title level={5}>Sector</Title>
              <NGIFormItem name="sector" rules={[{ required: true, message: "Please select Sector" }]}>
                <NGISelect showSearch placeholder={"Please select Sector"}>
                  {selectedDepotData?.sectors?.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
          )}
          <Space>
            <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default BulkLinkDepotModal;
