import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { readSettingsAPI } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import Title from "antd/lib/typography/Title";
import { useDispatch } from "react-redux";
import { createSettings, updateSettings } from "store/meter-management/configuration/global-settings/actions";
import { fieldsToConvert, VALIDATION_RULES, meterStatusList } from "pages/meter-management/meters/variables";
import { covertObjWithNumbers, getTrimmedObjectValues } from "utils/helpers/functions";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import { AUTH_USER } from "utils/constants";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
});

const ConfigurationModal = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [configurationId, setConfigurationId] = useState(null);

  const onSubmit = useCallback(
    values => {
      const mappedValues = covertObjWithNumbers(getTrimmedObjectValues(values), fieldsToConvert);
      if (configurationId) {
        dispatch(updateSettings({ id: configurationId, ...mappedValues }));
      } else {
        dispatch(
          createSettings({
            ...mappedValues,
          })
        );
      }
      onClose();
    },
    [configurationId]
  );

  useEffect(() => {
    setLoader(true);
    readSettingsAPI()
      .then(({ data }) => {
        const userTenant = AUTH_USER?.tenant;
        const foundConfig = data.result.find(item => item.tenant === userTenant);
        if (foundConfig) {
          setConfigurationId(foundConfig?.id);
          form.setFieldsValue(foundConfig);
        } else {
          setConfigurationId(data.result[0]?.id);
          form.setFieldsValue(data.result[0]);
        }
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [form]);

  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          Meter Auto Configuration
          <span>Default configurations are separated by tenants (countries)</span>
        </div>
      }
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm
          form={form}
          isUpdate={configurationId}
          name="configuration-modal"
          layout="inline"
          onFinish={onSubmit}
          className={classes.formContainer}
        >
          <div className={classes.modalContent}>
            <Row>
              <Col span={12}>
                <Title level={5}>Server URL</Title>
                <NGIFormItem name="serverURL" rules={VALIDATION_RULES}>
                  <NGIInput />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Update Period</Title>
                <NGIFormItem name="updatePeriod" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" min={0} max={1440} />
                </NGIFormItem>
              </Col>
            </Row>
            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Warning Low Battery Voltage</Title>
                <NGIFormItem name="warningLowBatteryVoltage" rules={VALIDATION_RULES}>
                  <NGIInput suffix={"V"} type="number" step="any" />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Warning Low Credit Balance</Title>
                <NGIFormItem name="warningLowCreditBalance" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Warning Low Gas Mass</Title>
                <NGIFormItem name="warningLowGasMass" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" min={0} />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Gas Remaining</Title>
                <NGIFormItem name="gasRemaining" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" min={2} max={13} />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Unit Price</Title>
                <NGIFormItem name="unitPrice" rules={VALIDATION_RULES}>
                  <NGIInput />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Currency</Title>
                <NGIFormItem name="currency" rules={VALIDATION_RULES}>
                  <NGIInput />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Sensor Slope</Title>
                <NGIFormItem name="sensorSlope" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Sensor Intercept</Title>
                <NGIFormItem name="sensorIntercept" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Gas Density</Title>
                <NGIFormItem name="gasDensity" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" min={0} />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Min Full Battery Level</Title>
                <NGIFormItem name="minFullBatteryLevel" rules={VALIDATION_RULES}>
                  <NGIInput suffix={"%"} type="number" step="any" />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Warning Low Battery</Title>
                <NGIFormItem name="warningLowBattery" rules={VALIDATION_RULES}>
                  <NGIInput suffix={"mAh"} type="number" step="any" />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Display Duration</Title>
                <NGIFormItem name="displayDuration" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Density Change</Title>
                <NGIFormItem name="densityChange" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Cooking Minutes Remaining</Title>
                <NGIFormItem name="cookingMinutesRemaining" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Cooking Timeout</Title>
                <NGIFormItem name="cookingTimeout" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Meter Status</Title>
                <NGIFormItem name="meterStatus" rules={VALIDATION_RULES}>
                  <NGISelect showSearch loading={!meterStatusList?.length} placeholder="Please select value">
                    {meterStatusList.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>BLE Time Out</Title>
                <NGIFormItem name="BLETimeOut" rules={VALIDATION_RULES}>
                  <NGIInput type="number" step="any" />
                </NGIFormItem>
              </Col>
            </Row>
          </div>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default ConfigurationModal;
