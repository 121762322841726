import moment from "moment";
import { groupBy } from "lodash";

export const getMappedData = data => {
  let available = 0;
  let all = 0;
  const todayDate = moment().format("YYYY-MM-DD");
  const tomorrowDate = moment().add(1, "day").format("YYYY-MM-DD");
  const end7Date = moment().add(7, "day").format("YYYY-MM-DD");
  const after7Date = moment().add(8, "day").format("YYYY-MM-DD");

  const mapped = Object.keys(data?.schedule).map(item => ({
    name: item,
    ...data?.schedule[item],
  }));

  const preparedData = mapped.map(item => {
    let allAvailable = 0;

    let nextWeek = 0;
    let afterNextWeek = 0;
    let today = 0;

    Object.keys(item.available).forEach(k => {
      if (moment(k).isBetween(tomorrowDate, after7Date)) {
        nextWeek += item.available[k];
      }
      if (moment(k).isAfter(end7Date)) {
        afterNextWeek += item.available[k];
      }
      if (k === todayDate) {
        today = item.available[k];
        allAvailable += item.available[k];
      }
    });
    const tomorrow = item.available[tomorrowDate] || 0;

    all += allAvailable + item.unreachable + item.notCalledYet + nextWeek + afterNextWeek + tomorrow;

    available += allAvailable;

    return {
      ...item,
      availableCount: available,
      tomorrow,
      nextWeek,
      afterNextWeek,
      today,
    };
  });
  const mapping = getMapping(preparedData);
  return {
    preparedData,
    available,
    mapping,
    all,
  };
};

export const getMappedStatusData = data => {
  let allCompleted = 0;
  let all = 0;
  const groupedData = groupBy(data, "bucket");

  const list = Object.keys(groupedData).map(key => {
    const assigned = calcCountByStatus(groupedData[key], "Assigned");
    const inProgress = calcCountByStatus(groupedData[key], "In progress");
    const cancelled = calcCountByStatus(groupedData[key], "Cancelled");
    const completed = calcCountByStatus(groupedData[key], "Completed");
    all += assigned + inProgress + cancelled + completed;
    allCompleted += completed;

    return {
      name: key,
      assigned,
      inProgress,
      cancelled,
      completed,
    };
  });

  const mapping = getMapping(list);

  return {
    list,
    completed: allCompleted,
    mapping,
    all,
  };
};
export const getMappedSourceData = data => {
  let allCompleted = 0;
  let all = 0;
  const groupedData = groupBy(data, "bucket");

  const list = Object.keys(groupedData).map(key => {
    const cc = calcCountBySource(groupedData[key], "Odoo");
    const cc1 = calcCountBySource(groupedData[key], "customer-care");
    const tl = calcCountBySource(groupedData[key], "task-list");
    const tr = calcCountBySource(groupedData[key], "App");
    const be = calcCountBySource(groupedData[key], "ngi-platform");
    const nd = calcCountBySource(groupedData[key], "ngi-dashboard");
    all += cc + cc1 + tl + tr + be + nd;
    allCompleted += nd;

    return {
      name: key,
      cc: cc + cc1,
      tl,
      tr,
      be,
      nd,
    };
  });

  const mapping = getMapping(list);

  return {
    list,
    completed: allCompleted,
    mapping,
    all,
  };
};

const calcCountByStatus = (data, status) =>
  data.filter(item => item.status === status).reduce((count, obj) => count + obj.count, 0);

const calcCountBySource = (data, source) =>
  data.filter(item => item.source === source).reduce((count, obj) => count + obj.count, 0);

export const taskSourceFormatter = task => {
  switch (task?.source) {
    case "App":
      return "TR";
    case "ngi-dashboard":
      return "ND";
    case "Odoo":
      return "CC";
    case "task-list":
      return "TL";
    case "ngi-platform":
      return "BE";
    default:
      return task?.source || "";
  }
};

export const formatTime = date => moment(date).format("HH:mm");

const getMapping = list => {
  const mapping = {};

  list.forEach(item => {
    Object.keys(item).forEach(key => {
      if (key !== "name" && typeof item[key] !== "object") {
        if (mapping[key]) {
          mapping[key] += item[key];
        } else {
          mapping[key] = item[key];
        }
      }
    });
  });

  return mapping;
};
