import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { getUsersList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { userListColumns } from "./userListColumns";
import UserSearch from "./UserSearch";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { emptyResponse } from "pages/customer-management/customers/constants";
import SetUserUnavailabilityModal from "pages/user-management/users/SetUserUnavailabilityModal";

const UserList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.USERS);
  const [unavailabilityModalVisible, setUnavailabilityModalVisible] = useState(false);

  const openUnavailabilityModal = useCallback(user => setUnavailabilityModalVisible(user), []);
  const closeUnavailabilityModal = useCallback(() => setUnavailabilityModalVisible(false), []);

  useEffect(() => {
    handleSearch(filters);
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Users were not found");
    }
    setUserList(res?.data || emptyResponse);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    getUsersList({
      ...filterOptions,
    })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setUserList(emptyResponse);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => userListColumns(openUnavailabilityModal), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <UserSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.USERS}
              rowSelection={null}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={userList}
              updateList={handleSearch}
            />
          </Col>
          {unavailabilityModalVisible && (
            <SetUserUnavailabilityModal
              user={unavailabilityModalVisible}
              handleCancel={closeUnavailabilityModal}
              onSuccess={() => handleSearch(filters)}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default UserList;
