export const CREATE_SETTINGS = "CREATE_SETTINGS";
export const CREATE_SETTINGS_SUCCESS = "CREATE_SETTINGS_SUCCESS";
export const CREATE_SETTINGS_FAIL = "CREATE_SETTINGS_FAIL";

export const READ_SETTINGS = "READ_SETTINGS";
export const READ_SETTINGS_SUCCESS = "READ_SETTINGS_SUCCESS";
export const READ_SETTINGS_FAIL = "READ_SETTINGS_FAIL";

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL";

export const DELETE_SETTINGS = "DELETE_SETTINGS";
export const DELETE_SETTINGS_SUCCESS = "DELETE_SETTINGS_SUCCESS";
export const DELETE_SETTINGS_FAIL = "DELETE_SETTINGS_FAIL";
