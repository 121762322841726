import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { Option } from "antd/es/mentions";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { filterOptions } from "pages/customer-management/customerUpdateRequest/constants";
import { dateFormat } from "pages/customer-management/customers/constants";
import { makeStyles } from "@material-ui/core/styles";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";
import TSRSelect from "components/Common/components/TSRSelect";
import { getUsersList } from "utils/api";

const useStyles = makeStyles({
  root: { width: "250px!important" },
});

const CustomerUpdateSearch = ({ handleSearch, statuses }) => {
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.TASK_REQUESTS);
  const [selectedFilter, setSelectedFilter] = useState("status");
  const { RangePicker } = DatePicker;
  const classes = useStyles();
  const [TSRList, setTSRList] = useState([]);
  const roles = [
    USER_ROLES.SYSTEM_FULL_ADMINS,
    USER_ROLES.OPERATION_MANAGERS,
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.A_SM,
    USER_ROLES.TECHNICIANS,
    USER_ROLES.MTSR,
    USER_ROLES.TRAINING,
    USER_ROLES.LT,
    USER_ROLES.TSS,
  ];

  const onChangeFilter = useCallback(values => {
    setSelectedFilter(values);
    form.setFieldValue("value", null);
  }, []);

  useEffect(() => {
    handleSearch({ status: "Pending" });
  }, []);

  useEffect(() => {
    form.setFieldsValue({ type: "status", value: "Pending" });
    getUsersList({
      role: roles.join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    handleSearch({
      [values?.type]: values?.value,
      ...(values?.date
        ? {
            createdAtFrom: getStartOfTheDay(moment(values?.date[0])),
            createdAtTo: getEndOfTheDay(moment(values?.date[1])),
          }
        : {}),
      pageSize,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={onSearch}>
        <Form.Item className={classes.root} name="date">
          <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
        </Form.Item>
        <div className="filter-divider" />
        <Form.Item name="type">
          <Select showSearch onChange={onChangeFilter} tokenSeparators={[","]} placeholder="Select type" allowClear>
            {filterOptions?.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedFilter === "status" && (
          <>
            <Form.Item name="value">
              <Select showSearch placeholder={"Select value"}>
                {statuses?.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        {selectedFilter === "createdBy" && (
          <>
            <Form.Item name="value">
              <TSRSelect showSearch initialList={TSRList} roles={roles} depot={AUTH_USER?.depotName} />
            </Form.Item>
          </>
        )}
        {selectedFilter !== "status" && selectedFilter !== "createdBy" && (
          <Form.Item name="value">
            <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
          </Form.Item>
        )}
        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default CustomerUpdateSearch;
