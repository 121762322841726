export const FILTERS = {
  CATEGORY: "CATEGORY",
  CUSTOMER: "CUSTOMER",
  TSR: "TSR",
};
export const filterOptions = [
  { name: "Category", value: FILTERS.CATEGORY },
  { name: "Customer ID", value: FILTERS.CUSTOMER },
  { name: "TSR", value: FILTERS.TSR },
];
