import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { getUsersList, retrieveAssetValidationRequests } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { validationTaskRequestsListColumns } from "pages/operations/validation-task-requests/ValidationTaskRequestsListColumns";
import ValidationTaskRequestsSearch from "pages/operations/validation-task-requests/ValidationTaskRequestsSearch";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { isEmpty } from "lodash";
import { userHasOneOfRoles } from "utils/helpers/functions";

const ValidationTaskRequests = () => {
  const [isLoading, setLoader] = useState(false);
  const [tasksList, setTasksList] = useState(null);
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.VALIDATION_TASK_REQUESTS);
  const [TSRList, setTSRList] = useState([]);
  const dispatch = useDispatch();

  const hideRoles = [USER_ROLES.INTERNAL_AUDITOR];

  useEffect(() => {
    handleSearch(!isEmpty(filters) ? filters : { status: "Pending" });
    getUsersList({
      role: [USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS, USER_ROLES.WAREHOUSE].join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Validation Task Requests were not found");
    }
    setTasksList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    retrieveAssetValidationRequests({
      status: "Pending",
      ...filterOptions,
    })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setTasksList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => validationTaskRequestsListColumns(userHasOneOfRoles(hideRoles)), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <ValidationTaskRequestsSearch TSRList={TSRList} handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.VALIDATION_TASK_REQUESTS}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={tasksList}
              updateList={handleSearch}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default ValidationTaskRequests;
