import React, { useEffect, useState } from "react";
import { routineTasksColumns } from "pages/operations/routineDetails/constants";
import NGITable from "components/Common/NGITable";
import { Spin } from "antd";

const TasksTab = ({ customers = [], onOpen, showAction = true, isLoading: defaultLoading }) => {
  const [isLoading, setLoader] = useState(false);

  useEffect(() => {
    setLoader(defaultLoading);
  }, [defaultLoading]);

  return (
    <Spin spinning={isLoading}>
      <NGITable
        columns={routineTasksColumns(onOpen, showAction)}
        data={{
          result: { items: customers },
        }}
      />
    </Spin>
  );
};

export default TasksTab;
