import React, { useCallback, useState } from "react";
import SidebarContent from "components/VerticalLayout/SidebarContent";
import logos from "../../assets/images/svg/logos.svg";
import expandArrow from "../../assets/images/svg/expandArrow.svg";
import classname from "classnames";
import { Link } from "react-router-dom";

const Sidebar = ({ currentPage }) => {
  const [hideMenu, setHideMenu] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [activeSubItem, setActiveSubItem] = useState(null);

  const handleHideMenu = useCallback(() => {
    setHideMenu(prevState => !prevState);
  }, [hideMenu]);

  const onLogoClick = useCallback(() => setActiveItem(null), []);

  return (
    <div className={classname("vertical-menu", hideMenu && "hide")} id="menu">
      <div className={classname("navbar-brand-box", hideMenu && "hide")}>
        <Link to="/dashboard">
          <img
            className={classname("logo-svg", hideMenu && "move-logo")}
            src={logos}
            alt="mGas powered by Safaricom"
            onClick={onLogoClick}
          />
        </Link>

        <img className="logo-expand" src={expandArrow} alt="expand" onClick={handleHideMenu} />
      </div>

      <div data-simplebar className="simplebar">
        <SidebarContent
          currentPage={currentPage}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          activeSubItem={activeSubItem}
          setActiveSubItem={setActiveSubItem}
          handleHideMenu={handleHideMenu}
        />
      </div>
      {!hideMenu && <div className="mobile-menu-backdrop" onClick={handleHideMenu}></div>}
      <div className="sidebar-background" />
    </div>
  );
};

export default Sidebar;
