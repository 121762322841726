import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { fetchCustomerList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import ProspectsSearch from "pages/operations/prospects/ProspectsSearch";
import { prospectListColumns } from "pages/operations/prospects/prospectListColumns";
import AssignProspectModal from "pages/operations/prospects/AssignProspectModal";
import NGITable from "components/Common/NGITable";
import { AVAILABLE_STATUSES, DEFAULT_PAGINATION_CONFIG } from "pages/operations/prospects/variables";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { getTSRList } from "store/TSR/actions";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { makeStyles } from "@material-ui/core/styles";
import NGITooltip from "components/Common/utils/NGITooltip";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import addIcon from "assets/images/svg/plus-circle-white.svg";
import { UserAddOutlined } from "@ant-design/icons";

const useStyles = makeStyles({
  link: {
    marginRight: 10,
  },
});

const Prospects = () => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { filters, setFilters, getFilters } = useStoreFilters(FILTER_PAGE.PROSPECTS);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.CUSTOMER_TYPE));
    dispatch(getTSRList());
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items, meta },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Prospects were not found");
    }
    const filteredItems = items.map(customer => ({
      fullName: `${customer.firstName} ${customer.lastName}`,
      ...customer,
    }));

    setCustomerList({ result: { items: filteredItems, meta } } || null);
    setLoader(false);
  }, []);

  const handleSearch = useCallback(
    (filterOptions = DEFAULT_PAGINATION_CONFIG) => {
      setLoader(true);
      setFilters(filterOptions);
      if (!AUTH_USER?.roles?.includes(USER_ROLES.SYSTEM_FULL_ADMINS)) filterOptions.depot = AUTH_USER?.depotName;
      fetchCustomerList({
        status: AVAILABLE_STATUSES.join(),
        ...filterOptions,
      })
        .then(res => onListLoaded(res))
        .catch(err => {
          setLoader(false);
          setCustomerList(null);
          defaultNgiErrorMethod(err);
        });
    },
    [AUTH_USER]
  );

  const openAssignModal = useCallback(() => setIsAssignModalVisible(true), []);
  const closeAssignModal = useCallback(() => setIsAssignModalVisible(false), []);

  const onUpdateList = useCallback(() => {
    const filters = getFilters();
    setCustomerList([]);
    handleSearch(filters);
    setSelectedItems([]);
  }, []);

  const columns = useMemo(() => prospectListColumns(classes), []);

  const rowSelection = {
    selectedItems,
    onChange: rows => setSelectedItems(rows),
    renderCell: (checked, record, index, node) => {
      if (record.status !== "OpenedProspect" && record.status !== "AssignedProspect") {
        return <NGITooltip title="Only 'Opened Prospect' or 'Assigned Prospect' items are allowed.">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      if (record.status !== "OpenedProspect" && record.status !== "AssignedProspect") {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };

  const hideRowSelection = useMemo(
    () =>
      userHasOneOfRoles([
        USER_ROLES.CUSTOMER_CARE,
        USER_ROLES.INTERNAL_AUDITOR,
        USER_ROLES.GENERAL,
        USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
        USER_ROLES.IT_OPERATIONS,
        USER_ROLES.BUSINESS_DEV,
        USER_ROLES.FINANCE,
      ]),
    []
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper with-buttons">
            <div className="filter-buttons">
              <ButtonGroupContainer color={"primary"}>
                <ButtonGroup
                  color={"primary"}
                  link={"/operations/prospects/new"}
                  tooltipTitle="New Prospect"
                  icon={addIcon}
                />
                {!!selectedItems.length && (
                  <ButtonGroup
                    hideRoles={[USER_ROLES.CUSTOMER_CARE, USER_ROLES.IT_OPERATIONS, USER_ROLES.BUSINESS_DEV]}
                    color={"primary"}
                    tooltipTitle="Assign to TSR"
                    handleClick={openAssignModal}
                  >
                    <UserAddOutlined />
                  </ButtonGroup>
                )}
              </ButtonGroupContainer>
            </div>
            <ProspectsSearch handleSearch={handleSearch} isLoading={isLoading} setLoader={setLoader} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.PROSPECTS}
              isLoading={isLoading}
              columns={columns}
              data={customerList}
              rowSelection={hideRowSelection ? null : rowSelection}
              rowKey={record => record.id}
              updateList={handleSearch}
            />
          </Col>
          {isAssignModalVisible && (
            <AssignProspectModal onClose={closeAssignModal} customerIds={selectedItems} onUpdate={onUpdateList} />
          )}
        </Container>
      </div>
    </>
  );
};

export default Prospects;
