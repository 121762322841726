import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import React from "react";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import wrenchIcon from "assets/images/svg/Wrench.svg";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";
import CustomLink from "components/Common/buttons/CustomLink";

export const stoveRepairListColumns = handleDetails => [
  {
    title: "Barcode",
    dataIndex: "stoveBarcode",
    render: (cellContent, row) => (
      <CustomLink underlined target="_blank" to={`/warehouse/assets/${row.stoveId}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Type",
    dataIndex: "stoveType",
  },

  {
    title: "Collected by",
    dataIndex: "collectedBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Customer ID",
    dataIndex: "customerId",
    render: id => (
      <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
        {id}
      </CustomLink>
    ),
  },
  {
    title: "Received at",
    dataIndex: "receivedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Collected Date",
    dataIndex: "collectedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  ...(!userHasOneOfRoles([USER_ROLES.DEPOT_CLERK])
    ? [
        {
          dataIndex: "",
          title: "",
          width: "4%",
          render: row => (
            <ButtonGroup
              tooltipTitle="Repair"
              icon={wrenchIcon}
              hideRoles={[USER_ROLES.DEPOT_CLERK]}
              handleClick={() => {
                handleDetails(row);
              }}
            />
          ),
        },
      ]
    : []),
];
