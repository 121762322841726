import React, { useCallback, useState } from "react";
import { Button, Select } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Option } from "antd/es/mentions";
import { DEFAULT_PAGE_SIZE } from "utils/constants";

const useStyles = makeStyles({
  root: {
    display: "flex",
    margin: "16px 0",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #444444",
    borderRadius: 30,
    background: "transparent",
    marginRight: 8,

    "&.ant-btn:focus": {
      border: "2px solid #444444",
    },
    "&.ant-btn:not([disabled]):hover": {
      border: "2px solid #444444",
    },
  },
  sizeSelect: {
    "&.ant-select > .ant-select-selector": {
      display: "flex",
      alignItems: "center",
      border: "2px solid #444444",
      borderRadius: 30,
      background: "transparent",
    },
    "& .ant-select:not(.ant-select-disabled):hover .ant-select-selector": {
      borderRightWidth: 2,
    },
  },
});

const SIZE_OPTIONS = [10, 20, 50, 100];

const NGITablePagination = ({
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
  onSizeChange,
  endCursor,
  startCursor,
  size = DEFAULT_PAGE_SIZE,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(size);

  const handlePreviousPage = useCallback(() => {
    onPreviousPage(pageSize, startCursor);
  }, [pageSize, startCursor]);

  const handleNextPage = useCallback(() => {
    onNextPage(pageSize, endCursor);
  }, [pageSize, endCursor]);

  const handleSizeChange = useCallback(data => {
    const newSize = parseInt(data);
    setPageSize(newSize);
    onSizeChange && onSizeChange(newSize);
  }, []);

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        disabled={!hasPreviousPage}
        icon={<i className="bx bx-chevron-left"></i>}
        onClick={handlePreviousPage}
      />
      <Button
        className={classes.button}
        disabled={!hasNextPage}
        icon={<i className="bx bx-chevron-right"></i>}
        onClick={handleNextPage}
      />
      <Select showSearch className={classes.sizeSelect} onChange={handleSizeChange} defaultValue={`${pageSize} / page`}>
        {SIZE_OPTIONS.map(item => (
          <Option key={item} value={item}>{`${item} / page`}</Option>
        ))}
      </Select>
    </div>
  );
};

export default NGITablePagination;
