import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { CustomerAvailabilitySection } from "pages/dashboard/sections/CustomerAvailabilitySection";
import { CustomersStatsHeader } from "pages/dashboard/sections/CustomersStatsHeadr";
import { StatusSection } from "pages/dashboard/sections/StatusSection";
import { SourceSection } from "pages/dashboard/sections/SourceSection";
import { getTSRList } from "store/TSR/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
});

export const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTSRList());
  }, []);

  return (
    <div className="page-content">
      <CustomersStatsHeader />
      <Container fluid className={classes.container}>
        <CustomerAvailabilitySection />
        <StatusSection />
        <SourceSection />
      </Container>
    </div>
  );
};
