import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import infoIcon from "assets/images/svg/Info.svg";
import CustomLink from "components/Common/buttons/CustomLink";

export const unprocessedListColumns = onDiscard => [
  {
    title: "Customer Name",
    dataIndex: "customerFullName",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Customer ID",
    dataIndex: "customerId",
    render: id =>
      id.includes("%") ? (
        id
      ) : (
        <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
          {id}
        </CustomLink>
      ),
  },
  {
    title: "PAYEE PHONE NUMBER",
    dataIndex: "payeePhoneNumber",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Amount",
    dataIndex: "transactionAmount",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Receipt Number",
    dataIndex: "receiptNumber",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Duration",
    dataIndex: "transactionDuration",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "METER BARCODE",
    dataIndex: "meterNumber",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Processing Type",
    dataIndex: "processingType",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "DATE",
    dataIndex: "transactionDateTime",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "",
    key: "action",
    render: (text, record) => (
      <ButtonGroup tooltipTitle="Discard" icon={infoIcon} handleClick={() => onDiscard(record)} />
    ),
  },
];
