import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container } from "reactstrap";
import { routineTaskColumns } from "./routineTaskColumns";
import RoutineTaskFilters from "components/Common/filters/operations/routineTaskFilter";
import { openRoutine, retrieveAllTaskRoutines } from "utils/api";
import { successMessage, defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { prepareData } from "pages/operations/routines/utils";
import NGITable from "components/Common/NGITable";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { useHistory } from "react-router";

const RetrieveRoutineTasks = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const status = history.location.pathname.includes("suspended")
    ? "Suspended"
    : history.location.pathname.includes("close")
    ? "Close"
    : "Open";
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.ROUTINE_TASK[status]);
  const [isLoading, setLoader] = useState(false);
  const [routineList, setRoutineList] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState(null);

  const openConfirmationModal = useCallback(item => {
    setIsConfirmationOpen(true);
    setSelectedRoutine(item);
  }, []);

  const columns = useMemo(() => {
    const col = routineTaskColumns({ openRoutine: openConfirmationModal, status });
    if (
      userHasOneOfRoles([
        USER_ROLES.FINANCE,
        USER_ROLES.TRAINING,
        USER_ROLES.TSS,
        USER_ROLES.IT_OPERATIONS,
        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        USER_ROLES.A_SM,
      ]) ||
      status !== "Close"
    )
      col.pop();
    return col;
  }, [status, openConfirmationModal]);

  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  useEffect(() => {
    handleOnFilter(!isEmpty(filters) ? { ...filters, status } : { status: status || "Open" });
    dispatch(getDictionary(DICTIONARY_TYPES.AGENT_ROLES));
  }, [status]);

  const onOpenRoutine = useCallback(() => {
    setLoader(true);
    openRoutine(selectedRoutine)
      .then(() => {
        successMessage(`Routine is opened for ${selectedRoutine?.assigneeName}.`);
        closeConfirmationModal();
        setLoader(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [selectedRoutine]);

  const handleOnFilter = filterOptions => {
    setLoader(true);
    const options = AUTH_USER?.roles?.includes(USER_ROLES.SYSTEM_FULL_ADMINS)
      ? { ...filterOptions }
      : { depot: AUTH_USER?.depotName, ...filterOptions };
    setFilters(filterOptions);

    retrieveAllTaskRoutines(options)
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items.length === 0) {
          warnMessage("No routines have been found");
        }
        const groupedData = prepareData(res.data.result.items);
        setRoutineList({ result: { ...res.data.result, items: groupedData } } || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <RoutineTaskFilters
              defaultFilter={filters}
              status={status}
              onFilter={handleOnFilter}
              isLoading={isLoading}
            />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.ROUTINE_TASK[status]}
              isLoading={isLoading}
              columns={columns}
              data={routineList}
              updateList={handleOnFilter}
            />
          </Col>
          {isConfirmationOpen && (
            <ConfirmationModal
              description={"Are you sure you want to open routine?"}
              onCancel={closeConfirmationModal}
              onConfirm={onOpenRoutine}
              loading={isLoading}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default RetrieveRoutineTasks;
