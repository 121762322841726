import React from "react";
import classname from "classnames";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";
import { Tooltip } from "@material-ui/core";

const CustomButton = ({
  text,
  roles,
  hideRoles,
  icon,
  iconImg,
  size,
  color,
  type,
  disabled,
  className = "",
  ...props
}) => {
  if (!userHasAdminRole()) {
    if (userHasOneOfRoles(hideRoles)) {
      return null;
    }
    if (roles && !userHasOneOfRoles(roles)) {
      return null;
    }
  }

  return (
    <Tooltip placement="bottom" title={text} color="#444444">
      <>
        <button
          {...props}
          disabled={!!disabled}
          title={text}
          className={classname(
            "button-general",
            className,
            size === "small" && "button-small",
            type === "round" && "button-round",
            type === "success" && "button-success",
            type === "error" && "button-error",
            color === "outlined" && "button-outlined",
            color === "outlined-white" && "button-outlined-white",
            disabled && "button-disabled"
          )}
        >
          {!!iconImg && <img className="image-icon-btn" src={iconImg} alt="button-image" />}
          {!!icon && <span className="button-icon">{icon}</span>}
          {text}
        </button>
      </>
    </Tooltip>
  );
};
export default CustomButton;
