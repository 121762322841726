import React, { useCallback, useEffect, useState } from "react";
import { linkedAccountsColumns } from "pages/customer-management/customerDetails/constants";
import NGITable from "components/Common/NGITable";
import { retrieveLinkedAccounts } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";

const LinkedAccountsTab = ({ customer }) => {
  const [isLoading, setLoading] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState([]);

  const handleUpdateLinkedAccounts = useCallback(
    (filters = {}) => {
      setLoading(true);
      retrieveLinkedAccounts({ ...filters }, customer.id)
        .then(({ data }) => {
          setLoading(false);
          setLinkedAccounts(data);
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoading(false);
        });
    },
    [customer]
  );

  useEffect(() => {
    handleUpdateLinkedAccounts();
  }, []);

  return (
    <NGITable
      isLoading={isLoading}
      updateList={handleUpdateLinkedAccounts}
      columns={linkedAccountsColumns(customer.id)}
      data={linkedAccounts}
    />
  );
};

export default LinkedAccountsTab;
