import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { FirmwaresSettingsSelector } from "store/meter-management/configuration/firmware/selectors";
import { getFirmwares } from "store/meter-management/configuration/firmware/actions";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { updateOTAFirmware } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { MCU_TYPES } from "pages/meter-management/meters/variables";

const useStyles = makeStyles({
  root: { width: "300px!important" },
  modal: {
    width: "400px!important",
  },

  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,

      "&>b": {
        fontWeight: 600,
      },
    },
  },
  title: {
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
    marginTop: 20,
  },
});

const ALLOWED_STATUSES = ["Published", "Draft"];

const UpdateFirmwareModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { filters } = useStoreFilters(FILTER_PAGE.METER);
  const [loading, setLoader] = useState(false);
  const [selectedMcuType, setSelectedMcuType] = useState(null);
  const classes = useStyles();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { firmwares } = useSelector(FirmwaresSettingsSelector);
  useEffect(() => {
    dispatch(getFirmwares());
  }, []);

  const isPX = useMemo(() => activeItem.type === "PX-MQTT", [activeItem]);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const onMcuTypeChanged = useCallback(value => {
    setSelectedMcuType(value);
    form.setFieldValue("firmware", null);
  }, []);

  const handleSubmit = useCallback(() => {
    setLoader(true);
    const values = form.getFieldsValue();
    const preparedData = {
      newFirmware: values.firmware,
      meterBarcode: activeItem.barcode,
      ...(isPX && {
        mcuType: values.mcuType,
      }),
    };
    closeConfirmationModal();
    updateOTAFirmware(preparedData)
      .then(() => {
        closeConfirmationModal();
        onClose();
        warnMessage(`OTA request successfully submitted`);
        onUpdate(filters);
        setLoader(false);
      })
      .catch(err => {
        closeConfirmationModal();
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [form, isPX]);

  const filteredFirmwares = useMemo(
    () =>
      firmwares.filter(
        item =>
          item.meterType === activeItem.type &&
          (isPX
            ? item.microcontrollerType === activeItem[MCU_TYPES[selectedMcuType]]
            : item.microcontrollerType === activeItem.controller) &&
          ALLOWED_STATUSES.includes(item.status)
      ),
    [activeItem, selectedMcuType, isPX]
  );

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Update Meter Firmware
            <span>Update current meter firmware</span>
            {isPX && (
              <span>
                Primary: <b>{activeItem.controller}</b>; Secondary: <b>{activeItem.controller2}</b>
              </span>
            )}
          </div>
        </div>
      }
      className={classes.modal}
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={loading}>
        <>
          <NGIForm name="change-meter-type" form={form} isUpdate={true} onFinish={openConfirmationModal}>
            <Title level={5}>New Firmware:</Title>
            <Form.Item name="firmware" rules={[{ required: true, message: "Please select new firmware" }]}>
              <NGISelect
                showSearch
                disabled={isPX && !selectedMcuType}
                className={classes.root}
                placeholder="Please select new firmware"
              >
                {!!filteredFirmwares &&
                  filteredFirmwares.map(item => (
                    <Option value={item.version} key={item.version}>
                      {item.version}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>

            {isPX && (
              <>
                <Title className={classes.title} level={5}>
                  MCU type:
                </Title>
                <Form.Item name="mcuType">
                  <NGISelect
                    showSearch
                    value={selectedMcuType}
                    onChange={onMcuTypeChanged}
                    className={classes.root}
                    placeholder="Please select MCU type"
                  >
                    {["Primary", "Secondary"].map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                  </NGISelect>
                </Form.Item>
              </>
            )}

            <Space>
              <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
              <CustomButton type="primary" text="Update" size="small" />
            </Space>
          </NGIForm>
          {isConfirmationOpen && (
            <ConfirmationModal
              loading={loading}
              description={"Are you sure you want to change the firmware?"}
              onCancel={closeConfirmationModal}
              onConfirm={handleSubmit}
            />
          )}
        </>
      </Spin>
    </Modal>
  );
};

export default UpdateFirmwareModal;
