import React from "react";
import { customDateFormatWithTime, statusFormatter, textFormatter } from "components/Common/formatter";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import teamIcon from "assets/images/svg/team-white.svg";

export const depotsListColumns = (hide, openDepotTeams) => [
  {
    title: "DEPOT NAME",
    dataIndex: "name",
    render: cellContent => {
      if (hide) return textFormatter(cellContent);
      return (
        <NGITooltip title={`${cellContent} details`}>
          <CustomLink to={`/depots/depot?name=${cellContent}`}>{cellContent}</CustomLink>
        </NGITooltip>
      );
    },
  },
  {
    title: "DEPOT STATUS",
    dataIndex: "status",
    render: row => statusFormatter(row),
  },
  {
    title: "DISTRICT",
    dataIndex: "",
    render: row => textFormatter(row?.location?.district),
  },
  {
    title: "DATE",
    dataIndex: "operationalizationDate",
    render: rowData => customDateFormatWithTime(rowData),
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroup
        tooltipTitle="View Teams"
        icon={teamIcon}
        handleClick={() => {
          openDepotTeams(row);
        }}
      />
    ),
  },
];
