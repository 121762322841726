import React, { useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { customDateFormatWithTime } from "components/Common/formatter";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import { MeterReportsSelector } from "store/meter-management/logs/reports/selectors";
import { getCsrp } from "store/meter-management/logs/reports/actions";
import { Spin } from "antd";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  rootBox: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "100%",
  },
  mapContainer: {
    width: "100%",
  },
  column: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "25%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "51%",
  },
});

const CookingSessionDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { barcode, number } = useParams();
  const { csrp } = useSelector(MeterReportsSelector);
  const activeSession = csrp[number];

  useEffect(() => {
    dispatch(getCsrp({ meterBarcode: barcode }));
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Spin spinning={!csrp}>
          <>
            <Header
              meterBarcode={activeSession?.meterBarcode}
              cookingTime={activeSession?.cookingTime}
              customerId={activeSession?.customerId}
              depot={activeSession?.depot}
            />
            <div className={classes.root}>
              <Card className={classes.container}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.rootBox}>
                    <div className={classes.informationContainer}>
                      <div className={classes.column}>
                        <div className={classes.title}>Card Id: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.cardId}</div>
                        <div className={classes.title}>Sequence No: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.csrpSequenceNo}</div>
                        <div className={classes.title}>Start Credit: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.startCredit}</div>
                        <div className={classes.title}>End Credit: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.endCredit}</div>
                        <div className={classes.title}>Start Time: </div>
                        <div className={classes.data}>
                          {customDateFormatWithTime(activeSession?.cookingSession?.startTime)}
                        </div>
                        <div className={classes.title}>End Time: </div>
                        <div className={classes.data}>
                          {customDateFormatWithTime(activeSession?.cookingSession?.endTime)}
                        </div>
                        <div className={classes.title}>Gas Remaining: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.gasRemaining}</div>
                        <div className={classes.title}>Start Cumulative Mass: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.startCumulativeMass}</div>
                        <div className={classes.title}>End Cumulative Mass: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.endCumulativeMass}</div>
                        <div className={classes.title}>End Reason: </div>
                        <div className={classes.data}>{activeSession?.cookingSession?.endReason}</div>
                        <div className={classes.title}>Tenant: </div>
                        <div className={classes.data}>{activeSession?.tenant}</div>
                        <div className={classes.title}>ID: </div>
                        <div className={classes.data}>{activeSession?.id}</div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </>
        </Spin>
      </Container>
    </div>
  );
};

export default CookingSessionDetails;
