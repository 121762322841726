import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import CustomButton from "components/Common/buttons/CustomButton";
import { generateXLS, squareKmFormatter } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 10,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
      maxWidth: 200,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
    textTransform: "uppercase",
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
  inactive: {
    backgroundColor: "#c31d17",
  },
});

const Header = ({ depot }) => {
  const classes = useStyles();
  const { name, status, location, areaSize, boundaries } = depot;

  const handleDownloadCSV = useCallback(() => {
    const title = ["Longitude", "Latitude", `Area: ${areaSize} km2`];

    const normalizedData = [title, ...boundaries?.coordinates[0]];
    generateXLS(normalizedData, `${name} Polygons`, "csv", true);
  }, [boundaries]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/depots/list"}>Back</BackButtonLink>

        <div className={classes.nameContainer}>
          <span>{name}</span>
          <div className={classnames(classes.status, status === "inactive" && classes.inactive)}>{status}</div>
        </div>
      </div>

      <div className={classes.rightContainer}>
        <CustomButton type="primary" onClick={handleDownloadCSV} text="Export Polygons" />
        <div className={classes.mainInfo}>
          {!!areaSize && (
            <div className={classes.section}>
              <div>
                <div className={classes.title}>Area</div>
                <div className={classes.data}>{squareKmFormatter(areaSize)}</div>
              </div>
            </div>
          )}
          <div className={classes.divider} />
          <div className={classes.section}>
            <div>
              <div className={classes.title}>County</div>
              <div className={classes.data}>{location?.county}</div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <div>
              <div className={classes.title}>Sub-county</div>
              <div className={classnames(classes.data, classes.overflowText)} title={location?.subCounty}>
                {location?.subCounty}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
