import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

export const searchOptions = [
  {
    value: "transactionId",
    title: "Receipt ID",
  },
  {
    value: "status",
    title: "Status",
  },
  {
    value: "customerId",
    title: "Customer ID",
    render: id => (
      <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
        {id}
      </CustomLink>
    ),
  },
  {
    value: "reason",
    title: "Reason",
  },
  {
    value: "date",
    title: "Creation Date",
  },
  {
    value: "createdBy",
    title: "Creator",
  },
];

export const availableStatuses = ["Pending", "Posted", "Rejected"];
export const dateFormat = "DD/MM/YYYY";

export const TYPES = {
  INSTALLATION_PAYMENT_PROCESSING_FAILURE: "Installation payment processing failure",
  STANDARD_PAYMENT_TRANSACTION_PROCESSING_FAILURE: "Standard payment transaction processing failure",
  REVERSAL_CREDIT_ERROR: "Reversal / credit error",
  METER_MALFUNCTION: "Meter malfunction",
  PROMOTION_AND_BONUS: "Promotion and bonus",
  CUSTOMER_LEDGER_ADJUSTMENT: "Customer Ledger adjustment",
  REFUND_TO_CUSTOMERS: "Refund to customers",
  CREDIT_TRANSFER: "Credit transfer",
  MISSING_SAFARICOM_TRANSACTIONS: "Missing Safaricom Transactions",
  FULL_REVERSAL: "Full Reversal",
  MISSING_TRANSACTIONS: "Missing Transactions",
};

export const showFieldsMapping = {
  [TYPES.INSTALLATION_PAYMENT_PROCESSING_FAILURE]: ["correctMode", "transactionId"],
  [TYPES.STANDARD_PAYMENT_TRANSACTION_PROCESSING_FAILURE]: ["transactionId", "date"],
  [TYPES.REVERSAL_CREDIT_ERROR]: ["amount", "transactionId"],
  [TYPES.METER_MALFUNCTION]: ["amount", "files"],
  [TYPES.PROMOTION_AND_BONUS]: ["amount"],
  [TYPES.CUSTOMER_LEDGER_ADJUSTMENT]: ["transactionMode", "transactionId"],
  [TYPES.REFUND_TO_CUSTOMERS]: [],
  [TYPES.CREDIT_TRANSFER]: ["transactionId"],
  [TYPES.MISSING_SAFARICOM_TRANSACTIONS]: [],
  [TYPES.MISSING_TRANSACTIONS]: [],
  [TYPES.FULL_REVERSAL]: ["transactionId"],
};

export const AMOUNT_TITLE = {
  [TYPES.REVERSAL_CREDIT_ERROR]: "Amount including TAX (Negative only)",
  [TYPES.METER_MALFUNCTION]: "Amount",
  [TYPES.PROMOTION_AND_BONUS]: "Amount excluding TAX (Positive only)",
};

export const AMOUNT_VALIDATION = {
  [TYPES.REVERSAL_CREDIT_ERROR]: [
    {
      message: "Should be a number. Allowed 2 digits after comma",
      pattern: /^-?\d+(\.\d{1,2})?$/,
    },
  ],
  [TYPES.METER_MALFUNCTION]: [
    {
      message: "Should be a number. Allowed 2 digits after comma",
      pattern: /^-?\d+(\.\d{1,2})?$/,
    },
  ],
  [TYPES.PROMOTION_AND_BONUS]: [
    {
      message: "Should be a number. Allowed 2 digits after comma",
      pattern: /^\d+(\.\d{1,2})?$/,
    },
  ],
};

export const disabledFieldsForType = [TYPES.REFUND_TO_CUSTOMERS, TYPES.MISSING_SAFARICOM_TRANSACTIONS];

export const BULK_REQUEST_TYPES = [
  TYPES.REVERSAL_CREDIT_ERROR,
  TYPES.PROMOTION_AND_BONUS,
  TYPES.MISSING_SAFARICOM_TRANSACTIONS,
  TYPES.MISSING_TRANSACTIONS,
];

export const BULK_REQUEST_VALIDATION = {
  [TYPES.REVERSAL_CREDIT_ERROR]: ["customerId", "amount", "transactionId"],
  [TYPES.PROMOTION_AND_BONUS]: ["customerId", "amount"],
  [TYPES.MISSING_SAFARICOM_TRANSACTIONS]: [
    "customerId",
    "billReferenceNumber",
    "transactionId",
    "transactionDate",
    "amount",
  ],
  [TYPES.MISSING_TRANSACTIONS]: [
    "customerId",
    "billReferenceNumber",
    "transactionId",
    "transactionDate",
    "amount",
    "provider",
  ],
};

export const HIDE_SINGLE_TYPES = [TYPES.MISSING_SAFARICOM_TRANSACTIONS, TYPES.MISSING_TRANSACTIONS];
