import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Form, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";

const { Option } = Select;

const AccessoryFilters = props => {
  const [form] = Form.useForm();
  const { onFilter, accessoryList } = props;
  const [activeFilterType, setActiveFilterType] = useState("Sensor");

  const handleFilter = () => {
    const filter = { accessoryType: activeFilterType };
    onFilter(filter);
  };

  const handleFilterType = useCallback(e => {
    setActiveFilterType(e);
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline">
        <Form.Item name="accessoryType">
          <Select
            data-testid={"selectAccessoryValue"}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            size={"default"}
            placeholder={"Filter By Accessory Type"}
            value={activeFilterType}
            defaultValue={activeFilterType}
            onChange={handleFilterType}
          >
            {accessoryList.length > 0
              ? accessoryList.map(
                  item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ),
                  this
                )
              : null}
          </Select>
        </Form.Item>

        <CustomButton type="primary" htmlType="submit" text="Filter" size="small" onClick={handleFilter} />
      </Form>
    </div>
  );
};

AccessoryFilters.propTypes = {
  accessoryList: PropTypes.array,
  onFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default AccessoryFilters;
