import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import MetersFilter from "./metersFilter";
import { retrieveMeters } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import UploadMeterModal from "./uploadModal";

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
const meterInformation = () => {
  const [isMeterLoading, setMeterLoader] = useState(false);
  const [meterList, setMeterList] = useState(null);
  const [filteredMeterList, setFilteredMeterList] = useState(meterList);
  const [dataHasNextPage, setDataHasNextPage] = useState(false);
  const [dataHasPreviousPage, setDataHasPreviousPage] = useState(false);
  const [currentFilterOptions, setCurrentFilterOptions] = useState({});
  const [dataEndCursor, setDataEndCursor] = useState(null);
  const [dataStartCursor, setDataStartCursor] = useState(null);
  const [isUploadModalMeterVisible, setIsUploadModalMeterVisible] = useState(false);
  const closeUploadMeterModal = () => setIsUploadModalMeterVisible(false);
  const openUploadMeterModal = () => setIsUploadModalMeterVisible(true);

  useEffect(() => {
    setFilteredMeterList(meterList);
  }, [meterList]);

  useEffect(() => {
    handleMeterFilter();
  }, []);

  const handleMeterFilter = async (filterOptions = {}) => {
    setMeterLoader(true);
    const options = {
      ...filterOptions,
    };
    setCurrentFilterOptions(options);
    await retrieveMeters(options)
      .then(async res => {
        const {
          data: {
            result: {
              items,
              meta: { pageData },
            },
          },
        } = res;
        if (items === 0) {
          warnMessage(`No ${!!filterOptions.status ? filterOptions.status : ""} meters were found`);
          setMeterLoader(false);
          setMeterList(null);
          return;
        }
        setMeterList(items);
        const { hasNextPage, hasPreviousPage, startCursor, endCursor } = pageData;
        setDataEndCursor(endCursor);
        setDataStartCursor(startCursor);
        setDataHasNextPage(hasNextPage);
        setDataHasPreviousPage(hasPreviousPage);
        setMeterLoader(false);
        successMessage(`${!!filterOptions.status ? filterOptions.status : "All"} Meters Loaded Successfully`);
      })
      .catch(err => {
        setMeterLoader(false);
        setMeterList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const onNextPage = async () => {
    const options = { ...currentFilterOptions, endCursor: dataEndCursor };
    await handleMeterFilter(options);
  };
  const onPreviousPage = async () => {
    const options = { ...currentFilterOptions, endCursor: dataEndCursor, startCursor: dataStartCursor };
    await handleMeterFilter(options);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <MetersFilter handleFilter={handleMeterFilter} isLoading={isMeterLoading} openModal={openUploadMeterModal} />
        {/*<Col lg={12}>*/}
        {/*  {isMeterLoading ? (*/}
        {/*    <Card>*/}
        {/*      <CardBody>*/}
        {/*        <div className={"text-center"}>*/}
        {/*          <Spinner color={"primary"} />*/}
        {/*          <p>Loading data...</p>*/}
        {/*        </div>*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  ) : !!meterList?.length ? (*/}
        {/*    <B2cTable*/}
        {/*      enableSearch={false}*/}
        {/*      columns={meterListColumns()}*/}
        {/*      data={filteredMeterList}*/}
        {/*      hasNextpage={dataHasNextPage}*/}
        {/*      hasPreviousPage={dataHasPreviousPage}*/}
        {/*      onNextPage={onNextPage}*/}
        {/*      onPreviousPage={onPreviousPage}*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <Card>*/}
        {/*      <CardBody className="text-center">*/}
        {/*        <i className="bx bx-warning" />*/}
        {/*        <h5>No Meters available</h5>*/}
        {/*        <p className="text-muted">Please search to retrieve data.</p>*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  )}*/}
        {/*</Col>*/}
      </Container>
      {isUploadModalMeterVisible && (
        <UploadMeterModal isVisible={isUploadModalMeterVisible} onClose={closeUploadMeterModal} />
      )}
    </div>
  );
};

export default meterInformation;
