import React, { useCallback, useMemo, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { updateUserSector } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { isEqual } from "lodash";

const useStyles = makeStyles({
  modal: {
    "& .ant-modal-content .ant-modal-header": {
      padding: "25px 30px 0 !important",
    },
    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 30px !important",
    },
    "& .ant-modal-title": {
      borderBottom: "1px solid #00000014",
      paddingBottom: 15,
    },
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  fullWidth: {
    width: "50%!important",
  },
});

const ManageMembersModel = ({ onClose, teams, sector, TSRList, updateData }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [initialState, setInitialState] = useState({});
  const [form] = Form.useForm();

  const handleSave = useCallback(() => {
    const data = form.getFieldsValue();
    const needUpdate = Object.keys(data)
      .filter(item => initialState[item] !== data[item])
      .map(item => data[item]);

    setLoader(true);
    closeConfirmation();
    Promise.all(
      needUpdate.map(item =>
        updateUserSector({
          email: item,
          sector: sector.name,
        }).then(({ data: { result } }) => ({ ...result, ...item }))
      )
    )
      .then(() => {
        setTimeout(() => {
          setLoader(false);
          onClose();
          updateData();
        }, 1000);
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [form, sector, initialState]);

  const openConfirmation = useCallback(() => setIsOpen(true), []);
  const closeConfirmation = useCallback(() => setIsOpen(false), []);
  const onChange = useCallback(() => {
    const newValues = form.getFieldsValue();
    setDisabled(isEqual(initialState, newValues));
  }, [initialState, form]);

  const mappedTeams = useMemo(() => {
    const state = {};
    const newValue = teams.map(item => {
      item.member = sector?.users.find(user => user.team === item.teamType) || {};
      state[item.teamType] = item?.member?.email;
      return item;
    });
    form.setFieldsValue(state);
    setInitialState(state);
    return newValue;
  }, [form]);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithDescription}>
          <div>
            Manage <b>{sector.name}</b> Sector Members
          </div>
          <span>Manage members for {sector.name}</span>
        </div>
      }
      className={classes.modal}
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <Form onFieldsChange={onChange} name="members" form={form} onFinish={openConfirmation}>
          {mappedTeams?.map(item => {
            const list = TSRList.filter(tsr => tsr.team === item.teamType);
            return (
              <Col span={24} key={item.teamType}>
                <TitleWithDivider title={item.teamType} />
                <NGIFormItem name={item.teamType}>
                  <NGISelect
                    showSearch
                    defaultValue={item?.member?.email}
                    placeholder="Select TSR"
                    filterOption={(input, option) =>
                      (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {list.map(item => (
                      <Option key={item.id} value={item.email}>
                        {item.email}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            );
          })}
          <Space>
            <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton disabled={disabled} htmlType="submit" type="primary" size="small" text="Save" />
          </Space>
        </Form>
        {isOpen && (
          <ConfirmationModal
            onCancel={closeConfirmation}
            onConfirm={handleSave}
            description="Are you sure you want to save it?"
          />
        )}
      </Spin>
    </Modal>
  );
};

export default ManageMembersModel;
