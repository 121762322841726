import React, { useCallback, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { getUsersList, retrieveSurveysListByIdOrName, retrieveTaskDetails } from "utils/api";
import { Spin } from "antd";
import Header from "pages/operations/taskDetails/Header";
import DataTabs from "pages/operations/taskDetails/DataTabs";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import NotFoundContainer from "components/Common/NotFoundContainer";

const TaskDetails = () => {
  const { id } = useParams();
  const [task, setTask] = useState(null);
  const [isLoading, setLoader] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [questionnaire, setQuestionnaire] = useState({});

  const updateTask = useCallback(() => {
    setTask(null);
    retrieveTaskDetails(id)
      .then(({ data }) => {
        setTask(data.result);
        if (data?.result?.survey?.id) {
          retrieveSurveysListByIdOrName({ id: data?.result?.survey?.id }).then(res => {
            setQuestionnaire(res.data.result);
          });
        }
      })
      .catch(error => {
        setLoader(false);
        if (error.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
  }, [id]);

  useEffect(() => {
    updateTask();
  }, []);

  useEffect(() => {
    getUsersList({
      role: [USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS].join(),
      depot: AUTH_USER?.depotName,
      pageSize: 5000,
    }).then(res => {
      setAgentList(res?.data?.result?.items);
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              {task && (
                <>
                  <Header task={task} setLoader={setLoader} agentList={agentList} updateTask={updateTask} />
                  <DataTabs task={task} questionnaire={questionnaire} />
                </>
              )}
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default TaskDetails;
