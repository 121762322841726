export const ORDER_TYPES = ["Inbound", "Outbound"];

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const CONDITIONS = {
  Inbound: {
    Cylinder: ["Full"],
    Meter: ["Ready for Market", "Healthy"],
    Stove: ["New", "Refurbished"],
  },
  Outbound: {
    Cylinder: ["Full", "Ready for Filling"],
    Meter: ["Faulty", "Ready for Market", "Healthy"],
    Stove: ["Damaged", "New", "Refurbished"],
  },
};

export const ASSET_TYPES = ["Cylinder", "Meter", "Stove"];
