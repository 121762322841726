import React, { useCallback, useState } from "react";
import { Form, Select, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";
import { dateFormat } from "pages/TAM/TAMRequests/constants";
import { makeStyles } from "@material-ui/core/styles";
const { RangePicker } = DatePicker;

const { Option } = Select;
const filterOptions = [
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Sent Date",
    value: "sentDate",
  },
  {
    name: "Created Date",
    value: "createdDate",
  },
];

export const status = ["Draft", "Scheduled", "In Progress", "Completed", "Canceled"];

const useStyles = makeStyles({
  root: { width: "250px!important" },
});
const SMSBulkFilter = ({ onFilter }) => {
  const classes = useStyles();

  const [form] = Form.useForm();
  const [activeFilter, setActiveFilter] = useState(null);

  const handleFilterChange = useCallback(
    item => {
      setActiveFilter(item);
      form.setFieldsValue({ value: null });
    },
    [form]
  );

  const onFinish = useCallback(values => {
    if (values?.type === "sentDate") {
      const filter = {};
      filter.sendAtFrom = getStartOfTheDay(moment(values?.value[0]));
      filter.sendAtTo = getEndOfTheDay(moment(values?.value[1]));
      onFilter(filter);
      return;
    }
    if (values?.type === "createdDate") {
      const filter = {};
      filter.createdAtFrom = getStartOfTheDay(moment(values?.value[0]));
      filter.createdAtTo = getEndOfTheDay(moment(values?.value[1]));
      onFilter(filter);
      return;
    }
    !(values.value && !values.type) && onFilter(values.type ? { [values.type]: values.value?.trim() } : {});
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <Form.Item name="type">
          <Select
            tokenSeparators={[","]}
            onChange={handleFilterChange}
            size={"default"}
            placeholder={"Select type"}
            allowClear
            showSearch
          >
            {filterOptions.map(item => (
              <Option key={`${item.name}-option`} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {activeFilter === "status" && (
          <Form.Item name="value">
            <Select showSearch size="default" placeholder="Select status" allowClear>
              {status.map(item => (
                <Option key={`${item}-option`} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {(activeFilter === "sentDate" || activeFilter === "createdDate") && (
          <Form.Item
            name="value"
            rules={[{ required: true, message: "Please select date range" }]}
            className={classes.root}
          >
            <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
          </Form.Item>
        )}

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default SMSBulkFilter;
