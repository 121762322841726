import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import meterIcon from "assets/images/svg/meater.svg";
import doneIcon from "assets/images/svg/Done.svg";
import { RESOLUTION_TYPES, RESOLUTIONS } from "pages/meter-management/awaitingTestingMeters/variables";
import classnames from "classnames";

const useStyles = makeStyles({
  container: {
    padding: 35,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    position: "relative",
  },
  statusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    width: 36,
    height: 36,
    borderRadius: 5,
    position: "absolute",
    bottom: -16,
    left: 32,
  },
  Certified: {
    background: "linear-gradient(180deg, #24DF58 0%, #17C447 100%)",
  },
  Decommission: {
    background: "linear-gradient(180deg, #FF2E00 0%, #FF0000 100%);",
  },
  title: {
    marginTop: 20,
    fontWeight: 700,
    fontSize: 18,
  },
  description: {
    marginTop: 20,
    fontWeight: 500,
    fontSize: 13,
  },
});

const MeterResolutionConfirmation = ({ resolution }) => {
  const classes = useStyles();

  const icon = useMemo(() => {
    if (resolution === RESOLUTIONS.CERTIFIED) return doneIcon;
  }, [resolution]);

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={meterIcon} alt="meter" />
        <div className={classnames(classes.statusIcon, classes[resolution])}>
          <img src={icon} alt={resolution} />
        </div>
      </div>
      <div className={classes.title}>{RESOLUTION_TYPES[resolution].title}</div>
      <div className={classes.description}>{RESOLUTION_TYPES[resolution].description}</div>
    </div>
  );
};

export default MeterResolutionConfirmation;
