import { Col, Form, Input, Space, Spin } from "antd";
import { DEFAULT_UNIT_PRICE } from "./variables";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { adjustDataAPI, retrieveMeterDetails } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import CustomButton from "components/Common/buttons/CustomButton";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const AdjustData = ({ onClose, meterId }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  useEffect(() => {
    setLoader(true);
    retrieveMeterDetails(meterId)
      .then(async res => {
        const {
          data: { result },
        } = res;
        setDefaultData(prevState => ({
          ...prevState,
          meterType: result.type,
          customerId: !!result.customerId ? result.customerId : "0000000",
          unitPrice: DEFAULT_UNIT_PRICE,
          cardBarcode: 0,
          creditRemaining: 0,
          endCumulativeMass: 0,
          cylinderSerial: 0,
          endCumulativeCredit: 0,
        }));
        setLoader(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, [meterId]);
  form.setFieldsValue(defaultData);

  const onSave = async data => {
    const formattedData = {
      ...data,
      cardBarcode: data.cardBarcode.toString(),
      customerId: data.customerId.toString(),
      cylinderSerial: data.cylinderSerial.toString(),
      gasRemaining: Number.parseInt(data.gasRemaining),
      creditRemaining: Number.parseInt(data.creditRemaining),
      unitPrice: Number.parseInt(data.unitPrice),
      endCumulativeMass: Number.parseInt(data.endCumulativeMass),
      endCumulativeCredit: Number.parseInt(data.endCumulativeCredit),
    };
    setLoader(true);
    await adjustDataAPI({ id: meterId, payload: formattedData })
      .then(() => {
        successMessage(`Data was saved successfully`);
        onClose();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onSave}>
        <Col span={24}>
          <Title level={5}>{"Meter Type"}</Title>
          <Form.Item name="meterType">
            <Input
                id="meterType"
                disabled={true}
                value={defaultData.meterType}
                pattern={inputTextPattern}
                title={inputPatternTitle}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"Customer ID"}</Title>
          <Form.Item name="customerId" rules={[{ required: true, message: "Please enter Customer ID" }]}>
            <Input
                pattern={inputTextPattern}
                title={inputPatternTitle}
                id="customerId"
                value={defaultData.customerId}
                onChange={e => setDefaultData(prevState => ({ ...prevState, customerId: e.target.value }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"Card barcode"}</Title>
          <Form.Item name="cardBarcode">
            <Input
                pattern={inputTextPattern}
                title={inputPatternTitle}
                id="cardBarcode"
                value={defaultData.cardBarcode}
                onChange={e => setDefaultData(prevState => ({ ...prevState, cardBarcode: e.target.value }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"Gas remaining"}</Title>
          <Form.Item name="gasRemaining" rules={[{ required: true, message: "Please enter Gas remaining" }]}>
            <Input id="gasRemaining" type={"number"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"Credit remaining"}</Title>
          <Form.Item name="creditRemaining">
            <Input id="customerId" type={"number"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"Unit price"}</Title>
          <Form.Item name="unitPrice">
            <Input
              type={"number"}
              id="unitPrice"
              value={defaultData.unitPrice}
              onChange={e => setDefaultData(prevState => ({ ...prevState, unitPrice: e.target.value }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"End cumulative mass"}</Title>
          <Form.Item name="endCumulativeMass">
            <Input
              type={"number"}
              id="endCumulativeMass"
              value={defaultData.endCumulativeMass}
              onChange={e => setDefaultData(prevState => ({ ...prevState, endCumulativeMass: e.target.value }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"End cumulative credit"}</Title>
          <Form.Item name="endCumulativeCredit">
            <Input
              type={"number"}
              id="endCumulativeCredit"
              value={defaultData.endCumulativeCredit}
              onChange={e => setDefaultData(prevState => ({ ...prevState, endCumulativeCredit: e.target.value }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>{"Cylinder serial"}</Title>
          <Form.Item name="cylinderSerial">
            <Input
                pattern={inputTextPattern}
                title={inputPatternTitle}
                id="cylinderSerial"
                value={defaultData.cylinderSerial}
                onChange={e => setDefaultData(prevState => ({ ...prevState, cylinderSerial: e.target.value }))}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item>
            <Col>
              <Space>
                <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
                <CustomButton type="primary" htmlType="submit" size="small" text="Save" />
              </Space>
            </Col>
          </Form.Item>
        </Col>
      </Form>
    </Spin>
  );
};

export default AdjustData;
