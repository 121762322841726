import React, { useCallback, useEffect, useState } from "react";
import { Col, Container } from "reactstrap";
import NGITable from "components/Common/NGITable";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { downloadBulkSMSCsv, downloadFileData, getSmsBulkList } from "utils/api";
import { smsBulkColumns } from "pages/SMS-management/SMS-bulk/smsBulkColumns";
import SMSBulkFilter from "pages/SMS-management/SMS-bulk/SMSBulkFilter";
import CustomButton from "components/Common/buttons/CustomButton";
import NewBulkSMSModal from "pages/SMS-management/SMS-bulk/NewBulkSMS/NewBulkSMSModal";
import { makeStyles } from "@material-ui/core/styles";
import { generateXLS } from "utils/helpers/functions";
import { getNormalizedCSVData } from "pages/TAM/TAMRequests/utils";
import { USER_ROLES } from "utils/constants";

const useStyles = makeStyles({
  overflowText: {
    "&>div": {
      overflow: "hidden",
      width: 100,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
});
const SMSBulk = () => {
  const classes = useStyles();

  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOnFilter = (filterOptions = {}) => {
    setLoading(true);

    getSmsBulkList({ ...filterOptions })
      .then(res => {
        setList(res?.data || null);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  };

  const onOpenModal = useCallback(() => setIsOpen(true), []);
  const onCloseModal = useCallback(() => setIsOpen(false), []);

  const onDownload = useCallback((filename, title) => {
    setLoading(true);
    downloadBulkSMSCsv(filename)
      .then(({ data }) => {
        downloadFileData(data.result)
          .then(res => generateXLS(getNormalizedCSVData(res.data), title, "csv", true))
          .catch(error => {
            defaultNgiErrorMethod(error);
          });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  useEffect(() => {
    handleOnFilter();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="filters-wrapper">
          <div className="filter-buttons">
            <CustomButton
              icon={<i className="bx bx-plus-circle" />}
              onClick={onOpenModal}
              hideRoles={[USER_ROLES.IT_OPERATIONS]}
              type="primary"
              text="Create new bulk sms"
            />
          </div>
          <SMSBulkFilter onFilter={handleOnFilter} />
        </div>
        <Col lg={12}>
          <NGITable
            updateList={handleOnFilter}
            isLoading={loading}
            columns={smsBulkColumns(onDownload, classes)}
            data={list}
          />
        </Col>
      </Container>

      {isOpen && <NewBulkSMSModal onClose={onCloseModal} updateList={handleOnFilter} />}
    </div>
  );
};

export default SMSBulk;
