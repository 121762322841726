import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import testIcon from "assets/images/svg/Test.svg";
import logIcon from "assets/images/svg/Log.svg";
import resetIcon from "assets/images/svg/Wrench.svg";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import deleteIcon from "assets/images/svg/Delete.svg";
import { USER_ROLES } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";

export const awaitingTestingMeterListColumns = ({
  openMeterTestResultModal,
  handleTriggerGetLogsReport,
  openConfirmationModal,
  openConfirmationDeleteModal,
}) => [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    render: cellContent =>
      cellContent !== "UNKNOWN" ? (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${cellContent}?t=serialNumber`}>
          {cellContent}
        </CustomLink>
      ) : (
        cellContent
      ),
    sort: true,
  },
  {
    title: "Condition",
    dataIndex: "condition",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    title: "Сollected date",
    dataIndex: "collectedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    dataIndex: "",
    title: "",
    width: "70px",
    render: (text, record) => (
      <>
        {record?.condition === "Awaiting Testing From Market" && record?.type?.includes("MQTT") ? (
          <ButtonGroupContainer>
            <ButtonGroup
              tooltipTitle="Test Meter"
              icon={testIcon}
              hideRoles={[USER_ROLES.IT_OPERATIONS]}
              handleClick={() => openMeterTestResultModal(record)}
            />
            <ButtonGroup
              tooltipTitle="Get Report"
              icon={logIcon}
              hideRoles={[USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD]}
              handleClick={() => {
                handleTriggerGetLogsReport(record.id);
              }}
            />
            <ButtonGroup
              tooltipTitle="Reset Parameters"
              icon={resetIcon}
              hideRoles={[USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD]}
              handleClick={() => {
                openConfirmationModal(record.id);
              }}
            />
          </ButtonGroupContainer>
        ) : (
          <ButtonGroupContainer>
            <ButtonGroup
              hideRoles={[USER_ROLES.IT_OPERATIONS]}
              tooltipTitle="Test Meter"
              icon={testIcon}
              handleClick={() => openMeterTestResultModal(record)}
            />
            {record?.condition === "Awaiting Testing New" && (
              <ButtonGroup
                tooltipTitle="Remove meter"
                hideRoles={[USER_ROLES.METER_ENGINEER, USER_ROLES.PRODUCT_TECHNICIANS]}
                icon={deleteIcon}
                handleClick={() => {
                  openConfirmationDeleteModal(record.id);
                }}
              />
            )}
          </ButtonGroupContainer>
        )}
      </>
    ),
  },
];
