import { getTrimmedObjectValues } from "utils/helpers/functions";

export const mandatoryFields = ["meterBarcode", "newFirmware", "mcuType"];

export const getFormattedFields = json =>
  json?.map(item =>
    getTrimmedObjectValues({
      meterBarcode: item?.meterBarcode?.toString(),
      newFirmware: item?.newFirmware?.toString(),
      mcuType: item?.mcuType?.toString(),
    })
  );

export const isFileNoError = data => data.length && data.every(item => handleValidate(item, mandatoryFields));

export const isAllFieldsHaveSameFirmware = data =>
  data.length &&
  data.every((item, index) => {
    const haveNextItem = index + 1 < data.length;
    if (haveNextItem) {
      return data[index + 1] && data[index + 1].newFirmware === item.newFirmware;
    }
    return true;
  });

const handleValidate = (item, requiredFields) => !requiredFields.some(field => !item[field]);
