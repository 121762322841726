import React, { useEffect } from "react";
import { Col, Form, Modal, Select, Space, Typography } from "antd";

import { useDispatch } from "react-redux";
import { createAccessory, updateAccessory } from "store/meter-management/configuration/accessories/actions";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIForm from "components/Common/form/NGIForm";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import NGIFormItem from "components/Common/form/NGIFormItem";

const { Title } = Typography;
const AccessoryModal = ({ isVisible, onClose, initialValues, accessoryTypes }) => {
  const Option = Select.Option;
  const [form] = Form.useForm();
  useEffect(() => {
    if (!!initialValues) form.setFieldsValue(initialValues);
    else form.resetFields();
  }, [form, initialValues]);

  const dispatch = useDispatch();

  const onSave = values => {
    if (!!initialValues) {
      const { version, ...normalizedValues } = values;
      dispatch(
        updateAccessory({
          ...normalizedValues,
        })
      );
    } else
      dispatch(
        createAccessory({
          ...values,
        })
      );
    onClose();
  };

  return (
    <Modal
      title={!!initialValues ? `Edit Accessory: ${initialValues.accessoryType}` : "Add Accessory"}
      open={isVisible}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <>
        <NGIForm
          name="AccessoryForm"
          isUpdate={!!initialValues}
          form={form}
          onFinish={onSave}
          data-testid={"AccessoryFormId"}
        >
          <Col>
            <Title data-testid={"typeTitleId"} level={5}>
              Name
            </Title>
            <NGIFormItem name="type" rules={[{ required: true, message: "Please enter the name" }]}>
              <NGIInput data-testid={"typeValueId"} disabled={!!initialValues} placeholder="Name" />
            </NGIFormItem>
          </Col>
          <Col span={24}>
            <Title data-testid={"descTitleId"} level={5}>
              Description
            </Title>
            <NGIFormItem name="description" rules={[{ required: true, message: "Please enter Description" }]}>
              <NGIInput data-testid={"descValueId"} placeholder="Please enter Description" />
            </NGIFormItem>
          </Col>
          <Col span={24}>
            <Title data-testid={"accessoryTypeTitleId"} level={5}>
              Accessory Type
            </Title>
            <NGIFormItem name="accessoryType" rules={[{ required: true, message: "Please select Accessory Type" }]}>
              <NGISelect
                showSearch
                data-testid={"accessoryTypeValueId"}
                disabled={!!initialValues}
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                placeholder={"Please select Accessory Type"}
              >
                {accessoryTypes.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </NGISelect>
            </NGIFormItem>
          </Col>
          <Col>
            <Space>
              <CustomButton onClick={onClose} size="small" type="primary" color="outlined" text="Cancel" />
              <CustomButton htmlType="submit" size="small" type="primary" text={!!initialValues ? "Update" : "Save"} />
            </Space>
          </Col>
        </NGIForm>
      </>
    </Modal>
  );
};

export default AccessoryModal;
