import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container } from "reactstrap";
import { typeColumns } from "./typeColumns";
import { errorMessage, NgiErrorMethod } from "components/Common/responses/message";
import { getAssetTypes } from "store/actions";
import { AssetTypesSelector } from "store/warehouse/configurations/asset-types/selectors";
import NGITable from "components/Common/NGITable";
import AddAssetTypeModal from "pages/warehouse/configurations/types/AddAssetTypeModal";
import CustomButton from "components/Common/buttons/CustomButton";
import { USER_ROLES } from "utils/constants";

const AssetTypes = () => {
  const dispatch = useDispatch();
  const [openAddModal, setOpenAddModal] = useState(false);
  const { assetTypes, assetTypesLoading, error } = useSelector(AssetTypesSelector);

  useEffect(() => {
    handleOnFilter();
  }, []);

  useEffect(() => {
    error && errorMessage(NgiErrorMethod(error));
  }, [error]);

  let dataSource;
  if (assetTypes && assetTypes.length > 0)
    dataSource = assetTypes.map((item, index) => ({ ...item, key: index.toString() }));

  const handleOnFilter = () => {
    dispatch(getAssetTypes());
  };

  const onOpenAddModal = useCallback(() => setOpenAddModal(true), []);
  const onCloseAddModal = useCallback(() => setOpenAddModal(false), []);

  const data = useMemo(() => ({ result: { items: dataSource } }), [dataSource]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                hideRoles={[
                  USER_ROLES.INTERNAL_AUDITOR,
                  USER_ROLES.GENERAL,
                  USER_ROLES.IT_OPERATIONS,
                  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                  USER_ROLES.FINANCE,
                  USER_ROLES.TSS,
                ]}
                type="primary"
                onClick={onOpenAddModal}
                icon={<i className="bx bx-plus-circle" />}
                text="New asset type"
              />
            </div>
          </div>
          <Col>
            <div className="p-2">
              <NGITable isLoading={assetTypesLoading} columns={typeColumns()} data={data} />
            </div>
          </Col>
        </Container>
        {openAddModal && (
          <AddAssetTypeModal isOpen={openAddModal} onCancel={onCloseAddModal} updateList={handleOnFilter} />
        )}
      </div>
    </>
  );
};

export default AssetTypes;
