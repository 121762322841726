import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";

export const maintenanceReportsListColumns = () => [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: (cellContent, row) => (
      <Tooltip placement="bottom" title={`${cellContent} details`}>
        <CustomLink to={`maintenance-report/${row.id}`}>{cellContent}</CustomLink>
      </Tooltip>
    ),
    sort: true,
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Good for use",
    dataIndex: "isGoodForUse",
    render: cellContent => textFormatter(cellContent === true ? "Yes" : "No"),
    sort: true,
  },
  {
    title: "Maintained by",
    dataIndex: "maintainedBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
    sort: true,
  },
  {
    title: "Maintained date",
    dataIndex: "maintainedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
];
