import React, { useCallback, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { filterOptions, statusList } from "pages/meter-management/configuration/simCardsReports/constants";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import { dateFormat } from "pages/stock-management/stockTransferOrders/constants";
import { makeStyles } from "@material-ui/core/styles";

const { Option } = Select;
const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  root: { width: "250px!important" },
});
const SimCardsReportsFilter = ({ handleFilter }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");

  const { getFilters } = useStoreFilters(FILTER_PAGE.SIM_CARD_REPORTS);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();

    if (values?.type === "date") {
      const filter = { pageSize };
      filter.timeStart = getStartOfTheDay(values?.date[0]);
      filter.timeEnd = getEndOfTheDay(values?.date[1]);
      handleFilter(filter);
    } else {
      !(values.value && !values.type) &&
        handleFilter(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
    }
  }, []);

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select showSearch onChange={onChangeFilter} tokenSeparators={[","]} placeholder="Select type" allowClear>
            {filterOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedFilter === "status" && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                {statusList.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        {(selectedFilter === "iccid" || selectedFilter === "createdBy") && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          </>
        )}
        {selectedFilter === "date" && (
          <>
            <div className="filter-divider" />
            <Form.Item
              name="date"
              rules={[{ required: true, message: "Please select date range" }]}
              className={classes.root}
            >
              <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
            </Form.Item>
          </>
        )}

        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default SimCardsReportsFilter;
