import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { bulkUpdateLeadsSector } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
});
const SectorTSRModal = ({ onClose, lead, leadTsr, sectors, onUpdate, TSRList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const list = TSRList.filter(tsr => tsr.team === "Growth");

  const handleCancel = useCallback(
    e => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    if (leadTsr?.team === "Growth") {
      form.setFieldsValue({
        tsr: lead?.technician,
      });
    }
  }, []);

  const handleSave = useCallback(() => {
    const { sectorName, tsr } = form.getFieldsValue();
    setIsLoading(true);
    const currentTsr = list.find(item => item.id === tsr);
    bulkUpdateLeadsSector({
      technician: currentTsr.email,
      technicianName: currentTsr.name,
      sector: sectorName,
      leads: [lead.id],
    })
      .then(() => {
        setTimeout(() => {
          successMessage("Data was updated successfully");
          setIsLoading(false);
          onUpdate();
          onClose();
        });
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setIsLoading(false);
      });
  }, [onUpdate, lead, list, form]);

  const isGrowth = useMemo(() => leadTsr?.team === "Growth", [leadTsr]);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithDescription}>
          Select Sector
          <span>Select sector and a TSR for this lead</span>
        </div>
      }
      closable={false}
      onCancel={handleCancel}
      footer={[]}
    >
      <>
        <Spin spinning={isLoading}>
          <Form name="sectorTsr" form={form} onFinish={handleSave}>
            <Col span={24}>
              <Title level={5}>Sector Name</Title>
              <NGIFormItem name="sectorName" rules={[{ required: true, message: "Please select TSR" }]}>
                <NGISelect
                  showSearch
                  placeholder="Select Sector"
                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                >
                  {sectors.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
            <Col span={24}>
              <Title level={5}>Growth TSR</Title>
              <NGIFormItem name="tsr" rules={[{ required: true, message: "Please select TSR" }]}>
                <NGISelect
                  showSearch
                  disabled={isGrowth}
                  placeholder="Select TSR"
                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                >
                  {list.map(item => (
                    <Option key={item.id} value={item.email}>
                      {item.email}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
            <Space>
              <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
              <CustomButton htmlType="submit" type="primary" size="small" text="Assign TSR" />
            </Space>
          </Form>
        </Spin>
      </>
    </Modal>
  );
};

export default SectorTSRModal;
