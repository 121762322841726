import React, { useCallback, useState } from "react";
import { CardBody } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import NGIInput from "components/Common/inputs/NGIInput";
import CustomButton from "components/Common/buttons/CustomButton";
import userPreviewIcon from "assets/images/svg/user-team.svg";
import { validateEmail } from "utils/helpers/functions";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { addRemoveMembers } from "utils/api";
import { STATE } from "pages/depot-management/teams/teamDetails/constants";
import { history } from "store";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGIPrompt from "components/Common/NGIPrompt";

const useStyles = makeStyles({
  cardBody: {
    padding: 0,
    width: "50%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    fontSize: 13,
    color: "black",
    fontWeight: 600,

    "&>span": {
      fontSize: 11,
      fontWeight: 300,
      color: "#666666",
    },
  },
  teamName: {
    marginTop: 10,
  },
  membersContainer: {
    margin: "20px 0",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 32,
    minWidth: 32,
    height: 32,
    marginRight: 8,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
      padding: 3,
    },
  },
  memberList: {
    marginTop: 15,
  },
  member: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    marginRight: 8,
    justifyContent: "space-between",
  },
  memberName: {
    display: "flex",
    alignItems: "center",
    color: "#444444",
    fontWeight: 600,
  },
  removeBtn: {
    color: "#FF0000",
    cursor: "pointer",

    "&:hover": {
      opacity: 0.7,
    },
  },
});

const Edit = ({ data }) => {
  const classes = useStyles();
  const [teamName, setTeamName] = useState("");
  const [memberList, setMemberList] = useState([
    ...(data?.members?.map(item => ({
      member: item,
      state: STATE.OLD,
    })) || []),
  ]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const onChange = useCallback(e => setTeamName(e.target.value), []);

  const onAdd = useCallback(() => {
    if (teamName) {
      setIsDirty(true);
      const name = teamName.trim();
      const existTeam = memberList.find(item => item.member === name);
      const isEmail = validateEmail(name);
      if (!existTeam && isEmail) {
        setMemberList(prevState => [...prevState, { member: name, state: STATE.ADD }]);
        setTeamName("");
      } else {
        if (existTeam?.state === STATE.REMOVE) {
          openConfirmationModal();
        } else {
          warnMessage(
            existTeam
              ? "Member has been already added"
              : "Please make sure that address is properly formed, firstname.lastname@domain.tld"
          );
        }
      }
    }
  }, [teamName, memberList]);

  const handleRemove = useCallback(e => {
    const id = e.currentTarget.dataset.id;
    setIsDirty(true);
    setMemberList(prevState =>
      prevState
        .filter(item => !(item.member === id && item.state === STATE.ADD))
        .map(item => {
          if (item.member === id && item.state === STATE.OLD) {
            item.state = STATE.REMOVE;
          }
          return item;
        })
    );
  }, []);

  const handleSave = useCallback(() => {
    setIsDirty(false);
    const addedList = memberList.filter(item => item.state === STATE.ADD).map(item => item.member);
    const removeList = memberList.filter(item => item.state === STATE.REMOVE).map(item => item.member);
    Promise.all([
      addedList.length && saveTeams(addedList, STATE.ADD),
      removeList.length && saveTeams(removeList, STATE.REMOVE),
    ])
      .then(() => {
        history.push("/depots/teams");
        successMessage(
          addedList.length || removeList.length ? "Team is updated successfully" : "Team is saved successfully"
        );
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
      });
  }, [memberList]);

  const saveTeams = useCallback(
    (members, action) =>
      addRemoveMembers({
        team: data.name,
        depot: data.depot,
        action,
        members,
      }),
    [data]
  );

  const handleRestore = useCallback(() => {
    const id = teamName.trim();
    setMemberList(prevState =>
      prevState.map(item => {
        if (item.member === id) {
          item.state = STATE.OLD;
        }
        return item;
      })
    );
    setTeamName("");
    closeConfirmationModal();
  }, [teamName]);

  return (
    <CardBody className={classes.cardBody}>
      <NGIPrompt show={isDirty} />
      <div className={classes.addContainer}>
        <div className={classes.title}>
          Add Member
          <span>Enter member email to add him to the team</span>
        </div>
        <div className={classes.teamName}>
          <NGIInput
            value={teamName}
            onChange={onChange}
            suffix={<CustomButton disabled={!teamName} onClick={onAdd} size="small" type="primary" text="ADD MEMBER" />}
          />
        </div>
      </div>
      <div className={classes.membersContainer}>
        <div className={classes.title}>Team Members</div>
        <div className={classes.memberList}>
          {memberList
            .filter(item => item.state !== STATE.REMOVE)
            .map(item => (
              <div key={item.member} className={classes.member}>
                <div className={classes.memberName}>
                  <div className={classes.iconContainer}>
                    <img src={userPreviewIcon} alt="customer" />
                  </div>
                  {item.member}
                </div>

                <div data-id={item.member} className={classes.removeBtn} onClick={handleRemove}>
                  REMOVE
                </div>
              </div>
            ))}
        </div>
        {isConfirmationOpen && (
          <ConfirmationModal
            description="Member has been previously removed from the team. Would you like to restore it?"
            onCancel={closeConfirmationModal}
            onConfirm={handleRestore}
          />
        )}
      </div>
      <CustomButton onClick={handleSave} type="primary" size="small" text="Save" />
    </CardBody>
  );
};

export default Edit;
