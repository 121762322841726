import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import classnames from "classnames";
import { removeSpaces } from "utils/helpers/functions";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  groupSection: {},
  assetContainer: {},
  assetRow: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F8F8F8",
    padding: 14,
    marginBottom: 5,
    borderRadius: 30,
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 11,
    width: 100,
    backgroundColor: "#e9e9e9",
    borderRadius: 4,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  NotLoaded: {
    color: "#757575",
    backgroundColor: "#e9e9e9",
  },
  Loaded: {
    color: "#db6605",
    backgroundColor: "#ffe3cc",
  },
  InTransit: {
    color: "#0088ea",
    backgroundColor: "#e6f5ff",
  },
  Delivered: {
    color: "#19c346",
    backgroundColor: "#d7ffe3",
  },
  assetValue: {
    fontSize: 13,
    color: "#444444",
    fontWeight: 600,

    "&>span": {
      color: "#8C8C8C",
      fontWeight: 300,
    },
  },
  conditionContainer: {
    width: 200,
  },
  groupContainer: {
    width: 100,
  },
  qtyContainer: {
    width: 80,
  },
  locationContainer: {
    width: 250,
  },
  overflowText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

const AssetRowGroup = ({ groupName, assets }) => {
  const classes = useStyles();

  return (
    <div className={classes.groupSection}>
      <TitleWithDivider
        title={
          groupName !== "Not Planned" ? (
            <CustomLink underlined target="_blank" to={`/stock-management/trips/${groupName}`}>
              {groupName}
            </CustomLink>
          ) : (
            groupName
          )
        }
      />
      <div className={classes.assetContainer}>
        {assets.map(item => (
          <div key={item.id} className={classes.assetRow}>
            <div className={classnames(classes.assetValue, classes.groupContainer)}>{item.group}</div>
            <div className={classnames(classes.assetValue, classes.conditionContainer)}>
              <span>Condition:</span> {item.condition}
            </div>
            <div className={classnames(classes.assetValue, classes.qtyContainer)}>
              <span>QTY:</span> {item.quantity}
            </div>
            <div
              title={item.location}
              className={classnames(classes.assetValue, classes.locationContainer, classes.overflowText)}
            >
              {item.location && (
                <>
                  <span>Location:</span>
                  {` ${item.location}`}
                </>
              )}
            </div>
            <div className={classnames(classes.status, classes[removeSpaces(item.status)])}>{item.status}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetRowGroup;
