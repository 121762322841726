export const getPhotoRequiredReasons = meterFaultyReason => {
  let allReasons = [];
  meterFaultyReason.forEach(category => {
    if (category.isPhotoRequired && category?.items) {
      const withSubCat = category?.items?.map(subCat => `${category.item}>${subCat.item}`);
      allReasons = [...allReasons, ...withSubCat];
    } else {
      const subCatReasons = (category?.items || [])?.map(subCat =>
        subCat.isPhotoRequired ? `${category.item}>${subCat.item}` : ""
      );
      allReasons = [...allReasons, ...subCatReasons];
    }
  });
  return allReasons.filter(item => item);
};

export const getRequiredFieldsMapping = (meterFaultyReason, allFields) => {
  const allReasons = {};
  allFields.forEach(field => {
    meterFaultyReason.forEach(category => {
      if (category?.requiredRepairFields?.includes(field) && category?.items) {
        const withSubCat = category?.items?.map(subCat => `${category.item}>${subCat.item}`);
        allReasons[field] = [...(allReasons[field] || []), ...withSubCat?.filter(item => item)];
      } else {
        const subCatReasons =
          category?.items?.map(subCat =>
            subCat?.requiredRepairFields?.includes(field) ? `${category.item}>${subCat.item}` : ""
          ) || [];
        allReasons[field] = [...(allReasons[field] || []), ...subCatReasons?.filter(item => item)];
      }
    });
  });
  return allReasons;
};

export const prepareReasons = reasons =>
  reasons.map(k => {
    if (k?.items?.length) {
      return k;
    }
    return {
      item: k.item,
      items: [],
    };
  });
