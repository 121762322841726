import React, { useCallback, useMemo, useState } from "react";
import NGITable from "components/Common/NGITable";
import CustomButton from "components/Common/buttons/CustomButton";
import NGITooltip from "components/Common/utils/NGITooltip";
import { Spin } from "antd";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { setD2DLostAssets } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { allAssetsColumns, ASSET_TYPES, extraAssetsColumns } from "pages/stock-management/trips/tripDetails/constants";

const AllAssetsTab = ({ assets, update, showActions = true, id, type, filtersType }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowList, setRowList] = useState([]);
  const [isLoading, setLoader] = useState(false);

  const rowSelection = {
    selectedRowKeys,
    onChange: rows => {
      setSelectedRowKeys(rows);
    },
    renderCell: (checked, record, index, node) => {
      if (record.state === "Lost") {
        return <NGITooltip title="Incorrect status for marking as Lost">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      if (record.state === "Lost") {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };

  const resetSelectedRows = useCallback(() => setSelectedRowKeys([]), []);

  const columns = useMemo(() => {
    switch (type) {
      case ASSET_TYPES.ALL:
        return allAssetsColumns();
      case ASSET_TYPES.EXTRA:
      case ASSET_TYPES.LOST:
        return extraAssetsColumns();
      default:
        return allAssetsColumns();
    }
  }, [type]);

  const openModal = useCallback(() => {
    setRowList(selectedRowKeys);
    setShowModal(true);
  }, [selectedRowKeys]);

  const closeModal = useCallback(() => {
    setRowList([]);
    setShowModal(false);
  }, [selectedRowKeys]);

  const handleSubmit = useCallback(async () => {
    setLoader(true);
    await setD2DLostAssets({ assets: rowList }, id)
      .then(() => {
        setTimeout(() => {
          setLoader(false);
          update();
          successMessage("The asset is marked as lost");
        }, 3000);
        resetSelectedRows();
        closeModal();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [rowList, id]);

  return (
    <>
      {showActions && (
        <div className="filters-wrapper with-buttons">
          <CustomButton
            type="primary"
            size="small"
            text="Mark as Lost"
            onClick={openModal}
            disabled={!selectedRowKeys.length}
          />
        </div>
      )}
      <Spin spinning={isLoading}>
        <NGITable
          filtersType={filtersType}
          columns={columns}
          data={assets}
          updateList={update}
          rowSelection={showActions ? rowSelection : null}
          rowKey={record => record.id}
        />
      </Spin>
      {showModal && (
        <ConfirmationModal
          loading={isLoading}
          onCancel={closeModal}
          onConfirm={handleSubmit}
          description={"Are you sure you want to mark the item as lost? The status will change to the ‘Lost’."}
        />
      )}
    </>
  );
};

export default AllAssetsTab;
