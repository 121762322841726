import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import NGIInput from "components/Common/inputs/NGIInput";
import { makeStyles } from "@material-ui/core/styles";
import closeIcon from "../../../assets/images/svg/closeIcon.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import { createDictionary } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  root: {
    width: "400px !important",
    marginTop: 30,
  },
  button: {
    marginBottom: "15px",
    position: "relative",
  },
  close: {
    position: "absolute",
    right: 20,
    top: 20,
    cursor: "pointer",
    display: "block",
  },
  addNew: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    cursor: "pointer",
    width: 300,
    height: 54,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 30,
    color: "#666666",
  },
});

const CategoriesModal = ({ onClose, isOpen }) => {
  const [isLoading, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [form] = Form.useForm();

  const {
    list: { sparePartCategory },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    if (!!sparePartCategory) {
      setCategoryList(sparePartCategory);
      sparePartCategory.forEach((item, index) => form.setFieldValue(`${index}`, item));
    } else setCategoryList([]);
  }, [sparePartCategory]);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.SPARE_PART_CATEGORY));
  }, []);

  const addItem = useCallback(() => {
    const arr = Object.values(form.getFieldsValue());
    setCategoryList([...arr, ""]);

    setTimeout(() => {
      const input = document.getElementById(`input-${categoryList.length}`);
      input.focus();
    }, 300);
  }, [form, categoryList]);

  const removeItem = useCallback(
    e => {
      const index = Number(e.target.dataset.index);
      setDisabled(false);
      const arr = Object.values(form.getFieldsValue());
      setCategoryList(() => {
        arr.splice(index, 1);
        return [...arr];
      });
    },
    [form]
  );

  const onSave = useCallback(async values => {
    setLoader(true);
    const formattedData = {
      name: DICTIONARY_TYPES.SPARE_PART_CATEGORY,
      items: Object.values(values),
    };

    await createDictionary(formattedData)
      .then(() => {
        setLoader(false);
        successMessage(`Data was saved successfully`);
        onClose();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  useEffect(() => {
    categoryList.forEach((item, index) => form.setFieldValue(`${index}`, item));
  }, [categoryList, form]);

  return (
    <Modal
      open={isOpen}
      className={classes.root}
      title={"Edit Spare Part Categories"}
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <>
        <Spin spinning={isLoading}>
          <Form form={form} onFinish={onSave}>
            {categoryList?.map((item, index) => (
              <div key={item} className={classes.button}>
                <Form.Item name={`${index}`}>
                  <NGIInput
                    id={`input-${index}`}
                    onChange={e => {
                      form.setFieldValue(`${index}`, e.target.value);
                      setDisabled(false);
                    }}
                  />
                </Form.Item>
                <img src={closeIcon} className={classes.close} alt="close" data-index={index} onClick={removeItem} />
              </div>
            ))}
            <div className={classes.addNew} onClick={addItem}>
              + Add New Category
            </div>
            <br />
            <Space>
              <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
              <CustomButton disabled={disabled} htmlType={"submit"} type="primary" text="Save" size="small" />
            </Space>
          </Form>
        </Spin>
      </>
    </Modal>
  );
};

export default CategoriesModal;
