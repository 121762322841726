import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import Header from "./Header";
import DataTabs from "./DataTabs";
import { fetchPackageDetailsById } from "utils/api";
import { Spin } from "antd";

const PackageDetails = () => {
  const { id } = useParams();
  const [packageData, setPackageData] = useState({});

  useEffect(() => {
    fetchPackageDetailsById(id).then(({ data }) => setPackageData(data.result));
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Spin spinning={!packageData?.code}>
          <Header packageData={packageData} />
          <DataTabs packageData={packageData} />
        </Spin>
      </Container>
    </div>
  );
};

export default PackageDetails;
