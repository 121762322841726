import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import noAccess from "./../../assets/images/svg/noAccess.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 42,
    fontWeight: 800,
    color: "rgba(68, 68, 68, 1)",
    width: 380,
    textAlign: "center",
    margin: "54px 0 23px 0",
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(102, 102, 102, 1)",
    width: 380,
    textAlign: "center",
    marginBottom: 50,
  },
  withMargin: {
    margin: "0 10px",
  },
});

const data = {
  title: "ACCESS DENIED!",
  description:
    "Sorry, you cannot view the page. No permissions to the page. To access, please contact the administrator.",
};

const NoAccess = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <img src={noAccess} alt="no access" />
      <div className={classes.title}>{data.title}</div>
      <div className={classes.description}>{data.description}</div>
      <div>
        <CustomButton
          className={classes.withMargin}
          type="primary"
          text="Go to the main page"
          onClick={() => history.push("/dashboard")}
        />
        OR
        <CustomButton
          className={classes.withMargin}
          type="error"
          text="Logout"
          onClick={() => history.push("/logout")}
        />
      </div>
    </div>
  );
};

export default NoAccess;
