import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal, Space, Tabs } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import MeterDataUpdate from "pages/meter-management/configuration/meters/FactoryTest/Tabs/MeterDataUpdate";
import TestResultHistory from "pages/meter-management/configuration/meters/FactoryTest/Tabs/TestResultHistory";
import { retrieveMeterReports, saveMeterFactoryTest } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { getErrorList } from "pages/meter-management/configuration/meters/utils";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
});

const FactoryTestModal = ({ isOpen, onClose, meter }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [reports, setReports] = useState([]);
  const { serial, id } = meter;
  const onSubmit = useCallback(() => {
    const { slope, intercept, isLeakTestOk, location, isPassed } = form.getFieldsValue();
    const errors = getErrorList(form);
    if (slope && intercept && location && !errors.length) {
      saveMeterFactoryTest(meter.barcode, {
        slope: parseInt(slope),
        intercept: parseInt(intercept),
        isPassed,
        isLeakTestOk,
        location,
      })
        .then(() => {
          successMessage(`Meter Acceptance Test saved successfully`);
          onClose();
        })
        .catch(e => defaultNgiErrorMethod(e));
    } else {
      form.submit();
    }
  }, [form, meter]);
  const tabs = useMemo(
    () => [
      {
        label: (
          <div className={classes.titleWithDescription}>
            Meter Data Update
            <span>Calibration Formula, Location</span>
          </div>
        ),
        key: "meterData",
        children: <MeterDataUpdate form={form} />,
      },
      {
        label: (
          <div className={classes.titleWithDescription}>
            Test Results History
            <span>Previous Acceptance Tests</span>
          </div>
        ),
        key: "history",
        children: <TestResultHistory data={reports} />,
      },
    ],
    [form, reports]
  );

  useEffect(() => {
    retrieveMeterReports({ meterId: id }).then(({ data }) => setReports(data.result.items));
  }, []);

  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          Meter Acceptance Test
          <span>{`Meter (${serial})`}</span>
        </div>
      }
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <div className={classes.modalContent}>
        <Tabs defaultActiveKey="meterData" tabPosition="left" items={tabs} />
      </div>

      <Space>
        <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
        <CustomButton onClick={onSubmit} type="primary" text="Save" size="small" />
      </Space>
    </Modal>
  );
};

export default FactoryTestModal;
