import React from "react";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { textFormatter } from "components/Common/formatter";
import { CardBody } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  informationContainer: {
    display: "flex",
    width: "50%",
    paddingRight: 25,
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
});

const General = ({ user }) => {
  const classes = useStyles();

  return (
    <CardBody>
      <TitleWithDivider title="User Details" />
      <div className={classes.informationContainer}>
        <div className={classes.column}>
          <div className={classes.rowData}>
            <div className={classes.title}>Phone</div>
            <div className={classes.data}>{textFormatter(user?.phone)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Email</div>
            <div className={classes.data}>{textFormatter(user?.email)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Role</div>
            <div className={classes.data}>{textFormatter(user?.roles?.map(role => role).join(", "))}</div>
          </div>
        </div>
      </div>
    </CardBody>
  );
};

export default General;
