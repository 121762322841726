import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import wrenchIcon from "assets/images/svg/Wrench.svg";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const hideRoles = [USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD];

export const reportsListColumns = openFaultyModal => [
  {
    title: "Report Type",
    dataIndex: "type",
    render: (cellContent, row) =>
      userHasOneOfRoles(hideRoles) ? (
        <div>{cellContent}</div>
      ) : (
        <Tooltip placement="bottom" title={`${cellContent} details`}>
          <CustomLink to={`/meters/maintenance-report/${row.id}`} target="_blank">
            {cellContent}
          </CustomLink>
        </Tooltip>
      ),
    sort: true,
  },
  {
    title: "Faulty Reasons",
    dataIndex: "faultyReasons",
    render: cellContent => textFormatter(cellContent?.join(", ")),
    sort: true,
  },
  {
    title: "Collected From",
    dataIndex: "fromDepot",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Maintained by",
    dataIndex: "maintainedBy",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Maintained date",
    dataIndex: "maintainedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    dataIndex: "",
    title: "",
    width: "60px",
    render: row => {
      if (row?.editable) {
        return (
          <ButtonGroup
            tooltipTitle="Edit Faulty Reasons"
            icon={wrenchIcon}
            handleClick={() => {
              openFaultyModal(row?.id);
            }}
          />
        );
      }
      return null;
    },
  },
];
