import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import MetaData from "components/VerticalLayout/MetaData";
import ReportsFilter from "./reportsFilter";
import { getMaintenanceReportsAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { maintenanceReportsListColumns } from "pages/meter-management/maintenanceReports/maintenanceReportsListColumns";
import CustomButton from "components/Common/buttons/CustomButton";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { generateXLS } from "utils/helpers/functions";
import useStoreFilters from "customHooks/useStoreFilters";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";
import { TYPE_NAMES } from "pages/meter-management/maintenanceReports/variables";
import { isEmpty } from "lodash";

const MaintenanceReports = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.MAINTENANCE_REPORTS);

  useEffect(() => {
    const defaultData = { [TYPE_NAMES.REPORT_TYPE]: "Testing" };
    handleFilter(!isEmpty(filters) ? filters : { ...defaultData, location: AUTH_USER?.depotName });
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const reportName = useMemo(() => {
    const { timeStart, timeEnd, ...newData } = filterOptions;
    if (!!timeStart && !!timeEnd) {
      newData.from = customDateFormatWithoutTime(timeStart);
      newData.to = customDateFormatWithoutTime(timeEnd);
    }
    return `maintenance_report, ${Object.keys(newData)
      .map(key => `${key}:${newData[key]}`)
      .join(", ")}`;
  }, [filterOptions]);

  const handleFilter = useCallback(
    (newFilterOptions = {}) => {
      setLoader(true);
      setFilterOptions(newFilterOptions);
      setFilters(newFilterOptions);
      getMaintenanceReportsAPI({
        ...newFilterOptions,
      })
        .then(res => {
          const {
            result: { items },
          } = res?.data;
          if (items === 0) {
            warnMessage("No reports were found");
          }
          setReportsList(res?.data);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
          setReportsList(null);
          defaultNgiErrorMethod(err);
        });
    },
    [filterOptions]
  );

  const onDownload = useCallback(
    () => generateXLS(reportsList?.result?.items, reportName, "csv"),
    [reportsList, reportName]
  );

  const columns = useMemo(() => maintenanceReportsListColumns(), []);

  return (
    <>
      <div className="page-content" data-testid="metersComponent">
        <MetaData pageTitle={"Maintenance Reports"} />
        <Container fluid>
          <div className="filters-wrapper">
            <CustomButton
              icon={<i className="bx bx-download" />}
              type="primary"
              disabled={!reportsList?.result?.items?.length}
              text="Download"
              hideRoles={[USER_ROLES.IT_OPERATIONS]}
              onClick={onDownload}
            />
            <ReportsFilter handleFilter={handleFilter} isLoading={isLoading} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.MAINTENANCE_REPORTS}
              isLoading={isLoading}
              columns={columns}
              data={reportsList}
              updateList={handleFilter}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default MaintenanceReports;
