import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";

export const TABS = {
  EDIT_GEOFENCE: "EDIT_GEOFENCE",
  SECTORS: "SECTORS",
  DEPOT_HISTORY: "DEPOT_HISTORY",
};

export const TABS_BUTTONS = [
  {
    title: "Edit Depot",
    key: TABS.EDIT_GEOFENCE,
  },
  {
    title: "Sectors",
    key: TABS.SECTORS,
  },
  {
    title: "Depot History",
    key: TABS.DEPOT_HISTORY,
  },
];

export const depotHistoryColumns = () => [
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Updated by",
    dataIndex: "updatedBy",
    render: textFormatter,
  },
  {
    title: "Changed Fields",
    dataIndex: "fields",
    render: textFormatter,
  },
];

export const ALLOWED_TEAMS = ["Maintenance", "Retention", "Repossession"];
