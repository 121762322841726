import React from "react";
import { Button, Tooltip } from "antd";
import { textFormatter } from "components/Common/formatter";
export const userListColumns = ({ onAction }) => [
  {
    text: "Name",
    dataField: "fullName",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Phone",
    dataField: "phone",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Email",
    dataField: "email",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Access Role",
    dataField: "roleName",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Depot",
    dataField: "depotName",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    dataField: "",
    text: "",
    formatter: (cellContent, agent) => {
      const style = {
        marginRight: "10px",
        transform: "scale(1.1)",
        cursor: "pointer",
      };
      const color = "#448aff";

      return (
        <>
          <div>
            <Tooltip placement="bottom" title="View User Information" color={color}>
              <Button
                shape="circle"
                size="small"
                icon={<i className="bx bx-search" />}
                style={style}
                onClick={() => onAction(agent, "view")}
              />
            </Tooltip>

            <Tooltip placement="bottom" title="Edit User Information" color={color}>
              <Button
                danger
                shape="circle"
                size="small"
                icon={<i className="bx bxs-edit-alt" />}
                style={style}
                onClick={() => onAction(agent, "edit")}
              />
            </Tooltip>
          </div>
        </>
      );
    },
  },
];
