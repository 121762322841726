import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { Spin } from "antd";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import Header from "pages/marketing/initiativeDetails/Header";
import DataTabs from "pages/marketing/initiativeDetails/DataTabs";
import { getInitiativeDetails, getInitiativePhoto } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import moment from "moment";
import NotFoundContainer from "components/Common/NotFoundContainer";

const InitiativeDetails = () => {
  const { id } = useParams();
  const [initiative, setInitiative] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== "new") {
      setIsLoading(true);
      getInitiativeDetails(id)
        .then(res => {
          const { endAt, startAt } = res.data.result;
          getInitiativePhoto(id).then(photoData => {
            setInitiative({
              ...res.data.result,
              photoUrl: photoData.data.result.url,
              endAt: moment(endAt),
              startAt: moment(startAt),
            });
            setIsLoading(false);
          });
        })
        .catch(e => {
          defaultNgiErrorMethod(e);
          setIsLoading(false);
          if (e.response?.data?.errors[0]?.code === "NOT_FOUND") {
            setIsNotFound(true);
          }
        });
    }

    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              <Header initiative={initiative} />
              <DataTabs initiative={initiative} setIsLoading={setIsLoading} />
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default InitiativeDetails;
