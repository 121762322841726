import React from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import Header from "./Header";
import DataTabs from "./DataTabs";

const EditSpareParts = () => {
  const { id } = useParams();

  return (
    <div className="page-content">
      <Container fluid>
        <Header id={id} />
        <DataTabs id={id} />
      </Container>
    </div>
  );
};

export default EditSpareParts;
