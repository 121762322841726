import React, { useCallback, useState } from "react";
import { Col, Divider, Modal, Row, Select, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { Option } from "antd/es/mentions";
import { makeStyles } from "@material-ui/core/styles";
import swapIcon from "assets/images/svg/SwapIcon.svg";
import { PRIORITY_LIST } from "pages/operations/routineDetails/constants";
import classnames from "classnames";
import { assignBulkTaskApi, retrieveAllTasks } from "utils/api";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import InfoModal from "components/Common/modals/InfoModal";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  title: {
    "&> p": {
      color: "#8C8C8C",
      fontWeight: 300,
      fontSize: 12,
      "&> span": {
        fontWeight: 500,
        color: "black",
      },
    },
  },
  columnContainer: {
    width: "47%",
    marginBottom: 20,
  },
  swapIcon: {
    display: "flex",
    alignItems: "center",
  },
  column: {
    borderRadius: "10px 10px 0 0",
    padding: 5,
    width: "100%",
    background: "rgba(245, 245, 245, 1)",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 13,
    padding: 5,
    color: "grey",
    "&> p": {
      marginLeft: 5,
      marginBottom: 0,
      fontSize: 13,
      color: "black",
    },
  },
  select: {
    "&.ant-select > .ant-select-selector": {
      maxWidth: 200,
      background: "transparent",
      border: "none",
      fontSize: 13,
      "&> span": {
        fontWeight: "700",
      },
    },
  },
  divider: {
    margin: 0,
  },
  tasksContainer: {
    borderRadius: "0 0 10px 10px",
    border: "1px solid rgba(0, 0, 0, 0.05)",
  },
  taskItem: {
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",
  },
  badge: {
    marginLeft: 10,
    width: 17,
    height: 17,
    margin: -4,
    padding: 3,
    color: "white",
    display: "flex",
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "11px",
    lineHeight: "11px",
    fontWeight: 500,
  },
  high: {
    backgroundColor: "rgba(255, 46, 0, 1)",
  },
  medium: {
    backgroundColor: "rgba(240, 139, 57, 1)",
  },
  low: {
    backgroundColor: "rgba(23, 195, 71, 1)",
  },
  name: {
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 0,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.75)",
  },
});

const SwapEmployeesModal = ({ handleCancel, swapData, teams, isLoading, setLoader, onChangeTeam, teamConfig }) => {
  const classes = useStyles();
  const [secondTeam, setSecondTeam] = useState(teams[swapData?.firstEmployee?.team]);
  const [secondEmployee, setSecondEmployee] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmSwapModal, setConfirmSwapModal] = useState(false);
  const { firstEmployee, depot, tasks, newTeam } = swapData;

  const handleChangeSecondTeam = useCallback(value => {
    setSecondEmployee(undefined);
    setSecondTeam(teams[value]);
  }, []);

  const handleChangeSecondEmployee = useCallback(async value => {
    const { email, name } = JSON.parse(value);
    setLoader(true);
    const res = await retrieveAllTasks({ pageSize: 500, assignedTo: email });
    const grouped = res?.data?.result?.items?.reduce(function (r, a) {
      r[a.customerId] = r[a.customerId] || [];
      r[a.customerId].push({ ...a });
      return r;
    }, {});
    setSecondEmployee({ email, name, tasks: grouped });
    setLoader(false);
  }, []);

  const priorityFormatter = useCallback(
    priority =>
      PRIORITY_LIST.find(
        item =>
          (Math.round(priority) <= item.max && Math.round(priority) >= item.min) || (!priority && item.letter === "L")
      ),
    []
  );

  const openConfirmModal = useCallback(() => setConfirmModal(true), []);
  const openConfirmSwapModal = useCallback(() => setConfirmSwapModal(true), []);
  const closeConfirmModal = useCallback(() => {
    setConfirmModal(false);
    setConfirmSwapModal(false);
  }, []);

  const onSwap = useCallback(() => {
    const options = {
      assignTo: secondEmployee?.email,
      assignToName: secondEmployee?.name,
      assignToDepot: depot,
      taskIds: tasks.map(task => task.id),
    };

    closeConfirmModal();
    setLoader(true);
    assignBulkTaskApi(options)
      .then(() => {
        setTimeout(() => {
          successMessage(`Tasks was reassigned successfully`);
          onChangeTeam(firstEmployee?.email, newTeam);
          handleCancel();
        }, 2000);
      })
      .catch(error => {
        setLoader(false);
        defaultNgiErrorMethod(error);
      });
  }, [secondEmployee, secondEmployee, depot, tasks, firstEmployee, newTeam]);

  const handleSwapClick = useCallback(() => {
    tasks.forEach(task => {
      if (task.status === "In progress") {
        openConfirmModal();
        return;
      }
      openConfirmSwapModal();
    });
  }, [tasks]);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.title}>
          Swap Employees <p>Select employees you want to swap</p>
          <Divider />
        </div>
      }
      closable={false}
      onCancel={handleCancel}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <Row justify={"space-between"}>
          <Col className={classes.columnContainer}>
            <h6>First Employee</h6>
            <div className={classes.column}>
              <div className={classes.section}>
                Team
                <Select
                  style={{ width: "100%" }}
                  className={classes.select}
                  value={newTeam}
                  tokenSeparators={[","]}
                  placeholder={"Select value"}
                  disabled={true}
                >
                  {Object.keys(teamConfig).map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.section}>
                Employee
                <Select
                  style={{ width: "100%" }}
                  className={classes.select}
                  tokenSeparators={[","]}
                  placeholder={"Select value"}
                  value={firstEmployee?.email}
                  disabled={true}
                >
                  {Object.keys(teams).map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className={classes.tasksContainer}>
              {tasks?.map((item, index) => (
                <>
                  <Row key={item.id} className={classes.taskItem}>
                    <Col>
                      <p className={classes.name}>{item.customer?.name}</p>
                      <span className={classes.subtitle}>{item.customerId}</span>
                    </Col>
                    <div>
                      <span
                        className={classnames(
                          classes.badge,
                          classes[priorityFormatter(item.priority)?.name.toLowerCase()]
                        )}
                      >
                        {priorityFormatter(item.priority)?.letter.toUpperCase()}
                      </span>
                    </div>
                  </Row>
                  {index + 1 !== tasks?.length && <Divider className={classes.divider} />}
                </>
              ))}
            </div>
          </Col>
          <Col className={classes.swapIcon}>
            <img src={swapIcon} alt="swap" />
          </Col>
          <Col className={classes.columnContainer}>
            <h6>Second Employee</h6>
            <div className={classes.column}>
              <div className={classes.section}>
                Team:
                <Select
                  disabled={true}
                  style={{ width: "100%" }}
                  className={classes.select}
                  value={secondTeam?.title}
                  tokenSeparators={[","]}
                  placeholder={"Select value"}
                  onChange={handleChangeSecondTeam}
                >
                  {Object.keys(teams).map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.section}>
                Employee
                <Select
                  style={{ width: "100%" }}
                  className={classes.select}
                  value={secondEmployee?.email}
                  tokenSeparators={[","]}
                  placeholder={"Select value"}
                  onChange={handleChangeSecondEmployee}
                >
                  {secondTeam &&
                    secondTeam?.users?.map(
                      item =>
                        item.email !== firstEmployee?.email && (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.name || item.id}
                          </Option>
                        )
                    )}
                </Select>
              </div>
            </div>
            <div className={classes.tasksContainer}>
              {Object.keys(secondEmployee?.tasks || {}).length ? (
                Object.values(secondEmployee?.tasks || {})?.map((item, index) => (
                  <>
                    <Row key={item[0].customerId} className={classes.taskItem}>
                      <Col>
                        <p className={classes.name}>{item[0].customer?.name}</p>
                        <span className={classes.subtitle}>{item[0].customerId}</span>
                      </Col>
                      <Row>
                        {item.map(task => (
                          <span
                            key={task.id}
                            className={classnames(
                              classes.badge,
                              classes[priorityFormatter(task.priority)?.name.toLowerCase()]
                            )}
                          >
                            {priorityFormatter(task.priority)?.letter.toUpperCase()}
                          </span>
                        ))}
                      </Row>
                    </Row>
                    {index + 1 !== tasks?.length && <Divider className={classes.divider} />}
                  </>
                ))
              ) : (
                <Row className={classes.taskItem}>
                  <h6>No data to display</h6>
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <Space>
          <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
          <CustomButton
            disabled={!secondEmployee}
            onClick={handleSwapClick}
            type="primary"
            size="small"
            text="Swap Employees"
          />
        </Space>
        {confirmModal && (
          <InfoModal
            onCancel={closeConfirmModal}
            description={`The First Employee currently has 'In Progress' tasks. Please ask ${firstEmployee?.name} to complete these tasks before proceeding with the swap.`}
          />
        )}
        {confirmSwapModal && (
          <ConfirmationModal
            onConfirm={onSwap}
            onCancel={closeConfirmModal}
            title={"Swap Employee"}
            description={`The First Employee currently has 'Assigned' tasks. Are you sure you want to proceed with the swap? This action will assign all tasks from the First Employee to the Second Employee.`}
          />
        )}
      </Spin>
    </Modal>
  );
};

export default SwapEmployeesModal;
