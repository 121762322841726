import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input } from "antd";
import { Card, CardBody, Col } from "reactstrap";
import { getDepotInfoLegacyAPI, getRolesInfoLegacyAPI } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import CustomButton from "components/Common/buttons/CustomButton";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const UsersSearch = ({ handleSearch }) => {
  const [depots, setDepots] = useState([]);
  const [roles, setRoles] = useState([]);
  const [form] = Form.useForm();
  const [type, setType] = useState(null);
  const onSearch = useCallback(values => handleSearch(values, 1), []);
  const cardStyles = {
    marginBottom: "0",
    paddingBottom: "0",
  };

  useEffect(() => {
    getDepotInfoLegacyAPI()
      .then(async result => {
        const { data } = result;
        setDepots(data);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, []);

  useEffect(() => {
    getRolesInfoLegacyAPI()
      .then(async res => {
        const {
          data: { results },
        } = res;
        setRoles(results);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, []);

  return (
    <>
      <Col lg={12}>
        <Card style={cardStyles}>
          <CardBody style={cardStyles}>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
              <Col lg={2} className="mt-2">
                <Form.Item name="type" rules={[{ required: true, message: "Please select type" }]}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    placeholder={"Select type"}
                    onChange={e => setType(e)}
                  >
                    <Option value="fullName">Full Name</Option>
                    <Option value="email">Email</Option>
                    <Option value="phone">Phone</Option>
                    <Option value="depotName">Depot</Option>
                    <Option value="roleName"> Access Role</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={2} className="mt-2">
                {!!depots && type === "depotName" ? (
                  <Form.Item name="value" rules={[{ required: true, message: "Please Select Search Value" }]}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      placeholder={"Please select Depot"}
                    >
                      {depots.map(item => (
                        <Option key={item.name} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : !!roles && type === "roleName" ? (
                  <Form.Item name="value" rules={[{ required: true, message: "Please Select Search Value" }]}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      placeholder={"Please select Access Role"}
                    >
                      {roles.map(item => (
                        <Option key={item.roleName} value={item.roleName}>
                          {item.roleName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
                    <Input placeholder={"Enter value"} pattern={inputTextPattern} title={inputPatternTitle} />
                  </Form.Item>
                )}
              </Col>
              <Col lg={2} className="mt-2">
                <CustomButton htmlType="submit" type="primary" size="small" color="outlined" text="Search" />
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default UsersSearch;
