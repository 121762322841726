import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { editableFieldsMap, REQUEST_STATUSES, VALIDATION_RULES } from "./constants";
import Header from "./Header";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import NGIInput from "components/Common/inputs/NGIInput";
import {
  approveCustomerUpdateLocationRequestsById,
  createCustomerUpdateLocationRequest,
  customerDuplicateCheckRequest,
  declineCustomerUpdateLocationRequestsById,
  fetchCustomerById,
  fetchDepotsList,
  retrieveCustomerPhotoUrlByType,
  retrieveCustomerUpdateLocationRequestsById,
} from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage, warnMessage } from "components/Common/responses/message";
import { useDispatch, useSelector } from "react-redux";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { extractPromisesResult, getTrimmedObjectValues, randomColor, userHasOneOfRoles } from "utils/helpers/functions";
import CustomLink from "components/Common/buttons/CustomLink";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { textFormatter } from "components/Common/formatter";
import OTPVerificationModal from "pages/customer-management/customerUpdateRequest/createCustomerDetailsUpdaterequest/OTPVerificationModal";
import { useHistory, useParams } from "react-router";
import { useJsApiLoader } from "@react-google-maps/api";
import { AUTH_USER, GOOGLE_MAPS_API_KEY, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import ChangeAddressModel from "pages/customer-management/customerUpdateLocationRequest/createCustomerLocationUpdateRequest/ChangeAddressModel";
import InfoMessage from "components/Common/InfoMessage";
import ChangeOverviewModel from "pages/customer-management/customerUpdateRequest/createCustomerDetailsUpdaterequest/ChangeOverviewModel";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { TSRSelector } from "store/TSR/selectors";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  buttonCenter: {
    marginTop: 5,
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  link: {
    textDecoration: "underline!important",
    color: "rgba(0, 133, 255, 1) !important",
    margin: "20px 0",
    fontWeight: "bold",
    display: "block",
    fontSize: 15,
    maxWidth: "max-content",
  },
  editContainer: {
    display: "flex",
  },
  filedColumn: {
    padding: "0 10px",
  },
  columnTitle: {
    fontSize: "14px!important",
  },
  button: {
    height: 50,
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 10,
    },
  },
  approveDeclineContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  column: {
    width: "33%",
    marginRight: 35,
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
    textAlign: "end",
  },
  columnsContainer: {
    display: "flex",
  },
  sendOtpBtn: {
    height: 30,
    padding: "0 12px",
  },
  customerFind: {
    fontSize: 15,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
});

const CreateCustomerLocationUpdateRequest = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id: customerRequestId } = useParams();
  const [editForm] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [updatedCustomer, setUpdatedCustomer] = useState(null);
  const [controlsDisabled, setControlsDisabled] = useState(false);
  const [id, setId] = useState(null);
  const [updateRequest, setUpdateRequest] = useState(null);
  const [photoDetails, setPhotoDetails] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [OTPVerificationData, setOTPVerificationData] = useState(null);
  const [isAddressChangeModalOpen, setIsAddressChangeModalOpen] = useState(false);
  const [allDepotsList, setAllDepotsList] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmationDeclineOpen, setIsConfirmationDeclineOpen] = useState(false);
  const [isChangesPreviewModalOpen, setIsChangesPreviewModalOpen] = useState(null);
  const [technician, setTechnician] = useState(null);
  const DSMDepot = AUTH_USER?.depotName;
  const { TSRList } = useSelector(TSRSelector);

  const showControls =
    ((DSMDepot === updateRequest?.depot || userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])) &&
      updateRequest?.status === REQUEST_STATUSES.PENDING) ||
    ((DSMDepot === updateRequest?.newDepot || userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])) &&
      updateRequest?.status === REQUEST_STATUSES.APPROVED_BY_CURRENT);

  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    libraries: ["drawing", "places"],
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const {
    list: { customerType, county },
  } = useSelector(DictionariesSelector);

  const openConfirmationModal = useCallback(
    e => {
      e.preventDefault();
      if (updateRequest?.status === REQUEST_STATUSES.APPROVED_BY_CURRENT && !technician) {
        errorMessage("Please select TSR.");
        return;
      }
      setIsConfirmationOpen(true);
    },
    [technician, updateRequest]
  );
  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(false);
  }, []);

  const openConfirmationDeclineModal = useCallback(e => {
    e.preventDefault();
    setIsConfirmationDeclineOpen(true);
  }, []);

  const closeConfirmationDeclineModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationDeclineOpen(false);
  }, []);

  const handleApprove = useCallback(() => {
    setLoader(true);

    const selectedTSR = TSRList.find(item => item.email === technician);
    const data = {
      ...(selectedTSR && { technician: selectedTSR?.email }),
      ...(selectedTSR && { technicianName: selectedTSR?.name || "" }),
    };

    approveCustomerUpdateLocationRequestsById(customerRequestId, data)
      .then(() => {
        setLoader(false);
        setIsConfirmationOpen(false);
        successMessage(`The information for ${customer.firstName} ${customer.lastName} was updated successfully.`, 5);
        history.push("/customers/update-location-requests");
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [customerRequestId, customer, technician]);

  const handleDecline = useCallback(
    reason => {
      setLoader(true);
      declineCustomerUpdateLocationRequestsById(customerRequestId, reason)
        .then(() => {
          setLoader(false);
          setIsConfirmationDeclineOpen(false);
          successMessage(`The information for ${customer.firstName} ${customer.lastName} was declined successfully`, 5);
          history.push("/customers/update-location-requests");
        })
        .catch(e => {
          setLoader(false);
          defaultNgiErrorMethod(e);
        });
    },
    [customerRequestId, customer]
  );

  const countyList = useMemo(() => (county && county[USER_TENANT_NAME]?.map(item => item.name)) || [], [county]);

  const subCountyList = useMemo(() => {
    const currentCounty = selectedCounty ?? editForm.getFieldValue("region");
    const selected = (county && county[USER_TENANT_NAME]?.find(item => item.name === currentCounty)) || {};
    return selected?.sub || [];
  }, [selectedCounty, editForm.getFieldValue("region")]);

  const onCountyChange = useCallback(
    value => {
      setSelectedCounty(value);
      editForm.setFieldValue("district", null);
    },
    [editForm]
  );

  useEffect(() => {
    fetchDepotsList({
      pageSize: 1000,
    }).then(res => {
      const {
        result: { items },
      } = res?.data;
      const list = items?.map(item => ({
        id: item.name,
        district: item.location.subCounty,
        region: item.location.county,
        color: randomColor(),
        poly: item.boundaries.coordinates[0].map(item => [item[1], item[0]]),
      }));
      setAllDepotsList(list || []);
    });
    dispatch(getDictionary(DICTIONARY_TYPES.COUNTY));
  }, []);

  useEffect(() => {
    if (!!id) {
      Promise.allSettled([retrieveCustomerPhotoUrlByType(id, "housePhoto")]).then(data => {
        const [customerHousePhoto] = extractPromisesResult(data);
        setPhotoDetails({
          housePhotoUrl: customerHousePhoto?.data?.result?.url,
        });
      });
    }
  }, [id]);

  useEffect(() => {
    if (!!customerRequestId) {
      setLoader(true);
      retrieveCustomerUpdateLocationRequestsById(customerRequestId).then(({ data }) => {
        setId(data?.result?.customerId);
        setUpdateRequest(data?.result);

        fetchCustomerById(data?.result?.customerId).then(res => {
          setLoader(false);
          setCustomer(res?.data?.result);
          setUpdatedCustomer(res?.data?.result);
        });
      });
    }
  }, [customerRequestId]);

  useEffect(() => {
    if (!!customer) {
      const {
        location: { street, region, houseNumber, district },
        phone,
      } = customer;
      editForm.setFieldsValue({
        street,
        region,
        houseNumber,
        district,
        phone,
      });
      setTechnician(customer.technician);
    }
  }, [customer]);

  const onFieldsChanged = useCallback(
    async () =>
      editForm
        .validateFields()
        .then(() => {
          setFormValid(true);
        })
        .catch(res => {
          setFormValid(!res.errorFields.length);
        })
        .finally(() => {
          setIsDirty(true);
        }),
    [editForm]
  );

  const sendOtpToCustomer = useCallback(() => {
    setLoader(true);
    const { phone } = updatedCustomer;
    customerDuplicateCheckRequest(id, { phone })
      .then(({ data }) => {
        if (data?.result?.duplicates?.length) {
          warnMessage("The customer's phone is duplicated.", 10);
        } else {
          setIsDirty(false);
          setOTPVerificationData({
            customerId: id,
            phone,
          });
        }
        setLoader(false);
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [id, updatedCustomer]);

  const onCloseVerificationModal = useCallback(() => setOTPVerificationData(null), []);

  const getField = useCallback(
    type => {
      switch (type) {
        case "coordinates":
          return (
            <CustomButton
              type="primary"
              className={classes.button}
              size={"small"}
              text="Open Map"
              onClick={openChangeAddressModal}
              disabled={customerRequestId}
            />
          );
        case "housePhotoFileName":
          return photoDetails?.housePhotoUrl ? (
            <div className={classes.img}>
              <img
                data-url={photoDetails?.housePhotoUrl}
                onClick={onPreviewImg}
                src={photoDetails?.housePhotoUrl}
                alt="preview"
              />
            </div>
          ) : (
            "No photo"
          );
        case "region":
          return (
            <NGISelect onChange={onCountyChange} placeholder={"Select value"}>
              {countyList &&
                countyList.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </NGISelect>
          );
        case "district":
          return (
            <NGISelect placeholder={"Select value"}>
              {subCountyList &&
                subCountyList.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </NGISelect>
          );

        default:
          return <NGIInput />;
      }
    },
    [
      customerType,
      photoDetails,
      sendOtpToCustomer,
      isDirty,
      onFieldsChanged,
      formValid,
      onCountyChange,
      countyList,
      subCountyList,
    ]
  );

  const onPreviewImg = useCallback(e => {
    const url = e.currentTarget.dataset.url;
    setPreviewUrl(url);
  }, []);

  const onClose = useCallback(() => setPreviewUrl(""), []);

  const onSubmit = useCallback(
    values => {
      setLoader(true);
      setId(values?.customerId?.trim());
      fetchCustomerById(values?.customerId?.trim())
        .then(res => {
          setLoader(false);
          if (res?.data?.result?.status === "Active") {
            setCustomer(res?.data?.result);
            setUpdatedCustomer(res?.data?.result);
          } else {
            warnMessage("Customer should be ACTIVE");
            setCustomer(null);
            setUpdatedCustomer(null);
          }
        })
        .catch(error => {
          let msg = "Customer is not found";
          const errorMessage = error.response?.data?.errors[0];
          if (errorMessage.code === "ACCESS_DENIED") {
            msg = errorMessage.desc;
            form.setFieldValue("customerId", null);
            setControlsDisabled(true);
          }
          warnMessage(msg);
          setLoader(false);
          setCustomer(null);
          setUpdatedCustomer(null);
        });
    },
    [form]
  );

  const handleViewChanges = useCallback(e => {
    e.preventDefault();
    setIsChangesPreviewModalOpen(true);
  }, []);

  const closeViewChangesModal = useCallback(() => setIsChangesPreviewModalOpen(false), []);

  const onSave = useCallback(
    values => {
      setLoader(true);
      const { depot, location } = updatedCustomer;
      const { street, houseNumber, region, district } = getTrimmedObjectValues(values);

      const params = {
        //added temporary
        newDepot: depot,
        housePhotoFileName: location?.housePhotoFileName || " ",
        lat: location.lat,
        lon: location.lon,
        street,
        houseNumber,
        region,
        district,
      };
      createCustomerUpdateLocationRequest(id.trim(), params)
        .then(() => {
          successMessage("Update location request was created successfully");
          setLoader(false);
          history.push("/customers/update-location-requests");
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoader(false);
        });
    },
    [editForm, id, updatedCustomer]
  );

  const closeChangeAddressModal = useCallback(() => setIsAddressChangeModalOpen(false), []);

  const openChangeAddressModal = useCallback(e => {
    e.preventDefault();
    setIsAddressChangeModalOpen(true);
  }, []);

  const handleAddressSave = useCallback(
    values => {
      const { street, houseNumber, district, region, lat, lon, depot } = values;
      editForm.setFieldsValue({
        street,
        houseNumber,
        district,
        region,
        lat,
        lon,
      });
      setUpdatedCustomer({
        ...customer,
        depot,
        location: {
          street,
          houseNumber,
          district,
          region,
          lat,
          lon,
          housePhotoFileName: customer?.location?.housePhotoFileName,
        },
      });
      onFieldsChanged();
    },
    [editForm, customer]
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header isEdit={!!customerRequestId} />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    {!customerRequestId && (
                      <Form form={form} layout="inline" onFinish={onSubmit} className={classes.formContainer}>
                        <div className={classes.modalContent}>
                          <Row>
                            <Col span={3} className={classes.customerFind}>
                              Find Customer
                            </Col>
                            <Col span={12}>
                              <Form.Item name="customerId" rules={VALIDATION_RULES}>
                                <NGIInput disabled={controlsDisabled} allowClear placeholder={"Enter Customer ID"} />
                              </Form.Item>
                            </Col>
                            <Col span={9} className={classes.buttonCenter}>
                              <CustomButton
                                disabled={controlsDisabled}
                                htmlType="submit"
                                type="primary"
                                text="Search"
                                size="small"
                              />
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    )}
                    {Boolean(customer) && (
                      <>
                        <CustomLink
                          className={classes.link}
                          target="_blank"
                          to={`/customers/customer/${id || customer.id}`}
                        >
                          {(!!id || customer.id) && `${customer.firstName} ${customer.lastName}`}
                        </CustomLink>

                        <div className={classes.columnsContainer}>
                          <div className={classes.column}>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Customer ID</div>
                              <div className={classes.data}>{textFormatter(id || customer?.id)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Phone</div>
                              <div className={classes.data}>{textFormatter(customer?.phone)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Location (region, district)</div>
                              <div
                                className={classes.data}
                              >{`${customer?.location?.region}, ${customer?.location?.district}`}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Address</div>
                              <div
                                className={classes.data}
                              >{`${customer?.location?.street} ${customer?.location?.houseNumber}`}</div>
                            </div>
                          </div>
                          <div className={classes.column}>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Current Customer Depot</div>
                              <div className={classes.data}>{textFormatter(customer?.depot)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Customer Package</div>
                              <div className={classes.data}>{textFormatter(customer?.packageName)}</div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>Assigned TSR</div>
                              <div className={classes.data}>
                                {customer?.technicianName ? (
                                  <CustomLink underlined target="_blank" to={`/user/${customer?.technician}`}>
                                    {textFormatter(customer?.technicianName)}
                                  </CustomLink>
                                ) : (
                                  textFormatter(customer?.technicianName)
                                )}
                              </div>
                            </div>
                            <div className={classes.rowData}>
                              <div className={classes.title}>TSR Email</div>
                              <div className={classes.data}>
                                {customer?.technician ? (
                                  <CustomLink underlined target="_blank" to={`/user/${customer?.technician}`}>
                                    {textFormatter(customer?.technician)}
                                  </CustomLink>
                                ) : (
                                  textFormatter(customer?.technician)
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!!updateRequest && (
                          <InfoMessage
                            DSMDepot={DSMDepot}
                            TSRList={TSRList}
                            updateRequest={updateRequest}
                            customer={customer}
                            onSelectTechnician={setTechnician}
                          />
                        )}

                        <div className={classes.editContainer}>
                          <Form
                            form={editForm}
                            onFinish={sendOtpToCustomer}
                            onChange={onFieldsChanged}
                            className={classes.formContainer}
                            disabled={!!customerRequestId}
                          >
                            {editableFieldsMap.map(item => (
                              <div className={classes.section} key={item.title}>
                                <TitleWithDivider title={item.title} />
                                <Row>
                                  {item.fields.map(field => {
                                    const [fieldName] = Object.keys(field);
                                    const fieldByType = getField(fieldName);
                                    return (
                                      <Col span={6} className={classes.filedColumn} key={fieldName}>
                                        <Title className={classes.columnTitle}>{field[fieldName]}</Title>
                                        <Form.Item
                                          name={fieldName}
                                          rules={[
                                            { required: field?.required, message: "Please enter value" },
                                            ...(field?.rules || []),
                                          ]}
                                        >
                                          {fieldByType}
                                        </Form.Item>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </div>
                            ))}
                            <Divider />

                            <div className={classes.buttonsContainer}>
                              {!customerRequestId && (
                                <CustomButton htmlType="submit" type="primary" size="small" text="send request" />
                              )}
                            </div>
                          </Form>
                        </div>

                        <div className={classes.approveDeclineContainer}>
                          {customerRequestId && (
                            <>
                              <CustomButton
                                color="outlined"
                                size="small"
                                text="Changes overview"
                                onClick={handleViewChanges}
                              />

                              {showControls && (
                                <Space>
                                  <CustomButton
                                    roles={[USER_ROLES.OPERATION_MANAGERS]}
                                    type="error"
                                    size="small"
                                    text="Decline request"
                                    onClick={openConfirmationDeclineModal}
                                  />
                                  <CustomButton
                                    roles={[USER_ROLES.OPERATION_MANAGERS]}
                                    type="success"
                                    size="small"
                                    text="Approve request"
                                    onClick={openConfirmationModal}
                                  />
                                </Space>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
              {OTPVerificationData && (
                <OTPVerificationModal
                  onClose={onCloseVerificationModal}
                  data={OTPVerificationData}
                  onVerifySuccess={() => onSave(editForm.getFieldsValue())}
                  onSendOTP={sendOtpToCustomer}
                  customerData={customer}
                  lostOption={false}
                />
              )}
              {previewUrl && (
                <Modal title="Photo preview" open={!!previewUrl} onCancel={onClose} footer={[]}>
                  <img className={classes.imgPreview} src={previewUrl} alt="preview" />
                </Modal>
              )}

              {isAddressChangeModalOpen && (
                <ChangeAddressModel
                  isLoaded={isLoaded}
                  classes={classes}
                  customer={updatedCustomer}
                  onClose={closeChangeAddressModal}
                  onSave={handleAddressSave}
                  polygonList={allDepotsList}
                />
              )}

              {isConfirmationOpen && (
                <ConfirmationModal
                  description={
                    updateRequest.newDepot
                      ? "Are you sure you want to approve request?"
                      : "You are about to assign a “Repossession Task”\n" +
                        "task to this customer.\n" +
                        "\n" +
                        "Are you sure you want to proceed?"
                  }
                  onCancel={closeConfirmationModal}
                  onConfirm={handleApprove}
                />
              )}
              {isConfirmationDeclineOpen && (
                <ConfirmationModal
                  title="Decline Reason"
                  description="Add Decline Reason"
                  onCancel={closeConfirmationDeclineModal}
                  cancelBtnTitle="Cancel"
                  confirmBtnTitle="Save Changes"
                  onConfirm={handleDecline}
                  withComment
                  isCommentRequired
                  commentLength={100}
                />
              )}
              {isChangesPreviewModalOpen && (
                <ChangeOverviewModel
                  customer={customer}
                  customerChanges={{ ...updateRequest?.updates, depot: updateRequest?.newDepot }}
                  onClose={closeViewChangesModal}
                />
              )}
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default CreateCustomerLocationUpdateRequest;
