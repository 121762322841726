import React, { useCallback, useEffect, useMemo, useState } from "react";
import Title from "antd/lib/typography/Title";
import { Col, Space, Spin, Tree } from "antd";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { createBulkTaskApi, getUsersList } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { TASK_TYPES } from "pages/operations/createTask/constants";
import NGIPrompt from "components/Common/NGIPrompt";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";
import TSRSelect from "components/Common/components/TSRSelect";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tree: {
    "& .ant-tree-checkbox-disabled": {
      display: "none",
    },
    "& .ant-tree-checkbox-inner": {
      borderRadius: 5,
      width: 24,
      height: 24,
    },
    "& .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner":
      {
        borderColor: "#0093ff",
      },
    "& .ant-tree-checkbox-checked::after": {
      borderColor: "#0093ff",
      borderRadius: 5,
    },
    "& .ant-tree-checkbox-inner::after": {
      left: "33%",
    },
    "& .ant-tree-checkbox-checked .ant-tree-checkbox-inner": {
      background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
      border: "none",
    },
    "& .ant-tree-switcher-line-icon>svg": {
      width: 24,
      height: 24,
    },

    "& .ant-tree-node-content-wrapper": {
      marginTop: 3,
    },
    "& .ant-tree-title": {
      fontSize: 15,
      fontWeight: 500,
    },
  },
});

const CreateTaskSection = ({ data }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [taskType, setTaskType] = useState(TASK_TYPES[0]);
  const [users, setUsers] = useState([]);
  const [selectedTss, setSelectedTss] = useState(null);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedReasons, setCheckedReasons] = useState([]);

  const {
    list: { meterChangeCompleteTaskReasonsV2 },
  } = useSelector(DictionariesSelector);

  const onExpand = useCallback(expandedKeysValue => setExpandedKeys(expandedKeysValue), []);
  const onCheck = useCallback(checkedKeysValue => setCheckedReasons(checkedKeysValue), []);

  const treeData = useMemo(
    () =>
      meterChangeCompleteTaskReasonsV2.map(item => ({
        title: item.item,
        key: item.item,
        disableCheckbox: item?.items,
        children: item?.items
          ? item?.items.map(k => ({
              title: k.item,
              key: `${item.item}>${k.item}`,
            }))
          : [],
      })),
    [meterChangeCompleteTaskReasonsV2]
  );

  const handleSubmit = useCallback(() => {
    closeConfirmationModal();

    if (
      (taskType === "Asset Swap" && data?.consumptionStat?.totalConsumptionLast30Days < 3) ||
      (taskType === "Asset Swap" && !data?.consumptionStat?.totalConsumptionLast30Days)
    ) {
      warnMessage("It is impossible to create Asset Swap task because a low gas consumption");
      return;
    }

    if (taskType === "Meter Change" && !checkedReasons?.checked?.length) {
      warnMessage("It is impossible to create Meter Change task without Meter Change Reason(s)");
      return;
    }

    setLoading(true);

    const assignedBy = JSON.parse(localStorage.getItem("b2c:authUser")).email || "";
    const options = {
      assignedBy: assignedBy,
      assignedTo: taskType === "Investigation" ? selectedTss.email : data?.technician,
      assignedToName: taskType === "Investigation" ? selectedTss.name : data?.technicianName || assignedBy,
      assignedToDepot: taskType === "Investigation" ? selectedTss?.depot : data?.depot,
      comments: "Manually created task through NGI Dashboard",
      category: taskType,
      initiateReasons: checkedReasons?.checked ? checkedReasons?.checked.join(",") : "",
      customer: {
        id: data?.id,
        name: `${data?.firstName} ${data?.lastName}`,
        phone: data?.phone || data?.secondaryPhone,
        status: data?.status,
        location: {
          lat: data?.location?.lat || 0,
          lon: data?.location?.lon || 0,
        },
        address: data?.location?.region,
      },
      extraData: {
        ...(taskType === "Safety"
          ? {
              isSafetyIssue: true,
            }
          : {}),
      },
    };

    createBulkTaskApi({ tasks: [options], source: "ngi-dashboard" })
      .then(res => {
        setLoading(false);
        if (!!res?.data?.result?.duplicates?.length) {
          warnMessage(`Task with ${taskType} has been already assigned.`);
        } else successMessage(`Task was assigned successfully`);
      })
      .catch(error => {
        setLoading(false);
        defaultNgiErrorMethod(error);
      });
  }, [data, taskType, selectedTss, checkedReasons]);

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);
  const onTypeChange = useCallback(value => {
    setTaskType(value);
    setExpandedKeys([]);
    setCheckedReasons([]);
    onFieldsChanged();
  }, []);

  const closeConfirmationModal = () => setIsConfirmationVisible(false);
  const openConfirmationModal = () => setIsConfirmationVisible(true);

  useEffect(() => {
    getUsersList({
      role: USER_ROLES.TSS,
      ...(userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])
        ? {}
        : {
            depot: AUTH_USER?.depotName,
          }),
      pageSize: 500,
    }).then(res => {
      setUsers(res?.data?.result?.items);
    });
  }, []);

  return (
    <div>
      <NGIPrompt show={isDirty} />
      <Spin spinning={isLoading}>
        <Col span={12}>
          <Title level={5}>Task type:</Title>
          <NGISelect
            showSearch
            style={{ width: "100%" }}
            placeholder={"Select type"}
            value={taskType}
            onChange={onTypeChange}
          >
            {TASK_TYPES.map(item => {
              if (item === "Stove Change" && data?.stoveChangeEligibilityStatus !== "Valid") return null;
              if (
                item === "Asset Swap" &&
                !userHasOneOfRoles([
                  USER_ROLES.SYSTEM_FULL_ADMINS,
                  USER_ROLES.A_SM,
                  USER_ROLES.D_SM,
                  USER_ROLES.OPERATION_MANAGERS,
                ])
              )
                return null;
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })}
          </NGISelect>
        </Col>
        <br />

        {taskType === "Meter Change" && (
          <>
            <Col span={12}>
              <Title level={5}>Meter Change Reason:</Title>
              <Tree
                className={classes.tree}
                checkable
                showLine
                autoExpandParent={true}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                onCheck={onCheck}
                checkedKeys={checkedReasons}
                checkStrictly={true}
                selectable={false}
                treeData={treeData}
              />
            </Col>
            <br />
          </>
        )}
        {taskType === "Investigation" && (
          <>
            <Col span={12}>
              <Title level={5}>TSS:</Title>
              <TSRSelect
                showSearch
                initialList={users}
                handleChange={setSelectedTss}
                roles={[USER_ROLES.TSS]}
                depot={!userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS]) && AUTH_USER?.depotName}
              />
            </Col>
            <br />
          </>
        )}

        <Space>
          <CustomButton
            disabled={taskType === "Investigation" && !selectedTss}
            onClick={openConfirmationModal}
            type="primary"
            text="Save"
            size="small"
          />
        </Space>
      </Spin>
      {isConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeConfirmationModal}
          onConfirm={handleSubmit}
          description="Are you sure you want to create new task?"
        />
      )}
    </div>
  );
};

export default CreateTaskSection;
