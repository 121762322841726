import { USER_ROLES } from "utils/constants";

export const BREADCRUMBS = [
  {
    name: "STOCK MANAGEMENT",
  },
  {
    name: "ALL TRIPS",
  },
  {
    name: "Create Trip",
    roles: [USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.LOGISTICS],
  },
];

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];
