import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import depot from "assets/images/svg/depot.svg";
import calendarIcon from "assets/images/svg/calendarMinus.svg";
import locationMarker from "assets/images/svg/locationMarker.svg";
import CustomButton from "components/Common/buttons/CustomButton";
import { TRIP_STATUSES } from "pages/stock-management/trips/constants";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_ROLES } from "utils/constants";
import cancelIcon from "assets/images/svg/cancel-white.svg";
import SpotTripModal from "pages/stock-management/trips/tripDetails/SpotTripModal";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  button: {
    marginLeft: 10,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    margin: "0 20px 0 20px",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  inactive: {
    backgroundColor: "#c31d17",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    marginRight: 20,

    "& img": {
      width: 24,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
    textTransform: "uppercase",
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ id, trip, onMarkAsPlanned, onStopTrip, hasAssets }) => {
  const classes = useStyles();
  const [open, setIsOpen] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(false);

  const { status, startLocation, startDate, startTime, stops } = trip;

  const onOpenConfirm = useCallback(() => setIsOpen(true), []);
  const onCloseConfirm = useCallback(() => setIsOpen(false), []);

  const onOpenConfirmStop = useCallback(() => setOpenStopModal(true), []);
  const onCloseConfirmStop = useCallback(() => setOpenStopModal(false), []);

  const onConfirm = useCallback(() => {
    onCloseConfirm();
    onMarkAsPlanned();
  }, []);

  const onStopTripConfirm = useCallback(values => {
    onCloseConfirmStop();
    onStopTrip(values);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/stock-management/trips"}>Back</BackButtonLink>
        <div className={classes.nameContainer}>
          <span>{id}</span>
          {status && <div className={classes.status}>{status}</div>}
        </div>
      </div>
      <div className={classes.rightContainer}>
        {status === TRIP_STATUSES.IN_TRANSIT && (
          <CustomButton
            iconImg={cancelIcon}
            roles={[USER_ROLES.SYSTEM_FULL_ADMINS]}
            onClick={onOpenConfirmStop}
            type="error"
            text="Stop Trip"
          />
        )}
        {openStopModal && (
          <SpotTripModal hasAssets={hasAssets} onCancel={onCloseConfirmStop} onConfirm={onStopTripConfirm} />
        )}
        {status === TRIP_STATUSES.PENDING && (
          <CustomButton
            roles={[USER_ROLES.DEPOT_CLERK, USER_ROLES.WAREHOUSE, USER_ROLES.LOGISTICS]}
            onClick={onOpenConfirm}
            type="primary"
            text="Mark as Planned"
          />
        )}
        {open && (
          <ConfirmationModal
            onCancel={onCloseConfirm}
            onConfirm={onConfirm}
            description={`Please be sure that stop order is correct. If yes, please mark this trip as Planned.`}
          />
        )}
        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <img src={depot} alt="start location" />
            <div>
              <div className={classes.title}>START LOCATION</div>
              <div className={classes.data}>{startLocation}</div>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.section}>
            <img src={calendarIcon} alt="Trip Date & Time" />
            <div>
              <div className={classes.title}>Trip Date & Time</div>
              <div className={classes.data}>
                {startDate} {startTime}
              </div>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.section}>
            <img src={locationMarker} alt="start location" />
            <div>
              <div className={classes.title}>Stops</div>
              <div className={classes.data}>{stops?.length}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
