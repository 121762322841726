import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import {
  fetchCustomerById,
  fetchDepotDetails,
  getPackages,
  getUserByEmail,
  getUsersList,
  retrieveCustomerPhotoUrlByType,
  retrieveSurveysListByIdOrName,
} from "utils/api";
import { Spin } from "antd";
import DataTabs from "pages/operations/leadDetails/DataTabs";
import Header from "pages/operations/leadDetails/Header";
import { extractPromisesResult } from "utils/helpers/functions";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { getLeadQuestionnaire } from "pages/operations/leadDetails/utils";
import { AUTH_USER } from "utils/constants";
import { errorMessage } from "components/Common/responses/message";

const LeadDetails = () => {
  const { id } = useParams();

  const [lead, setLead] = useState({});
  const [packages, setPackages] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [TSRList, setTSRList] = useState([]);
  const [currentLeadTsr, setCurrentLeadTsr] = useState(null);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [engineVersion, setEngineVersion] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    updateLeadData();
    dispatch(getDictionary(DICTIONARY_TYPES.CUSTOMER_TYPE));
    dispatch(getDictionary(DICTIONARY_TYPES.OCCUPATION));
    dispatch(getDictionary(DICTIONARY_TYPES.DOCUMENT_TYPE));
  }, []);

  useEffect(() => {
    if (lead?.id) {
      const questionnaireToGet = getLeadQuestionnaire(lead);
      retrieveSurveysListByIdOrName(questionnaireToGet)
        .then(res => {
          setQuestionnaire(res.data.result);
        })
        .catch(() => {
          errorMessage(
            `The survey ${
              questionnaireToGet.id ? questionnaireToGet.id : questionnaireToGet.name
            } is absent. The updates for lead will be unsaved.`
          );
          setQuestionnaire({ items: [] });
        });
    }
  }, [lead?.id]);

  useEffect(() => {
    if (lead?.id) {
      fetchDepotDetails({ name: lead.depot }).then(async ({ data }) => {
        const { sectors, taskEngine } = data.result;
        setSectors(sectors);
        setEngineVersion(taskEngine);
      });
      getUsersList({
        depot: lead.depot,
        hasTeam: true,
        pageSize: 3000,
      }).then(res => {
        const {
          data: {
            result: { items: list },
          },
        } = res;
        setTSRList(list);
      });
    }
    getUserByEmail(lead.technician).then(res => {
      setCurrentLeadTsr(res?.data?.result);
    });
  }, [lead?.id]);

  const updateLeadData = useCallback(() => {
    Promise.allSettled([
      fetchCustomerById(id),
      retrieveCustomerPhotoUrlByType(id, "id"),
      retrieveCustomerPhotoUrlByType(id, "personalPhoto"),
      retrieveCustomerPhotoUrlByType(id, "housePhoto"),
      getPackages({ status: "Active", depot: AUTH_USER?.depotName, pageSize: 100 }),
    ]).then(data => {
      const [leadInfo, customerIdPhoto, customerPersonalPhoto, customerHousePhoto, packages] =
        extractPromisesResult(data);
      setLead({
        ...leadInfo?.data?.result,
        idPhotoUrl: customerIdPhoto?.data?.result?.url,
        personalPhotoUrl: customerPersonalPhoto?.data?.result?.url,
        housePhotoUrl: customerHousePhoto?.data?.result?.url,
      });
      setPackages(packages?.data?.result?.items);
    });
  }, [id, AUTH_USER]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={!lead?.id}>
            <>
              <Header lead={lead} />
              <DataTabs
                packages={packages}
                update={updateLeadData}
                lead={lead}
                setLead={setLead}
                sectors={sectors}
                engineVersion={engineVersion}
                TSRList={TSRList}
                leadTsrData={currentLeadTsr}
                questionnaire={questionnaire}
              />
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default LeadDetails;
