import React, { useCallback, useState } from "react";
import { Col, Divider, Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { createBulkTAMRequest } from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import { BULK_REQUEST_TYPES, BULK_REQUEST_VALIDATION } from "pages/TAM/TAMRequests/constants";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";
import closeIcon from "assets/images/svg/closeSmall.svg";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "50%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  errorMsg: {
    color: "#ff4d4f",
    fontSize: 10,
    marginLeft: 18,
  },
  form: {
    width: "100%",

    "& .ant-row": {
      width: "100%",
    },
    "& .ant-typography": {
      fontWeight: 400,
      marginTop: 15,
    },
  },
  typeSelect: {
    width: 250,
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});

const NewBulkTAMRequestModal = ({ onClose, updateList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [selectedType, setSelectedType] = useState(BULK_REQUEST_TYPES[0]);
  const [uploadedData, setUploadedData] = useState("");
  const [file, setFile] = useState(null);

  const readUploadFile = useCallback(
    e => {
      const file = e.file;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = e => {
          const data = e.target.result;
          const workbook = xlsx.read(data, {
            cellDates: true,
            dateNF: 'mm"-"dd"-"yyyy HH":"mm":"ss',
          });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
          const csv = xlsx.utils.sheet_to_csv(worksheet, { raw: false });

          const formattedResult = json.map(row => ({ ...row }));
          const noError = formattedResult.every(
            item => BULK_REQUEST_VALIDATION[selectedType].sort().join() === Object.keys(item).sort().join()
          );

          !noError &&
            errorMessage(
              `Please upload the correct ‘*.csv’ file with ${BULK_REQUEST_VALIDATION[selectedType].join(", ")}.`
            );

          if (noError) {
            setUploadedData(csv);
            setFile(file);
          } else {
            setUploadedData("");
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    [selectedType]
  );

  const handleSave = useCallback(() => {
    setLoader(true);
    createBulkTAMRequest(selectedType, uploadedData)
      .then(() => {
        successMessage("Bulk TAM request was created successfully");
        onClose();
        updateList();
        setLoader(false);
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [uploadedData, selectedType]);

  const handleChange = useCallback(value => {
    form.resetFields();
    setSelectedType(value);
    setUploadedData([]);
    setFile(null);
  }, []);

  const removeFile = useCallback(() => {
    setFile(null);
    setUploadedData([]);
  }, []);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>NEW BULK TAM REQUEST</div>
          <NGISelect
            showSearch
            className={classes.typeSelect}
            value={selectedType}
            onChange={handleChange}
            placeholder="Please select TAM case"
          >
            {BULK_REQUEST_TYPES.map(item => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))}
          </NGISelect>
        </div>
      }
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <Col span={24}>
            {file ? (
              <div className={classes.fileContainer}>
                <div className={classes.fileTitle}>{`${file.name} (${uploadedData.length})`}</div>
                <div onClick={removeFile} className={classes.removeBtn}>
                  <img src={closeIcon} alt="remove" />
                </div>
              </div>
            ) : (
              <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                <img className="upload-bg" src={uploadArea} alt="upload" />
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single upload. Strictly prohibit from uploading company data or other band files
                </p>
              </Dragger>
            )}
          </Col>
        </div>
      </Spin>
      <Divider />
      <Space>
        <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
        <CustomButton
          onClick={handleSave}
          disabled={!uploadedData.length}
          type="primary"
          size="small"
          text="UPLOAD REQUESTS"
        />
      </Space>
    </Modal>
  );
};

export default NewBulkTAMRequestModal;
