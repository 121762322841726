import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";

export const TABS = {
  SETTINGS: "SETTINGS",
  HARDWARE_REPORTS: "HARDWARE_REPORTS",
  ALERTS: "ALERTS",
  CUMULATIVE_REPORTS: "CUMULATIVE_REPORTS",
  METER_STATUS: "METER_STATUS",
};

export const TABS_BUTTONS = [
  {
    title: "Settings",
    key: TABS.SETTINGS,
  },
  {
    title: "Hardware Report",
    key: TABS.HARDWARE_REPORTS,
  },
  {
    title: "Alerts",
    key: TABS.ALERTS,
  },
  {
    title: "Cumulative Report",
    key: TABS.CUMULATIVE_REPORTS,
  },
  {
    title: "Meter Status",
    key: TABS.METER_STATUS,
  },
];

export const settingsColumns = [
  {
    title: "Start Period",
    dataIndex: "startPeriod",
    key: "startPeriod",
  },
  {
    title: "Update Period",
    dataIndex: "updatePeriod",
    key: "updatePeriod",
  },
  {
    title: "Voltage Warn",
    dataIndex: "warningLowBatteryVoltage",
    key: "warningLowBatteryVoltage",
  },
  {
    title: "Credit Warn",
    dataIndex: "warningLowCreditBalance",
    key: "warningLowCreditBalance",
  },
  {
    title: "Gas Warn",
    dataIndex: "warningLowGasMass",
    key: "warningLowGasMass",
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    key: "unitPrice",
  },
  {
    title: "Gas Level",
    dataIndex: "gasRemaining",
    key: "gasRemaining",
  },
];

export const reportColumns = [
  {
    title: "Voltage",
    dataIndex: "batteryModel",
    key: "batteryModel",
    render: (cellContent, report) => textFormatter(report.batteryModel),
  },
  {
    title: "Meter Serial",
    dataIndex: "meterSerialNumber",
    key: "meterSerialNumber",
    render: (cellContent, report) => textFormatter(report.meterSerialNumber),
  },
  {
    title: "MCU FV",
    dataIndex: "mcuFirmwareVersion",
    key: "mcuFirmwareVersion",
    render: (cellContent, report) => textFormatter(report.mcuFirmwareVersion),
  },
  {
    title: "PCB V",
    dataIndex: "pcbVersion",
    key: "pcbVersion",
    render: (cellContent, report) => textFormatter(report.pcbVersion),
  },
  {
    title: "MCU CDate",
    dataIndex: "mcuCompileDate",
    key: "mcuCompileDate",
    render: (cellContent, report) => textFormatter(report.mcuCompileDate),
  },
  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
  },
];

export const meterStatusColumns = [
  {
    title: "Volts",
    dataIndex: "batteryVoltage",
    key: "batteryVoltage",
    render: (cellContent, report) => textFormatter(report.batteryVoltage),
    width: 100,
  },
  {
    title: "CS Sent",
    dataIndex: "cookingSessionSent",
    key: "cookingSessionSent",
    render: (cellContent, report) => textFormatter(report.cookingSessionSent),
    width: 100,
  },
  {
    title: "Signal",
    dataIndex: "gsmSignalIntensity",
    key: "gsmSignalIntensity",
    render: (cellContent, report) => textFormatter(report.gsmSignalIntensity),
    width: 100,
  },
  {
    title: "Light",
    dataIndex: "lidLightSensorStatus",
    key: "lidLightSensorStatus",
    render: (cellContent, report) => textFormatter(report.lidLightSensorStatus),
    width: 100,
  },
  {
    title: "Lock",
    dataIndex: "meterLockStatus",
    key: "meterLockStatus",
    render: (cellContent, report) => textFormatter(report.meterLockStatus),
    width: 100,
  },
  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
    width: 100,
  },
];

export const alertColumns = [
  {
    title: "type",
    dataIndex: "type",
    key: "type",
    render: (cellContent, report) => textFormatter(report.type),
  },
  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
  },
];

export const cumulativeReportColumns = [
  {
    title: "Gas Level",
    dataIndex: "gasRemaining",
    key: "gasRemaining",
    render: (cellContent, report) => textFormatter(report.gasRemaining),
    width: 100,
  },
  {
    title: "Credit",
    dataIndex: "currentCredit",
    key: "currentCredit",
    render: (cellContent, report) => textFormatter(report.currentCredit),
    width: 100,
  },
  {
    title: "Cumulative Credit",
    dataIndex: "endCumulativeCredit",
    key: "endCumulativeCredit",
    render: (cellContent, report) => textFormatter(report.endCumulativeCredit),
    width: 100,
  },
  {
    title: "Cumulative Gas",
    dataIndex: "endCumulativeMass",
    key: "endCumulativeMass",
    render: (cellContent, report) => textFormatter(report.endCumulativeMass.toFixed(2)),
    width: 100,
  },
  {
    title: "CS Sent",
    dataIndex: "cookingSessionSent",
    key: "cookingSessionSent",
    render: (cellContent, report) => textFormatter(report.cookingSessionSent),
    width: 100,
  },
  {
    title: "Signal",
    dataIndex: "gsmSignalIntensity",
    key: "gsmSignalIntensity",
    render: (cellContent, report) => textFormatter(report.gsmSignalIntensity),
    width: 100,
  },

  {
    title: "Received At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (cellContent, report) => textFormatter(customDateFormatWithTime(report.createdAt)),
    width: 150,
  },
];
