import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import tasksIcon from "../../../../../../assets/images/svg/tasks.svg";
import allReadyForRepair from "../../../../../../assets/images/svg/spareParts.svg";
import depotIcon from "../../../../../../assets/images/svg/depot.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import { customDateFormatWithTime } from "components/Common/formatter";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ meterBarcode, customerId, cookingTime, depot }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/meters/logs/session"}>Back</BackButtonLink>

        <div className={classes.nameContainer}>
          <span>{meterBarcode}</span>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <img src={tasksIcon} alt="task count" />
            <div>
              <div className={classes.title}>CUSTOMER ID</div>
              <div className={classes.data}>{customerId}</div>
            </div>
          </div>
          <div className={classes.section}>
            <img src={allReadyForRepair} alt="task count" />
            <div>
              <div className={classes.title}>COOKING TIME</div>
              <div className={classes.data}>{customDateFormatWithTime(cookingTime)}</div>
            </div>
          </div>
          <div className={classes.section}>
            <img src={depotIcon} alt="task count" />
            <div>
              <div className={classes.title}>DEPOT</div>
              <div className={classes.data}>{depot}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
