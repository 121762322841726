import React, { useState, useEffect } from "react";
import { Col, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import CustomButton from "components/Common/buttons/CustomButton";
import PackagesFilter from "pages/packages/all-packages/PackagesFilter";
import { packageColumns } from "pages/packages/all-packages/packageColumns";
import NGITable from "components/Common/NGITable";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import CustomLink from "components/Common/buttons/CustomLink";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { getPackages } from "utils/api";

const Packages = () => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState(null);
  const [loading, setLoading] = useState(false);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.PACKAGES);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  useEffect(() => {
    handleOnFilter(filters);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [packages]);

  const handleOnFilter = (filterOptions = {}) => {
    setLoading(true);
    setFilters(filterOptions);

    getPackages({ ...filterOptions })
      .then(res => {
        setPackages(res?.data || null);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="filters-wrapper">
          <div className="filter-buttons">
            <CustomLink to={`/packages/package`}>
              <CustomButton
                hideRoles={[
                  USER_ROLES.INTERNAL_AUDITOR,
                  USER_ROLES.D_SM,
                  USER_ROLES.A_SM,
                  USER_ROLES.OPERATION_MANAGERS,
                  USER_ROLES.GENERAL,
                  USER_ROLES.IT_OPERATIONS,
                  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                  USER_ROLES.FINANCE,
                  USER_ROLES.PRODUCT_TECHNICIANS,
                ]}
                type="primary"
                text="New Package"
                icon={<i className="bx bx-plus-circle" />}
              />
            </CustomLink>
          </div>
          <PackagesFilter onFilter={handleOnFilter} />
        </div>
        <Col lg={12}>
          <NGITable
            filtersType={FILTER_PAGE.PACKAGES}
            updateList={handleOnFilter}
            isLoading={loading}
            columns={packageColumns()}
            data={packages}
          />
        </Col>
      </Container>
    </div>
  );
};

export default Packages;
