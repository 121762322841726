import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { getTAMList, getUsersList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { TAMRequestsListColumns } from "pages/TAM/TAMRequests/TAMRequestsListColumns";
import TAMRequestsSearch from "pages/TAM/TAMRequests/TAMRequestsSearch";
import { isEmpty } from "lodash";
import NGIDropdownButton from "components/Common/buttons/NGIDropdownButton";
import CustomButton from "components/Common/buttons/CustomButton";
import NewTAMRequestModal from "pages/TAM/TAMRequests/NewTAMRequestModal";
import { makeStyles } from "@material-ui/core/styles";
import ViewTAMReportModal from "pages/TAM/TAMRequests/ViewTAMReportModal";
import ApproveDeclineTAMReportModal from "pages/TAM/TAMRequests/ApproveDeclineTAMReportModal";
import { userHasOneOfRoles } from "utils/helpers/functions";
import NewBulkTAMRequestModal from "pages/TAM/TAMRequests/NewBulkTAMRequestModal";

const useStyles = makeStyles({
  overflowText: {
    overflow: "hidden",
    maxWidth: 110,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});
const TAMRequests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [response, setResponse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.TAM_REQUESTS);
  const [TSRList, setTSRList] = useState([]);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch({ status: "Pending" });
    getUsersList({
      role: [
        USER_ROLES.SYSTEM_FULL_ADMINS,
        USER_ROLES.TECHNICIANS,
        USER_ROLES.MTSR,
        USER_ROLES.LT,
        USER_ROLES.TRAINING,
        USER_ROLES.TSS,
        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        USER_ROLES.IT_OPERATIONS,
        USER_ROLES.CUSTOMER_CARE,
      ].join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
    dispatch(getDictionary(DICTIONARY_TYPES.TAM_REASONS));
  }, []);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const openBulkModal = useCallback(() => setIsBulkModalOpen(true), []);
  const closeBulkModal = useCallback(() => setIsBulkModalOpen(false), []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;

    if (items?.length === 0) {
      warnMessage("TAM Requests were not found");
      setLoader(false);
    }

    setResponse(res?.data || {});
    setLoader(false);
  }, []);

  const handleSearch = useCallback((filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);
    getTAMList({
      ...filterOptions,
    })
      .then(async res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setResponse(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const onDetailsOpen = useCallback(item => setActiveItem(item), []);
  const onDetailsClose = useCallback(() => setActiveItem(null), []);

  const columns = useMemo(() => {
    const col = TAMRequestsListColumns(classes, onDetailsOpen);
    if (userHasOneOfRoles([USER_ROLES.CUSTOMER_CARE])) col.pop();
    return col;
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <NGIDropdownButton
                hideRoles={[
                  USER_ROLES.D_SM,
                  USER_ROLES.A_SM,
                  USER_ROLES.OPERATION_MANAGERS,
                  USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
                ]}
                toggleEl={<CustomButton icon={<i className="bx bx-plus-circle" />} type="primary" text="NEW REQUEST" />}
                menuItems={[
                  {
                    label: "Single Request",
                    callback: openModal,
                  },
                  {
                    label: "Bulk Request",
                    callback: openBulkModal,
                  },
                ]}
              />
            </div>

            <TAMRequestsSearch handleSearch={handleSearch} TSRList={TSRList} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.TAM_REQUESTS}
              isLoading={isLoading}
              columns={columns}
              updateList={handleSearch}
              data={response}
            />
          </Col>
        </Container>
        {isModalOpen && <NewTAMRequestModal onClose={closeModal} updateList={handleSearch} />}
        {isBulkModalOpen && <NewBulkTAMRequestModal onClose={closeBulkModal} updateList={handleSearch} />}
        {activeItem && activeItem.status !== "Pending" && (
          <ViewTAMReportModal onClose={onDetailsClose} activeItem={activeItem} />
        )}
        {activeItem && activeItem.status === "Pending" && (
          <ApproveDeclineTAMReportModal onClose={onDetailsClose} activeItem={activeItem} update={handleSearch} />
        )}
      </div>
    </>
  );
};

export default TAMRequests;
