import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { retrieveSegmentDetails } from "utils/api";
import Header from "./Header";
import { Divider, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import CustomButton from "components/Common/buttons/CustomButton";
import { Link } from "react-router-dom";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: 30,
    width: "100%",
  },
  informationContainer: {
    display: "flex",
    width: "100%",
    paddingRight: 25,
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "25%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 14,
  },
  editContainer: {
    backgroundColor: "white",
    borderRadius: 30,
    marginTop: 20,
    padding: 20,
    flex: "1 auto",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancel: { marginRight: 10 },
  container: { height: "100%", display: "flex", flexDirection: "column" },
});

const SegmentDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [priseDetails, setPriceDetails] = useState({});

  useEffect(() => {
    setLoader(true);
    retrieveSegmentDetails(id)
      .then(({ data }) => {
        setLoader(false);
        setPriceDetails(data?.result);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [id]);

  return (
    <>
      <div className={classnames("page-content", classes.container)}>
        <Container fluid className={classes.container}>
          <Spin spinning={isLoading}>
            <>
              <Header data={priseDetails} />
              <div className={classes.editContainer}>
                <div className={classes.root}>
                  <div className={classes.informationContainer}>
                    <div className={classes.column}>
                      <div className={classes.sectionTitle}>General Information</div>
                      <div className={classes.rowData}>
                        <div className={classes.title}>Price Value</div>
                        <div className={classes.data}>{textFormatter(priseDetails?.unitPrice)}</div>
                      </div>
                      <div className={classes.rowData}>
                        <div className={classes.title}>Customer Qty</div>
                        <div className={classes.data}>{textFormatter(priseDetails?.activeCustomersNumber)}</div>
                      </div>
                      <div className={classes.rowData}>
                        <div className={classes.title}>Date Created</div>
                        <div className={classes.data}>
                          {textFormatter(customDateFormatWithTime(priseDetails?.createdAt))}
                        </div>
                      </div>
                      <div className={classes.rowData}>
                        <div className={classes.title}>Created By</div>
                        <div className={classes.data}>{textFormatter(priseDetails?.createdBy)}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className={classes.buttons}>
                  <Link to={"/price-management/segments"}>
                    <CustomButton
                      type="primary"
                      className={classes.cancel}
                      color="outlined"
                      size="small"
                      text="Cancel"
                    />
                  </Link>
                </div>
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default SegmentDetails;
