export const getRequiredFormFields = name => {
  try {
    const form = document.getElementsByName(name);
    const elements = form[0]?.querySelectorAll("[data-is-required]") || [];
    const names = [...elements]?.map(item => {
      const { isRequired, name } = item.dataset;
      return isRequired === "true" && name;
    });
    return names.filter(item => item !== "");
  } catch (e) {
    return [];
  }
};
