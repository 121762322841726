import { textFormatter } from "components/Common/formatter";

export const vehicleListColumns = () => [
  {
    title: "Licence Plate",
    dataIndex: "licencePlate",
    render: textFormatter,
  },
  {
    title: "Unit Type",
    dataIndex: "unitType",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "QR Code",
    dataIndex: "qrCode",
    render: textFormatter,
  },
];
