import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",

    "&> div:not(:first-child)": {
      borderLeft: "1px solid rgba(0, 0, 0, 0.08)",
    },

    "& div": {
      borderRadius: 0,
    },

    "&>:first-child": {
      borderRadius: "5px 0 0 5px",
    },

    "&>:last-child": {
      borderRadius: "0 5px 5px 0",
    },

    "&>:last-child:first-child": {
      borderRadius: "5px 5px 5px 5px",
    },
  },
  primary: {
    "&>.dropdown": {
      border: 0,
      background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",

      "&>span": {
        padding: 0,
        border: 0,

        "&>div": {
          background: "transparent",
        },
      },
    },

    "&>:first-child": {
      borderRadius: "30px 0 0 30px",
    },

    "&>:last-child": {
      borderRadius: "0 30px 30px 0",
    },

    "&>:last-child:first-child": {
      borderRadius: 30,
    },
  },
});

const ButtonGroupContainer = ({ children, color }) => {
  const classes = useStyles();
  return <div className={classnames(classes.root, color === "primary" && classes.primary)}>{children}</div>;
};
export default ButtonGroupContainer;
