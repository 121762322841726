import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  button: {
    marginLeft: 10,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ team }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/depots/teams"}>Back</BackButtonLink>
        <div className={classes.nameContainer}>
          <span>{team.name}</span>
        </div>
      </div>
      <div className={classes.rightContainer}></div>
    </div>
  );
};

export default Header;
