import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import React from "react";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import infoIcon from "assets/images/svg/Info.svg";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";
import CustomLink from "components/Common/buttons/CustomLink";

export const refundRequestListColumns = handleDetails => [
  {
    title: "Customer ID",
    dataIndex: "customerNumber",
    render: id => (
      <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
        {id}
      </CustomLink>
    ),
  },
  {
    title: "Refund Amount",
    dataIndex: "amount",
  },
  {
    title: "Type",
    dataIndex: "type",
    render: cellContent => textFormatter(cellContent?.toUpperCase()),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: cellContent => textFormatter(cellContent?.toUpperCase()),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  ...(!userHasOneOfRoles([USER_ROLES.CUSTOMER_CARE, USER_ROLES.GENERAL])
    ? [
        {
          dataIndex: "",
          title: "",
          width: "4%",
          render: row => (
            <ButtonGroup
              hideRoles={[USER_ROLES.CUSTOMER_CARE, USER_ROLES.GENERAL]}
              tooltipTitle="Report details"
              icon={infoIcon}
              handleClick={() => {
                handleDetails(row);
              }}
            />
          ),
        },
      ]
    : []),
];
