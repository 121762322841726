export const TABS = {
  EDIT_INITIATIVE: "EDIT_INITIATIVE",
};

export const tabButtons = [
  {
    title: "Initiative Details",
    key: TABS.EDIT_INITIATIVE,
  },
];

export const getBreacrumbs = isCreate => [
  {
    name: "Marketing",
  },
  {
    name: "Marketing Initiatives",
  },
  {
    name: isCreate ? "Create New Initiative" : "Initiative Details",
  },
];

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];
