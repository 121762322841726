import React, { useCallback, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import {
  createSegment,
  editSegment,
  getUploadSegmentUrl,
  retrieveSegmentDetails,
  uploadMetersToS3Bucket,
} from "utils/api";
import Header from "./Header";
import { Col, Form, Row, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import NGIInput from "components/Common/inputs/NGIInput";
import CustomButton from "components/Common/buttons/CustomButton";
import { Link } from "react-router-dom";
import { defaultNgiErrorMethod, errorMessage, successMessage, warnMessage } from "components/Common/responses/message";
import { history } from "store";
import NGIPrompt from "components/Common/NGIPrompt";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGICheckbox from "components/Common/inputs/NGICheckbox";
import closeIcon from "assets/images/svg/closeSmall.svg";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";

const useStyles = makeStyles({
  editContainer: {
    backgroundColor: "white",
    borderRadius: 30,
    marginTop: 20,
    padding: 20,
    flex: "1 auto",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancel: { marginRight: 10 },
  container: { height: "100%", display: "flex", flexDirection: "column" },
  form: { height: "100%", display: "flex", flexDirection: "column", width: "100%" },
  formContent: { flex: "1 auto" },
  checkbox: {
    display: "flex",
    alignItems: "center",

    "&>span:nth-child(2)": {
      paddingTop: 8,
    },
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  formItem: {
    display: "flex",

    "& .ant-col + .ant-col": {
      paddingLeft: 10,
    },
  },
  fileTitle: {
    fontWeight: 600,
    fontSize: 12,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

const EditSegment = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [file, setFile] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  useEffect(() => {
    if (id !== "new") {
      setLoader(true);
      retrieveSegmentDetails(id)
        .then(({ data }) => {
          setLoader(false);
          setInitialValues(data?.result);
        })
        .catch(err => {
          setLoader(false);
          defaultNgiErrorMethod(err);
        });
    }
  }, [id]);

  useEffect(() => {
    if (!!initialValues) form.setFieldsValue(initialValues);
    else form.resetFields();
  }, [form, initialValues]);

  const removeFile = useCallback(() => {
    setFile(null);
    setUploadedData(null);
  }, []);

  const readUploadFile = useCallback(e => {
    const file = e.file;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });

        const formattedResult = json.map(row => ({ ...row }));
        const noError = formattedResult.every(item => item.customerId || null);
        !noError && errorMessage("Please upload the correct ‘*.csv’ file with customerId field");
        if (noError) {
          setUploadedData(json);
          setFile(file);
        } else {
          setUploadedData(null);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const onSuccess = useCallback(() => {
    setLoader(false);
    history.push("/price-management/segments");
    successMessage(`Segment was ${id === "new" ? "created" : "saved"} successfully`);
  }, [id]);

  const onSubmit = useCallback(() => {
    isConfirmationOpen && closeConfirmationModal();
    const options = form.getFieldsValue();
    if (id === "new" && !uploadedData) {
      warnMessage(`Please upload the ‘*.csv’ file.`);
      return;
    }
    setLoader(true);
    setIsDirty(false);
    const normalizedData = {
      ...options,
      unitPrice: Number.parseInt(options?.unitPrice),
      isDefault: !!options?.isDefault,
    };

    const editedJson = uploadedData?.map(item => ({ ...item, segment: options.name }));
    const editedSheet = uploadedData && xlsx.utils.json_to_sheet(editedJson);
    const csv = uploadedData && xlsx.utils.sheet_to_csv(editedSheet, { raw: false });

    const request = id === "new" ? createSegment : editSegment;
    request(normalizedData, id)
      .then(() => {
        csv
          ? getUploadSegmentUrl()
              .then(async res => {
                const {
                  data: {
                    result: { url },
                  },
                } = res;
                if (!url) {
                  warnMessage("URL was not generated");
                } else {
                  uploadMetersToS3Bucket(url, csv);
                }
                onSuccess();
              })
              .catch(err => {
                setLoader(false);
                defaultNgiErrorMethod(err);
              })
          : onSuccess();
      })
      .catch(err => {
        setLoader(false);
        const errorMessage = err.response?.data?.errors[0];
        if (errorMessage.code === "PRICE_ALREADY_EXISTS")
          warnMessage(`${errorMessage.desc}, id: ${errorMessage?.items[0]?.id}`, 0);
        else defaultNgiErrorMethod(err);
      });
  }, [id, uploadedData, form, isConfirmationOpen]);

  const handleSubmit = useCallback(options => (options.isDefault ? openConfirmationModal() : onSubmit()), [onSubmit]);

  const openConfirmationModal = useCallback(() => setConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setConfirmationOpen(false), []);

  return (
    <>
      <NGIPrompt show={isDirty} />
      <div className={classnames("page-content", classes.container)}>
        <Container fluid className={classes.container}>
          <Spin spinning={isLoading}>
            <>
              <Header data={initialValues} />
              <div className={classes.editContainer}>
                <NGIForm
                  isUpdate
                  onChange={onFieldsChanged}
                  form={form}
                  className={classes.form}
                  onFinish={handleSubmit}
                >
                  <div className={classes.formContent}>
                    <Row className={classes.formItem}>
                      <Col span={12}>
                        <Title level={5}>Name *</Title>
                        <NGIFormItem name="name" rules={[{ required: true, message: "Please enter segment name" }]}>
                          <NGIInput placeholder="Segment name" disabled={id !== "new"} />
                        </NGIFormItem>
                      </Col>

                      <Col span={12}>
                        <Title level={5}>Price *</Title>
                        <NGIFormItem
                          name="unitPrice"
                          rules={[{ required: true, message: "Please enter segment price" }]}
                        >
                          <NGIInput placeholder="Segment price" type="number" min={1} />
                        </NGIFormItem>
                      </Col>
                    </Row>

                    <Col span={24} className="mb-3">
                      <Title level={5}>List of Users *.csv</Title>
                      {file ? (
                        <div className={classes.fileContainer}>
                          <div className={classes.fileTitle}>{`${file.name}`}</div>
                          <div onClick={removeFile} className={classes.removeBtn}>
                            <img src={closeIcon} alt="remove" />
                          </div>
                        </div>
                      ) : (
                        <>
                          <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                            <img className="upload-bg" src={uploadArea} alt="upload" />
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                              Support for a single upload. Strictly prohibit from uploading company data or other band
                              files
                            </p>
                          </Dragger>
                        </>
                      )}
                    </Col>
                    <Col span={12}>
                      <Title level={5}>Default Version</Title>
                      <Form.Item valuePropName="checked" name="isDefault">
                        <NGICheckbox className={classes.checkbox} disabled={initialValues.isDefault}>
                          Set as a default
                        </NGICheckbox>
                      </Form.Item>
                    </Col>
                  </div>

                  <div className={classes.buttons}>
                    <Link to={"/price-management/segments"}>
                      <CustomButton
                        type="primary"
                        className={classes.cancel}
                        color="outlined"
                        size="small"
                        text="Cancel"
                      />
                    </Link>

                    <CustomButton type="primary" htmlType="submit" size="small" text="Submit" disabled={!isDirty} />
                  </div>
                </NGIForm>
                {isConfirmationOpen && (
                  <ConfirmationModal
                    description="Are you sure you want to make this segment as default? This will reset the default flag for the previous segment."
                    onCancel={closeConfirmationModal}
                    onConfirm={onSubmit}
                    loading={isLoading}
                  />
                )}
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default EditSegment;
