/* ID_TYPES */

export const GET_ASSET_TYPES = "GET_ASSET_TYPES"
export const GET_ASSET_TYPES_FAIL = "GET_ASSET_TYPES_FAIL"
export const GET_ASSET_TYPES_SUCCESS = "GET_ASSET_TYPES_SUCCESS"

export const UPDATE_ASSET_TYPE_SUCCESS = "UPDATE_ASSET_TYPE_SUCCESS"
export const UPDATE_ASSET_TYPE_FAIL = "UPDATE_ASSET_TYPE_FAIL"
export const UPDATE_ASSET_TYPE = "UPDATE_ASSET_TYPE"

export const CREATE_ASSET_TYPE = "CREATE_ASSET_TYPE"
export const CREATE_ASSET_TYPE_FAIL = "CREATE_ASSET_TYPE_FAIL"
export const CREATE_ASSET_TYPE_SUCCESS = "CREATE_ASSET_TYPE_SUCCESS"

export const START_ACTION = "START_ACTION"
export const STOP_ACTION = "STOP_ACTION"
export const REFRESH_ACTION_START = "REFRESH_ACTION_START"
export const REFRESH_ACTION_STOP = "REFRESH_ACTION_STOP"
