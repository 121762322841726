import { textFormatter, weightFormatter } from "components/Common/formatter";

export const typeColumns = () => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (cellContent, type) => textFormatter(type.name),
    width: "15%",
  },
  {
    title: "Group",
    dataIndex: "group",
    key: "group",
    render: (cellContent, type) => textFormatter(type.group),
    width: "15%",
  },
  {
    title: "SAP ID",
    dataIndex: "sapId",
    key: "sapId",
    render: (cellContent, type) => textFormatter(type.sapId),
    width: "15%",
  },
  {
    dataIndex: "cylinderAttributes.cylinderCapacity",
    key: "cylinderCapacity",
    title: "Capacity",
    render: (cellContent, type) =>
      type.hasOwnProperty("cylinderAttributes")
        ? weightFormatter(type.cylinderAttributes.cylinderCapacity)
        : weightFormatter(undefined),
  },
];
