import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { retrieveSurveysHistory, retrieveSurveysListByIdOrName } from "utils/api";
import { Spin } from "antd";
import Header from "pages/settings/survey-management/surveyDetails/Header";
import DataTabs from "pages/settings/survey-management/surveyDetails/DataTabs";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";

const SurveyDetails = () => {
  const { id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [surveyHistory, setSurveyHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== "new") {
      setIsLoading(true);
      retrieveSurveysListByIdOrName({ id })
        .then(res => {
          const { name, depot } = res.data.result;
          setSurvey(res.data.result);
          retrieveSurveysHistory(name, depot).then(result => {
            const history = result.data.result.filter(item => item.status === "Inactive");
            setSurveyHistory(history);
          });
          setIsLoading(false);
        })
        .catch(e => {
          defaultNgiErrorMethod(e);
          setIsLoading(false);
        });
    }

    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <Header survey={survey} />
            <DataTabs
              isCreate={id === "new"}
              survey={survey}
              surveyHistory={surveyHistory}
              setIsLoading={setIsLoading}
            />
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default SurveyDetails;
