export const getEditableItems = (reports, meterInfo) => {
  let repairCounter = 0;
  let testingCounter = 0;
  if (!reports?.result?.items?.length) return reports;
  const editableItems = reports?.result?.items?.reduce((a, item) => {
    if (
      meterInfo?.maintenanceStartDate === item?.journeyStartDate &&
      meterInfo?.status === "Inactive" &&
      ((item?.type === "Testing" && testingCounter === 0) || (item?.type === "Repair" && repairCounter === 0))
    ) {
      if (item?.type === "Testing") testingCounter += 1;
      if (item?.type === "Repair") repairCounter = repairCounter += 1;
      a.push({ ...item, editable: true });
    } else a.push({ ...item });
    return a;
  }, []);
  reports.result.items = [...editableItems];
  return reports;
};
