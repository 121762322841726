export const TABS = {
  GENERAL_INFORMATION: "GENERAL_INFORMATION",
  QUESTIONNAIRE: "QUESTIONNAIRE",
};

export const TABS_BUTTONS = [
  {
    title: "General Information",
    key: TABS.GENERAL_INFORMATION,
  },
  {
    title: "Questionnaire",
    key: TABS.QUESTIONNAIRE,
    allowCategories: ["Intervention", "Follow Up", "Safety"],
  },
];

export const SORTED_GENERAL_KEYS = [
  "priority",
  "routineId",
  "assignedBy",
  "assignedTo",
  "assignedToName",
  "comments",
  "plannedVisitDatetime",
  "updatedAt",
  "updatedBy",
];
export const SORTED_CUSTOMER_KEYS = ["name", "id", "status", "phone", "location"];
