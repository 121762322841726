import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { fetchFailedPayments, resolveFailedPayment } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { failedListColumns } from "pages/payments/failed-payments/failedListColumns";
import FailedPaymentsSearch from "pages/payments/failed-payments/FailedPaymentsSearch";
import DiscardModal from "pages/payments/failed-payments/DiscardModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { userHasOneOfRoles } from "utils/helpers/functions";

const FailedPayments = () => {
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const { setFilters } = useStoreFilters(FILTER_PAGE.FAILED_PAYMENTS);

  const hideRoles = [
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.TRAINING,
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.GENERAL,
    USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
    USER_ROLES.FINANCE,
    USER_ROLES.PRODUCT_TECHNICIANS,
  ];

  useEffect(() => {
    handleFilter();
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Payments were not found");
      setLoader(false);
    }
    setList(res?.data || {});
    setLoader(false);
  }, []);

  const handleFilter = useCallback((filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    fetchFailedPayments({ ...filterOptions })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const openDiscardModal = useCallback(itemData => {
    setActiveItem(itemData);
  }, []);

  const closeDiscardModal = useCallback(() => {
    setActiveItem(null);
  }, []);

  const onDiscard = useCallback(id => {
    resolveFailedPayment({ id })
      .then(() => {
        closeDiscardModal();
        handleFilter();
        successMessage("The failed payment was resolved");
      })
      .catch(err => {
        closeDiscardModal();
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(() => {
    const col = failedListColumns(openDiscardModal);
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [AUTH_USER, hideRoles]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <FailedPaymentsSearch handleSearch={handleFilter} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.FAILED_PAYMENTS}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleFilter}
            />
          </Col>
        </Container>
        {activeItem && <DiscardModal onClose={closeDiscardModal} onDiscard={onDiscard} item={activeItem} />}
      </div>
    </>
  );
};

export default FailedPayments;
