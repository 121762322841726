import React, { useCallback, useState } from "react";
import { Divider, Modal, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    width: "400px!important",

    "& .ant-modal-header": {
      padding: "24px 24px 0 !important",
    },

    "& .ant-modal-body": {
      padding: "10px 24px !important",
    },
  },
  description: {
    color: "#666666",
    whiteSpace: "pre-wrap",
    padding: "0 10px",
  },
  buttonsList: {
    display: "flex",
    flexDirection: "column",
  },
  reasonBtn: {
    width: "100%",
    padding: "10px 15px",
    fontSize: 13,
    backgroundColor: "#fff",
    border: "2px solid #0000001A",
    borderRadius: 15,
    marginBottom: 10,

    "&>div": {
      color: "#666666B2",
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedBtn: {
    backgroundColor: "#0085FF",
    color: "#FFF",

    "&>div": {
      color: "#FFFFFFB2",
    },
  },
});

const UNASSIGN_REASONS = ["unassign", "createNew"];

const ConfirmationUnassignModal = ({ onConfirm, onCancel, loading = false }) => {
  const classes = useStyles();
  const [reason, setReason] = useState(UNASSIGN_REASONS[0]);

  const handleReasonChange = useCallback(e => setReason(e.currentTarget.dataset.type), []);
  const handleConfirm = useCallback(() => onConfirm(reason), [reason]);

  return (
    <Modal
      className={classes.root}
      open={true}
      closable={false}
      title="Confirm Unassigning STO"
      onCancel={onCancel}
      footer={[]}
    >
      <Spin spinning={loading}>
        <p className={classes.description}>By unassigning this Stock Transfer Order you want to proceed with:</p>
        <div className={classes.buttonsList}>
          <div
            onClick={handleReasonChange}
            data-type="unassign"
            className={classnames(classes.reasonBtn, reason === "unassign" && classes.selectedBtn)}
          >
            Unassign corresponding order
            <div>Unassign the reflective outbound order</div>
          </div>
          <div
            onClick={handleReasonChange}
            data-type="createNew"
            className={classnames(classes.reasonBtn, reason === "createNew" && classes.selectedBtn)}
          >
            Create new Inbound STO
            <div>Create new Inbound STO with the same qty.</div>
          </div>
        </div>
        <Divider />
        <Space>
          <CustomButton onClick={onCancel} size="small" type="primary" color="outlined" text="Cancel" />
          <CustomButton onClick={handleConfirm} size="small" type="primary" text="Confirm" />
        </Space>
      </Spin>
    </Modal>
  );
};

export default ConfirmationUnassignModal;
