import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Divider, Form, List, Radio, Space, Spin } from "antd";
import { activeProductsValidationString, deductionType, fieldsToConvert, TYPE, types, weekDays } from "./constants";
import Header from "./Header";
import Title from "antd/lib/typography/Title";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { addPackage } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { Option } from "antd/lib/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import NGIInput from "components/Common/inputs/NGIInput";
import NGICheckbox from "components/Common/inputs/NGICheckbox";
import { history } from "store";
import { covertObjWithNumbers, getTrimmedObjectValues } from "utils/helpers/functions";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGIPrompt from "components/Common/NGIPrompt";
import { USER_TENANT_NAME } from "utils/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  formItem: {
    width: 300,
  },
  form: {
    width: "50%",
  },
});

const NewPackage = () => {
  const groups = [
    {
      product: "Cylinder",
      isOwnedByCustomer: false,
      count: 1,
      selected: false,
    },
    {
      product: "Meter",
      isOwnedByCustomer: false,
      count: 1,
      selected: false,
    },
    {
      product: "Card",
      isOwnedByCustomer: false,
      count: 1,
      selected: false,
    },
    {
      product: "Stove",
      isOwnedByCustomer: false,
      count: 1,
      selected: false,
    },
  ];
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);
  const [stovePaymentAmount, setStovePaymentAmount] = useState(null);
  const [gasPaymentAmount, setGasPaymentAmount] = useState(null);
  const [activeType, setActiveType] = useState(null);
  const [products, setProducts] = useState(groups);
  const [activeProducts, setActiveProducts] = useState([]);
  const [activeDeductionPeriod, setDeductionPeriod] = useState(null);
  const [upfrontAmount, seUpfrontAmount] = useState(null);
  const classes = useStyles();
  const validateActiveProducts = useMemo(
    () =>
      activeProducts.length === 4 ||
      (activeProducts.length === 3 && activeProducts.every(el => activeProductsValidationString.includes(el.product))),
    [activeProducts, activeProductsValidationString]
  );

  const deductionPeriod =
    activeType === TYPE.INSTALLMENT_PURCHASE || activeType === TYPE.INSTALLMENT_SALES
      ? ["Weekly", "Monthly"]
      : ["Upon Payment", "Weekly", "Monthly"];

  const handleSelectProduct = useCallback(
    (type, number, value = null) => {
      if (type === "selected") {
        const newData = [...products];
        newData[number].selected = !newData[number].selected;
        setProducts(newData);
        setActiveProducts(newData.filter(item => item.selected === true));
      }
      if (type === "isOwnedByCustomer") {
        const newData = [...products];
        newData[number].isOwnedByCustomer = !newData[number].isOwnedByCustomer;
        setProducts(newData);
      }
      if (type === "count") {
        const newData = [...products];
        newData[number].count = value;
        setProducts(newData);
      }
    },
    [products, activeProducts]
  );
  const handleChangeDeductionPeriod = useCallback(value => setDeductionPeriod(value), []);
  const handleChangeStovePaymentAmount = useCallback(e => setStovePaymentAmount(e.target.value), []);
  const handleChangeGasPaymentAmount = useCallback(e => setGasPaymentAmount(e.target.value), []);
  const handleChangeType = useCallback(item => {
    setActiveType(item);
    onFieldsChanged();
  }, []);
  const handleBalanceChange = useCallback(item => setBalance(item), []);

  useEffect(() => {
    if (activeType === TYPE.OUTRIGHT_PURCHASE || activeType === TYPE.OUTRIGHT_SALES) {
      setStovePaymentAmount(balance?.target?.value);
      setDeductionPeriod("Upon Payment");
      form.setFieldsValue({
        deductionPeriod: "Upon Payment",
        stoveFirstPaymentAmount: balance?.target?.value,
      });
    } else if (activeType === TYPE.INSTALLMENT_PURCHASE || activeType === TYPE.INSTALLMENT_SALES)
      setDeductionPeriod(null);
  }, [activeType, balance]);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);
  useEffect(() => {
    setLoading(true);
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    setLoading(false);
  }, []);

  const handleSave = async (values = {}) => {
    setLoading(true);
    setIsDirty(false);
    const normalizedProducts = activeProducts.map(item => ({ ...item }));

    const hasAllDepots = !!values?.depots && values?.depots[0] === "allDepots";
    const { depots, ...otherOptions } = values;
    const options = { ...(hasAllDepots ? otherOptions : values), products: normalizedProducts };

    const mappedValues = covertObjWithNumbers(options, fieldsToConvert);

    await addPackage(getTrimmedObjectValues(mappedValues))
      .then(() => {
        setLoading(false);
        successMessage(`New package was added successfully`);
        history.push("/packages/list");
      })
      .catch(error => {
        setLoading(false);
        defaultNgiErrorMethod(error);
      });
  };

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  useEffect(() => {
    if (activeType === "Own Stove") seUpfrontAmount(gasPaymentAmount || 0);
    else if (activeType === TYPE.INSTALLMENT_PURCHASE || activeType === TYPE.INSTALLMENT_SALES)
      seUpfrontAmount(Number.parseInt(gasPaymentAmount || 0) + Number.parseInt(stovePaymentAmount || 0));
    else if (activeType === TYPE.OUTRIGHT_PURCHASE || activeType === TYPE.OUTRIGHT_SALES)
      seUpfrontAmount(Number.parseInt(gasPaymentAmount || 0) + Number.parseInt(balance?.target?.value || 0));
  }, [gasPaymentAmount, stovePaymentAmount, activeType, balance]);

  const onDepotChanged = useCallback(
    values => {
      if (values.some(el => el === "allDepots")) form.setFieldsValue({ depots: ["allDepots"] });
    },
    [form]
  );

  return (
    <>
      <NGIPrompt show={isDirty} />
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    <NGIForm
                      name="newPackage"
                      form={form}
                      onFinish={handleSave}
                      onChange={onFieldsChanged}
                      className={classes.form}
                    >
                      <Col span={24} gutter={20}>
                        <Title level={5}>Type</Title>
                        <NGIFormItem name="type">
                          <NGISelect
                            showSearch
                            style={{ width: "100%" }}
                            onChange={handleChangeType}
                            tokenSeparators={[","]}
                            size="default"
                            placeholder="Select package type"
                          >
                            {types.map(
                              item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ),
                              this
                            )}
                          </NGISelect>
                        </NGIFormItem>
                      </Col>
                      {!!activeType && (
                        <>
                          <Col span={24}>
                            <Title level={5}>Code</Title>
                            <NGIFormItem name="code" rules={[{ required: true, message: `Please enter the code` }]}>
                              <NGIInput placeholder={"Enter unique package code"} />
                            </NGIFormItem>
                          </Col>
                          <Col span={24}>
                            <Title level={5}>Name</Title>
                            <NGIFormItem name="name" rules={[{ required: true, message: `Please enter the name` }]}>
                              <NGIInput placeholder={"Enter package name"} />
                            </NGIFormItem>
                          </Col>
                          {activeType !== "Own Stove" && (
                            <Col span={24}>
                              <Title level={5}>Stove Balance</Title>
                              <NGIFormItem
                                name="balance"
                                rules={[
                                  {
                                    required:
                                      activeType === TYPE.INSTALLMENT_PURCHASE ||
                                      activeType === TYPE.OUTRIGHT_PURCHASE ||
                                      activeType === TYPE.OUTRIGHT_SALES ||
                                      activeType === TYPE.INSTALLMENT_SALES,
                                    message: "Please enter the value",
                                  },
                                ]}
                              >
                                <NGIInput
                                  type={"number"}
                                  min={1}
                                  placeholder={"Enter the full price of stove"}
                                  max={Number.MAX_SAFE_INTEGER}
                                  onChange={handleBalanceChange}
                                />
                              </NGIFormItem>
                            </Col>
                          )}
                          <Col span={24}>
                            <Title level={5}>Upfront Amount</Title>
                            <NGIFormItem>
                              <NGIInput type={"number"} value={upfrontAmount} disabled={true} />
                            </NGIFormItem>
                          </Col>
                          <Col span={24}>
                            <Title level={5}>Gas First Payment Amount</Title>
                            <NGIFormItem
                              name="gasFirstPaymentAmount"
                              rules={[{ required: true, message: "Please enter the gas first payment amount" }]}
                            >
                              <NGIInput
                                type={"number"}
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                placeholder={"Enter initial gas payment"}
                                onChange={handleChangeGasPaymentAmount}
                                value={gasPaymentAmount}
                              />
                            </NGIFormItem>
                          </Col>
                          {activeType !== "Own Stove" && (
                            <Col span={24}>
                              <Title level={5}>Stove First Payment Amount</Title>
                              <NGIFormItem
                                name="stoveFirstPaymentAmount"
                                rules={[
                                  {
                                    required:
                                      activeType === TYPE.INSTALLMENT_PURCHASE || activeType === TYPE.INSTALLMENT_SALES,
                                    message: "Please enter the value",
                                  },
                                ]}
                              >
                                <NGIInput
                                  type={"number"}
                                  min={0}
                                  placeholder={"Enter initial stove payment"}
                                  disabled={activeType === TYPE.OUTRIGHT_PURCHASE || activeType === TYPE.OUTRIGHT_SALES}
                                  max={
                                    activeType === TYPE.INSTALLMENT_PURCHASE || activeType === TYPE.INSTALLMENT_SALES
                                      ? balance - 1
                                      : Number.MAX_SAFE_INTEGER
                                  }
                                  onChange={handleChangeStovePaymentAmount}
                                  value={stovePaymentAmount}
                                />
                              </NGIFormItem>
                            </Col>
                          )}
                          {activeType !== "Own Stove" && (
                            <Col span={24}>
                              <Title level={5}>Stove Repair Cost</Title>
                              <NGIFormItem
                                name="stoveRepairCost"
                                rules={[
                                  {
                                    required:
                                      activeType === TYPE.OUTRIGHT_PURCHASE ||
                                      activeType === TYPE.INSTALLMENT_PURCHASE ||
                                      activeType === TYPE.OUTRIGHT_SALES ||
                                      activeType === TYPE.INSTALLMENT_SALES,
                                    message: "Please enter the Stove Repair Cost",
                                  },
                                ]}
                              >
                                <NGIInput
                                  type={"number"}
                                  min={0}
                                  max={Number.MAX_SAFE_INTEGER}
                                  placeholder={"Enter stove repair cost"}
                                />
                              </NGIFormItem>
                            </Col>
                          )}
                          {activeType !== "Own Stove" && (
                            <Col span={24}>
                              <Title level={5}>Warranty Duration</Title>
                              <NGIFormItem
                                name="warrantyDuration"
                                rules={[
                                  {
                                    required:
                                      activeType === TYPE.OUTRIGHT_PURCHASE ||
                                      activeType === TYPE.INSTALLMENT_PURCHASE ||
                                      activeType === TYPE.OUTRIGHT_SALES ||
                                      activeType === TYPE.INSTALLMENT_SALES,
                                    message: "Please enter the value",
                                  },
                                ]}
                              >
                                <NGIInput
                                  type={"number"}
                                  min={1}
                                  max={Number.MAX_SAFE_INTEGER}
                                  placeholder={"Enter duration in month"}
                                />
                              </NGIFormItem>
                            </Col>
                          )}
                          {activeType !== "Own Stove" && (
                            <Col span={24}>
                              <Title level={5}>Stove Change Eligibility Duration</Title>
                              <NGIFormItem
                                name="stoveChangeEligibilityDuration"
                                rules={[
                                  {
                                    required:
                                      activeType === TYPE.OUTRIGHT_PURCHASE ||
                                      activeType === TYPE.INSTALLMENT_PURCHASE ||
                                      activeType === TYPE.OUTRIGHT_SALES ||
                                      activeType === TYPE.INSTALLMENT_SALES,
                                    message: "Please enter the value",
                                  },
                                ]}
                              >
                                <NGIInput
                                  type={"number"}
                                  min={1}
                                  max={Number.MAX_SAFE_INTEGER}
                                  placeholder={"Enter duration in month"}
                                />
                              </NGIFormItem>
                            </Col>
                          )}
                          {activeType !== "Own Stove" && (
                            <Col span={24}>
                              <Title level={5}>Stove Eligible Number</Title>
                              <NGIFormItem
                                name="stoveChangeEligibleNumber"
                                rules={[
                                  {
                                    required:
                                      activeType === TYPE.OUTRIGHT_PURCHASE ||
                                      activeType === TYPE.INSTALLMENT_PURCHASE ||
                                      activeType === TYPE.OUTRIGHT_SALES ||
                                      activeType === TYPE.INSTALLMENT_SALES,
                                    message: "Please enter the value",
                                  },
                                ]}
                              >
                                <NGIInput
                                  type={"number"}
                                  min={1}
                                  max={Number.MAX_SAFE_INTEGER}
                                  placeholder={"Enter the total number of stove changes"}
                                />
                              </NGIFormItem>
                            </Col>
                          )}
                          {activeType !== "Own Stove" && (
                            <Col span={24} gutter={20}>
                              <Title level={5}>Deduction period</Title>
                              <NGIFormItem
                                name="deductionPeriod"
                                rules={[
                                  {
                                    required:
                                      activeType === TYPE.INSTALLMENT_PURCHASE || activeType === TYPE.INSTALLMENT_SALES,
                                    message: "Please enter the value",
                                  },
                                ]}
                              >
                                <NGISelect
                                  showSearch
                                  style={{ width: "100%" }}
                                  tokenSeparators={[","]}
                                  size="default"
                                  placeholder="Select deduction period"
                                  disabled={activeType === TYPE.OUTRIGHT_PURCHASE || activeType === TYPE.OUTRIGHT_SALES}
                                  onChange={handleChangeDeductionPeriod}
                                  value={activeDeductionPeriod}
                                >
                                  {deductionPeriod.map(
                                    item => (
                                      <Option key={item} value={item}>
                                        {item}
                                      </Option>
                                    ),
                                    this
                                  )}
                                </NGISelect>
                              </NGIFormItem>
                            </Col>
                          )}
                          {activeType !== "Own Stove" &&
                            activeType !== TYPE.OUTRIGHT_PURCHASE &&
                            activeType !== TYPE.OUTRIGHT_SALES && (
                              <Col span={24}>
                                <Title level={5}>Deduction day</Title>
                                <NGIFormItem
                                  name="deductionDay"
                                  rules={[
                                    {
                                      required:
                                        activeType === TYPE.INSTALLMENT_PURCHASE ||
                                        activeType === TYPE.INSTALLMENT_SALES,
                                      message: "Please enter the value",
                                    },
                                  ]}
                                >
                                  {activeDeductionPeriod === "Weekly" ? (
                                    <NGISelect showSearch placeholder={"Select day of week"}>
                                      {weekDays.map(
                                        item => (
                                          <Option key={item.name} value={item.value}>
                                            {item.name}
                                          </Option>
                                        ),
                                        this
                                      )}
                                    </NGISelect>
                                  ) : (
                                    <NGIInput
                                      type={"number"}
                                      min={0}
                                      max={28}
                                      placeholder={"Enter the ordinal numeral of the month's day (1-28)"}
                                    />
                                  )}
                                </NGIFormItem>
                              </Col>
                            )}
                          {activeType !== "Own Stove" &&
                            activeType !== TYPE.OUTRIGHT_PURCHASE &&
                            activeType !== TYPE.OUTRIGHT_SALES && (
                              <Col span={24}>
                                <Title level={5}>Deduction type</Title>
                                <NGIFormItem
                                  name="deductionType"
                                  rules={[
                                    {
                                      required:
                                        activeType === TYPE.INSTALLMENT_PURCHASE ||
                                        activeType === TYPE.INSTALLMENT_SALES,
                                      message: "Please enter value",
                                    },
                                  ]}
                                >
                                  <NGISelect
                                    showSearch
                                    style={{ width: "100%" }}
                                    tokenSeparators={[","]}
                                    size="default"
                                    placeholder="Select deduction type"
                                  >
                                    {deductionType.map(
                                      item => (
                                        <Option key={item} value={item}>
                                          {item}
                                        </Option>
                                      ),
                                      this
                                    )}
                                  </NGISelect>
                                </NGIFormItem>
                              </Col>
                            )}
                          {activeType !== "Own Stove" &&
                            activeType !== TYPE.OUTRIGHT_PURCHASE &&
                            activeType !== TYPE.OUTRIGHT_SALES && (
                              <Col span={24}>
                                <Title level={5}>Deduction value</Title>
                                <NGIFormItem
                                  name="deductionValue"
                                  rules={[
                                    {
                                      required:
                                        activeType === TYPE.INSTALLMENT_PURCHASE ||
                                        activeType === TYPE.INSTALLMENT_SALES,
                                      message: "Please enter the value",
                                    },
                                  ]}
                                >
                                  <NGIInput
                                    type={"number"}
                                    min={1}
                                    max={Number.MAX_SAFE_INTEGER}
                                    placeholder={"Enter deduction value"}
                                  />
                                </NGIFormItem>
                              </Col>
                            )}

                          <Col span={24}>
                            <NGIFormItem
                              name="products"
                              rules={[
                                {
                                  required: !validateActiveProducts,
                                  message: "Cylinder, Meter and Card are mandatory",
                                },
                              ]}
                            >
                              <Title level={5}>Products</Title>
                            </NGIFormItem>
                            <List
                              bordered
                              dataSource={products}
                              renderItem={(item, number) => (
                                <List.Item key={`list-item-${item.product}-${number}`} value={item}>
                                  <NGICheckbox
                                    checked={item.selected}
                                    onChange={() => {
                                      handleSelectProduct("selected", number);
                                    }}
                                  >
                                    {item.product}, owned by:
                                  </NGICheckbox>
                                  <Radio.Group
                                    onChange={() => {
                                      handleSelectProduct("isOwnedByCustomer", number);
                                    }}
                                    value={item.isOwnedByCustomer}
                                  >
                                    <Radio.Button value={false}>Company</Radio.Button>
                                    <Radio.Button value={true}>Customer</Radio.Button>
                                  </Radio.Group>
                                  <NGIInput
                                    type={"number"}
                                    min={1}
                                    max={5}
                                    style={{ width: "50px" }}
                                    value={item.count}
                                    disabled={true}
                                    onChange={e => {
                                      handleSelectProduct("count", number, e);
                                    }}
                                  />
                                </List.Item>
                              )}
                            />
                          </Col>
                          <Divider />
                          <Col span={24}>
                            <Title level={5}>Depots</Title>
                            <Col span={24}>
                              <NGIFormItem name="depots">
                                <NGISelect
                                  showSearch
                                  style={{ width: "100%" }}
                                  mode="multiple"
                                  tokenSeparators={[" ", ","]}
                                  size="default"
                                  placeholder="Select depots"
                                  onChange={onDepotChanged}
                                >
                                  <Option value={"allDepots"}>{"ALL DEPOTS"}</Option>
                                  {depot[USER_TENANT_NAME]?.length > 0 &&
                                    depot[USER_TENANT_NAME]?.sort()?.map(
                                      item => (
                                        <Option key={item} value={item}>
                                          {item}
                                        </Option>
                                      ),
                                      this
                                    )}
                                </NGISelect>
                              </NGIFormItem>
                            </Col>
                          </Col>
                        </>
                      )}
                      <Divider />
                      <Space>
                        <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
                      </Space>
                    </NGIForm>
                  </CardBody>
                </Card>
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default NewPackage;
