import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useLocation } from "react-router";
import { fetchDepotDetails, fetchDepotsList, fetchHistoryByType, getUsersList, updateDepot } from "utils/api";
import { Form, Spin } from "antd";
import Header from "pages/depot-management/depotDetails/Header";
import DataTabs from "pages/depot-management/depotDetails/DataTabs";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  extractMarkerCoords,
  extractPolygonCoords,
  isMarkerInsidePolygon,
  isPolygonNotOnMap,
  mapGeoJsonToGoogle,
} from "utils/helpers/mapHelpers";
import moment from "moment";
import { getErrorList } from "pages/meter-management/configuration/meters/utils";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { history } from "store";
import { GOOGLE_MAPS_API_KEY } from "utils/constants";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { prepareDepotData, preparedHistoryData } from "pages/depot-management/depotDetails/utils";
import { randomColor } from "utils/helpers/functions";
import { getTSRList } from "store/TSR/actions";
import NotFoundContainer from "components/Common/NotFoundContainer";

const DepotDetails = () => {
  const searchParams = useLocation().search;
  const name = new URLSearchParams(searchParams).get("name");
  const [isLoading, setLoader] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [depot, setDepot] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [marker, setMarker] = useState(null);
  const [aria, setAria] = useState(null);
  const [isTouched, setIsTouched] = useState(false);
  const [depotsHistory, setDepotsHistory] = useState([]);
  const [allDepotsList, setAllDepotsList] = useState(null);
  const [TSRList, setTSRList] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    libraries: ["drawing", "places"],
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.COUNTY));
    dispatch(getDictionary(DICTIONARY_TYPES.TEAM_TYPE));

    fetchDepotsList({
      pageSize: 1000,
    }).then(res => {
      const {
        result: { items },
      } = res?.data;
      setAllDepotsList(items || []);
    });
  }, []);

  useEffect(() => {
    dispatch(getTSRList());
    updateData();
  }, []);

  const updateData = useCallback(() => {
    fetchDepotDetails({ name })
      .then(async ({ data }) => {
        const depotData = data.result;
        const {
          data: {
            result: { items },
          },
        } = await getUsersList({
          depot: name,
          hasTeam: true,
          hasSector: true,
          pageSize: 100,
        });
        const {
          data: {
            result: { items: list },
          },
        } = await getUsersList({
          depot: name,
          hasTeam: true,
          pageSize: 3000,
        });
        const mappedDepot = mapGeoJsonToGoogle(depotData);
        const preparedDepot = prepareDepotData(mappedDepot, items);
        setTSRList(list);
        setDepot(preparedDepot);

        form.setFieldsValue({
          name: mappedDepot.name,
          status: mappedDepot.status,
          lat: mappedDepot.location.lat,
          lng: mappedDepot.location.lon,
          county: mappedDepot.location.county,
          subCounty: mappedDepot.location.subCounty,
          address: mappedDepot.location.address,
          dsmPhoneNumber: mappedDepot.dsmPhoneNumber,
          azureObjectID: mappedDepot.azureObjectID,
          operationalizationDate: moment(mappedDepot.operationalizationDate),
        });

        // eslint-disable-next-line no-undef
        const latlngObj = new google.maps.LatLng(mappedDepot.location.lat, mappedDepot.location.lon);
        setMarker(
          // eslint-disable-next-line no-undef
          new google.maps.Marker({
            position: latlngObj,
          })
        );
        setIsHistoryLoading(true);
        fetchHistoryByType("depots", mappedDepot.id)
          .then(({ data }) => {
            setIsHistoryLoading(false);
            const preparedHistory = preparedHistoryData(data?.result?.items);
            setDepotsHistory({
              result: { items: preparedHistory },
            });
          })
          .catch(() => {
            setIsHistoryLoading(false);
            setDepotsHistory({
              result: { items: [] },
            });
          });
      })
      .catch(e => {
        setLoader(false);
        if (e.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
  }, [name]);

  const onPolygonComplete = useCallback(
    (data, isUpdate = false) => {
      if (isUpdate) {
        const polygonCoords = extractPolygonCoords(data);
        const isInside = marker && isMarkerInsidePolygon(marker, data);
        if (!isInside) {
          marker?.setMap(null);
          form.setFieldsValue({
            lat: null,
            lng: null,
          });
        }
        setDepot(prevState => ({
          ...prevState,
          location: isInside ? { ...prevState.location } : {},
          boundaries: {
            type: "Polygon",
            coordinates: [polygonCoords],
          },
        }));
      } else {
        setAria(prevAria => {
          setIsTouched(true);
          prevAria?.setMap(null);
          const polygonCoords = extractPolygonCoords(data);
          const isInside = marker && isMarkerInsidePolygon(marker, data);
          if (!isInside) {
            marker?.setMap(null);
          }
          if (isPolygonNotOnMap(polygonCoords)) {
            data.setMap(null);
            marker?.setMap(null);
            form.setFieldsValue({
              lat: null,
              lng: null,
            });
            return null;
          }
          return data;
        });
      }
    },
    [marker, form]
  );

  const onMarkerComplete = useCallback(
    data =>
      setMarker(prevMarker => {
        prevMarker?.setMap(null);
        const isInside = isMarkerInsidePolygon(data, isTouched ? aria : depot?.boundaries?.coordinates[0]);
        if (isInside) {
          const markerCoords = extractMarkerCoords(data);
          form.setFieldsValue({
            lat: markerCoords[0],
            lng: markerCoords[1],
          });
          setDepot(prevState => ({ ...prevState, location: { lat: markerCoords[0], lon: markerCoords[1] } }));
          return data;
        }
        form.setFieldsValue({
          lat: null,
          lng: null,
        });
        setDepot(prevState => ({ ...prevState, location: {} }));
        data.setMap(null);
        return null;
      }),
    [aria, form, depot]
  );

  const clearMap = useCallback(() => {
    setAria(prevAria => {
      prevAria?.setMap(null);
      return null;
    });
    setMarker(prevMarker => {
      prevMarker?.setMap(null);
      return null;
    });
    setIsTouched(true);
    form.setFieldsValue({
      lat: null,
      lng: null,
    });
  }, [form]);

  const handleSave = useCallback(() => {
    const {
      status,
      name,
      county,
      subCounty,
      lat,
      lng,
      address,
      operationalizationDate,
      dsmPhoneNumber,
      azureObjectID,
    } = form.getFieldsValue();
    const errors = getErrorList(form);
    if (name && county && subCounty && address && operationalizationDate && lat && lng && !errors.length) {
      const polygonCoords = aria ? extractPolygonCoords(aria) : depot.boundaries.coordinates[0];
      const mappedData = {
        name,
        location: {
          address,
          county,
          subCounty,
          lat,
          lon: lng,
        },
        operationalizationDate: operationalizationDate.valueOf(),
        boundaries: {
          type: "Polygon",
          coordinates: [polygonCoords],
        },
        status,
        dsmPhoneNumber,
        azureObjectID,
      };
      const convertedData = mapGeoJsonToGoogle(mappedData);
      setLoader(true);
      updateDepot(convertedData)
        .then(() => {
          setLoader(false);
          history.push("/depots/list");
          successMessage(`Depot updated successfully`);
        })
        .catch(e => {
          setLoader(false);
          defaultNgiErrorMethod(e);
        });
    } else {
      form.submit();
    }
  }, [form, aria, depot]);

  const polygonList = useMemo(
    () =>
      allDepotsList
        ?.filter(depot => depot.name !== name)
        ?.map(item => ({
          id: item.name,
          color: randomColor(),
          poly: item.boundaries.coordinates[0].map(item => [item[1], item[0]]),
        })),
    [allDepotsList]
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              <>
                <Header depot={depot} />
                <DataTabs
                  polygonList={polygonList}
                  depotForm={form}
                  depot={depot}
                  depotsHistory={depotsHistory}
                  isLoaded={isLoaded}
                  onClearMap={clearMap}
                  onPolygonComplete={onPolygonComplete}
                  onMarkerComplete={onMarkerComplete}
                  isTouched={isTouched}
                  handleSave={handleSave}
                  isHistoryLoading={isHistoryLoading}
                  TSRList={TSRList}
                  updateData={updateData}
                />
              </>
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default DepotDetails;
