import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Divider } from "antd";
import Title from "antd/lib/typography/Title";
import { CardBody } from "reactstrap";
import userPreviewIcon from "assets/images/svg/user-team.svg";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  cardBody: {
    padding: 0,
  },
  title: {
    fontSize: "12px !important",
    fontWeight: "300 !important",
    lineHeight: 15,
    textTransform: "uppercase",
    marginBottom: "0 !important",
  },
  value: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 20,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 32,
    minWidth: 32,
    height: 32,
    marginRight: 8,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
      padding: 3,
    },
  },
  memberList: {
    display: "flex",
    width: "50%",
    flexWrap: "wrap",
    padding: "10px 0",
  },
  member: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    marginRight: 8,
  },
});

const View = ({ data }) => {
  const classes = useStyles();

  return (
    <CardBody className={classes.cardBody}>
      <Col span={24}>
        <Title className={classes.title} level={5}>
          MAINTENANCE DEPOT
        </Title>
        <div className={classes.value}>{data.depot}</div>
      </Col>
      <Divider />
      {data?.members && (
        <Col span={24}>
          <Title className={classes.title} level={5}>
            TEAM MEMBERS
          </Title>
          <div className={classes.value}>
            <div className={classes.memberList}>
              {data?.members?.map(item => (
                <div className={classes.member} key={item}>
                  <div className={classes.iconContainer}>
                    <img src={userPreviewIcon} alt="customer" />
                  </div>
                  <CustomLink underlined target="_blank" to={`/user/${item}`}>
                    {item}
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </Col>
      )}
    </CardBody>
  );
};

export default View;
