import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Space, Col, Form, Tabs } from "antd";
import { successMessage } from "components/Common/responses/message";
import { resetCommandSuccessGet, sendMeter } from "store/actions";
import { MeterCommandsSelector } from "store/meter-management/meters/selectors";
import AdjustData from "./AdjustData";
import CustomButton from "components/Common/buttons/CustomButton";

export const CommandsModel = props => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { commandSuccessGet } = useSelector(MeterCommandsSelector);
  const { isVisible, meterId, onCancel } = props;

  const [disabled, setDisabled] = useState(false);

  const closeModal = () => {
    onCancel(false);
  };

  const handleOk = async () => {
    setDisabled(true);
    dispatch(sendMeter(meterId));
  };

  useEffect(() => {
    dispatch(resetCommandSuccessGet());
  }, []);

  useEffect(() => {
    if (!!commandSuccessGet) {
      successMessage(`Commands were sent successfully`);
      closeModal();
      dispatch(resetCommandSuccessGet());
    }
  }, [commandSuccessGet]);

  return (
    <div>
      <Modal title="Send Commands/Adjust Data" open={isVisible} onCancel={closeModal} closable={false} footer={null}>
        <Tabs type="card" tabBarExtraContent={""} defaultActiveKey="1">
          <TabPane tab="Send Commands" key="1">
            <div className="card-container">
              <p>
                This action will send Get Settings, Meter Status, Logs, Hardware, Cooking Session and Cumulative Report
                Status to Meter!!.
              </p>
              <Form.Item>
                <Col>
                  <Space>
                    <CustomButton onClick={closeModal} type="primary" size="small" color="outlined" text="Cancel" />
                    <CustomButton onClick={handleOk} disabled={disabled} type="primary" size="small" text="Yes,Send" />
                  </Space>
                </Col>
              </Form.Item>
            </div>
          </TabPane>
          <TabPane tab="Adjust Data" key="2">
            <AdjustData onClose={closeModal} meterId={meterId} />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};
