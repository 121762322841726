import { groupBy, maxBy } from "lodash";

export const prepareData = list => {
  const filteredData = [];
  const groupedList = groupBy(list, "assigneeName");
  Object.keys(groupedList).forEach(item => {
    const lastItem = maxBy(groupedList[item], task => task.updatedAt);
    filteredData.push(lastItem);
  });
  return filteredData;
};

export const BREADCRUMBS = {
  Open: [{ name: "Operations" }, { name: "ALL ROUTINES" }, { name: "Opened Routines" }],
  Close: [{ name: "Operations" }, { name: "ALL ROUTINES" }, { name: "Closed Routines" }],
  Suspended: [{ name: "Operations" }, { name: "ALL ROUTINES" }, { name: "Suspended Routines" }],
};
