import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NGIInput from "components/Common/inputs/NGIInput";
import closeIcon from "assets/images/svg/closeSmall.svg";

const useStyles = makeStyles({
  answerItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  remove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 19,
    borderRadius: 50,
    backgroundColor: "#f5f5f5",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  answerText: {
    width: "100%",
    marginRight: 10,
  },
});

const NewAnswerRow = ({ item, index, setAnswers, onRemoveAnswer }) => {
  const classes = useStyles();

  const handleTextChange = useCallback(
    e =>
      setAnswers(prevState => {
        prevState[index].text = e.target.value;
        return [...prevState];
      }),
    [index]
  );

  return (
    <div className={classes.answerItem}>
      <NGIInput
        onChange={handleTextChange}
        value={item.text}
        className={classes.answerText}
        placeholder="Enter value"
      />

      <div className={classes.remove} data-id={item.id} onClick={onRemoveAnswer}>
        <img src={closeIcon} alt="remove" />
      </div>
    </div>
  );
};

export default NewAnswerRow;
