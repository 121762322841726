import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { DatePicker } from "antd";
import { FILTER_TYPES, REPORT_TYPES, TYPE_NAMES } from "pages/meter-management/maintenanceReports/variables";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { isEmpty, uniq } from "lodash";
import { TSRSelector } from "store/TSR/selectors";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { getSelectedFilterProp } from "pages/meter-management/maintenanceReports/utils";
import moment from "moment";
import TSRSelect from "components/Common/components/TSRSelect";

const { RangePicker } = DatePicker;

const { Option } = Select;

const ReportsFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.MAINTENANCE_REPORTS);
  const [filterType, setFilterType] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);
  const { TSRList } = useSelector(TSRSelector);

  // useEffect(() => {
  //   const property = Object.keys(filters)[0];
  //   const value = filters[property];
  //   const defaultData = { [TYPE_NAMES.REPORT_TYPE]: "Testing" };
  //   form.setFieldsValue(!isEmpty(filters) ? filters : defaultData);
  //
  //   setFilterType(property);
  // }, []);

  useEffect(() => {
    const defaultData = { [TYPE_NAMES.REPORT_TYPE]: "Testing" };
    form.setFieldsValue(!isEmpty(filters) ? filters : defaultData);
    const selectedFilter = getSelectedFilterProp(filters);
    setFilterType(selectedFilter);
    if (filters["timeStart"]) {
      setDateRange([moment(filters["timeStart"]), moment(filters["timeEnd"])]);
    }
  }, [TYPE_NAMES.REPORT_TYPE]);

  const onTypeChange = useCallback(value => {
    if (!!value) setFilterType(value);
    else setFilterType(null);
  }, []);

  const onDateRangeChange = useCallback(value => setDateRange(value), []);

  const onSearch = useCallback(
    values => {
      const { pageSize } = getFilters();
      const normalizedData = { ...values, location: AUTH_USER?.depotName };
      if (!!normalizedData[filterType?.value])
        normalizedData[filterType?.value] = normalizedData[filterType?.value]?.trim();
      if (!!dateRange) {
        normalizedData.timeStart = getStartOfTheDay(dateRange[0]);
        normalizedData.timeEnd = getEndOfTheDay(dateRange[1]);
      }
      handleFilter({ ...normalizedData, pageSize });
    },
    [dateRange, filterType]
  );

  const disableDate = useCallback(current => current && current.valueOf() > Date.now(), []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <RangePicker inputReadOnly={true} value={dateRange} onChange={onDateRangeChange} disabledDate={disableDate} />
        <div className="filter-divider" />
        <Form.Item name={TYPE_NAMES.REPORT_TYPE} rules={[{ required: true, message: `Please select report type` }]}>
          <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select type"} allowClear>
            {REPORT_TYPES?.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <Select
          showSearch
          style={{ width: "100%" }}
          tokenSeparators={[","]}
          placeholder={"Select type"}
          allowClear
          value={filterType}
          onChange={onTypeChange}
        >
          {FILTER_TYPES?.map(item => (
            <Option key={item.value} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
        {!!filterType && (
          <Form.Item
            name={filterType}
            rules={[{ required: true, message: `Please select ${filterType.toLowerCase()}` }]}
          >
            {filterType === TYPE_NAMES.DEPOT ? (
              <Select
                showSearch
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                placeholder={"Select type"}
                allowClear
              >
                {!!depot &&
                  uniq(depot[USER_TENANT_NAME])
                    ?.sort()
                    ?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
              </Select>
            ) : filterType === TYPE_NAMES.TECHNICIAN ? (
              <TSRSelect
                showSearch
                initialList={TSRList}
                roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                depot={AUTH_USER?.depotName}
              />
            ) : (
              <Input placeholder="Enter value" allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            )}
          </Form.Item>
        )}
        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default ReportsFilter;
