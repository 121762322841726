export const ACTION_TYPE = {
  ASSIGN_PACKAGE: "ASSIGN_PACKAGE",
  ASSIGN_DEPOT: "ASSIGN_DEPOT",
  LINK_TSR: "LINK_TSR",
};

export const searchOptions = [
  {
    value: "date",
    title: "Installation Date",
  },
  {
    value: "id",
    title: "Customer ID",
  },
  {
    value: "firstName",
    title: "First Name",
  },
  {
    value: "lastName",
    title: "Last Name",
  },
  {
    value: "depot",
    title: "Depot",
  },
  {
    value: "technicianName",
    title: "TSR",
  },
];
export const dateFormat = "DD/MM/YYYY";

export const emptyResponse = {
  result: { items: [] },
};
