import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal, Space, Spin, Tabs } from "antd";
import { createDepot } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  extractGeoJsonPolygonCoords,
  extractMarkerCoords,
  extractPolygonCoords,
  isMarkerInsidePolygon,
  isPolygonNotOnMap,
} from "utils/helpers/mapHelpers";
import GeoDetails from "pages/depot-management/depotModal/Tabs/GeoDetails";
import DepotInformation from "pages/depot-management/depotModal/Tabs/DepotInformation";
import { getErrorList } from "pages/meter-management/configuration/meters/utils";
import { defaultNgiErrorMethod, errorMessage, successMessage, warnMessage } from "components/Common/responses/message";
import { DEFAULT_CENTER } from "pages/depot-management/depots/constants";
import { GOOGLE_MAPS_API_KEY } from "utils/constants";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { getTrimmedObjectValues } from "utils/helpers/functions";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  container: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 30,
    paddingBottom: 20,
  },
  tabs: {
    "&.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav": {
      padding: "0px!important",
      margin: 0,
    },
  },
});

const DepotModal = ({ onClose, polygonList, isOpen, onUpdate }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    libraries: ["drawing", "places"],
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const dispatch = useDispatch();
  const [center, setCenter] = useState(null);
  const [marker, setMarker] = useState(null);
  const [aria, setAria] = useState(null);
  const [activeTab, setActiveTab] = useState("geofenceArea");

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.COUNTY));
  }, []);

  useEffect(
    () =>
      navigator.geolocation.getCurrentPosition(
        ({ coords }) =>
          setCenter({
            lat: coords.latitude,
            lng: coords.longitude,
          }),
        () => setCenter(DEFAULT_CENTER)
      ),
    []
  );

  const onPolygonComplete = useCallback(
    data =>
      setAria(prevAria => {
        prevAria?.setMap(null);
        const polygonCoords = extractPolygonCoords(data);
        const isInside = marker && isMarkerInsidePolygon(marker, data);
        if (!isInside) {
          marker?.setMap(null);
        }
        if (isPolygonNotOnMap(polygonCoords)) {
          data.setMap(null);
          marker?.setMap(null);
          form.setFieldsValue({
            lat: null,
            lng: null,
          });
          return null;
        }
        return data;
      }),
    [marker, form]
  );

  const onMarkerComplete = useCallback(
    data =>
      setMarker(prevMarker => {
        prevMarker?.setMap(null);
        const isInside = isMarkerInsidePolygon(data, aria);
        if (isInside) {
          const markerCoords = extractMarkerCoords(data);
          form.setFieldsValue({
            lat: markerCoords[0],
            lng: markerCoords[1],
          });
          return data;
        }
        form.setFieldsValue({
          lat: null,
          lng: null,
        });
        data.setMap(null);
        return null;
      }),
    [aria, form]
  );

  const clearMap = useCallback(() => {
    setAria(prevAria => {
      prevAria?.setMap(null);
      return null;
    });
    setMarker(prevMarker => {
      prevMarker?.setMap(null);
      return null;
    });
    form.setFieldsValue({
      lat: null,
      lng: null,
    });
  }, [form]);

  const handleSave = useCallback(() => {
    const {
      status,
      name,
      county,
      subCounty,
      lat,
      lng,
      address,
      operationalizationDate,
      dsmPhoneNumber,
      azureObjectID,
    } = form.getFieldsValue();
    const isInside = isMarkerInsidePolygon(marker, aria);
    if (isInside) {
      const errors = getErrorList(form);
      if (aria && name && county && subCounty && address && operationalizationDate && !errors.length) {
        const mappedData = getTrimmedObjectValues({
          name,
          location: {
            address,
            county,
            subCounty,
            lat,
            lon: lng,
          },
          operationalizationDate: operationalizationDate.valueOf(),
          boundaries: {
            type: "Polygon",
            coordinates: [extractGeoJsonPolygonCoords(aria)],
          },
          status,
          dsmPhoneNumber,
          azureObjectID,
        });
        setLoader(true);
        createDepot(mappedData)
          .then(() => {
            setLoader(false);
            successMessage(`Depot was created successfully`);
            onClose();
            onUpdate();
          })
          .catch(e => {
            setLoader(false);
            defaultNgiErrorMethod(e);
          });
      } else {
        if (!aria && activeTab === "depotInformation") {
          errorMessage("Geofence Area tab is mandatory");
        }
        form.submit();
      }
    } else {
      warnMessage("Depot location is not inside a polygon. Please move the pointer");
    }
  }, [form, aria, activeTab, marker]);

  const tabs = useMemo(
    () => [
      {
        label: "Geofence Area",
        key: "geofenceArea",
        children: (
          <GeoDetails
            center={center}
            polygonList={polygonList}
            isLoaded={isLoaded}
            initialData={{
              marker: {
                lat: marker?.position?.lat(),
                lng: marker?.position?.lng(),
              },
            }}
            onClearMap={clearMap}
            onPolygonComplete={onPolygonComplete}
            onMarkerComplete={onMarkerComplete}
            allowSearch
          />
        ),
      },
      {
        label: "Depot Information",
        key: "depotInformation",
        children: <DepotInformation form={form} />,
      },
    ],
    [center, onPolygonComplete, onMarkerComplete, isLoaded, form, polygonList, marker]
  );

  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          New Depot
          <span>Creating a new depot</span>
        </div>
      }
      open={isOpen}
      onCancel={openConfirmationModal}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.container}>
          <Tabs onChange={setActiveTab} className={classes.tabs} defaultActiveKey="geofenceArea" items={tabs} />
        </div>
        <Space>
          <CustomButton onClick={openConfirmationModal} type="primary" size="small" color="outlined" text="Cancel" />
          <CustomButton onClick={handleSave} type="primary" size="small" text="Save" />
        </Space>
      </Spin>
      {isConfirmationOpen && (
        <ConfirmationModal
          description={
            <span>
              The depot creation is in progress. <b>The new depot will not be added after the closing of the popup.</b>{" "}
              Are you sure?
            </span>
          }
          onCancel={closeConfirmationModal}
          onConfirm={onClose}
        />
      )}
    </Modal>
  );
};

export default DepotModal;
