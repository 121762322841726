import { isArray } from "lodash/lang";
import { getFormattedKeyName } from "utils/helpers/functions";

const fieldsMapping = {
  status_: "Status",
  stopsList: "Stops",
};

const skipKeys = ["updatedAt", "updatedBy"];

export const preparedHistoryData = data => {
  try {
    const changes = [];
    data.forEach(item => {
      const changedFields = [];
      if (!item.oldImage) return;

      item.newImage &&
        Object.keys(item.newImage).forEach(key => {
          if (skipKeys.includes(key)) return;

          if (key === "stops") {
            item.newImage[key].forEach((stop, i) => {
              if (item.oldImage[key][i] && stop.assets?.length !== item.oldImage[key][i].assets?.length) {
                changedFields.push("Assets");
              }
            });
            return;
          }
          if (typeof item.newImage[key] === "object" && !isArray(item.newImage[key])) {
            if (!item.oldImage[key]) {
              changedFields.push(key);
              return;
            }
            Object.keys(item.newImage[key]).forEach(k => {
              if (item.newImage[key][k] !== item.oldImage[key][k]) {
                changedFields.push(fieldsMapping[k] || k);
              }
            });
            return;
          }
          if (isArray(item.newImage[key])) {
            if (!item.oldImage[key] || item.newImage[key].length !== item.oldImage[key].length) {
              changedFields.push(fieldsMapping[key] || key);
            }
            return;
          }
          if (item.newImage[key] !== item.oldImage[key]) {
            changedFields.push(fieldsMapping[key] || key);
          }
        });
      if (changedFields.length) {
        changes.push({
          updatedAt: item.newImage.updatedAt,
          updatedBy: item.newImage.updatedBy,
          fields: changedFields.map(getFormattedKeyName).join(", "),
        });
      }
    });
    return changes;
  } catch (e) {
    console.error(e);
  }
};
