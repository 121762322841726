import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./i18n"
import { Provider } from "react-redux"

import store, { history } from "./store"

import persistor from "./store/persitor"
import { ConnectedRouter } from "connected-react-router"
import { PersistGate } from "redux-persist/integration/react"

const b2c = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>   
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)

ReactDOM.render(b2c, document.getElementById("root"))
serviceWorker.unregister()
