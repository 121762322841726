import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { fetchCustomerList, fetchDepotDetails, getUsersList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import LeadsSearch from "./LeadsSearch";
import { leadListColumns } from "./leadListColumns";
import NGITable from "components/Common/NGITable";
import { DEFAULT_PAGINATION_CONFIG } from "pages/operations/leads/variables";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { generateXLS, userHasOneOfRoles } from "utils/helpers/functions";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import downloadIcon from "assets/images/svg/DownloadFromCloud.svg";
import { UserAddOutlined } from "@ant-design/icons";
import SectorTSRModal from "pages/operations/leads/SectorTSRModal";
import NGITooltip from "components/Common/utils/NGITooltip";

const useStyles = makeStyles({
  scoreContainer: {
    padding: "3px 8px",
    borderRadius: 6,
    textAlign: "center",
  },
  Declined: {
    backgroundColor: "#FF0000",
    color: "white",
  },
  Qualified: {
    backgroundColor: "#17C347",
    color: "white",
  },
});

const NotAssignedLeads = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [totalLeads, setTotalLeads] = useState(0);
  const [sectors, setSectors] = useState([]);
  const [TSRList, setTSRList] = useState([]);
  const { filters, setFilters, getFilters } = useStoreFilters(FILTER_PAGE.NOT_ASSIGNED_LEADS);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.CUSTOMER_TYPE));
    getUsersList({
      depot: AUTH_USER?.depotName,
      hasTeam: true,
      pageSize: 3000,
    }).then(res => {
      const {
        data: {
          result: { items: list },
        },
      } = res;
      setTSRList(list);
    });
    fetchDepotDetails({ name: AUTH_USER?.depotName }).then(async ({ data }) => {
      const { sectors } = data.result;
      setSectors(sectors);
    });
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items, meta },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Leads were not found");
    }
    setTotalLeads(meta?.totalCount || 0);
    const filteredItems = items.map(customer => ({
      fullName: `${customer.firstName} ${customer.lastName}`,
      ...customer,
    }));

    setCustomerList({ result: { items: filteredItems, meta } } || null);
    setLoader(false);
  }, []);

  const handleSearch = useCallback(
    (filterOptions = DEFAULT_PAGINATION_CONFIG) => {
      setLoader(true);
      setFilters(filterOptions);
      if (!AUTH_USER?.roles?.includes(USER_ROLES.SYSTEM_FULL_ADMINS)) filterOptions.depot = AUTH_USER?.depotName;
      fetchCustomerList({
        status: "Qualified",
        technician: "null",
        ...filterOptions,
      })
        .then(res => onListLoaded(res))
        .catch(err => {
          setLoader(false);
          setCustomerList(null);
          defaultNgiErrorMethod(err);
        });
    },
    [AUTH_USER]
  );

  const onDownload = useCallback(() => {
    const filters = getFilters();
    fetchCustomerList({
      status: "Qualified",
      technician: "null",
      ...filters,
      pageNumber: 1,
      pageSize: totalLeads,
    }).then(res => {
      const {
        result: { items },
      } = res?.data;
      generateXLS(items, "Leads", "xlsx");
    });
  }, [totalLeads]);

  const openAssignModal = useCallback(() => setIsAssignModalVisible(true), []);
  const closeAssignModal = useCallback(() => setIsAssignModalVisible(false), []);

  const onUpdateList = useCallback(() => {
    const filters = getFilters();
    handleSearch(filters);
    setCustomerList(null);
    setSelectedItems([]);
  }, []);

  const columns = useMemo(() => leadListColumns(classes), []);

  const rowSelection = {
    selectedItems,
    onChange: rows => setSelectedItems(rows),
    renderCell: (checked, record, index, node) => {
      if (record.status !== "Qualified") {
        return <NGITooltip title="Only 'Approved' items are allowed.">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      if (record.status !== "Qualified") {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };

  const hideRowSelection = useMemo(
    () =>
      userHasOneOfRoles([
        USER_ROLES.CUSTOMER_CARE,
        USER_ROLES.INTERNAL_AUDITOR,
        USER_ROLES.GENERAL,
        USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
        USER_ROLES.IT_OPERATIONS,
        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        USER_ROLES.BUSINESS_DEV,
        USER_ROLES.FINANCE,
      ]),
    []
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper with-buttons">
            <div className="filter-buttons">
              <ButtonGroupContainer color={"primary"}>
                <ButtonGroup
                  hideRoles={[USER_ROLES.BUSINESS_DEV, USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
                  color={"primary"}
                  handleClick={onDownload}
                  tooltipTitle="Download"
                  icon={downloadIcon}
                  disabled={!totalLeads}
                />
                {!!selectedItems.length && (
                  <ButtonGroup
                    hideRoles={[
                      USER_ROLES.CUSTOMER_CARE,
                      USER_ROLES.IT_OPERATIONS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.BUSINESS_DEV,
                    ]}
                    color={"primary"}
                    tooltipTitle="Assign"
                    handleClick={openAssignModal}
                  >
                    <UserAddOutlined />
                  </ButtonGroup>
                )}
              </ButtonGroupContainer>
            </div>
            <LeadsSearch handleSearch={handleSearch} isLoading={isLoading} setLoader={setLoader} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.NOT_ASSIGNED_LEADS}
              isLoading={isLoading}
              columns={columns}
              data={customerList}
              rowSelection={hideRowSelection ? null : rowSelection}
              rowKey={record => record.id}
              updateList={handleSearch}
            />
          </Col>
          {isAssignModalVisible && (
            <SectorTSRModal
              TSRList={TSRList}
              sectors={sectors}
              onClose={closeAssignModal}
              selectedLeads={selectedItems}
              onUpdate={onUpdateList}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default NotAssignedLeads;
