import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import { camelCaseTextFormatter } from "components/Common/formatter";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,
    fontWeight: 600,

    "& span": {
      fontSize: 16,
      fontWeight: 300,
      marginBottom: 4,
      marginLeft: 5,
    },
  },
  iconContainer: {
    padding: 8,
    marginLeft: 23,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 65,
    minWidth: 65,
    height: 65,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
    backgroundColor: "#8C8C8C",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  OpenedProspect: {
    color: "#0088ea",
    backgroundColor: "#e6f5ff",
  },
  ArchievedProspect: {
    backgroundColor: "#FF0000",
  },
  AssignedProspect: {
    backgroundColor: "#17C347",
  },
});

const Header = ({ prospect }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/operations/prospects"}>Back</BackButtonLink>

        <div className={classes.nameContainer}>
          <div>
            PROSPECT:<span> {prospect?.id}</span>
          </div>
          {prospect?.status && (
            <div className={classnames(classes.status, classes[prospect?.status])}>
              {camelCaseTextFormatter(prospect?.status)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
