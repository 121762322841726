import React, { useEffect, useMemo, useState } from "react";
import { Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { retrieveSTODetails } from "utils/api";
import { removeSpaces } from "utils/helpers/functions";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { textFormatter } from "components/Common/formatter";
import AssetRowGroup from "pages/stock-management/stockTransferOrders/STODetails/AssetRowGroup";
import { groupBy } from "lodash";
import classnames from "classnames";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  generalInfoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",
  },
  section: {
    maxWidth: "33%",
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 14,
  },
  comment: {
    fontWeight: 600,
    fontSize: 14,
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerWithStatus: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  statusContainer: {
    display: "flex",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
    fontSize: 11,
    width: 100,
    color: "#757575",
    backgroundColor: "#e9e9e9",
    borderRadius: 4,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  Pending: {
    color: "#757575",
    backgroundColor: "#e9e9e9",
  },
  Planned: {
    color: "#0088ea",
    backgroundColor: "#e6f5ff",
  },
  PartiallyPlanned: {
    color: "#0088ea",
    backgroundColor: "#e6f5ff",
  },
  InTransit: {
    color: "#19c346",
    backgroundColor: "#d7ffe3",
  },
  Completed: {
    color: "#19c346",
    backgroundColor: "#d7ffe3",
  },
});

const STODetailsModal = ({ onClose, id }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});

  useEffect(() => {
    setIsLoading(true);
    retrieveSTODetails(id).then(({ data }) => {
      setDetails(data?.result);
      setIsLoading(false);
    });
  }, [id]);

  const assetGroups = useMemo(() => groupBy(details.items, "tripId"), [details]);

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.headerWithStatus}>
            View Stock Transfer Order
            <span>{details.id}</span>
          </div>
          <span className={classes.statusContainer}>
            STATUS
            <span className={classnames(classes.status, classes[removeSpaces(details.status)])}>{details.status}</span>
          </span>
        </div>
      }
      className={classes.modal}
      open
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <TitleWithDivider title="General Information" />

        <div className={classes.generalInfoContainer}>
          <div className={classes.section}>
            <div className={classes.title}>Order type</div>
            <div className={classes.value}>{textFormatter(details.type)}</div>
          </div>
          <div className={classes.section}>
            <div className={classes.title}>PREFERRED DATE</div>
            <div className={classes.value}>{textFormatter(details.preferredDate)}</div>
          </div>
          <div className={classes.section}>
            <div className={classes.title}>{`${details.type === "Inbound" ? "ORDER" : "FROM"} LOCATION`}</div>
            <div className={classes.value}>
              {textFormatter(details.type === "Inbound" ? details.toDepot : details.fromDepot)}
            </div>
          </div>
        </div>

        {Object.keys(assetGroups).map(item => (
          <AssetRowGroup
            key={item}
            groupName={item === "undefined" ? "Not Planned" : item}
            assets={assetGroups[item]}
          />
        ))}

        <TitleWithDivider title="Additional Comments" />
        <div className={classes.comment}>{textFormatter(details.comments)}</div>
      </Spin>
      <br />
      <Space>
        <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Close" />
      </Space>
    </Modal>
  );
};

export default STODetailsModal;
