import React, { useCallback, useMemo } from "react";
import { Checkbox, Col, Form, Modal, Row, Space } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import Title from "antd/lib/typography/Title";
import { VALIDATION_RULES } from "pages/meter-management/meters/variables";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getFormattedKeyName } from "utils/helpers/functions";
import NGICheckbox from "components/Common/inputs/NGICheckbox";

const useStyles = makeStyles({
  root: {
    maxWidth: "350px!important",

    "& .ant-modal-body": {
      padding: "24px 30px!important",
    },
  },
  checkboxes: {
    display: "flex",
    flexDirection: "column",

    "&>label": {
      marginLeft: "0!important",
    },
  },
  photoContainer: {
    marginBottom: 0,
  },
});

const NewFaultyReasonModal = ({ onClose, faultyReason = "", isSub, onSave, allFaultyReasons }) => {
  const [form] = Form.useForm();
  const classes = useStyles();

  const {
    list: { meterRepairInputFields },
  } = useSelector(DictionariesSelector);
  const [level1, level2] = useMemo(() => faultyReason.split(">"), []);
  const allFaultyReasonsNames = useMemo(
    () => allFaultyReasons.map(item => item.item.toLowerCase()),
    [allFaultyReasons]
  );
  const initialReason = isSub ? level2 : level1;
  const initialRequiredFields = useMemo(
    () => allFaultyReasons.find(item => item.item === initialReason),
    [initialReason]
  );
  const onSubmit = useCallback(
    values => {
      onSave(
        isSub ? `${level1}>${values.faultyReason}` : values.faultyReason,
        values?.requiredRepairFields,
        values.isPhotoRequired
      );
    },
    [isSub]
  );

  return (
    <Modal open={true} className={classes.root} closable={false} onCancel={onClose} footer={[]}>
      <div>
        <Form
          form={form}
          onFinish={onSubmit}
          initialValues={{
            faultyReason: isSub ? level2 : level1,
            requiredRepairFields: initialRequiredFields?.requiredRepairFields,
            isPhotoRequired: initialRequiredFields?.isPhotoRequired,
          }}
        >
          <Row>
            <Col span={24}>
              <Title level={5}>Faulty Reason Name</Title>
              <Form.Item
                name="faultyReason"
                rules={[
                  ...VALIDATION_RULES,
                  {
                    message: "The faulty reason has already existed",
                    validator: (_, value) => {
                      if (
                        allFaultyReasonsNames.includes(value.toLowerCase()) &&
                        initialReason.toLowerCase() !== value.toLowerCase()
                      ) {
                        return Promise.reject("The faulty reason has already existed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGIInput disablePattern placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Title level={5}>Required Fields</Title>
              <Form.Item className={classes.photoContainer} valuePropName="checked" name="isPhotoRequired">
                <NGICheckbox>Photo Required</NGICheckbox>
              </Form.Item>
              <Form.Item name={"requiredRepairFields"}>
                <Checkbox.Group className={classes.checkboxes}>
                  {!!meterRepairInputFields &&
                    meterRepairInputFields.map(item => (
                      <NGICheckbox key={item} value={item}>
                        {getFormattedKeyName(item)}
                      </NGICheckbox>
                    ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
          </Space>
        </Form>
      </div>
    </Modal>
  );
};

export default NewFaultyReasonModal;
