import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EmptyAssetItem from "pages/stock-management/trips/tripDetails/EmptyAssetItem";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import closeIcon from "assets/images/svg/closeSmall.svg";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { closeTrip, unassignSTO, updateTripSTO } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import EditAssetItem from "pages/stock-management/trips/editTrip/editSTO/EditAssetItem";
import { TRIP_STATUSES } from "pages/stock-management/trips/constants";

const useStyles = makeStyles({
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  remove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "#F8F8F8",
    marginLeft: 10,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});

const StopItem = ({
  stop,
  changedList = [],
  setChangedList,
  showActions = false,
  update,
  trip,
  lastStopType,
  disableStatusList = [],
}) => {
  const [isLoading, setLoader] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { location, assets, loadedAssets, offloadedAssets, status, id } = stop;
  const classes = useStyles();

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const onRemoveStop = useCallback(() => {
    if (trip.status === TRIP_STATUSES.IN_TRANSIT && stop.assets.length > 0) {
      closeConfirmationModal();
      return;
    }
    setLoader(true);

    if (lastStopType === "pendingPlaned" || trip.status === TRIP_STATUSES.IN_TRANSIT) {
      updateTripSTO(
        {
          stopId: id,
          isStopRemoved: true,
        },
        trip.tripNumber
      )
        .then(async () => {
          closeConfirmationModal();
          setLoader(false);
          successMessage("Stop was removed successfully");
          update();
        })
        .catch(err => {
          setLoader(false);
          defaultNgiErrorMethod(err);
        });
      return;
    }
    unassignSTO(trip.tripNumber, {
      stopId: id,
    })
      .then(async () => {
        if (lastStopType === "last") {
          await closeTrip(trip.tripNumber);
        }
        closeConfirmationModal();
        setLoader(false);
        successMessage("Stop was removed successfully");
        update();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [id, lastStopType, stop.assets.length, trip]);

  const desc = useMemo(() => {
    switch (lastStopType) {
      case "hasOnly2":
        return "You are removing the last not passed stop from the trip with only 2 stops. In this case trip will NOT be completed automatically and you need to add a new last stop. Are you sure you want this?";
      case "last":
        return "You are removing the last not passed stop from the trip. In this case trip will be completed automatically. Are you sure you want this?";
      case "pendingPlaned":
        return "Stop contains 1 or more corresponding STO’s that will be deleted. Are you sure you want to remove this stop?";
      default:
        if (trip.status === TRIP_STATUSES.IN_TRANSIT && stop.assets.length > 0)
          return "Please unassign each STO in this stop and then try to remove again.";
        return "Are you sure you want to remove this stop?";
    }
  }, [lastStopType, stop.assets.length]);

  return (
    <div className={disableStatusList.includes(status) && classes.disabled}>
      <div className={classes.titleContainer}>
        <TitleWithDivider
          title={location?.depot}
          button={
            showActions ? (
              <>
                {stop?.status || ""}
                <div className={classes.remove} onClick={openConfirmationModal}>
                  <img src={closeIcon} alt="remove" />
                </div>
              </>
            ) : (
              stop?.status || ""
            )
          }
        />
      </div>
      {assets.length ? (
        assets.map(asset => (
          <EditAssetItem
            stop={stop}
            setChangedList={setChangedList}
            isChanged={changedList.includes(asset.stoItemId)}
            key={asset.stoItemId}
            trip={trip}
            asset={asset}
            loadedAssets={loadedAssets}
            offloadedAssets={offloadedAssets}
            update={update}
            isEdit={showActions}
          />
        ))
      ) : (
        <EmptyAssetItem />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          description={desc}
          onCancel={closeConfirmationModal}
          onConfirm={onRemoveStop}
          loading={isLoading}
          cancelBtnTitle="Cancel"
          confirmBtnTitle="Confirm"
        />
      )}
    </div>
  );
};

export default StopItem;
