import React, { useCallback, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const searchOptions = [
  {
    value: "customerId",
    title: "Customer ID",
  },
  {
    value: "payeePhoneNumber",
    title: "Phone Number",
  },
  {
    value: "receiptNumber",
    title: "Receipt Number",
  },
];

const UnprocessedPaymentsSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");
  const { getFilters } = useStoreFilters(FILTER_PAGE.UNPROCESSED_PAYMENTS);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    !(values.value && !values.type) &&
      handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
  }, []);

  const onChangeFilter = useCallback(
    value => {
      setSelectedFilter(value);
      form.setFieldValue("value", null);
    },
    [form]
  );

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            showSearch
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <>
          {selectedFilter !== "status" && (
            <Form.Item name="value">
              <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default UnprocessedPaymentsSearch;
