export const FILTER_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const FILTER_STATUS_OPTIONS = {
  [FILTER_STATUS.ACTIVE]: "Active",
  [FILTER_STATUS.INACTIVE]: "Inactive",
};

export const SEARCH_TYPES = {
  BARCODE: "barcode",
  SERIAL: "serial",
  CUSTOMER: "customerId",
  CONDITION: "condition",
};

export const SEARCH_TYPES_OPTIONS = {
  [SEARCH_TYPES.BARCODE]: "Meter barcode",
  [SEARCH_TYPES.SERIAL]: "Meter serial number",
  [SEARCH_TYPES.CUSTOMER]: "Customer ID",
  [SEARCH_TYPES.CONDITION]: "Condition",
};

export const CONDITIONS = {
  NEW: "New",
  FACTORY_TESTED: "Factory Tested",
  SHIPPED: "Shipped",
  VERIFIED: "Verified",
  READY_FOR_MARKET: "Ready For Market",
  HEALTHY: "Healthy",
  NOT_TESTED: "Not Tested",
  REPOSSESSED: "Repossessed",
  DECOMMISSIONED: "Decommissioned",
};

export const CONDITION_OPTIONS = {
  [CONDITIONS.NEW]: "New",
  [CONDITIONS.FACTORY_TESTED]: "Factory Tested",
  [CONDITIONS.SHIPPED]: "Shipped",
  [CONDITIONS.VERIFIED]: "Verified",
  [CONDITIONS.READY_FOR_MARKET]: "Ready For Market",
  [CONDITIONS.HEALTHY]: "Healthy",
  [CONDITIONS.NOT_TESTED]: "Not Tested",
  [CONDITIONS.REPOSSESSED]: "Repossessed",
  [CONDITIONS.DECOMMISSIONED]: "Decommissioned",
};

//need to remove
export const STATUS_ACTIVE = "Active";
export const STATUS_INACTIVE = "Inactive";

export const ACTION_TYPE_EDIT = "edit";
export const ACTION_TYPE_VIEW = "view";

export const DEFAULT_UNIT_PRICE = 287;

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const fieldsToConvert = [
  "unitPrice",
  "gasDensity",
  "updatePeriod",
  "warningLowBatteryVoltage",
  "warningLowCreditBalance",
  "warningLowGasMass",
  "sensorSlope",
  "sensorIntercept",
  "gasRemaining",
];

export const meterStatusList = ["Normal"];

export const MCU_TYPES = {
  Primary: "controller",
  Secondary: "controller2",
};
