import React, { useCallback, useEffect, useState } from "react";
import { Modal, Space } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { extractPromisesResult, getFormattedKeyName } from "utils/helpers/functions";
import { textFormatter } from "components/Common/formatter";
import { retrieveCustomerPhotoUrlByType } from "utils/api";

const useStyles = makeStyles({
  modal: {
    "& .ant-modal-content .ant-modal-header": {
      padding: "25px 30px 0 !important",
    },
    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 30px !important",
    },
    "& .ant-modal-title": {
      borderBottom: "1px solid #00000014",
      paddingBottom: 15,
    },
  },
  tableContainer: {
    marginBottom: 20,
  },
  tableHeader: {
    display: "flex",
    fontWeight: 600,

    "& div": {
      width: "33%",
      padding: "0 0 10px 10px",
      borderBottom: "1px solid #00000014",
    },

    "& div:nth-child(2)": {
      borderLeft: "1px solid #00000014",
      borderRight: "1px solid #00000014",
    },
  },
  tableData: {
    display: "flex",
    fontWeight: 400,

    "& >div": {
      width: "33%",
      padding: 10,
      borderBottom: "1px solid #00000014",
    },

    "& div:nth-child(2)": {
      borderLeft: "1px solid #00000014",
      borderRight: "1px solid #00000014",
    },
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
});

const ChangeOverviewModel = ({ onClose, customerChanges, customer, oldPhotoDetails }) => {
  const classes = useStyles();
  const [photoDetails, setPhotoDetails] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const newChanges = { ...customerChanges, ...customerChanges.location };
  const oldCustomerData = { ...customer, ...customer.location };

  const onPreviewImg = useCallback(e => {
    const url = e.currentTarget.dataset.url;
    setPreviewUrl(url);
  }, []);

  const onClosePreview = useCallback(() => setPreviewUrl(""), []);

  useEffect(() => {
    if (!!customer) {
      Promise.allSettled([
        customerChanges.personalPhotoFileName &&
          retrieveCustomerPhotoUrlByType(customer.id, "personalPhoto", customerChanges.personalPhotoFileName),
        customerChanges.housePhotoFileName &&
          retrieveCustomerPhotoUrlByType(customer.id, "housePhoto", customerChanges.housePhotoFileName),
      ]).then(data => {
        const [customerPersonalPhoto, customerHousePhoto] = extractPromisesResult(data);
        setPhotoDetails({
          personalPhotoUrl: customerPersonalPhoto && customerPersonalPhoto?.data?.result?.url,
          housePhotoUrl: customerHousePhoto && customerHousePhoto?.data?.result?.url,
        });
      });
    }
  }, []);

  const getField = useCallback(
    (filed, newValue, oldValue) => {
      switch (filed) {
        case "personalPhotoFileName":
          return (
            <>
              <div>Personal Photo</div>
              <div>
                <div className={classes.img}>
                  {oldPhotoDetails?.personalPhotoUrl ? (
                    <img
                      data-url={oldPhotoDetails?.personalPhotoUrl}
                      onClick={onPreviewImg}
                      src={oldPhotoDetails?.personalPhotoUrl}
                      alt="preview"
                    />
                  ) : (
                    textFormatter(oldPhotoDetails?.personalPhotoUrl)
                  )}
                </div>
              </div>
              <div>
                <div className={classes.img}>
                  <img
                    data-url={photoDetails?.personalPhotoUrl}
                    onClick={onPreviewImg}
                    src={photoDetails?.personalPhotoUrl}
                    alt="preview"
                  />
                </div>
              </div>
            </>
          );
        case "housePhotoFileName":
          return (
            <>
              <div>House Photo</div>
              <div>
                <div className={classes.img}>
                  {oldPhotoDetails?.housePhotoUrl ? (
                    <img
                      data-url={oldPhotoDetails?.housePhotoUrl}
                      onClick={onPreviewImg}
                      src={oldPhotoDetails?.housePhotoUrl}
                      alt="preview"
                    />
                  ) : (
                    textFormatter(oldPhotoDetails?.housePhotoUrl)
                  )}
                </div>
              </div>
              <div>
                <div className={classes.img}>
                  <img
                    data-url={photoDetails?.housePhotoUrl}
                    onClick={onPreviewImg}
                    src={photoDetails?.housePhotoUrl}
                    alt="preview"
                  />
                </div>
              </div>
            </>
          );

        default:
          return (
            <>
              <div>{getFormattedKeyName(filed)}</div>
              <div>{textFormatter(oldValue)}</div>
              <div>{textFormatter(newValue)}</div>
            </>
          );
      }
    },
    [photoDetails, customer]
  );

  return (
    <Modal
      open={true}
      title="Changes Overview"
      className={classes.modal}
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <>
        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            <div>Changed Field</div>
            <div>Old Value</div>
            <div>New Value</div>
          </div>
          {Object.keys(newChanges).map(item => {
            const newValue = newChanges[item];
            const oldValue = oldCustomerData[item];
            if (item === "location") return null;
            if (newValue === oldValue) return null;

            return (
              <div key={item} className={classes.tableData}>
                {getField(item, newValue, oldValue)}
              </div>
            );
          })}
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Close" />
        </Space>
      </>
      <Modal title="Photo preview" open={!!previewUrl} onCancel={onClosePreview} footer={[]}>
        <img className={classes.imgPreview} src={previewUrl} alt="preview" />
      </Modal>
    </Modal>
  );
};

export default ChangeOverviewModel;
