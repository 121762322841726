import React, { useEffect } from "react";
import { Modal, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "400px!important",

    "& .ant-modal-header": {
      padding: "24px 24px 0 !important",
    },

    "& .ant-modal-body": {
      padding: "10px 24px !important",
    },
  },
  description: {
    padding: "0 10px",
  },
});

const InfoModal = ({ onCancel, description = "", loading = false, autoClose = false, hideButton = false }) => {
  const classes = useStyles();

  useEffect(() => {
    if (!!autoClose) {
      setTimeout(() => {
        onCancel();
      }, 5000);
    }
  }, [autoClose]);

  return (
    <Modal className={classes.root} open={true} title="Flow message" onCancel={onCancel} footer={[]}>
      <Spin spinning={loading}>
        <p className={classes.description}>{description}</p>
        {!hideButton && (
          <Space>
            <CustomButton onClick={onCancel} size="small" type="primary" text="Ok" />
          </Space>
        )}
      </Spin>
    </Modal>
  );
};

export default InfoModal;
