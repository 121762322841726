import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "antd";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    background: "#FFF!important",
    border: "2px solid #444444",
    padding: 14,
    borderRadius: 30,

    "&>input": {
      background: "#FFF!important",
    },
    "&input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  withBtn: {
    height: 50,
    padding: "0 12px",
    alignItems: "center",
  },
  white: {
    background: "#FFF!important",
  },
});

const NGIInput = ({ allowClear = false, disablePattern = false, white = false, className, ...props }) => {
  const classes = useStyles();

  const onScroll = useCallback(e => e.currentTarget.blur(), []);

  return (
    <Input
      {...props}
      className={classnames(classes.root, props?.suffix && classes.withBtn, className, white && classes.white)}
      onWheel={onScroll}
      allowClear={allowClear}
      pattern={!disablePattern && inputTextPattern}
      title={!disablePattern && inputPatternTitle}
    />
  );
};
export default NGIInput;
