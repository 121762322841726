import React, { useCallback, useEffect, useState } from "react";
import { Form, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import moment from "moment";
import { isEmpty } from "lodash";

const { RangePicker } = DatePicker;

const VersionSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.CONTRACT_VERSIONS);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    const defaultData = {};
    form.setFieldsValue(!isEmpty(filters) ? filters : defaultData);
    if (filters["activeFrom"]) {
      setDateRange([moment(filters["activeFrom"]), moment(filters["activeTo"])]);
    }
  }, []);

  const onSearch = useCallback(
    values => {
      const { pageSize } = getFilters();
      const normalizedData = { ...values };
      if (!!dateRange) {
        normalizedData.activeFrom = customDateFormatWithoutTime(dateRange[0]);
        normalizedData.activeTo = customDateFormatWithoutTime(dateRange[1]);
      }
      handleSearch({ ...normalizedData, pageSize });
    },
    [dateRange]
  );

  const onDateRangeChange = useCallback(value => setDateRange(value), []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <RangePicker inputReadOnly={true} value={dateRange} onChange={onDateRangeChange} />

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default VersionSearch;
