import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { getUsersList, retrieveCampaignList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";
import { campaignListColumns } from "pages/settings/campaign-management/campaignList/CampaignListColumns";
import CampaignSearch from "pages/settings/campaign-management/campaignList/CampaignSearch";

const CampaignList = () => {
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.CAMPAIGN);
  const [TSRList, setTSRList] = useState([]);

  useEffect(() => {
    getUsersList({
      role: [USER_ROLES.SYSTEM_FULL_ADMINS].join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
  }, []);

  const onListLoaded = useCallback(res => {
    const { result } = res?.data;
    if (result?.items?.length === 0) {
      warnMessage("Campaigns were not found");
    }
    setList({ result: { items: Array.isArray(result?.items) ? result.items : [result], meta: result.meta } } || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    retrieveCampaignList(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => campaignListColumns(), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomLink
                roles={[USER_ROLES.SYSTEM_FULL_ADMINS]}
                className="margin-right"
                to={"/settings/campaign/new"}
              >
                <CustomButton icon={<i className="bx bx-plus-circle" />} type="primary" text="New Campaign" />
              </CustomLink>
            </div>

            <CampaignSearch handleSearch={handleSearch} TSRList={TSRList} />
          </div>
          <Col lg={12}>
            <>
              <NGITable
                filtersType={FILTER_PAGE.CAMPAIGN}
                isLoading={isLoading}
                columns={columns}
                data={list}
                updateList={handleSearch}
              />
            </>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default CampaignList;
