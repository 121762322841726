import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { Spin } from "antd";
import { useParams } from "react-router";
import Header from "./Header";
import { retrieveMeterDetails } from "utils/api";
import DataTabs from "pages/meter-management/meters/meterDetails/DataTabs";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import NotFoundContainer from "components/Common/NotFoundContainer";

const MeterDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setLoader] = useState(false);
  const [meterDetails, setMeterDetails] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.MANUALLY_TRIGGERED_COMMAND));
  }, []);

  const updateMeterDetails = useCallback(() => {
    setLoader(true);
    retrieveMeterDetails(id)
      .then(res => {
        const {
          data: { result },
        } = res;
        setMeterDetails(result);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        if (error.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
  }, [id]);

  useEffect(() => {
    updateMeterDetails();
  }, [id]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <NotFoundContainer isNotFound={isNotFound}>
            <Spin spinning={isLoading}>
              <>
                <Header data={meterDetails} onUpdate={updateMeterDetails} />
                <DataTabs data={meterDetails} />
              </>
            </Spin>
          </NotFoundContainer>
        </Container>
      </div>
    </>
  );
};

export default MeterDetails;
