import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cardIcon: {
    marginBottom: 10,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
  },
  cardDivider: {
    width: 30,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    marginBottom: 10,
  },
  cardSubtitle: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    color: "rgba(68, 68, 68, 1);",
  },
  card: {
    backgroundColor: "white",
    maxWidth: 273,
    height: 250,
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    textAlign: "center",
    boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    marginBottom: 15,
  },
});

const UserCard = ({ icon, title, subtitle, route, onCardClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.card} data-route={route} onClick={onCardClick}>
      <img className={classes.cardIcon} src={icon} alt="icon" />
      <div className={classes.cardTitle}>{title}</div>
      <div className={classes.cardDivider} />
      <div className={classes.cardSubtitle}>{subtitle}</div>
    </div>
  );
};

export default UserCard;
