import React, { useCallback, useState } from "react";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { conditions, filterOptions } from "pages/meter-management/awaitingTestingMeters/variables";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const MetersFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.AWAITING_TESTING);
  const [selectedType, setSelectedType] = useState(null);
  const onSearch = useCallback(values => {
    const { type, value } = values;
    const { pageSize } = getFilters();
    handleFilter({ [type]: value?.trim(), pageSize });
  }, []);

  const onTypeChange = useCallback(
    type => {
      setSelectedType(type);
      form.setFieldValue("value", null);
    },
    [form]
  );

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type" rules={[{ required: true, message: "Please select type" }]}>
          <Select showSearch onChange={onTypeChange} placeholder={"Select type"} allowClear>
            {filterOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        {selectedType === "condition" && (
          <Form.Item name="value" rules={[{ required: true, message: "Please select value" }]}>
            <Select showSearch placeholder={"Select value"} allowClear>
              {conditions.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {selectedType !== "condition" && (
          <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
            <Input placeholder="Enter value" allowClear pattern={inputTextPattern} title={inputPatternTitle} />
          </Form.Item>
        )}
        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default MetersFilter;
