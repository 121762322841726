import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveSTOs } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { getTSRList } from "store/TSR/actions";
import { DEPOT_TYPES, STO_VIEW_TYPES } from "pages/stock-management/stockTransferOrders/constants";
import { stoListColumns } from "pages/stock-management/stockTransferOrders/STOListColumns";
import STOSearch from "pages/stock-management/stockTransferOrders/STOSearch";
import { userHasOneOfRoles } from "utils/helpers/functions";
import STOFilter from "pages/stock-management/stockTransferOrders/STOFilter";
import STODetailsModal from "pages/stock-management/stockTransferOrders/STODetails/STODetailsModal";
import NewSTOModal from "pages/stock-management/stockTransferOrders/NewSTO/NewSTOModal";
import NGITooltip from "components/Common/utils/NGITooltip";
import AssignSTOModal from "pages/stock-management/stockTransferOrders/AssignSTO/AssignSTOModal";

const STOList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const { filters, setFilters, getFilters } = useStoreFilters(FILTER_PAGE.STO);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [showAssignTripModal, setShowAssignTripModal] = useState(false);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.ASSET_TYPE_GROUP));
    dispatch(getDictionary(DICTIONARY_TYPES.TRUCK_LICENSE_PLATES));
    dispatch(getTSRList());
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Stock Transfer Orders were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    setSelectedRowKeys([]);
    setSelectedAssetType(null);
    const { viewType } = getFilters();
    const filters = { viewType: filterOptions?.viewType || viewType || STO_VIEW_TYPES.ASSET, ...filterOptions };
    setFilters(filters);
    retrieveSTOs(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };
  const rowSelection = {
    columnTitle: <></>,
    selectedRowKeys,
    renderCell: (checked, record, index, node) => {
      const rowType = record.toDepot ? DEPOT_TYPES.DEPOT_TO : DEPOT_TYPES.DEPOT_FROM;
      if (record.status !== "Not Loaded") {
        return <NGITooltip title="Only 'Not Loaded' assets are possible to assign to the trip.">{node}</NGITooltip>;
      }
      if (selectedAssetType && selectedAssetType !== rowType) {
        return <NGITooltip title="Orders of the same type should be checked.">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      const rowType = record.toDepot ? DEPOT_TYPES.DEPOT_TO : DEPOT_TYPES.DEPOT_FROM;
      if (record.status !== "Not Loaded") {
        checkboxProps.disabled = true;
      }
      if (selectedAssetType && selectedAssetType !== rowType) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
    onChange: rows => {
      if (rows.length === 1) {
        const rowType = list.result.items.find(item => item.id === rows[0]);
        setSelectedAssetType(rowType.toDepot ? DEPOT_TYPES.DEPOT_TO : DEPOT_TYPES.DEPOT_FROM);
      }
      if (rows.length === 0) {
        setSelectedAssetType(null);
      }
      setSelectedRowKeys(rows);
    },
  };

  const onDetailsClose = useCallback(() => setActiveId(null), []);

  const onNewSTOOpen = useCallback(() => setEditId("new"), []);
  const onNewSTOClose = useCallback(() => setEditId(null), []);

  const onAssignTripOpen = useCallback(() => setShowAssignTripModal(true), []);
  const onAssignTripClose = useCallback(() => setShowAssignTripModal(false), []);

  const handleOpenModal = useCallback((id, isEdit) => (isEdit ? setEditId(id) : setActiveId(id.slice(0, 36))), []);

  const columns = useMemo(() => stoListColumns(filters?.viewType, handleOpenModal), [filters]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                roles={[USER_ROLES.DEPOT_CLERK, USER_ROLES.LOGISTICS, USER_ROLES.WAREHOUSE]}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="New STO"
                onClick={onNewSTOOpen}
              />
              {!!selectedRowKeys.length && (
                <CustomButton
                  roles={[USER_ROLES.DEPOT_CLERK, USER_ROLES.WAREHOUSE, USER_ROLES.LOGISTICS]}
                  type="primary"
                  text="Assign Trip"
                  onClick={onAssignTripOpen}
                />
              )}
            </div>

            <STOFilter handleSearch={handleSearch} />
            {userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.LOGISTICS]) && (
              <STOSearch handleSearch={handleSearch} />
            )}
          </div>
          <Col lg={12}>
            <>
              <NGITable
                filtersType={FILTER_PAGE.STO}
                rowSelection={
                  userHasOneOfRoles([
                    USER_ROLES.SYSTEM_FULL_ADMINS,
                    USER_ROLES.DEPOT_CLERK,
                    USER_ROLES.WAREHOUSE,
                    USER_ROLES.LOGISTICS,
                  ]) && filters?.viewType === STO_VIEW_TYPES.ASSET
                    ? rowSelection
                    : null
                }
                rowKey={record => record.id}
                isLoading={isLoading}
                columns={columns}
                data={list}
                updateList={handleSearch}
              />
            </>
          </Col>
        </Container>

        {activeId && <STODetailsModal id={activeId} onClose={onDetailsClose} />}
        {!!editId && <NewSTOModal onClose={onNewSTOClose} update={handleSearch} id={editId} />}
        {showAssignTripModal && (
          <AssignSTOModal
            onClose={onAssignTripClose}
            update={handleSearch}
            selectedAssetType={selectedAssetType}
            selectedAssetIds={selectedRowKeys}
            assetList={list?.result?.items}
          />
        )}
      </div>
    </>
  );
};

export default STOList;
