export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const editableFieldsMap = [
  {
    title: "Address Information",
    fields: [
      {
        street: "Street Name",
        required: true,
      },
      {
        houseNumber: "House Number",
        required: true,
      },
      {
        region: "Region",
        required: true,
      },
      {
        district: "District",
        required: true,
      },
      {
        coordinates: "Coordinates",
      },
      {
        housePhotoFileName: "House Photo",
      },
    ],
  },
];

export const REQUEST_STATUSES = {
  DECLINED: "Declined",
  PENDING: "Pending",
  APPROVED: "Approved",
  APPROVED_BY_CURRENT: "ApprovedByCurrent",
  COMPLETED: "Completed",
};
