import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Form, Row, Select, Space, Spin } from "antd";
import { MAC_ADDRESS_VALIDATION_RULES, VALIDATION_RULES } from "./constants";
import Header from "./Header";
import Title from "antd/lib/typography/Title";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { createUploadTemplate, fetchFirmwares, getAccessoryByType, retrieveDictionaries } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import { useHistory, useParams } from "react-router";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import moment from "moment/moment";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { extractPromisesResult, getTrimmedObjectValues, handleFocusChange } from "utils/helpers/functions";
import NGISelect from "components/Common/inputs/NGISelect";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { ACCESSORY_TYPES } from "pages/meter-management/upload-templates/addManualMeter/constants";
import { NO_SPACE_PATTERN } from "pages/meter-management/configuration/meters/variables";
import { Option } from "antd/es/mentions";
import NGIPrompt from "components/Common/NGIPrompt";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  formItem: {
    marginTop: 15,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

const NewTemplate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { name } = useParams();
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [isPXType, setIsPXType] = useState(false);
  const [templateNames, setTemplateNames] = useState([]);
  const history = useHistory();
  const [dictionaries, setDictionaries] = useState({
    sensors: [],
    microcontrollers: [],
    firmwares: [],
  });
  const {
    list: { batteryModel, meterType, simCardType },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    setLoader(true);
    Promise.allSettled([
      getAccessoryByType(ACCESSORY_TYPES.SENSOR),
      getAccessoryByType(ACCESSORY_TYPES.MICROCONTROLLER),
      fetchFirmwares(),
    ]).then(data => {
      const [sensors, microcontrollers, firmwares] = extractPromisesResult(data);
      setDictionaries({
        sensors: sensors?.data?.result,
        microcontrollers: microcontrollers?.data?.result,
        firmwares: firmwares?.data?.result,
      });
    });
    retrieveDictionaries("meterUploadTemplate")
      .then(res => {
        const {
          data: { result },
        } = res;
        if (name !== "new") {
          const selectedTemplate = result.items.find(item => item.name === name);
          form.setFieldsValue({
            name: selectedTemplate.name,
            description: selectedTemplate.description,
            ...selectedTemplate.content,
            calibratedAt: selectedTemplate.content.calibratedAt
              ? moment(selectedTemplate.content.calibratedAt)
              : undefined,
            assemblyDate: moment(selectedTemplate.content.assemblyDate),
          });
          if (selectedTemplate?.content?.type === "PX-MQTT") {
            setIsPXType(true);
          }
        }
        setTemplateNames(result.items.map(item => item.name));
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
    dispatch(getDictionary(DICTIONARY_TYPES.UPLOAD_TEMPLATE));
    dispatch(getDictionary(DICTIONARY_TYPES.BATTERY_MODEL));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_TYPE));
    dispatch(getDictionary(DICTIONARY_TYPES.SIM_CARD_TYPE));
  }, []);

  const onSave = useCallback(data => {
    setLoader(true);
    setIsDirty(false);
    const formattedData = {
      name: data.name,
      description: data.description,
      content: {
        ...getTrimmedObjectValues(data),
        calibratedAt: data?.calibratedAt?.valueOf(),
        assemblyDate: data?.assemblyDate?.valueOf(),
        simCardTypes: data?.simCardTypes?.toString(),
      },
    };

    createUploadTemplate(formattedData)
      .then(() => {
        setLoader(false);
        successMessage("Template was saved successfully");
        history.push("/meters/registration/templates");
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  const onTypeChange = useCallback(value => {
    onFieldsChanged();
    setIsPXType(value === "PX-MQTT");
  }, []);

  return (
    <>
      <NGIPrompt show={isDirty} />
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header isEdit={name !== "new"} />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    <NGIForm
                      isUpdate={name !== "new"}
                      form={form}
                      name="newTemplate"
                      layout="inline"
                      onFinish={onSave}
                      onChange={onFieldsChanged}
                      className={classes.formContainer}
                    >
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Name *</Title>
                          <NGIFormItem
                            name="name"
                            rules={[
                              ...VALIDATION_RULES,
                              {
                                message: "The name should be unique",
                                validator: (_, value) => {
                                  if (templateNames.includes(value) && name === "new") {
                                    return Promise.reject("The name should be unique");
                                  }
                                  return Promise.resolve();
                                },
                              },
                              {
                                max: 50,
                                message: "The max length is 50",
                              },
                            ]}
                          >
                            <NGIInput disablePattern disabled={name !== "new"} />
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Description *</Title>
                          <NGIFormItem
                            name="description"
                            rules={[
                              ...VALIDATION_RULES,
                              {
                                max: 100,
                                message: "The max length is 100",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Type *</Title>
                          <Form.Item name="type" rules={VALIDATION_RULES}>
                            <NGISelect showSearch onChange={onTypeChange}>
                              {meterType?.map(item => (
                                <Select.Option key={item} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </NGISelect>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Title level={5}>Sensor Type *</Title>
                          <NGIFormItem name="sensorType" rules={VALIDATION_RULES}>
                            <NGISelect onChange={onFieldsChanged}>
                              {dictionaries.sensors.map(item => (
                                <Select.Option key={item.type} value={item.type}>
                                  {item.type}
                                </Select.Option>
                              ))}
                            </NGISelect>
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Embedded SimCard Iccid</Title>
                          <NGIFormItem
                            name="embeddedSimCardIccid"
                            rules={[
                              {
                                pattern: NO_SPACE_PATTERN,
                                message: "Space isn't allowed on begin and finish.",
                              },
                              {
                                max: 20,
                                message: "Please change the value. The max length is 20.",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                        <Col span={12}>
                          <Title level={5}>SimCard Slots Number {!isPXType && "*"}</Title>
                          <NGIFormItem name="simCardSlotsNumber" rules={isPXType ? [] : VALIDATION_RULES}>
                            <NGISelect onChange={onFieldsChanged}>
                              <Option value="1">1</Option>
                              <Option value="2">2</Option>
                            </NGISelect>
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={24}>
                          <Title level={5}>SimCard Types {!isPXType && "*"}</Title>
                          <NGIFormItem name="simCardTypes" rules={isPXType ? [] : VALIDATION_RULES}>
                            <NGISelect
                              style={{ width: "100%" }}
                              tokenSeparators={[","]}
                              mode="multiple"
                              onChange={onFieldsChanged}
                            >
                              {simCardType?.map(item => (
                                <Select.Option key={item} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </NGISelect>
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Firmware {!isPXType && "*"}</Title>
                          <NGIFormItem name="firmware" rules={isPXType ? [] : VALIDATION_RULES}>
                            <NGISelect onChange={onFieldsChanged}>
                              {dictionaries.firmwares.map(item => (
                                <Select.Option key={item.version} value={item.version}>
                                  {item.version}
                                </Select.Option>
                              ))}
                            </NGISelect>
                          </NGIFormItem>
                        </Col>
                        <Col span={12}>
                          <Title level={5}>Controller *</Title>
                          <NGIFormItem name="controller" rules={VALIDATION_RULES}>
                            <NGISelect onChange={onFieldsChanged}>
                              {dictionaries.microcontrollers.map(item => (
                                <Select.Option key={item.type} value={item.type}>
                                  {item.type}
                                </Select.Option>
                              ))}
                            </NGISelect>
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={24}>
                          <Title level={5}>Battery Model {!isPXType && "*"}</Title>
                          <NGIFormItem name="batteryModel" rules={isPXType ? [] : VALIDATION_RULES}>
                            <NGISelect onChange={onFieldsChanged}>
                              {batteryModel?.map(item => (
                                <Select.Option key={item} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </NGISelect>
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Max Battery Voltage {!isPXType && "*"}</Title>
                          <NGIFormItem
                            name="maxBatteryVoltage"
                            rules={[
                              { required: !isPXType, message: "Please enter value" },
                              {
                                pattern: NO_SPACE_PATTERN,
                                message: "Space isn't allowed on begin and finish.",
                              },
                              {
                                validator: (_, value) => {
                                  if (parseFloat(value) < 3 || parseFloat(value) > 4.3) {
                                    return Promise.reject(
                                      "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30."
                                    );
                                  }
                                  return Promise.resolve();
                                },
                                message:
                                  "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30.",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                        <Col span={12}>
                          <Title level={5}>Min Battery Voltage {!isPXType && "*"}</Title>
                          <NGIFormItem
                            name="minBatteryVoltage"
                            rules={[
                              { required: !isPXType, message: "Please enter value" },
                              {
                                pattern: NO_SPACE_PATTERN,
                                message: "Space isn't allowed on begin and finish.",
                              },
                              {
                                validator: (_, value) => {
                                  if (parseFloat(value) < 3 || parseFloat(value) > 4.3) {
                                    return Promise.reject(
                                      "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30."
                                    );
                                  }
                                  return Promise.resolve();
                                },
                                message:
                                  "Please match the requested format. For example, XX.XX. The range is 3.00- 4.30.",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Calibrated At {!isPXType && "*"}</Title>
                          <NGIFormItem name="calibratedAt" rules={isPXType ? [] : VALIDATION_RULES}>
                            <NGIDatePicker allowClear />
                          </NGIFormItem>
                        </Col>
                        <Col span={12}>
                          <Title level={5}>Manufacture Name</Title>
                          <NGIFormItem
                            name="manufactureName"
                            rules={[
                              {
                                pattern: NO_SPACE_PATTERN,
                                message: "Space isn't allowed on begin and finish.",
                              },
                              {
                                max: 50,
                                message: "Please change the value. The max length is 50.",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>Manufacture Location</Title>
                          <NGIFormItem
                            name="manufactureLocation"
                            rules={[
                              {
                                pattern: NO_SPACE_PATTERN,
                                message: "Space isn't allowed on begin and finish.",
                              },
                              {
                                max: 50,
                                message: "Please change the value. The max length is 50.",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                        <Col span={12}>
                          <Title level={5}>MSISDN</Title>
                          <NGIFormItem
                            name="msisdn"
                            rules={[
                              {
                                pattern: NO_SPACE_PATTERN,
                                message: "Space isn't allowed on begin and finish.",
                              },
                              {
                                max: 13,
                                message: "Please change the value. The max length is 13.",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>ICCID</Title>
                          <NGIFormItem
                            name="iccid"
                            rules={[
                              {
                                pattern: /^(89)\d+$/,
                                message: "Please match the requested format. ICCID always starts with 89.",
                              },
                              { min: 18, max: 20, message: "The max length is from 18 to 20." },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                        <Col span={12}>
                          <Title level={5}>IMSI</Title>
                          <NGIFormItem
                            name="imsi"
                            rules={[
                              {
                                pattern: NO_SPACE_PATTERN,
                                message: "Space isn't allowed on begin and finish.",
                              },
                              {
                                max: 15,
                                message: "Please change the value. The max length is 15.",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                      </Row>
                      <Row className={classes.formItem}>
                        <Col span={12}>
                          <Title level={5}>IP</Title>
                          <NGIFormItem
                            name="ip"
                            rules={[
                              {
                                pattern: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
                                message: "Please match the requested format. For example, 192.168.0.15",
                              },
                            ]}
                          >
                            <NGIInput />
                          </NGIFormItem>
                        </Col>
                      </Row>

                      {isPXType && (
                        <>
                          <Row className={classes.formItem}>
                            <Col span={12}>
                              <Title level={5}>Modem Mac address</Title>
                              <NGIFormItem name="modemMac" rules={MAC_ADDRESS_VALIDATION_RULES}>
                                <NGIInput />
                              </NGIFormItem>
                            </Col>
                            <Col span={12}>
                              <Title level={5}>Bluetooth Low Energy MAC address</Title>
                              <NGIFormItem name="bleMac" rules={MAC_ADDRESS_VALIDATION_RULES}>
                                <NGIInput />
                              </NGIFormItem>
                            </Col>
                          </Row>

                          <Row className={classes.formItem}>
                            <Col span={12}>
                              <Title level={5}>Meter Assembly Date *</Title>
                              <NGIFormItem name="assemblyDate" rules={VALIDATION_RULES}>
                                <NGIDatePicker allowClear onKeyDown={handleFocusChange} />
                              </NGIFormItem>
                            </Col>
                            <Col span={12}>
                              <Title level={5}>Certificate ID</Title>
                              <NGIFormItem name="certId">
                                <NGIInput />
                              </NGIFormItem>
                            </Col>
                          </Row>

                          <Row className={classes.formItem}>
                            <Col span={12}>
                              <Title level={5}>Secondary Controller</Title>
                              <NGIFormItem name="controller2">
                                <NGISelect onKeyDown={handleFocusChange}>
                                  {dictionaries.microcontrollers.map(item => (
                                    <Select.Option key={item.type} value={item.type}>
                                      {item.type}
                                    </Select.Option>
                                  ))}
                                </NGISelect>
                              </NGIFormItem>
                            </Col>
                            <Col span={12}>
                              <Title level={5}>Secondary Firmware</Title>
                              <NGIFormItem name="mcu2Firmware">
                                <NGISelect onKeyDown={handleFocusChange}>
                                  {dictionaries.firmwares.map(item => (
                                    <Select.Option key={item.version} value={item.version}>
                                      {item.version}
                                    </Select.Option>
                                  ))}
                                </NGISelect>
                              </NGIFormItem>
                            </Col>
                          </Row>

                          <Row className={classes.formItem}>
                            <Col span={12}>
                              <Title level={5}>Modem Firmware</Title>
                              <NGIFormItem name="modemFirmware">
                                <NGISelect onKeyDown={handleFocusChange}>
                                  {dictionaries.firmwares.map(item => (
                                    <Select.Option key={item.version} value={item.version}>
                                      {item.version}
                                    </Select.Option>
                                  ))}
                                </NGISelect>
                              </NGIFormItem>
                            </Col>
                            <Col span={12}>
                              <Title level={5}>Sensor Firmware</Title>
                              <NGIFormItem name="sensorFwVersion">
                                <NGIInput />
                              </NGIFormItem>
                            </Col>
                          </Row>

                          <Row className={classes.formItem}>
                            <Col span={12}>
                              <Title level={5}>Sensor Hardware</Title>
                              <NGIFormItem name="sensorHwVersion">
                                <NGIInput />
                              </NGIFormItem>
                            </Col>
                            <Col span={12}>
                              <Title level={5}>Battery Capacity</Title>
                              <NGIFormItem name="batteryCapacity">
                                <NGIInput suffix={"mah"} type="number" step="any" />
                              </NGIFormItem>
                            </Col>
                          </Row>

                          <Row className={classes.formItem}>
                            <Col span={12}>
                              <Title level={5}>Factory Tested</Title>
                              <NGIFormItem name="isFactoryTested">
                                <NGISelect onKeyDown={handleFocusChange}>
                                  <Option value={true}>Yes</Option>
                                  <Option value={false}>No</Option>
                                </NGISelect>
                              </NGIFormItem>
                            </Col>
                            <Col span={12}>
                              <Title level={5}>Factory Test Passed</Title>
                              <NGIFormItem name="isFactoryTestPassed">
                                <NGISelect onKeyDown={handleFocusChange}>
                                  <Option value={true}>Yes</Option>
                                  <Option value={false}>No</Option>
                                </NGISelect>
                              </NGIFormItem>
                            </Col>
                          </Row>
                        </>
                      )}
                      <br />

                      <Space>
                        <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
                      </Space>
                    </NGIForm>
                  </CardBody>
                </Card>
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default NewTemplate;
