import React from "react";
import {Card, CardBody, Col, Row, Button} from "reactstrap";
import {Space, Tooltip} from "antd";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {MySearch} from "./MySearch";

const B2cTable = props => {
  const {
    columns,
    data,
    hasNextpage,
    onNextPage,
    enableSearch = true,
    onPreviousPage,
    hasPreviousPage,
    onSearchHandle,
    searchName,
    enableSelect = false,
    onItemSelect = (row, isSelect, rowIndex) => {},
    onSelectAll = (isSelect, rows) => {},
  } = props;
  const nextText = <span>Next</span>;
  const prevText = <span>Previous</span>;

  const pageOptions = {
    sizePerPage: props.pageSize || 50,
    totalSize: data && data.length,
    custom: true,
  };

  const handleTableChange = (type, {page, searchText}) => {
    data.filter(obj =>
      Object.keys(obj).some(
        key => typeof obj[key] === "string" && obj[key].toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    clickToEdit: true,
    hideSelectColumn: !enableSelect,
    onSelect: (row, isSelect, rowIndex, e) => onItemSelect(row, isSelect, rowIndex),
    onSelectAll: (isSelect, rows, e) => onSelectAll(isSelect, rows),
  };

  return (
    <>
      <Card>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({paginationProps, paginationTableProps}) => (
              <ToolkitProvider keyField="id" data={data} columns={columns} bootstrap4>
                {toolkitProps => (
                  <>
                    <Row className="mb-2">
                      {props.title && (
                        <Col sm={8}>
                          <h5>{props.title}</h5>
                        </Col>
                      )}
                      {enableSearch && (
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block pull-right">
                            <div className="position-relative">
                              <MySearch searchHandler={onSearchHandle} name={searchName} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            selectRow={selectRow}
                            keyField="id"
                            responsive
                            remote
                            bordered={false}
                            striped={false}
                            classes={"table align-middle table-nowrap table-hover"}
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Space size={8}>
                          {hasPreviousPage && (
                            <Tooltip placement="bottom" title={prevText}>
                              <Button type={"button"} color={"warning"} onClick={onPreviousPage}>
                                <i className={"bx bx-left-arrow"} />
                                &nbsp;
                              </Button>
                            </Tooltip>
                          )}
                          {hasNextpage && (
                            <Tooltip placement="bottom" title={nextText}>
                              <Button type={"button"} color={"primary"} onClick={onNextPage}>
                                &nbsp;
                                <i className={"bx bx-right-arrow"} />
                              </Button>
                            </Tooltip>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};

B2cTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  hasNextpage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
};
export default B2cTable;
