/* TOP_REGIONS_STATS */
export const GET_TOP_REGIONS_STATS = "GET_TOP_REGIONS_STATS"
export const GET_TOP_REGIONS_STATS_SUCCESS = "GET_TOP_REGIONS_STATS_SUCCESS"
export const GET_TOP_REGIONS_STATS_FAIL = "GET_TOP_REGIONS_STATS_FAIL"

/* STATS */
export const GET_REG_BY_STATUS_STATS = "GET_REG_BY_STATUS_STATS"
export const GET_REG_BY_STATUS_STATS_SUCCESS = "GET_REG_BY_STATUS_STATS_SUCCESS"
export const GET_REG_BY_STATUS_STATS_FAIL = "GET_REG_BY_STATUS_STATS_FAIL"

/* STATS */
export const GET_DAILY_STATS = "GET_DAILY_STATS"
export const GET_DAILY_STATS_SUCCESS = "GET_DAILY_STATS_SUCCESS"
export const GET_DAILY_STATS_FAIL = "GET_DAILY_STATS_FAIL"

/* STATS */
export const GET_APPROVAL_STATS = "GET_APPROVAL_STATS"
export const GET_APPROVAL_STATS_SUCCESS = "GET_APPROVAL_STATS_SUCCESS"
export const GET_APPROVAL_STATS_FAIL = "GET_APPROVAL_STATS_FAIL"

/* STATS */
export const GET_LEAD_TRENDS = "GET_LEAD_TRENDS"
export const GET_LEAD_TRENDS_SUCCESS = "GET_LEAD_TRENDS_SUCCESS"
export const GET_LEAD_TRENDS_FAIL = "GET_LEAD_TRENDS_FAIL"
