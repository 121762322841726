import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGITablePagination from "components/Common/NGITable/NGITablePagination";
import { DEFAULT_PAGE_SIZE } from "utils/constants";
import { Card, CardBody } from "reactstrap";
import NGISpinner from "components/Common/NGISpinner";
import useStoreFilters from "customHooks/useStoreFilters";

const useStyles = makeStyles({
  root: {
    "& .ant-table-selection-column": {
      padding: "0 20px",
    },
    "& .ant-table-thead > tr > th": {
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.105) 0%, rgba(0, 0, 0, 0.15) 100%)",
      color: "#444444",
      textTransform: "uppercase",
    },
    "& .ant-table-cell": {
      borderRight: "1px solid rgba(0, 0, 0, 0.06)!important",
    },
    "& .ant-table.ant-table-bordered": {
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    },
    "& .ant-table.ant-table-bordered > .ant-table-container": {
      borderLeft: "none",
    },
    "& .ant-table-content > table": {
      overflow: "hidden",
      borderRadius: 30,
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    },
    "& .ant-table": {
      borderRadius: 30,
      color: "#444444",
      fontWeight: 600,
      overflow: "auto",
    },
    "& .ant-table-tbody .ant-table-row:nth-child(even)": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },

    "& .ant-pagination-prev > .ant-pagination-item-link": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #444444",
      borderRadius: 30,
      background: "transparent",
    },

    "& .ant-pagination-next > .ant-pagination-item-link": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #444444",
      borderRadius: 30,
      background: "transparent",
    },

    "& .ant-pagination-item": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #444444",
      color: "#444444",
      borderRadius: 30,
      background: "transparent",
    },
    "& .ant-pagination-disabled": {
      opacity: 0.4,
    },
    "& .ant-pagination-item-active": {
      background: "linear-gradient(180deg, #666666 0%, #444444 100%)",
      borderRadius: 30,
      color: "#FFFFFF",

      "&:hover a": {
        color: "#FFFFFF",
      },
    },

    "& .ant-table-pagination.ant-pagination": {
      zIndex: 9,
      position: "inherit",
    },

    "& .ant-select > .ant-select-selector": {
      display: "flex",
      alignItems: "center",
      border: "2px solid #444444",
      borderRadius: 30,
      background: "transparent",
    },
    "& .ant-select:not(.ant-select-disabled):hover .ant-select-selector": {
      borderRightWidth: 2,
    },
  },
});

const paginationConfig = {
  position: ["bottomLeft"],
  totalBoundaryShowSizeChanger: true,
};

const hidePaginationConfig = {
  position: ["none"],
};

const NGITable = ({ columns, data, isLoading = false, updateList, filtersType, ...props }) => {
  const classes = useStyles();
  const { getFilters } = useStoreFilters(filtersType);
  const [showNativePagination, setShowNativePagination] = useState(false);
  const [hasPagination, setHasPagination] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [endCursor, setEndCursor] = useState(null);
  const [startCursor, setStartCursor] = useState(null);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [pagePosition, setPagePosition] = useState(1);

  useEffect(() => {
    const hasPagination = data?.result?.hasOwnProperty("meta");
    const isNativePagination = Boolean(data?.result?.meta?.hasOwnProperty("totalCount"));
    if (!isNativePagination && hasPagination) {
      const { pageSize } = getFilters();
      if (pagePosition !== 1 && !data?.result?.items?.length) {
        setHasNextPage(false);
        setPagePosition(prevState => {
          prevState--;
          return prevState;
        });
        return;
      }
      setItems(data?.result?.items);
      setEndCursor(data?.result?.meta?.pageData?.endCursor);
      setStartCursor(data?.result?.meta?.pageData?.startCursor);
      setHasNextPage(data?.result?.meta?.pageData?.hasNextPage);
      setHasPreviousPage(data?.result?.meta?.pageData?.hasPreviousPage);
      if (filtersType) {
        setPageSize(pageSize);
      }
    } else {
      if (hasPagination) {
        setPagination(prevState => ({
          ...prevState,
          current: Number(data?.result?.meta?.pageData?.pageNum),
          total: data?.result?.meta?.totalCount,
        }));
        setItems(data?.result?.items);
      } else {
        setItems(data?.result?.items);
        setPageSize(data?.result?.items?.length);
      }
    }
    setHasPagination(hasPagination);
    setShowNativePagination(isNativePagination);
  }, [data]);

  const onNextPage = useCallback((pageSize, cursor) => {
    const options = { ...getFilters(), pageAfter: cursor, pageSize };
    updateList(options);
    setPagePosition(prevState => {
      prevState++;
      return prevState;
    });
  }, []);

  const onPreviousPage = useCallback((pageSize, cursor) => {
    const options = { ...getFilters(), pageBefore: cursor, pageSize };
    updateList(options);
    setPagePosition(prevState => {
      prevState--;
      return prevState;
    });
  }, []);

  const onSizeChange = useCallback(pageSize => {
    setPageSize(pageSize);
    updateList({ ...getFilters(), pageSize });
    setPagePosition(1);
  }, []);

  const onPaginationChange = filterOptions => {
    setPagination(prevState => {
      let newOptions = { ...filterOptions };
      if (prevState.pageSize !== filterOptions.pageSize) {
        newOptions = { ...filterOptions, current: 1 };
      }
      updateList({ ...getFilters(), pageNumber: newOptions.current, pageSize: newOptions.pageSize });
      return newOptions;
    });
  };

  return (
    <>
      {items?.length ? (
        <>
          <Table
            bordered
            {...props}
            loading={isLoading}
            dataSource={items}
            columns={columns}
            className={classes.root}
            pagination={
              showNativePagination ? { ...pagination, ...paginationConfig } : { ...hidePaginationConfig, pageSize }
            }
            onChange={onPaginationChange}
          />
          {!showNativePagination && hasPagination && (
            <NGITablePagination
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
              onSizeChange={onSizeChange}
              size={pageSize}
              endCursor={endCursor}
              startCursor={startCursor}
            />
          )}
        </>
      ) : (
        <>
          {!!data && !isLoading ? (
            <Card>
              <CardBody className="text-center">
                <i className="bx bx-warning" />
                <h5>No data is available</h5>
                <p className="text-muted">Please make a different filter selection</p>
              </CardBody>
            </Card>
          ) : (
            <NGISpinner />
          )}
        </>
      )}
    </>
  );
};

export default NGITable;
