import React from "react";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import editIcon from "assets/images/svg/Edit.svg";
import infoIcon from "assets/images/svg/Info.svg";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";
import deleteIcon from "assets/images/svg/Delete.svg";
import { Row, Tag } from "antd";
import { textFormatter } from "components/Common/formatter";

const roles = [USER_ROLES.A_SM, USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.FINANCE];

export const priceManagementListColumns = onDelete => [
  {
    title: "Name",
    dataIndex: "name",
    render: (cellContent, row) => (
      <Row wrap={false}>
        {textFormatter(cellContent)}
        {!!row.isDefault && (
          <Tag style={{ marginLeft: 5 }} color={"green"}>
            DEFAULT
          </Tag>
        )}
      </Row>
    ),
  },
  {
    title: "Price",
    dataIndex: "unitPrice",
  },
  {
    title: "Customer Count",
    dataIndex: "activeCustomersNumber",
  },
  {
    title: "",
    width: "4%",
    key: "action",
    render: (text, row) =>
      !userHasOneOfRoles(roles) ? (
        <ButtonGroup tooltipTitle="View" icon={infoIcon} link={`view/${row.id}`} />
      ) : (
        <ButtonGroupContainer>
          <ButtonGroup tooltipTitle="View" icon={infoIcon} link={`view/${row.id}`} />
          <ButtonGroup tooltipTitle="Edit" icon={editIcon} link={`segment/${row.id}`} />
          {row.activeCustomersNumber === 0 && !row.isDefault && (
            <ButtonGroup tooltipTitle="Delete" icon={deleteIcon} handleClick={() => onDelete(row.id)} />
          )}
        </ButtonGroupContainer>
      ),
  },
];
