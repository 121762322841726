import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
// import { CopyOutlined } from "@ant-design/icons";
// import { successMessage } from "components/Common/responses/message";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";
import { CopyOutlined } from "@ant-design/icons";
import { successMessage } from "components/Common/responses/message";

export const marketingInitiativesListColumns = (classes, domain) => [
  {
    title: "Initiative Name",
    dataIndex: "name",
    render: (cellContent, row) => (
      <div className={classes.withCopyIcon}>
        <NGITooltip title={`${row.name} details`}>
          <CustomLink to={`/marketing/initiative/${row.code}`}>{row.name}</CustomLink>
        </NGITooltip>

        <NGITooltip placement="bottom" title="Copy initiative link">
          <CopyOutlined
            onClick={() => {
              navigator.clipboard.writeText(`${domain}/${row?.code}`);
              successMessage("Initiative link is copied to clipboard.");
            }}
          />
        </NGITooltip>
      </div>
    ),
  },
  {
    title: "Initiative Start Date&Time",
    dataIndex: "startAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Initiative End Date&Time",
    dataIndex: "endAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Initiative Code",
    dataIndex: "code",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Depots list",
    dataIndex: "depots",
    render: cellContent => textFormatter(cellContent?.length ? cellContent?.join(", ") : "ALL DEPOTS"),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
];
