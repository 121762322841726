export const TABS = {
  GENERAL_INFO: "GENERAL_INFO",
};

export const getTabsButtons = () => [
  {
    title: "General Information",
    key: TABS.GENERAL_INFO,
  },
];
