import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { CLASSIFICATION, TABS, TABS_BUTTONS } from "pages/operations/routineDetails/constants";
import GeneralInformationTab from "pages/operations/routineDetails/Tabs/GeneralInformationTab";
import TasksTab from "pages/operations/routineDetails/Tabs/TasksTab";
import AssetsTab from "pages/operations/routineDetails/Tabs/AssetsTab";
import DetailsModal from "pages/operations/routineDetails/DetailsModal";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
});

const DataTabs = ({ routine, customers, updateDetails, isLoading }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TABS.GENERAL_INFORMATION);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  const openDetailsModal = useCallback(customer => setSelectedCustomer(customer), []);
  const closeDetailsModal = useCallback(() => setSelectedCustomer(undefined), []);

  const hideViewBtn = useMemo(() => routine.status === "Close" || routine.status === "Suspended", [routine]);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {TABS_BUTTONS.map(
          item =>
            (routine.status !== "Close" || item.key === TABS.GENERAL_INFORMATION) && (
              <div
                key={item.key}
                className={classnames(classes.tab, activeTab === item.key && classes.active)}
                data-tab={item.key}
                onClick={onTabClick}
              >
                {item.title}
              </div>
            )
        )}
      </div>
      <Card className={classes.container}>
        <CardBody className={classes.cardBody}>
          {activeTab === TABS.GENERAL_INFORMATION && <GeneralInformationTab routine={routine} />}
          {activeTab === TABS.PRIORITY_TASKS && (
            <TasksTab
              customers={customers[CLASSIFICATION.PRIORITY]}
              showAction={!hideViewBtn}
              onOpen={openDetailsModal}
              isLoading={isLoading}
            />
          )}
          {activeTab === TABS.REPOSSESSIONS_TASKS && (
            <TasksTab
              customers={customers[CLASSIFICATION.REPOSSESSIONS]}
              showAction={!hideViewBtn}
              onOpen={openDetailsModal}
              isLoading={isLoading}
            />
          )}
          {activeTab === TABS.INSTALLATIONS_TASKS && (
            <TasksTab
              customers={customers[CLASSIFICATION.INSTALLATIONS]}
              showAction={!hideViewBtn}
              onOpen={openDetailsModal}
              isLoading={isLoading}
            />
          )}
          {activeTab === TABS.FUTURE_TASKS && (
            <TasksTab
              customers={customers[CLASSIFICATION.FUTURE_TASKS]}
              showAction={!hideViewBtn}
              onOpen={openDetailsModal}
              isLoading={isLoading}
            />
          )}
          {activeTab === TABS.BACKLOG_TASKS && (
            <TasksTab
              customers={customers[CLASSIFICATION.BACKLOG]}
              showAction={!hideViewBtn}
              onOpen={openDetailsModal}
              isLoading={isLoading}
            />
          )}
          {activeTab === TABS.NOT_REACHABLE_TASKS && (
            <TasksTab
              customers={customers[CLASSIFICATION.NOT_REACHABLE]}
              showAction={!hideViewBtn}
              onOpen={openDetailsModal}
              isLoading={isLoading}
            />
          )}
          {activeTab === TABS.ASSETS && (
            <AssetsTab routine={routine} updateDetails={updateDetails} isLoading={isLoading} />
          )}
        </CardBody>
        {!!selectedCustomer && (
          <DetailsModal
            isOpen={!!selectedCustomer}
            customer={selectedCustomer}
            onClose={closeDetailsModal}
            updateRoutine={updateDetails}
          />
        )}
      </Card>
    </div>
  );
};

export default DataTabs;
