import React, { useCallback, useMemo, useState } from "react";
import { Col, Form, Modal, Row, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { sendBulkMeterCommand, sendMeterCommand } from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NGIFormItem from "components/Common/form/NGIFormItem";
import {
  API_MAPPING,
  commandRefuseConfig,
  getFieldsMapping,
} from "pages/meter-management/meters/meterDetails/constants";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { getPreparedData } from "pages/meter-management/meters/meterDetails/utils";
import NGICheckbox from "components/Common/inputs/NGICheckbox";
import closeIcon from "assets/images/svg/closeSmall.svg";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";
import NGIForm from "components/Common/form/NGIForm";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "15px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
  select: { width: "100%" },
  formCol: {
    "& .ant-form-item": {
      marginRight: 15,
    },
  },
  commandContainer: {
    marginBottom: 15,
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  fileTitle: {
    fontWeight: 600,
    fontSize: 12,
  },
});

const SendCommandsModal = ({ onClose, meterId, isBulk, isPX = false, meterType }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedData, setUploadedData] = useState(null);
  const {
    list: { manuallyTriggeredCommand },
  } = useSelector(DictionariesSelector);

  const filteredCommandList = useMemo(
    () => manuallyTriggeredCommand?.filter(command => !commandRefuseConfig[command]?.includes(meterType)),
    [manuallyTriggeredCommand, meterType]
  );

  const handleSelectCommand = useCallback(
    value => {
      setSelectedCommand(value);
      setUploadedFile(null);
      setUploadedData(null);
      form.resetFields();
    },
    [form]
  );

  const method = useMemo(() => (isBulk ? sendBulkMeterCommand : sendMeterCommand), [isBulk]);

  const handleSave = useCallback(() => {
    closeConfirmationModal();
    const preparedData = getPreparedData(form.getFieldsValue(), selectedCommand);
    setLoader(true);

    method(API_MAPPING[selectedCommand], isBulk ? uploadedData : preparedData, meterId)
      .then(() => {
        setLoader(false);
        successMessage("Commands were sent successfully");
        onClose();
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, [selectedCommand, meterId, form, isBulk, uploadedData]);

  const validateForm = useCallback(() => form.validateFields(), [form]);

  const getFieldByType = useCallback(
    item => {
      switch (item.type) {
        case "number":
          return <NGIInput type="number" placeholder={`Enter ${item.title}`} />;
        case "boolean":
          return <NGICheckbox onChange={validateForm} />;
        case "datetime":
          return (
            <NGIDatePicker
              dateFormat={"YYYY-MM-DD HH:mm"}
              showTime={{ format: "HH:mm" }}
              placeholder={item.title}
              allowClear
            />
          );
        default:
          return <NGIInput placeholder={`Enter ${item.title}`} />;
      }
    },
    [validateForm]
  );

  const readUploadFile = useCallback(
    e => {
      setLoader(true);
      const file = e.file;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = e => {
          const data = e.target.result;
          const workbook = xlsx.read(data);
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
          const csv = xlsx.utils.sheet_to_csv(worksheet, { raw: false });
          const formattedResult = json.map(item => getPreparedData(item, selectedCommand));

          const noError = formattedResult.every(item => item?.barcode);
          const tooMuchItems = formattedResult?.length > 50;
          !noError && errorMessage("Please upload the correct ‘*.csv’ or ‘*.xlsx’ file");
          tooMuchItems && errorMessage("Recommended max qty of meters in 1 bulk operation = 50");
          setLoader(false);

          if (noError && !tooMuchItems) {
            setUploadedFile(file);
            setUploadedData(csv);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    [selectedCommand]
  );

  const removeFile = useCallback(() => {
    setUploadedFile(null);
  }, []);

  const openConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const onSave = useCallback(() => {
    setIsConfirmationOpen(false);
    form.submit();
  }, [form]);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          Send Meter Commands
          <span>Please select a command from list of commands</span>
        </div>
      }
      open={true}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm name="sendCommand" isUpdate form={form} onFinish={handleSave} className={classes.formContainer}>
          <div className={classes.modalContent}>
            <Row>
              <Col className={classes.commandContainer} span={24}>
                <Title level={5}>Command:</Title>
                <NGISelect
                  showSearch
                  placeholder="Select Command to Continue"
                  value={selectedCommand}
                  className={classes.select}
                  onChange={handleSelectCommand}
                >
                  {filteredCommandList?.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </Col>
            </Row>

            {selectedCommand && !isBulk && (
              <Row>
                {getFieldsMapping(selectedCommand, isPX) &&
                  getFieldsMapping(selectedCommand, isPX).map(item => (
                    <Col className={classes.formCol} key={item.name} span={12}>
                      <Title level={5}>{item.title}:</Title>
                      <NGIFormItem
                        name={item.name}
                        valuePropName={item.type === "boolean" ? "checked" : "value"}
                        rules={[
                          { required: item.required, message: `Please enter ${item.title}` },
                          ...(item?.rules ? item?.rules : []),
                        ]}
                      >
                        {getFieldByType(item)}
                      </NGIFormItem>
                    </Col>
                  ))}
              </Row>
            )}

            {selectedCommand && isBulk && (
              <Col>
                <Title level={5}>Upload Meters</Title>
                <NGIFormItem name="file" rules={isBulk && [{ required: true, message: "Please upload file" }]}>
                  {uploadedFile ? (
                    <div className={classes.fileContainer}>
                      <div className={classes.fileTitle}>{`${uploadedFile.name}`}</div>
                      <div onClick={removeFile} className={classes.removeBtn}>
                        <img src={closeIcon} alt="remove" />
                      </div>
                    </div>
                  ) : (
                    <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                      <img className="upload-bg" src={uploadArea} alt="upload" />
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single upload. Strictly prohibit from uploading company data or other band files
                      </p>
                    </Dragger>
                  )}
                </NGIFormItem>
              </Col>
            )}
          </div>
          <Space>
            <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton
              onClick={openConfirmationModal}
              disabled={!selectedCommand || (isBulk && !uploadedFile)}
              type="primary"
              size="small"
              text="SEND COMMAND"
            />
          </Space>
        </NGIForm>
      </Spin>
      {isConfirmationOpen && (
        <ConfirmationModal
          description={"Are you sure you want to send command?"}
          onCancel={closeConfirmationModal}
          onConfirm={onSave}
        />
      )}
    </Modal>
  );
};

export default SendCommandsModal;
