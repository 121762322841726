import { textFormatter } from "components/Common/formatter";
import { Button, Popconfirm } from "antd";

//remove after update component
/* eslint-disable no-console */

export const requestListColumns = ({ onApprove, onReject }) => [
  {
    dataField: "",
    text: "Approve/Reject",
    formatter: () => {
      const style = {
        marginRight: "10px",
        transform: "scale(1.1)",
        cursor: "pointer",
      };
      // const color = "#448aff";

      const confirmApprove = e => {
        console.log(e);
        onApprove();
      };
      const confirmReject = e => {
        console.log(e);
        onReject();
      };

      const cancel = e => {
        console.log(e);
      };

      return (
        <>
          <div>
            <Popconfirm
              title="Are you sure to Approve this request?"
              onConfirm={confirmApprove}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" size="small" icon={<i className="bx bx-plus" />} style={style} />
            </Popconfirm>
            <Popconfirm
              title="Are you sure to Reject this request?"
              onConfirm={confirmReject}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" size="small" icon={<i className="bx bx-minus" />} style={style} />
            </Popconfirm>
          </div>
        </>
      );
    },
  },
  {
    text: "Receipt",
    dataField: "Receipt",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Customer",
    dataField: "Customer",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "MNO",
    dataField: "MNO",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Type",
    dataField: "Type",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Reason",
    dataField: "Reason",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Description",
    dataField: "Description",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Amount",
    dataField: "Amount",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Creator",
    dataField: "Creator",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    text: "Date",
    dataField: "Date",
    formatter: cellContent => textFormatter(cellContent),
    sort: true,
  },
];
