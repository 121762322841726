import React, { useCallback, useState } from "react";
import { Col, Form, Input, Modal, Select, Space } from "antd";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/lib/mentions";
import { useSelector } from "react-redux";
import { PackagesSelector } from "store/packages/selectors";
import { assignPackageToCustomer } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const PackageModal = ({ onClose, isOpen, customer, onUpdate }) => {
  const [form] = Form.useForm();
  const [selectedPackageCode, setSelectedPackageCode] = useState(null);
  const { list } = useSelector(PackagesSelector);

  const handleCancel = useCallback(() => onClose(), [onClose]);

  const handleSave = useCallback(() => {
    assignPackageToCustomer({ code: selectedPackageCode, customerId: customer.id }).then(() => {
      const selectedPackage = list.find(item => item.code === selectedPackageCode);
      onUpdate({ ...customer, packageName: selectedPackage.name });
      onClose();
    });
  }, [onUpdate, selectedPackageCode, customer, list]);

  const handleChange = useCallback(code => {
    setSelectedPackageCode(code);
  }, []);

  return (
    <Modal open={isOpen} title="Assign package to Customer" onCancel={handleCancel} closable={false} footer={[]}>
      <Form form={form} onFinish={handleSave}>
        <Col span={24}>
          <Title level={5}>Current package</Title>
          <Form.Item>
            <Input disabled value={customer.packageName || "-"} pattern={inputTextPattern} title={inputPatternTitle} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5}>New package</Title>
          <Form.Item name="package">
            <Select
              showSearch
              placeholder={"Please select package"}
              onChange={handleChange}
              value={selectedPackageCode}
            >
              {list.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Space>
          <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
          <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
        </Space>
      </Form>
    </Modal>
  );
};

export default PackageModal;
