import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Col, Form, Space, Spin } from "antd";
import Header from "./Header";
import Title from "antd/lib/typography/Title";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { createMeterSpareParts } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import { v4 as uuidv4 } from "uuid";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { history } from "store";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { getTrimmedObjectValues } from "utils/helpers/functions";
import NGIPrompt from "components/Common/NGIPrompt";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  formItem: {
    width: 300,
  },
});

const NewSparePart = () => {
  const dispatch = useDispatch();
  const partID = useMemo(() => uuidv4(), []);
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const {
    list: { sparePartCategory, sparePartShippingMethod },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.SPARE_PART_CATEGORY));
    dispatch(getDictionary(DICTIONARY_TYPES.SPARE_PART_SHIPPING_METHOD));
  }, []);

  const onSave = async data => {
    setLoader(true);
    setIsDirty(false);
    const formattedData = {
      ...data,
      id: partID,
      minimumLeadTime: Number.parseInt(data?.minimumLeadTime),
    };
    await createMeterSpareParts(getTrimmedObjectValues(formattedData))
      .then(() => {
        setLoader(false);
        successMessage(`Data was saved successfully`);
        history.push("/meters/spare-parts");
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  };

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  return (
    <>
      <NGIPrompt show={isDirty} />
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <Header id={partID} />
              <div className={classes.root}>
                <Card className={classes.container}>
                  <CardBody className={classes.cardBody}>
                    <NGIForm
                      name="newSparePart"
                      form={form}
                      onFinish={onSave}
                      onChange={onFieldsChanged}
                      className="sent-commands-page"
                    >
                      <Col span={24}>
                        <Title level={5}>{"Category*:"}</Title>
                        <NGIFormItem
                          className={classes.formItem}
                          name="category"
                          rules={[{ required: true, message: "The field is required" }]}
                        >
                          <NGISelect showSearch placeholder={"Select category"} onChange={onFieldsChanged}>
                            {!!sparePartCategory &&
                              sparePartCategory.map(item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                          </NGISelect>
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Title level={5}>{"Name*:"}</Title>
                        <NGIFormItem
                          className={classes.formItem}
                          name="name"
                          rules={[{ required: true, message: "The field is required" }]}
                        >
                          <NGIInput placeholder={"Please add name"} />
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Title level={5}>{"Minimum order quantity*:"}</Title>
                        <NGIFormItem
                          className={classes.formItem}
                          name="minimumOrderQuantity"
                          rules={[{ required: true, message: "The field is required" }]}
                        >
                          <NGIInput type={"number"} min={0} placeholder={"Please add value"} />
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Title level={5}>{"Minimum lead time*:"}</Title>
                        <NGIFormItem
                          className={classes.formItem}
                          name="minimumLeadTime"
                          rules={[{ required: true, message: "The field is required" }]}
                        >
                          <NGIInput type={"number"} min={0} placeholder={"Please add value"} />
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Title level={5}>{"Description:"}</Title>
                        <NGIFormItem className={classes.formItem} name="description">
                          <NGITextArea placeholder={"Please add description"} />
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Title level={5}>{"Specification:"}</Title>
                        <NGIFormItem className={classes.formItem} name="specification">
                          <NGIInput placeholder={"Please add specification"} />
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Title level={5}>{"Shipping method:"}</Title>
                        <NGIFormItem className={classes.formItem} name="shippingMethod">
                          <NGISelect placeholder={"Select shipping method"} onChange={onFieldsChanged}>
                            {!!sparePartShippingMethod &&
                              sparePartShippingMethod.map(item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                          </NGISelect>
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Title level={5}>{"Comments:"}</Title>
                        <NGIFormItem className={classes.formItem} name="comments">
                          <NGITextArea placeholder={"Please add comments"} />
                        </NGIFormItem>
                      </Col>
                      <Col span={24}>
                        <Col>
                          <Space>
                            <CustomButton type="primary" htmlType="submit" size="small" text="Save" />
                          </Space>
                        </Col>
                      </Col>
                    </NGIForm>
                  </CardBody>
                </Card>
              </div>
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default NewSparePart;
