import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions";
import { useHistory } from "react-router";
import config from "appconfig";

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cognito } = config;

  const handleCognitoLogout = useCallback(() => {
    const url =
      `${cognito.domain}/logout?state=${cognito.state}` +
      `&client_id=${cognito.clientId}&redirect_uri=${cognito.redirectUrl}` +
      `&response_type=code`;
    window.location.replace(url);
  }, [cognito]);

  useEffect(() => {
    handleCognitoLogout();
    dispatch(logoutUser(history));
  });

  return <></>;
};

export default Logout;
