import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import { fetchCustomerById, retrieveAssetValidationRequestDetails } from "utils/api";
import { Spin } from "antd";
import DataTabs from "pages/operations/validationTaskRequestDetails/DataTabs";
import Header from "pages/operations/validationTaskRequestDetails/Header";

const ValidationTaskRequestDetails = () => {
  const { id } = useParams();
  const [task, setTask] = useState({});
  const [loader, setLoader] = useState(false);
  const [customer, setCustomer] = useState({});
  const tabs = useMemo(() => task?.assets?.map(item => ({ id: item.id, group: item.group })), [task]);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = useCallback(() => {
    setTask({});
    retrieveAssetValidationRequestDetails(id).then(({ data }) => {
      const customerId = data.result.customerId;
      setTask(data.result);
      if (customerId) {
        fetchCustomerById(customerId).then(({ data }) => setCustomer(data.result));
      }
    });
  }, [id]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={!task?.id || loader}>
            <>
              <Header task={task} customer={customer} />
              {tabs && <DataTabs task={task} tabs={tabs} setLoader={setLoader} updateData={updateData} />}
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default ValidationTaskRequestDetails;
