import React from "react";
import { Modal } from "antd";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  img: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
});

const ContractModal = ({ isOpen, onClose, url }) => {
  const classes = useStyles();

  return (
    <Modal title={`Photo preview`} open={isOpen} onCancel={onClose} footer={[]}>
      <img className={classes.img} src={url} alt="preview" />
    </Modal>
  );
};

export default ContractModal;
