import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import React from "react";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import wrenchIcon from "assets/images/svg/Wrench.svg";
import CustomLink from "components/Common/buttons/CustomLink";

export const cylinderRepairListColumns = handleDetails => [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: (cellContent, row) =>
      cellContent !== "UNKNOWN" ? (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${row.serialNumber}?t=serialNumber`}>
          {cellContent}
        </CustomLink>
      ) : (
        cellContent
      ),
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Total weight",
    dataIndex: "",
    render: row => textFormatter(row.cylinderAttributes.totalWeight),
  },
  {
    title: "Collected Date",
    dataIndex: "updatedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: cellContent => textFormatter(cellContent),
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroup
        tooltipTitle="Repair"
        icon={wrenchIcon}
        handleClick={() => {
          handleDetails(row);
        }}
      />
    ),
  },
];
