import React, { useCallback, useEffect, useState } from "react";
import NGITable from "components/Common/NGITable";
import { readCumulativeReportsAPI } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { cumulativeReportColumns } from "pages/meter-management/meters/meterReportColumns";

const Alerts = ({ id }) => {
  const [isLoading, setLoading] = useState(false);
  const [cumulativeValues, setCumulativeValues] = useState(null);

  const onViewCumulative = useCallback(
    (options = {}) => {
      setLoading(true);
      readCumulativeReportsAPI({ meterId: id, ...options })
        .then(res => {
          if (res.data.result.items) {
            setCumulativeValues(res.data);
          } else {
            setCumulativeValues([]);
          }
          setLoading(false);
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    onViewCumulative();
  }, []);

  return (
    <NGITable
      isLoading={isLoading}
      columns={cumulativeReportColumns}
      data={cumulativeValues}
      updateList={onViewCumulative}
    />
  );
};

export default Alerts;
