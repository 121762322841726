import React from "react";
import { Col, Form, Switch } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Title from "antd/lib/typography/Title";
import NGIInput from "components/Common/inputs/NGIInput";
import { NUMBER_PATTERN } from "pages/meter-management/configuration/meters/variables";

const useStyles = makeStyles({
  withSwitch: {
    display: "flex",
    justifyContent: "space-between",
  },
  switchContainer: {
    margin: 0,
  },
  switch: {
    backgroundColor: "#FF5A36",
  },
});

const MeterDataUpdate = ({ form }) => {
  const classes = useStyles();

  return (
    <Form form={form}>
      <Col span={24}>
        <Title level={5}>Calibration Formula</Title>
        <Form.Item
          name="slope"
          rules={[
            { required: true, message: "Slope is required" },
            { pattern: NUMBER_PATTERN, message: "Should be a number" },
          ]}
        >
          <NGIInput maxLength={50} placeholder="Slope" allowClear allowOnlyNumbers />
        </Form.Item>
        <Form.Item
          name="intercept"
          rules={[
            { required: true, message: "Slope is required" },
            { pattern: NUMBER_PATTERN, message: "Should be a number" },
          ]}
        >
          <NGIInput maxLength={50} placeholder="Intercept" allowClear />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Title level={5} className={classes.withSwitch}>
          Leak Test Result
          <Form.Item initialValue={false} name="isLeakTestOk" className={classes.switchContainer}>
            <Switch className={classes.switch} checkedChildren="Passed" unCheckedChildren="Failed" />
          </Form.Item>
        </Title>
        <Title level={5} className={classes.withSwitch}>
          Factory Acceptance Test Result
          <Form.Item initialValue={false} name="isPassed" className={classes.switchContainer}>
            <Switch className={classes.switch} checkedChildren="Passed" unCheckedChildren="Failed" />
          </Form.Item>
        </Title>
        <Form.Item name="location" rules={[{ required: true, message: "Location is required" }]}>
          <NGIInput maxLength={50} placeholder="Location of Conduct" allowClear />
        </Form.Item>
      </Col>
    </Form>
  );
};

export default MeterDataUpdate;
