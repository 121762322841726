import React, { useEffect, useMemo } from "react";
import { Form, Select } from "antd";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_TENANT_NAME } from "utils/constants";
import { uniq } from "lodash";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { makeStyles } from "@material-ui/core/styles";

const { Option } = Select;

const useStyles = makeStyles({
  filterTitle: {
    color: "#bfbfbf",
    width: "95px!important",
  },
  filterValue: {
    width: "110px!important",
    "& .ant-select-selector": {
      padding: "0!important",
    },
  },
});
const TripFilter = ({ handleSearch }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { filters } = useStoreFilters(FILTER_PAGE.TRIP);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const depotList = useMemo(() => depot && depot[USER_TENANT_NAME] && uniq(depot[USER_TENANT_NAME])?.sort(), [depot]);

  useEffect(() => {
    if (!!filters) {
      form.setFieldsValue({
        startLocation: filters?.startLocation || AUTH_USER?.depotName,
        endLocation: filters?.endLocation,
      });
    }
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={handleSearch}>
        <Form.Item className={classes.filterTitle}>Start Location</Form.Item>
        <Form.Item className={classes.filterValue} name="startLocation">
          <Select showSearch allowClear placeholder="All" onChange={form.submit}>
            {depotList?.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="filter-divider" />
        <Form.Item className={classes.filterTitle}>End Location</Form.Item>
        <Form.Item className={classes.filterValue} name="endLocation">
          <Select showSearch allowClear placeholder="All" onChange={form.submit}>
            {depotList?.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TripFilter;
