export const isMarkerInsidePolygon = (marker, polygonData) => {
  let isInside = false;
  if (marker && polygonData) {
    const polygonCoords = extractPolygonCoords(polygonData);
    const markerCoords = extractMarkerCoords(marker);
    if (!isLatLong(markerCoords)) {
      return isInside;
    }
    const x = markerCoords[0];
    const y = markerCoords[1];

    for (let i = 0, j = polygonCoords.length - 1; i < polygonCoords.length; j = i++) {
      const xi = polygonCoords[i][0];
      const yi = polygonCoords[i][1];
      const xj = polygonCoords[j][0];
      const yj = polygonCoords[j][1];

      // eslint-disable-next-line no-mixed-operators
      const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) isInside = !isInside;
    }

    return isInside;
  }
  return isInside;
};

export const extractPolygonCoords = polygon => {
  if (!Array.isArray(polygon)) {
    return polygon
      .getPath()
      .getArray()
      .map(item => [item.lat(), item.lng()]);
  }
  return polygon;
};

export const extractGeoJsonPolygonCoords = polygon => {
  if (!Array.isArray(polygon)) {
    return polygon
      .getPath()
      .getArray()
      .map(item => [item.lng(), item.lat()]);
  }
  return polygon;
};

export const extractMarkerCoords = marker => [marker.position.lat(), marker.position.lng()];

export const isLatLong = coords => isLatitude(coords[0]) && isLongitude(coords[1]);

export const isPolygonNotOnMap = coords => coords.some(item => !isLatitude(item[0]));

export const isLatitude = num => isFinite(num) && Math.abs(num) <= 85;
export const isLongitude = num => isFinite(num) && Math.abs(num) <= 180;

export const getMapZoom = mapTypes => {
  if (mapTypes.includes("premise") || mapTypes.includes("street_address")) return 18;
  if (mapTypes.includes("route")) return 15;
  if (mapTypes.includes("locality")) return 9;
  return 9;
};

export const mapGeoJsonToGoogle = depotData => {
  const coords = depotData?.boundaries?.coordinates[0].map(item => [item[1], item[0]]);
  return {
    ...depotData,
    boundaries: {
      ...depotData?.boundaries,
      coordinates: [coords],
    },
  };
};

const GENERAL_ADDRESS_TYPES = {
  STREET: "route",
  STREET_NUMBER: "street_address",
  DISTRICT: "neighborhood",
  REGION: "locality",
};
export const extractAddress = addressComponents => {
  const newAddress = {};
  addressComponents.forEach(item => {
    if (item.types.includes(GENERAL_ADDRESS_TYPES.STREET)) {
      const foundData = item.address_components.find(k => k.types.includes(GENERAL_ADDRESS_TYPES.STREET));
      newAddress.newStreet = foundData?.long_name;
    }
    if (item.types.includes(GENERAL_ADDRESS_TYPES.STREET_NUMBER)) {
      const foundData = item.address_components.find(k => k.types.includes("street_number"));
      newAddress.newHouseNumber = foundData?.long_name;
    }
    if (item.types.includes(GENERAL_ADDRESS_TYPES.DISTRICT)) {
      const foundData = item.address_components.find(k => k.types.includes(GENERAL_ADDRESS_TYPES.DISTRICT));
      newAddress.newDistrict = foundData?.long_name;
    }
    if (item.types.includes(GENERAL_ADDRESS_TYPES.REGION)) {
      const foundData = item.address_components.find(k => k.types.includes(GENERAL_ADDRESS_TYPES.REGION));
      newAddress.newRegion = foundData?.long_name;
    }
  });

  return newAddress;
};
