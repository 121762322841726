import React, { useCallback, useEffect, useState } from "react";
import NGITable from "components/Common/NGITable";
import { customerTransactionsColumns } from "pages/customer-management/customerDetails/constants";
import TransactionDetailsModal from "pages/customer-management/customerDetails/Tabs/TransactionDetailsModal";
import TransactionsFilter from "pages/customer-management/customerDetails/Tabs/TransactionsFilter";
import { makeStyles } from "@material-ui/core/styles";
import { fetchCustomerTransactions } from "utils/api";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const useStyles = makeStyles({
  balanceHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const TransactionTab = ({ customerId }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const { setFilters } = useStoreFilters(FILTER_PAGE.CUSTOMERS_TRANSACTION_BOOK);

  const onShowModal = useCallback(id => {
    setActiveId(id);
    setShowModal(true);
  }, []);

  useEffect(() => {
    handleCustomerTransactions();
  }, []);

  const handleCustomerTransactions = useCallback(
    (filterParams = {}) => {
      setIsLoading(true);
      setFilters(filterParams);
      fetchCustomerTransactions({ ...filterParams, customerId }).then(({ data }) => {
        setIsLoading(false);
        setTransactions(data);
      });
    },
    [customerId]
  );

  const onCloseModal = useCallback(() => setShowModal(false), []);

  return (
    <>
      <div className={classes.balanceHeader}>
        <div></div>
        <TransactionsFilter type={"transaction"} onFilter={handleCustomerTransactions} />
      </div>
      <NGITable
        isLoading={isLoading}
        filtersType={FILTER_PAGE.CUSTOMERS_TRANSACTION_BOOK}
        columns={customerTransactionsColumns({ onShowModal })}
        updateList={handleCustomerTransactions}
        data={transactions}
      />
      {showModal && <TransactionDetailsModal isOpen={showModal} onClose={onCloseModal} id={activeId} />}
    </>
  );
};

export default TransactionTab;
