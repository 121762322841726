import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { createSTO, retrieveSTODetails, updateSTO } from "utils/api";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import classnames from "classnames";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import { ORDER_TYPES, VALIDATION_RULES } from "pages/stock-management/stockTransferOrders/NewSTO/constants";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { v4 as uuidv4 } from "uuid";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch, useSelector } from "react-redux";
import NewAssetRow from "pages/stock-management/stockTransferOrders/NewSTO/NewAssetRow";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { AUTH_USER, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { validatedAssets } from "pages/stock-management/stockTransferOrders/utils";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { uniq } from "lodash";
import { getTrimmedObjectValues, userHasOneOfRoles } from "utils/helpers/functions";
import moment from "moment";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col-12 + .ant-col-12": {
      paddingLeft: 10,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  addNewAsset: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
});

const NewSTOModal = ({ onClose, tripDate, update, id = "new", isEditTrip = false, tripNumber }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [newType, setNewType] = useState(null);
  const [allowTypeChangeConfirmation, setAllowTypeChangeConfirmation] = useState(false);
  const [selectedFromDepot, setSelectedFromDepot] = useState("");
  const [selectedToDepot, setSelectedToDepot] = useState("");

  useEffect(() => {
    if (id !== "new") {
      setIsLoading(true);
      retrieveSTODetails(id).then(({ data }) => {
        const { items, preferredDate, type } = data?.result;
        form.setFieldsValue({
          ...data?.result,
          preferredDate: moment(preferredDate),
        });
        setSelectedType(type);
        setAssets(items);
        setIsLoading(false);
      });
      return;
    }
    if (tripDate) {
      form.setFieldsValue({
        preferredDate: moment(tripDate),
      });
    }
  }, [id]);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const onSave = useCallback(
    values => {
      if (assets.length) {
        const preparedData = isEditTrip
          ? {
              items: validatedAssets(assets),
              tripNumber,
            }
          : {
              ...getTrimmedObjectValues(values),
              ...(!userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])
                ? values.type === "Inbound"
                  ? { toDepot: AUTH_USER?.depotName }
                  : { fromDepot: AUTH_USER?.depotName }
                : {}),
              preferredDate: customDateFormatWithoutTime(values.preferredDate),
              items: validatedAssets(assets),
              tripNumber,
            };
        setIsLoading(true);
        const request = id === "new" ? createSTO : updateSTO;
        request(preparedData, id)
          .then(() => {
            successMessage(`STO was ${id === "new" ? "created" : "updated"} successfully`);
            setIsLoading(false);
            setTimeout(() => update && update(), 1500);
            onClose();
          })
          .catch(e => {
            setIsLoading(false);
            defaultNgiErrorMethod(e);
          });
      } else {
        warnMessage("At least 1 asset type should be added");
      }
    },
    [assets, id, tripNumber]
  );

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const openConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(true), []);
  const closeConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(false), []);

  const openConfirmTypeChangeModal = useCallback(() => setAllowTypeChangeConfirmation(true), []);
  const closeConfirmTypeChangeModal = useCallback(() => setAllowTypeChangeConfirmation(false), []);

  const onAddAsset = useCallback(() => setAssets(prevState => [...prevState, { id: uuidv4() }]), []);
  const onRemoveAsset = useCallback(e => {
    const { id } = e.currentTarget.dataset;
    setAssets(prevState => prevState.filter(item => item.id !== id));
  }, []);

  const handleTypeChange = useCallback(
    value => {
      if (selectedType && assets.length) {
        openConfirmTypeChangeModal();
        setNewType(value);
        form.setFieldValue("type", selectedType);
      } else {
        setSelectedType(value);
        form.setFieldsValue({
          toDepot: null,
          fromDepot: null,
        });
      }
    },
    [selectedType, assets, form]
  );

  const onTypeChanged = useCallback(() => {
    closeConfirmTypeChangeModal();
    form.setFieldsValue({
      type: newType,
      toDepot: null,
      fromDepot: null,
    });
    setSelectedFromDepot("");
    setSelectedToDepot("");
    setSelectedType(newType);
    setAssets([]);
    setNewType(null);
  }, [newType, form]);

  const onSubmit = useCallback(() => {
    form.submit();
    closeConfirmationModal();
  }, [form]);

  const onFromChanged = useCallback(value => {
    setSelectedFromDepot(value);
    setSelectedToDepot("");
  }, []);
  const onToChanged = useCallback(value => {
    setSelectedToDepot(value);
    setSelectedFromDepot("");
  }, []);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.ASSET_TYPE_GROUP));
    dispatch(getDictionary(DICTIONARY_TYPES.STO_GROUP_CONDITIONS));
  }, []);

  return (
    <Modal
      title={
        <div className={classes.infoContainer}>
          {`${id === "new" ? "Create" : "Edit"} Stock Transfer Order`}
          <span>Please fill in all mandatory fields</span>
        </div>
      }
      className={classes.modal}
      open
      onCancel={openConfirmCloseModal}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm name="newSTO" form={form} onFinish={onSave}>
          <TitleWithDivider title="General Information" />
          <Row className={classes.formItem}>
            <Col span={12}>
              <Title level={5}>Order Type</Title>
              <NGIFormItem name="type" rules={VALIDATION_RULES}>
                <NGISelect
                  disabled={isEditTrip}
                  value={selectedType}
                  onChange={handleTypeChange}
                  placeholder="Select value"
                >
                  {ORDER_TYPES.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
            <Col span={12}>
              <Title level={5}>Preferred Date</Title>
              <NGIFormItem name="preferredDate" rules={VALIDATION_RULES}>
                <NGIDatePicker disabled={isEditTrip || tripDate} disabledPasDates allowClear />
              </NGIFormItem>
            </Col>
          </Row>

          {userHasOneOfRoles([
            USER_ROLES.SYSTEM_FULL_ADMINS,
            USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
            USER_ROLES.IT_OPERATIONS,
            USER_ROLES.IT_OPERATIONS_LEADERSHIP,
            USER_ROLES.WAREHOUSE,
          ]) && (
            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>To Depot</Title>
                <NGIFormItem
                  name="toDepot"
                  rules={[
                    {
                      required: selectedType === "Inbound" || (!!tripNumber && !isEditTrip),
                      message: "Please enter value",
                    },
                  ]}
                >
                  <NGISelect
                    showSearch
                    disabled={
                      (!selectedType || selectedType === "Outbound" || isEditTrip) &&
                      (!tripNumber || !selectedType || isEditTrip)
                    }
                    onChange={onToChanged}
                    placeholder={"Select value"}
                  >
                    {depot[USER_TENANT_NAME] &&
                      uniq(depot[USER_TENANT_NAME])
                        ?.filter(item => item !== selectedFromDepot)
                        ?.sort()
                        ?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>From Depot</Title>
                <NGIFormItem
                  name="fromDepot"
                  rules={[
                    {
                      required: selectedType === "Outbound" || (!!tripNumber && !isEditTrip),
                      message: "Please enter value",
                    },
                  ]}
                >
                  <NGISelect
                    showSearch
                    onChange={onFromChanged}
                    disabled={
                      (!selectedType || selectedType === "Inbound" || isEditTrip) &&
                      (!tripNumber || !selectedType || isEditTrip)
                    }
                    placeholder={"Select value"}
                  >
                    {depot[USER_TENANT_NAME] &&
                      uniq(depot[USER_TENANT_NAME])
                        ?.filter(item => item !== selectedToDepot)
                        ?.sort()
                        ?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            </Row>
          )}

          <TitleWithDivider title="List Of Assets" />
          <div className={classes.assetsContainer}>
            {assets.map((item, i) => (
              <NewAssetRow
                type={selectedType}
                key={item.id}
                index={i}
                setAssets={setAssets}
                onRemoveAsset={onRemoveAsset}
                item={item}
                isEditTrip={isEditTrip}
              />
            ))}

            {!isEditTrip && (
              <div className={classnames(classes.addNewAsset, !selectedType && classes.disabled)} onClick={onAddAsset}>
                + Add New Asset
              </div>
            )}
          </div>

          <TitleWithDivider title="Additional Comments" />
          <Row className={classes.formItem}>
            <Col span={24}>
              <NGIFormItem name="comments">
                <NGITextArea disabled={isEditTrip} rows={5} placeholder="Add Additional Comments" />
              </NGIFormItem>
            </Col>
          </Row>
        </NGIForm>
      </Spin>
      <Divider />
      <br />
      <Space>
        <CustomButton onClick={openConfirmCloseModal} type="primary" size="small" color="outlined" text="Close" />
        <CustomButton
          type="primary"
          disabled={!assets.length}
          onClick={openConfirmationModal}
          text={id === "new" ? "Submit" : "Save"}
          size="small"
        />
      </Space>
      {isConfirmationOpen && (
        <ConfirmationModal
          description={`Are you sure you want to ${id === "new" ? "add the new" : "edit"} STO?`}
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          loading={isLoading}
        />
      )}
      {isConfirmCloseOpen && (
        <ConfirmationModal
          description={
            id === "new"
              ? "STO adding is in progress. The new STO will not be added after the closing of the popup. Are you sure you want to close it?"
              : "STO editing is in progress. Are you sure you want to close it?"
          }
          onCancel={closeConfirmCloseModal}
          onConfirm={onClose}
        />
      )}
      {allowTypeChangeConfirmation && (
        <ConfirmationModal
          description="The order type is changed. All added assets will be removed. Are you sure you want to change the type?"
          onCancel={closeConfirmTypeChangeModal}
          onConfirm={onTypeChanged}
        />
      )}
    </Modal>
  );
};

export default NewSTOModal;
