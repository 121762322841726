import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";
import { REQUEST_STATUSES } from "pages/customer-management/customerUpdateLocationRequest/createCustomerLocationUpdateRequest/constants";

const statuses = [REQUEST_STATUSES.APPROVED_BY_CURRENT, REQUEST_STATUSES.PENDING];

export const customerUpdateLocationListColumns = () => [
  {
    title: "Customer ID",
    dataIndex: "",
    render: row => {
      if (
        !userHasOneOfRoles([
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.CUSTOMER_CARE,
          USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
          USER_ROLES.IT_OPERATIONS,
          USER_ROLES.IT_OPERATIONS_LEADERSHIP,
          USER_ROLES.A_SM,
        ]) ||
        !statuses.includes(row.status)
      )
        return textFormatter(row.customerId);

      return (
        <NGITooltip title="Approve/Decline Customer Location Update request">
          <div>
            <CustomLink to={`/customers/update-location-request/${row.id}`}>{row.customerId}</CustomLink>
          </div>
        </NGITooltip>
      );
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    render: textFormatter,
  },
  {
    title: "Request Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Created by",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Role",
    dataIndex: "role",
    render: textFormatter,
  },
  {
    title: "Current Depot",
    dataIndex: "depot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "New Depot",
    dataIndex: "newDepot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
];
