import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_TENANT_NAME } from "utils/constants";
import { uniq } from "lodash";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { formatName, userHasAdminRole } from "utils/helpers/functions";
import { AVAILABLE_STATUSES } from "pages/operations/prospectDetails/variables";

const { Option } = Select;

const ProspectsSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.PROSPECTS);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const depotList = useMemo(() => depot && depot[USER_TENANT_NAME] && uniq(depot[USER_TENANT_NAME])?.sort(), [depot]);

  const searchOptions = useMemo(() => {
    const result = [
      {
        value: "status",
        title: "Status",
      },
      {
        value: "information",
        title: "Customer Information",
      },
    ];
    if (userHasAdminRole())
      result.push({
        value: "depot",
        title: "Depot",
      });
    return result;
  }, []);

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (filters?.createdAtFrom) {
        form.setFieldsValue({
          type: "date",
          date: [moment(filters?.createdAtFrom), moment(filters?.createdAtTo)],
          value: null,
        });
        setSelectedFilter("date");
      }
      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: null,
        value: null,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    if (values?.type === "date") {
      const filter = {};
      filter.createdAtFrom = getStartOfTheDay(moment(values?.date[0]));
      filter.createdAtTo = getEndOfTheDay(moment(values?.date[1]));
      handleSearch(filter);
      return;
    }
    if (values?.type === "depot") {
      const depotValue = values.value === "ALL DEPOTS" ? "null" : values.value;
      handleSearch({ [values.type]: depotValue, pageSize });
      return;
    }
    !(values.value && !values.type) &&
      handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
  }, []);

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            showSearch
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <>
          {selectedFilter !== "status" && selectedFilter !== "depot" && selectedFilter !== "information" && (
            <Form.Item name="value">
              <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          )}
          {selectedFilter === "information" && (
            <Form.Item name="value">
              <Input
                placeholder={
                  "Search by: First Name, Last Name, Middle Name, Phone Number, Secondary Phone, Close Relative Phone, ID Number, Street"
                }
                allowClear
                pattern={inputTextPattern}
                title={
                  "Search by: First Name, Last Name, Middle Name, Phone Number, Secondary Phone, Close Relative Phone, ID Number, Street"
                }
              />
            </Form.Item>
          )}
          {selectedFilter === "status" && (
            <Form.Item name="value">
              <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select status"}>
                {AVAILABLE_STATUSES.map(item => (
                  <Option key={item} value={item}>
                    {formatName(item)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {selectedFilter === "depot" && (
            <Form.Item name="value">
              <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                {["ALL DEPOTS", ...depotList]?.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default ProspectsSearch;
