import { textFormatter, customDateFormatWithTime } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const hideRoles = [
  USER_ROLES.PRODUCT_TECHNICIANS,
  USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
  USER_ROLES.IT_OPERATIONS,
  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
];

export const csrpColumns = [
  {
    dataIndex: "customerId",
    title: "Customer ID",
    render: (cellContent, csrp) => (
      <CustomLink underlined target="_blank" to={`/customers/customer/${csrp.customerId}`}>
        {csrp.customerId}
      </CustomLink>
    ),
    width: 150,
  },
  {
    dataIndex: "meterBarcode",
    title: "Barcode",
    render: (cellContent, csrp, index) =>
      userHasOneOfRoles(hideRoles) ? (
        <div>{textFormatter(csrp.meterBarcode)}</div>
      ) : (
        <Tooltip placement="bottom" title={`Cooking Session Details`}>
          <div>
            <CustomLink to={`session-details/${csrp.meterBarcode}/${index}`}>
              {textFormatter(csrp.meterBarcode)}
            </CustomLink>
          </div>
        </Tooltip>
      ),
    width: 150,
  },
  {
    dataIndex: "unitPrice",
    title: "Unit Price",
    render: (cellContent, csrp) => textFormatter(csrp.cookingSession.unitPrice),
    width: 150,
  },
  {
    dataIndex: "creditUsed",
    title: "Credit Used",
    render: (cellContent, csrp) => textFormatter(csrp.creditUsed),
    width: 150,
  },
  {
    dataIndex: "cookingSession.endCredit",
    title: "End Credit",
    render: (cellContent, csrp) => textFormatter(csrp.cookingSession.endCredit),
    width: 150,
  },
  {
    title: "VOLUME USED (G)",
    render: row =>
      textFormatter((row?.cookingSession?.endCumulativeMass - row?.cookingSession?.startCumulativeMass).toFixed(2)),
    width: 150,
  },
  {
    dataIndex: "cookingSession.gasRemaining",
    title: "Gas Remaining",
    render: (cellContent, csrp) => textFormatter(csrp.cookingSession.gasRemaining),
    width: 150,
  },
  {
    title: "TIME(M)",
    render: row => ((row?.cookingSession?.endTime - row?.cookingSession?.startTime) / 60000).toFixed(),
    width: 100,
  },
  {
    dataIndex: "cookingTime",
    title: "Cooking Time",
    render: (cellContent, csrp) => textFormatter(customDateFormatWithTime(csrp.cookingTime)),
    width: 250,
  },
];
