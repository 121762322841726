import React, { useCallback, useState } from "react";
import { Col, Divider, Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { RESOLUTION_OPTIONS } from "./variables";
import { cylinderRepair, retrieveAssetUploadUrlByType, uploadPictureToS3Bucket } from "utils/api";
import Title from "antd/es/typography/Title";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import actionIcon from "./../../../assets/images/svg/ActionRequired.svg";
import NGITextArea from "components/Common/inputs/NGITextArea";
import removeIcon from "assets/images/svg/Close.svg";
import cameraIcon from "assets/images/svg/Camera.svg";
import { base64toBuffer, getTrimmedObjectValues, toBase64, uuidv4 } from "utils/helpers/functions";
import classnames from "classnames";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    marginBottom: 24,
    paddingTop: 20,
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  listBlock: {
    display: "flex",
  },
  item: {
    marginBottom: 20,
    width: "50%",
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  commentContainer: {
    marginTop: 40,
  },
  action: {
    textAlign: "center",
    height: 240,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
  },
  actionTitle: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    width: 300,
    marginBottom: 20,
  },
  actionText: {
    fontSize: 13,
    fontWeight: 300,
    textAlign: "center",
    width: 300,
  },
  actionImage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: "10%",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F5F5F5",
    borderRadius: 5,
    padding: 5,
    marginBottom: 10,
    marginRight: 9,
    width: "32%",
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
  },
  fileContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  fileInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "0 10px",
    width: "100%",
    overflow: "hidden",
  },
  fileTitle: {
    fontWeight: 600,
    fontSize: 12,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  fileSize: {
    fontWeight: 300,
    fontSize: 10,
  },
  fileRemove: {
    padding: "0 8px",

    "& :hover": {
      cursor: "pointer",
    },
  },
  uploadContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "20px 0",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    fontWeight: 600,
    color: "white",
    padding: 12,
    height: 40,
    borderRadius: 20,
    textTransform: "uppercase",
    fontSize: "11px!important",

    "&> img": {
      marginRight: 10,
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    color: "#ff4d4f",
    fontSize: 10,
  },
  fileItemsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  disabled: {
    cursor: "not-allowed!important",
    opacity: 0.6,
  },
});

const CylinderRepairModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const { barcode, type, cylinderAttributes, depot, updatedAt, id, updatedBy, serialNumber } = activeItem;
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [files, setFiles] = useState([]);
  const [isFormTouched, setIsFormTouched] = useState(false);

  const handleChange = useCallback(value => {
    form.resetFields();
    setFiles([]);
    setIsFormTouched(false);
    setSelectedType(value);
  }, []);

  const getPictureIds = useCallback(
    async (fileList, id) =>
      await Promise.all(
        fileList.map(item =>
          retrieveAssetUploadUrlByType("picture", item.fileType.replace("image/", "."), id).then(
            ({ data: { result } }) => {
              const base64Data = base64toBuffer(item.base64);
              return uploadPictureToS3Bucket(result.url, base64Data, item.fileType).then(() => result.fileName);
            }
          )
        )
      ),
    []
  );

  const handleSave = useCallback(async () => {
    const { comment } = form.getFieldsValue();
    setIsLoading(true);
    let pictures = [];
    setIsFormTouched(true);

    if (selectedType === "Decommissioned") {
      if (files.length) {
        pictures = await getPictureIds(files, id);
      } else {
        form.submit();
        setIsLoading(false);
        return;
      }
    }
    const params = {
      action: selectedType,
      pictures,
      comment,
    };

    cylinderRepair(id, getTrimmedObjectValues(params))
      .then(() => {
        successMessage("Saved successfully");
        onUpdate();
        onClose();
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [form, selectedType, id, files]);

  const handleFileChange = useCallback(async event => {
    const fileUploaded = event.target.files[0];
    const base64Data = await toBase64(fileUploaded);
    setFiles(prevState => [
      ...prevState,
      {
        _id: uuidv4(),
        name: fileUploaded.name,
        size: (fileUploaded.size / 1000).toFixed(2),
        base64: base64Data,
        fileType: fileUploaded.type,
      },
    ]);
    event.target.value = "";
  }, []);

  const handleRemove = useCallback(event => {
    const id = event.target.dataset.id;
    setFiles(prevState => prevState.filter(item => item._id !== id));
  }, []);

  return (
    <Modal
      open={isOpen}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Cylinder
            <span>{barcode}</span>
          </div>
          <NGISelect showSearch value={selectedType} onChange={handleChange} placeholder="SELECT AN OPTION">
            {RESOLUTION_OPTIONS.map(item => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))}
          </NGISelect>
        </div>
      }
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <div>
            <div className={classes.container}>
              <div className={classes.listBlock}>
                <div className={classes.item}>
                  <div className={classes.title}>BARCODE</div>
                  <div className={classes.value}>
                    {barcode ? (
                      <CustomLink underlined target="_blank" to={`/warehouse/assets/${serialNumber}?t=serialNumber`}>
                        {barcode}
                      </CustomLink>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>CYLINDER TYPE</div>
                  <div className={classes.value}>{type ?? "-"}</div>
                </div>
              </div>
              <div className={classes.listBlock}>
                <div className={classes.item}>
                  <div className={classes.title}>Total weight</div>
                  <div className={classes.value}>{cylinderAttributes?.totalWeight ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>DEPOT</div>
                  <div className={classes.value}>{depot ?? "-"}</div>
                </div>
              </div>
              <div className={classes.listBlock}>
                <div className={classes.item}>
                  <div className={classes.title}>COLLECTED DATE</div>
                  <div className={classes.value}>{customDateFormatWithoutTime(updatedAt) ?? "-"}</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.title}>Returned By</div>
                  <div className={classes.value}>
                    {updatedBy ? (
                      <CustomLink underlined target="_blank" to={`/user/${updatedBy}`}>
                        {updatedBy}
                      </CustomLink>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!selectedType ? (
            <div className={classes.action}>
              <img src={actionIcon} alt="actionIcon" className={classes.actionImage} />
              <div className={classes.actionTitle}>ACTION IS REQUIRED!</div>
              <div className={classes.actionText}>
                Please select one of the dropdown options to change the status of the cylinder.
              </div>
            </div>
          ) : (
            <>
              <Divider />
              <Form form={form} name="for-repair">
                <div className={classes.commentContainer}>
                  {selectedType === "Decommissioned" && (
                    <>
                      <Col>
                        <Title level={5}>Attach Photos</Title>
                        <div className={classes.fileItemsContainer}>
                          {files &&
                            files.map(item => (
                              <div className={classes.fileItem} key={item._id}>
                                <div className={classes.fileContainer}>
                                  <img className={classes.filePreview} src={item.base64} alt="preview img" />
                                  <div className={classes.fileInfo}>
                                    <div className={classes.fileTitle} title={item.name}>
                                      {item.name}
                                    </div>
                                    <div className={classes.fileSize}>{`${item.size} kb`}</div>
                                  </div>
                                  <div className={classes.fileRemove}>
                                    <img onClick={handleRemove} data-id={item._id} src={removeIcon} alt="remove" />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className={classes.uploadContainer}>
                          <label
                            onChange={handleFileChange}
                            htmlFor="file"
                            className={classnames(classes.uploadButton, files.length === 5 && classes.disabled)}
                          >
                            <input disabled={files.length === 5} accept="image/*" type="file" id="file" hidden />
                            <img src={cameraIcon} alt="camera" />
                            Add photo
                          </label>
                        </div>
                        {!files.length && isFormTouched && <div className={classes.errorMsg}>Please attach photos</div>}
                      </Col>
                      <Divider />
                    </>
                  )}
                  <Col>
                    <Title level={5}>Additional Comments</Title>
                    <Form.Item name="comment">
                      <NGITextArea rows={5} placeholder="Please add your comments" />
                    </Form.Item>
                  </Col>
                </div>
              </Form>
            </>
          )}
        </div>
      </Spin>
      <Space>
        <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
        <CustomButton onClick={handleSave} disabled={!selectedType} type="primary" size="small" text="Confirm" />
      </Space>
    </Modal>
  );
};

export default CylinderRepairModal;
