import React from "react";
import { textFormatter } from "components/Common/formatter";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import deleteIcon from "assets/images/svg/Delete.svg";

export const teamsListColumns = handleRemove => [
  {
    title: "Team Name",
    dataIndex: "name",
    render: (_, row) => (
      <NGITooltip title={`${row.name} details`}>
        <CustomLink to={`/depots/team/${row.name}+${row.depot}`}>{row.name}</CustomLink>
      </NGITooltip>
    ),
  },
  {
    title: "MEMBERS QUANTITY",
    dataIndex: "members",
    render: cellContent => textFormatter(cellContent?.length),
  },
  {
    title: "Team Depot",
    dataIndex: "depot",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    dataIndex: "",
    title: "",
    width: "60px",
    render: row => (
      <ButtonGroup
        tooltipTitle="Remove team"
        icon={deleteIcon}
        handleClick={() => {
          handleRemove(row);
        }}
      />
    ),
  },
];
