import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import moment from "moment/moment";

const useStyles = makeStyles({
  root: {
    maxHeight: 300,
    overflow: "auto",
    padding: "0 6px",
  },
  container: {
    padding: 20,
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    marginBottom: 10,
  },
  date: {
    fontWeight: 300,
    fontSize: 11,
    marginBottom: 12,
  },
  item: {},
  value: {
    fontWeight: 600,
    marginLeft: 5,
  },
  testSuccess: {
    color: "#17C347",
  },
  testFailed: {
    color: "#FF2E00",
  },
});

const TestResultHistory = ({ data = [] }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!!data.length ? (
        data.map(item => (
          <div key={item.maintainedAt} className={classes.container}>
            <div className={classes.date}>{moment(item.maintainedAt).format("YYYY-MM-DD HH:mm:ss")}</div>

            <div className={classes.item}>
              Calibration Formula:
              <span
                className={classes.value}
              >{`${item.factoryAcceptanceTestData.slope}, ${item.factoryAcceptanceTestData.intercept}`}</span>
            </div>

            <div className={classes.item}>
              Leak Test Result:
              <span
                className={classnames(
                  classes.value,
                  item.factoryAcceptanceTestData.isLeakTestOk ? classes.testSuccess : classes.testFailed
                )}
              >
                {item.factoryAcceptanceTestData.isLeakTestOk ? "Passed" : "Failed"}
              </span>
            </div>

            <div className={classes.item}>
              Location of Conduct:
              <span className={classes.value}>{item.location}</span>
            </div>
          </div>
        ))
      ) : (
        <>No Test Results History </>
      )}
    </div>
  );
};

export default TestResultHistory;
