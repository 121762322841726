import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Col, Container } from "reactstrap";
import { stoveRepairListColumns } from "./stoveRepairListColumns";
import { getPendingStoveRepairListAPI, getUsersList } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import StoveRepairFilter from "pages/warehouse/stoveRepair/stoveRepairFilter";
import StoveRepairModal from "pages/warehouse/stoveRepair/StoveRepairModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { userHasOneOfRoles } from "utils/helpers/functions";

const StoveRepairPage = () => {
  const [isLoading, setLoader] = useState(false);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.STOVE_REPAIR);
  const [stovesList, setStovesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [itemDetails, setItemDetails] = useState(null);
  const [TSRList, setTSRList] = useState([]);

  const hideRoles = [USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.IT_OPERATIONS];

  const openModal = useCallback(item => {
    setItemDetails(item);
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    getUsersList({
      role: [
        USER_ROLES.SYSTEM_FULL_ADMINS,
        USER_ROLES.TECHNICIANS,
        USER_ROLES.MTSR,
        USER_ROLES.LT,
        USER_ROLES.TRAINING,
        USER_ROLES.TSS,
      ].join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
    handleFilter(!isEmpty(filters) ? filters : { state: "Available" });
  }, []);

  const handleFilter = useCallback((options = {}) => {
    setFilters(options);
    setLoader(true);

    getPendingStoveRepairListAPI(options)
      .then(res => {
        setStovesList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(() => {
    const col = stoveRepairListColumns(openModal);
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [hideRoles, AUTH_USER]);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <StoveRepairFilter onFilter={handleFilter} isLoading={isLoading} TSRList={TSRList} />
          </div>
          <Col>
            <NGITable
              filtersType={FILTER_PAGE.STOVE_REPAIR}
              columns={columns}
              data={stovesList}
              isLoading={isLoading}
              updateList={handleFilter}
            />
          </Col>
          {showModal && (
            <StoveRepairModal
              onClose={closeModal}
              isOpen={showModal}
              activeItem={itemDetails}
              onUpdate={handleFilter}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default StoveRepairPage;
