import React, { useMemo, useEffect } from "react";
import { Col, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import CookingSessionFilters from "pages/meter-management/logs/reports/csrp/cookingSessionFilter";
import { csrpColumns } from "./csrpColumns";
import { MeterReportsSelector } from "store/meter-management/logs/reports/selectors";
import { getCsrp, resetCsrp } from "store/meter-management/logs/reports/actions";
import NGITable from "components/Common/NGITable";
import { FILTER_PAGE } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";

const CookingSession = () => {
  const { loadingCsrp, csrp, meta } = useSelector(MeterReportsSelector);
  const dispatch = useDispatch();

  const dataSource = useMemo(
    () =>
      csrp
        .map(item => ({
          ...item,
          key: item.id,
          creditUsed: item?.cookingSession?.startCredit - item?.cookingSession?.endCredit,
          gasUsed: item?.cookingSession?.endCumulativeMass - item?.cookingSession?.startCumulativeMass,
          sessionTime: (item?.cookingSession?.endTime - item?.cookingSession?.startTime) / 60,
        }))
        .sort((a, b) => b.cookingTime - a.cookingTime),
    [csrp]
  );
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.COOKING_SESSION);

  useEffect(() => {
    (!!filters.meterBarcode || !!filters.bad) && handleOnFilter(filters);
    return () => dispatch(resetCsrp());
  }, []);

  const handleOnFilter = filterOption => {
    setFilters(filterOption);
    dispatch(getCsrp(filterOption));
  };

  const data = useMemo(() => ({ result: { meta, items: dataSource } }), [meta, dataSource]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <CookingSessionFilters onFilter={handleOnFilter} isLoading={loadingCsrp} />
          </div>

          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.COOKING_SESSION}
              columns={csrpColumns}
              data={data}
              isLoading={loadingCsrp}
              updateList={handleOnFilter}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default CookingSession;
