import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { TABS, TABS_BUTTONS } from "./constants";
import Edit from "./Tabs/Edit";
import View from "./Tabs/View";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  hide: {
    display: "none",
  },
});

const DataTabs = ({ team }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TABS.DETAILS);

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {TABS_BUTTONS.map(item => {
          if (item?.hideRoles && !userHasAdminRole() && userHasOneOfRoles(item?.hideRoles)) return null;
          return (
            <div
              key={item.key}
              className={classnames(classes.tab, activeTab === item.key && classes.active)}
              data-tab={item.key}
              onClick={onTabClick}
            >
              {item.key === TABS.EDIT ? `${item.title} (${team?.members?.length ?? 0})` : item.title}
            </div>
          );
        })}
      </div>
      <Card className={classes.container}>
        <CardBody className={classes.cardBody}>
          <div className={activeTab !== TABS.EDIT && classes.hide}>
            <Edit data={team} />
          </div>
          <div className={activeTab !== TABS.DETAILS && classes.hide}>
            <View data={team} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default DataTabs;
