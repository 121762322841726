import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { uniq } from "lodash";
import moment from "moment";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { TSRSelector } from "store/TSR/selectors";
import { dateFormat } from "pages/stock-management/stockTransferOrders/constants";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import { makeStyles } from "@material-ui/core/styles";
import TSRSelect from "components/Common/components/TSRSelect";

const { Option } = Select;
const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  root: { width: "250px!important" },
});

const searchOptions = [
  {
    value: "technician",
    title: "TSR",
  },
  {
    value: "type",
    title: "Category",
  },
  {
    value: "information",
    title: "Customer Information",
  },
  {
    value: "depot",
    title: "Depot",
  },
  {
    title: "Date",
    value: "date",
  },
];

const RepossessedSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const classes = useStyles();

  const [selectedFilter, setSelectedFilter] = useState("");
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.REPOSSESSED_CUSTOMERS);
  const { TSRList } = useSelector(TSRSelector);

  const {
    list: { depot, customerType },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (filters?.updatedAtFrom) {
        form.setFieldsValue({
          type: "date",
          date: [moment(filters?.updatedAtFrom), moment(filters?.updatedAtTo)],
          value: null,
        });
        setSelectedFilter("date");
      }
      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: null,
        value: null,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    if (values?.type === "date") {
      const filter = { pageSize };
      filter.updatedAtFrom = getStartOfTheDay(values?.date[0]);
      filter.updatedAtTo = getEndOfTheDay(values?.date[1]);
      handleSearch(filter);
    } else {
      !(values.value && !values.type) &&
        handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
    }
  }, []);

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <>
          {selectedFilter !== "depot" &&
            selectedFilter !== "type" &&
            selectedFilter !== "date" &&
            selectedFilter !== "technician" &&
            selectedFilter !== "information" && (
              <Form.Item name="value">
                <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
              </Form.Item>
            )}
          {selectedFilter === "information" && (
            <Form.Item name="value">
              <Input
                placeholder={
                  "Search by: First Name, Last Name, Middle Name, Phone Number, Secondary Phone, Close Relative Phone, ID Number, Street"
                }
                allowClear
                pattern={inputTextPattern}
                title={
                  "Search by: First Name, Last Name, Middle Name, Phone Number, Secondary Phone, Close Relative Phone, ID Number, Street"
                }
              />
            </Form.Item>
          )}
          {selectedFilter === "technician" && (
            <Form.Item name="value">
              <TSRSelect
                showSearch
                initialList={TSRList}
                roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                depot={AUTH_USER?.depotName}
              />
            </Form.Item>
          )}

          {selectedFilter === "depot" && (
            <Form.Item name="value">
              <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                {depot[USER_TENANT_NAME] &&
                  uniq(depot[USER_TENANT_NAME])
                    ?.sort()
                    ?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          )}
          {selectedFilter === "type" && (
            <Form.Item name="value">
              <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                {customerType?.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {selectedFilter === "date" && (
            <Form.Item
              name="date"
              rules={[{ required: true, message: "Please select date range" }]}
              className={classes.root}
            >
              <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
            </Form.Item>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default RepossessedSearch;
