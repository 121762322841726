import React, { useCallback, useEffect } from "react";
import { Form, Select } from "antd";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { FILTER_STATUS_OPTIONS } from "pages/depot-management/depots/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const DepotsFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const { getFilters, filters } = useStoreFilters(FILTER_PAGE.DEPOT);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    handleFilter({ ...values, pageSize });
  }, []);

  useEffect(() => {
    if (filters["status"]) {
      form.setFieldsValue(filters);
    }
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="meters-filter" layout="inline" onFinish={onSearch}>
        <Form.Item name="status" rules={[{ required: true, message: "Please select status" }]}>
          <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select status"} allowClear>
            {Object.keys(FILTER_STATUS_OPTIONS).map(key => (
              <Option key={key} value={key}>
                {FILTER_STATUS_OPTIONS[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <CustomButton type="primary" htmlType="submit" size="small" text="Filter" />
      </Form>
    </div>
  );
};

export default DepotsFilter;
