import React, { useCallback, useEffect, useState } from "react";
import NGITable from "components/Common/NGITable";
import { retrieveMeterStatus } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { meterStatusColumns } from "pages/meter-management/meters/meterReportColumns";

const Alerts = ({ id }) => {
  const [isLoading, setLoading] = useState(false);
  const [statusValues, setStatusValues] = useState(null);

  const onViewStatus = useCallback(
    (options = {}) => {
      setLoading(true);
      retrieveMeterStatus(id, options)
        .then(res => {
          if (res.data.result.items) {
            setStatusValues(res.data);
          } else {
            setStatusValues(null);
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [id]
  );

  useEffect(() => {
    onViewStatus();
  }, []);

  return <NGITable isLoading={isLoading} columns={meterStatusColumns} data={statusValues} updateList={onViewStatus} />;
};

export default Alerts;
