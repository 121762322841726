import { STO_TYPES } from "pages/stock-management/stockTransferOrders/constants";
import { UNASSIGN_CREATE_TYPE } from "pages/stock-management/trips/editTrip/constants";
import { TRIP_STATUSES } from "pages/stock-management/trips/constants";
import { groupBy } from "lodash";

export const getPreparedData = (status, additionalData) => {
  const asset = additionalData?.allAssets[0];
  const newItem = additionalData?.isNew
    ? {
        depot:
          additionalData?.newValues?.type === STO_TYPES.OUTBOUND
            ? additionalData?.newValues?.fromDepot
            : additionalData?.newValues?.toDepot,
        address: " ",
        stoType: additionalData?.newValues?.type,
        group: asset?.group,
        condition: asset?.condition,
        quantity: asset?.quantity,
        compensatoryItems: [
          {
            depot:
              additionalData?.newValues?.type === STO_TYPES.INBOUND
                ? additionalData?.newValues?.fromDepot
                : additionalData?.newValues?.toDepot,
            address: " ",
          },
        ],
      }
    : {};

  switch (status) {
    case TRIP_STATUSES.PENDING:
    case TRIP_STATUSES.PLANNED:
    case TRIP_STATUSES.IN_TRANSIT:
      if (additionalData?.isNew && (status === TRIP_STATUSES.PLANNED || status === TRIP_STATUSES.PENDING))
        return newItem;
      if (
        additionalData?.isNew &&
        additionalData?.selectedType === STO_TYPES.OUTBOUND &&
        status === TRIP_STATUSES.IN_TRANSIT
      )
        return newItem;
      let compensatoryItems = [];
      const grouped = groupBy(additionalData?.inTransitState?.compensatoryItems, "depot");
      const updatedList = Object.keys(grouped).map(item => {
        const itemSum = grouped[item].reduce((a, i) => a + i.quantity, 0);
        return {
          ...grouped[item][0],
          quantity: itemSum,
        };
      });
      const sum = updatedList?.reduce((a, i) => a + i.quantity, 0);

      if (additionalData?.selectedType === STO_TYPES.OUTBOUND && status === TRIP_STATUSES.IN_TRANSIT) {
        compensatoryItems = updatedList?.map(item => {
          const foundStop = additionalData?.trip?.stops.find(
            i => i.status !== "Passed" && i.location.depot === item.depot && i.orderedInAssets.length
          );

          const foundAssetCount =
            foundStop?.orderedInAssets?.find(
              asset =>
                asset.condition === additionalData.editedItem.condition &&
                asset.group === additionalData.editedItem.group
            )?.quantity || 0;

          return foundStop && !item.isNew
            ? {
                ...item,
                quantity: additionalData?.increaseState
                  ? item.quantity + foundAssetCount
                  : foundAssetCount - item.quantity,
              }
            : item;
        });
      }
      if (
        additionalData?.selectedType === STO_TYPES.INBOUND &&
        status === TRIP_STATUSES.IN_TRANSIT &&
        !additionalData?.isNew
      ) {
        compensatoryItems = updatedList?.map(item => {
          const foundStop = additionalData?.trip?.stops.find(
            i => i.status !== "Passed" && i.location.depot === item.depot && i.orderedInAssets.length
          );
          const foundAssetCount =
            foundStop?.orderedInAssets?.find(
              asset =>
                asset.condition === additionalData.editedItem.condition &&
                asset.group === additionalData.editedItem.group
            )?.quantity || 0;

          return foundAssetCount > 0
            ? {
                ...item,
                quantity: additionalData?.increaseState
                  ? foundAssetCount - item.quantity
                  : item.quantity + foundAssetCount,
              }
            : item;
        });
      }
      if (
        additionalData?.selectedType === STO_TYPES.INBOUND &&
        status === TRIP_STATUSES.IN_TRANSIT &&
        additionalData?.isNew
      ) {
        compensatoryItems = updatedList
          .filter((item, i) => i > 0)
          .map(item => {
            const foundStop = additionalData?.trip?.stops.find(
              i => i.status !== "Passed" && i.location.depot === item.depot && i.orderedInAssets.length
            );
            const foundAssetCount =
              foundStop?.orderedInAssets?.find(
                asset =>
                  asset?.condition === additionalData?.allAssets[0]?.condition &&
                  asset?.group === additionalData?.allAssets[0]?.group
              )?.quantity || 0;

            return foundAssetCount > 0
              ? {
                  ...item,
                  quantity: foundAssetCount - item.quantity,
                }
              : item;
          });
        const firstItem = additionalData?.inTransitState?.compensatoryItems[0];
        return {
          depot: firstItem?.depot,
          address: " ",
          stoType: additionalData?.selectedType,
          group: additionalData?.allAssets[0]?.group,
          condition: additionalData?.allAssets[0]?.condition,
          quantity: firstItem?.quantity,
          compensatoryItems,
        };
      }

      if (
        additionalData?.createType === UNASSIGN_CREATE_TYPE.OUTBOUND_DEDUCTION &&
        status === TRIP_STATUSES.IN_TRANSIT
      ) {
        return {
          stopId: additionalData.stop.id,
          stoItemId: additionalData.editedItem.id,
          isStoItemUnassigned: true,
          compensatoryItems,
        };
      }
      if (additionalData?.createType === UNASSIGN_CREATE_TYPE.RECREATE) {
        return {
          stopId: additionalData.stop.id,
          stoItemId: additionalData.editedItem.id,
          isStoItemUnassigned: true,
          compensatoryItems: [
            {
              depot: additionalData.newValues.toDepot,
              address: " ",
              quantity: additionalData.editedItem.quantity,
              isNew: true,
            },
          ],
        };
      }
      const inTransitQty = additionalData?.increaseState
        ? sum + additionalData?.initItem?.quantity
        : additionalData?.initItem?.quantity - sum;

      return {
        stopId: additionalData.stop.id,
        stoItemId: additionalData.editedItem.id,
        ...(status === TRIP_STATUSES.IN_TRANSIT
          ? {
              quantity: inTransitQty,
              ...(inTransitQty === 0 && {
                isStoItemUnassigned: true,
              }),
              compensatoryItems,
            }
          : {
              quantity: additionalData.editedItem.quantity,
              ...(additionalData.editedItem.quantity === 0 && {
                isStoItemUnassigned: true,
              }),
            }),
      };

    default:
      return {};
  }
};

export const isIncrease = (v1, v2) => v2 > v1;
