import { statusFormatter, textFormatter } from "components/Common/formatter";
import React from "react";
import CustomLink from "components/Common/buttons/CustomLink";
import NGITooltip from "components/Common/utils/NGITooltip";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const hideRoles = [
  USER_ROLES.TSS,
  USER_ROLES.A_SM,
  USER_ROLES.PRODUCT_TECHNICIANS,
  USER_ROLES.IT_OPERATIONS,
  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
];

export const packageColumns = () => [
  {
    title: "Package",
    dataIndex: "name",
    render: (cellContent, row) =>
      userHasOneOfRoles(hideRoles) ? (
        cellContent
      ) : (
        <NGITooltip title={`${cellContent} details`}>
          <div>
            <CustomLink to={`/packages/${row.code}`}>{cellContent}</CustomLink>
          </div>
        </NGITooltip>
      ),
    key: "name",
  },
  {
    title: "Code",
    dataIndex: "code",
    render: item => textFormatter(item),
    key: "code",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: item => statusFormatter(item),
    key: "status",
  },
  {
    title: "Type",
    dataIndex: "type",
    render: item => textFormatter(item),
    key: "type",
  },
];
