export const CANCEL_STATUSES = { INCOMPLETABLE: "Incompletable", ASSIGNED: "Assigned" };

export const taskSourceFormatter = task => {
  switch (task?.source) {
    case "App":
      return "TR";
    case "ngi-dashboard":
      return "ND";
    case "Odoo":
      return task?.extraData?.isCallCenterIssue ? "CC" : "TL";
    case "task-list":
      return "TL";
    case "ngi-platform":
      return "BE";
    default:
      return task?.source || "";
  }
};
