import React, { useCallback, useState } from "react";
import Title from "antd/lib/typography/Title";
import { Col, Form, Space, Spin } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { sendPaymentManually } from "utils/api";
import { errorMessage, successMessage } from "components/Common/responses/message";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGIPrompt from "components/Common/NGIPrompt";

const OPTIONS = [
  {
    value: "add",
    label: "Add",
  },
  {
    value: "subtract",
    label: "Subtract",
  },
];

const SendADMOSection = ({ customerId }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const handleSubmit = useCallback(() => {
    closeConfirmationModal();
    setLoading(true);
    const { operationType, amount } = form.getFieldsValue();
    sendPaymentManually({
      customerId,
      amount: operationType === "add" ? parseFloat(amount) : -parseFloat(amount),
    })
      .then(() => {
        form.setFieldsValue({
          amount: null,
        });
        successMessage("ADMO command was sent successfully");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        errorMessage("Command is failed. Please try again.");
      });
  }, [customerId, form]);

  const closeConfirmationModal = () => setIsConfirmationVisible(false);
  const openConfirmationModal = () => setIsConfirmationVisible(true);

  const onFieldsChanged = useCallback(() => setIsDirty(true), []);

  return (
    <div>
      <NGIPrompt show={isDirty} />
      <Spin spinning={isLoading}>
        <NGIForm name="sendADMOForCustomer" form={form} onFinish={openConfirmationModal} onChange={onFieldsChanged}>
          <Col span={12}>
            <Title level={5}>Amount operation:</Title>
            <NGIFormItem initialValue={"add"} name="operationType">
              <NGISelect style={{ width: "100%" }} onChange={onFieldsChanged}>
                {OPTIONS.map(item => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </NGISelect>
            </NGIFormItem>
          </Col>
          <Col span={12}>
            <Title level={5}>Amount</Title>
            <NGIFormItem name="amount" rules={[{ required: true, message: "Please enter value" }]}>
              <NGIInput type={"number"} min={1} max={3000} step=".01" />
            </NGIFormItem>
          </Col>
          <Space>
            <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
          </Space>
        </NGIForm>
      </Spin>
      {isConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeConfirmationModal}
          onConfirm={handleSubmit}
          description="Are you sure you want to send ADMO command to the meter?"
        />
      )}
    </div>
  );
};

export default SendADMOSection;
