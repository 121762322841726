import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "antd";
import NGITable from "components/Common/NGITable";
import { historyListColumns } from "pages/meter-management/ready-for-repair/constants";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import MarkAsFaultyReportModal from "pages/meter-management/ready-for-repair/MarkAsFaultyReportModal";
import { getEditableItems } from "pages/meter-management/ready-for-repair/RepairMeter/utils";

const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  dateContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
    fontWeight: 500,
  },
  dateRange: {
    marginLeft: 10,
    padding: 15,
    background: "#F5F5F5",
    borderRadius: 30,
    overflow: "hidden",
  },
});

const dateFormat = "YYYY-MM-DD";
const History = ({ reports, isLoading, updateReportList, meterInfo, updateMeterList }) => {
  const [reportId, setReportId] = useState(null);
  const classes = useStyles();

  const openFaultyModal = useCallback(id => setReportId(id), []);

  const closeFaultyModal = useCallback(() => setReportId(null), []);

  const onDateRangeChange = useCallback(value => {
    if (value) {
      updateReportList({
        timeStart: getStartOfTheDay(value[0]),
        timeEnd: getEndOfTheDay(value[1]),
      });
    } else {
      updateReportList();
    }
  }, []);

  return (
    <div>
      <div className={classes.dateContainer}>
        Date Range
        <RangePicker
          inputReadOnly={true}
          className={classes.dateRange}
          onChange={onDateRangeChange}
          format={dateFormat}
          size={"default"}
        />
      </div>
      <NGITable
        isLoading={isLoading}
        columns={historyListColumns(openFaultyModal)}
        data={getEditableItems(reports, meterInfo)}
        updateList={updateReportList}
      />
      {!!reportId && (
        <MarkAsFaultyReportModal
          isLoading={isLoading}
          activeItem={reportId}
          updateReportsList={updateReportList}
          updateMeterList={updateMeterList}
          onClose={closeFaultyModal}
        />
      )}
    </div>
  );
};

export default History;
