import { booleanStatusFormatter, customDateFormatWithoutTime, textFormatter } from "components/Common/formatter";
import React from "react";
import NGITooltip from "components/Common/utils/NGITooltip";
import CustomLink from "components/Common/buttons/CustomLink";

export const versionListColumns = () => [
  {
    title: "Version",
    dataIndex: "version",
    render: cellContent => (
      <NGITooltip title="Version details">
        <CustomLink to={`/settings/contract-version/${cellContent}`}>{cellContent}</CustomLink>
      </NGITooltip>
    ),
  },
  {
    title: "Status",
    dataIndex: "isActive",
    render: booleanStatusFormatter,
  },
  {
    title: "Activation Date",
    dataIndex: "activeFrom",
    render: textFormatter,
  },
  {
    title: "Creation Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithoutTime(cellContent)),
  },
];
