import { USER_ROLES } from "utils/constants";

export const BREADCRUMBS = [
  {
    name: "Meters",
  },
  {
    name: "REGISTRATION",
  },
  {
    name: "Add meter manually",
    roles: [
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
      USER_ROLES.METER_ENGINEER_LEADERSHIP,
      USER_ROLES.METER_ENGINEER,
    ],
  },
];

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const MAC_ADDRESS_VALIDATION_RULES = [
  {
    pattern: new RegExp(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/),
    message: "Please match the requested format. For example, B8:D6:1A:84:03:4C",
  },
];

export const ACCESSORY_TYPES = {
  SENSOR: "Sensor",
  MICROCONTROLLER: "Microcontroller",
};
