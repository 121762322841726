import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "reactstrap";
import NewFirmwareModal from "./NewFirmwareModal";
import { useSelector, useDispatch } from "react-redux";
import { FirmwaresSettingsSelector } from "store/meter-management/configuration/firmware/selectors";
import { firmwareTableColumns } from "./variables";
import { deleteFirmware, getFirmwares } from "store/meter-management/configuration/firmware/actions";
import moment from "moment";
import NGITable from "components/Common/NGITable";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import editIcon from "assets/images/svg/Edit.svg";
import deleteIcon from "assets/images/svg/Delete.svg";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";
import UpdateFileTypeModal from "pages/meter-management/configuration/firmware/UpdateFileTypeModal";
import addIcon from "assets/images/svg/plus-circle-white.svg";
import { FileSyncOutlined } from "@ant-design/icons";

const hideRoles = [
  USER_ROLES.INTERNAL_AUDITOR,
  USER_ROLES.PRODUCT_TECHNICIANS,
  USER_ROLES.GENERAL,
  USER_ROLES.IT_OPERATIONS,
  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
  USER_ROLES.FINANCE,
];
const Firmware = () => {
  const columns = useMemo(() => {
    const col = [
      ...firmwareTableColumns,
      {
        title: "",
        key: "action",
        render: (text, record) => (
          <ButtonGroupContainer>
            <ButtonGroup
              hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.FINANCE, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
              tooltipTitle="Edit"
              icon={editIcon}
              handleClick={() => onUpdate(record)}
            />
            <ButtonGroup
              hideRoles={[
                USER_ROLES.IT_OPERATIONS,
                USER_ROLES.FINANCE,
                USER_ROLES.METER_ENGINEER,
                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              ]}
              tooltipTitle="Delete"
              icon={deleteIcon}
              handleClick={() => onDelete(record.version)}
            />
          </ButtonGroupContainer>
        ),
      },
    ];
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [hideRoles, AUTH_USER]);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFileTypeModalVisible, setIsFileTypeModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);

  const openUpdateFileTypeModal = useCallback(() => setIsFileTypeModalVisible(true), []);
  const closeUpdateFileTypeModal = useCallback(() => setIsFileTypeModalVisible(false), []);

  const { firmwares, firmwaresLoading } = useSelector(FirmwaresSettingsSelector);

  useEffect(() => {
    dispatch(getFirmwares());
  }, []);

  const dataSource = useMemo(() => firmwares?.map((item, index) => ({ ...item, key: index })), [firmwares]);
  const onCreate = useCallback(() => {
    setSelectedItem(null);
    openModal();
  }, []);
  const onUpdate = useCallback(item => {
    setSelectedItem({ ...item, releaseAt: moment(item?.releaseAt) });
    openModal();
  }, []);
  const onDelete = useCallback(version => {
    setFirmwareToDelete(version);
    openDeleteModal();
  }, []);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [firmwareToDelete, setFirmwareToDelete] = useState(null);
  const closeDeleteModal = () => setIsDeleteModalVisible(false);
  const openDeleteModal = () => setIsDeleteModalVisible(true);

  const onConfirmDelete = useCallback(() => {
    closeDeleteModal();
    dispatch(deleteFirmware(firmwareToDelete));
  }, [firmwareToDelete]);

  const data = useMemo(() => ({ result: { items: dataSource } }), [dataSource]);

  return (
    <>
      <div className="page-content" data-testid="firmwareComponent">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <ButtonGroupContainer color={"primary"}>
                <ButtonGroup
                  color={"primary"}
                  hideRoles={hideRoles}
                  handleClick={onCreate}
                  tooltipTitle="New Firmware"
                  icon={addIcon}
                />
                <ButtonGroup color={"primary"} handleClick={openUpdateFileTypeModal} tooltipTitle="Update File Type">
                  <FileSyncOutlined />
                </ButtonGroup>
              </ButtonGroupContainer>
            </div>
          </div>
          <NGITable columns={columns} data={data} isLoading={firmwaresLoading} />
        </Container>
      </div>
      {isModalVisible && (
        <NewFirmwareModal onClose={closeModal} isVisible={isModalVisible} initialValues={selectedItem} />
      )}
      {isFileTypeModalVisible && (
        <UpdateFileTypeModal visible={isFileTypeModalVisible} onClose={closeUpdateFileTypeModal} />
      )}
      {isDeleteModalVisible && (
        <ConfirmationModal
          onCancel={closeDeleteModal}
          onConfirm={onConfirmDelete}
          description={`Are you sure you want to delete Firmware Version: ${firmwareToDelete}?`}
        />
      )}
    </>
  );
};

export default Firmware;
