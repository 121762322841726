import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { textFormatter } from "components/Common/formatter";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { Collapse } from "antd";
const { Panel } = Collapse;

const useStyles = makeStyles({
  root: {},
  column: {
    width: "50%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "40%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "60%",
    textAlign: "end",
  },
  id: {
    width: "60%",
  },
  withId: {
    width: "20%!important",
  },
  containerWrapper: {
    display: "flex",

    "&>:first-child": {
      paddingRight: 20,
    },
  },
  locationIcon: {
    width: 8,
    marginRight: 4,
    marginTop: -2,
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  img: {
    marginRight: 5,
    marginBottom: 5,
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  rowImgs: {},
  images: {
    display: "flex",
    flexWrap: "wrap",
  },
  link: {
    color: "rgba(0, 133, 255, 1) !important",

    "&:hover": {
      textDecoration: "underline!important",
    },
  },
  collapseList: {
    backgroundColor: "#FFF",
  },
});

const SurveyDetailsTab = ({ survey }) => {
  const classes = useStyles();

  const renderItem = useCallback(item => {
    switch (item.type) {
      case "Select Single":
      case "Select Multiple":
        return (
          <ol>
            {item?.options?.map(option => (
              <li key={option.id}>{option.text}</li>
            ))}
          </ol>
        );
      case "Instruction":
        return (
          <ul>
            {item?.items?.map(option => (
              <li key={option.id}>{option.text}</li>
            ))}
          </ul>
        );
      default:
        return item.type;
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.containerWrapper}>
        <div className={classes.column}>
          <div className={classes.rowData}>
            <div className={classes.title}>Name</div>
            <div className={classes.data}>{textFormatter(survey?.name)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Depot</div>
            <div className={classes.data}>{textFormatter(survey?.depot)}</div>
          </div>
        </div>
      </div>

      <TitleWithDivider title="Survey Questions" />

      <Collapse className={classes.collapseList}>
        {survey?.items?.map(item => (
          <Panel header={item.type === "Instruction" ? item.title : item.question} key={item.id}>
            {renderItem(item)}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default SurveyDetailsTab;
