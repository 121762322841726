/*eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";

const NonAuthLayout = ({ children }) =>
  // const capitalizeFirstLetter = useCallback(string => {
  //   return string.charAt(1).toUpperCase() + string.slice(2);
  // }, []);

  children;
NonAuthLayout.propTypes = {
  children: PropTypes.any,
};

export default NonAuthLayout;
