import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { createBulkSMS, retrieveSMSProvidersAPI, uploadBulkSMSCsv } from "utils/api";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGITextArea from "components/Common/inputs/NGITextArea";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGIInput from "components/Common/inputs/NGIInput";
import { VALIDATION_RULES } from "pages/SMS-management/SMS-bulk/NewBulkSMS/constants";
import NGITimePicker from "components/Common/inputs/NGITimePicker";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { getSendAt } from "pages/SMS-management/SMS-bulk/utils";
import { isEmpty } from "lodash";
import { getTrimmedObjectValues } from "utils/helpers/functions";
import moment from "moment";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col + .ant-col": {
      paddingLeft: 10,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  addNewAsset: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
  select: {
    width: "100%",
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});

const NewBulkSMSModal = ({ onClose, updateList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [date, setDate] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [file, setFile] = useState(null);

  const onSave = useCallback(
    async values => {
      try {
        const { provider, sendAt, time, title, message } = getTrimmedObjectValues(values);
        if (!isEmpty(title?.trim())) {
          setIsLoading(true);
          const {
            data: {
              result: { total, fileName },
            },
          } = await uploadBulkSMSCsv(uploadedData);
          if (!total) {
            errorMessage("Please upload a file with at least 1 phone.");
            setIsLoading(false);
            return;
          }
          const preparedData = {
            title: title?.trim(),
            message,
            sendAt: getSendAt(sendAt, time),
            file: fileName,
            total,
            provider,
          };
          createBulkSMS(preparedData)
            .then(() => {
              successMessage("Bulk SMS Template was created successfully");
              onClose();
              updateList();
              setIsLoading(false);
            })
            .catch(err => {
              setIsLoading(false);
              setProviders(null);
              defaultNgiErrorMethod(err);
            });
        }
      } catch (e) {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      }
    },
    [uploadedData]
  );

  const onSubmit = useCallback(() => {
    form.submit();
    closeConfirmationModal();
  }, [form]);

  const readUploadFile = useCallback(e => {
    const file = e.file;
    setFile(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        const csv = xlsx.utils.sheet_to_csv(worksheet, { raw: false });

        const formattedResult = json.map(row => ({ ...row }));
        const noError = formattedResult.every(item => item || null);
        !noError && errorMessage("Please upload the correct ‘*.csv’ file.");
        if (noError) {
          setUploadedData(csv);
        } else {
          setUploadedData("");
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const openConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(false);
  }, []);
  const removeFile = useCallback(() => {
    setFile(null);
    setUploadedData([]);
  }, []);

  const handleChangeDate = useCallback(value => {
    form.resetFields(["time"]);
    setDate(value);
  }, []);

  const getProviders = useCallback(async () => {
    setIsLoading(true);
    await retrieveSMSProvidersAPI()
      .then(res => {
        const {
          data: {
            result: { availableProviders },
          },
        } = res;
        setProviders(availableProviders);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setProviders(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  useEffect(() => {
    getProviders();
  }, []);

  return (
    <Modal
      title={
        <div className={classes.infoContainer}>
          Create a new Bulk SMS
          <span>Please fill in all mandatory fields</span>
        </div>
      }
      className={classes.modal}
      open
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm name="newBulkSMS" form={form} onFinish={onSave}>
          <Row className={classes.formItem}>
            <Col span={24}>
              <Title level={5}>SMS Title</Title>
              <NGIFormItem name="title" rules={VALIDATION_RULES}>
                <NGIInput maxLength={50} />
              </NGIFormItem>
            </Col>
          </Row>

          <Row className={classes.formItem}>
            <Col span={24}>
              <Title level={5}>SMS Message</Title>
              <NGIFormItem name="message" rules={VALIDATION_RULES}>
                <NGITextArea maxLength={200} rows={4} placeholder="Add Additional Comments" />
              </NGIFormItem>
            </Col>
          </Row>

          <Row className={classes.formItem}>
            <Col span={6}>
              <Title level={5}>Sent Date</Title>
              <NGIFormItem name="sendAt" rules={VALIDATION_RULES}>
                <NGIDatePicker onChange={handleChangeDate} disabledPasDates allowClear />
              </NGIFormItem>
            </Col>
            <Col span={6}>
              <Title level={5}>Sent Time</Title>
              <NGIFormItem name="time" rules={VALIDATION_RULES}>
                <NGITimePicker
                  isOneDay={moment().format("DD.MM.YYYY") === moment(date).format("DD.MM.YYYY")}
                  startTime={date}
                  minuteStep={60}
                  showSecond={false}
                />
              </NGIFormItem>
            </Col>
            <Col span={12}>
              <Title level={5}>SMS Provider</Title>
              <NGIFormItem name="provider">
                <NGISelect showSearch className={classes.select} placeholder="Select value">
                  {providers?.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Title level={5}>List of Users *.csv</Title>
              {file ? (
                <div className={classes.fileContainer}>
                  <div className={classes.fileTitle}>{`${file.name} (${uploadedData.length})`}</div>
                  <div onClick={removeFile} className={classes.removeBtn}>
                    <img src={closeIcon} alt="remove" />
                  </div>
                </div>
              ) : (
                <>
                  <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                    <img className="upload-bg" src={uploadArea} alt="upload" />
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single upload. Strictly prohibit from uploading company data or other band files
                    </p>
                  </Dragger>
                </>
              )}
            </Col>
          </Row>

          <Divider />
          <br />
          <Space>
            <CustomButton color="outlined" onClick={onClose} text="Cancel" size="small" />
            <CustomButton type="primary" htmlType="submit" onClick={openConfirmationModal} text="Create" size="small" />
          </Space>
        </NGIForm>
      </Spin>
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to create a new bulk sms template?"
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          loading={isLoading}
        />
      )}
    </Modal>
  );
};

export default NewBulkSMSModal;
