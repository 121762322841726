import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { fetchCustomerList } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { repossessedListColumns } from "pages/customer-management/repossessedCustomers/repossessedListColumns";
import NGITable from "components/Common/NGITable";
import { useDispatch } from "react-redux";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { generateXLS } from "utils/helpers/functions";
import { getTSRList } from "store/TSR/actions";
import RepossessedSearch from "pages/customer-management/repossessedCustomers/RepossessedSearch";

const useStyles = makeStyles({
  totalItem: {
    textAlign: "right",
    top: -43,
    position: "relative",
    zIndex: 1,
  },
});

const RepossessedCustomers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const { filters, setFilters, getFilters } = useStoreFilters(FILTER_PAGE.REPOSSESSED_CUSTOMERS);

  const hideRoles = [
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.D_SM,
    USER_ROLES.A_SM,
    USER_ROLES.OPERATION_MANAGERS,
    USER_ROLES.SALES_SUPPORT,
    USER_ROLES.GENERAL,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
    USER_ROLES.FINANCE,
    USER_ROLES.METER_ENGINEER,
    USER_ROLES.METER_ENGINEER_LEADERSHIP,
  ];

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getTSRList());
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: {
        items,
        meta: { totalCount },
      },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Repossessed customers were not found");
    }
    setTotalCustomers(totalCount || 0);
    setCustomerList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    setFilters(filterOptions);

    fetchCustomerList({
      ...filterOptions,
      status: "Repossessed",
    })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setCustomerList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => repossessedListColumns(), []);

  const onDownload = useCallback(() => {
    const filters = getFilters();
    fetchCustomerList({
      ...filters,
      pageSize: totalCustomers,
      status: "Repossessed",
    })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        generateXLS(items, "Repossessed customers", "csv");
      })
      .catch(e => defaultNgiErrorMethod(e));
  }, [totalCustomers]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                hideRoles={hideRoles}
                icon={<i className="bx bx-download" />}
                type="primary"
                disabled={!totalCustomers}
                text="Download"
                onClick={onDownload}
              />
            </div>

            <RepossessedSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <>
              <NGITable
                filtersType={FILTER_PAGE.REPOSSESSED_CUSTOMERS}
                isLoading={isLoading}
                columns={columns}
                data={customerList}
                updateList={handleSearch}
              />
              {!!totalCustomers && totalCustomers > 0 && (
                <div className={classes.totalItem}>Total number of customers = {totalCustomers}</div>
              )}
            </>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default RepossessedCustomers;
