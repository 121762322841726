import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TimePicker } from "antd";
import moment from "moment";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    width: "100%",
    background: "#F5F5F5!important",
    border: "none!important",
    padding: 14,
    borderRadius: 30,

    "&>input": {
      background: "#F5F5F5!important",
    },
    "&input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  white: {
    background: "#FFF!important",
  },
});

const DEFAULT_DATEFORMAT = "HH:mm";

const NGITimePicker = ({
  disableDates,
  dateFormat = DEFAULT_DATEFORMAT,
  allowClear = false,
  isOneDay = false,
  startTime = moment(),
  white = false,
  ...props
}) => {
  const classes = useStyles();

  const disabledHours = useCallback(() => {
    const hours = [];
    const currentHour = isOneDay ? moment(startTime).hour() : moment().hour();
    const startHour = isOneDay ? currentHour + 1 : 0;
    for (let i = 0; i <= 24; i++) {
      if (i < startHour || i > 24) {
        hours.push(i);
      }
    }

    return hours;
  }, [isOneDay, startTime]);

  const disabledMinutes = useCallback(selectedHour => {
    const minutes = [];
    const currentMinute = moment().minute();
    for (let i = 0; i <= 60; i++) {
      if ((selectedHour >= 19 && i >= 30) || (selectedHour === moment().hour() && currentMinute < 30 && i < 30)) {
        minutes.push(i);
      }
    }
    return minutes;
  }, []);

  return (
    <TimePicker
      {...props}
      inputReadOnly={true}
      allowClear={allowClear}
      className={classnames(classes.root, white && classes.white)}
      hideDisabledOptions
      disabledTime={() => ({ disabledHours, disabledMinutes })}
      format={dateFormat}
    />
  );
};
export default NGITimePicker;
