import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal, Space, Spin, Tabs } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { getMaintenanceReportsAPI, retrieveMeterDetails, retrieveMeterReports, saveMeterRepairReport } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import MeterDetails from "pages/meter-management/ready-for-repair/RepairMeter/Tabs/MeterDetails";
import FaultyReasons from "pages/meter-management/ready-for-repair/RepairMeter/Tabs/FaultyReasons";
import { REPAIR_RESOLUTION, REPAIR_RESOLUTION_OPTIONS } from "pages/meter-management/ready-for-repair/constants";
import History from "pages/meter-management/ready-for-repair/RepairMeter/Tabs/History";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";
import NGIForm from "components/Common/form/NGIForm";
import { getTrimmedObjectValues } from "utils/helpers/functions";
import { getFormErrors } from "pages/operations/leadDetails/utils";
import SpareParts from "pages/meter-management/ready-for-repair/RepairMeter/SpareParts";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    marginBottom: 24,
  },
  mandatory: {
    fontSize: 9,
  },
  tabs: {
    "&.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav": {
      padding: "0px!important",
      margin: 0,
    },
  },
});

const RepairMeterModal = ({ onClose, isOpen, activeItem, onUpdate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { serial, id } = activeItem;
  const [isLoading, setIsLoading] = useState(false);
  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const [extendedData, setExtendedData] = useState(null);
  const [repairReport, setRepairReport] = useState({});
  const [reports, setReports] = useState([]);
  const [faultyReasons, setFaultyReasons] = useState([]);
  const [spareParts, setSpareParts] = useState([]);
  const [resolution, setResolution] = useState(REPAIR_RESOLUTION.REPAIRED);
  const [activeTab, setActiveTab] = useState("");

  const handleChangeResolution = useCallback(value => setResolution(value), []);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_FAULTY_REASONS_V2));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_REPAIR_INPUT_FIELDS));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    retrieveMeterReports({ meterId: id }).then(({ data }) => {
      const repairData = data.result.items.find(
        item => item?.type === "Testing" && item?.testingData?.resolution === "Repair"
      );
      setRepairReport(repairData);
    });
    handleRetrieveMeterReports();
    retrieveMeterDetails(activeItem.id)
      .then(res => {
        const {
          data: { result },
        } = res;
        setExtendedData({ ...activeItem, ...result });
        setIsLoading(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setIsLoading(false);
      });
  }, [activeItem]);

  const handleRetrieveMeterReports = useCallback(
    (filters = {}) => {
      setIsReportsLoading(true);
      getMaintenanceReportsAPI({
        ...filters,
        meterId: id,
      }).then(({ data }) => {
        setIsReportsLoading(false);
        setReports(data);
      });
    },
    [id]
  );

  const handleSave = useCallback(async () => {
    const { comment, pcb, sensorSerial, sensorType, removableIccid } = form.getFieldsValue();
    const formHasErrors = getFormErrors(form, ["pcb", "sensorSerial", "removableIccid"]);
    if (faultyReasons?.length && formHasErrors === 0) {
      setIsLoading(true);
      const params = {
        faultyReasons,
        pcb,
        sensorSerial,
        sensorType,
        removableIccid,
        resolution,
        comment,
      };
      if (!!spareParts?.length) params.spareParts = spareParts;
      saveMeterRepairReport({
        id,
        params: getTrimmedObjectValues(params),
      })
        .then(() => {
          onClose();
          onUpdate();
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    }
  }, [form, id, resolution, faultyReasons, spareParts]);

  const tabs = useMemo(
    () => [
      {
        label: <div className={classes.titleWithDescription}>Meter Details</div>,
        key: "meterDetails",
        children: <MeterDetails reportData={repairReport} meterInfo={extendedData} form={form} />,
      },
      {
        label: <div className={classes.titleWithDescription}>Faulty Reasons*</div>,
        key: "faultyReasons",
        children: (
          <>
            <FaultyReasons updateFaultyReason={setFaultyReasons} showCommentSection showRequiredRepairFields />
            {!!faultyReasons?.length && <SpareParts setSelectedSpareParts={setSpareParts} />}
          </>
        ),
      },
      {
        label: <div className={classes.titleWithDescription}>Repair History</div>,
        key: "repairHistory",
        children: (
          <History
            reports={reports}
            updateReportList={handleRetrieveMeterReports}
            updateMeterList={onUpdate}
            meterInfo={extendedData}
            isLoading={isReportsLoading}
          />
        ),
      },
    ],
    [
      extendedData,
      repairReport,
      form,
      reports,
      handleRetrieveMeterReports,
      isReportsLoading,
      setFaultyReasons,
      faultyReasons,
    ]
  );

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Repair Details
            <span>{`Meter (${serial})`}</span>
            <span className={classes.mandatory}>* - mandatory tabs.</span>
          </div>
          <NGISelect
            showSearch
            value={resolution}
            onChange={handleChangeResolution}
            placeholder="Please select resolution"
          >
            {Object.keys(REPAIR_RESOLUTION_OPTIONS).map(item => (
              <Option value={item} key={item}>
                {REPAIR_RESOLUTION_OPTIONS[item]}
              </Option>
            ))}
          </NGISelect>
        </div>
      }
      className={classes.modal}
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <NGIForm name="faulty-reasons" isUpdate={Boolean(extendedData)} onFinish={handleSave} form={form}>
        <Spin spinning={isLoading}>
          <div className={classes.modalContent}>
            <Tabs onChange={setActiveTab} className={classes.tabs} defaultActiveKey="meterData" items={tabs} />
          </div>
        </Spin>
      </NGIForm>
      <Space>
        <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
        {activeTab === "faultyReasons" && (
          <CustomButton onClick={form.submit} type="primary" text="Save" size="small" />
        )}
      </Space>
    </Modal>
  );
};

export default RepairMeterModal;
