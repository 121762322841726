export const VALIDATION_RULES = [
  { required: true, message: "Please enter value" },
  {
    message: "Please enter value",
    validator: (_, value) => {
      if (value && !String(value).trim()) {
        return Promise.reject("Please enter value");
      }
      return Promise.resolve();
    },
  },
];

export const ANSWER_TYPES = ["Select Single", "Select Multiple", "Text", "Instruction"];
