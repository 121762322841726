import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import infoIcon from "assets/images/svg/Info.svg";
import React from "react";
import CustomLink from "components/Common/buttons/CustomLink";

export const TAMRequestsListColumns = (styles, handleDetails) => [
  {
    title: "Receipt ID",
    dataIndex: "transactionId",
    render: textFormatter,
  },
  {
    title: "Customer ID",
    dataIndex: "customerId",
    render: id =>
      id !== "BULK" ? (
        <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
          {id}
        </CustomLink>
      ) : (
        textFormatter(id)
      ),
  },
  {
    title: "Reason",
    dataIndex: "reason",
    render: textFormatter,
  },
  {
    title: "Description",
    dataIndex: "description",
    render: cellContent =>
      cellContent ? (
        <div title={cellContent} className={styles.overflowText}>
          {cellContent}
        </div>
      ) : (
        textFormatter(cellContent)
      ),
  },
  {
    title: "Type",
    dataIndex: "type",
    render: textFormatter,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Creator",
    dataIndex: "createdBy",
    render: cellContent =>
      cellContent ? (
        <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
          <div title={cellContent} className={styles.overflowText}>
            {cellContent}
          </div>
        </CustomLink>
      ) : (
        textFormatter(cellContent)
      ),
  },
  {
    title: "Approver",
    dataIndex: "approvedBy",
    render: cellContent =>
      cellContent ? (
        <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
          <div title={cellContent} className={styles.overflowText}>
            {cellContent}
          </div>
        </CustomLink>
      ) : (
        textFormatter(cellContent)
      ),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroup
        tooltipTitle="View TAM Report"
        icon={infoIcon}
        handleClick={() => {
          handleDetails(row);
        }}
      />
    ),
  },
];
