import React, { useCallback, useEffect, useState } from "react";
import NGITable from "components/Common/NGITable";
import { readAlertsAPI } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { alertColumns } from "pages/meter-management/meters/meterReportColumns";

const Alerts = ({ id }) => {
  const [isLoading, setLoading] = useState(false);
  const [alertValues, setAlertValues] = useState(false);

  const onViewAlerts = useCallback((options = {}) => {
    setLoading(true);
    readAlertsAPI({ meterId: id, ...options })
      .then(res => {
        if (res.data.result.items) {
          setAlertValues(res.data);
        } else {
          setAlertValues([]);
        }
        setLoading(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onViewAlerts();
  }, []);

  return <NGITable isLoading={isLoading} columns={alertColumns} data={alertValues} updateList={onViewAlerts} />;
};

export default Alerts;
