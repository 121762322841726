import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";

import NGIFormItem from "components/Common/form/NGIFormItem";
import { v4 as uuidv4 } from "uuid";
import NGIInput from "components/Common/inputs/NGIInput";
import { ANSWER_TYPES, VALIDATION_RULES } from "pages/settings/survey-management/surveyDetails/NewQuestion/constants";
import NewAnswerRow from "pages/settings/survey-management/surveyDetails/NewQuestion/NewAnswerRow";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col-12 + .ant-col-12": {
      paddingLeft: 10,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  addNewAnswer: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
});

const NewQuestionModal = ({ questionData, onClose, onQuestionSave }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [answers, setAnswers] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  const onSave = useCallback(
    values => {
      const { type, question, title, label } = values;
      onQuestionSave({
        id: questionData ? questionData.id : uuidv4(),
        question,
        title,
        type,
        label,
        [type === "Instruction" ? "items" : "options"]: answers.filter(item => item.text),
      });
      onClose();
    },
    [answers, questionData]
  );

  const onAddAnswer = useCallback(() => setAnswers(prevState => [...prevState, { id: uuidv4() }]), []);
  const onRemoveAnswer = useCallback(e => {
    const { id } = e.currentTarget.dataset;
    setAnswers(prevState => prevState.filter(item => item.id !== id));
  }, []);

  const handleTypeChange = useCallback(value => {
    setSelectedType(value);
    setAnswers([]);
  }, []);

  const onSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const disableSubmit = useMemo(
    () =>
      (selectedType === "Select Single" || selectedType === "Instruction" || selectedType === "Select Multiple") &&
      (!answers.length || !!answers?.find(answer => !answer.text?.trim().length)),
    [selectedType, answers]
  );

  useEffect(() => {
    if (questionData) {
      form.setFieldsValue(questionData);
      setSelectedType(questionData.type);
      setAnswers(questionData.type === "Instruction" ? questionData.items : questionData.options);
    }
  }, []);

  return (
    <Modal
      title={
        <div className={classes.infoContainer}>
          {`${questionData ? "Edit" : "Create"} Question`}
          <span>{`${questionData ? "Edit" : "Create"} a question for the survey`}</span>
        </div>
      }
      className={classes.modal}
      open
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <NGIForm name="newQuestion" form={form} onFinish={onSave}>
        <Row className={classes.formItem}>
          {selectedType === "Instruction" ? (
            <Col span={24}>
              <Title level={5}>Title</Title>
              <NGIFormItem name="title" rules={VALIDATION_RULES}>
                <NGIInput />
              </NGIFormItem>
            </Col>
          ) : (
            <Col span={24}>
              <Title level={5}>Question</Title>
              <NGIFormItem name="question" rules={VALIDATION_RULES}>
                <NGIInput />
              </NGIFormItem>
            </Col>
          )}
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Order Type</Title>
            <NGIFormItem name="type" rules={VALIDATION_RULES}>
              <NGISelect showSearch value={selectedType} onChange={handleTypeChange} placeholder="Select value">
                {ANSWER_TYPES.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </NGISelect>
            </NGIFormItem>
          </Col>
          {selectedType !== "Instruction" && (
            <Col span={12}>
              <Title level={5}>Question Label</Title>
              <NGIFormItem name="label" rules={VALIDATION_RULES}>
                <NGIInput />
              </NGIFormItem>
            </Col>
          )}
        </Row>
        {(selectedType === "Select Single" || selectedType === "Select Multiple" || selectedType === "Instruction") && (
          <>
            <TitleWithDivider title={selectedType === "Instruction" ? "Instruction Items" : "Question Answers"} />
            <div className={classes.assetsContainer}>
              {answers.map((item, i) => (
                <NewAnswerRow
                  key={item.id}
                  index={i}
                  setAnswers={setAnswers}
                  onRemoveAnswer={onRemoveAnswer}
                  item={item}
                />
              ))}

              <div className={classes.addNewAnswer} onClick={onAddAnswer}>
                {selectedType === "Instruction" ? "+Add New Item" : "+Add New Answer"}
              </div>
            </div>
          </>
        )}
      </NGIForm>
      <Divider />
      <br />
      <Space>
        <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Close" />
        <CustomButton type="primary" disabled={disableSubmit} onClick={onSubmit} text="Save Changes" size="small" />
      </Space>
    </Modal>
  );
};

export default NewQuestionModal;
