import React, { useCallback, useState } from "react";
import { Col, Form, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import CustomButton from "components/Common/buttons/CustomButton";
import { cylinderInstallAPI } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import { makeStyles } from "@material-ui/core/styles";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { userHasOneOfRoles } from "utils/helpers/functions";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";

const useStyles = makeStyles({
  input: {
    width: 200,
  },
});
const SendCommandTab = ({ data, hideRoles }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  form.setFieldsValue(defaultData);

  const onSave = useCallback(
    saveData => {
      setLoader(true);
      closeConfirmationModal();
      const formattedData = {
        ...saveData,
        gasLevel: saveData.gasLevel ? Number.parseFloat(saveData.gasLevel) : 0,
        cylinderSerial: saveData.cylinderSerial.toString(),
      };
      setLoader(true);
      cylinderInstallAPI({ id: data.id, payload: formattedData })
        .then(() => {
          setLoader(false);
          successMessage(`Data was saved successfully`);
        })
        .catch(err => {
          setLoader(false);
          defaultNgiErrorMethod(err);
        });
    },
    [data]
  );

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  if (userHasOneOfRoles(hideRoles)) return null;

  return (
    <>
      <Spin spinning={isLoading}>
        <NGIForm name="meter-sent-commands" isUpdate form={form} onFinish={onSave} className="sent-commands-page">
          <Col span={24}>
            <Title level={5}>{"Gas remaining:"}</Title>
            <NGIFormItem
              className={classes.input}
              name="gasLevel"
              rules={[
                { required: true, message: "Please enter Gas remaining" },
                {
                  message: "Value should be between 3 and 13",
                  validator: function (_, value) {
                    const numberValue = parseFloat(value);
                    if (numberValue >= 3 && numberValue <= 13) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Value should be between 3 and 13");
                  },
                },
              ]}
            >
              <NGIInput id="gasLevel" type={"number"} min={3} max={13} step=".1" />
            </NGIFormItem>
          </Col>
          <Col span={24}>
            <Title level={5}>{"Cylinder serial:"}</Title>
            <NGIFormItem className={classes.input} name="cylinderSerial">
              <NGIInput
                id="cylinderSerial"
                value={defaultData.cylinderSerial}
                onChange={e => setDefaultData(prevState => ({ ...prevState, cylinderSerial: e.target.value }))}
              />
            </NGIFormItem>
          </Col>
        </NGIForm>
        <Space>
          <CustomButton type="primary" onClick={openConfirmationModal} size="small" text="Save" />
        </Space>
        {isConfirmationOpen && (
          <ConfirmationModal
            loading={isLoading}
            description="Are you sure you want to send the Cylinder Install command to the meter?"
            onCancel={closeConfirmationModal}
            onConfirm={form.submit}
          />
        )}
      </Spin>
    </>
  );
};

export default SendCommandTab;
