import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import calendarIcon from "../../../assets/images/svg/calendarMinus.svg";
import depotIcon from "../../../assets/images/svg/depot.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import { getFormattedDataByName } from "utils/helpers/functions";
import { useLocation } from "react-router";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
    textTransform: "uppercase",
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 110,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  overflowTextWithoutIcon: {
    width: 150,
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
  link: {
    textDecoration: "underline!important",
  },
  noCustomer: {
    textTransform: "uppercase",
    opacity: 0.5,
  },
});

const Header = ({ task, customer }) => {
  const classes = useStyles();
  const { status, depot, createdAt, id: taskId, updatedBy } = task;
  const { firstName, lastName, id } = customer;
  const searchParams = useLocation().search;
  const backUrl = new URLSearchParams(searchParams).get("backUrl");
  const backButtonUrl = useMemo(() => (backUrl ? backUrl : "/warehouse/asset-validation"), [backUrl]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={backButtonUrl}>Back</BackButtonLink>

        <div className={classes.nameContainer}>
          {firstName ? (
            <span>
              {`${firstName} ${lastName} `}
              <CustomLink className={classes.link} to={`/customers/customer/${id}?activeTab=ASSETS`} target={"_blank"}>
                {id}
              </CustomLink>
            </span>
          ) : (
            <span className={classes.noCustomer}>Customer is not found to display</span>
          )}
          {status && <div className={classes.status}>{status}</div>}
        </div>
      </div>

      <div className={classes.rightContainer}>
        <div className={classes.mainInfo}>
          <div className={classes.section}>
            <div>
              <div className={classes.title}>REQUEST ID</div>
              <div
                className={classnames(classes.data, classes.overflowText, classes.overflowTextWithoutIcon)}
                title={taskId}
              >
                {taskId}
              </div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <img src={calendarIcon} alt="Created At" />
            <div>
              <div className={classes.title}>CREATED AT</div>
              <div className={classnames(classes.data, classes.overflowText)}>
                {getFormattedDataByName("createdAt", createdAt)}
              </div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <div>
              <div className={classes.title}>{status === "Pending" ? "Created By" : "Updated By"}</div>
              <div
                className={classnames(classes.data, classes.overflowText, classes.overflowTextWithoutIcon)}
                title={updatedBy}
              >
                {updatedBy}
              </div>
            </div>
          </div>
          <div className={classes.divider} />

          <div className={classes.section}>
            <img src={depotIcon} alt="depot" />
            <div>
              <div className={classes.title}>DEPOT</div>
              <div className={classnames(classes.data, classes.overflowText)} title={depot}>
                {depot}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
