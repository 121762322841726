export const TABS = {
  EDIT_CAMPAIGN: "EDIT_CAMPAIGN",
};

export const getTabButtons = () => [
  {
    title: "Campaign Details",
    key: TABS.EDIT_CAMPAIGN,
  },
];

export const getBreacrumbs = isCreate => [
  {
    name: "Settings",
  },
  {
    name: "Campaign Management",
  },
  {
    name: isCreate ? "Create New Campaign" : "Campaign Details",
  },
];

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];
