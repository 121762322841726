import React, { useCallback, useEffect } from "react";
import { Form, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const DepotsSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const { getFilters, filters } = useStoreFilters(FILTER_PAGE.DEPOT);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
  }, []);

  useEffect(() => {
    if (filters["name"]) {
      form.setFieldsValue({
        type: "name",
        value: filters["name"],
      });
    }
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type" initialValue="name">
          Depot name
        </Form.Item>
        <div className="filter-divider" />
        <Form.Item name="value">
          <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
        </Form.Item>
        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default DepotsSearch;
