import React, { useCallback, useMemo, useState } from "react";
import { getMaintenanceReportsAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { Col, Container } from "reactstrap";
import NGITable from "components/Common/NGITable";
import ReportsFilter from "./reportsFilter";
import { reportsListColumns } from "pages/meter-management/meters/meterDetails/Tabs/historyTab/listColumns";
import MarkAsFaultyReportModal from "pages/meter-management/ready-for-repair/MarkAsFaultyReportModal";
import { getEditableItems } from "pages/meter-management/ready-for-repair/RepairMeter/utils";
import { AUTH_USER, USER_ROLES } from "utils/constants";

const HistoryTab = ({ data }) => {
  const [isLoading, setLoader] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [reportId, setReportId] = useState(null);
  const hideRoles = [USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.METER_ENGINEER];

  const openFaultyModal = useCallback(id => setReportId(id), []);

  const closeFaultyModal = useCallback(() => setReportId(null), []);

  const handleFilter = useCallback(
    (newFilterOptions = { meterId: data?.id }) => {
      setLoader(true);
      getMaintenanceReportsAPI({
        ...newFilterOptions,
        meterId: data?.id,
      })
        .then(async res => {
          const {
            result: { items },
          } = res?.data;
          setLoader(false);
          if (items === 0) {
            warnMessage("No reports were found");
          }
          setReportsList(res?.data || null);
        })
        .catch(err => {
          setLoader(false);
          setReportsList(null);
          defaultNgiErrorMethod(err);
        });
    },
    [filterOptions, data?.id]
  );

  const handleListUpdate = useCallback(() => onFiltering(filterOptions), []);

  const onFiltering = useCallback(options => {
    setFilterOptions(options);
    handleFilter(options);
  }, []);

  const columns = useMemo(() => {
    const col = reportsListColumns(openFaultyModal);
    if (hideRoles?.includes(AUTH_USER?.roles)) col.pop();
    return col;
  }, [openFaultyModal]);

  return (
    <Container fluid>
      <div className="filters-wrapper">
        <ReportsFilter handleFilter={onFiltering} isLoading={isLoading} />
      </div>
      <Col lg={12}>
        <NGITable
          isLoading={isLoading}
          columns={columns}
          data={getEditableItems(reportsList, data)}
          updateList={getEditableItems(reportsList, data)}
        />
      </Col>
      {!!reportId && (
        <MarkAsFaultyReportModal
          isLoading={isLoading}
          activeItem={reportId}
          onClose={closeFaultyModal}
          updateReportsList={handleListUpdate}
        />
      )}
    </Container>
  );
};

export default HistoryTab;
