import React, { useCallback, useEffect, useState } from "react";
import { Form, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { searchBy, searchByList, statuses, types, status, type } from "pages/warehouse/assetRequestHistory/variables";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { getUsersList } from "utils/api";
import TSRSelect from "components/Common/components/TSRSelect";

const { Option } = Select;

const AssetRequestFilter = ({ onFilter, filtersType }) => {
  const { getFilters } = useStoreFilters(filtersType);
  const { setFilters } = useStoreFilters(FILTER_PAGE.FROM_MARKET_REPORTS);
  const { pageSize } = getFilters();
  const [form] = Form.useForm();
  const [activeSearchBy, setActiveSearchBy] = useState(searchBy.type);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getUsersList({
      role: [USER_ROLES.LT, USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS].join(),
      depot: AUTH_USER?.depotName,
      pageSize: 1000,
    }).then(res => {
      setUserList(res?.data?.result?.items);
    });
  }, []);

  useEffect(() => {
    form.setFieldValue("status", status.approved);
    form.setFieldValue("type", type.receive);
    form.submit();
    setFilters({ status: status.approved, type: type.receive });
  }, []);

  const handleSearchBy = useCallback(value => setActiveSearchBy(value), []);
  const onFinish = useCallback(values => onFilter({ ...values, pageSize }), []);

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item name="status" rules={[{ required: true, message: "Please select search type" }]}>
          <Select
            showSearch
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            size={"default"}
            placeholder={"Select status"}
          >
            {statuses.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />

        <Select
          showSearch
          style={{ width: "100%" }}
          tokenSeparators={[","]}
          size={"default"}
          placeholder={"Seaqrch by"}
          value={activeSearchBy}
          onChange={handleSearchBy}
        >
          {searchByList.map(item => (
            <Option key={item.value} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
        {activeSearchBy === searchBy.type ? (
          <Form.Item name="type" rules={[{ required: true, message: "Please select search type" }]}>
            <Select
              showSearch
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              size={"default"}
              placeholder={"Select type"}
              allowClear
            >
              {types.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item name="requestedFor" rules={[{ required: true, message: "Please enter Requested For" }]}>
            <TSRSelect
              showSearch
              roles={[USER_ROLES.LT, USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS]}
              initialList={userList}
              depot={AUTH_USER?.depotName}
            />
          </Form.Item>
        )}
        <CustomButton type="primary" htmlType="submit" text="Search" size="small" />
      </Form>
    </div>
  );
};

export default AssetRequestFilter;
