import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Col, Form, Row, Select } from "antd";
import NGICheckbox from "components/Common/inputs/NGICheckbox";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import AdditionalComments from "pages/meter-management/ready-for-repair/RepairMeter/Tabs/AdditionalComments";
import NGIFormItem from "components/Common/form/NGIFormItem";
import Title from "antd/es/typography/Title";
import NGIInput from "components/Common/inputs/NGIInput";
import NGISelect from "components/Common/inputs/NGISelect";
import { AccessoriesSelector } from "store/meter-management/configuration/accessories/selectors";
import classnames from "classnames";
import { getRequiredFieldsMapping } from "pages/meter-management/faulty-reasons/utils";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "100%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
    marginTop: 5,
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
    marginRight: 16,
  },
  simCardContainer: {
    margin: "10px 0",
  },
  checkboxContainer: {
    width: "100%",
  },
  checkboxRow: {
    marginBottom: 10,
  },
  subItem: {
    marginLeft: 20,
    marginTop: 10,
  },
  formTitle: {
    fontSize: "14px!important",
  },
  formRow: {
    width: "100%!important",
    marginBottom: 20,
  },
  requiredRepairFields: {
    display: "flex",
    alignItems: "flex-start",

    "&>div": {
      width: "40%",
    },
  },
});

const FaultyReasons = ({
  updateFaultyReason,
  className,
  showCommentSection = false,
  showRequiredRepairFields = false,
}) => {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const {
    list: { meterFaultyReasonV2, meterRepairInputFields },
  } = useSelector(DictionariesSelector);
  const { sensors } = useSelector(AccessoriesSelector);

  const onSelectCategory = useCallback(event => {
    const { value, checked } = event.target;
    const hasSub = meterFaultyReasonV2.find(item => item.item === value)?.items?.length;

    setSelectedCategory(prevState => {
      if (checked) {
        return [...prevState, value];
      }
      updateFaultyReason(prevState => prevState.filter(item => item.split(">")[0] !== value));
      setSelectedReasons(prevState => prevState.filter(item => item.split(">")[0] !== value));

      return prevState.filter(item => item !== value);
    });

    if (!hasSub) {
      updateFaultyReason(prevState => {
        if (checked) {
          return [...prevState, value];
        }
        return prevState.filter(item => item !== value);
      });
      setSelectedReasons(prevState => {
        if (checked) {
          return [...prevState, value];
        }
        return prevState.filter(item => item !== value);
      });
    }
  }, []);

  const onSelectSubCategory = useCallback(event => {
    const { value, checked } = event.target;
    updateFaultyReason(prevState => {
      if (checked) {
        return [...prevState, value];
      }
      return prevState.filter(item => item !== value);
    });
    setSelectedReasons(prevState => {
      if (checked) {
        return [...prevState, value];
      }
      return prevState.filter(item => item !== value);
    });
  }, []);

  const requiredFieldsMapping = useMemo(
    () =>
      meterFaultyReasonV2 && meterRepairInputFields
        ? getRequiredFieldsMapping(meterFaultyReasonV2, meterRepairInputFields)
        : {},
    [meterFaultyReasonV2, meterRepairInputFields]
  );

  const getIsFieldRequired = useCallback(
    field => selectedReasons.some(item => requiredFieldsMapping[field].includes(item)) || false,
    [requiredFieldsMapping, selectedReasons]
  );

  return (
    <>
      <div className={classnames(className, showRequiredRepairFields && classes.requiredRepairFields)}>
        <div>
          <Form.Item
            className={classes.checkboxContainer}
            name="faultyReasons"
            rules={[{ required: true, message: "Please select at least one faulty reasons" }]}
          >
            <Checkbox.Group className={classes.checkboxContainer}>
              {meterFaultyReasonV2?.map(item => (
                <div key={item.item} className={classes.checkboxRow}>
                  <NGICheckbox onClick={onSelectCategory} value={item.item}>
                    {item.item}
                  </NGICheckbox>
                  {!!item?.items?.length &&
                    selectedCategory.includes(item.item) &&
                    item?.items.map(subItem => (
                      <div key={`${item.item}>${subItem.item}`} className={classes.subItem}>
                        <NGICheckbox onClick={onSelectSubCategory} value={`${item.item}>${subItem.item}`}>
                          {subItem.item}
                        </NGICheckbox>
                      </div>
                    ))}
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
        {showRequiredRepairFields && (
          <Row className={classes.formRow}>
            {getIsFieldRequired("sensorSerial") && (
              <Col span={24}>
                <Title className={classes.formTitle}>Sensor Serial Number</Title>
                <div className={classes.formItem}>
                  <NGIFormItem
                    name="sensorSerial"
                    rules={[
                      { required: true, message: "Please enter value" },
                      {
                        pattern: /^[A-Z0-9]+$/,
                        message: "Please match the requested format. Only A-Z or 0-9 characters are allowed.",
                      },
                      {
                        max: 30,
                        message: "The max length is 30",
                      },
                    ]}
                  >
                    <NGIInput placeholder="Enter Sensor Serial Number" />
                  </NGIFormItem>
                </div>
              </Col>
            )}
            {getIsFieldRequired("sensorType") && (
              <Col span={24}>
                <Title className={classes.formTitle}>Sensor Type</Title>
                <div className={classes.formItem}>
                  <NGIFormItem name="sensorType" rules={[{ required: true, message: "Please enter value" }]}>
                    <NGISelect showSearch placeholder={"Select Sensor Type"} loading={!sensors.length}>
                      {sensors?.map(item => (
                        <Select.Option key={item.type} value={item.type}>
                          {item.type}
                        </Select.Option>
                      ))}
                    </NGISelect>
                  </NGIFormItem>
                </div>
              </Col>
            )}
            {getIsFieldRequired("pcb") && (
              <Col span={24}>
                <Title className={classes.formTitle}>PCB Serial Number</Title>
                <div className={classes.formItem}>
                  <NGIFormItem
                    name="pcb"
                    rules={[
                      { required: true, message: "Please enter value" },
                      {
                        max: 30,
                        message: "Please change the value. The max length is 30.",
                      },
                    ]}
                  >
                    <NGIInput placeholder="Enter PCB Serial Number" />
                  </NGIFormItem>
                </div>
              </Col>
            )}
            {getIsFieldRequired("removableIccid") && (
              <Col span={24}>
                <Title className={classes.formTitle}>Sim Card ICCID</Title>
                <div className={classes.formItem}>
                  <NGIFormItem
                    name="removableIccid"
                    rules={[
                      { required: true, message: "Please enter value" },
                      {
                        max: 20,
                        message: "Please change the value. The max length is 20.",
                      },
                    ]}
                  >
                    <NGIInput placeholder="Enter Sim Card ICCID" />
                  </NGIFormItem>
                </div>
              </Col>
            )}
          </Row>
        )}
      </div>

      {showCommentSection && <AdditionalComments />}
    </>
  );
};

export default FaultyReasons;
