/* ID_TYPES */

export const GET_FIRMWARES = "GET_FIRMWARES"
export const GET_FIRMWARES_FAIL = "GET_FIRMWARES_FAIL"
export const GET_FIRMWARES_SUCCESS = "GET_FIRMWARES_SUCCESS"

export const CREATE_FIRMWARE = "CREATE_FIRMWARE"
export const CREATE_FIRMWARE_SUCCESS = "CREATE_FIRMWARE_SUCCESS"
export const CREATE_FIRMWARE_FAIL = "CREATE_FIRMWARE_FAIL"

export const UPDATE_FIRMWARE = "UPDATE_FIRMWARE"
export const UPDATE_FIRMWARE_FAIL = "UPDATE_FIRMWARE_FAIL"
export const UPDATE_FIRMWARE_SUCCESS = "UPDATE_FIRMWARE_SUCCESS"

export const DELETE_FIRMWARE = "DELETE_FIRMWARE"
export const DELETE_FIRMWARE_FAIL = "DELETE_FIRMWARE_FAIL"
export const DELETE_FIRMWARE_SUCCESS = "DELETE_FIRMWARE_SUCCESS"