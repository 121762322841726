export const AVAILABLE_STATUSES = ["OpenedProspect", "ArchievedProspect", "AssignedProspect"];

export const HIDE_FIELDS = ["packageHistory", "id", "firstName", "lastName", "middleName"];

export const ACTION_TYPE = {
  VIEW_DETAILS: "VIEW_DETAILS",
  RECREATE_TASK: "RECREATE_TASK",
  OPEN_TASKS: "OPEN_TASKS",
};

export const DEFAULT_PAGINATION_CONFIG = { current: 1, pageSize: 10 };
