import React from "react";
import "react-drawer/lib/react-drawer.css";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import Breadcrumbs from "components/VerticalLayout/Breadcrumbs";

const Header = ({ currentPage }) => (
  <header id="page-topbar">
    <div className="navbar-header">
      <div className="d-flex">
        <Breadcrumbs currentPage={currentPage} />
      </div>
      <div className="d-flex">
        <div className="dropdown d-inline-block d-lg-none ms-2"></div>

        <ProfileMenu />
      </div>
    </div>
  </header>
);

export default Header;
