import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { APPROVE_DECLINE_STATUSES, TABS, TABS_BUTTONS } from "pages/operations/leadDetails/constants";
import PersonalInformationTab from "pages/operations/leadDetails/Tabs/PersonalInformationTab";
import DemographicQuestionsTab from "pages/operations/leadDetails/Tabs/DemographicQuestionsTab";
import CustomButton from "components/Common/buttons/CustomButton";
import { ENGINE_VERSION, USER_ROLES } from "utils/constants";
import { Form, Modal, Space } from "antd";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import {
  approveDeclineLead,
  assignPackageToCustomer,
  deleteLead,
  updateLead,
  uploadPictureToS3Bucket,
} from "utils/api";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import { base64toBuffer, userHasOneOfRoles } from "utils/helpers/functions";
import { getFormErrors } from "pages/operations/leadDetails/utils";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { useHistory } from "react-router";
import CancelTaskModal from "pages/operations/leadDetails/Tabs/CancelTaskModal";
import SectorTSRModal from "pages/operations/leadDetails/SectorTSRModal";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 10,
    },
  },
  hide: {
    display: "none",
  },
  show: {
    display: "block",
  },
  hasError: {
    position: "relative",

    "&:after": {
      content: "''",
      top: -2,
      right: 0,
      width: 8,
      height: 8,
      borderRadius: 50,
      position: "absolute",
      backgroundColor: "#ff4d4f",
    },
  },
  duplicateModal: {
    width: "500px!important",

    "& .ant-modal-header": {
      padding: "24px 24px 0 !important",
    },

    "& .ant-modal-body": {
      padding: "24px 24px !important",
    },
  },
  leadSection: {
    "&> ul": {
      listStyleType: "none",
    },
  },
});

const DataTabs = ({ lead, setLead, packages, sectors, engineVersion, update, questionnaire, TSRList, leadTsrData }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const history = useHistory();
  const [questionsForm] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.PERSONAL_INFORMATION);
  const [mode, setMode] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [duplicateList, setDuplicateList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [isOpenCancelTaskModal, setIsOpenCancelTaskModal] = useState(false);
  const [tabsError, setTabsError] = useState({
    [TABS.PERSONAL_INFORMATION]: false,
    [TABS.DEMOGRAPHIC_QUESTIONS]: false,
  });
  const {
    list: { occupation },
  } = useSelector(DictionariesSelector);

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  const handleChangeEditMode = useCallback(() => {
    setMode("edit");
    const isOccupationExist = Boolean(occupation?.find(item => item === lead?.occupation));
    form.setFieldsValue({
      ...lead,
      ...lead?.location,
      occupation: isOccupationExist ? lead?.occupation : null,
      dob: moment(lead?.dob),
      idType: lead?.document?.type,
      idNumber: lead?.document?.number,
    });
    const values = lead?.registrationSurvey?.items?.reduce((obj, key) => {
      obj[key.question] = key.answer;
      return obj;
    }, {});
    questionsForm.setFieldsValue(values);
  }, [form, lead, occupation]);

  const handleCancelEditMode = useCallback(() => {
    setMode("");
    setTabsError({
      [TABS.PERSONAL_INFORMATION]: false,
      [TABS.DEMOGRAPHIC_QUESTIONS]: false,
    });
    closeCancelModal();
  }, []);

  const uploadPhoto = useCallback(async data => {
    const base64Data = base64toBuffer(data.base64);
    return await uploadPictureToS3Bucket(data.url, base64Data, data.fileType).then(() => data.fileName);
  }, []);

  const onSave = useCallback(async () => {
    setLoader(true);
    closeSaveModal();
    const {
      firstName,
      lastName,
      middleName,
      gender,
      dob,
      occupation,
      familySize,
      type,
      street,
      houseNumber,
      secondaryPhone,
      closeRelativePhone,
      idType,
      idNumber,
      personalPhotoFileName,
      idPhotoFileName,
      housePhotoFileName,
      packageCode,
    } = form.getFieldsValue();
    const questionsInfo = questionsForm.getFieldsValue();
    const withAnswers = Object.values(questionsInfo).filter(item => item);
    const formHasErrors = getFormErrors(form, [
      "firstName",
      "lastName",
      "gender",
      "dob",
      "occupation",
      "familySize",
      "type",
      "packageCode",
      "street",
      "houseNumber",
      "housePhotoFileName",
      "secondaryPhone",
      "idType",
      "idNumber",
      "idPhotoFileName",
      "personalPhotoFileName",
    ]);
    if (formHasErrors === 0 && withAnswers.length === questionnaire.items.length) {
      setTabsError({
        [TABS.PERSONAL_INFORMATION]: false,
        [TABS.DEMOGRAPHIC_QUESTIONS]: false,
      });
      const questions = Object.keys(questionsInfo).map(item => ({
        questionId: item,
        answerId: questionsInfo[item],
      }));

      const personalPhoto = personalPhotoFileName?.url
        ? await uploadPhoto(personalPhotoFileName)
        : lead?.personalPhotoFileName;
      const idPhoto = idPhotoFileName?.url ? await uploadPhoto(idPhotoFileName) : lead?.document?.fileName;
      const housePhoto = housePhotoFileName?.url
        ? await uploadPhoto(housePhotoFileName)
        : lead?.location?.housePhotoFileName;

      const dataToSave = {
        generalInformation: {
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          middleName: middleName?.trim(),
          gender,
          dob: moment(dob).format("YYYY-MM-DD"),
          occupation,
          familySize: +familySize,
          type,
          location: {
            lat: lead.location.lat,
            lon: lead.location.lon,
          },
        },
        contactDetails: {
          street: street?.trim(),
          houseNumber: houseNumber?.trim(),
          phone: lead.phone,
          secondaryPhone,
          closeRelativePhone,
          housePhotoFileName: housePhoto,
        },
        identityDetails: {
          document: {
            type: idType,
            number: `${idNumber}`,
            fileName: idPhoto,
          },
          personalPhotoFileName: personalPhoto,
        },
        survey: {
          id: questionnaire.id,
          items: questions,
        },
        refSource: lead?.refSource,
        refValue: lead?.refValue,
      };
      updateLead(lead?.id, dataToSave, !lead?.dob)
        .then(async () => {
          if (lead?.packageCode !== packageCode) {
            await assignPackageToCustomer({ code: packageCode, customerId: lead?.id });
          }
          setLoader(false);
          successMessage(`Lead was updated successfully`);
          setMode("");
          update();
        })
        .catch(e => {
          setLoader(false);
          if (e.response?.data?.errors[0]?.code === "DUPLICATED_CUSTOMER") {
            setDuplicateList(e.response?.data?.errors[0]?.items);
            return errorMessage(e.response?.data?.errors[0]?.desc);
          }
          defaultNgiErrorMethod(e);
        });
    } else {
      setTabsError({
        [TABS.PERSONAL_INFORMATION]: Boolean(formHasErrors),
        [TABS.DEMOGRAPHIC_QUESTIONS]: withAnswers.length !== questionnaire.items.length,
      });
      setLoader(false);
    }
  }, [form, questionsForm, lead, questionnaire]);

  const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);
  const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);

  const openSaveModal = useCallback(() => {
    form.submit();
    questionsForm.submit();
    setShowSaveModal(true);
  }, [form, questionsForm]);
  const closeSaveModal = useCallback(() => setShowSaveModal(false), []);

  const openCancelModal = useCallback(() => setShowCancelModal(true), []);
  const closeCancelModal = useCallback(() => setShowCancelModal(false), []);

  const handleCancelTask = useCallback(() => setIsOpenCancelTaskModal(true), []);
  const closeCancelTask = useCallback(() => setIsOpenCancelTaskModal(false), []);

  const onDuplicatesClose = useCallback(() => setDuplicateList([]), []);

  const onDelete = useCallback(() => {
    closeDeleteModal();
    setLoader(true);
    deleteLead(lead?.id)
      .then(() => {
        setTimeout(() => {
          setLoader(false);
          history.push("/operations/leads");
          successMessage("Lead deleted successfully");
        }, 3000);
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [lead]);

  const onDecline = useCallback(
    reason => {
      closeDeclineModal();
      setLoader(true);
      approveDeclineLead(lead?.id, APPROVE_DECLINE_STATUSES.DECLINE, reason)
        .then(() => {
          setLoader(false);
          history.push("/operations/leads");
          successMessage(`Lead status changed to Declined successfully`);
        })
        .catch(e => {
          setLoader(false);
          defaultNgiErrorMethod(e);
        });
    },
    [lead?.id]
  );

  const onApprove = useCallback(() => {
    closeModal();
    setLoader(true);
    approveDeclineLead(lead?.id, APPROVE_DECLINE_STATUSES.APPROVE)
      .then(() => {
        setLoader(false);
        history.push("/operations/leads");
        successMessage(`Lead status changed to Approved successfully`);
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [lead?.id]);

  const openModal = useCallback(() => setShowModal(true), []);

  const closeModal = useCallback(() => setShowModal(false), []);

  const openDeclineModal = useCallback(() => setShowDeclineModal(true), []);

  const closeDeclineModal = useCallback(() => setShowDeclineModal(false), []);

  const openAssignModal = useCallback(() => setShowAssignModal(true), []);
  const closeAssignModal = useCallback(() => setShowAssignModal(false), []);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {TABS_BUTTONS.map(item => (
          <div
            key={item.key}
            className={classnames(
              classes.tab,
              activeTab === item.key && classes.active,
              tabsError[item.key] && mode === "edit" && classes.hasError
            )}
            data-tab={item.key}
            onClick={onTabClick}
          >
            {item.title}
          </div>
        ))}
      </div>
      <Card className={classes.container}>
        <CardBody>
          <div className={classnames(classes.hide, activeTab === TABS.PERSONAL_INFORMATION && classes.show)}>
            <PersonalInformationTab packages={packages} mode={mode} editForm={form} lead={lead} isLoading={isLoading} />
          </div>
          <div className={classnames(classes.hide, activeTab === TABS.DEMOGRAPHIC_QUESTIONS && classes.show)}>
            <DemographicQuestionsTab
              mode={mode}
              editForm={questionsForm}
              registrationSurvey={lead?.registrationSurvey}
              leadQuestionnaire={questionnaire}
            />
          </div>
          <div className={classes.buttonsContainer}>
            {lead?.status === "Pending" && lead?.type === "Commercial" && (
              <>
                {!lead?.sector && leadTsrData?.team !== "Growth" && engineVersion === ENGINE_VERSION.V3 && (
                  <CustomButton type="success" text="Assign" size="small" onClick={openAssignModal} />
                )}
                <CustomButton
                  roles={[USER_ROLES.SYSTEM_SUPPORT, USER_ROLES.OPERATION_MANAGERS, USER_ROLES.A_SM]}
                  type="error"
                  text="Decline"
                  size="small"
                  onClick={openDeclineModal}
                />
                <CustomButton
                  roles={[USER_ROLES.SYSTEM_SUPPORT, USER_ROLES.OPERATION_MANAGERS, USER_ROLES.A_SM]}
                  type="primary"
                  text="Approve"
                  size="small"
                  // disabled={!lead?.sector && leadTsrData?.team !== "Growth"}
                  onClick={openModal}
                />
              </>
            )}

            {lead?.status === "Pending" && lead?.type === "Residential" && (
              <CustomButton
                hideRoles={[USER_ROLES.IT_OPERATIONS_LEADERSHIP, USER_ROLES.IT_OPERATIONS]}
                type="error"
                text="Decline"
                size="small"
                onClick={openDeclineModal}
              />
            )}

            {lead?.status === "Qualified" && (
              <CustomButton
                data-type="decline"
                hideRoles={[USER_ROLES.IT_OPERATIONS_LEADERSHIP, USER_ROLES.IT_OPERATIONS]}
                onClick={handleCancelTask}
                type="error"
                size="small"
                text="Cancel Installation"
              />
            )}

            {(lead?.status === "Pending" ||
              (lead?.status === "Declined" && !userHasOneOfRoles([USER_ROLES.TRAINING]))) && (
              <>
                {mode !== "edit" ? (
                  <CustomButton
                    roles={[USER_ROLES.SYSTEM_SUPPORT]}
                    color="outlined-white"
                    text="Edit"
                    size="small"
                    onClick={handleChangeEditMode}
                  />
                ) : (
                  <>
                    <CustomButton
                      roles={[USER_ROLES.SYSTEM_SUPPORT, USER_ROLES.TRAINING]}
                      color="outlined-white"
                      text="Cancel"
                      size="small"
                      onClick={openCancelModal}
                    />
                    <CustomButton
                      roles={[USER_ROLES.SYSTEM_SUPPORT, USER_ROLES.TRAINING]}
                      type="primary"
                      text="Save"
                      size="small"
                      onClick={openSaveModal}
                    />
                  </>
                )}
              </>
            )}

            {mode !== "edit" && lead?.status === "Incompleted" && (
              <CustomButton
                roles={[USER_ROLES.SYSTEM_FULL_ADMINS]}
                type="error"
                text="Delete"
                size="small"
                onClick={openDeleteModal}
              />
            )}
          </div>
        </CardBody>
      </Card>

      {showModal && (
        <ConfirmationModal
          onCancel={closeModal}
          onConfirm={onApprove}
          description="Are you sure you want to approve lead?"
        />
      )}
      {showDeclineModal && (
        <ConfirmationModal
          withComment
          isCommentRequired
          onCancel={closeDeclineModal}
          onConfirm={onDecline}
          description={`Are you sure you want to decline ${lead?.firstName} ${lead?.lastName}?`}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          onCancel={closeDeleteModal}
          onConfirm={onDelete}
          description="Are you sure you want to delete the lead?"
        />
      )}
      {showSaveModal && (
        <ConfirmationModal
          onCancel={closeSaveModal}
          onConfirm={onSave}
          description="The lead information will be updated. Are you sure you want to save it?"
        />
      )}
      {showCancelModal && (
        <ConfirmationModal
          onCancel={closeCancelModal}
          onConfirm={handleCancelEditMode}
          description="The changes will be removed. Are you sure you want to discard it?"
        />
      )}
      {showAssignModal && (
        <SectorTSRModal
          onClose={closeAssignModal}
          leadTsr={leadTsrData}
          lead={lead}
          sectors={sectors}
          TSRList={TSRList}
          onUpdate={update}
        />
      )}
      {!!duplicateList.length && (
        <Modal
          className={classes.duplicateModal}
          title={""}
          open={true}
          onCancel={onDuplicatesClose}
          closable={false}
          footer={[]}
        >
          <>
            <div>
              {duplicateList.map(item => (
                <div key={item?.id} className={classes.leadSection}>
                  The lead with specified data already exists. Please specify unique data.
                  <ul>
                    <li>
                      Lead ID (<b>{`${item?.id}`}</b>)
                    </li>
                    <li>
                      Name (<b>{`${item?.firstName} ${item?.lastName}`}</b>)
                    </li>
                    <li>
                      Phone Number (<b>{`${item?.phone}`}</b>)
                    </li>
                    {item?.document?.number && (
                      <li>
                        Id Number (<b>{`${item?.document?.number}`}</b>)
                      </li>
                    )}
                  </ul>
                </div>
              ))}
            </div>
            <Space>
              <CustomButton onClick={onDuplicatesClose} type="primary" size="small" color="outlined" text="Close" />
            </Space>
          </>
        </Modal>
      )}
      {!!isOpenCancelTaskModal && <CancelTaskModal onClose={closeCancelTask} lead={lead} setLead={setLead} />}
    </div>
  );
};

export default DataTabs;
