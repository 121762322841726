const INIT_STATE = {
  failedActions: [],
};

const Utils = (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default Utils;
