import { textFormatter } from "components/Common/formatter";

export const simCardsListColumns = () => [
  {
    title: "ICC ID",
    dataIndex: "iccid",
    sort: true,
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "Sim Card Type",
    dataIndex: "simType",
    sort: true,
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "IMSI",
    dataIndex: "imsi",
    sort: true,
    render: cellContent => textFormatter(cellContent),
  },
  {
    title: "IP",
    dataIndex: "ip",
    sort: true,
    render: cellContent => textFormatter(cellContent),
  },

  {
    title: "MSISDN",
    dataIndex: "msisdn",
    sort: true,
    render: cellContent => textFormatter(cellContent),
  },
];
