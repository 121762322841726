import React from "react";
import { Tag } from "antd";
import moment from "moment";

export const textFormatter = (val, color = "black") =>
  val === undefined ? <Tag color={"gold"}>{"-"}</Tag> : <div style={{ color }}>{val}</div>;
export const boolFormatter = val => {
  if (typeof val === "boolean") return <div>{val === true ? "Yes" : "No"}</div>;
  return <Tag color={"gold"}>{"-"}</Tag>;
};

export const camelCaseTextFormatter = string =>
  !!string
    ? string
        .split(/(?=[A-Z])/)
        .join(" ")
        .toUpperCase()
    : "-";

export const uppercaseTextFormatter = val =>
  !val ? <Tag color={"gold"}>{"-"}</Tag> : <span className="uppercase-text">{val}</span>;

export const priceFormatter = (price, currency) => {
  if (!price) return <Tag color={"gold"}>{"-"}</Tag>;

  return (
    <span className="price-format">
      <b>{price} </b>
      {currency}
    </span>
  );
};

export const tagColorFormatter = val => {
  if (!val) return <Tag color={"red"}>{"-"}</Tag>;
  return <span className={`custom-tag tag-color-2`}>{val}</span>;
};

export const statusFormatter = val => {
  if (!val) return <Tag color={"gold"}>{"-"}</Tag>;
  return val.toLowerCase() === "active" ? (
    <span style={{ color: "#17C347", textTransform: "capitalize" }}>{val}</span>
  ) : (
    <span style={{ color: "#FF0000", textTransform: "capitalize" }}>{val}</span>
  );
};

export const booleanStatusFormatter = val => {
  const status = {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  };

  return !!val ? (
    <span style={status}>{"ACTIVE"}</span>
  ) : (
    <span style={{ ...status, backgroundColor: "#FF0000" }}>{"INACTIVE"}</span>
  );
};

export const priorityFormatter = val => {
  if (typeof val === "number") {
    if (val > 0 && val < 14) return <span className="priority-text high">High</span>;
    if (val >= 14 && val <= 27) return <span className="priority-text medium">Medium</span>;
    if (val >= 28) return <span className="priority-text low">Low</span>;
  }
  return !val ? <Tag color={"gold"}>{"-"}</Tag> : <div>{val}</div>;
};

export const userFormatter = (val, color) =>
  val === undefined ? (
    <>
      <Tag color={color}>{"-"}</Tag>
    </>
  ) : (
    <>
      <div className="user-column-value">{val}</div>
    </>
  );

export const weightFormatter = val => {
  const color = val > 3 ? "green" : "red";
  return val === undefined ? (
    <>
      <Tag color={"gold"}>{"-"}</Tag>
    </>
  ) : (
    <>
      <Tag color={color}>{Number(val).toFixed(2)}</Tag>
    </>
  );
};

export const depotSpliter = () => localStorage.getItem("depotId").split("-");

export const sortByName = items =>
  // sort by name
  items.sort(function (a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

export const formatAMPM = (hours, minutes, seconds) => {
  const ampm = hours >= 12 ? "PM" : "AM";
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${hours}:${minutes}:${seconds} ${ampm}`;
};

export const customDateFormatWithTime = (timestamp, showSeconds = true) =>
  !!timestamp ? moment(timestamp).format(`YYYY-MM-DD HH:mm${showSeconds ? ":ss" : ""}`) : null;
export const customTimeFormat = timestamp => (!!timestamp ? moment(timestamp).format("HH:mm") : null);
export const customDateFormatWithoutTime = timestamp => (!!timestamp ? moment(timestamp).format("YYYY-MM-DD") : null);

export const getStartOfTheDay = moment => (!!moment ? Date.parse(moment.hours(0).minutes(0).seconds(0)) : null);
export const getEndOfTheDay = moment => (!!moment ? Date.parse(moment.hours(23).minutes(59).seconds(59)) : null);

export async function globalSearch(
  handleOnFilter,
  currentFilterOptions,
  search,
  assetList,
  handleSearchAPI,
  setAssetList,
  setLoader
) {
  try {
    if (search.length === 0) {
      await handleOnFilter(currentFilterOptions);
    } else {
      const filteredTypes = assetList.filter(type => type.barcode.toLowerCase().includes(search.toLowerCase()));
      if (filteredTypes.length === 0) {
        const options = { ...currentFilterOptions, barcode: search };
        await handleSearchAPI(options);
      } else setAssetList(filteredTypes);
      setLoader(false);
    }
  } catch (err) {
    setLoader(false);
  }
}

export async function globalTaskSearch(
  handleOnFilter,
  currentFilterOptions,
  search,
  taskList,
  handleSearchAPI,
  setTaskList,
  setLoader
) {
  try {
    if (search.length === 0) {
      await handleOnFilter(currentFilterOptions);
    } else {
      const filteredTypes = taskList.filter(type => type.customer.name.toLowerCase().includes(search.toLowerCase()));
      if (filteredTypes.length === 0) {
        const options = { ...currentFilterOptions, customerName: search };
        await handleSearchAPI(options);
      } else setTaskList(filteredTypes);
      setLoader(false);
    }
  } catch (err) {
    setLoader(false);
  }
}

export async function globalRoutineSearch(
  handleOnFilter,
  currentFilterOptions,
  search,
  routineList,
  handleSearchAPI,
  setRoutineList,
  setLoader
) {
  try {
    if (search.length === 0) {
      await handleOnFilter(currentFilterOptions);
    } else {
      const filteredTypes = routineList.filter(type => type.assigneeName.toLowerCase().includes(search.toLowerCase()));
      if (filteredTypes.length === 0) {
        const options = { ...currentFilterOptions, assigneeName: search };
        await handleSearchAPI(options);
      } else setRoutineList(filteredTypes);
      setLoader(false);
    }
  } catch (err) {
    setLoader(false);
  }
}

export async function globalSearchMeter(
  handleOnFilter,
  currentFilter,
  search,
  dataSource,
  handleSearchAPI,
  setMeterList
) {
  try {
    if (search.length === 0) {
      await handleOnFilter(currentFilter);
    } else {
      const filteredTypes = dataSource.filter(type => type.barcode.toLowerCase().includes(search.toLowerCase()));
      if (filteredTypes.length === 0) {
        const options = { ...currentFilter, barcode: search };
        await handleSearchAPI(options);
      } else setMeterList(filteredTypes);
      //setLoader(false)
    }
  } catch (err) {
    //setLoader(false)
  }
}

export function searchStateSetters(
  setDataEndCursor,
  setDataStartCursor,
  setDataHasNextPage,
  setDataHasPreviousPage,
  setLoader,
  res
) {
  const { hasNextPage, hasPreviousPage, startCursor, endCursor } = res.data.result.meta.pageData;
  setDataEndCursor(endCursor);
  setDataStartCursor(startCursor);
  setDataHasNextPage(hasNextPage);
  setDataHasPreviousPage(hasPreviousPage);
  setLoader(false);
}

export async function searchAssetCallBack(
  filterOptions,
  depot,
  retrieveAllAssets,
  warnMessage,
  setGroup,
  setAssetList,
  setDataEndCursor,
  setDataStartCursor,
  setDataHasNextPage,
  setDataHasPreviousPage,
  setLoader,
  defaultNgiErrorMethod
) {
  const myOptions = {
    ...filterOptions,
    depot: depot,
    endCursor: null,
    pageSize: null,
    state: null,
    group: null,
    barcode: filterOptions.barcode.toUpperCase(),
  };
  await retrieveAllAssets(myOptions)
    .then(async res => {
      const arr = res.data.result.items;
      if (arr.length === 0) {
        warnMessage(`${filterOptions.barcode} not found`);
        setLoader(false);
        return;
      }

      if (arr.length === 1) setGroup(arr[0]["typeGroup"]);
      else if (arr.length > 1) setGroup(null);

      setAssetList(arr);
      searchStateSetters(
        filterOptions,
        setAssetList,
        arr,
        setDataEndCursor,
        setDataStartCursor,
        setDataHasNextPage,
        setDataHasPreviousPage,
        setLoader,
        setGroup
      );
    })
    .catch(err => {
      setLoader(false);
      defaultNgiErrorMethod(err);
    });
}

export async function searchTaskCallBack(
  filterOptions,
  depot,
  retrieveAllTasks,
  warnMessage,
  setTaskList,
  setLoader,
  defaultNgiErrorMethod
) {
  const myOptions = { ...filterOptions, customerName: filterOptions.customerName, depot: depot };
  await retrieveAllTasks(myOptions)
    .then(async res => {
      const arr = res.data.result.items;
      if (arr.length === 0) {
        warnMessage(`${filterOptions.customerName} not found`);
        setLoader(false);
        return;
      }

      setTaskList(arr);
    })
    .catch(err => {
      setLoader(false);
      defaultNgiErrorMethod(err);
    });
}

export async function searchRoutineCallBack(
  filterOptions,
  depot,
  retrieveAllTaskRoutines,
  warnMessage,
  setRoutineList,
  setLoader,
  defaultNgiErrorMethod
) {
  const myOptions = {
    ...filterOptions,
    pageSize: null,
    depot: depot,
    status: null,
    assigneeName: filterOptions.assigneeName,
  };
  await retrieveAllTaskRoutines(myOptions)
    .then(async res => {
      const arr = res.data.result.items;
      if (arr.length === 0) {
        warnMessage(`${filterOptions.assigneeName} not found`);
        setLoader(false);
        return;
      }

      setRoutineList(arr);
    })
    .catch(err => {
      setLoader(false);
      defaultNgiErrorMethod(err);
    });
}

export function normalStateSetters(filterOptions, res, setAssetList, arr, setLoader) {
  setAssetList(arr);
  setLoader(false);
}

export async function normalAssetCallBack(
  filterOptions,
  depot,
  retrieveAllAssets,
  warnMessage,
  setCurrentFilterOptions,
  setAssetList,
  setLoader,
  defaultNgiErrorMethod,
  successMessage
) {
  setLoader(true);
  const myConstOptions = { ...filterOptions, depot: depot };
  setCurrentFilterOptions(myConstOptions);
  await retrieveAllAssets(myConstOptions)
    .then(async res => {
      const arr = res.data.result.items.sort((a, b) => b.createdAt - a.createdAt);
      if (arr.length === 0) {
        warnMessage(`${filterOptions.group} not found`);
        setLoader(false);
        setAssetList(null);
        return;
      }
      normalStateSetters(filterOptions, res, setAssetList, arr, setLoader, successMessage);
    })
    .catch(err => {
      setLoader(false);
      defaultNgiErrorMethod(err);
    });
}

export async function searchGenericCallBack(
  filterOptions,
  depot,
  retrieveAllAssets,
  warnMessage,
  setAssetList,
  setDataEndCursor,
  setDataStartCursor,
  setDataHasNextPage,
  setDataHasPreviousPage,
  setLoader,
  defaultNgiErrorMethod
) {
  let myOptions;
  if (filterOptions.location)
    myOptions = { ...filterOptions, depot: depot, location: null, barcode: filterOptions.barcode.toUpperCase() };
  await retrieveAllAssets(myOptions)
    .then(async res => {
      const arr = res.data.result.items;
      if (arr.length === 0) {
        warnMessage(`${filterOptions.barcode} not found`);
        setLoader(false);
        return;
      }

      setAssetList(arr);
      searchStateSetters(
        filterOptions,
        setAssetList,
        arr,
        setDataEndCursor,
        setDataStartCursor,
        setDataHasNextPage,
        setDataHasPreviousPage,
        setLoader
      );
    })
    .catch(err => {
      setLoader(false);
      defaultNgiErrorMethod(err);
    });
}
