import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Row, Col, CardBody, Card, UncontrolledAlert, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { loginUser } from "../../store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.png";
import MetaData from "components/VerticalLayout/MetaData";
import config from "appconfig";
import { LoginSelector } from "store/auth/login/selectors";
import { useHistory, useLocation } from "react-router";
import { Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
    maxHeight: "100vh",
  },
});
const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { error } = useSelector(LoginSelector);
  const { cognito } = config;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useLayoutEffect(() => {
    setLoading(true);
    const resp = location.search.replace("?resp=", "");
    if (resp) {
      setTimeout(() => dispatch(loginUser(resp, history)), 1500);
      return;
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!!localStorage.getItem("b2c:authUser")) {
      history.push("/dashboard");
    }
  }, [!!localStorage.getItem("b2c:authUser")]);

  const handleValidSubmit = useCallback(
    async (event, values) => {
      dispatch(loginUser(values, history));
    },
    [history]
  );

  const handleCognitoAuth = useCallback(() => {
    const url =
      `${cognito.domain}/oauth2/authorize?state=${cognito.state}` +
      `&client_id=${cognito.clientId}&redirect_uri=${cognito.redirectUrl}` +
      `&response_type=code`;
    window.location.replace(url);
  }, [cognito]);

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large">
          <div className={classes.root}></div>
        </Spin>
      ) : (
        <>
          <MetaData pageTitle={"Login"} />

          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="fas fa-home h2" />
            </Link>
          </div>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col xs={7}>
                          <div className="text-primary p-4">
                            <h5>NGI PORTAL</h5>
                            <p className="text-muted">Sign in to continue.</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img src={logo} alt="" className="rounded-circle" height="34" />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          {error && typeof error === "string" ? (
                            <UncontrolledAlert color="danger" style={{ marginTop: "13px" }} fade={true}>
                              {error}
                            </UncontrolledAlert>
                          ) : null}

                          <div className="mt-4 text-center">
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <button
                                  onClick={handleCognitoAuth}
                                  className="btn btn-light btn-block waves-effect waves-light"
                                  type="button"
                                >
                                  <i className="mdi mdi-microsoft" /> SignIn with Microsoft
                                </button>
                              </li>
                            </ul>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      © {new Date().getFullYear()} NGI PORTAL<sup>TM</sup>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
