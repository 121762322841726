import { getTrimmedObjectValues } from "utils/helpers/functions";

export const getFormattedFields = json =>
  json?.map(item => {
    if (!item.iccid) return null;
    return getTrimmedObjectValues({
      msisdn: item?.msisdn?.toString(),
      iccid: item.iccid.toString(),
      ip: item?.ip?.toString(),
      imsi: item?.imsi?.toString(),
      simType: item?.simType?.toString(),
      imei: Number.parseInt(item?.imei),
      networkWhitelist: item?.networkWhitelist?.toString(),
      imeiLock: item?.imeiLock?.toString(),
      simConnector: item?.simConnector?.toString(),
      isActivated: item?.isActivated?.toString().toLowerCase() === "yes",
      networkName: item?.networkName?.toString(),
      networkCountry: item?.networkCountry?.toString(),
      networkCountryCode: item?.networkCountryCode?.toString(),
      networkMCC: item?.networkMCC?.toString(),
      networkMNC: item?.networkMNC?.toString(),
    });
  });
