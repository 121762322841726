import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { FILTER_PAGE, USER_TENANT_NAME } from "utils/constants";
import { deductionType } from "pages/packages/all-packages/newPackage/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;
const filterOptions = [
  {
    name: "Depot",
    value: "depot",
  },
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Code",
    value: "code",
  },
  {
    name: "Deduction type",
    value: "deductionType",
  },
  {
    name: "Gas First Payment Amount",
    value: "hasGasFirstPayment",
  },
  {
    name: "Stove First Payment Amount",
    value: "hasStoveFirstPayment",
  },
];

const gasStoveValues = ["Yes", "No"];

export const status = ["Active", "Inactive"];

const PackageFilter = ({ onFilter }) => {
  const [form] = Form.useForm();
  const [activeFilter, setActiveFilter] = useState(null);
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.PACKAGES);

  useEffect(() => {
    if (!isEmpty(filters)) {
      const options = filterOptions.map(item => item.value);
      Object.keys(filters).forEach(item => {
        if (options.includes(item)) {
          form.setFieldsValue({ type: item, value: filters[item] });
          setActiveFilter(item);
        }
      });
    }
  }, []);

  const filterTypeConfig = useMemo(
    () => ({
      depot: {
        values: !!depot ? depot[USER_TENANT_NAME]?.sort() : [],
      },
      status: {
        values: status,
      },
      deductionType: {
        values: deductionType,
      },
      hasGasFirstPayment: {
        values: gasStoveValues,
      },
      hasStoveFirstPayment: {
        values: gasStoveValues,
      },
    }),
    [depot, status, deductionType]
  );

  const handleFilterChange = useCallback(
    item => {
      setActiveFilter(item);
      form.setFieldsValue({ value: null });
    },
    [form]
  );

  const onFinish = useCallback(values => {
    const { pageSize } = getFilters();
    const { type, value } = values;
    onFilter({ [type]: value?.trim(), pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <Form.Item name="type">
          <Select
            showSearch
            tokenSeparators={[","]}
            onChange={handleFilterChange}
            size={"default"}
            placeholder={"Select type"}
            allowClear
          >
            {filterOptions.map(item => (
              <Option key={`${item.name}-option`} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {activeFilter && (
          <>
            {activeFilter === "code" ? (
              <Form.Item name="value">
                <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
              </Form.Item>
            ) : (
              <Form.Item name="value">
                <Select showSearch tokenSeparators={[","]} size={"default"} placeholder={"Select value"} allowClear>
                  {filterTypeConfig[activeFilter]?.values.map(item => (
                    <Option key={`${item}-option`} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        )}

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default PackageFilter;
