import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "antd";

const useStyles = makeStyles({
  formItemWrapper: {
    display: "contents",
  },
});

//The NGIFormItem is a wrapper for Form.Item
// to have possibility to check if field is required
const NGIFormItem = ({ children, ...props }) => {
  const classes = useStyles();
  const isRequired = useMemo(() => (props?.rules && props?.rules?.find(item => item.required)) || false, [children]);

  return (
    <div className={classes.formItemWrapper} data-name={props.name} data-is-required={Boolean(isRequired)}>
      <Form.Item {...props}>{children}</Form.Item>
    </div>
  );
};

export default NGIFormItem;
