import {
  assetMicroservice,
  smartsaleMicroservice,
  taskMicroservice,
  configurationMicroservice,
  metersMicroservice,
  operationsLegacyMicroservice,
  pgLegacyMicroservice,
  customersMicroservice,
  packagesMicroservice,
  paymentsMicroservice,
  depotsMicroservice,
  stoveMicroservice,
  historyManagement,
  smsMicroservice,
  transactionAdjustmentMicroservice,
  userMicroservice,
  vehicleMicroservice,
  campaignMicroservice,
  marketingMicroservice,
} from "./config";
import axios from "axios";
import moment from "moment";
import { DEFAULT_PAGE_SIZE } from "utils/constants";

const smartsale = smartsaleMicroservice();

//NGI ASSETS APIS
export const retrieveAllAssets = options =>
  assetMicroservice().get("/assets", {
    params: {
      ...options,
    },
  });

export const retrieveAllTasks = options =>
  taskMicroservice().get("/tasks", {
    params: {
      ...options,
    },
  });

export const retrieveAssetValidationRequests = options =>
  assetMicroservice().get("/validation", {
    params: {
      ...options,
    },
  });

export const retrieveAssetValidationRequestDetails = id => assetMicroservice().get(`/validation/${id}`);

export const assetValidationItem = (requestId, action, itemId, data) =>
  assetMicroservice().post(`/validation/${requestId}/${action}/${itemId}`, data);

export const assetValidationItemReject = requestId => assetMicroservice().post(`/validation/${requestId}/reject`);

export const retrieveTasksStatistics = (options, version = "v1") =>
  taskMicroservice(version).get("/tasks/stats", {
    params: { ...options },
  });

export const assignBulkTaskApi = data => taskMicroservice().post("/tasks/bulk/assign", data);

export const bulkCancelTasksAPI = data => taskMicroservice().post("/tasks/bulk/cancel", data);

export const cancelTaskApi = data => {
  const { id, comments, reason, newTask } = data;
  const payload = { comments: comments, reason: reason, newTask };
  return taskMicroservice().post(`/tasks/${id}/cancel`, payload);
};

export const approveTaskApi = (id, data) => taskMicroservice().post(`/tasks/${id}/approve`, data);

export const createBulkTaskApi = data => taskMicroservice().post("/tasks/bulk", data);

export const bulkCreateADMOCommands = data => operationsLegacyMicroservice().post("/job/admo", data);

export const retrieveTaskDetails = id => taskMicroservice().get(`/tasks/${id}`);

export const retrieveTaskDocuments = (id, fileName) =>
  taskMicroservice().get(`/tasks/${id}/documents/download/url`, {
    params: { fileName },
  });

export const openRoutine = data => {
  const { depot, assigneeId, assigneeName, assigneePhone } = data;
  return taskMicroservice().post(`/routines`, {
    depot,
    assignee: {
      id: assigneeId,
      name: assigneeName,
      phone: assigneePhone,
    },
  });
};

export const retrieveRoutineDetails = id => taskMicroservice().get(`/routines/${id}`);
export const setCustomerAvailability = (id, data) => taskMicroservice().post(`/customerAvailability/${id}`, data);

export const retrieveAssetsDetails = (id, idType) => assetMicroservice().get(`/assets/${id}`, { params: { idType } });

export const retrieveAssetsRequestsAPI = data => assetMicroservice().get(`/assetRequests`, { params: { ...data } });

export const retrieveAssetPhotos = (id, photos) =>
  assetMicroservice().get(`/assets/${id}/photos`, {
    params: { photos },
  });

export const retrieveAssets = data => assetMicroservice().get(`/assets`, { params: { ...data } });

export const retrieveSMSProvidersAPI = () => smsMicroservice().get(`/providers`);
export const updateSMSProviders = data => smsMicroservice().post(`/providers`, data);

export const sendOtp = data => smsMicroservice().post(`otp/send?template=Default`, data);
export const validateOtp = data => smsMicroservice().post(`otp/validate`, data);

export const retrieveCylinderWeightReports = (type, params) =>
  assetMicroservice().get(`/cylinders/reports?type=${type}`, { params });

export const retrieveCylinderReportsDownloadUrl = params =>
  assetMicroservice().get(`/download/cylinder-reports/url`, { params });

export const retrieveAssetsHistory = data => assetMicroservice().get(`/assets/history`, { params: { ...data } });
export const createBulkCardApi = data => assetMicroservice().post("/assets/cards/bulk", data);
export const getCardsGenerationStatus = id => assetMicroservice().get(`/assets/cards/bulk/${id}`);
export const createQRCodeApi = count => assetMicroservice().post(`/qrCodes?count=${count}`);

export const retrieveAssetUploadUrlByType = (type, extension, assetId) =>
  assetMicroservice().get(`/upload/${type}/url`, {
    params: { extension, assetId },
  });

export const retrieveTAMUploadUrlByType = extension =>
  transactionAdjustmentMicroservice().get(`requests/photos/url`, {
    params: { extension },
  });

export const cylinderRepair = (id, params) =>
  assetMicroservice().put(`/cylinders/${id}/repair`, {
    ...params,
  });

export const transferAssets = (data, idType = "id") =>
  assetMicroservice().put(`/assets/transfer?idType=${idType}`, data);

// export const retrieveDepots = () => {
//   return configurationMicroservice().get("/dictionaries/depot");
// };
export const retrieveDictionaries = name => configurationMicroservice().get(`/dictionaries/${name}`);

export const createDictionary = data => configurationMicroservice().post(`/dictionaries`, data);
//END OF NGI

// export const fetchDepotList = () => {
//   return smartsale.get("/depot/list/");
// };

// export const fetchAgentsList = params => {
//   return smartsale.get("/technician/list/", {params});
// };

// Metrics APIs

export const getStats = () => smartsale.get("metrics/stats/");

export const fetchTopDepotsMetrics = () => smartsale.get("metrics/top/depots/");

export const fetchDailyMetrics = () => smartsale.get("metrics/leads/registration/");

export const fetchApprovalMetrics = () => smartsale.get("metrics/leads/approval/rates/");

export const fetchRegByStatusMetrics = () => smartsale.get("metrics/leads/by-status/");

// export const fetchLeadRegTrendMetrics = () => {
//   return smartsale.get("metrics/leads/trends/");
// };

// Leads APIs

// export const fetchLeadsList = params => {
//   return smartsale.get("lead/list/", {params});
// };
//
// export const fetchLeadsDetails = leadId => {
//   return smartsale.get(`lead/detail/${leadId}/`);
// };
//
// export const fetchLeadAddressesList = leadId => {
//   return smartsale.get(`lead/address/list/${leadId}/`);
// };
//
// export const fetchLeadContactsList = leadId => {
//   return smartsale.get(`lead/contact/list/${leadId}/`);
// };
//
// export const fetchLeadIdentitiesList = leadId => {
//   return smartsale.get(`lead/identity/list/${leadId}/`);
// };

// Counties APIs

// export const fetchCountyList = () => {
//   return smartsale.get("address/county/list/");
// };

// export const createCounty = data => {
//   return smartsale.post("address/county/create/", data);
// };

// export const updateCounty = (countyId, data) => {
//   return smartsale.put(`address/county/update/${countyId}/`, data);
// };

// Districts APIs

// export const fetchDistrictList = () => {
//   return smartsale.get("address/district/list/");
// };
//
// export const createDistrict = data => {
//   return smartsale.post("address/district/create/", data);
// };
//
// export const updateDistrict = (districtId, data) => {
//   return smartsale.put(`address/district/update/${districtId}/`, data);
// };

// Status APIs

// export const fetchStatusList = () => {
//   return smartsale.get("settings/status/list/");
// };

// export const createStatus = data => {
//   return smartsale.post("settings/status/create/", data);
// };

export const updateStatus = (statusId, data) => smartsale.put(`settings/status/update/${statusId}/`, data);

// Firmware APIs
export const retrieveMeterSettings = meterId => metersMicroservice().get(`/meters/${meterId}/settings`);

export const retrieveHardwareReports = (meterId, params) =>
  metersMicroservice().get(`/hardwareReports?meterId=${meterId}`, {
    params: {
      ...params,
    },
  });

export const retrieveMeterStatus = (meterId, params) =>
  metersMicroservice().get(`/meterStatusReports?meterId=${meterId}`, {
    params: {
      ...params,
    },
  });
export const triggerCumulativeReport = meterId =>
  metersMicroservice().post(`/meters/${meterId}/triggerGetCumulativeReportStatus`);
export const triggerCookingSessionReport = meterId =>
  metersMicroservice().post(`/meters/${meterId}/triggerGetCookingSessionReport`);
export const triggerHardwareReport = meterId =>
  metersMicroservice().post(`/meters/${meterId}/triggerGetHardwareReport`);
export const triggerLogsReport = meterId => metersMicroservice().post(`/meters/${meterId}/triggerGetLogsReport`);
export const triggerMeterStatusReport = meterId =>
  metersMicroservice().post(`/meters/${meterId}/triggerGetMeterStatusReport`);
export const triggerSettingsReport = meterId =>
  metersMicroservice().post(`/meters/${meterId}/triggerGetSettingsReport`);
export const meterAdjustCredits = ({ meterId, amount }) =>
  metersMicroservice().post(`/meters/${meterId}/adjustCredits`, {
    amount,
  });
export const sendMeterCommand = (type, data = null, meterId) =>
  metersMicroservice().post(`/meters/${meterId}/${type}`, data);
export const sendBulkMeterCommand = (type, data = null) =>
  metersMicroservice().post(`/meters/bulk/${type}`, data, {
    headers: {
      "Content-Type": "text/csv",
    },
  });
export const fetchFirmwares = () => metersMicroservice().get("firmwares");
export const createFirmware = data => metersMicroservice().post("firmwares", data);

export const updateFirmware = ({ version, data }) => metersMicroservice().put(`firmwares/${version}`, data);

export const deleteFirmware = version => metersMicroservice().delete(`firmwares/${version}`);

export const getFirmwareUpdateProcesses = params => metersMicroservice().get("/ota-firmware-updates", { params });

export const getMetersFirmwareHistory = params => metersMicroservice().get("/ota-firmware-updates/meters", { params });

// Asset Types APIs

export const fetchAssetsTrendMetrics = () => assetMicroservice().get("assetTypes");

export const fetchAssetTypesList = () => assetMicroservice().get("assetTypes");

export const createAssetType = data => assetMicroservice().post("assetTypes", data);

export const updateAssetTypes = (idTypeId, data) => assetMicroservice().put(`assetTypes/${idTypeId}/`, data);

export const setLostAssets = data => assetMicroservice().put(`assets/lost`, data);
export const setD2DLostAssets = (data, assetTransferTripId) =>
  assetMicroservice().put(`assets/lost?assetTransferTripId=${assetTransferTripId}`, data);

export const setFoundAssets = data => assetMicroservice().put(`assets/found`, data);

export const resetAssetsValidation = data => assetMicroservice().put("support/assets/validation/reset", data);

export const retrieveAllTaskRoutines = options =>
  taskMicroservice("v2").get("/routines", {
    params: {
      ...options,
    },
  });

/**
 * Meters Management APIs
 */

export const readAccessoriesAPI = options => {
  const { payload } = options;
  return metersMicroservice().get("/accessories", {
    params: {
      accessoryType: payload,
    },
  });
};
export const createAccessoryAPI = data => {
  const { type, description, accessoryType } = data;
  const payload = { type: type, description: description };
  return metersMicroservice().post("/accessories", payload, {
    params: {
      accessoryType: accessoryType,
    },
  });
};

export const updateAccessoryAPI = data => {
  const { type, description, accessoryType } = data;
  const payload = { type: type, description: description };
  return metersMicroservice().put(`/accessories/${type}`, payload, {
    params: {
      accessoryType: accessoryType,
    },
  });
};

export const deleteAccessoryAPI = data => {
  const { accessoryType, type } = data;
  return metersMicroservice().delete(`/accessories/${type}`, {
    params: {
      accessoryType: accessoryType,
    },
  });
};

export const getAccessoryByType = type => metersMicroservice().get(`/accessories?accessoryType=${type}`);

export const retrieveMeters = ({ ...params }) =>
  metersMicroservice().get("/meters", {
    params: {
      ...params,
    },
  });
export const saveMeterFactoryTest = (barcode, params) =>
  metersMicroservice().post(`/meters/${barcode}/factory-acceptance-test`, params);

export const retrieveMeterReports = params => metersMicroservice().get(`/meters/reports/maintenance`, { params });

export const retrieveMeterPinDetails = params => metersMicroservice().get(`/meters/pin`, { params });

export const setMeterPin = (params, action) => metersMicroservice().post(`/meters/pin/${action}`, params);

export const saveMetersPackagingReport = params => metersMicroservice().post(`/meters/reports/packaging`, params);

export const deleteMeterAPI = id => metersMicroservice().delete(`/meters/${id}`);

export const retrieveReadyForRepairMeters = ({ pageSize = DEFAULT_PAGE_SIZE, ...params }) =>
  metersMicroservice().get("/meters", {
    params: {
      condition: "Faulty",
      pageSize,
      ...params,
    },
  });

export const retrieveMeterDetails = id => metersMicroservice().get(`/meters/${id}`);
export const unlockMeter = id => metersMicroservice().post(`/meters/${id}/unlock`);

export const saveMeterTestingReport = ({ id, params }) =>
  metersMicroservice().post(`/meters/${id}/reports/testing`, params);

export const retrieveUploadUrlByType = (type, extension, meterId) =>
  metersMicroservice().get(`/upload/${type}/url`, {
    params: { extension, meterId },
  });

export const retrieveDownloadUrlByType = (type, fileName) =>
  metersMicroservice().get(`/download/${type}/url`, {
    params: { fileName },
  });

export const saveMeterRepairReport = ({ id, params }) =>
  metersMicroservice().post(`/meters/${id}/reports/repair`, params);

export const configureMeter = (id, params) => metersMicroservice().put(`meters/${id}/configure`, params);

export const markMeterFaultyAPI = (id, faultyReasons) =>
  metersMicroservice().put(`/meters/${id}/mark-as-faulty`, { faultyReasons });

export const updateMeterMaintenanceReportAPI = (id, faultyReasons) =>
  metersMicroservice().put(`/meters/reports/maintenance/${id}`, { faultyReasons });

export const deleteMeterJourney = id =>
  metersMicroservice().delete(`/journeys/MeterConfiguration`, { params: { meterId: id } });

export const startBulkUploadProcess = type => metersMicroservice("v2").post(`/bulk-processes/start`, type);

export const retrieveBulkProcessResults = (type, id) => metersMicroservice().get(`bulk-processes/${id}`, type);

export const triggerGetLogsReport = id => metersMicroservice().post(`/meters/${id}/triggerGetLogsReport`);

export const resetAllParametersAPI = id => metersMicroservice().post(`/meters/${id}/resetAllParameters`);

export const retrieveAsyncProcessStatusResults = id => metersMicroservice().get(`async-processes/${id}`);

export const uploadMetersToS3Bucket = (url, data) =>
  axios({
    method: "put",
    url,
    headers: {
      "Content-Type": "text/csv",
    },
    data: data,
  });

export const downloadFileData = url =>
  axios({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/json",
    },
  });

export const downloadFileBlob = url =>
  axios({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "blob",
  });

export const uploadPictureToS3Bucket = (url, data, fileType) =>
  axios({
    method: "put",
    url,
    headers: {
      "Content-Type": fileType,
    },
    data: data,
  });

export const uploadMeterLog = (meterId, params) => metersMicroservice().post(`/meters/${meterId}/logs`, params);

export const updateMeterTypeAPI = (meterId, params) => metersMicroservice().put(`/meters/${meterId}/type`, params);

export const updateOTAFirmware = data => metersMicroservice().post(`/ota-firmware-update/start`, data);

export const downloadMeterLog = fileName => metersMicroservice().get(`/meters/logs/${fileName}`);

/**
 * Global Settings/ Configurations APIs
 */
export const readSettingsAPI = () => metersMicroservice().get("/configurations");
export const createSettingsAPI = data => metersMicroservice().post(`/configurations`, data);

export const updateSettingsAPI = data => {
  const { id } = data;

  const payload = Object.fromEntries(Object.entries(data).filter(([key]) => !key.includes("id")));

  return metersMicroservice().put(`/configurations/${id}`, payload);
};

export const deleteSettingsAPI = data => {
  const { accessoryType, type } = data;
  return metersMicroservice().delete(`/configurations/${type}`, {
    params: {
      accessoryType: accessoryType,
    },
  });
};

export const retrieveSimCards = params =>
  metersMicroservice().get("/simCards", {
    params: {
      ...params,
    },
  });

export const retrieveSimCardReports = params =>
  metersMicroservice().get("/simCards/reports", {
    params: {
      ...params,
    },
  });

export const retrieveJourneyDetailsAPI = ({ journey, meterId }) =>
  metersMicroservice().get(`/journeys/${journey}?meterId=${meterId}`);

export const readCookingSessionsAPI = params =>
  metersMicroservice().get("cookingSessions", {
    params: {
      ...params,
    },
  });

export const readAlertsAPI = params =>
  metersMicroservice().get("alerts", {
    params: {
      ...params,
    },
  });

export const readCumulativeReportsAPI = params =>
  metersMicroservice().get("cumulativeReports", {
    params: {
      ...params,
    },
  });

export const readMeterStatusAPI = data => {
  const { meterId } = data;
  return metersMicroservice().get(`/meters/${meterId}/status`);
};

export const bulkUploadMeters = (type, cards) => metersMicroservice("v2").post(`/meters/${type}/upload`, cards);

//REGISTRATION METER
export const createMeterAPI = meter => assetMicroservice().post("assets", meter);

export const registerMeterAPI = data => {
  const { id } = data;

  const payload = Object.fromEntries(Object.entries(data).filter(([key]) => !key.includes("id")));
  return metersMicroservice().post(`meters/register/${id}`, payload);
};

export const adjustDataAPI = data => {
  const { id, payload } = data;
  return metersMicroservice().post(`meters/${id}/adjustData`, payload);
};

export const cylinderInstallAPI = data => {
  const { id, payload } = data;
  return metersMicroservice().post(`meters/${id}/cylinderInstall`, payload);
};

export const retrieveMeterSpareParts = depot =>
  metersMicroservice().get(`spareParts`, {
    params: {
      depot,
    },
  });

export const createMeterSpareParts = params => metersMicroservice().post(`spareParts`, params);
export const updateMeterSpareParts = ({ id, params }) => metersMicroservice().put(`spareParts/${id}`, params);

export const postMeterTestReportAPI = data => {
  const { id } = data;
  const payload = Object.fromEntries(Object.entries(data).filter(([key]) => !key.includes("id")));
  return metersMicroservice().post(`/meters/${id}/testReports`, { ...payload, isRfidStatusOk: data.isRfidStatusOk });
};

export const updateSparePartCounterAPI = ({ id, params }) =>
  metersMicroservice().put(`spareParts/${id}/counter`, params);

export const getMetersTestReportAPI = data => {
  const { meterId, meterSerialNumber, resolution, timeStart, timeEnd, pageSize, endCursor } = data;
  return metersMicroservice().get("meters/testReports", {
    params: {
      meterId: meterId,
      meterSerialNumber: meterSerialNumber,
      resolution: resolution,
      timeStart: timeStart,
      timeEnd: timeEnd,
      pageSize: pageSize,
      pageAfter: endCursor,
    },
  });
};

export const getPendingStoveRepairListAPI = params => stoveMicroservice().get("stove-repair/pending", { params });

export const repairStoveAPI = ({ userName, params }) =>
  stoveMicroservice().post(`stove-repair&userName=${userName}`, params);

export const getMaintenanceReportsAPI = ({ pageSize = DEFAULT_PAGE_SIZE, ...data }) =>
  metersMicroservice().get("meters/reports/maintenance", { params: { ...data, pageSize } });

export const getUsersLegacyAPI = pageNumber =>
  operationsLegacyMicroservice().get("/users", {
    params: {
      pageSize: 10,
      pageNumber: pageNumber,
    },
  });

export const postPaymentLegacyAPI = data => pgLegacyMicroservice().post("/confirm", data);

export const getDepotInfoLegacyAPI = () =>
  operationsLegacyMicroservice().get("/locations", {
    params: {
      type: "DEPOT",
    },
  });

export const getPaymentsLegacyAPI = customerId =>
  operationsLegacyMicroservice().get("/payments", {
    params: {
      customerId,
      pageSize: "10",
      pageNumber: "1",
    },
  });

export const getRolesInfoLegacyAPI = () => operationsLegacyMicroservice().get("/users/roles");

export const getFilteredUsersLegacyAPI = ({ type, value, pageNumber }) => {
  let encodedValue = encodeURIComponent(value);
  if (type === "email") encodedValue = value;
  return operationsLegacyMicroservice().get(`/users/${encodedValue}`, {
    params: {
      idType: type,
      pageSize: 10,
      pageNumber: pageNumber,
    },
  });
};

export const updateUserLegacyAPI = (id, params) => operationsLegacyMicroservice().put(`/users/${id}`, params);

export const getLeadsLegacyAPI = ({ depot, searchType, value, pageNumber }) =>
  operationsLegacyMicroservice().get(`/leads/${value}`, {
    params: {
      depotReferenceId: depot,
      searchType,
      pageNumber,
      pageSize: 10,
    },
  });

export const getCustomerLegacyAPI = ({ customer, type }) =>
  operationsLegacyMicroservice().get(`/customer/${customer}`, {
    params: {
      idType: type,
    },
  });

export const getOutgoingLogsAPI = (params = {}) => {
  const { pageSize = DEFAULT_PAGE_SIZE, ...data } = params;
  return metersMicroservice().get("outgoingLogs", {
    params: { ...data, pageSize },
  });
};

//customers management APIs

export const fetchCustomerList = params => customersMicroservice().get("customers", { params: { ...params } });

export const retrieveLinkedAccounts = (params, id) =>
  customersMicroservice().get(`customers/${id}/linkedCustomers`, { params: { ...params } });

export const retrieveCustomerUpdateRequestsList = params =>
  customersMicroservice().get("customers/updateRequest", { params: { ...params } });

export const retrieveCustomerLocationUpdateRequestsList = params =>
  customersMicroservice().get("customers/updateLocationRequest", { params: { ...params } });

export const retrieveCustomerUpdateRequestsById = id => customersMicroservice().get(`customers/updateRequest/${id}`);

export const approveCustomerUpdateRequestsById = id =>
  customersMicroservice().put(`customers/updateRequest/${id}/approve`);

export const declineCustomerUpdateRequestsById = (id, reason) =>
  customersMicroservice().put(`customers/updateRequest/${id}/decline`, { reason });

export const retrieveCustomerUpdateLocationRequestsById = id =>
  customersMicroservice().get(`customers/updateLocationRequest/${id}`);

export const approveCustomerUpdateLocationRequestsById = (id, data) =>
  customersMicroservice().put(`customers/updateLocationRequest/${id}/approve`, data);

export const declineCustomerUpdateLocationRequestsById = (id, reason) =>
  customersMicroservice().put(`customers/updateLocationRequest/${id}/decline`, { reason });

export const createCustomerUpdateRequest = (id, params) =>
  customersMicroservice().post(`customers/${id}/updateRequest`, params);

export const createCustomerUpdateLocationRequest = (id, params) =>
  customersMicroservice().post(`customers/${id}/updateLocationRequest`, params);

export const assignDepotToCustomer = ({ id, ...props }) =>
  customersMicroservice().put(`customers/${id}/depot/assign`, { ...props });

export const assignDepotToCustomers = ({ uploadedData, depot, sector }) =>
  customersMicroservice().post(`customers/depot?newDepot=${depot}${sector ? `&sector=${sector}` : ""}`, uploadedData, {
    headers: {
      "Content-Type": "text/csv",
    },
  });

export const retrieveCustomerContractUrl = (id, file) =>
  customersMicroservice().get(`customers/${id}/contract`, {
    params: { file },
  });
export const retrieveCustomerPhotoUrlByType = (id, type, file = "") =>
  customersMicroservice().get(`customers/${id}/${type}${file ? `?file=${file}` : ""}`);

export const retrieveContractVersions = (params = {}) =>
  customersMicroservice().get(`contracts`, {
    params,
  });
export const fetchVersionById = version => customersMicroservice().get(`contracts/${version}`);

export const retrieveCustomerContractUploadUrl = extension =>
  customersMicroservice().get(`customers/contracts/url`, {
    params: { extension },
  });

export const retrieveCustomerVersionDownloadUrl = version =>
  customersMicroservice().get(`contracts/${version}/download`);

export const createContractVersion = data => customersMicroservice().post(`contracts`, data);

export const linkTSRToCustomer = ({ id, technician, technicianName }) =>
  customersMicroservice().put(`customers/${id}/technician/assign`, { technician, technicianName });

export const linkTSRToCustomers = ({ customerIds, technician, technicianName }) =>
  customersMicroservice().put(`customers/technician/bulkAssign`, {
    technician,
    technicianName,
    customerIds,
  });

export const fetchCustomerById = id => customersMicroservice().get(`customers/${id}`);

export const approveDeclineLead = (id, type, reason) =>
  customersMicroservice().put(`leads/${id}/${type}`, {
    reason,
  });

export const deleteLead = id => customersMicroservice().delete(`leads/${id}`);

export const bulkUpdateLeadsSector = data => customersMicroservice().put("leads/update", data);

//type: housePhotos, personalPhotos, upload/id
export const retrieveCustomerPhotoUploadUrlByType = (type, extension) =>
  customersMicroservice().get(`customers/${type}/url`, {
    params: { extension },
  });

export const customerDuplicateCheckRequest = (id, params) =>
  customersMicroservice("v2").post(`/customers/duplicate-check?id=${id}&otpTemplate=Default`, params);

export const updateLead = (id, data, isV3) => customersMicroservice(isV3 ? "v3" : "v2").put(`leads/${id}`, data);

export const reCreateCustomer = data => customersMicroservice().post(`customers`, data);

export const fetchCustomerTransactions = filter =>
  paymentsMicroservice("v2").get(`payments`, {
    params: {
      ...filter,
      from: moment.utc().subtract(12, "months").valueOf(),
      to: moment().utc().endOf("day").valueOf(),
    },
  });

export const fetchCustomerBalance = id =>
  paymentsMicroservice().get(`payments/balance`, {
    params: {
      customerId: id,
    },
  });

export const fetchCustomerCreditBalance = id =>
  paymentsMicroservice().get(`payments/credit/balance`, {
    params: {
      customerId: id,
    },
  });

export const fetchCustomerConsumptionStat = id => metersMicroservice().get(`/customer/${id}/consumptionStat`);

export const retrieveRefundRequestsAPI = options =>
  paymentsMicroservice().get(`refunds`, {
    params: options,
  });

export const retrieveRefundRequestPhotoUrlAPI = id => paymentsMicroservice().get(`/refunds/${id}/photo`);

export const postRefundRequestsAPI = ({ options, type }) => paymentsMicroservice().post(`refunds/${type}`, options);

export const retrieveCustomersTasksHistory = data =>
  taskMicroservice().get(`/tasks/history`, {
    params: { ...data },
  });

export const retrieveTasksArchive = data =>
  taskMicroservice().get(`/tasks/archive`, {
    params: { ...data },
  });

export const fetchStatsByDepot = depot => taskMicroservice().get(`tasks/stats/export?depot=${depot}`);

export const fetchTransactionDetails = id => paymentsMicroservice().get(`transaction/${id}`);

export const fetchUnprocessedPayments = ({ pageSize = DEFAULT_PAGE_SIZE, ...data }) =>
  paymentsMicroservice().get(`payments/unprocessed`, {
    params: { ...data, pageSize },
  });

export const discardUnprocessedPayment = ({ id }) => paymentsMicroservice().put(`payments/unprocessed/discard/${id}`);

export const fetchFailedPayments = ({ pageSize = DEFAULT_PAGE_SIZE, ...data }) =>
  paymentsMicroservice().get(`payments/failed`, {
    params: { ...data, pageSize },
  });

export const resolveFailedPayment = ({ id }) => paymentsMicroservice().put(`payments/failed/resolve/${id}`);

export const sendPaymentManually = ({ customerId, amount }) =>
  paymentsMicroservice().post("payments/manual", { customerId, amount });

export const confirmTransaction = transactionId => paymentsMicroservice().put(`payments/confirm/${transactionId}`);

//depots management APIs

export const fetchDepotsList = params => depotsMicroservice().get("depots", { params: { ...params } });

export const fetchDepotDetails = name => depotsMicroservice().get("depots/details", { params: name });

export const createDepot = data => depotsMicroservice().post("depots", data);

export const updateDepot = data => depotsMicroservice().put(`depots`, data);

export const fetchTeamsList = params => depotsMicroservice().get("depot/teams", { params: { ...params } });

export const fetchHistoryByType = (type, id) => historyManagement().get(`history/${type}/${id}?pageSize=1000`);

export const createTeam = data => depotsMicroservice().post("depot/teams", data);

export const deleteTeam = (team, depot) => depotsMicroservice().delete(`depot/teams/${team}?teamDepot=${depot}`);

export const addRemoveMembers = ({ team, depot, action, members }) =>
  depotsMicroservice().put(`depot/teams/${team}/members?teamDepot=${depot}`, {
    action,
    members,
  });
//packages APIs

export const getPackages = ({ ...params }) => packagesMicroservice().get("packages", { params: { ...params } });
export const addPackage = params => packagesMicroservice().post("packages", params);

export const createSegment = params => packagesMicroservice().post("segments", params);
export const editSegment = (params, id) => packagesMicroservice().put(`segments/${id}`, params);
export const getUploadSegmentUrl = () => customersMicroservice("v2").post(`priceSegments`);
export const getSegments = params => packagesMicroservice().get("segments", { params: { ...params } });
export const retrieveSegmentDetails = id => packagesMicroservice().get(`segments/${id}`);
export const deleteSegment = id => packagesMicroservice().delete(`segments/${id}`);

export const getPackageDetails = code => packagesMicroservice().get(`packages/${code}`);
export const editPackageDetails = ({ code, options }) => packagesMicroservice().put(`packages/${code}`, options);
export const fetchPackageDetailsById = id => packagesMicroservice().get(`packages/${id}`);

export const assignPackageToCustomer = ({ code, customerId }) =>
  packagesMicroservice().post(`packages/${code}/assignToCustomer`, { customerId });

export const getTSRList = depot =>
  taskMicroservice().get("/routines/opened/agents?pageSize=1000", { params: { depot } });

export const createUploadTemplate = data => configurationMicroservice().post("templates/meterUploadTemplate", data);

export const deleteUploadTemplate = templateName =>
  configurationMicroservice().delete(`templates/meterUploadTemplate/${templateName}`);

//TAM APIs

export const getTAMList = params => transactionAdjustmentMicroservice().get("requests", { params: { ...params } });

export const getTAMRequestById = id => transactionAdjustmentMicroservice().get(`requests/${id}`);

export const createTAMRequest = data => transactionAdjustmentMicroservice().post("requests", data);

export const declineTAMRequest = id => transactionAdjustmentMicroservice().post(`requests/${id}/decline`);

export const approveTAMRequest = id => transactionAdjustmentMicroservice().put(`requests/${id}/approve`);

export const createBulkTAMRequest = (reason, data) =>
  transactionAdjustmentMicroservice().post(`requests/bulk?reason=${reason}`, data, {
    headers: {
      "Content-Type": "text/csv",
    },
  });

export const getUsersList = params => userMicroservice().get("users", { params });
export const updateUserSector = params => userMicroservice().put("users/sector", params);
export const getUserByEmail = email => userMicroservice().get(`users/details`, { params: { email } });
export const retrieveUserLeaves = email => userMicroservice().get(`absences`, { params: { email } });
export const setUserUnavailability = (email, data) => userMicroservice().post("absences", data, { params: { email } });
export const cancelUserAbsence = id => userMicroservice().post(`absences/${id}`);
export const setUserTeam = data => userMicroservice().put("/users/team", data);

// STO APIs

export const retrieveSTOs = options =>
  assetMicroservice().get("/sto", {
    params: {
      ...options,
    },
  });

export const retrieveSTODetails = id => assetMicroservice().get(`/sto/${id}`);

export const createSTO = data => assetMicroservice().post("/sto", data);
export const updateSTO = (data, id) => assetMicroservice().put(`/sto/${id}`, data);

export const updateTripSTO = (data, tripNumber) => assetMicroservice().put(`/transfer/trips/${tripNumber}/stos`, data);

// Transport Management APIs

export const retrieveVehicles = options =>
  vehicleMicroservice().get("/vehicles", {
    params: {
      ...options,
    },
  });

export const bulkUploadVehicles = data => vehicleMicroservice().post("/vehicles/bulk", data);

// D2D APIs

export const retrieveTrips = options =>
  assetMicroservice().get("/transfer/trips", {
    params: {
      ...options,
    },
  });

//search pending and planned trips, ONLY for Plan a TRIP screen
export const retrievePendingTrips = options =>
  assetMicroservice().get("/transfer/trips/pending", {
    params: {
      ...options,
    },
  });

export const createTrip = data => assetMicroservice().post("/transfer/trips", data);
export const updateTrip = (tripNumber, data) => assetMicroservice().put(`/transfer/trips/${tripNumber}`, data);
export const retrieveTripDetails = id => assetMicroservice().get(`/transfer/trips/${id}`);
export const markTripAsPlanned = id => assetMicroservice().post(`/transfer/trips/${id}/planned`);

export const retrieveTripSeal = tripNumber => assetMicroservice().get(`/transfer/trips/${tripNumber}/seal`);
export const cancelTrip = (tripNumber, data) => assetMicroservice().put(`/transfer/trips/${tripNumber}/cancel`, data);
export const stopTrip = (tripNumber, data) => assetMicroservice().put(`/transfer/trips/${tripNumber}/stop`, data);
export const unassignSTO = (tripNumber, data) =>
  assetMicroservice().put(`/transfer/trips/${tripNumber}/stos/unassign`, data);

export const closeTrip = tripNumber => assetMicroservice().post(`/transfer/trips/${tripNumber}/close`);

export const planAssetTrip = (tripNumber, data) => assetMicroservice().post(`/transfer/trips/${tripNumber}/plan`, data);

//Loading Bays API
export const retrieveLoadingBays = options =>
  assetMicroservice().get("/transfer/loadBays", {
    params: {
      ...options,
    },
  });

export const retrieveLoadingBayDetails = id => assetMicroservice().get(`/transfer/loadBays/${id}`);
export const retrieveLoadingBayAssets = (id, params) =>
  assetMicroservice().get(`/transfer/loadBays/${id}/assets`, { params: { ...params } });
export const removeLoadingBay = id => assetMicroservice().delete(`/transfer/loadBays/${id}`);
export const createLoadingBay = data => assetMicroservice().post("/transfer/loadBays", data);
export const updateLoadingBay = (data, id) => assetMicroservice().put(`/transfer/loadBays/${id}`, data);
export const attachDetachLoadBayAssets = (id, data) => assetMicroservice().put(`transfer/loadBays/${id}/assets`, data);

//Survey Management
export const retrieveSurveysList = params => configurationMicroservice().get("surveys/list", { params });
export const retrieveSurveysListByIdOrName = params => configurationMicroservice().get("surveys", { params });
export const retrieveSurveysHistory = (name, depot) =>
  configurationMicroservice().get("surveys/history", { params: { name, depot } });

export const createSurvey = data => configurationMicroservice().post("surveys", data);

//Campaign Management
export const startCustomersCalculation = (type, data) =>
  campaignMicroservice().post(`campaigns/customers/calculate?type=${type}`, data);

export const getCalculatedStatus = id => campaignMicroservice().get(`campaigns/customers/calculation/${id}`);
export const getCampaignDetails = id => campaignMicroservice().get(`campaigns/${id}`);
export const createCampaign = (type, data) => campaignMicroservice().post(`campaigns?type=${type}`, data);
export const updateCampaign = (id, data) => campaignMicroservice().put(`campaigns/${id}`, data);
export const retrieveCampaignList = params => campaignMicroservice().get("campaigns", { params });

export const stopCampaign = (id, stoppingReason) =>
  campaignMicroservice().put(`campaigns/${id}/stop`, { stoppingReason });

export const createProspect = data => customersMicroservice().post("prospects", data);
export const archiveProspect = id => customersMicroservice().post(`prospects/${id}/archive`);

// SMS Bulk
export const getSmsBulkList = ({ ...params }) => smsMicroservice().get("messages/bulk", { params: { ...params } });

export const uploadBulkSMSCsv = data =>
  smsMicroservice().post("messages/bulk/upload", data, {
    headers: {
      "Content-Type": "text/csv",
    },
  });

export const downloadBulkSMSCsv = filename =>
  smsMicroservice().get("messages/bulk/download", {
    params: { filename },
  });

export const createBulkSMS = data => smsMicroservice().post("messages/bulk", data);
export const updateBulkSMS = (data, id, isSchedule = false) =>
  smsMicroservice().put(`messages/bulk/${id}?schedule=${isSchedule}`, data);

export const discardBulkSMS = id => smsMicroservice().put(`messages/bulk/${id}/discard`);
export const getBulkSMSDetails = id => smsMicroservice().get(`messages/bulk/${id}`);

export const getMarketingInitiatives = ({ pageSize = DEFAULT_PAGE_SIZE, ...data }) =>
  marketingMicroservice().get("initiatives", { params: { ...data, pageSize } });

export const createInitiative = data => marketingMicroservice().post("initiatives", data);
export const getInitiativeDetails = id => marketingMicroservice().get(`initiatives/${id}`);
export const getInitiativePhoto = id => marketingMicroservice().get(`initiatives/${id}/cover`);
export const cancelTerminateInitiative = id => marketingMicroservice().put(`initiatives/${id}/cancel`);

export const retrieveInitiativeUploadUrl = extension =>
  marketingMicroservice().get("initiatives/cover", {
    params: { extension },
  });

// stats

export const retrieveTasksSchedule = params => taskMicroservice().get("tasks/schedule", { params: { ...params } });
export const retrieveCustomerAvailabilityCalendar = params =>
  taskMicroservice().get("/customerAvalability/calendar", { params: { ...params } });

export const retrieveCustomersStats = () => customersMicroservice().get("customers/stats");

export const fetchConsumptionStat = () => metersMicroservice().get("/consumptionStat");
