import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Col, Container } from "reactstrap";
import { refundRequestListColumns } from "./refundRequestListColumns";
import { retrieveRefundRequestsAPI } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import RefundRequestFilter from "pages/operations/refundReports/refundRequestFilter";
import RefundRequestModal from "pages/operations/refundReports/RefundRequestModal";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const RefundRequestPage = () => {
  const [isLoading, setLoader] = useState(false);
  const [stovesList, setStovesList] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [itemDetails, setItemDetails] = useState(null);
  const { setFilters } = useStoreFilters(FILTER_PAGE.REFUND_REQUESTS);

  const hideRoles = [
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
    USER_ROLES.FINANCE,
  ];

  const openModal = useCallback(item => {
    setItemDetails(item);
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => setShowModal(false), []);

  useEffect(() => {
    handleFilter();
  }, []);

  const handleFilter = useCallback((options = {}) => {
    setFilters(options);
    setLoader(true);

    retrieveRefundRequestsAPI(options)
      .then(res => {
        setStovesList(res?.data);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(() => {
    const col = refundRequestListColumns(openModal);
    if (userHasOneOfRoles(hideRoles)) col.pop();
    return col;
  }, [hideRoles, AUTH_USER]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <RefundRequestFilter onFilter={handleFilter} />
          </div>
          <Col>
            <NGITable
              filtersType={FILTER_PAGE.REFUND_REQUESTS}
              columns={columns}
              data={stovesList}
              isLoading={isLoading}
              updateList={handleFilter}
            />
          </Col>
          {showModal && (
            <RefundRequestModal
              onClose={closeModal}
              isOpen={showModal}
              activeItem={itemDetails}
              onUpdate={handleFilter}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default RefundRequestPage;
