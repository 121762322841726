import React, { useCallback, useMemo, useState } from "react";
import { DatePicker, Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { filterOptions } from "pages/meter-management/firmware/firmwareUpdateProcesses/variables";
import { dateFormat } from "pages/TAM/TAMRequests/constants";
import { makeStyles } from "@material-ui/core/styles";
import { preparedFilterData } from "pages/meter-management/firmware/firmwareUpdateProcesses/utils";
import NGISelect from "components/Common/inputs/NGISelect";
import { useSelector } from "react-redux";
import { FirmwaresSettingsSelector } from "store/meter-management/configuration/firmware/selectors";

const { Option } = Select;
const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  root: { width: "250px!important" },
});

const ALLOWED_STATUSES = ["Published", "Draft"];

const FirmwareUpdateProcessesFilter = ({ handleFilter }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.FIRMWARE_UPDATE_PROCESSES);
  const [activeFilter, setActiveFilter] = useState(null);
  const { firmwares } = useSelector(FirmwaresSettingsSelector);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    const preparedData = preparedFilterData(values, values.type);
    handleFilter({ ...preparedData, pageSize });
  }, []);

  const handleFilterChange = useCallback(
    item => {
      setActiveFilter(item);
      form.setFieldsValue({ new: null, old: null });
    },
    [form]
  );

  const newFilteredFirmwares = useMemo(() => firmwares.filter(item => ALLOWED_STATUSES.includes(item.status)), []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select showSearch onChange={handleFilterChange} placeholder={"Select type"} allowClear>
            {filterOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {(activeFilter === "old" || activeFilter === "both") && (
          <>
            <div className="filter-divider" />
            <Form.Item rules={[{ required: true, message: "Please select value" }]} name="old">
              <NGISelect showSearch className={classes.root} placeholder="Please select old firmware">
                {!!firmwares &&
                  firmwares.map(item => (
                    <Option value={item.version} key={item.version}>
                      {item.version}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
            {activeFilter === "both" && <div className="filter-divider" />}
          </>
        )}

        {(activeFilter === "new" || activeFilter === "both") && (
          <>
            <div className="filter-divider" />
            <Form.Item rules={[{ required: true, message: "Please select value" }]} name="new">
              <NGISelect showSearch className={classes.root} placeholder="Please select new firmware">
                {!!newFilteredFirmwares &&
                  newFilteredFirmwares.map(item => (
                    <Option value={item.version} key={item.version}>
                      {item.version}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
            {activeFilter === "both" && <div className="filter-divider" />}
          </>
        )}

        {activeFilter === "processId" && (
          <>
            <div className="filter-divider" />
            <Form.Item rules={[{ required: true, message: "Please select value" }]} name="processId">
              <Input disablePattern placeholder="Enter value" />
            </Form.Item>
          </>
        )}

        <Form.Item name="date" className={classes.root}>
          <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
        </Form.Item>

        <CustomButton size="small" type="primary" htmlType="submit" text="Search" />
      </Form>
    </div>
  );
};

export default FirmwareUpdateProcessesFilter;
