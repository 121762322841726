import React, { useCallback, useEffect, useState } from "react";
import { Col, Modal, Row, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { TASK_STATUS, TASK_STATUSES_OPTIONS } from "pages/operations/task-requests/constants";
import CustomLink from "components/Common/buttons/CustomLink";
import infoIcon from "assets/images/svg/infoIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import {
  approveTaskApi,
  cancelTaskApi,
  fetchCustomerBalance,
  fetchCustomerById,
  fetchCustomerConsumptionStat,
  retrieveAllAssets,
  retrieveAssets,
} from "utils/api";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import moment from "moment";
import { dateAgo, extractPromisesResult } from "utils/helpers/functions";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import classnames from "classnames";
import { USER_CURRENCY, USER_ROLES } from "utils/constants";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
    padding: "20px 0",
  },
  container: {
    display: "flex",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  statusTitle: {
    marginRight: 10,
    color: "#666666",
    fontSize: 11,
  },
  subTitle: {
    marginRight: 5,
    color: "#666666",
    fontSize: 13,
  },
  link: {
    textDecoration: "underline!important",
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    color: "#FF8A00",

    "&> img": {
      marginRight: 10,
    },
  },
  taskType: {
    display: "flex",
    flexDirection: "column",
  },
  marginLeft: {
    marginLeft: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  sectionTitle: {
    marginTop: 15,
    marginBottom: 10,
    color: "#666666",
  },
  infoMessage: {
    color: "#666666",
    marginLeft: 10,
    fontStyle: "italic",
    fontWeight: 300,
  },
  infoSection: {
    padding: "20px 0",
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
  },
  infoTitle: {
    fontWeight: 600,
  },
  infoRow: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  infoName: {
    fontWeight: 300,
    color: "#666666",
  },
  infoValue: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,

    "&> span": {
      marginLeft: 5,
      fontWeight: 300,
      color: "#666666",
    },
  },
  green: {
    color: "#00b050",
  },
  red: {
    color: "#ff4d4f",
  },
});

const ViewTaskRequestModal = ({ onClose, update, activeItem }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(TASK_STATUS.APPROVE);
  const [taskType, setTaskType] = useState(activeItem.category);
  const [reason, setReason] = useState();
  const [subReason, setSubReason] = useState();
  const [additionalData, setAdditionalData] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    list: { taskCategory, meterChangeCompleteTaskReasonsV2, meterChangeDeclineTaskReasons },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_CHANGE_REASONS_V2));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_CHANGE_DECLINE_REASONS));
  }, []);

  const hideCategories = ["New Installation", "Asset Collection", "Stove Attachment"];

  const filteredTaskCategory = taskCategory?.filter(item => !hideCategories.includes(item));

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      fetchCustomerById(activeItem.customerId),
      retrieveAssets({ customerId: activeItem.customerId }),
      retrieveAllAssets({ customerId: activeItem.customerId }),
      fetchCustomerBalance(activeItem.customerId),
      fetchCustomerConsumptionStat(activeItem.customerId),
    ]).then(data => {
      const [customerInfo, assetsHistory, customerAssets, customerBalance, consumptionStat] =
        extractPromisesResult(data);
      const lastCylinderChange = assetsHistory?.data?.result?.items?.find(item => item.typeGroup === "Cylinder");
      const { firstName, lastName, installationDate } = customerInfo?.data?.result;
      setIsLoading(false);
      setAdditionalData({
        customerName: `${firstName} ${lastName}`,
        installationDate,
        lastCylinderChange: lastCylinderChange?.updatedAt && moment().diff(lastCylinderChange?.updatedAt, "days"),
        customerAssets: customerAssets.data?.result?.items,
        customerBalance: customerBalance.data?.result?.items[0],
        consumptionStat: consumptionStat?.data?.result,
      });
    });
  }, [activeItem]);

  const approveTask = useCallback(() => {
    closeConfirmationModal();
    setIsLoading(true);
    const approvalReason = reason ? JSON.parse(reason).item : "";
    const data = {
      category: taskType,
      ...(approvalReason && {
        approvalReason: `${approvalReason ? approvalReason : ""}${subReason ? `>${subReason}` : ""}`,
      }),
    };

    approveTaskApi(activeItem.id, data)
      .then(() => {
        successMessage("Task Request is approved. Task status is Assigned.");
        setIsLoading(false);
        setTimeout(() => update(), 500);
        onClose();
      })
      .catch(err => {
        setIsLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [activeItem, reason, subReason, taskType]);

  const handleSave = useCallback(() => {
    switch (selectedType) {
      case TASK_STATUS.APPROVE:
        if (taskType !== activeItem.category) {
          openConfirmationModal();
        } else {
          approveTask();
        }
        break;
      case TASK_STATUS.CANCEL:
        setIsLoading(true);
        cancelTaskApi({ id: activeItem.id, comments: "Task Declined", reason: "Not Relevant" })
          .then(() => {
            successMessage("Task Request is declined. Task status is Cancelled.");
            setIsLoading(false);
            setTimeout(() => update(), 500);
            onClose();
          })
          .catch(err => {
            setIsLoading(false);
            defaultNgiErrorMethod(err);
          });
        break;
      default:
        break;
    }
  }, [selectedType, taskType, activeItem, approveTask]);

  const handleChange = useCallback(value => setSelectedType(value), []);
  const handleTypeChange = useCallback(value => setTaskType(value), []);

  const handleReasonChange = useCallback(value => {
    setSubReason(undefined);
    setReason(value);
  }, []);
  const handleSubReasonChange = useCallback(value => setSubReason(value), []);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            TASK REQUEST
            <span>
              <span className={classes.subTitle}>CUSTOMER</span>
              <CustomLink className={classes.link} target="_blank" to={`/customers/customer/${activeItem.customerId}`}>
                {additionalData?.customerName}
              </CustomLink>
            </span>
          </div>
          <div>
            <span className={classes.statusTitle}>TASK STATUS</span>
            <NGISelect showSearch value={selectedType} onChange={handleChange} placeholder="Please select status">
              {Object.keys(TASK_STATUSES_OPTIONS).map(item => (
                <Option value={item} key={item}>
                  {TASK_STATUSES_OPTIONS[item]}
                </Option>
              ))}
            </NGISelect>
          </div>
        </div>
      }
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <div className={classes.infoBox}>
            <img src={infoIcon} alt="infoIcon" />
            In order to minimize the scam every client history should be checked before approving manual tasks.
          </div>

          <div className={classes.sectionTitle}>Task Type</div>
          <Row className={classes.row}>
            <Col lg={12}>
              <div className={classes.taskType}>
                <NGISelect
                  showSearch
                  disabled={selectedType === TASK_STATUS.CANCEL}
                  value={taskType}
                  onChange={handleTypeChange}
                  placeholder="Please select task type"
                >
                  {filteredTaskCategory &&
                    filteredTaskCategory?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </NGISelect>
              </div>
            </Col>
            <Col lg={12}>
              <div className={classes.infoMessage}>You can change the task type if needed.</div>
            </Col>
          </Row>
          {taskType === "Meter Change" && (
            <>
              <Row className={classes.row}>
                <Col lg={12}>
                  <div className={classes.sectionTitle}>
                    {selectedType === TASK_STATUS.APPROVE ? "Approve Reason" : "Decline Reason"}
                  </div>
                  <div className={classes.taskType}>
                    {selectedType === TASK_STATUS.APPROVE ? (
                      <NGISelect
                        showSearch
                        value={reason}
                        onChange={handleReasonChange}
                        placeholder="Please select reason"
                      >
                        {meterChangeCompleteTaskReasonsV2 &&
                          meterChangeCompleteTaskReasonsV2?.map(item => (
                            <Option key={item.item} value={JSON.stringify(item)}>
                              {item.item}
                            </Option>
                          ))}
                      </NGISelect>
                    ) : (
                      <NGISelect
                        showSearch
                        value={reason}
                        onChange={handleReasonChange}
                        placeholder="Please select reason"
                      >
                        {meterChangeDeclineTaskReasons &&
                          meterChangeDeclineTaskReasons?.map(item => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                      </NGISelect>
                    )}
                  </div>
                </Col>
                {selectedType === TASK_STATUS.APPROVE && (
                  <Col lg={12}>
                    <div className={classnames(classes.taskType, classes.marginLeft)}>
                      <div className={classes.sectionTitle}>Sub-Reason</div>
                      <NGISelect
                        showSearch
                        value={subReason}
                        onChange={handleSubReasonChange}
                        placeholder="Please select sub-reason"
                      >
                        {reason &&
                          JSON.parse(reason)?.items?.map(item => (
                            <Option key={item.item} value={item.item}>
                              {item.item}
                            </Option>
                          ))}
                      </NGISelect>
                    </div>
                  </Col>
                )}
              </Row>
              <div className={classes.infoSection}>
                <Row gutter={[24, 8]}>
                  <Col lg={12}>
                    <div className={classes.infoRow}>
                      <div className={classes.infoName}>Initiate Reasons </div>
                      <div className={classes.infoValue}>{textFormatter(activeItem.initiateReasons)}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          <div className={classes.infoSection}>
            <div className={classes.infoTitle}>Last 30 Days</div>
            <Row gutter={[24, 8]}>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Total Payments</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.consumptionStat?.totalPaymentLast30Days
                        ? `${additionalData?.consumptionStat?.totalPaymentLast30Days} KSH`
                        : ""
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Total Consumption</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.consumptionStat?.totalConsumptionLast30Days
                        ? `${additionalData?.consumptionStat?.totalConsumptionLast30Days} Kg`
                        : ""
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12} />
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Outstanding Amount</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.customerBalance?.outstandingAmount
                        ? `${additionalData?.customerBalance?.outstandingAmount} ${USER_CURRENCY}`
                        : ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className={classes.infoSection}>
            <div className={classes.infoTitle}>Last 6 Months</div>
            <Row gutter={[24, 8]}>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Average Payment</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.consumptionStat?.averagePaymentLast6Month
                        ? `${additionalData?.consumptionStat?.averagePaymentLast6Month} KSH`
                        : ""
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Average Consumption</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.consumptionStat?.averageConsumptionLast6Month
                        ? `${additionalData?.consumptionStat?.averageConsumptionLast6Month} Kg`
                        : ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {!!additionalData?.customerAssets?.length && (
            <div className={classes.infoSection}>
              <div className={classes.infoTitle}>Assets</div>
              <Row gutter={[24, 8]}>
                {additionalData?.customerAssets.map(item => (
                  <Col lg={12} key={item.barcode}>
                    <div className={classes.infoRow}>
                      <div className={classes.infoName}>{item.typeGroup}</div>
                      <div className={classes.infoValue}>
                        <CustomLink
                          underlined
                          target="_blank"
                          to={`/warehouse/assets/${item.serialNumber}?t=serialNumber`}
                        >
                          {item.barcode}
                        </CustomLink>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}

          <div className={classes.infoSection}>
            <div className={classes.infoTitle}>Meter Data</div>
            <Row gutter={[24, 8]}>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Last Connected</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.consumptionStat?.meterLastConnectedTime
                        ? customDateFormatWithTime(additionalData?.consumptionStat?.meterLastConnectedTime)
                        : ""
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Battery Level</div>
                  <div
                    className={classnames(
                      classes.infoValue,
                      additionalData?.consumptionStat?.batteryLevel >= 20 ? classes.green : classes.red
                    )}
                  >
                    {textFormatter(
                      additionalData?.consumptionStat?.batteryVoltage && additionalData?.consumptionStat?.batteryLevel
                        ? `${additionalData?.consumptionStat?.batteryVoltage}V (${additionalData?.consumptionStat?.batteryLevel}%)`
                        : ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className={classes.infoSection}>
            <div className={classes.infoTitle}>Cylinder Data</div>
            <Row gutter={[24, 8]}>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Gas Level</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.consumptionStat?.gasLevel ? `${additionalData?.consumptionStat?.gasLevel} Kg` : ""
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Last Cylinder Change</div>
                  <div className={classes.infoValue}>
                    {additionalData?.lastCylinderChange ? (
                      <>{textFormatter(`${additionalData?.lastCylinderChange} days ago`)}</>
                    ) : (
                      textFormatter(additionalData?.lastCylinderChange)
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 8]}>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Age As Customer</div>
                  <div className={classes.infoValue}>
                    {textFormatter(additionalData?.installationDate ? dateAgo(additionalData?.installationDate) : "")}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className={classes.infoRow}>
                  <div className={classes.infoName}>Spent Since Last Cylinder Change</div>
                  <div className={classes.infoValue}>
                    {textFormatter(
                      additionalData?.consumptionStat?.amountSpentSinceLastCylinderChange
                        ? `${additionalData?.consumptionStat?.amountSpentSinceLastCylinderChange} KSH`
                        : ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
      <Space>
        <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
        <CustomButton
          roles={activeItem.category === "Asset Swap" && [USER_ROLES.OPERATION_MANAGERS]}
          onClick={handleSave}
          disabled={!selectedType}
          type="primary"
          size="small"
          text="Confirm"
        />
      </Space>
      {isConfirmationOpen && (
        <ConfirmationModal
          onCancel={closeConfirmationModal}
          onConfirm={approveTask}
          description="Are you sure you want to change the task type and approve the request?"
        />
      )}
    </Modal>
  );
};

export default ViewTaskRequestModal;
