import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Col, Container } from "reactstrap";
import { deleteSegment, getSegments } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { priceManagementListColumns } from "pages/price-management/PriceManagement/priceMenegementListColumns";
import CustomButton from "components/Common/buttons/CustomButton";
import CustomLink from "components/Common/buttons/CustomLink";
import PriceManagementFilter from "pages/price-management/PriceManagement/priceManagementFilter";
import { makeStyles } from "@material-ui/core/styles";
import classname from "classnames";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";

const useStyles = makeStyles({
  filters: { display: "flex", justifyContent: "space-between" },
});

const SegmentManagementPage = () => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [isDeleteConfirmation, setDeleteConfirmation] = useState(null);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.PRICE);
  const [list, setList] = useState([]);
  const roles = [USER_ROLES.A_SM, USER_ROLES.SYSTEM_FULL_ADMINS, USER_ROLES.FINANCE];

  useEffect(() => {
    handleFilter(!isEmpty(filters) ? filters : {});
  }, []);

  const handleFilter = useCallback((options = {}) => {
    setFilters(options);
    setLoader(true);
    getSegments(options)
      .then(res => {
        setList(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const openDeleteConfirmation = useCallback(id => setDeleteConfirmation(id), []);
  const closeDeleteConfirmation = useCallback(() => setDeleteConfirmation(null), []);
  const onDelete = useCallback(() => {
    setLoader(true);
    deleteSegment(isDeleteConfirmation)
      .then(() => {
        successMessage("Segment was deleted successfully");
        closeDeleteConfirmation();
        handleFilter(!isEmpty(filters) ? filters : {});
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [isDeleteConfirmation]);

  const columns = useMemo(() => priceManagementListColumns(openDeleteConfirmation), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className={classname(classes.filters, "filters-wrapper")}>
            <CustomLink to={`segment/new`}>
              <CustomButton
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="New Segment"
                roles={roles}
              />
            </CustomLink>
            <PriceManagementFilter onFilter={handleFilter} isLoading={isLoading} />
          </div>
          <Col>
            <NGITable
              filtersType={FILTER_PAGE.PRICE}
              columns={columns}
              data={list}
              isLoading={isLoading}
              updateList={handleFilter}
            />
          </Col>
          {!!isDeleteConfirmation && (
            <ConfirmationModal
              description={"Are you sure you want to delete this segment?"}
              onCancel={closeDeleteConfirmation}
              onConfirm={onDelete}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default SegmentManagementPage;
