import React, { useCallback, useState } from "react";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import NGISelect from "components/Common/inputs/NGISelect";
import { filterOptions, FILTERS, statuses, types } from "./variables";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const RefundRequestFilter = ({ onFilter }) => {
  const [form] = Form.useForm();
  const [activeFilter, setFilter] = useState(null);
  const { getFilters } = useStoreFilters(FILTER_PAGE.REFUND_REQUESTS);

  const onFinish = useCallback(values => {
    const { pageSize } = getFilters();
    onFilter({ [values?.type]: values?.value?.trim(), pageSize });
  }, []);

  const handleFilterChange = useCallback(
    value => {
      setFilter(value);
      form.setFieldsValue({
        value: null,
      });
    },
    [form]
  );

  return (
    <>
      <div className="custom-filter">
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
          <Form.Item name="type">
            <NGISelect
              showSearch
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              size="default"
              placeholder="Select type"
              allowClear
              onChange={handleFilterChange}
            >
              {filterOptions.length > 0 &&
                filterOptions.map(item => (
                  <Option key={item} value={item.value}>
                    {item.name}
                  </Option>
                ))}
            </NGISelect>
          </Form.Item>
          {!!activeFilter && <div className="filter-divider" />}
          {activeFilter === FILTERS.CUSTOMER && (
            <Form.Item name="value">
              <Input placeholder="Enter value" pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          )}
          {activeFilter === FILTERS.STATUS && (
            <Form.Item name="value">
              <NGISelect
                showSearch
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                size="default"
                placeholder="Select status"
                allowClear
              >
                {statuses.length > 0 &&
                  statuses.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
          )}
          {activeFilter === FILTERS.TYPE && (
            <Form.Item name="value">
              <NGISelect
                showSearch
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                size="default"
                placeholder="Select type"
                allowClear
              >
                {types.length > 0 &&
                  types.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </NGISelect>
            </Form.Item>
          )}

          <CustomButton type="primary" htmlType="submit" text="Filter" size="small" />
        </Form>
      </div>
    </>
  );
};

export default RefundRequestFilter;
