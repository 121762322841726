import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import CustomButton from "components/Common/buttons/CustomButton";
import { Form, Spin } from "antd";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { createProspect, fetchDepotsList } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import ProspectInformation from "pages/operations/prospectDetails/ProspectInformation";
import { getTrimmedObjectValues, randomColor } from "utils/helpers/functions";
import { useHistory } from "react-router";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 10,
    },
  },
  show: {
    display: "block",
  },
});

const DataTabs = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [address, setAddress] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [allDepotsList, setAllDepotsList] = useState(null);
  const [foundCustomer, setFoundCustomer] = useState(null);

  const onSave = useCallback(async () => {
    try {
      await form.validateFields();
      setLoader(true);
      closeSaveModal();
      const { firstName, lastName, phone, houseNumber, refSource, refValue } = form.getFieldsValue();
      const { street, prospectDepot, lat, lon } = address;
      if (!houseNumber) {
        form.setFields([
          {
            name: "houseNumber",
            errors: ["Address should include house number."],
          },
        ]);
      }
      if (!lat || !lon) {
        form.setFields([
          {
            name: "address",
            errors: ["No lat/lon. Please pick the address on the map."],
          },
        ]);
      }
      if (!foundCustomer && refSource === "Customer") {
        form.setFields([
          {
            name: "refValue",
            errors: ["Referral Value is not found. No matches with existing customers."],
          },
        ]);
        setLoader(false);
        return;
      }

      if (firstName && lastName && phone && street && houseNumber && lat && lon && refSource) {
        const dataToSave = getTrimmedObjectValues({
          firstName,
          lastName,
          phone,
          prospectDepot,
          location: {
            street,
            houseNumber,
            lat,
            lon,
          },
          refValue: refValue ? refValue : "N/A",
          refSource,
        });
        createProspect(dataToSave)
          .then(async () => {
            setLoader(false);
            successMessage(`Prospect was created successfully`);
            history.push("/operations/prospects");
          })
          .catch(e => {
            setLoader(false);
            defaultNgiErrorMethod(e);
          });
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      closeSaveModal();
    }
  }, [form, address, foundCustomer]);

  const openSaveModal = useCallback(() => setShowSaveModal(true), []);
  const closeSaveModal = useCallback(() => setShowSaveModal(false), []);

  useEffect(() => {
    fetchDepotsList({
      pageSize: 1000,
    }).then(res => {
      const {
        result: { items },
      } = res?.data;
      const list = items?.map(item => ({
        id: item.name,
        district: item.location.subCounty,
        region: item.location.county,
        color: randomColor(),
        poly: item.boundaries.coordinates[0].map(item => [item[1], item[0]]),
      }));
      setAllDepotsList(list || []);
    });
    dispatch(getDictionary(DICTIONARY_TYPES.LEAD_SOURCES));
  }, []);

  return (
    <div className={classes.root}>
      <Spin spinning={isLoading}>
        <Card className={classes.container}>
          <CardBody>
            <ProspectInformation
              allDepotsList={allDepotsList}
              editForm={form}
              setAddress={setAddress}
              address={address}
              setFoundCustomer={setFoundCustomer}
              foundCustomer={foundCustomer}
            />
            <div className={classes.buttonsContainer}>
              <CustomButton type="primary" text="Save" size="small" onClick={openSaveModal} />
            </div>
          </CardBody>
        </Card>
      </Spin>
      {showSaveModal && (
        <ConfirmationModal
          onCancel={closeSaveModal}
          onConfirm={onSave}
          description="Are you sure you want to create new prospect?"
        />
      )}
    </div>
  );
};

export default DataTabs;
