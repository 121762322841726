import React from "react";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { textFormatter } from "components/Common/formatter";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const hideRoles = [
  USER_ROLES.PRODUCT_TECHNICIANS,
  USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
  USER_ROLES.IT_OPERATIONS,
  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
];

export const partsListColumns = handleItemClick => [
  {
    title: "SPARE PART ID",
    dataIndex: "id",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "NAME",
    dataIndex: "name",
    sort: true,
    render: (cellContent, item) =>
      userHasOneOfRoles(hideRoles) ? (
        <div>{textFormatter(cellContent)}</div>
      ) : (
        <Tooltip placement="bottom" title={`${cellContent} details/edit`}>
          <div onClick={() => handleItemClick(item)}>
            <CustomLink to={`spare-part/${item.id}`}>{textFormatter(cellContent)}</CustomLink>
          </div>
        </Tooltip>
      ),
  },
  {
    title: "CATEGORY",
    dataIndex: "category",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "SPECIFICATION",
    dataIndex: "specification",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Minimum Order Quantity",
    dataIndex: "minimumOrderQuantity",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "IN STOCK",
    dataIndex: "count",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
];
