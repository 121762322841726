import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import configureIcon from "assets/images/svg/Configure.svg";
import { USER_ROLES } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";

export const awaitingConfigMeterListColumns = ({ openMeterConfigurationModal }) => [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    render: cellContent =>
      cellContent !== "UNKNOWN" ? (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${cellContent}?t=serialNumber`}>
          {cellContent}
        </CustomLink>
      ) : (
        cellContent
      ),
    sort: true,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    title: "Сollected date",
    dataIndex: "collectedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    dataIndex: "",
    title: "",
    width: "70px",
    render: (text, record) => (
      <ButtonGroup
        hideRoles={[USER_ROLES.IT_OPERATIONS]}
        tooltipTitle="Meter Configuration"
        icon={configureIcon}
        handleClick={() => openMeterConfigurationModal(record)}
      />
    ),
  },
];
