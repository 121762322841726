import React from "react";
import { textFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { segmentWithColor } from "pages/price-management/PriceManagement/utils";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import calendarIcon from "assets/images/svg/calendarIcon.svg";
import { AUTH_USER, USER_ROLES } from "utils/constants";

const color = "#444444";

export const userListColumns = openUnavailabilityModal => [
  {
    title: "Name",
    dataIndex: "name",
    render: (cellContent, row) => (
      <Tooltip placement="bottom" title={`${cellContent} details`} color={color}>
        <div>
          <CustomLink to={`/user/${row.email}`}>{textFormatter(cellContent)}</CustomLink>
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: segmentWithColor,
  },
  {
    title: "Email",
    dataIndex: "email",
    render: textFormatter,
  },
  {
    title: "Role",
    dataIndex: "roles",
    render: cellContent => cellContent?.join(", "),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: (cellContent, row) => (
      <ButtonGroup
        roles={[
          USER_ROLES.SYSTEM_FULL_ADMINS,
          USER_ROLES.OPERATION_MANAGERS,
          USER_ROLES.A_SM,
          USER_ROLES.SALES_SUPPORT,
          USER_ROLES.TSS,
        ]}
        disabled={row.depot !== AUTH_USER?.depotName}
        tooltipTitle="Set User unavailability"
        icon={calendarIcon}
        handleClick={() => openUnavailabilityModal(row)}
      />
    ),
  },
];
