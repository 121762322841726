import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButtonLink from "components/Common/buttons/BackButtonLink";
import classnames from "classnames";
import { useLocation } from "react-router";
import { getFormattedDataByName } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  mainInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #00C2FF 0%, #0085FF 100%)",
    boxShadow: "0 8px 16px 0 #00000014",
    borderRadius: 100,
    height: 60,
    marginLeft: 20,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",

    "& img": {
      width: 20,
    },

    "& :hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 23,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginRight: 4,
    },
  },
  status: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: "#17C347",
    textTransform: "uppercase",
    padding: "3px 8px",
    borderRadius: 6,
    letterSpacing: "0.05em",
  },
  section: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    width: 180,

    "& img": {
      width: 30,
      marginRight: 10,
    },
  },
  title: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 300,
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
  },
  overflowText: {
    overflow: "hidden",
    width: 150,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    height: "100%",
    width: 2,
    backgroundColor: "#0000000F",
  },
  rightContainer: {
    display: "flex",
    marginTop: 15,
  },
});

const Header = ({ survey }) => {
  const classes = useStyles();
  const searchParams = useLocation().search;
  const backUrl = new URLSearchParams(searchParams).get("backUrl");
  const backButtonUrl = useMemo(() => (backUrl ? backUrl : "/settings/surveys"), [backUrl]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={backButtonUrl}>Back</BackButtonLink>

        <div className={classes.nameContainer}>
          {survey ? (
            <>
              <div>
                <span>ID</span>
                {survey.id}
              </div>
              {survey.status && <div className={classes.status}>{survey.status}</div>}
            </>
          ) : (
            <span>Create New Survey</span>
          )}
        </div>
      </div>

      {survey && (
        <div className={classes.rightContainer}>
          <div className={classes.mainInfo}>
            <div className={classes.section}>
              <div>
                <div className={classes.title}>Updated By</div>
                <div className={classnames(classes.data, classes.overflowText)} title={"Updated By"}>
                  {survey.createdBy ? survey.createdBy : "-"}
                </div>
              </div>
            </div>
            <div className={classes.divider} />

            <div className={classes.section}>
              <div>
                <div className={classes.title}>Last Active</div>
                <div className={classes.data}>{getFormattedDataByName("createdAt", survey.createdAt)}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
