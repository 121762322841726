import { boolFormatter, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import React from "react";

export const EDITABLE_FIELDS = {
  Stove: ["barcode"],
  Meter: ["qrCode", "barcode", "serialNumber"],
  Cylinder: ["tareWeight", "supplier", "duplicateCorrectionCode", "manufactured", "qrCode", "barcode"],
};

export const DISABLED_EMPTY_FIELDS = ["barcode", "qrCode"];

export const SORTED_KEYS = {
  Meter: [
    "state",
    "barcode",
    "serialNumber",
    "qrCode",
    "photos",
    "foundAssetId",
    "foundDuplicateAssetId",
    "duplicates",
  ],
  Cylinder: [
    "state",
    "barcode",
    "qrCode",
    "serialNumber",
    "supplier",
    "manufactured",
    "tareWeight",
    "duplicateCorrectionCode",
    "photos",
    "foundAssetId",
    "foundDuplicateAssetId",
    "duplicates",
  ],
  Stove: ["state", "barcode", "stoveType", "photos", "foundAssetId", "foundDuplicateAssetId", "duplicates"],
};

export const hideEmptyFields = ["photos", "foundAssetId", "foundDuplicateAssetId", "duplicates"];

export const STATES_MAPPING = {
  Validated: "validated",
  Failed: "failed",
  "Not Validated": "notValidated",
};

export const VALIDATION_RULES = {
  Meter: {
    serialNumber: [
      {
        pattern: /^[A-Z0-9-]+$/,
        message: "Please match the requested format. Only -, A-Z or 0-9 characters are allowed.",
      },
      {
        max: 30,
        message: "The max length is 30",
      },
    ],
  },
  Cylinder: {
    duplicateCorrectionCode: [
      {
        pattern: /^[1-9A-Z]$/,
        message: "Please match the requested format. Only 1 character of range [1-9] or [A-Z] is allowed.",
      },
    ],
  },
};

export const TITLES = {
  Stove: {
    barcode: "Barcode/QRcode",
  },
};

export const duplicatesListColumns = [
  {
    title: "Duplicated By",
    dataIndex: "duplicateBy",
    render: cellContent => textFormatter(cellContent.join(", ")),
  },
  {
    title: "Depot",
    dataIndex: "depot",
  },
  {
    title: "Assigned To",
    dataIndex: "assignedTo",
  },
  {
    title: "Customer Id",
    dataIndex: "customerId",
    render: id => (
      <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
        {id}
      </CustomLink>
    ),
  },
  {
    title: "Can Resolve",
    dataIndex: "canResolve",
    render: boolFormatter,
  },
  {
    title: "Validated",
    dataIndex: "isValidated",
    render: boolFormatter,
  },
];

export const STATUSES = {
  PENDING: "Pending",
  IN_PROGRESS: "InProgress",
  COMPLETED: "Completed",
  REJECTED: "Rejected",
};

export const ASSET_STATES = {
  NOT_VALIDATED: "Not Validated",
  VALIDATED: "Validated",
  FAILED: "Failed",
};
