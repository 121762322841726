import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import CustomButton from "components/Common/buttons/CustomButton";
import { createQRCodeApi, downloadFileData } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { generateXLS } from "utils/helpers/functions";

const AddQRCodesModal = ({ onCancel, isOpen }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);

  const onDownload = useCallback(link => {
    downloadFileData(link)
      .then(res => {
        const normalizedData = res.data.map(item => [item]);
        generateXLS(normalizedData, "QR Codes", "xlsx", true);
      })
      .catch(error => {
        defaultNgiErrorMethod(error);
      });
  }, []);

  const handleSave = useCallback(values => {
    setLoader(true);
    createQRCodeApi(parseInt(values.count))
      .then(res => {
        setLoader(false);
        const {
          data: { result },
        } = res;
        successMessage(
          "QR codes were created successfully",
          0,
          <CustomButton color="outlined" size="small" onClick={() => onDownload(result.link)} text="Download" />
        );
        onCancel();
      })
      .catch(error => {
        setLoader(false);
        defaultNgiErrorMethod(error);
      });
  }, []);

  return (
    <Modal open={isOpen} title="Generate QR Codes" onCancel={onCancel} closable={false} footer={[]}>
      <Spin spinning={isLoading}>
        <NGIForm name="addQRCode" form={form} onFinish={handleSave}>
          <Col span={24}>
            <Title level={5}>Count</Title>
            <NGIFormItem
              name="count"
              rules={[
                {
                  required: true,
                  validator: async (_, value) => {
                    if (parseInt(value) > 1000) {
                      return Promise.reject(new Error("Value must be less than or equal to 1000"));
                    } else if (parseInt(value) < 1) {
                      return Promise.reject(new Error("Number of QR codes to be generated is required."));
                    }
                  },
                },
              ]}
            >
              <NGIInput type={"number"} min={1} max={1000} placeholder="Enter No. of QR codes (Max 1000)" />
            </NGIFormItem>
          </Col>
          <Space>
            <CustomButton onClick={onCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Generate" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default AddQRCodesModal;
