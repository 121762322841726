import React from "react";
import { camelCaseTextFormatter, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";
import NGITooltip from "components/Common/utils/NGITooltip";

const hideRoles = [USER_ROLES.TSS];
export const prospectListColumns = () => [
  {
    title: "Name",
    dataIndex: "",
    render: row => {
      const fullName = `${row.firstName} ${row.lastName}`;
      return userHasOneOfRoles(hideRoles) ? (
        textFormatter(fullName)
      ) : (
        <NGITooltip placement="bottom" title={`${row.firstName} ${row.lastName} details`}>
          <CustomLink to={`prospect/${row.id}`}>{textFormatter(fullName)}</CustomLink>
        </NGITooltip>
      );
    },
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: textFormatter,
    sort: true,
  },
  {
    title: "Address",
    dataIndex: "location",
    render: cellContent => `${cellContent?.street}, ${cellContent?.houseNumber}`,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: cellContent =>
      cellContent?.length ? (
        <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
          {cellContent}
        </CustomLink>
      ) : (
        "ALL DEPOTS"
      ),
  },
  {
    title: "status",
    dataIndex: "status",
    render: camelCaseTextFormatter,
  },
  {
    title: "Source",
    dataIndex: "createdByRole",
    render: camelCaseTextFormatter,
  },
];
