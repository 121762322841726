import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "utils/constants";
import GeoDetails from "pages/depot-management/depotModal/Tabs/GeoDetails";
import { isEmpty } from "lodash";
import { DEFAULT_CENTER } from "pages/depot-management/depots/constants";
import { customDateFormatWithoutTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  infoContainer: {
    display: "flex",
    marginBottom: 15,
  },
  title: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10,
  },
  rowData: {
    display: "flex",
  },
  rowTitle: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 5,
    width: "30%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 5,
    width: "70%",
    textAlign: "end",
  },
  prospectInfo: {
    width: "50%",
    marginRight: 20,
  },
  prospectAssignedTo: {
    width: "50%",
    marginRight: 20,
  },
  createdByName: {
    display: "flex",
  },
  mapContainer: {},
  link: {
    color: "black",
    textDecoration: "underline!important",
  },
});

const ProspectDetailsSection = ({ prospect, allDepots }) => {
  const classes = useStyles();

  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    libraries: ["drawing", "places"],
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const renderCreatedBy = useCallback(() => {
    if (
      prospect?.createdBy &&
      prospect?.createdBy !== "ngi-qa@mgas.ke" &&
      prospect?.createdBy !== "initiatives_landing@mgas.ke"
    ) {
      return (
        <div className={classes.rowData}>
          <div className={classes.rowTitle}>Created By</div>
          <div className={classes.data}>
            <CustomLink underlined target="_blank" to={`/user/${prospect?.createdBy}`}>
              {prospect?.createdBy}
            </CustomLink>
          </div>
        </div>
      );
    }
  }, [prospect]);

  const renderReferralValue = useCallback(() => {
    if (prospect?.refValue?.length === 7 && prospect?.refSource === "Customer") {
      return (
        <CustomLink className={classes.link} target="_blank" to={`/customers/customer/${prospect?.refValue}`}>
          {prospect?.refValue}
        </CustomLink>
      );
    }
    if (prospect?.refValue?.includes("https://") || prospect?.refValue?.includes("http://")) {
      return (
        <a target="_blank" className={classes.link} href={prospect?.refValue} rel="noreferrer">
          {prospect?.refValue}
        </a>
      );
    }
    return textFormatter(prospect?.refValue);
  }, [prospect]);

  return (
    <div>
      <div className={classes.infoContainer}>
        <div className={classes.prospectInfo}>
          <div className={classes.title}>Prospect</div>
          <div className={classes.rowData}>
            <div className={classes.rowTitle}>Name</div>
            <div className={classes.data}>
              {prospect?.firstName ? `${prospect?.firstName} ${prospect?.lastName}` : "-"}
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.rowTitle}>Phone</div>
            <div className={classes.data}>{prospect?.phone ?? "-"}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.rowTitle}>Depot</div>
            <div className={classes.data}>{prospect?.depot ?? "-"}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.rowTitle}>Address</div>
            <div className={classes.data}>
              {prospect?.location ? `${prospect?.location?.street}, ${prospect?.location?.houseNumber}` : "-"}
            </div>
          </div>
          {renderCreatedBy()}
        </div>
        <div className={classes.prospectAssignedTo}>
          <div className={classes.title}>Referral Information</div>
          <div className={classes.rowData}>
            <div className={classes.rowTitle}>Referral Source</div>
            <div className={classes.data}>{textFormatter(prospect?.refSource)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.rowTitle}>Referral Value</div>
            <div className={classes.data}>{renderReferralValue()}</div>
          </div>
          {prospect?.status === "AssignedProspect" && (
            <>
              <div className={classes.title}>Assigned To</div>
              <div className={classes.rowData}>
                <div className={classes.rowTitle}>TSR Name</div>
                <div className={classes.data}>
                  <CustomLink underlined target="_blank" to={`/user/${prospect?.technician}`}>
                    {prospect?.technicianName}
                  </CustomLink>
                </div>
              </div>
              <div className={classes.rowData}>
                <div className={classes.rowTitle}>Date Assigned</div>
                <div className={classes.data}>{customDateFormatWithoutTime(prospect?.updatedAt)}</div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classes.mapContainer}>
        <GeoDetails
          allowPolygon={false}
          allowMarker={false}
          center={
            (!isEmpty(prospect?.location) && {
              lat: prospect?.location?.lat,
              lng: prospect?.location?.lon,
            }) ||
            DEFAULT_CENTER
          }
          initialData={{
            marker: !isEmpty(prospect?.location) && {
              lat: prospect?.location?.lat,
              lng: prospect?.location?.lon,
            },
          }}
          allowOpenDetails={false}
          isLoaded={isLoaded}
          polygonList={allDepots}
        />
      </div>
    </div>
  );
};

export default ProspectDetailsSection;
