import React, { useCallback, useEffect, useMemo } from "react";
import { Form, Select } from "antd";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { uniq } from "lodash";
import { DEPOT_TYPE_SELECT, DEPOT_TYPES, STO_VIEW_TYPES } from "pages/stock-management/stockTransferOrders/constants";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { makeStyles } from "@material-ui/core/styles";
import { userHasOneOfRoles } from "utils/helpers/functions";

const { Option } = Select;

const useStyles = makeStyles({
  filterTitle: {
    color: "#bfbfbf",
    width: "40px!important",
  },
  filterValue: {
    width: "110px!important",
    "& .ant-select-selector": {
      padding: "0!important",
    },
  },
});
const STOFilter = ({ handleSearch }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.STO);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const depotList = useMemo(
    () =>
      userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])
        ? depot && depot[USER_TENANT_NAME]
          ? uniq(depot[USER_TENANT_NAME])?.sort()
          : []
        : [AUTH_USER?.depotName],
    [depot]
  );

  useEffect(() => {
    if (!!filters) {
      form.setFieldsValue({
        viewType: filters?.viewType || STO_VIEW_TYPES.ASSET,
        depot: filters?.depot,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    const { depotType, depot, viewType } = values;
    const depotValue = depotList?.length === 1 ? depotList[0] : depot;
    handleSearch({ [depotType]: depotValue, viewType, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item className={classes.filterTitle}>View</Form.Item>
        <Form.Item className={classes.filterValue} name="viewType">
          <Select
            defaultValue={STO_VIEW_TYPES.ASSET}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder={"Select value"}
            onChange={form.submit}
          >
            {Object.values(STO_VIEW_TYPES).map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="filter-divider" />
        <Form.Item initialValue={DEPOT_TYPES.DEPOT_TO} className={classes.filterValue} name="depotType">
          <Select showSearch defaultValue={DEPOT_TYPES.DEPOT_TO} placeholder="Select value" onChange={form.submit}>
            {DEPOT_TYPE_SELECT.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className={classes.filterValue} name="depot">
          <Select
            disabled={depotList?.length === 1}
            defaultValue={depotList?.length === 1 ? depotList[0] : null}
            allowClear
            placeholder="All"
            onChange={form.submit}
          >
            {depotList?.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default STOFilter;
