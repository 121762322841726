
   
/* METER REGISTRATION */

export const CREATE_METER = "CREATE_METER"
export const CREATE_METER_FAIL = "CREATE_METER_FAIL"
export const CREATE_METER_SUCCESS = "CREATE_METER_SUCCESS"

export const REGISTER_METER = "REGISTER_METER"
export const REGISTER_METER_FAIL = "REGISTER_METER_FAIL"
export const REGISTER_METER_SUCCESS = "REGISTER_METER_SUCCESS"

export const GET_METERS = "GET_METERS"
export const GET_METERS_FAIL = "GET_METERS_FAIL"
export const GET_METERS_SUCCESS = "GET_METERS_SUCCESS"

export const GET_METER_DETAIL = "GET_METER_DETAIL"
export const GET_METER_DETAIL_FAIL = "GET_METER_DETAIL_FAIL"
export const GET_METER_DETAIL_SUCCESS = "GET_METER_DETAIL_SUCCESS"

export const UPDATE_METER = "UPDATE_METER"
export const UPDATE_METER_FAIL = "UPDATE_METER_FAIL"
export const UPDATE_METER_SUCCESS = "UPDATE_METER_SUCCESS"

export const GET_META = "GET_META"

export const POST_METER_REPORT = "POST_METER_REPORT"
export const POST_METER_REPORT_FAIL = "POST_METER_REPORT_FAIL"
export const POST_METER_REPORT_SUCCESS = "POST_METER_REPORT_SUCCESS"

export const GET_METER_REPORT = "GET_METER_REPORT"
export const GET_METER_REPORT_FAIL = "GET_METER_REPORT_FAIL"
export const GET_METER_REPORT_SUCCESS = "GET_METER_REPORT_SUCCESS"