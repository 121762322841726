import React, { useCallback, useState } from "react";
import { Modal, Space } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { textFormatter } from "components/Common/formatter";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import classnames from "classnames";
import GeoDetails from "pages/depot-management/depotModal/Tabs/GeoDetails";
import { extractAddress, extractMarkerCoords, isMarkerInsidePolygon } from "utils/helpers/mapHelpers";
import { DEFAULT_CENTER } from "pages/depot-management/depots/constants";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  modal: {
    "& .ant-modal-content .ant-modal-header": {
      padding: "25px 30px 0 !important",
    },
    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 30px !important",
    },
    "& .ant-modal-title": {
      borderBottom: "1px solid #00000014",
      paddingBottom: 15,
    },
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  fullWidth: {
    width: "50%!important",
  },
});

const ChangeAddressModel = ({ onClose, onSave, address, polygonList, classes, isLoaded }) => {
  const modalClasses = useStyles();
  const [marker, setMarker] = useState(null);
  const [newAddress, setNewAddress] = useState({ ...address });
  const [initAddress, setInitAddress] = useState(address);

  // eslint-disable-next-line no-undef
  const geocoder = new google.maps.Geocoder();

  const handleSave = useCallback(() => {
    onClose();
    onSave(newAddress);
  }, [newAddress]);

  const onMarkerComplete = useCallback(
    data =>
      setMarker(prevMarker => {
        prevMarker?.setMap(null);
        let insideDepot;
        polygonList.some(item => {
          const isInside = isMarkerInsidePolygon(data, item.poly);
          if (isInside) {
            insideDepot = item;
          }
          return isInside;
        });
        const markerCoords = extractMarkerCoords(data);
        geocoder
          .geocode({
            location: {
              lat: markerCoords[0],
              lng: markerCoords[1],
            },
          })
          .then(response => {
            if (response?.results?.length) {
              const { newStreet, newHouseNumber } = extractAddress(response.results);
              setNewAddress({
                street: newStreet,
                prospectDepot: insideDepot?.id,
                houseNumber: newHouseNumber,
                lat: markerCoords[0],
                lon: markerCoords[1],
              });
            }
          });
        setInitAddress(null);
        return data;
      }),
    [polygonList]
  );

  return (
    <Modal
      open={true}
      title={
        <div className={modalClasses.titleWithDescription}>
          Change Address
          <span>Address should include house number.</span>
        </div>
      }
      className={modalClasses.modal}
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <>
        <div>
          <GeoDetails
            allowSearch
            allowPolygon={false}
            center={
              marker ||
              (!isEmpty(initAddress) && {
                lat: initAddress?.lat,
                lng: initAddress?.lon,
              }) ||
              DEFAULT_CENTER
            }
            initialData={{
              marker: !isEmpty(initAddress) && {
                lat: initAddress?.lat,
                lng: initAddress?.lon,
              },
            }}
            allowOpenDetails={false}
            isTouched={marker}
            onMarkerComplete={onMarkerComplete}
            isLoaded={isLoaded}
            polygonList={polygonList}
          />
        </div>
        <TitleWithDivider title="Customer Location" />
        <div className={classes.columnsContainer}>
          <div className={classnames(modalClasses.fullWidth, classes.column)}>
            <div className={classes.rowData}>
              <div className={classes.title}>Address</div>
              <div className={classes.data}>
                {newAddress?.street ? `${newAddress?.street} ${newAddress?.houseNumber ?? "-"}` : textFormatter("")}
              </div>
            </div>
          </div>
          <div className={classnames(modalClasses.fullWidth, classes.column)}>
            <div className={classes.rowData}>
              <div className={classes.title}>Latitude / Longitude</div>
              <div className={classes.data}>
                {newAddress?.lat ? `${newAddress?.lat} / ${newAddress?.lon}` : textFormatter("")}
              </div>
            </div>
          </div>
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Close" />
          <CustomButton onClick={handleSave} disabled={!newAddress?.lat} type="primary" size="small" text="Save" />
        </Space>
      </>
    </Modal>
  );
};

export default ChangeAddressModel;
