export const filterOptions = [
  { name: "Customer ID", value: "customerId" },
  { name: "Collected By", value: "collectedBy" },
  { name: "Date", value: "dateRange" },
];

export const RESOLUTIONS = {
  REFURBRISHED: "Stove Repaired",
  REPAIR: "Sent to Supplier",
};

export const faultOrigins = ["Company", "Customer", "Unknown"];

export const RESOLUTION_OPTIONS = [
  { name: "Refurbished", value: RESOLUTIONS.REFURBRISHED },
  { name: "For Repair", value: RESOLUTIONS.REPAIR },
];

export const RESOLUTION_TYPES = {
  [RESOLUTIONS.REFURBRISHED]: {
    title: "STOVE IS REFURBISHED",
    description: "Confirming this option will change the status of the stove to “REFURBISHED”.",
  },
  [RESOLUTIONS.REPAIR]: {
    title: "STOVE IS FOR REPAIR",
    description: "Confirming this option will change the status of the stove to “FOR REPAIR”.",
  },
};
