export const searchOptions = [
  {
    value: "forDepot",
    title: "Depot",
  },
  {
    value: "name",
    title: "Name",
  },
];
