import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { retrieveContractVersions } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";
import { versionListColumns } from "pages/settings/contract-versions/versionList/VersionListColumns";
import VersionSearch from "pages/settings/contract-versions/versionList/VersionSearch";

const ContractVersions = () => {
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.CONTRACT_VERSIONS);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
  }, []);

  const onListLoaded = useCallback(res => {
    const { result } = res?.data;
    if (result?.items?.length === 0) {
      warnMessage("Contract versions were not found");
    }
    setList({ result: { items: Array.isArray(result?.items) ? result.items : result, meta: result.meta } } || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    retrieveContractVersions(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => versionListColumns(), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomLink
                roles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP, USER_ROLES.SYSTEM_FULL_ADMINS]}
                className="margin-right"
                to={"/settings/contract-version/new"}
              >
                <CustomButton icon={<i className="bx bx-plus-circle" />} type="primary" text="New Version" />
              </CustomLink>
            </div>

            <VersionSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <>
              <NGITable
                filtersType={FILTER_PAGE.CONTRACT_VERSIONS}
                isLoading={isLoading}
                columns={columns}
                data={list}
                updateList={handleSearch}
              />
            </>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default ContractVersions;
