import React, { useState, useCallback, useEffect } from "react";
import { Col, Container } from "reactstrap";
import OutgoingLogsFilter from "./filter";
import { getOutgoingLogsAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import { listColumns } from "./listColumns";
import NGITable from "components/Common/NGITable";
import { FILTER_PAGE } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";

const OutgoingLogs = () => {
  const [loader, setLoader] = useState(false);
  const [items, setItems] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.OUTGOING_LOGS);

  useEffect(() => {
    if (!isEmpty(filters)) {
      onFilter(filters);
    }
  }, []);

  const onFilter = useCallback(filterOptions => {
    setLoader(true);
    setFilters(filterOptions);

    getOutgoingLogsAPI(filterOptions)
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (!items.length) {
          warnMessage(`No data was found`);
        }
        setItems(res?.data || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setItems([]);
        defaultNgiErrorMethod(err);
      });
  }, []);

  return (
    <div className="page-content" data-testid="outgoingLogs">
      <Container fluid>
        <div className="filters-wrapper">
          <OutgoingLogsFilter handleSearch={onFilter} isLoading={loader} />
        </div>
        <Col lg={12}>
          <NGITable
            filtersType={FILTER_PAGE.OUTGOING_LOGS}
            isLoading={loader}
            columns={listColumns()}
            data={items}
            updateList={onFilter}
          />
        </Col>
      </Container>
    </div>
  );
};

export default OutgoingLogs;
