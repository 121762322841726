import { textFormatter } from "components/Common/formatter";
import React from "react";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import { CopyOutlined } from "@ant-design/icons";
import { successMessage } from "components/Common/responses/message";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import editIcon from "assets/images/svg/Edit.svg";
import { USER_ROLES } from "utils/constants";
import { TRIP_STATUSES } from "pages/stock-management/trips/constants";

const color = "#444444";
const ALLOWED_FOR_STATUSES = [
  TRIP_STATUSES.PENDING,
  TRIP_STATUSES.PLANNED,
  TRIP_STATUSES.IN_TRANSIT,
  TRIP_STATUSES.EXPIRED,
];
export const tripListColumns = (classes, history) => [
  {
    title: "Trip Number",
    dataIndex: "tripNumber",
    render: (cellContent, row) => (
      <div className={classes.withCopyIcon}>
        <Tooltip placement="bottom" title={`${cellContent} details`} color={color}>
          <CustomLink to={`trips/${row.tripNumber}`}>{cellContent}</CustomLink>
        </Tooltip>

        <Tooltip placement="bottom" title="Copy trip number" color={color}>
          <CopyOutlined
            onClick={() => {
              navigator.clipboard.writeText(row.tripNumber);
              successMessage("Trip Number is copied to clipboard.");
            }}
          />
        </Tooltip>
      </div>
    ),
  },
  {
    title: "Start Location",
    dataIndex: "startLocation",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "End Location",
    dataIndex: "endLocation",
    render: cellContent => (
      <CustomLink underlined to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Stops",
    dataIndex: "stops",
    render: row => textFormatter(row?.length),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Date & Time",
    dataIndex: "",
    render: row => textFormatter(`${row.startDate} ${row.startTime}`),
  },
  {
    title: "",
    dataIndex: "tripNumber",
    render: (tripNumber, row) => (
      <ButtonGroup
        disabled={!ALLOWED_FOR_STATUSES.includes(row.status)}
        roles={[USER_ROLES.PRODUCT_TECHNICIAN_LEAD, USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP]}
        tooltipTitle="Edit"
        icon={editIcon}
        handleClick={() => history.push(`/stock-management/trip/${tripNumber}`)}
      />
    ),
  },
];
