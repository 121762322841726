import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import userPreviewIcon from "assets/images/svg/userPreview.svg";
import BackButtonLink from "components/Common/buttons/BackButtonLink";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 8,

    "& span": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },
  },
  iconContainer: {
    padding: 8,
    marginLeft: 23,
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    width: 65,
    minWidth: 65,
    height: 65,

    "& img": {
      width: "100%",
      height: "100%",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
    },
  },
});

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <BackButtonLink to={"/operations/prospects"}>Back</BackButtonLink>

        <div className={classes.iconContainer}>
          <img src={userPreviewIcon} alt="customer" />
        </div>
        <div className={classes.nameContainer}>
          <span>NEW PROSPECT</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
