import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Spin } from "antd";
import { retrieveDownloadUrlByType } from "utils/api";
import { useDispatch } from "react-redux";
import { getSensors } from "store/meter-management/configuration/accessories/actions";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "100%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
    marginTop: 5,
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
    marginRight: 16,
    cursor: "pointer",
  },
  imgPreview: {
    width: "100%",
  },
  formTitle: {
    fontSize: "14px!important",
  },
  formRow: {
    width: "100%",
    marginBottom: 20,
  },
  editBtn: {
    cursor: "pointer",
  },
  formItem: {
    display: "flex",

    "& .ant-form-item": {
      width: "85%",
      marginRight: 5,
      marginBottom: 0,
    },
  },
});

const MeterDetails = ({ meterInfo, reportData, form }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [preview, setPreview] = useState("");

  useEffect(async () => {
    if (reportData?.testingData?.pictures) {
      setIsLoading(true);
      const urls = await Promise.all(
        reportData?.testingData?.pictures.map(item =>
          retrieveDownloadUrlByType("picture", item).then(({ data: { result } }) => result.url)
        )
      );
      setIsLoading(false);
      setPictures(urls);
    }
  }, [reportData?.testingData?.pictures]);

  useEffect(() => {
    form.setFieldsValue({
      sensorSerial: meterInfo?.sensorSerial || "",
      sensorType: meterInfo?.sensorType || "",
      pcb: meterInfo?.pcb || "",
      removableIccid: meterInfo?.removableIccid || "",
    });
  }, [meterInfo]);

  useEffect(() => {
    dispatch(getSensors("Sensor"));
  }, []);

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.listBlock}>
          <div className={classes.item}>
            <div className={classes.title}>meter serial number</div>
            <div className={classes.value}>
              {meterInfo?.serial ? (
                <CustomLink underlined target="_blank" to={`/warehouse/assets/${meterInfo?.serial}?t=serialNumber`}>
                  {meterInfo?.serial}
                </CustomLink>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>meter barcode</div>
            <div className={classes.value}>{meterInfo?.barcode ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>pcb serial number</div>
            <div className={classes.value}>{meterInfo?.pcb ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>sim card</div>
            <div className={classes.value}>{meterInfo?.removableIccid ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>Sensor Type</div>
            <div className={classes.value}>{meterInfo?.sensorType ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>sensor serial number</div>
            <div className={classes.value}>{meterInfo?.sensorSerial ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>current firmware</div>
            <div className={classes.value}>{meterInfo?.firmware ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>BATTERY #1</div>
            <div className={classes.value}>{meterInfo?.battery1 ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>BATTERY #2</div>
            <div className={classes.value}>{meterInfo?.battery2 ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>Maintained By</div>
            <div className={classes.value}>{meterInfo?.maintainedBy ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>COLLECTED BY</div>
            <div className={classes.value}>
              {meterInfo?.collectedBy ? (
                <CustomLink underlined target="_blank" to={`/user/${meterInfo.collectedBy}`}>
                  {meterInfo?.collectedBy}
                </CustomLink>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>reason of collection</div>
            <div className={classes.value}>{reportData?.faultyReasons?.join(", ") ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>collected date</div>
            <div className={classes.value}>{meterInfo?.maintenanceStartDate ?? "-"}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.title}>Previous photos of the meter</div>
            <div className={classes.value}>
              {isLoading ? (
                <Spin spinning={true} />
              ) : pictures.length ? (
                pictures.map(item => (
                  <img
                    key={item}
                    alt="preview"
                    onClick={() => setPreview(item)}
                    className={classes.filePreview}
                    src={item}
                  />
                ))
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal title="Photo preview" open={Boolean(preview)} onCancel={() => setPreview("")} footer={null}>
        <img className={classes.imgPreview} src={preview} alt="img preview" />
      </Modal>
    </div>
  );
};

export default MeterDetails;
