import { isEqual } from "lodash";
import { ALLOWED_TEAMS } from "pages/depot-management/depotDetails/constants";

const fieldsMapping = {
  status_: "Status",
  region: "County",
  county: "County",
  subCounty: "Sub-county",
  operationalizationDate: "Operationalization Date",
  lon: "Longitude",
  lat: "Latitude",
  address: "Physical Address",
  district: "District",
  boundaries: "Depot Coordinates",
};

const skipKeys = ["updatedAt", "updatedBy", "resyncTime"];

export const preparedHistoryData = data => {
  const changes = [];
  data.forEach(item => {
    const changedFields = [];
    Object.keys(item.newImage).forEach(key => {
      if (skipKeys.includes(key)) return;
      if (key === "location") {
        Object.keys(item.newImage[key]).forEach(k => {
          if (item.newImage[key][k] !== item.oldImage[key][k]) {
            changedFields.push(fieldsMapping[k] || k);
          }
        });
        return;
      }
      if (key === "boundaries") {
        if (!isEqual(item.newImage[key].coordinates, item.oldImage[key].coordinates)) {
          changedFields.push(fieldsMapping[key] || key);
        }
        return;
      }
      if (item.newImage[key] !== item.oldImage[key]) {
        changedFields.push(fieldsMapping[key] || key);
      }
    });
    if (changedFields.length) {
      changes.push({
        updatedAt: item.newImage.updatedAt,
        updatedBy: item.newImage.updatedBy,
        fields: changedFields.join(", "),
      });
    }
  });
  return changes;
};

export const getValue = (value = "N/A") => value;

export const prepareDepotData = (depotData, users) => {
  const sectors = depotData?.sectors?.map(item => {
    const foundUser = users.filter(user => user.sector === item.name && ALLOWED_TEAMS.includes(user.team));
    return { ...item, users: foundUser };
  });
  return { ...depotData, sectors };
};
