import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import wrenchIcon from "assets/images/svg/Wrench.svg";
import CustomLink from "components/Common/buttons/CustomLink";

export const readyForRepairListColumns = openModal => [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: cellContent => textFormatter(cellContent),
    sort: true,
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    render: cellContent =>
      cellContent !== "UNKNOWN" ? (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${cellContent}?t=serialNumber`}>
          {cellContent}
        </CustomLink>
      ) : (
        cellContent
      ),
    sort: true,
  },
  {
    title: "Findings",
    dataIndex: "faultyReasons",
    render: cellContent => textFormatter(cellContent?.join()),
    sort: true,
  },
  {
    title: "Meter engineer",
    dataIndex: "maintainedBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
    sort: true,
  },
  {
    title: "Collected date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    title: "Inspection date",
    dataIndex: "maintainedAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
    sort: true,
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroup
        tooltipTitle="Repair"
        icon={wrenchIcon}
        handleClick={() => {
          openModal(row);
        }}
      />
    ),
  },
];
