import { REQUEST_STATUSES } from "pages/customer-management/customerUpdateLocationRequest/createCustomerLocationUpdateRequest/constants";

export const filterOptions = [
  {
    value: "status",
    title: "Status",
  },
  {
    value: "customerId",
    title: "Customer ID",
  },
  {
    value: "createdBy",
    title: "TSR",
  },
];

export const STATUSES = [
  REQUEST_STATUSES.PENDING,
  REQUEST_STATUSES.DECLINED,
  REQUEST_STATUSES.APPROVED_BY_CURRENT,
  REQUEST_STATUSES.APPROVED,
  REQUEST_STATUSES.COMPLETED,
];
