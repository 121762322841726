import React from "react";
import PropTypes from "prop-types";
import { Form, Select } from "antd";
import { Card, CardBody, Col } from "reactstrap";
import CustomButton from "components/Common/buttons/CustomButton";

const { Option } = Select;

const MetersFilter = props => {
  const [form] = Form.useForm();
  const { onFilter, showState, showAssetType } = props;

  const onFinish = values => {
    const filter = { ...values };
    onFilter(filter);
  };

  return (
    <>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
              <Col lg={3} className="mt-2">
                <Form.Item name="state" rules={[{ required: true, message: "Please select state" }]}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    size="default"
                    placeholder={"Select state"}
                  >
                    {showState.length > 0 &&
                      showState.map(item => {
                        if (item === "Not Ready") {
                          return (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          );
                        }
                        return null;
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={3} className="mt-2">
                <Form.Item name="group" rules={[{ required: true, message: "Please select asset group." }]}>
                  <Select
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    size="default"
                    placeholder={"Filter By Group"}
                  >
                    {showAssetType.length > 0 &&
                      showAssetType.map(item => {
                        if (item === "Meter") {
                          return (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          );
                        }
                        return null;
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={3} className="mt-2">
                <CustomButton type="primary" htmlType="submit" size="small" text="Filter" />
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

MetersFilter.propTypes = {
  filterType: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  showState: PropTypes.array,
  showAssetType: PropTypes.array,
};

export default MetersFilter;
