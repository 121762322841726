import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import GeneralValidationTab from "pages/operations/validationTaskRequestDetails/GeneralValidationTab";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
});

const DataTabs = ({ task, tabs, setLoader, updateData }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(`${tabs[0].group}>${tabs[0]?.id}`);

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {tabs?.map(item => (
          <div
            key={`${item.group}>${item?.id}`}
            className={classnames(classes.tab, activeTab === `${item.group}>${item?.id}` && classes.active)}
            data-tab={`${item.group}>${item?.id}`}
            onClick={onTabClick}
          >
            {item.group}
          </div>
        ))}
      </div>
      <Card className={classes.container}>
        <CardBody className={classes.cardBody}>
          <GeneralValidationTab task={task} type={activeTab} setLoader={setLoader} update={updateData} />
        </CardBody>
      </Card>
    </div>
  );
};

export default DataTabs;
