import React, { useCallback } from "react";
import { Form } from "antd";
import { Card, CardBody, Col, CardTitle } from "reactstrap";

const MetersFilter = ({ handleFilter }) => {
  const [form] = Form.useForm();
  const onSearch = useCallback(values => handleFilter(values), []);

  return (
    <>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle>Meters Uploads</CardTitle>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
              {/*<Col lg={3} className="mt-2">*/}
              {/*  <Form.Item name="status" rules={[{required: true, message: "Please select Status"}]}>*/}
              {/*    <Select style={{width: "100%"}} tokenSeparators={[","]} placeholder={"Select Status"}>*/}
              {/*      <Option value={STATUS_ACTIVE}>{STATUS_ACTIVE}</Option>*/}
              {/*      <Option value={STATUS_INACTIVE}>{STATUS_INACTIVE}</Option>*/}
              {/*    </Select>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col lg={8} className="mt-2">*/}
              {/*  <Button type="primary" htmlType="submit" loading={isLoading}>*/}
              {/*    <i className="bx bx-search" /> Search*/}
              {/*  </Button>*/}
              {/*</Col>*/}
              <Col lg={1} className="mt-2"></Col>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default MetersFilter;
