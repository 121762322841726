import { getTrimmedObjectValues } from "utils/helpers/functions";

export const getFormattedResult = result =>
  `Failed items: ${result?.failedItemsCount}, skipped items: ${result?.skippedItemsCount}, succeeded items: ${result?.succeededItemsCount}`;

export const getFormattedVehicleFields = json =>
  json?.map(item =>
    getTrimmedObjectValues({
      licencePlate: item["UNIT"],
      unitType: item["UNIT TYPE"],
      qrCode: item["ASSIGNED NO"],
      depot: item["DEPOT"],
    })
  );

export const isVehicleFileNoError = data =>
  data.every(item => item?.licencePlate || item?.unitType || item?.qrCode || item?.depot);
