export const STATUSES = ["New", "Active", "Inactive", "Cancelled"];

export const TYPE_NAMES = {
  INITIATIVE_CODE: "code",
  STATUS: "status",
};

export const FILTER_TYPES = [
  { name: "Initiative Code", value: TYPE_NAMES.INITIATIVE_CODE },
  { name: "Status", value: TYPE_NAMES.STATUS },
];
