import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Form } from "antd";
import Title from "antd/es/typography/Title";
import NGITextArea from "components/Common/inputs/NGITextArea";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "100%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
    marginTop: 5,
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  filePreview: {
    width: 36,
    height: 36,
    borderRadius: 5,
    marginRight: 16,
  },
});

const AdditionalComments = () => {
  const classes = useStyles();

  return (
    <div className={classes.testResultContainer}>
      <div className={classes.commentContainer}>
        <Col>
          <Title level={5}>Additional Comments</Title>
          <Form.Item name="comment">
            <NGITextArea rows={5} placeholder="Please add your comments" />
          </Form.Item>
        </Col>
      </div>
    </div>
  );
};

export default AdditionalComments;
