import React, { useCallback, useMemo, useState } from "react";
import { Modal, Space, Spin } from "antd";
import { validateOtp } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import infoError from "assets/images/svg/infoError.svg";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { makeStyles } from "@material-ui/core/styles";
import CustomLink from "components/Common/buttons/CustomLink";
import NGIInput from "components/Common/inputs/NGIInput";
import classnames from "classnames";

const useStyles = makeStyles({
  modal: {
    maxWidth: "400px!important",

    "& .ant-modal-content .ant-modal-header": {
      padding: "25px 30px 0 !important",
    },
    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 30px !important",
    },
    "& .ant-modal-title": {
      borderBottom: "1px solid #00000014",
      paddingBottom: 15,
    },
  },
  phoneText: {
    fontSize: 13,
    fontWeight: 400,
    textAlign: "center",
    marginBottom: 15,

    "& span": {
      fontWeight: 600,
    },
  },

  newPhoneText: {
    fontSize: 12,
    fontWeight: 300,
    textAlign: "center",
    marginBottom: 15,

    "& span": {
      fontWeight: 600,
    },
  },
  codeContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,

    "& .ant-input": {
      height: 100,
      width: 60,
      borderRadius: 15,
      fontSize: 40,
      textAlign: "center",
      color: "#8C8C8C",
      fontWeight: 100,
    },
    "& .ant-input+.ant-input": {
      marginLeft: 10,
    },
  },
  helpText: {
    fontSize: 13,
    fontWeight: 400,
    textAlign: "center",
    marginBottom: 20,
  },
  error: {
    fontSize: 9,
    color: "#FF0000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& img": {
      marginRight: 5,
    },
  },
});

const INPUT_ARRAY = [0, 1, 2, 3];
const OTPVerificationModal = ({ onClose, onSendOTP, data, onVerifySuccess, customerData, lostOption = true }) => {
  const classes = useStyles();
  const [code, setCode] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setLoader] = useState(false);

  const handleCodeChange = useCallback(e => {
    const {
      dataset: { index },
      value,
    } = e.target;
    setError(false);
    setCode(prevState => {
      if (value.length === 1 || value.length === 0) {
        prevState[parseInt(index)] = value;
      }
      const i = value.length === 0 ? parseInt(index) - 1 : parseInt(index) + 1;
      const nextField = document.querySelector(`input[name=field-${i}]`);
      if (nextField !== null) {
        nextField.focus();
      }

      return [...prevState];
    });
  }, []);

  const onVerify = useCallback(() => {
    setLoader(true);
    validateOtp({
      ...data,
      OTP: code.join(""),
    })
      .then(() => {
        setLoader(false);
        onVerifySuccess(true);
        onClose();
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
        setError(true);
      });
  }, [code, data]);

  const handleReSendOtp = useCallback(() => {
    setCode([]);
    successMessage("New code sent successfully");
    onSendOTP();
  }, []);

  const isInvalidCode = useMemo(() => code.length !== 4 || code.findIndex(item => item === "") !== -1, [code]);

  const handlePaste = useCallback(event => {
    const numbers = event.clipboardData.getData("text").substring(0, 4).split("");
    if (numbers.length === 4) {
      setCode(numbers);
    }
  }, []);

  return (
    <Modal
      open={true}
      title="OTP Verification"
      className={classes.modal}
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className={classes.phoneText}>
            We’ve sent a verification code to
            <br />
            <span>{data.phone}</span>.
          </div>

          <div className={classes.codeContainer}>
            {INPUT_ARRAY.map(item => (
              <NGIInput
                onPaste={handlePaste}
                key={item}
                name={`field-${item}`}
                value={code[item]}
                data-index={item}
                onChange={handleCodeChange}
                disablePattern
              />
            ))}
          </div>
          {error && (
            <div className={classnames(classes.phoneText, classes.error)}>
              <img src={infoError} alt="error" />
              OTP CODE is incorrect. Please try again!
            </div>
          )}

          <div className={classes.helpText}>
            Didn’t receive a code?{" "}
            <CustomLink color="#0088EA" onClick={handleReSendOtp}>
              Resend OTP
            </CustomLink>
          </div>

          {customerData.phone !== data.phone ? (
            <div className={classes.newPhoneText}>
              <span>Important:</span> Once you verify this number it will become the primary for this account!
            </div>
          ) : (
            lostOption && (
              <div className={classes.helpText}>
                Lost your phone?{" "}
                <CustomLink color="#0088EA" onClick={onClose}>
                  Verify with new number
                </CustomLink>
              </div>
            )
          )}
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Close" />
          <CustomButton
            onClick={onVerify}
            disabled={isInvalidCode || error}
            type="success"
            size="small"
            text="VERIFY OTP"
          />
        </Space>
      </Spin>
    </Modal>
  );
};

export default OTPVerificationModal;
