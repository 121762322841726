export const searchOptions = [
  {
    value: "name",
    title: "Name",
  },
  {
    value: "depot",
    title: "Depot",
  },
  {
    value: "role",
    title: "Role",
  },
  {
    value: "status",
    title: "Status",
  },
];

export const emptyResponse = {
  result: { items: [] },
};
