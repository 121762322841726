import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Row, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { configureMeter, deleteMeterJourney, retrieveJourneyDetailsAPI, retrieveMeterDetails } from "utils/api";
import CustomButton from "components/Common/buttons/CustomButton";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { makeStyles } from "@material-ui/core/styles";
import iconOk from "../../../assets/images/svg/iconOk.svg";
import infoRed from "../../../assets/images/svg/InfoRed.svg";
import infoBlue from "../../../assets/images/svg/InfoBlue.svg";
import InfoModal from "components/Common/modals/InfoModal";
import classname from "classnames";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_CURRENCY } from "utils/constants";
import NGITextArea from "components/Common/inputs/NGITextArea";
import MarkAsFaultyModal from "pages/meter-management/meters/MarkAsFaultyModal";
import printIcon from "./../../../assets/images/svg/print.svg";
import { printFrame } from "utils/helpers/functions";
import QRCode from "react-qr-code";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  formItem: {
    width: "100%",
  },
  logo: {
    height: 50,
    border: "2px solid #444444",
    padding: "14px 0 14px 14px",
    borderRadius: "30px 0 0 30px",
  },
  select: {
    width: "100%",

    "&.ant-select:not(.ant-select-customize-input) .ant-select-selector": {
      border: "2px solid #444444",
      padding: "7px 20px",
      minHeight: 50,
      display: "flex",
      alignItems: "center",
    },
  },
  errorMsg: {
    color: "#ff4d4f",
    fontSize: 10,
  },
  left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  result: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: 12,
    fontWeight: 500,
    textTransform: "uppercase",
    margin: 10,
  },
  commentText: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: 12,
    fontWeight: "300 !important",
    textTransform: "uppercase",
  },
  commentValue: {
    marginBottom: 24,
  },
  flex: {
    display: "flex",
    width: "200%",
  },
  flexTitle: {
    margin: "4px 6px 14px 0",
  },
  status: {
    minHeight: 50,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "rgba(243, 243, 243, 1)",
    borderRadius: 10,
    padding: "0 4px",
    marginBottom: 10,
    flexWrap: "wrap",
  },
  good: {
    background: "rgba(236, 255, 242, 1)",
  },
  fail: {
    background: "rgba(255, 236, 236, 1)",
  },
  statusImg: {
    margin: 12,
  },
  statusText: {},
  statusTitle: {
    fontSize: 11,
    fontWeight: 300,
    lineHeight: 1,
  },
  statusCommonTitle: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1,
    marginLeft: 10,
  },
  statusDescription: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1,
  },
  buttonFaulty: {
    marginRight: 20,
    background: "#e31417 !important",
  },
  succeedText: {
    color: "rgba(23, 195, 71, 1)",
    fontWeight: 600,
    marginTop: 20,
    textTransform: "uppercase",
  },
  error: {
    width: "100%",
    borderTop: "solid 1px rgba(0, 0, 0, 0.06)",
  },
  information: {
    margin: 10,
    color: "rgba(102, 102, 102, 1)",
  },
  informationIcon: {
    marginRight: 6,
  },
  red: {
    color: "rgba(255, 0, 0, 1)",
    fontWeight: 600,
  },
  blue: {
    color: "rgba(0, 133, 255, 1)",
    fontWeight: 600,
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,
  },
});

const MeterConfigurationModal = ({ onClose, isOpen, activeItem, updateData }) => {
  const [form] = Form.useForm();
  const [loading, setLoader] = useState(false);
  const [meterDetails, setMeterDetails] = useState(false);
  const [showFailAlert, setFailAlert] = useState(false);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [comment, setComment] = useState("");
  const [defaultSim, setDefaultSim] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmationDeleteOpen, setIsConfirmationDeleteOpen] = useState(false);
  const [isConfirmationFaulty, setIsConfirmationFaulty] = useState(false);
  const [completedStages, setCompletedStages] = useState([]);
  const [nextStage, setNextStage] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const [lastSavedStage, setLastSavedStage] = useState(null);
  const [latestFirmware, setLatestFirmware] = useState(null);
  const [meterFirmware, setMeterFirmware] = useState(null);
  const [condition, setCondition] = useState(null);
  const [status, setStatus] = useState(null);
  const [pendingItems, setPendingItems] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isFaultyModalOpen, setIsFaultyModalOpen] = useState(false);
  const classes = useStyles();
  const { filters } = useStoreFilters(FILTER_PAGE.AWAITING_TESTING_CONFIG);

  const isConfigurable = useMemo(
    () => condition === "Awaiting Configuration" && status === "Succeeded",
    [condition, status]
  );

  const openFaultyModal = useCallback(() => {
    if (status === "Failed") setIsConfirmationFaulty(true);
    else setIsFaultyModalOpen(true);
  }, [status]);

  const closeFaultyModal = useCallback(() => {
    setIsConfirmationFaulty(false);
    setIsFaultyModalOpen(false);
  }, []);

  const closeFlowModal = useCallback(() => setShowFlowModal(false), []);
  const openFlowModal = useCallback(() => setShowFlowModal(true), []);

  useEffect(() => {
    if (currentStage !== lastSavedStage && status === "In Progress") {
      setLastSavedStage(currentStage);
      sendStageMessage();
    }
  }, [lastSavedStage, currentStage, status]);

  const sendStageMessage = useCallback(() => {
    currentStage === "UpdateSettings" && warnMessage("Update Settings stage is ‘IN PROGRESS’");
    (currentStage === "UpdateFirmware" || nextStage === "UpdateFirmware") &&
      warnMessage(
        `Update Firmware stage is ‘IN PROGRESS’. The meter firmware is updated from ${meterFirmware} to ${latestFirmware}.`
      );
    status === "Succeeded" &&
      !completedStages.includes("UpdateFirmware") &&
      warnMessage(`Update Firmware stage is ‘SKIPPED’. The meter firmware is ${meterFirmware}.`);
  }, [currentStage, latestFirmware, status, meterFirmware, nextStage]);

  const config = useMemo(
    () => ({
      channel: "NGI Dashboard",
    }),
    []
  );

  const normalizedMeterDetails = useMemo(
    () => [
      { text: "Update Period (seconds)", value: meterDetails?.dataUploadPeriod },
      { text: "Low Battery Voltage Warning", value: meterDetails?.warningLowBatteryVoltage },
      { text: `Low Credit Balance Warning (${USER_CURRENCY})`, value: meterDetails?.warningLowCreditBalance },
      { text: "Low Gas Mass Warning (kg)", value: meterDetails?.warningLowGasVolumeAlarm },
      { text: "Currency", value: meterDetails?.meterCurrency },
      { text: "Sensor Intercept", value: meterDetails?.sensorIntercept },
      { text: "Sensor Slope", value: meterDetails?.sensorSlope },
      { text: "Firmware Version", value: meterDetails?.modemFirmwareVersion },
    ],
    [meterDetails]
  );

  const handleDataChanged = useCallback(res => {
    !!res?.data?.result?.completedStages && setCompletedStages(res.data.result.completedStages);
    !!res?.data?.result?.nextStage && setNextStage(res.data.result.nextStage);
    !!res?.data?.result?.currentStage && setCurrentStage(res.data.result.currentStage);
    !!res?.data?.result?.status && setStatus(res.data.result.status);
    !!res?.data?.result?.data?.reportId && setReportId(res.data.result.data.reportId);
    !!res?.data?.result?.data?.latestFirmware && setLatestFirmware(res.data.result.data.latestFirmware);
    !!res?.data?.result?.data?.meterFirmware && setMeterFirmware(res.data.result.data.meterFirmware);
    !!res?.data?.result?.pendingItems && setPendingItems(res.data.result.pendingItems);
    !!res?.data?.result?.errors && setErrors(res.data.result.errors);
    !!res?.data?.result?.errors?.length && warnMessage(res.data.result.errors[0]);
  }, []);

  useEffect(() => {
    setLoader(true);
    retrieveJourneyDetailsAPI({ journey: "MeterConfiguration", meterId: activeItem.id }).then(async res => {
      handleDataChanged(res);
      if (!!res?.data?.result?.completedAt || !!res?.data?.result?.completedStages?.includes("VerifyBatteries")) {
        setLoader(false);
      }
    });
  }, [activeItem]);

  useEffect(() => {
    onRetrieveMeterDetails();
  }, []);

  useEffect(() => {
    if (status === "Succeeded") onRetrieveMeterDetails();
  }, [status]);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const openConfirmationDeleteModal = useCallback(() => setIsConfirmationDeleteOpen(true), []);
  const closeConfirmationDeleteModal = useCallback(() => setIsConfirmationDeleteOpen(false), []);

  const handleDeleteMeterJourney = useCallback(() => {
    setLoader(true);
    deleteMeterJourney(activeItem.id)
      .then(async () => {
        closeConfirmationDeleteModal();
        onClose();
        updateData(filters);
        warnMessage(`Meter configuration process was deleted successfully.`);
        setLoader(false);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [activeItem, filters]);

  const onRetrieveMeterDetails = useCallback(() => {
    setLoader(true);
    retrieveMeterDetails(activeItem.id)
      .then(async res => {
        const {
          data: { result },
        } = res;
        setMeterDetails(result);
        setCondition(result?.condition);
        const defaultData = {
          simCardIccid: !!result.removableIccid ? result.removableIccid : result?.iccid,
          serverURL: result?.serverURL || "",
        };
        form.setFieldsValue(defaultData);
        setDefaultSim(defaultData.simCardIccid);
        setLoader(false);
        return defaultData;
      })
      .catch(err => {
        if (err?.response?.data?.errors[0]?.code === "NOT_FOUND") return;
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [activeItem]);

  const oRetrieveAsyncProcessStatusResults = useCallback(async ({ journey, meterId, stage }) => {
    setLoader(true);
    setTimeout(async () => {
      await retrieveJourneyDetailsAPI({ journey, meterId })
        .then(async res => {
          handleDataChanged(res);
          if (
            !!res?.data?.result?.completedAt ||
            res?.data?.result?.status === "Succeeded" ||
            res?.data?.result?.status === "Failed" ||
            res?.data?.result?.completedStages?.length === 1
          ) {
            if (res?.data?.result?.errors?.length > 0) {
              warnMessage(`${res.data.result.errors}`);
              setFailAlert(true);
            } else {
              setFailAlert(false);
            }
            setLoader(false);
            closeConfirmationModal();
          } else {
            if (res?.data?.result?.nextStage === "UpdateFirmware") setLoader(false);
            else await oRetrieveAsyncProcessStatusResults({ journey, meterId, stage });
          }
        })

        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoader(false);
        });
    }, 10000);
  }, []);

  const handleMarkFaulty = useCallback(() => {
    setLoader(true);
    const customConfig = {
      reportId,
      isFaulty: true,
      channel: "NGI Dashboard",
      comment,
    };
    configureMeter(activeItem.id, customConfig)
      .then(async () => {
        setLoader(false);
        warnMessage("Meter configuration is Faulty. Meter is ready for repair.");
        closeFaultyModal();
        onClose();
        updateData(filters);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [reportId, comment, filters]);

  const handleAutoConfig = useCallback(
    values => {
      const customConfig = { ...config };
      if (nextStage?.includes("UpdateFirmware") || currentStage === "UpdateFirmware")
        customConfig.stage = "UpdateFirmware";
      else customConfig.stage = "UpdateSettings";

      if (values.simCardIccid !== defaultSim) customConfig.simCardIccid = values.simCardIccid;
      setLoader(true);
      configureMeter(activeItem.id, customConfig)
        .then(async () => {
          openFlowModal();
          await oRetrieveAsyncProcessStatusResults({
            journey: "MeterConfiguration",
            meterId: activeItem.id,
            stage: customConfig.stage,
          });
        })
        .catch(err => {
          defaultNgiErrorMethod(err);
          setLoader(false);
          closeConfirmationModal();
          if (err?.response?.data?.errors[0]?.code === "JOURNEY_STAGE_IS_ALREADY_RUNNING") {
            oRetrieveAsyncProcessStatusResults({
              journey: "MeterConfiguration",
              meterId: activeItem.id,
              stage: customConfig.stage,
            });
          }
        });
    },
    [activeItem, completedStages, nextStage, currentStage]
  );

  const handleClose = useCallback(() => {
    if (status === "Succeeded") updateData(filters);
    if (loading) openConfirmationModal();
    else onClose();
  }, [loading, status]);

  const handleCancel = useCallback(() => {
    if (status === "Succeeded") updateData(filters);
    if (loading) openConfirmationDeleteModal();
    else onClose();
  }, [loading, status, filters]);

  const handleUpdate = useCallback(() => {
    updateData(filters);
    onClose();
  }, [filters]);

  const renderErrors = useCallback(
    () => (
      <div className={classes.error}>
        {errors.map(item => (
          <div key={item} className={classes.information}>
            <img className={classes.informationIcon} src={infoRed} alt="info" />
            Error: (<span className={classes.red}>{item}</span>)
          </div>
        ))}
      </div>
    ),
    [errors]
  );

  const renderJobs = useCallback(
    stage => {
      const filteredItems = pendingItems?.filter(item => item.stage === stage);
      if (!!filteredItems?.length && status === "In Progress")
        return (
          <div className={classes.error}>
            {filteredItems.map(item => (
              <div key={item.subType} className={classes.information}>
                <img className={classes.informationIcon} src={infoBlue} alt="info" />
                {item.subType}: (<span className={classes.blue}>{item.status}</span>)
              </div>
            ))}
          </div>
        );
    },
    [errors, pendingItems, status]
  );

  const printQrCode = useCallback(() => {
    const printEl = document.getElementById("printArea");
    printEl.style.display = "block";
    printEl.style.display = "none";
    printFrame("meterQrCode", `<html><body>${printEl.innerHTML}</></body></html>`);
  }, []);

  return (
    <Modal
      open={isOpen}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>Meter Configuration</div>
          <CustomButton
            disabled={!activeItem?.barcode}
            icon={<img src={printIcon} alt="printQrcode" />}
            size="small"
            color="outlined"
            text="PRINT QR CODE"
            onClick={printQrCode}
          />
        </div>
      }
      onCancel={handleClose}
      footer={[]}
      closable={false}
    >
      <iframe
        id="meterQrCode"
        style={{ left: "-1000%", height: "200px", width: "200px", position: "absolute", zIndex: 0 }}
      ></iframe>
      <div style={{ left: "-1000%", display: "none", position: "absolute", zIndex: 0 }} id="printArea">
        {activeItem?.barcode !== "UNKNOWN" && <QRCode size={180} value={activeItem?.barcode} />}
      </div>
      <>
        <Spin spinning={loading}>
          <div className="meter-details">
            <div className="info">
              <div className="column">
                <span className="title">meter serial number</span>
                <span className="description">{meterDetails?.serial}</span>
                <span className="title">meter barcode</span>
                <span className="description">{meterDetails?.barcode}</span>
                <span className="title">pcb serial number</span>
                <span className="description">{meterDetails?.pcb}</span>
              </div>
              <div className="column">
                <span className="title">sensor serial number</span>
                <span className="description">{meterDetails?.sensorSerial}</span>
                <span className="title">sensor type</span>
                <span className="description">{meterDetails?.sensorType}</span>
                <span className="title">collected date</span>
                <span className="description">{meterDetails?.maintenanceStartDate}</span>
              </div>
            </div>
          </div>
          <Form className="meter-configuration-modal" form={form} onFinish={handleAutoConfig}>
            {!showFailAlert && (
              <>
                <Row className="space-between">
                  <Col span={24} className={classes.commentValue}>
                    <div className={classname(classes.commentText, "comment-text")}>SIM CARD</div>
                    <Form.Item name="simCardIccid" rules={[{ required: true, message: "This field can not be empty" }]}>
                      <NGIInput />
                    </Form.Item>
                  </Col>
                </Row>
                {form.getFieldValue("serverURL") && (
                  <Row className="space-between">
                    <Col span={24} className={classes.commentValue}>
                      <div className={classname(classes.commentText, "comment-text")}>SERVER URL</div>
                      <Form.Item name="serverURL">
                        <NGIInput disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </>
            )}

            {showFailAlert && (
              <div className="comments">
                <div className={classname("comment-text", classes.commentText)}>Additional Comments</div>

                <Form.Item name="comment">
                  <NGITextArea
                    classname="comment-area"
                    placeholder="Please add additional comments here"
                    onChange={e => {
                      setComment(e.target.value);
                    }}
                  ></NGITextArea>
                </Form.Item>
              </div>
            )}
          </Form>
          {isConfigurable ? (
            <>
              <Row className="space-between">
                <Col span={24}>
                  <Title level={5}>Automatic configuration process</Title>

                  <div className={classname(classes.status, classes.fail)}>
                    <div className={classes.left}>
                      <div className={classes.statusText}>
                        <div className={classes.statusCommonTitle}>1. UPDATE METER SETTINGS</div>
                      </div>
                    </div>
                    <div className={classes.right}>
                      <div className={classes.result}>NOT STARTED</div>
                    </div>
                  </div>
                  <div className={classname(classes.status, classes.fail)}>
                    <div className={classes.left}>
                      <div className={classes.statusText}>
                        <div className={classes.statusCommonTitle}>2. UPDATE FIRMWARE</div>
                      </div>
                    </div>
                    <div className={classes.right}>
                      <div className={classes.result}>NOT STARTED</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="space-between">
                <Col span={24}>
                  <Title level={5}>Automatic configuration process</Title>

                  <div
                    className={classname(
                      classes.status,
                      completedStages.includes("UpdateSettings") && status === "Succeeded" ? classes.good : classes.fail
                    )}
                  >
                    <div className={classes.left}>
                      <div className={classes.statusText}>
                        <div className={classes.statusCommonTitle}>1. UPDATE METER SETTINGS</div>
                      </div>
                    </div>
                    <div className={classes.right}>
                      {completedStages.includes("UpdateSettings") ? (
                        <>
                          <div className={classes.result}>DONE</div>
                          <img src={iconOk} alt="ok" />
                        </>
                      ) : status === "Failed" ? (
                        <div className={classes.result}>UPDATE FAILED</div>
                      ) : (
                        <div className={classes.result}>NOT STARTED</div>
                      )}
                    </div>
                    {(currentStage === "UpdateSettings" || currentStage === "VerifyFirmware") &&
                      status === "Failed" &&
                      !!errors.length &&
                      renderErrors()}
                    {renderJobs("UpdateSettings")}
                    {renderJobs("VerifyFirmware")}
                  </div>
                  <div
                    className={classname(
                      classes.status,
                      completedStages.includes("UpdateFirmware") &&
                        (nextStage === "UpdateSettings" || completedStages.includes("UpdateSettings"))
                        ? classes.good
                        : classes.fail
                    )}
                  >
                    <div className={classes.left}>
                      <div className={classes.statusText}>
                        <div className={classes.statusCommonTitle}>2. UPDATE FIRMWARE</div>
                      </div>
                    </div>
                    <div className={classes.right}>
                      {completedStages.includes("UpdateFirmware") ? (
                        <>
                          <div className={classes.result}>DONE</div>
                          <img src={iconOk} alt="ok" />
                        </>
                      ) : currentStage === "UpdateFirmware" && status === "Failed" ? (
                        <div className={classes.result}>UPDATE FAILED</div>
                      ) : completedStages.includes("VerifyFirmware") &&
                        (!completedStages.includes("UpdateFirmware") ||
                          currentStage !== "UpdateFirmware" ||
                          nextStage !== "UpdateFirmware") &&
                        status === "Succeeded" ? (
                        <div className={classes.result}>SKIPPED</div>
                      ) : (
                        <div className={classes.result}>NOT STARTED</div>
                      )}
                      {(nextStage === "UpdateFirmware" || currentStage === "UpdateFirmware") &&
                        status !== "Succeeded" && (
                          <CustomButton text={"RUN FIRMWARE UPDATE"} size={"small"} onClick={handleAutoConfig} />
                        )}
                    </div>
                    {currentStage === "UpdateFirmware" && status === "Failed" && !!errors.length && renderErrors()}
                    {renderJobs("UpdateFirmware")}
                  </div>
                </Col>
              </Row>
            </>
          )}

          {status === "Succeeded" && !isConfigurable && (
            <>
              <div className={classes.succeedText}>Configuration Process Succeeded</div>

              <div className="meter-details">
                <div className="info">
                  <div className="column">
                    {!!meterDetails?.serverURL && (
                      <div className={classes.flex}>
                        <span className={classname("title", classes.flexTitle)}>Server URL</span>
                        <span className="description">{meterDetails?.serverURL}</span>
                      </div>
                    )}
                    {normalizedMeterDetails.map(item => {
                      if (!item.value) return null;
                      return (
                        <div key={item.text} className={classes.flex}>
                          <span className={classname("title", classes.flexTitle)}>{item.text}</span>
                          <span className="description">{item.value}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </Spin>
        <Space className="with-divider">
          <CustomButton
            onClick={handleCancel}
            type="primary"
            size="small"
            color="outlined"
            text={status === "Succeeded" ? "Close" : "Cancel"}
          />
          {(status !== "Succeeded" || !!isConfigurable) && (
            <div>
              <CustomButton
                className={classes.buttonFaulty}
                onClick={openFaultyModal}
                text={"Mark as faulty"}
                size={"small"}
                disabled={loading}
              />
              <CustomButton
                disabled={
                  ((status === "Succeeded" || completedStages.includes("VerifyFirmware")) && !isConfigurable) || loading
                }
                onClick={() => form.submit()}
                type="primary"
                size="small"
                text={"Run Automatic config"}
              />
            </div>
          )}
        </Space>
      </>
      {showFlowModal && (
        <InfoModal
          hideButton
          autoClose
          onCancel={closeFlowModal}
          description={
            "To run the process you need to long press the meter and wait up to 240s for the hardware report."
          }
        />
      )}
      {isConfirmationFaulty && (
        <ConfirmationModal
          description={
            <span>Are you sure you want to mark the meter as faulty and terminate the configuration process?</span>
          }
          onCancel={closeFaultyModal}
          onConfirm={handleMarkFaulty}
        />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          description={
            <span>
              The configuration has already started.
              <b>The closing of the popup doesn't stop the process of configuration.</b> Are you sure?
            </span>
          }
          onCancel={closeConfirmationModal}
          onConfirm={onClose}
        />
      )}
      {isConfirmationDeleteOpen && (
        <ConfirmationModal
          description={
            <span>
              <b> The configuration process will be cancelled.</b> Are you sure?
            </span>
          }
          onCancel={closeConfirmationDeleteModal}
          onConfirm={handleDeleteMeterJourney}
        />
      )}
      {isFaultyModalOpen && (
        <MarkAsFaultyModal
          onUpdate={handleUpdate}
          isOpen={isFaultyModalOpen}
          isLoading={loading}
          activeItem={meterDetails}
          onClose={closeFaultyModal}
        />
      )}
    </Modal>
  );
};

export default MeterConfigurationModal;
