import { textFormatter, customDateFormatWithTime, camelCaseTextFormatter } from "components/Common/formatter";
import React from "react";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import copyRoutine from "assets/images/svg/CopyRoutine.svg";
import { USER_ROLES } from "utils/constants";
import { userHasOneOfRoles } from "utils/helpers/functions";

const color = "#444444";

const hideRoles = [USER_ROLES.A_SM, USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP];
export const routineTaskColumns = ({ openRoutine, status }) => [
  {
    title: "Assignee",
    dataIndex: "",
    key: "assigneeName",
    render: row =>
      userHasOneOfRoles(hideRoles) ? (
        textFormatter(row.assigneeName)
      ) : (
        <Tooltip placement="bottom" title={`${row.assigneeName} details`} color={color}>
          <div>
            <CustomLink to={`/operations/routine/${row.id}`}>{textFormatter(row.assigneeName)}</CustomLink>
          </div>
        </Tooltip>
      ),
    width: "15%",
  },
  {
    title: "Depot",
    dataIndex: "depot",
    key: "depot",
    render: (cellContent, routine) => textFormatter(routine.depot),
    width: "10%",
  },
  {
    dataIndex: "assigneePhone",
    key: "assigneePhone",
    title: "Contact",
    render: (cellContent, routine) => textFormatter(routine.assigneePhone),
    width: "15%",
  },

  {
    title: "Assets Count",
    dataIndex: "assetsCount",
    key: "assetsCount",
    render: (cellContent, routine) => textFormatter(routine.assetsCount),
    width: "10%",
  },

  {
    dataIndex: "updatedAt",
    key: "updatedAt",
    title: "Updated At",
    render: (cellContent, routine) => textFormatter(customDateFormatWithTime(routine.updatedAt)),
    width: "15%",
  },
  {
    dataIndex: "taskCount",
    key: "taskCount",
    title: "Tasks Count",
    render: textFormatter,
    width: "15%",
  },
  {
    dataIndex: "assigneeRole",
    key: "assigneeRole",
    title: "Role",
    render: camelCaseTextFormatter,
    width: "15%",
  },
  ...(!userHasOneOfRoles([
    USER_ROLES.A_DSM,
    USER_ROLES.D_SM,
    USER_ROLES.OPERATION_MANAGERS,
    USER_ROLES.SALES_SUPPORT,
    USER_ROLES.DEPOT_CLERK,
    USER_ROLES.GENERAL,
  ])
    ? [
        {
          dataIndex: "",
          title: "",
          width: "4%",
          render: row => {
            if (status === "Close")
              return (
                <ButtonGroup
                  hideRoles={[
                    USER_ROLES.A_DSM,
                    USER_ROLES.D_SM,
                    USER_ROLES.OPERATION_MANAGERS,
                    USER_ROLES.SALES_SUPPORT,
                    USER_ROLES.DEPOT_CLERK,
                    USER_ROLES.GENERAL,
                  ]}
                  tooltipTitle="Open Routine"
                  icon={copyRoutine}
                  handleClick={() => {
                    openRoutine(row);
                  }}
                />
              );
          },
        },
      ]
    : []),
];
