import React, { useCallback, useEffect, useMemo } from "react";
import "react-drawer/lib/react-drawer.css";
import MetaData from "components/VerticalLayout/MetaData";
import { useHistory } from "react-router";
import { AUTH_USER } from "utils/constants";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";
import { userRoutes } from "routes/allRoutes";

const Breadcrumbs = ({ currentPage }) => {
  const history = useHistory();
  const { crumbs, pageTitle, subSections } = useMemo(() => {
    const mappedCrumbs = userRoutes.filter(({ path }) => currentPage.includes(path)).filter(({ name }) => name);
    const lastItem = mappedCrumbs[mappedCrumbs.length - 1];
    return {
      crumbs: mappedCrumbs,
      pageTitle: lastItem?.name,
      subSections: lastItem?.subSections,
    };
  }, [currentPage]);

  const checkHasAccess = useCallback(
    el =>
      !currentPage.includes("no-access") &&
      !!el?.roles?.length &&
      !userHasOneOfRoles(el?.roles) &&
      !userHasAdminRole() &&
      history.push("/no-access"),
    [currentPage, history, AUTH_USER]
  );

  useEffect(() => {
    checkHasAccess(crumbs[crumbs.length - 1]);
  }, [crumbs, history]);

  return (
    <div className="breadcrumbs">
      <MetaData pageTitle={pageTitle} />
      <span className="title">{pageTitle}</span>
      <div>
        {subSections &&
          subSections?.map(item => (
            <span key={`breadcrumbs-item-${item}`} className="breadcrumbs-item">
              {item}
            </span>
          ))}
        {crumbs.map(item => (
          <span key={`breadcrumbs-item-${item?.name}`} className="breadcrumbs-item">
            {item?.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
