import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col } from "antd";
import Title from "antd/lib/typography/Title";
import { CardBody } from "reactstrap";
import { getFormattedKeyName } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  cardBody: {
    padding: 0,
  },
  title: {
    fontSize: "12px !important",
    fontWeight: "300 !important",
    lineHeight: 15,
    textTransform: "uppercase",
    marginBottom: "0 !important",
  },
  value: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 20,
  },
});

const View = ({ data }) => {
  const classes = useStyles();

  return (
    <CardBody className={classes.cardBody}>
      {Object.keys(data).map(item => {
        if (!data[item]) return null;
        if (item === "name") return null;
        return (
          <Col key={item} span={24}>
            <Title className={classes.title} level={5}>
              {getFormattedKeyName(item)}:
            </Title>
            <div className={classes.value}>{data[item]}</div>
          </Col>
        );
      })}
    </CardBody>
  );
};

export default View;
