import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import { getFilteredUsersLegacyAPI, getUsersLegacyAPI } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import B2cTable from "components/Common/table";
import UserModal from "./userModal";
import { userListColumns } from "./userListColumns";
import UsersSearch from "./userSearch";
import NGISpinner from "components/Common/NGISpinner";

const Users = () => {
  const [isLoading, setLoader] = useState(false);
  const [userList, setUserList] = useState([{}]);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState(null);
  const [modalActionType, setModalActionType] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFiltered, setFiltered] = useState(false);
  const [dataHasNextPage, setDataHasNextPage] = useState(false);
  const [dataHasPreviousPage, setDataHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);

  const handleAction = async (item, type) => {
    setActiveItem(item);
    setModalActionType(type);
    openModal();
  };

  useEffect(() => {
    handleFilter(pageNumber);
  }, []);

  const handleFilter = async (page = {}) => {
    setFilter(null);
    setLoader(true);
    setFiltered(false);
    await getUsersLegacyAPI(page)
      .then(async res => {
        const {
          data: {
            results,
            meta: { pageData },
          },
        } = res;
        if (results.length === 0) {
          warnMessage(`No Users found`);
          setLoader(false);
          setUserList(null);
          return;
        }
        setUserList(results || []);
        setLoader(false);
        const { hasNextPage, hasPreviousPage, pageNum } = pageData;
        setDataHasNextPage(hasNextPage);
        setDataHasPreviousPage(hasPreviousPage);
        setPageNumber(pageNum);
      })
      .catch(err => {
        setLoader(false);
        setUserList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const handleSearch = async (filterOptions = {}, pageNumber) => {
    setLoader(true);
    setFilter(filterOptions);
    setFiltered(true);
    await getFilteredUsersLegacyAPI({ type: filterOptions.type, value: filterOptions.value, pageNumber })
      .then(res => {
        const {
          data: {
            results,
            meta: { pageData },
          },
        } = res;
        if (results?.length === 0) {
          warnMessage("Users were not found");
          setLoader(false);
          setUserList(null);
          return;
        }
        setUserList(results || []);
        setLoader(false);
        const { hasNextPage, hasPreviousPage, pageNum } = pageData;
        setDataHasNextPage(hasNextPage || false);
        setDataHasPreviousPage(hasPreviousPage || false);
        setPageNumber(pageNum || 1);
      })
      .catch(err => {
        setLoader(false);
        setUserList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const onNextPage = async () => {
    if (isFiltered) await handleSearch(filter, pageNumber + 1);
    else await handleFilter(pageNumber + 1);
  };
  const onPreviousPage = async () => {
    if (isFiltered) await handleSearch(filter, pageNumber - 1);
    else await handleFilter(pageNumber - 1);
  };

  return (
    <>
      <div className="page-content" data-testid="metersComponent">
        <Container fluid>
          <UsersSearch handleSearch={handleSearch} isLoading={isLoading} />
          <Col lg={12}>
            {isLoading ? (
              <NGISpinner />
            ) : (
              <>
                {!!userList ? (
                  <B2cTable
                    enableSearch={false}
                    columns={userListColumns({
                      onAction: handleAction,
                    })}
                    data={userList}
                    hasNextpage={dataHasNextPage}
                    hasPreviousPage={dataHasPreviousPage}
                    onNextPage={onNextPage}
                    onPreviousPage={onPreviousPage}
                  />
                ) : (
                  <Card>
                    <CardBody className="text-center">
                      <i className="bx bx-warning" />
                      <h5>No Users available</h5>
                      <p className="text-muted">No data.</p>
                    </CardBody>
                  </Card>
                )}
              </>
            )}
          </Col>
        </Container>
        {isModalVisible && (
          <UserModal
            item={activeItem}
            onClose={closeModal}
            isVisible={isModalVisible}
            type={modalActionType}
            updateData={handleFilter}
            handleSearch={handleSearch}
            filter={filter}
            pageNumber={pageNumber}
          />
        )}
      </div>
    </>
  );
};

export default Users;
