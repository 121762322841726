import { TYPE_NAMES } from "pages/meter-management/maintenanceReports/variables";

const getFlatObjData = obj => {
  const result = {};
  for (const key in obj) {
    if (typeof obj[key] === "object" && Array.isArray(obj[key])) {
      if (typeof obj[key][0] === "object") {
        obj[key].forEach((item, number) => {
          const subFlatObject = getFlatObjData(item);
          for (const subKey in subFlatObject) {
            result[`${key}_${subKey}_${number}`] = subFlatObject[subKey].toString();
          }
        });
      } else result[key] = obj[key].join(", ");
    } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      const subFlatObject = getFlatObjData(obj[key]);
      for (const subKey in subFlatObject) {
        result[`${key}_${subKey}`] = subFlatObject[subKey].toString();
      }
    } else result[key] = obj[key] ? obj[key].toString() : "";
  }
  return result;
};

export const getFlatDataArray = array => array.map(item => getFlatObjData(item));

export const getSelectedFilterProp = filters => {
  if (filters[TYPE_NAMES.DEPOT]) return TYPE_NAMES.DEPOT;
  if (filters[TYPE_NAMES.BARCODE]) return TYPE_NAMES.BARCODE;
  if (filters[TYPE_NAMES.SERIAL]) return TYPE_NAMES.SERIAL;
  if (filters[TYPE_NAMES.TECHNICIAN]) return TYPE_NAMES.TECHNICIAN;
  return null;
};
