import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";

export const validationTaskRequestsListColumns = hide => [
  {
    title: "Customer ID",
    dataIndex: "",
    render: row => {
      if (hide) return textFormatter(row.customerId);
      return <CustomLink to={`asset-validation/${row.id}`}>{textFormatter(row.customerId)}</CustomLink>;
    },
  },
  {
    title: "Depot",
    dataIndex: "",
    render: row => {
      if (hide) return textFormatter(row.depot);
      return <CustomLink to={`asset-validation/${row.id}`}>{textFormatter(row.depot)}</CustomLink>;
    },
  },
  {
    title: "TSR",
    dataIndex: "requestedByName",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Validated",
    dataIndex: "",
    render: row => row.assets.filter(item => item.state === "Validated").length,
  },
  {
    title: "Not Validated",
    dataIndex: "",
    render: row => row.assets.filter(item => item.state === "Not Validated").length,
  },
  {
    title: "Failed",
    dataIndex: "",
    render: row => row.assets.filter(item => item.state === "Failed").length,
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
];
