import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";

export const metersFirmwareUpdateHistoryColumns = [
  {
    title: "Barcode",
    dataIndex: "meterBarcode",
    render: textFormatter,
  },
  {
    title: "MCU Type",
    dataIndex: "mcuType",
    render: textFormatter,
  },
  {
    title: "Old Firmware",
    dataIndex: "oldFirmware",
    render: textFormatter,
  },
  {
    title: "New Firmware",
    dataIndex: "newFirmware",
    render: textFormatter,
  },
  {
    title: "Meter Type",
    dataIndex: "meterType",
    render: textFormatter,
  },
  {
    title: "MCU",
    dataIndex: "controller",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "Triggered At",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    render: customDateFormatWithTime,
  },
  {
    title: "Triggered By",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: textFormatter,
  },
  {
    title: "Retries Count",
    dataIndex: "attempts",
    render: cellContent => textFormatter(cellContent?.length),
  },
];
