import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Option } from "antd/lib/mentions";
import { retrieveTasksStatistics } from "utils/api";
import { Divider, Select } from "antd";
import NGICollapsibleTable from "components/Common/NGICollapsibleTable";
import { statusStatsColumns, taskStatusTypes } from "pages/dashboard/variables";
import { getMappedStatusData } from "pages/dashboard/utils";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { AUTH_USER } from "utils/constants";
import { useSelector } from "react-redux";
import { TSRSelector } from "store/TSR/selectors";
import classnames from "classnames";

const useStyles = makeStyles({
  filter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  filterTitle: {
    padding: 10,
    color: "rgba(140, 140, 140, 1)",
  },
  filterSelect: {
    fontSize: 15,
    "&>.ant-select-selector": {
      border: "0!important",
    },
  },
  divider: {
    margin: 0,
    padding: 10,
  },
  counter: {
    fontSize: 16,
    flexGrow: 1,
    textAlign: "end",
    fontWeight: 500,

    "&>span:first-child": {
      marginRight: 5,
    },
    "&>span:nth-child(2)": {
      marginLeft: 5,
      color: "rgba(23, 195, 71, 1)",
    },
  },
  tsrSelect: {
    maxWidth: 220,
  },
});

export const StatusSection = () => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [stats, setStats] = useState({ list: [], all: 0, complited: 0, mapping: {} });
  const [taskStatusType, setTaskStatusType] = useState(taskStatusTypes[0]);
  const [selectedTSR, setSelectedTSR] = useState(null);
  const { TSRList } = useSelector(TSRSelector);

  useEffect(() => {
    filterData(taskStatusType);
  }, []);

  const onTypeChange = useCallback(value => {
    setTaskStatusType(value);
    setSelectedTSR(null);
    filterData(value, null);
  }, []);

  const onTSRChange = useCallback(
    value => {
      setSelectedTSR(value);
      filterData(taskStatusType, value);
    },
    [taskStatusType]
  );

  const filterData = useCallback((type, filterByEmail = null) => {
    setLoader(true);
    retrieveTasksStatistics(
      { depot: AUTH_USER?.depotName, filterByStatus: type, filterByEmail, filterBySource: "PriorityTasks" },
      "v2"
    )
      .then(res => {
        const data = getMappedStatusData(res?.data?.result?.statusStats);
        setStats(data);
        setLoader(false);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, []);

  return (
    <NGICollapsibleTable
      isLoading={isLoading}
      size="small"
      headerContent={
        <>
          <div className={classes.filter}>
            <div className={classes.filterTitle}>Statuses:</div>
            <Select
              showSearch
              value={taskStatusType}
              className={classes.filterSelect}
              placeholder={"Select type"}
              onChange={onTypeChange}
            >
              {taskStatusTypes.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            {taskStatusType !== "AssignedTo" && (
              <>
                <div className={classes.filterTitle}>TSR:</div>
                <Select
                  showSearch
                  value={selectedTSR}
                  className={classnames(classes.filterSelect, classes.tsrSelect)}
                  placeholder={"Select TSR"}
                  onChange={onTSRChange}
                  allowClear
                >
                  {TSRList.map(item => (
                    <Option key={item.id} value={item.email}>
                      {item.email}
                    </Option>
                  ))}
                </Select>
              </>
            )}
            <div className={classes.counter}>
              <span>{stats.all}</span> / <span>{stats.completed}</span>
            </div>
          </div>

          <Divider className={classes.divider} />
        </>
      }
      data={stats.list}
      columns={statusStatsColumns(taskStatusType, stats.mapping)}
    />
  );
};
