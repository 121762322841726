export const status = { approved: "Approved", declined: "Declined", pending: "PendingApproval" };
export const type = { receive: "ReceiveRequest", return: "ReturnRequest", transfer: "TransferRequest" };
export const searchBy = { type: "type", requestedFor: "requestedFor" };

export const searchByList = [
  { value: searchBy.type, name: "Type" },
  { value: searchBy.requestedFor, name: "Requested For" },
];
export const statuses = [status.approved, status.declined];
export const types = [
  { value: type.receive, name: "Receive" },
  { value: type.return, name: "Return" },
  { value: type.transfer, name: "Transfer" },
];
