import { USER_ROLES } from "utils/constants";

export const TABS = {
  DETAILS: "DETAILS",
  EDIT: "EDIT",
};

export const TABS_BUTTONS = [
  {
    title: "Team Information",
    key: TABS.DETAILS,
  },
  {
    title: "Team Members",
    key: TABS.EDIT,
    hideRoles: [USER_ROLES.INTERNAL_AUDITOR, USER_ROLES.GENERAL, USER_ROLES.FINANCE],
  },
];

export const STATE = {
  OLD: "OLD",
  REMOVE: "Remove",
  ADD: "Add",
};
