import React, { useState, useEffect, useCallback } from "react";
import { Modal, Tabs, Table, Spin } from "antd";
import { cumulativeReportColumns, meterStatusColumns, alertColumns, reportColumns } from "./meterReportColumns";
import {
  retrieveMeterSettings,
  retrieveHardwareReports,
  retrieveMeterStatus,
  readAlertsAPI,
  readCumulativeReportsAPI,
} from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";

const ResponseModel = props => {
  const { TabPane } = Tabs;

  const [isLoading, setLoading] = useState(false);
  const [settingValues, setSettingValues] = useState(null);
  const [reportValues, setReportValues] = useState(null);
  const [initialValues, setinitialValues] = useState(null);
  const [cumulativeValues, setCumulativeValues] = useState(null);
  const [statusValues, setStatusValues] = useState(null);
  const { isVisible, onCancel, getAssetId } = props;

  const onClose = useCallback(() => {
    onCancel();
  }, []);

  useEffect(() => {
    onGetSettings();
    onGetHardware();
    onViewAlerts();
    onViewCumulative();
    onViewStatus();
  }, []);

  const onGetSettings = async () => {
    setLoading(true);
    await retrieveMeterSettings(getAssetId)
      .then(async res => {
        if (res.data.result) {
          setLoading(false);
          setSettingValues([res.data.result]);
        } else {
          setSettingValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };

  const onGetHardware = async () => {
    await retrieveHardwareReports(getAssetId)
      .then(async res => {
        if (res.data.result.items) {
          setReportValues(res.data.result.items);
        } else {
          setReportValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };

  const onViewCumulative = async () => {
    await readCumulativeReportsAPI({ meterId: getAssetId })
      .then(async res => {
        if (res.data.result.items) {
          setCumulativeValues(res.data.result.items);
        } else {
          setCumulativeValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };

  const onViewStatus = async () => {
    await retrieveMeterStatus(getAssetId)
      .then(async res => {
        if (res.data.result.items) {
          setStatusValues(res.data.result.items);
        } else {
          setStatusValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };

  const onViewAlerts = async () => {
    await readAlertsAPI({ meterId: getAssetId })
      .then(async res => {
        if (res.data.result.items) {
          setinitialValues(res.data.result.items);
        } else {
          setinitialValues(null);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  };

  const columns = [
    // {
    //   title: "Intercept",
    //   dataIndex: "sensorIntercept",
    //   key: "sensorIntercept",
    // },
    // {
    //   title: "Slope",
    //   dataIndex: "sensorSlope",
    //   key: "sensorSlope",
    // },
    {
      title: "Start Period",
      dataIndex: "startPeriod",
      key: "startPeriod",
    },
    {
      title: "Update Period",
      dataIndex: "updatePeriod",
      key: "updatePeriod",
    },
    {
      title: "Voltage Warn",
      dataIndex: "warningLowBatteryVoltage",
      key: "warningLowBatteryVoltage",
    },
    {
      title: "Credit Warn",
      dataIndex: "warningLowCreditBalance",
      key: "warningLowCreditBalance",
    },
    {
      title: "Gas Warn",
      dataIndex: "warningLowGasMass",
      key: "warningLowGasMass",
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
    {
      title: "Gas Level",
      dataIndex: "gasRemaining",
      key: "gasRemaining",
    },
  ];

  return (
    <Modal title="Meter Status Report" visible={isVisible} onCancel={onClose} footer={[]} width={900}>
      <>
        <Spin spinning={isLoading}>
          <Tabs type="card" tabBarExtraContent={""}>
            <TabPane tab="Settings" key="1">
              {settingValues && (
                <>
                  <Table
                    columns={columns}
                    dataSource={settingValues.map((item, index) => ({ ...item, key: index.toString() }))}
                    scroll={{ x: 1200, y: 300 }}
                  />
                </>
              )}
            </TabPane>

            <TabPane tab="Hardware Report" key="2">
              {reportValues?.length > 0 && (
                <>
                  <Table
                    columns={reportColumns}
                    dataSource={reportValues.map((item, index) => ({ ...item, key: index.toString() }))}
                    scroll={{ x: 1400, y: 300 }}
                  />
                </>
              )}
            </TabPane>

            <TabPane tab="Alerts" key="3">
              {initialValues?.length > 0 && (
                <>
                  <Table
                    columns={alertColumns}
                    dataSource={initialValues.map((item, index) => ({ ...item, key: index.toString() }))}
                    scroll={{ x: 900, y: 300 }}
                  />
                </>
              )}
            </TabPane>

            <TabPane tab="Cumulative Report" key="4">
              {cumulativeValues?.length > 0 && (
                <>
                  <Table
                    columns={cumulativeReportColumns}
                    dataSource={cumulativeValues.map((item, index) => ({ ...item, key: index.toString() }))}
                    scroll={{ x: 1500, y: 300 }}
                  />
                </>
              )}
            </TabPane>

            <TabPane tab="Meter Status" key="5">
              {statusValues?.length > 0 && (
                <>
                  <Table
                    columns={meterStatusColumns}
                    dataSource={statusValues.map((item, index) => ({ ...item, key: index.toString() }))}
                    scroll={{ x: 1500, y: 300 }}
                  />
                </>
              )}
            </TabPane>
          </Tabs>
        </Spin>
      </>
    </Modal>
  );
};

export default ResponseModel;
