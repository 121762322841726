import React from "react";
import { Tooltip } from "antd";

const color = "#444444";

const NGITooltip = ({ title, children, ...props }) => (
  <Tooltip {...props} placement="bottom" title={title} color={color}>
    <div>{children}</div>
  </Tooltip>
);

export default NGITooltip;
