import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Space, Spin, Typography } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { Option } from "antd/lib/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import NGISelect from "components/Common/inputs/NGISelect";
import NGITextArea from "components/Common/inputs/NGITextArea";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { bulkCancelTasksAPI, cancelTaskApi } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { getTrimmedObjectValues } from "utils/helpers/functions";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { taskSourceFormatter } from "pages/operations/tasks/variables";

const { Title } = Typography;
const CancelTaskModal = ({
  onClose,
  initialValues,
  updateTable,
  bulkValues,
  resetSelectedRowKeys,
  setLoader,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const { filters } = useStoreFilters(FILTER_PAGE.TASK);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const {
    list: { taskCategory },
  } = useSelector(DictionariesSelector);

  const filteredCategories = useMemo(
    () =>
      taskCategory?.filter(
        item => item !== "New Installation" && item !== "Asset Collection" && item !== initialValues?.category
      ),
    [taskCategory, initialValues]
  );

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.TASK_CATEGORY));
  }, []);

  const onSave = useCallback(
    values => {
      setLoading(true);
      if (!!bulkValues?.length) {
        bulkCancelTasksAPI({ ...getTrimmedObjectValues(values), taskIds: bulkValues })
          .then(() => {
            successMessage(`Tasks were cancelled successfully`);
            setLoader(true);
            setTimeout(() => updateTable(filters), 1500);
            resetSelectedRowKeys([]);
            onClose();
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
            defaultNgiErrorMethod(error);
          });
      } else {
        const { id } = initialValues;
        const payload = { id, ...values };
        cancelTaskApi(payload)
          .then(() => {
            successMessage("Task is declined. Task status is Cancelled.");
            !!onSuccess
              ? setTimeout(() => {
                  onSuccess();
                  setLoading(false);
                }, 500)
              : setLoading(false);
            onClose();
            setTimeout(() => updateTable(filters), 1500);
          })
          .catch(err => {
            setLoading(false);
            defaultNgiErrorMethod(err);
          });
      }
    },
    [initialValues, bulkValues, filters]
  );

  const handleChangeReason = useCallback(value => {
    form.setFieldValue("reason", value);
    setShowCategory(value === "Task Replacement");
  }, []);

  return (
    <Modal title={"Confirmation"} open={true} onCancel={onClose} closable={false} footer={[]}>
      <>
        <Spin spinning={loading}>
          {!!bulkValues?.length ? (
            <p>{`Are you sure you want to cancel selected tasks?`}</p>
          ) : (
            <p>{`Are you sure you want to cancel task ${initialValues?.category} for Customer ${initialValues?.customer?.name}?`}</p>
          )}

          <Form form={form} onFinish={onSave} data-testid={"CancelTaskFormId"}>
            <Col span={24}>
              <Title data-testid={"reasonTitleId"} level={5}>
                Reason
              </Title>
              <Form.Item name="reason" rules={[{ required: true, message: "Please select Reason" }]}>
                <NGISelect
                  showSearch
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  size={"default"}
                  placeholder={"Select reason"}
                  onChange={handleChangeReason}
                >
                  <Option value={"Need Repossession"}>Need Repossession</Option>
                  <Option value={"Not Relevant"}>Not Relevant</Option>
                  {taskSourceFormatter(initialValues) === "CC" && initialValues?.status === "Assigned" && (
                    <Option value={"Task Replacement"}>Task Replacement</Option>
                  )}
                </NGISelect>
              </Form.Item>
            </Col>
            {showCategory && (
              <Col span={24}>
                <Title level={5}>Category</Title>
                <Form.Item name="newTask" rules={[{ required: true, message: "Please select Category" }]}>
                  <NGISelect
                    showSearch
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    size={"default"}
                    placeholder={"Select category"}
                  >
                    {filteredCategories?.map(reason => (
                      <Option value={reason} key={reason}>
                        {reason}
                      </Option>
                    ))}
                  </NGISelect>
                </Form.Item>
              </Col>
            )}
            <Col>
              <Title data-testid={"commentTitleId"} level={5}>
                Comments
              </Title>
              <Form.Item name="comments" rules={[{ required: true, message: "Please enter comments" }]}>
                <NGITextArea rows={4} data-testid={"commentValueId"} placeholder="Enter comments" />
              </Form.Item>
            </Col>
            <Col>
              <Space>
                <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
                <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
              </Space>
            </Col>
          </Form>
        </Spin>
      </>
    </Modal>
  );
};

export default CancelTaskModal;
