import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Spin } from "antd";
import Header from "pages/meter-management/logs/outgoingLogs/outgoingLogsDetails/Header";
import DataTabs from "pages/meter-management/logs/outgoingLogs/outgoingLogsDetails/DataTabs";
import { getOutgoingLogsAPI } from "utils/api";
import { useParams } from "react-router";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";

const OutgoingLogsDetails = () => {
  const { id } = useParams();

  const [log, setLog] = useState({});
  const { filters } = useStoreFilters(FILTER_PAGE.OUTGOING_LOGS);

  useEffect(() => {
    getOutgoingLogsAPI(filters).then(res => {
      const {
        data: {
          result: { items },
        },
      } = res;
      const foundLog = items.find(item => item.id === id);
      setLog(foundLog);
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Spin spinning={!log?.id}>
            <>
              <Header log={log} />
              <DataTabs log={log} />
            </>
          </Spin>
        </Container>
      </div>
    </>
  );
};

export default OutgoingLogsDetails;
