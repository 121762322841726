import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { camelCaseTextFormatter } from "components/Common/formatter";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { isEmpty, uniq } from "lodash";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import NGISelect from "components/Common/inputs/NGISelect";
import { searchOptions } from "pages/user-management/users/constants";

const { Option } = Select;

const UserSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.CUSTOMERS);
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const rolesList = useMemo(
    () => Object.values(USER_ROLES).map(role => ({ label: camelCaseTextFormatter(role), value: role })),
    []
  );

  useEffect(() => {
    const options = searchOptions.map(item => item.value);
    if (!isEmpty(filters)) {
      const filtersArray = [];
      Object.keys(filters).forEach(item => {
        if (options.includes(item)) {
          form.setFieldsValue({
            [item]: filters[item],
          });
          filtersArray.push(item);
        }
      });
      setSelectedFilter(filtersArray);
    }
  }, [form]);

  const onChangeFilter = useCallback(values => {
    setSelectedFilter(values.length > 1 ? values.filter(item => item !== "id") : values);
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    const preparedFilters = Object.keys(values).reduce((a, item) => ({ ...a, [item]: values[item]?.trim() }), {});
    handleSearch({ ...preparedFilters, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Select
          onChange={onChangeFilter}
          style={{ width: "100%" }}
          tokenSeparators={[","]}
          mode="multiple"
          value={selectedFilter}
          placeholder="Select type"
          allowClear
        >
          {searchOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.title}
            </Option>
          ))}
        </Select>
        <>
          {!!selectedFilter.length && selectedFilter.includes("name") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="name">
                <Input placeholder={"Enter name"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
              </Form.Item>
            </>
          )}
          {!!selectedFilter.length && selectedFilter.includes("role") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="role">
                <NGISelect
                  showSearch
                  placeholder="Please select Role"
                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                >
                  {rolesList.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </NGISelect>
              </Form.Item>
            </>
          )}

          {!!selectedFilter.length && selectedFilter.includes("status") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="status">
                <NGISelect
                  showSearch
                  placeholder="Please select Status"
                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                >
                  {["Available", "Unavailable", "Fired"].map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </Form.Item>
            </>
          )}

          {!!selectedFilter.length && selectedFilter.includes("depot") && (
            <>
              <div className="filter-divider" />
              <Form.Item name="depot">
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select depot"}>
                  {depot[USER_TENANT_NAME] &&
                    uniq(depot[USER_TENANT_NAME])
                      ?.sort()
                      ?.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default UserSearch;
