import React, { useCallback, useEffect, useState } from "react";
import { Divider, Modal, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import {
  approveTAMRequest,
  declineTAMRequest,
  downloadFileData,
  fetchCustomerById,
  fetchTransactionDetails,
  getTAMRequestById,
} from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_CURRENCY, USER_ROLES } from "utils/constants";
import { generateXLS } from "utils/helpers/functions";
import actionIcon from "./../../../assets/images/svg/ActionRequired.svg";
import { getAmountFiled, getNormalizedCSVData } from "pages/TAM/TAMRequests/utils";

const useStyles = makeStyles({
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  subTitle: {
    marginRight: 5,
    color: "#666666",
    fontSize: 13,
  },
  link: {
    textDecoration: "underline!important",
  },
  modalContent: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    paddingTop: 24,
  },
  container: {
    display: "flex",
  },
  listBlock: {
    width: "50%",
  },
  item: {
    marginBottom: 20,
  },
  title: {
    textTransform: "uppercase",
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
  },
  value: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    margin: "15px 0",
  },
  form: {
    width: "100%",

    "& .ant-row": {
      width: "100%",
    },
    "& .ant-typography": {
      fontWeight: 400,
      marginTop: 15,
    },
  },
  infoSection: {
    padding: "20px 0",
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
  },
  infoTitle: {
    fontWeight: 600,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    "&>div": {
      "& .button-general + .button-general": {
        marginLeft: 20,
      },
    },
  },
  successContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: 25,
    border: "1px solid rgba(0, 0, 0, 0.06)",
    borderRadius: 15,
  },
  customerList: {
    fontSize: 14,
    fontWeight: 600,
  },
  csvInfo: {
    fontSize: 12,
    fontWeight: 300,
    textTransform: "uppercase",
    color: "rgba(102, 102, 102, 1)",
  },
  actionImage: {
    width: 43,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    "&>div": {
      marginLeft: 20,
    },
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  img: {
    marginRight: 5,
    marginBottom: 5,
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  images: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const ApproveDeclineTAMReportModal = ({ activeItem, onClose, update }) => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [confirmationType, setConfirmationType] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const openConfirmationModal = useCallback(e => setConfirmationType(e.target.dataset.type), []);
  const closeConfirmationModal = useCallback(() => setConfirmationType(null), []);

  const onPreviewImg = useCallback(e => setPreviewUrl(e.currentTarget.dataset.url), []);
  const onClosePreview = useCallback(() => setPreviewUrl(""), []);

  const onApprove = useCallback(() => {
    setLoader(true);
    closeConfirmationModal();
    approveTAMRequest(activeItem.id)
      .then(() => {
        successMessage("TAM Request is approved.");
        setLoader(false);
        setTimeout(() => update(), 500);
        onClose();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [activeItem]);

  const onDecline = useCallback(() => {
    setLoader(true);
    closeConfirmationModal();
    declineTAMRequest(activeItem.id)
      .then(() => {
        successMessage("TAM Request is declined.");
        setLoader(false);
        setTimeout(() => update(), 500);
        onClose();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [activeItem]);

  useEffect(() => {
    if (activeItem.type !== "Bulk") {
      if (activeItem?.customerId) {
        setLoader(true);
        fetchCustomerById(activeItem.customerId)
          .then(res => {
            setLoader(false);
            setCustomer(res?.data?.result);
          })
          .catch(() => {
            setLoader(false);
            setCustomer(null);
            warnMessage("Customer is not found");
          });
      }
      if (activeItem?.transactionId) {
        setLoader(true);
        fetchTransactionDetails(activeItem.transactionId)
          .then(({ data }) => {
            setLoader(false);
            setTransactionDetails(data?.result);
          })
          .catch(() => {
            setLoader(false);
            setTransactionDetails(null);
            warnMessage("Receipt details are not found");
          });
      }
      getTAMRequestById(activeItem.id).then(({ data }) => {
        setPhotos(data.result.photos);
      });
    }
  }, []);

  const onDownload = useCallback(e => {
    downloadFileData(e.target.dataset.url)
      .then(res => generateXLS(getNormalizedCSVData(res.data), "Customer list", "csv", true))
      .catch(error => {
        defaultNgiErrorMethod(error);
      });
  }, []);

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            TAM REQUEST
            <span>
              {activeItem.customerId !== "BULK" ? (
                <>
                  <span className={classes.subTitle}>CUSTOMER</span>
                  <CustomLink
                    className={classes.link}
                    target="_blank"
                    to={`/customers/customer/${activeItem.customerId}`}
                  >
                    {customer && `${customer?.firstName} ${customer?.lastName}`}
                  </CustomLink>
                </>
              ) : (
                <span>BULK</span>
              )}
            </span>
          </div>
        </div>
      }
      closable={false}
      onCancel={onClose}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          {activeItem.type !== "Bulk" ? (
            <>
              <div className={classes.container}>
                <div className={classes.listBlock}>
                  <div className={classes.item}>
                    <div className={classes.title}>CUSTOMER ID</div>
                    <div className={classes.value}>{textFormatter(activeItem?.customerId)}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>RECEIPT</div>
                    <div className={classes.value}>{textFormatter(activeItem?.transactionId)}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>TYPE</div>
                    <div className={classes.value}>{textFormatter(activeItem?.type)}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>REASON</div>
                    <div className={classes.value}>{textFormatter(activeItem?.reason)}</div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>Photos</div>
                    <div className={classes.images}>
                      {photos?.length
                        ? photos.map(item => (
                            <div key={item} className={classes.img}>
                              <img data-url={item} onClick={onPreviewImg} src={item} alt="preview" />
                            </div>
                          ))
                        : "No photos"}
                    </div>
                  </div>

                  {!!previewUrl && (
                    <Modal title="Photo preview" open={!!previewUrl} onCancel={onClosePreview} footer={[]}>
                      <Spin spinning={!previewUrl}>
                        {previewUrl && <img className={classes.imgPreview} src={previewUrl} alt="preview" />}
                      </Spin>
                    </Modal>
                  )}
                </div>
                <div className={classes.listBlock}>
                  <div className={classes.item}>
                    <div className={classes.title}>CUSTOMER NAME</div>
                    <div className={classes.value}>
                      {textFormatter(customer && `${customer?.firstName} ${customer?.lastName}`)}
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>DATE</div>
                    <div className={classes.value}>
                      {textFormatter(customDateFormatWithTime(transactionDetails?.transactionDateTime))}
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.title}>AMOUNT ({USER_CURRENCY})</div>
                    <div className={classes.value}>{textFormatter(getAmountFiled(activeItem, transactionDetails))}</div>
                  </div>
                </div>
              </div>
              <div className={classes.infoSection}>
                <div className={classes.infoTitle}>DESCRIPTION</div>
                {activeItem?.description ?? "-"}
              </div>
            </>
          ) : (
            <div className={classes.successContent}>
              <div className={classes.infoContainer}>
                <img src={actionIcon} alt="actionIcon" className={classes.actionImage} />

                <div>
                  <div className={classes.customerList}>{activeItem.customerListCount} Customers added</div>
                  <div className={classes.csvInfo}>download *.CSV file to view users</div>
                </div>
              </div>
              <CustomButton
                data-url={activeItem.customerListFile}
                onClick={onDownload}
                color="primary"
                size="small"
                text="Download List"
              />
            </div>
          )}
        </div>
      </Spin>

      <Divider />
      <div className={classes.actionsContainer}>
        <CustomButton onClick={onClose} type="primary" color="outlined" size="small" text="Cancel" />
        <div className={classes.actions}>
          <CustomButton
            hideRoles={[USER_ROLES.D_SM, USER_ROLES.A_SM, USER_ROLES.OPERATION_MANAGERS]}
            data-type="decline"
            onClick={openConfirmationModal}
            type="error"
            size="small"
            text="DECLINE"
          />
          <CustomButton
            hideRoles={[USER_ROLES.D_SM, USER_ROLES.A_SM, USER_ROLES.OPERATION_MANAGERS, USER_ROLES.IT_OPERATIONS]}
            data-type="approve"
            onClick={openConfirmationModal}
            type="primary"
            size="small"
            text="APPROVE"
          />
        </div>
      </div>

      {!!confirmationType && (
        <ConfirmationModal
          description={`Are you sure you want to ${confirmationType} request?`}
          onCancel={closeConfirmationModal}
          onConfirm={confirmationType === "approve" ? onApprove : onDecline}
          loading={isLoading}
        />
      )}
    </Modal>
  );
};

export default ApproveDeclineTAMReportModal;
