import { NUMBER_PATTERN } from "pages/meter-management/configuration/meters/variables";

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const editableFieldsMap = [
  {
    title: "Personal Information",
    fields: [
      {
        firstName: "First Name",
        required: true,
        disabled: true,
      },
      {
        lastName: "Last Name",
        required: true,
        disabled: true,
      },
      {
        dob: "Date of Birth",
        required: true,
      },
      {
        occupation: "Occupation",
        required: true,
      },
      {
        familySize: "Family Size",
        required: true,
        rules: [
          { pattern: NUMBER_PATTERN, message: "Should be a number" },
          {
            pattern: /^[1-9]$|^0[1-9]$|^1[0-9]$|^20$/,
            message: "Value should be between 1 and 20",
          },
        ],
      },
      {
        personalPhotoFileName: "Personal Photo",
      },
    ],
  },
  {
    title: "Contact Information",
    fields: [
      {
        phone: "Phone",
        required: true,
        rules: [
          {
            message: "Phone should be in the correct format",
            validator: function (_, value) {
              const stringValue = value.toString();
              const hasZero = stringValue[3] === "0";
              if (hasZero && stringValue.match(/^(254|255)\d{10}$/)) {
                return Promise.resolve();
              } else if (!hasZero && stringValue.match(/^(254|255)\d{9}$/)) {
                return Promise.resolve();
              }
              return Promise.reject("Secondary Phone #1 should be in the correct format");
            },
          },
        ],
      },
      {
        secondaryPhone: "Secondary Phone #1",
        required: true,
        rules: [
          {
            message: "Secondary Phone #1 should be in the correct format",
            validator: function (_, value) {
              const stringValue = value.toString();
              const hasZero = stringValue[3] === "0";
              if (hasZero && stringValue.match(/^(254|255)\d{10}$/)) {
                return Promise.resolve();
              } else if (!hasZero && stringValue.match(/^(254|255)\d{9}$/)) {
                return Promise.resolve();
              }
              return Promise.reject("Secondary Phone #1 should be in the correct format");
            },
          },
        ],
      },
      {
        closeRelativePhone: "Secondary Phone #2",
        rules: [
          {
            message: "Secondary Phone #2 should be in the correct format",
            validator: function (_, value) {
              const stringValue = value.toString();
              if (!stringValue) {
                return Promise.resolve();
              }
              const hasZero = stringValue[3] === "0";
              if (hasZero && stringValue.match(/^(254|255)\d{10}$/)) {
                return Promise.resolve();
              } else if (!hasZero && stringValue.match(/^(254|255)\d{9}$/)) {
                return Promise.resolve();
              }
              return Promise.reject("Secondary Phone #2 should be in the correct format");
            },
          },
        ],
      },
    ],
  },
];
