export const searchOptions = [
  {
    value: "campaignId",
    title: "Campaign ID",
  },
  {
    value: "status",
    title: "Status",
  },
  {
    value: "name",
    title: "Name",
  },
  {
    value: "startDateFrom",
    title: "Start Date",
  },
  {
    value: "createdBy",
    title: "Initiator Email (CreatedBy)",
  },
];

export const campaignStatus = {
  CREATING: "Creating",
  NEW: "New",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  STOPPED: "Stopped",
};

export const statusList = [
  campaignStatus.CREATING,
  campaignStatus.NEW,
  campaignStatus.ACTIVE,
  campaignStatus.INACTIVE,
  campaignStatus.STOPPED,
];
