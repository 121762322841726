import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { FirmwaresSettingsSelector } from "store/meter-management/configuration/firmware/selectors";
import { getFirmwares } from "store/meter-management/configuration/firmware/actions";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_TENANT_NAME } from "utils/constants";
import { defaultNgiErrorMethod, errorMessage, warnMessage } from "components/Common/responses/message";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { uniq } from "lodash";
import closeIcon from "assets/images/svg/closeSmall.svg";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";
import {
  getFormattedFields,
  isAllFieldsHaveSameFirmware,
  isFileNoError,
  mandatoryFields,
} from "pages/meter-management/meters/utils";
import { updateOTAFirmware, uploadMetersToS3Bucket } from "utils/api";

const useStyles = makeStyles({
  modal: {
    maxWidth: "600px!important",
  },

  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionTitle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  title: {
    color: "#666666",
    fontWeight: 300,
    fontSize: 12,
    marginTop: 20,
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  input: { width: "100%", marginBottom: 20 },
});

const BulkUpdateFirmwareModal = ({ onClose, isOpen, isCsv }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoader] = useState(false);
  const classes = useStyles();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [filteredFirmwares, setFilteredFirmwares] = useState([]);
  const [isCsvUpload, setIsCsvUpload] = useState(isCsv);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(false);

  const { firmwares } = useSelector(FirmwaresSettingsSelector);
  useEffect(() => {
    dispatch(getFirmwares());
  }, []);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const readUploadFile = useCallback(e => {
    setDataLoading(true);
    const file = e.file;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        const formattedResult = getFormattedFields(json);

        const noError = isFileNoError(formattedResult);
        !noError && errorMessage(`File should contain ${mandatoryFields.map(field => ` ${field}`)} fields.`);

        const isSameFirmware = isAllFieldsHaveSameFirmware(formattedResult);
        !isSameFirmware && errorMessage(`All rows in the CSV should have the same newFirmware value`);

        setError(!noError || !isSameFirmware);

        setData(formattedResult);
        setFile(file);
        setDataLoading(false);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const handleChangeOldFirmware = useCallback(value => {
    form.setFieldValue("oldFirmware", value);
    form.setFieldValue("newFirmware", undefined);
    const selectedFirmware = firmwares?.find(item => item.version === value);
    setFilteredFirmwares(() =>
      firmwares?.filter(
        item =>
          item.microcontrollerType === selectedFirmware.microcontrollerType &&
          item.meterType === selectedFirmware.meterType &&
          (item.status === "Published" || item.status === "Draft")
      )
    );
  }, []);

  const handleChangeFlow = useCallback(value => {
    setIsCsvUpload(value);
  }, []);

  const handleSubmit = useCallback(() => {
    setLoader(true);

    const values = {
      ...(isCsvUpload && { isCsvUpload, newFirmware: data[0].newFirmware }),
      ...(!isCsvUpload && { ...form.getFieldsValue(), isCsvUpload }),
    };

    closeConfirmationModal();
    updateOTAFirmware(values)
      .then(async res => {
        const {
          data: {
            result: { url },
          },
        } = res;
        if (isCsvUpload && !url) {
          warnMessage("URL was not generated");
        }
        if (isCsvUpload && url) {
          uploadMetersToS3Bucket(url, file);
        }
        closeConfirmationModal();
        onClose();
        warnMessage(`OTA request successfully submitted`);
        setLoader(false);
      })
      .catch(err => {
        closeConfirmationModal();
        defaultNgiErrorMethod(err);
        setLoader(false);
      });
  }, [form, data, isCsvUpload, file]);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const removeFile = useCallback(() => {
    setData(null);
    setFile(null);
    setError(false);
  }, []);

  return (
    <Modal
      title={
        <div className={classes.titleWithAction}>
          <div className={classes.descriptionTitle}>
            Send Bulk Meter Firmware
            <span>Please fill all mandatory fields</span>
          </div>
        </div>
      }
      className={classes.modal}
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={loading}>
        <>
          <Title level={5}>Send To:</Title>
          <NGISelect showSearch className={classes.input} value={isCsvUpload} onChange={handleChangeFlow}>
            <Option value={false}>From Old to New Firmware</Option>
            <Option value={true}>CSV file</Option>
          </NGISelect>
          {isCsvUpload ? (
            <Spin spinning={dataLoading}>
              {file ? (
                <div className={classes.fileContainer}>
                  <div className={classes.fileTitle}>{`${file.name}`}</div>
                  <div onClick={removeFile} className={classes.removeBtn}>
                    <img src={closeIcon} alt="remove" />
                  </div>
                </div>
              ) : (
                <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                  <img className="upload-bg" src={uploadArea} alt="upload" />
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single upload. Strictly prohibit from uploading company data or other band files
                  </p>
                </Dragger>
              )}
              <br />
              <Space>
                <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />

                <CustomButton
                  onClick={openConfirmationModal}
                  disabled={error}
                  type="primary"
                  text="Save"
                  size="small"
                />
              </Space>
            </Spin>
          ) : (
            <NGIForm name="update-firmware" form={form} isUpdate={true} onFinish={openConfirmationModal}>
              <Title level={5}>Select Old Firmware: {!isCsv ? "*" : ""}</Title>
              <Form.Item name="oldFirmware" rules={[{ required: !isCsv, message: "Please select old firmware" }]}>
                <NGISelect showSearch placeholder="Please select old firmware" onChange={handleChangeOldFirmware}>
                  {!!firmwares &&
                    firmwares.map(item => (
                      <Option value={item.version} key={item.version}>
                        {item.version}
                      </Option>
                    ))}
                </NGISelect>
              </Form.Item>
              <Title level={5}>Select New Firmware: *</Title>
              <Form.Item name="newFirmware" rules={[{ required: true, message: "Please select new firmware" }]}>
                <NGISelect showSearch disabled={!filteredFirmwares?.length} placeholder="Please select new firmware">
                  {!!filteredFirmwares?.length &&
                    filteredFirmwares.map(item => (
                      <Option value={item.version} key={item.version}>
                        {item.version}
                      </Option>
                    ))}
                </NGISelect>
              </Form.Item>
              <Title level={5}>Select Depot:</Title>
              <Form.Item name="depot">
                <NGISelect showSearch placeholder={"Select value"}>
                  {depot[USER_TENANT_NAME] &&
                    uniq(depot[USER_TENANT_NAME])
                      ?.sort()
                      ?.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                </NGISelect>
              </Form.Item>
              <Space>
                <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
                <CustomButton type="primary" text="Update" size="small" />
              </Space>
            </NGIForm>
          )}
          {isConfirmationOpen && (
            <ConfirmationModal
              loading={loading}
              description={"Are you sure you want to change the firmware?"}
              onCancel={closeConfirmationModal}
              onConfirm={handleSubmit}
            />
          )}
        </>
      </Spin>
    </Modal>
  );
};

export default BulkUpdateFirmwareModal;
