import React from "react";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { Tooltip } from "antd";
import CustomLink from "components/Common/buttons/CustomLink";
import classnames from "classnames";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";

const color = "#444444";
const hideRoles = [USER_ROLES.TSS];
export const leadListColumns = styles => [
  {
    title: "Document ID",
    dataIndex: "",
    render: row => textFormatter(row.document?.number),
  },
  {
    title: "Full Name",
    dataIndex: "",
    render: row => {
      const fullName = `${row.firstName} ${row.lastName}`;
      return userHasOneOfRoles(hideRoles) ? (
        textFormatter(fullName)
      ) : (
        <Tooltip placement="bottom" title={`${row.firstName} ${row.lastName} details`} color={color}>
          <div>
            <CustomLink to={`lead/${row.id}?backUrl=/operations/not-assigned`}>{textFormatter(fullName)}</CustomLink>
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
    sort: true,
  },
  {
    title: "Scheduled Approval",
    dataIndex: "leadScoringAt",
    render: customDateFormatWithTime,
    sort: true,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: textFormatter,
    sort: true,
  },
  {
    title: "TSR",
    dataIndex: "technicianName",
    render: cellContent =>
      cellContent ? (
        <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
          {cellContent}
        </CustomLink>
      ) : (
        textFormatter(cellContent)
      ),
    sort: true,
  },
  {
    title: "Category",
    dataIndex: "type",
    render: textFormatter,
    sort: true,
  },
  {
    title: "Score",
    dataIndex: "leadScore",
    render: (cellContent, row) => (
      <div className={classnames(styles.scoreContainer, styles[row.status])}>
        {cellContent ? cellContent?.toFixed(4) : "-"}
      </div>
    ),
    sort: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: cellContent => textFormatter(cellContent === "Qualified" ? "Approved" : cellContent),
    sort: true,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: cellContent => (
      <CustomLink underlined={true} to={`/depots/depot?name=${cellContent}`} target={"_blank"}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Parent",
    dataIndex: "parentCustomerId",
    render: cellContent => (
      <div style={{ textDecoration: "underline" }}>
        <CustomLink to={`/customers/customer/${cellContent}`} target={"_blank"}>
          {textFormatter(cellContent)}
        </CustomLink>
      </div>
    ),
  },
];
