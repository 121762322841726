const config = {
    env: 'ngi-prod',
    cognito: {
        userPoolId: 'eu-west-1_1SAkru1vS',
        domain: 'https://ngi-auth-ngi-prod.auth.eu-west-1.amazoncognito.com',
        landingDomain: 'https://promo.mgas.ke',
        state: 'web.b2cdashboard',
        clientId: '6jucihl1a0jjjbgllaolljjg09',
        redirectUrl: 'https://d2o8e3di80ca23.cloudfront.net',
        region: 'eu-west-1',
        clientSecret: '7uj1spa7bue92bt51schrr9n1hhq5o63oier3gq06vortm8jeih'
    }
}
export default config
