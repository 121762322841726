export const filterOptions = [
  { name: "Condition", value: "condition" },
  { name: "Meter barcode", value: "barcode" },
  { name: "Meter serial number", value: "serial" },
  { name: "PCB serial number", value: "pcb" },
  { name: "Sensor serial number", value: "sensorSerial" },
];

export const conditions = ["Awaiting Testing New", "Awaiting Testing From Market"];

export const RESOLUTIONS = {
  CERTIFIED: "Certified",
  REPAIR: "Repair",
};

export const RESOLUTION_OPTIONS = [
  { name: "Certified", value: RESOLUTIONS.CERTIFIED },
  { name: "For Repair", value: RESOLUTIONS.REPAIR },
];

export const RESOLUTION_TYPES = {
  [RESOLUTIONS.CERTIFIED]: {
    title: "Meter Will Be Certified!",
    description: "Verifying the status of the meter will make it available for the configuration step.",
  },
};

export const FAULTY_REASONS = {
  RFID: "RFID",
  REGULATOR: "Regulator",
  LOCK: "Lock",
  VALVE: "Valve",
  KNOB: "Knob",
  BUTTON: "Button",
  FLOW_SENSOR: "Flow Sensor",
  TOP_COVER: "Top Cover",
  BOTTOM_COVER: "Bottom Cover",
  PCB: "PCB",
  LCD_PCB: "LCD PCB",
  CONFIG: "Config",
  FIRMWARE: "Firmware",
  MODEM: "Modem",
  SIM_PORT: "SIM Port",
};

export const FAULTY_REASONS_OPTIONS = {
  [FAULTY_REASONS.RFID]: "SIM Card",
  [FAULTY_REASONS.REGULATOR]: "Regulator",
  [FAULTY_REASONS.LOCK]: "Lock",
  [FAULTY_REASONS.VALVE]: "Valve",
  [FAULTY_REASONS.KNOB]: "Knob",
  [FAULTY_REASONS.BUTTON]: "Button",
  [FAULTY_REASONS.FLOW_SENSOR]: "Flow Sensor",
  [FAULTY_REASONS.TOP_COVER]: "Top Cover",
  [FAULTY_REASONS.BOTTOM_COVER]: "Bottom Cover",
  [FAULTY_REASONS.PCB]: "PCB",
  [FAULTY_REASONS.LCD_PCB]: "LCD PCB",
  [FAULTY_REASONS.CONFIG]: "Config",
  [FAULTY_REASONS.FIRMWARE]: "Firmware",
  [FAULTY_REASONS.MODEM]: "Modem",
  [FAULTY_REASONS.SIM_PORT]: "SIM Port",
};
