import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { Col, Form, Row } from "antd";
import classnames from "classnames";
import editIcon from "assets/images/svg/Pencil Drawing.svg";
import deleteIcon from "assets/images/svg/Delete.svg";
import Title from "antd/es/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import NGIInput from "components/Common/inputs/NGIInput";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import CustomButton from "components/Common/buttons/CustomButton";
import NewQuestionModal from "pages/settings/survey-management/surveyDetails/NewQuestion/NewQuestionModal";
import { createSurvey } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { USER_TENANT_NAME } from "utils/constants";

const useStyles = makeStyles({
  root: {},
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  questionsContainer: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  addNew: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,
    marginTop: 15,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  rowContainer: {
    borderRadius: 5,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgba(250, 250, 250, 1)",
    marginBottom: 5,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  value: {
    fontSize: 13,
    color: "#444444",
    fontWeight: 600,
    marginRight: 5,
    padding: 15,
    whiteSpace: "nowrap",
    width: "15%",

    "&>span": {
      color: "#8C8C8C",
      fontWeight: 300,
    },
  },
  questionContainer: {
    width: "50%",
  },
  questionOptionsContainer: {},
  questionTitle: {
    width: "20%",
  },
  actionContainer: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 80,

    "& img": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  deleteBtn: {
    width: 18,
  },
  overflowText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

const EditSurveyTab = ({ survey, isCreate, setIsLoading }) => {
  const classes = useStyles();
  const history = useHistory();
  const [form] = Form.useForm();
  const [questions, setQuestions] = useState(survey?.items || []);
  const [activeItem, setActiveItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    if (survey) {
      form.setFieldsValue(survey);
      setQuestions(survey?.items.map(item => ({ ...item, id: uuidv4() })));
    } else {
      form.setFieldValue("depot", "ALL");
    }
  }, [survey]);

  const onSave = useCallback(
    values => {
      const preparedData = {
        ...values,
        items: questions,
      };
      setIsLoading(true);
      closeConfirmationModal();
      createSurvey(preparedData)
        .then(() => {
          successMessage(`Survey was ${isCreate ? "created" : "updated"} successfully`);
          setIsLoading(false);
          history.push("/settings/surveys");
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [questions, isCreate]
  );

  const onAddQuestion = useCallback(
    newQuestion =>
      setQuestions(prevState => {
        if (activeItem) {
          const index = prevState.findIndex(item => item.id === activeItem?.id);
          if (index !== -1) {
            prevState[index] = newQuestion;
            return prevState;
          }
        }
        return [...prevState, newQuestion];
      }),
    [activeItem]
  );
  const onOpenModal = useCallback(() => setOpenModal(true), []);
  const onCloseModal = useCallback(() => {
    setOpenModal(false);
    setActiveItem(null);
  }, []);

  const onRemoveQuestion = useCallback(e => {
    const { id } = e.currentTarget.dataset;
    setQuestions(prevState => prevState.filter(item => item.id !== id));
  }, []);

  const onEditQuestion = useCallback(
    e => {
      const { id } = e.currentTarget.dataset;
      const foundItem = questions.find(item => item.id === id);
      setActiveItem(foundItem);
      onOpenModal();
    },
    [questions]
  );

  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationVisible(false);
  }, []);

  const openConfirmationModal = useCallback(e => {
    e.preventDefault();
    setIsConfirmationVisible(true);
  }, []);

  return (
    <div className={classes.root}>
      <Form form={form} name="survey-edit" onFinish={onSave}>
        <Row gutter={[24, 8]}>
          <Col lg={12}>
            <Title level={5}>Survey Name</Title>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Please enter value" },
                {
                  message: "Please enter value",
                  validator: (_, value) => {
                    if (value && !String(value).trim()) {
                      return Promise.reject("Please enter value");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <NGIInput disabled={!isCreate} placeholder="Enter survey name" />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Title level={5}>Depot</Title>
            <Form.Item name="depot">
              <NGISelect showSearch disabled={!isCreate} placeholder={"Select depot"} tokenSeparators={[" ", ","]}>
                <Option value={"ALL"}>ALL</Option>
                {!!depot &&
                  !!depot[USER_TENANT_NAME]?.length &&
                  depot[USER_TENANT_NAME]?.sort()?.map(
                    item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ),
                    this
                  )}
              </NGISelect>
            </Form.Item>
          </Col>
        </Row>

        <TitleWithDivider title="Survey Questions" />
        <div className={classes.questionsContainer}>
          {questions.map(item => (
            <div className={classes.rowContainer} key={item.question}>
              <>
                <div className={classnames(classes.value, classes.questionContainer, classes.overflowText)}>
                  {item.type === "Instruction" ? item.title : item.question}
                </div>
                <div className={classnames(classes.value, classes.questionTitle, classes.overflowText)}>
                  <span>Answer type</span> {item.type}
                </div>
                <div className={classnames(classes.value, classes.questionOptionsContainer, classes.overflowText)}>
                  <span>Total Answers</span> {item.type === "Instruction" ? item?.items?.length : item?.options?.length}
                </div>
              </>
              <div className={classes.actionContainer}>
                <img src={editIcon} data-id={item.id} onClick={onEditQuestion} alt="edit" />
                <img
                  className={classes.deleteBtn}
                  data-id={item.id}
                  onClick={onRemoveQuestion}
                  src={deleteIcon}
                  alt="remove"
                />
              </div>
            </div>
          ))}

          <div className={classes.addNew} onClick={onOpenModal}>
            + Add New Question
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <CustomButton disabled={!questions.length} onClick={openConfirmationModal} size="small" text="Save Changes" />
        </div>
      </Form>

      {openModal && (
        <NewQuestionModal questionData={activeItem} onClose={onCloseModal} onQuestionSave={onAddQuestion} />
      )}
      {isConfirmationVisible && (
        <ConfirmationModal
          onCancel={closeConfirmationModal}
          onConfirm={form.submit}
          description={`Are you sure you want to ${isCreate ? "create a new" : "change the"} survey?`}
        />
      )}
    </div>
  );
};

export default EditSurveyTab;
