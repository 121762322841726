import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import CustomLink from "components/Common/buttons/CustomLink";

export const searchOptions = [
  {
    value: "operationDepot",
    title: "Operation Depot",
  },
  {
    value: "date",
    title: "Date",
  },
  {
    value: "serialNumber",
    title: "Cylinder serial number",
  },
];

export const fromToMarketReportsListColumns = () => [
  {
    title: "Serial",
    dataIndex: "serialNumber",
    render: cellContent =>
      cellContent !== "UNKNOWN" ? (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${cellContent}?t=serialNumber`}>
          {cellContent}
        </CustomLink>
      ) : (
        cellContent
      ),
  },
  {
    title: "Code",
    dataIndex: "qrCode",
    render: textFormatter,
  },
  {
    title: "T.Weight",
    dataIndex: "tareWeight",
    render: value => textFormatter(value?.toFixed(2)),
  },
  {
    title: "Weight Out",
    dataIndex: "weightToMarket",
    render: value => textFormatter(value?.toFixed(2)),
  },
  {
    title: "Weight Back",
    dataIndex: "weightFromMarket",
    render: value => textFormatter(value?.toFixed(2)),
  },
  {
    title: "Gas Left",
    dataIndex: "gasWeightLeft",
    render: value => textFormatter(value?.toFixed(2)),
  },
  {
    title: "Expected G.W.",
    dataIndex: "weightExpected",
    render: value => textFormatter(value?.toFixed(2)),
  },
  {
    title: "To Refill",
    dataIndex: "gasWeightToRefill",
    render: value => textFormatter(value?.toFixed(2)),
  },
  {
    title: "Depot Out",
    dataIndex: "sourceDepot",
    render: textFormatter,
  },
  {
    title: "Depot Fill",
    dataIndex: "operationDepot",
    render: textFormatter,
  },
  {
    title: "Recorded By",
    dataIndex: "createdBy",
    render: cellContent => (
      <CustomLink underlined target="_blank" to={`/user/${cellContent}`}>
        {cellContent}
      </CustomLink>
    ),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
  },
];

export const REPORT_TYPES = {
  FROM_MARKET_REPORTS: "from-market-reports",
  TO_MARKET_REPORTS: "to-market-reports",
};

export const apiByType = {
  [REPORT_TYPES.FROM_MARKET_REPORTS]: "From Market",
  [REPORT_TYPES.TO_MARKET_REPORTS]: "To Market",
};

export const dateFormat = "DD/MM/YYYY";
