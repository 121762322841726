import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "antd";
import { getRequiredFormFields } from "components/Common/form/utils";
import classnames from "classnames";
import { uuidv4 } from "utils/helpers/functions";

const useStyles = makeStyles({
  disabledSubmit: {
    "& button[htmltype='submit']": {
      pointerEvents: "none",
      opacity: 0.4,
    },
  },
});

//The NGIForm includes logic of handling the disabled submit button
//before one of the required fields becomes filled
const NGIForm = ({ children, isUpdate = false, name = uuidv4(), ...props }) => {
  const classes = useStyles();
  const [disabledSave, setDisabledSave] = useState(!isUpdate);
  const handleFormChange = useCallback(
    field => {
      const requiredFields = getRequiredFormFields(name);
      const fieldName = field[0].name[0];
      if (isUpdate || requiredFields.includes(fieldName)) {
        setDisabledSave(false);
      }
    },
    [isUpdate]
  );

  return (
    <div name={name}>
      <Form
        {...props}
        name={name}
        onFieldsChange={handleFormChange}
        className={classnames(disabledSave && classes.disabledSubmit, props.className)}
      >
        {children}
      </Form>
    </div>
  );
};

export default NGIForm;
