export const statuses = ["Cleared", "Uncleared", "Rejected"];
export const types = ["Manual", "Automatic"];
export const allowedStatus = "Uncleared";

export const FILTERS = {
  CUSTOMER: "customerNumber",
  STATUS: "status",
  TYPE: "type",
};
export const filterOptions = [
  { name: "Customer ID", value: FILTERS.CUSTOMER },
  { name: "Status", value: FILTERS.STATUS },
  { name: "Type", value: FILTERS.TYPE },
];

export const RESOLUTIONS = {
  RESOLVE: "resolve",
  REJECT: "reject",
};
export const RESOLUTION_OPTIONS = [
  { name: "Resolve", value: RESOLUTIONS.RESOLVE },
  { name: "Reject", value: RESOLUTIONS.REJECT },
];
